import React from 'react';
import './index.css';
export const Spinner = () => {
    return (
        <div className="loader-center" key="1">
            <div>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div></div>
    );
};
export const WhiteSpinner = () => {
    return (

        <div className="lds-ring-white" key="1">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

    );
};
export const BlueSmallSpinner = () => {
    return (

        <div className="lds-ring-blue-small" key="1">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

    );
};
