import React, { Component } from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import background from "../../assets/img/login-background.jpg";
import "./ConfirmCode.css";
import auxo from "../../assets/img/AUXO-Horiz-fleet.png";
import toastr from "toastr";
import tooltipIcon from "../../assets/img/tooltip.png";
import { connect } from "react-redux";
import UserAuthentication from "../../ducks/UserAuthentication";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { validateConfirmCodeInput } from "../../validations/auth";
import { WhiteSpinner } from '../common/Spinner/index';
const queryString = require('query-string');


class UserReset extends Component {
  constructor(props) {
    super(props);

    //let { userName, token } = queryString.parse(props.location.search);

    const queryParams = queryString.parse(props.location.search);

    let userName, token, isMqex;

    if ('isMqex' in queryParams) {
      ({ userName, token, isMqex } = queryParams);
    } else {
      ({ userName, token } = queryParams);
    }

    console.log("queryParams -> ", queryParams)

    let logedInUser = localStorage.getItem('token');
    if (logedInUser) {
      toastr.error("Another user already loggedin, please logout and activate your account.");
      this.props.history.push("/")
    }
    this.state = {
      username: userName,
      token,
      password: "",
      confirmPassword: "",
      errors: {},
      loader: false,
      isMqex: isMqex || false
    }

  }

  isValid = () => {
    const { errors, isValid } = validateConfirmCodeInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  };
  onSubmit = async e => {
    e.preventDefault();
    if (this.isValid()) {

      this.setState({ ...this.state, loader: true })
      try {
        const userCred = {
          username: this.state.username,
          token: this.state.token,
          password: this.state.password,
          isMqex: this.state.isMqex
        }

        console.log("queryParams userCred -> ", JSON.stringify(userCred))


        await this.props.actions.confirmCode(userCred);
        this.setState({ ...this.state, loader: false })
        toastr.success("New password is set successfully");
        this.props.history.push("/");
      }
      catch (error) {
        if (error.response) {
          const data = error.response.data.message;
          toastr.error(data);
        }
        else {
          toastr.error(error);
        }
        this.setState({ ...this.state, loader: false })
      }
    }
  };

  render() {
    const labelIcon = {
      icon: tooltipIcon,
      text: "Password must be 8 characters long containing numbers, special characters, uppercase and lowercase letters."
    }
    const loader = <WhiteSpinner key="1" />
    return (
      <div className="justify-content-md-center login-container " style={{ backgroundImage: background }}>
        <div className="login-box mt-lg-5">
          <div className="row">
            <img className="login-logo" src={auxo} alt="" />
          </div>
          <form className={"login-form"} onSubmit={this.onSubmit}>


            <TextFieldGroup
              field={"password"}
              placeholder={"Enter Your New Password"}
              value={this.state.password}
              label={"Set Your Password"}
              type={"password"}
              labelIcon={labelIcon}
              onChange={this.onChange}
              error={this.state.errors.password} />

            <TextFieldGroup
              field={"confirmPassword"}
              placeholder={"Please retype your new password"}
              value={this.state.confirmPassword}
              label={"Retype Your Password"}
              type={"password"}
              onChange={this.onChange}
              error={this.state.errors.confirmPassword} />
            <div className="mt-lg-5">
              <button className="btn btn-success btn-login" type="submit">{this.state.loader ? [loader] : 'Set Password'}</button>
            </div>
            <div className={"help-link-section"}>
              <Link to="/login">log In</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

UserReset.propTypes = {};
UserReset.defaultProps = {};
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(UserAuthentication.creators, dispatch) });
export default connect(null, mapDispatchToProps)(UserReset);
