import Duck from 'extensible-duck'
import axios from "axios";
import {geoFenceEndPoints} from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoGeoFence',
    store: 'geoFence',
    types: [
        'IS_LOADING', 'DETAILS_FETCHED', 'GEOFENCES_FETCHED','GEOFENCES_WPAGING_FETCHED', 'CLEAR_GEOFENCE', 'GEOFENCES_COUNT'
    ],
    initialState: {
        geoFence: {},
        geoFences: [],
        geoFencesWpaging: [],
        geoFenceCount: 0,
        hasMoreItems: false,
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.GEOFENCES_FETCHED:
                return {
                    ...state,
                    geoFences: [...state.geoFences, ...action.geoFences],
                    hasMoreItems: action.geoFences.length > 0 ? true : false
                };
            case duck.types.GEOFENCES_WPAGING_FETCHED:
                return {
                    ...state,
                    geoFencesWpaging: action.geoFencesWpaging
                };
            case duck.types.GEOFENCES_COUNT:
                return {
                    ...state,
                    geoFenceCount: action.geoFenceCount,
                };
            case duck.types.CLEAR_GEOFENCE:
                return {
                    ...state,
                    geoFences: [],
                };

            case duck.types.DETAILS_FETCHED:
                return {
                    ...state,
                    geoFence: action.geoFence
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().geoFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(geoFenceEndPoints.individualGeoFence(state.id, companyInfo.groupId));
                let geoFence = response.data.data;
                dispatch({type: duck.types.DETAILS_FETCHED, geoFence});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupGeoFences: () => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().geoFence.isLoading) {
                return;
            }
            
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                dispatch({type: duck.types.CLEAR_GEOFENCE});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(geoFenceEndPoints.geoFence(companyInfo.groupId));
                let geoFences = response.data.data;
                dispatch({type: duck.types.GEOFENCES_FETCHED, geoFences});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return geoFences
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupGeoFences1: (search, clearRecord) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().geoFence.isLoading) {
                return;
            }
            if(clearRecord){
              dispatch({type: duck.types.CLEAR_GEOFENCE});
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                let url = geoFenceEndPoints.geoFenceList(companyInfo.groupId) + `?search=${search}&offset=${getState().geoFence.geoFences.length}`;
       
                const response = await axios.get(url);
                let {geoFences, geoFenceCount} = response.data.data;
                dispatch({type: duck.types.GEOFENCES_FETCHED, geoFences});
                dispatch({type: duck.types.GEOFENCES_COUNT, geoFenceCount});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return geoFences;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupGeoFences2: () => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().geoFence.isLoading) {
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                let url = geoFenceEndPoints.WithoutPaging(companyInfo.groupId);
       
                const response = await axios.get(url);
                let {geoFencesWpaging} = response.data.data;
                dispatch({type: duck.types.GEOFENCES_WPAGING_FETCHED, geoFencesWpaging});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return geoFencesWpaging;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },

     
        GeoFence: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().geoFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }

            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

                if(state.id){
                   
                    await axios.put(geoFenceEndPoints.individualGeoFence(state.id, companyInfo.groupId), state.geofenceObj);
                } else{
                    await axios.post(geoFenceEndPoints.geoFence(companyInfo.groupId), state);
                }
               
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
              console.log(e)
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        importGeofence: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().geoFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
               
                await axios.post(geoFenceEndPoints.importGeofence(state.groupId), {geofences: state.geofences});
                
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        deleteGeoFence: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().geoFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                await axios.delete(geoFenceEndPoints.individualGeoFence(state.id, companyInfo.groupId), state.vehicles);

                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        deleteCheckedGeoFence : (state) =>  async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().geoFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                await axios.delete(geoFenceEndPoints.deleteGeoFence(companyInfo.groupId),{fenceToDelete :state.fenceToDelete});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
    })
})