import React, { Component } from "react";
import { connect } from "react-redux";
import Things from "../../../ducks/Things";
import Drivers from "../../../ducks/Drivers";
import Modal from "react-bootstrap4-modal";
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import { validateInput } from "../../../validations/createDriver";
import {
  isEmptyValue,
  isAlphaNumeric,
  isNumeric,
} from "../../../validations/validator";
import "./index.css";
import toastr from "toastr";
import * as _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select-nested-group";
import moment from "moment";
import CSVReader from "react-csv-reader";
import { URL } from "../../../constants";

class CreateDriver extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      serialNumber: "",
      dateOfJoining: moment(),
      vehicleNumberPlate: "",
      iButton: null,
      email: '',
      driversToImport: [],
      importDrivers: false,
      errors: {},
    };
  }
  async componentDidMount() {
    await this.props.thingsActions.getVehiclesPlateNumber();
  }

  handleDateChange(date) {
    this.setState({
      ...this.state,
      dateOfJoining: date,
    });
  }

  navigateToList() {
    this.props.history.push("/drivers");
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: "",
      },
    });
  };

  onChangeEmail = (e) => {
    this.setState({
      ...this.state,
      email: e.target.value,
    });
  };

  onChangeData = (e) => {
    if (e && e.target) {
      const index = e.target.id;

      let driversToImport = this.state.driversToImport;
      let item = driversToImport[index];
      item[e.target.name] = e.target.value;
      driversToImport[index] = item;
      this.setState({ ...this.state, driversToImport });
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  handlePlateNumberChange = async (selectedOption) => {
    this.setState({
      ...this.state,
      vehicleNumberPlate: selectedOption,
    });
  };

  async onSubmit() {
    if (this.isValid()) {
      try {
        const driverInfo = {
          name: this.state.name,
          serialNumber: this.state.serialNumber,
          iButton: this.state.iButton,
          dateOfJoining: this.state.dateOfJoining,
          thing: this.state.vehicleNumberPlate || null,
          email: this.state.email
        };

        await this.props.actions.addDriver(driverInfo);
        toastr.success("Driver added successfully!");
        this.props.history.push("/drivers");
      } catch (e) {
        toastr.error(e.response ? e.response.data.message : e);
      }
    }
  }
  handleDarkSideForce() {
    toastr.error("Invalid file format!");
  }

  handleFileUpload(data) {
    if (data && data.length) {
      let heading = data[0];

      if (
        heading[0] !== "Serial Number" ||
        heading[1] !== "Name" ||
        heading[2] !== "Date Of Joining" ||
        heading[3] !== "Plate Number" ||
        heading[4] !== "iButton Value"
      ) {
        return toastr.error("Invalid file format!");
      }

      let drivers = [];
      let validSerialNumbers = true;
      let validEmployeeNames = true;
      let validEmployeeDoj = true;
      let validDate = true;

      _.forEach(data, (item, index) => {
        if (index !== 0) {
          let obj = {
            serialNumber: "",
            name: "",
            iButton: "",
            dateOfJoining: "",
            plateNumber: "",
            valid: true,
          };
          _.forEach(item, (value, valueIndex) => {
            if (valueIndex === 0) {
              obj.serialNumber = _.trim(value);
            }
            if (valueIndex === 1) {
              obj.name = value;
            }
            if (valueIndex === 2) {
              try {
                obj.dateOfJoining = moment(value);
                if (!obj.dateOfJoining.isValid()) {
                  validDate = false;
                  obj.valid = false;
                  obj.dateOfJoining = "";
                }
              } catch (err) {
                validDate = false;
                obj.valid = false;
              }
            }

            if (valueIndex === 3) {
              obj.plateNumber = _.trim(value) || null;
            }
            if (valueIndex === 4) {
              obj.iButton = _.trim(value) || null;
            }
          });

          if (obj.serialNumber || obj.name || obj.dateOfJoining) {
            if (isEmptyValue(obj.serialNumber)) {
              obj.valid = false;
              validSerialNumbers = false;
            } else if (!isAlphaNumeric(obj.serialNumber)) {
              obj.valid = false;
              validSerialNumbers = false;
            } else if (obj.serialNumber.length >= 50) {
              obj.valid = false;
              validSerialNumbers = false;
            }
            if (isEmptyValue(obj.name)) {
              obj.valid = false;
              validEmployeeNames = false;
            }
            // else if (!isAlphaNumeric(obj.name)) {
            //     obj.valid = false;
            //     validEmployeeNames = false;
            // }
            // else if (obj.name.length > 50) {
            //     obj.valid = false;
            //     validEmployeeNames = false;
            // }
            if (isEmptyValue(obj.dateOfJoining)) {
              obj.valid = false;
              validEmployeeDoj = false;
            }

            if (obj.valid) {
              drivers.push(obj);
            }
          }
        }
      });
      if (!validSerialNumbers) {
        return toastr.error("Invalid serialNumber for some drivers in file!");
      } else if (!validEmployeeNames) {
        return toastr.error("Invalid driver name for some drivers in file!");
      } else if (!validEmployeeDoj) {
        return toastr.error(
          "Invalid date Of joining for some drivers in file!"
        );
      } else if (!validDate) {
        return toastr.error(
          "Invalid date of joining for some drivers in file!"
        );
      }
      if (drivers.length) {
        this.setState({
          ...this.state,
          driversToImport: drivers,
          importDrivers: true,
        });
      }
    }
  }
  async onConfirm() {
    try {
      let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
      await this.props.actions.importDrivers({
        drivers: this.state.driversToImport,
        groupId: companyInfo.groupId,
      });
      toastr.success("Drivers imported successfully!");
      this.props.history.push("/drivers");
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  }

  render() {
    let token = localStorage.getItem("refreshToken");
    return (
      <Modal
        visible={true}
        onClickBackdrop={this.modalBackdropClicked}
        dialogClassName="driver-modal-dialog"
      >
        <div>
          <button
            type="button"
            className="close close-x"
            aria-label="Close"
            onClick={this.navigateToList.bind(this)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-heading row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            {" "}
            <p className="driver-heading">Add a new driver</p>
          </div>

          <div className="col-lg-8 col-md-8 col-sm-8">
            <span>
              <a
                className="custom-vehicles-upload"
                href={`${URL}/companies/files/drivers.csv?token=${token}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download template
              </a>
            </span>
            <span>
              <a className="custom-vehicles-upload">
                <CSVReader
                  cssClass="drivers-input-reader"
                  accept=".csv"
                  onFileLoaded={this.handleFileUpload.bind(this)}
                  onError={this.handleDarkSideForce}
                />
                Import drivers
              </a>
            </span>
          </div>
        </div>
        <div className="modal-body">
          {!this.state.importDrivers ? (
            <div className="row">
              <div className="col-lg-6">
                <TextFieldGroup
                  field={"name"}
                  placeholder={"Driver Name"}
                  value={this.state.name}
                  type={"text"}
                  label={"DRIVER NAME"}
                  error={this.state.errors.name}
                  onChange={this.onChange.bind(this)}
                />

                <div className="form-group">
                  <label>VEHICLE NUMBER PLATE</label>
                  <Select
                    name="form-field-name"
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    onChange={this.handlePlateNumberChange}
                    options={
                      this.props.vehicles &&
                      this.props.vehicles.map((item) => {
                        return { value: item._id, label: item.plateNumber };
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <TextFieldGroup
                  field={"serialNumber"}
                  placeholder={"Serial Number"}
                  value={this.state.serialNumber}
                  type={"text"}
                  label={"Serial Number"}
                  error={this.state.errors.serialNumber}
                  onChange={this.onChange.bind(this)}
                />
                <div className="form-group">
                  <label>DATE OF JOINING</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.dateOfJoining}
                    onChange={this.handleDateChange.bind(this)}
                  />{" "}
                  {this.state.errors.dateOfJoining && (
                    <span className="text-danger">
                      {this.state.errors.dateOfJoining}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <TextFieldGroup
                  field={"iButton"}
                  placeholder={"iButton Value"}
                  value={this.state.iButton}
                  type={"text"}
                  label={"iButton Value"}
                  onChange={this.onChange.bind(this)}
                />
              </div>
              {this.props.permissions.companyFeatures.indexOf('DRIVER_LOGIN') !== -1 &&
              <div className="col-lg-6">
                <TextFieldGroup
                  field={"email"}
                  placeholder={"Email"}
                  value={this.state.email}
                  type={"text"}
                  label={"Email"}
                  onChange={this.onChangeEmail.bind(this)}
                />
                {this.state.errors.email && (
                    <span className="text-danger">
                      {this.state.errors.email}
                    </span>
                  )}
              </div>
              }
            </div>
          ) : (
            <div>
              <table className="table-auxo">
                <thead>
                  <tr>
                    <th>Serial Number</th>
                    <th>NAME</th>
                    <th>DATE OF JOINING</th>
                    <th>VEHICLE NUMBER PLATE</th>
                    <th>iButton Value</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.driversToImport &&
                    this.state.driversToImport.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.serialNumber}
                              id={index}
                              key={index}
                              type={"text"}
                              field={"serialNumber"}
                              placeholder={"Serial Number"}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.name}
                              id={index}
                              key={index}
                              type={"text"}
                              field={"name"}
                              placeholder={"Driver Name"}
                            />
                          </td>

                          <td>
                            <div className="form-group">
                              <DatePicker
                                className="form-control"
                                selected={item.dateOfJoining}
                                onChange={this.onChangeData.bind(this)}
                              />
                            </div>
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.plateNumber}
                              type={"text"}
                              id={index}
                              key={index}
                              field={"plateNumber"}
                              placeholder={"Enter Plate Number"}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.iButton}
                              type={"text"}
                              id={index}
                              key={index}
                              field={"iButton"}
                              placeholder={"Enter iButton value"}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="modal-footer">
          {!this.state.importDrivers ? (
            <button className="close-btn" onClick={this.onSubmit.bind(this)}>
              Save
            </button>
          ) : (
            <button className="close-btn" onClick={this.onConfirm.bind(this)}>
              Confirm
            </button>
          )}
        </div>
      </Modal>
    );
  }
}

CreateDriver.propTypes = {};
CreateDriver.defaultProps = {};
const mapStateToProps = (state) => ({
  vehicles: state.things.vehiclesPlateNumber,
  permissions: state.permissions 
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Drivers.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateDriver);
