import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { subDays } from 'date-fns';
import { Popover, PopoverBody } from 'reactstrap';
import Select from 'react-select-nested-group';
import { DateRangePicker } from 'react-date-range';
import Patrolling from '../../../ducks/Patrolling';
import toastr from 'toastr';

import './index.scss'
import calenderIcon from '../../../assets/img/calender.png';

class ReportInterval extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      cellSites: [],
      vehicles: [],
      selectedCellSites: [],
      selectedVehicles: [],
      errors: {},
      dateRangePicker: {
        selection: {
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
          startTime: moment(),
          endTime: moment(),
          key: 'selection',
        },
      },
    }
  }

  async componentDidMount() {

    const data = await this
      .props.actions
      .listCellSitesAndVehiclesName();

    let dateRangePicker = {
      selection: {
        startDate: subDays(new Date(), 2),
        endDate: new Date(),
        startTime: moment(),
        endTime: moment(),
        key: 'selection',
      },
    };
    if (this.props.interval && this.props.interval.startDate) {
      dateRangePicker = { selection: this.props.interval }
    }

    this.setState({
      ...this.state,
      cellSites: data.cellSites,
      vehicles: data.vehicles.filter(item => {return !item.isAdminVehicle}),
      dateRangePicker,
    });
  }

  toggle() {
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  handleChange(which, payload) {

    this.setState({
      [which]: payload,
    });
  }

  handleRangeChange(which, payload) {

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }

  isTabActive = name => {
    return name === this.state.activeTab;
  };

  handleTabClick = name => {
    this.setState({
      ...this.state,
      activeTab: name
    })
  };

  handleSelect(range) {
    // An object with two keys,
    // 'startDate' and 'endDate' which are Momentjs objects.
  }

  selectCellSitesAndRange() {
    // if (this.props.reportType === 'rescueCellsite' && !this.state.selectedCellSites.length) {
    //   return toastr.error('Please select cellsites');
    // }
    if (this.props.reportType === 'rosterLog' && !this.state.selectedVehicles.length) {
      return toastr.error('Please select vehicles');
    }
    const cellSites = map(this.state.selectedCellSites, item => { return item.value });
    const vehicles = map(this.state.selectedVehicles, item => { return item.value });
    this.props.selectCellSitesAndRange(cellSites, vehicles, this.state.dateRangePicker.selection)
  }



  back() {
    this.props.switchStep(1, this.state.selectedCellSites, this.state.dateRangePicker.selection);
  }
  searchCellSite = (value) => {
    let selectAll = false;
    forEach(value, item => {
      if (item.value === 'ALL') {
        selectAll = true;
      }
    });

    if (selectAll) {
      const selectedCellSites = this.state.cellSites.map(item => {
        return {
          value: item._id,
          label: item.name
        }
      });
      this.setState({
        ...this.state,
        selectedCellSites
      });
    } else {
      this.setState({
        ...this.state,
        selectedCellSites: value,
      });
    }

  }
  searchVehicles = (value) => {
    let selectAll = false;
    forEach(value, item => {
      if (item.value === 'ALL') {
        selectAll = true;
      }
    });

    if (selectAll) {
      const selectedVehicles = this.state.vehicles.map(item => {
        return {
          value: item._id,
          label: item.plateNumber
        }
      });
      this.setState({
        ...this.state,
        selectedVehicles
      });
    } else {
      this.setState({
        ...this.state,
        selectedVehicles: value,
      });
    }

  }

  render() {
    return (

      <div>
        <div>
          <ul className='reports-list'>
            <li className='interval-report'>
              <span>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.back.bind(this)}
                >
                  Previous
                </button>
              </span>

              <span className='text-center interval-icon'>
                <h4>Interval</h4>
                <p>Select interval for the report</p>
              </span>

              <span className='select-report'>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.selectCellSitesAndRange.bind(this)}
                >
                  Export
                </button>
              </span>
            </li>
          </ul>
        </div>


        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-6'>
            <div>
              <div className='form-group'>
                {this.props.reportType === 'rosterLog' ?
                  <Select
                    name='form-field-name'
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    placeholder='Select Vehicles'
                    onChange={this.searchVehicles}
                    value={this.state.selectedVehicles}
                    options={[{
                      value: 'ALL',
                      label: 'Select all'
                    }, ...this.state.vehicles.map(item => {
                      return {
                        value: item._id,
                        label: item.plateNumber
                      }
                    })]}
                  /> :
                  <Select
                    name='form-field-name'
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    placeholder='Select CellSite'
                    onChange={this.searchCellSite}
                    value={this.state.selectedCellSites}
                    options={this.state.cellSites.map(item => {
                      return {
                        value: item._id,
                        label: item.name
                      }
                    })}
                  />
                }

              </div>
            </div>

          </div>


          <div className='col-lg-3 col-md-3 col-sm-4'>
            <label>Select Interval</label>
            <div>
              <a onClick={this.toggle} id='Popover1'>
                <img src={calenderIcon} alt='' />
              </a>
              <Popover placement='bottom' isOpen={this.state.popoverOpen} target='Popover1' toggle={this.toggle}>
                <PopoverBody>
                  <div>
                    <DateRangePicker
                      onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      className={'PreviewArea'}
                      months={2}
                      ranges={[this.state.dateRangePicker.selection]}
                      direction='horizontal'
                      maxDate={new Date()}
                    />
                  </div>
                </PopoverBody>
              </Popover>
            </div>

            <div className='selected-Interval'>
              <p><b>From:</b> {moment(this.state.dateRangePicker.selection.startDate).format('MMMM Do YYYY')}</p>
              <p><b>To:</b> {moment(this.state.dateRangePicker.selection.endDate).format('MMMM Do YYYY')}</p>
            </div>
          </div>





        </div>
      </div>
    );
  }
}

ReportInterval.propTypes = {};
ReportInterval.defaultProps = {};

const mapStateToProps = state => ({
  groupsAndVehicles: state.groups.groupsAndVehicles,
  geoFences: state.geoFence.geoFences,
  routeFences: state.routeFence.routeFences,
  groupList: state.groups.subGroups
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Patrolling.creators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportInterval);
