import React, {useState, useEffect} from "react";
import Modal from "react-bootstrap4-modal";
import MarkerMap from "./markerMap";

const MapModal = (props) => {

  return (


    <Modal visible={props.showMap}>
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={props.close}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
       
        <MarkerMap
          key={parseFloat(props.lat)}
          center={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }}
          radius={300}
        ></MarkerMap>
      </div>
</Modal>
  );
};

export default MapModal;
