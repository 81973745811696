import React, { Component } from "react";
import { bindActionCreators } from "redux";
import axios from "axios";
import { connect } from "react-redux";
import { clone, map, filter, forEach, cloneDeep, split, uniq } from "lodash";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import Select from "react-select-nested-group";
import { Element, scroller } from "react-scroll";
import Stepper from "react-stepper-horizontal";
import Patrolling from "../../../ducks/Patrolling";
import { Spinner, WhiteSpinner } from "../../common/Spinner/index";
import { PatrollingMap } from "../../common/maps/PatrollingMap";
import { Loader } from "google-maps";
import pointerIcon from "../../../assets/img/ic_speedometer_grey.png";
import pin_moving from "../../../assets/img/pin_moving.png";
import pin_ignition from "../../../assets/img/pin_ignition.png";
import pin_idle_shadow from "../../../assets/img/pin_idle_shadow.png";
import pin_pluggedout from "../../../assets/img/pin_pluggedout.png";
import pin_parked from "../../../assets/img/pin_parked.png";
import last_updated from "../../../assets/img/last_updated.png";
import CellTower from "../../../assets/img/tower.png";
import driverIcon from "../../../assets/img/driverIcon.jpeg";
import toastr from "toastr";
import Modal from "react-bootstrap4-modal";
import socketIOClient from "socket.io-client";
import { socketAuth, SOCKET_URL } from "../../../constants";
import { groupEndPoints } from "../../../api/endpoints";
import { MqexIot } from "../../../utils/mqex-sdk.js";
import * as _async from "async";
import * as geolib from "geolib";

const steps = [
  { title: "Start Rescue" },
  { title: "Select Route" },
  { title: "Initiate Rescue" },
];
let _google = {};
let directionsRenderer = {};

class PatrollingDashboard extends Component {
  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    this.state = {
      selectedGroup: [],
      selectedStatus: null,
      isSettingsVisible: false,
      defaultSearchValue: null,
      defaultCellSiteValue: null,
      searchTerm: "",
      defaultCenter: {
        lat: 30.1575,
        lng: 71.5249,
      },
      vehicleLocation: {
        lat: 30.1575,
        lng: 71.5249,
      },
      zoom: 5.5,
      vehicleNumberPlate: "",
      loading: true,
      selectedVehicle: null,
      vehicles: [],
      allVehicles: [],
      closestVehicles: [],
      cellSites: [],
      nearestCellsites: [],
      directions: null,
      directionVehicle: null,
      vehicleRescueStep: 0,
      vehiclesAndRouteSelected: [],
      activeTab: "1",
      pathRender1: [],
      pathRender2: [],
      rescueCellSitesInProgress: [],
      inProgressAdmin: [],
      selectedRescueCellSitesInProgress: null,
      showCancelConfirmation: false,
      cancellingRecord: false,
      recordToCancelRescue: {},
      defaultVehicleStatuses: { value: "ALL", label: "All" },
      showVehiclesOnMap: true,
      showCellSitesOnMap: false,
      showTrafficLayerOnMap: true,
      showLabelOnMap: false,
      updateOnMqtt: false,
      startingRescue: false,
      dc_loader: false,
      groupId: companyInfo ? companyInfo.groupId : "",
      loadingDirections: false,
      showDriverModal: false,
      driverInfo: null,
      selectedVehicleForDriver: {},
      loadingDriverInfo: false,
      selectedNearestCellsite: null,
    };
  }

  getMqtt = async () => {
    let mqttCache = {};
   
  }

  changeVehicleStatus = (item, type) => {
    switch (type) {
      case "ignitionOn":
        item.vehicleStatus = "IGNITION_ON";
        break;
      case "vehicleMoving":
        item.vehicleStatus = "VEHICLE_MOVING";
        break;
      case "vehicleParked":
        item.vehicleStatus = "IGNITION_OFF";
        break;
      case "vehicleOffline":
        item.vehicleStatus = "DEVICE_UNPLUG";
        break;
      case "vehicleIdle":
        item.vehicleStatus = "IDLE";
        break;
      default:
        break;
    }
    return item;
  };
  subscribeNotifications = async () => {
    try {
      const userId = localStorage.getItem("user_id");
      let socket = socketIOClient(SOCKET_URL + "/mic-webhooks-notifications", {
        path: "/socket.io",
        transports: ["websocket"],
        query: {
          token: socketAuth,
        },
      });

      //const user = await this.props.actions.getUser(userId);

      // if(user && user.userType === "Manager"){
      //   socket.on("connect", () => {
      //     socket.emit("room", { user });
      // });
      // }

      socket.on("connect", () => {
          socket.emit("room", { userId });
      });

      socket.on("reconnect_attempt", () => {
        socket.io.opts.transports = ["polling", "websocket"];
      });
      socket.on("vehicleStatusChanged", (data) => {
        try {
          const { thname, type } = data;
          let vehicles = map(this.state.vehicles, (vehicle) => {
            if (vehicle.name === thname) {
              vehicle = this.changeVehicleStatus(vehicle, type);
            }
            return vehicle;
          });
          let allVehicles = map(this.state.allVehicles, (vehicle) => {
            if (vehicle.name === thname) {
              vehicle = this.changeVehicleStatus(vehicle, type);
            }
            return vehicle;
          });
          this.setState({
            ...this.state,
            vehicles,
            allVehicles,
          });
        } catch (err) {
          console.log(err);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  async componentDidMount() {
    const mapLoader = new Loader("AIzaSyAiv7I9jK0DW9yV4RvaQtpkjJh3-57F7_M", {});
    _google = await mapLoader.load();
    if (this.state.groupId !== "") {
      const data = await this.props.actions.listCellSitesAndVehicles();
      const response = await this.props.actions.getInProgressrescueCellSite();

      await this.setState({
        ...this.state,
        loading: false,
        vehicles: data.vehicles,
        allVehicles: data.vehicles,
        cellSites: data.cellSites,
        rescueCellSitesInProgress: response.inProgress,
        inProgressAdmin: response.inProgressAdmin,
      });
      this.getMqtt();
      this.subscribeNotifications();
    }
  }

  displayRoutes = (data) => {
    const closestVehicles = data.sort(
      (a, b) => a.vehicle.routeDistance - b.vehicle.routeDistance
    );

    this.setState({
      ...this.state,
      loadingDirections: false,
      closestVehicles,
      directions: closestVehicles[0].result,
      vehicleRescueStep: 1,
      directionVehicle: closestVehicles[0].vehicle,
      defaultSearchValue: {
        value: closestVehicles[0].vehicle._id,
        label: closestVehicles[0].vehicle.plateNumber,
      },
    });
  };

  skipVehicle(id) {
    let directionVehicle = null;
    let defaultSearchValue = null;
    let directions = null;
    const closestVehicles = filter(
      this.state.closestVehicles,
      (item, index) => {
        if (item.vehicle._id === id) {
          let nextIndex = index + 1;
          if (
            this.state.closestVehicles[nextIndex] &&
            this.state.closestVehicles[nextIndex].result
          ) {
            directionVehicle = this.state.closestVehicles[nextIndex].vehicle;
            directions = this.state.closestVehicles[nextIndex].result;
            defaultSearchValue = {
              value: this.state.closestVehicles[nextIndex].vehicle._id,
              label: this.state.closestVehicles[nextIndex].vehicle.plateNumber,
            };
            return false;
          }
          return true;
        } else {
          return true;
        }
      }
    );
    if (directionVehicle) {
      this.setState({
        ...this.state,
        closestVehicles,
        directionVehicle,
        directions,
        defaultSearchValue,
      });
    }
  }

  assign() {
    const selectedIndex = directionsRenderer.getRouteIndex() || 0;
    const vehiclesAndRouteSelected = this.state.vehiclesAndRouteSelected;
    vehiclesAndRouteSelected.push({
      vehicleAndRoute: this.state.closestVehicles[
        this.state.vehicleRescueStep - 1
      ],
      routeIndex: selectedIndex,
    });
    this.setState({
      ...this.state,
      vehiclesAndRouteSelected,
    });
    if (this.state.vehicleRescueStep < 2 && this.state.closestVehicles[1]) {
      this.setState({
        ...this.state,
        directions: this.state.closestVehicles[1].result,
        vehicleRescueStep: 2,
        directionVehicle: this.state.closestVehicles[1].vehicle,
        defaultSearchValue: {
          value: this.state.closestVehicles[1].vehicle._id,
          label: this.state.closestVehicles[1].vehicle.plateNumber,
        },
      });
    }
    if (this.state.vehicleRescueStep === 2 || !this.state.closestVehicles[1]) {
      this.setState({
        ...this.state,
        vehicleRescueStep: 3,
      });
    }
  }

  getVehicleDriverInfo(selectedThing) {
    try {
      setTimeout(async () => {
        this.setState({
          ...this.state,
          showDriverModal: true,
          driverInfo: null,
          loadingDriverInfo: true,
        });
        const driver = await this.props.actions.getVehicleDriver(
          selectedThing.value
        );
        this.setState({
          ...this.state,
          driverInfo: driver.name ? driver : null,
          selectedVehicleForDriver: selectedThing,
          loadingDriverInfo: false,
          showDriverModal: true,
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  async doneResue() {
    if (
      !this.state.vehiclesAndRouteSelected ||
      !this.state.vehiclesAndRouteSelected.length
    ) {
      return toastr.error("No vehicle assigned");
    }
    try {
      this.setState({
        ...this.state,
        startingRescue: true,
      });
      const rescueCellSitesInProgress = await this.props.actions.rescueCellSite(
        {
          vehiclesAndRouteSelected: this.state.vehiclesAndRouteSelected,
          cellSite: this.state.cellSites[0]._id,
        }
      );

      toastr.success("Started Rescue Mission!");
      this.setState({
        ...this.state,
        vehiclesAndRouteSelected: [],
        closestVehicles: [],
        directions: null,
        directionVehicle: null,
        vehicleRescueStep: 0,
        startingRescue: false,
        rescueCellSitesInProgress: [
          ...this.state.rescueCellSitesInProgress,
          rescueCellSitesInProgress,
        ],
      });
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  }

  skipCurrent() {
    if (this.state.vehicleRescueStep === 1 && this.state.closestVehicles[1]) {
      this.setState({
        ...this.state,
        directions: this.state.closestVehicles[1].result,
        vehicleRescueStep: 2,
        directionVehicle: this.state.closestVehicles[1].vehicle,
        defaultSearchValue: {
          value: this.state.closestVehicles[1].vehicle._id,
          label: this.state.closestVehicles[1].vehicle.plateNumber,
        },
      });
    }
    if (this.state.vehicleRescueStep === 2 || !this.state.closestVehicles[1]) {
      this.setState({
        ...this.state,
        vehicleRescueStep: 3,
      });
    }
  }
  cancelRescue() {
    this.setState({
      ...this.state,
      vehiclesAndRouteSelected: [],
      closestVehicles: [],
      directions: null,
      directionVehicle: null,
      vehicleRescueStep: 0,
    });
  }

  async rescueCellSite() {
    const _directionsService = new _google.maps.DirectionsService();

    await this.setState({ ...this.state, loadingDirections: true });
    const routes = [];
    const cellSite = this.state.cellSites[0];
    const vehicles = filter(this.state.vehicles, (item) => {
      let returnValue = true;
      forEach(this.state.rescueCellSitesInProgress, (site) => {
        if (
          (site.vehicleOne && site.vehicleOne._id === item._id) ||
          (site.vehicleTwo && site.vehicleTwo._id === item._id)
        ) {
          returnValue = false;
        }
      });
      forEach(this.state.inProgressAdmin, (site) => {
        if (
          (site.vehicleOne && site.vehicleOne._id === item._id) ||
          (site.vehicleTwo && site.vehicleTwo._id === item._id)
        ) {
          returnValue = false;
        }
      });
      if (
        item.vehicleStatus === "DEVICE_UNPLUG" ||
        item.vehicleStatus === "NEVER_HEARD" ||
        item.vehicleStatus === "NOT_RESPONDING"
      ) {
        returnValue = false;
      }
      return returnValue;
    });

    if (vehicles && vehicles.length) {
      forEach(vehicles, (item) => {
        item.distance = null;
        const distance = geolib.getPreciseDistance(
          {
            latitude: parseFloat(cellSite.cords.lat).toFixed(6),
            longitude: parseFloat(cellSite.cords.lng).toFixed(6),
          },
          {
            latitude: parseFloat(item.lastLocation.lat).toFixed(6),
            longitude: parseFloat(item.lastLocation.lng).toFixed(6),
          }
        );
        item.distance = distance;
      });
      const closestVehicles = vehicles
        .sort((a, b) => a.distance - b.distance)
        .slice(0, 10);

      const m_get_directions_route = (items) => {
        return new Promise(async (resolve, reject) => {
          _async.map(
            items,
            (element, cb) => {
              _directionsService.route(
                {
                  origin: new _google.maps.LatLng(
                    element.lastLocation.lat,
                    element.lastLocation.lng
                  ),
                  destination: new _google.maps.LatLng(
                    cellSite.cords.lat,
                    cellSite.cords.lng
                  ),
                  unitSystem: _google.maps.UnitSystem.METRIC,
                  travelMode: _google.maps.TravelMode.DRIVING,
                  provideRouteAlternatives: true,
                },
                (result, status) => {
                  if (status === _google.maps.DirectionsStatus.OK) {
                    forEach(result.routes, (route) => {
                      element.routeDistance = null;
                      forEach(route.legs, (leg) => {
                        if (
                          !element.routeDistance ||
                          leg.distance.value < element.routeDistance
                        ) {
                          element.routeDistance = leg.distance.value;
                        }
                      });
                    });
                    routes.push({ vehicle: element, result });
                  } else {
                    console.error(`error fetching directions ${result}`);
                  }
                  cb();
                }
              );
            },
            () => {
              resolve();
            }
          );
        });
      };
      await m_get_directions_route(closestVehicles);

      await this.setState({ ...this.state, loadingDirections: false });
      this.displayRoutes(routes);
    } else {
      this.setState({ ...this.state, loadingDirections: false });
      toastr.error("No vehicles available in this cellsite region!");
    }
  }

  searchVehicle = async (selectedOption) => {
    const thingId = selectedOption ? selectedOption.value : null;

    if (thingId) {
      let things = clone(this.state.vehicles);
      let vehicleLocation = filter(things, (item) => {
        if (item._id === thingId) return item;
      });
      if (vehicleLocation.length) {
        this.setState({
          ...this.state,
          vehicleLocation: {
            lat: vehicleLocation[0].latitude,
            lng: vehicleLocation[0].longitude,
          },
          defaultCenter: {
            lat: vehicleLocation[0].latitude,
            lng: vehicleLocation[0].longitude,
          },
          defaultSearchValue: selectedOption,
          zoom: 15,
          vehicleNumberPlate: selectedOption.value,
          updateOnMqtt: true,
        });

        scroller.scrollTo(thingId, {
          duration: 1500,
          smooth: true,
          containerId: "containerElement",
          offset: -120,
        });
      }
    } else {
      this.setState({
        ...this.state,
        searchTerm: "",
        defaultCenter: {
          lat: 30.1575,
          lng: 71.5249,
        },
        vehicleLocation: {
          lat: 30.1575,
          lng: 71.5249,
        },
        zoom: 5.5,
        vehicleNumberPlate: "",
        selectedVehicle: null,
        defaultSearchValue: null,
        updateOnMqtt: false,
      });
    }
  };
  selectVehicleStatus = async (selectedOption) => {
    const option = selectedOption ? selectedOption.value : null;

    let vehicles = this.state.allVehicles;
    if (this.state.defaultCellSiteValue) {
      vehicles = filter(this.state.allVehicles, (item) => {
        if (item.isAdminVehicle) {
          return true;
        }
        return item.group.indexOf(this.state.defaultCellSiteValue.group) !== -1;
      });
    }
    if (option === "ALL") {
      this.setState({
        ...this.state,
        vehicles: vehicles,
        defaultVehicleStatuses: selectedOption,
      });
    }
    if (option === "ON_DUTY") {
      this.setState({
        ...this.state,
        vehicles: vehicles.filter((item) => {
          if (item.isOnDuty || item.isAdminVehicle) {
            return true;
          }
          return false;
        }),
        defaultVehicleStatuses: selectedOption,
      });
    }
    if (option === "OFF_DUTY") {
      this.setState({
        ...this.state,
        vehicles: vehicles.filter((item) => {
          if (!item.isOnDuty || item.isAdminVehicle) {
            return true;
          }
          return false;
        }),
        defaultVehicleStatuses: selectedOption,
      });
    }
  };
  searchCellSite = async (selectedOption, all) => {
    const siteId = selectedOption ? selectedOption.value : null;
    if (siteId) {
      let sites = clone(this.props.cellSites);

      let site = filter(sites, (item) => {
        if (item._id === siteId) return item;
      });

      if (site.length) {
        const vehicles = filter(this.state.allVehicles, (item) => {
          if (item.isAdminVehicle) {
            return true;
          }
          if (all) {
            return item.group.indexOf(site[0].group) !== -1;
          }
          return item.group.indexOf(site[0].group) !== -1 && item.isOnDuty;
        });
        const nearestCellsites = [];
        forEach(sites, (item) => {
          if (item._id !== site[0]._id) {
            item.distance = null;
            const distance = geolib.getPreciseDistance(
              {
                latitude: parseFloat(site[0].cords.lat).toFixed(6),
                longitude: parseFloat(site[0].cords.lng).toFixed(6),
              },
              {
                latitude: parseFloat(item.cords.lat).toFixed(6),
                longitude: parseFloat(item.cords.lng).toFixed(6),
              }
            );
            item.distance = parseFloat(distance / 1000).toFixed(2);
            if (distance <= 10000) {
              nearestCellsites.push(item);
            }
          }
        });
        await this.setState({
          ...this.state,
          vehicleLocation: {
            lat: parseFloat(site[0].cords.lat),
            lng: parseFloat(site[0].cords.lng),
          },
          defaultCellSiteValue: selectedOption,
          vehicles,
          selectedNearestCellsite: null,
          nearestCellsites,
          cellSites: [...site, ...nearestCellsites],
          defaultVehicleStatuses: all
            ? { value: "ALL", label: "All" }
            : { value: "ON_DUTY", label: "On Duty" },
          zoom: 15,
          updateOnMqtt: false,
        });
      }
    } else {
      await this.setState({
        ...this.state,
        searchTerm: "",
        defaultCenter: {
          lat: 30.1575,
          lng: 71.5249,
        },
        vehicleLocation: {
          lat: 30.1575,
          lng: 71.5249,
        },
        zoom: 5.5,
        selectedNearestCellsite: null,
        vehicles: this.state.allVehicles,
        cellSites: this.props.cellSites,
        defaultCellSiteValue: null,
        updateOnMqtt: false,
        nearestCellsites: [],
        defaultVehicleStatuses: { value: "ALL", label: "All" },
      });
    }
  };
  selectNearestCellsite = async (item) => {
    await this.setState({
      ...this.state,
      vehicleLocation: {
        lat: parseFloat(item.cords.lat),
        lng: parseFloat(item.cords.lng),
      },
      selectedNearestCellsite: item,
      zoom: 15,
      updateOnMqtt: false,
    });
  };

  selectStatus = (selectedOption) =>
    this.setState({ selectedStatus: selectedOption });

  onCenterChanged(map) {
    this.setState({
      ...this.state,
      vehicleLocation: {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      },
    });
  }

  confirmCancelRescue(item) {
    this.setState({
      showCancelConfirmation: true,
      cancellingRecord: false,
      recordToCancelRescue: item,
      dc_loader: false,
    });
  }
  confirmCompleteRescue(item) {
    this.setState({
      showCompleteConfirmation: true,
      cancellingRecord: false,
      recordToCancelRescue: item,
      dc_loader: false,
    });
  }

  getDirectionsRenderer = (directionsRendererObj) => {
    directionsRenderer = directionsRendererObj;
  };

  cancelModal = () => {
    this.setState({
      showCancelConfirmation: false,
      showCompleteConfirmation: false,
      cancellingRecord: false,
      showDriverModal: false,
      recordToCancelRescue: {},
    });
  };
  cancelInprogressRescue = async () => {
    try {
      this.setState({ ...this.state, dc_loader: true });

      await this.props.actions.cancelrescueCellSite(
        this.state.recordToCancelRescue._id
      );
      const data = filter(this.state.rescueCellSitesInProgress, (item) => {
        return item._id !== this.state.recordToCancelRescue._id;
      });
      await this.setState({
        ...this.state,
        rescueCellSitesInProgress: data,
        recordToCancelRescue: {},
        dc_loader: false,
      });
      this.clearSelectedData();
      this.cancelModal();
      toastr.success("Rescue cancelled successfully!");
    } catch (err) {
      console.log(err);
    }
  };
  completeInprogressRescue = async () => {
    try {
      this.setState({ ...this.state, dc_loader: true });

      await this.props.actions.completeRescueCellSite(
        this.state.recordToCancelRescue._id
      );
      const data = filter(this.state.rescueCellSitesInProgress, (item) => {
        return item._id !== this.state.recordToCancelRescue._id;
      });
      await this.setState({
        ...this.state,
        rescueCellSitesInProgress: data,
        recordToCancelRescue: {},
        dc_loader: false,
      });
      this.clearSelectedData();
      this.cancelModal();
      toastr.success("Rescue completed successfully!");
    } catch (err) {
      console.log(err);
    }
  };
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ ...this.state, activeTab: tab });
    }
  };
  selectCellSiteRescuing = async (site) => {
    await this.setState({
      ...this.state,
      selectedRescueCellSitesInProgress: site,
    });
    this.searchCellSite(
      { value: site.cellSite._id, label: site.cellSite.name },
      true
    );
  };
  clearSelectedData = async () => {
    await this.setState({
      ...this.state,
      searchTerm: "",
      defaultCenter: {
        lat: 30.1575,
        lng: 71.5249,
      },
      vehicleLocation: {
        lat: 30.1575,
        lng: 71.5249,
      },
      updateOnMqtt: false,
      zoom: 5.5,
      vehicles: this.state.allVehicles,
      cellSites: this.props.cellSites,
      defaultCellSiteValue: null,
      selectedRescueCellSitesInProgress: null,
    });
  };

  changeCheckValue = (target) => {
    if (target.name === "vehicles") {
      this.setState({
        ...this.state,
        showVehiclesOnMap: !this.state.showVehiclesOnMap,
      });
    } else if (target.name === "cellSites") {
      this.setState({
        ...this.state,
        showCellSitesOnMap: !this.state.showCellSitesOnMap,
      });
    } else if (target.name === "trafficLayer") {
      this.setState({
        ...this.state,
        showTrafficLayerOnMap: !this.state.showTrafficLayerOnMap,
      });
    } else if (target.name === "label") {
      this.setState({
        ...this.state,
        showLabelOnMap: !this.state.showLabelOnMap,
      });
    }
  };

  render() {
    let loader = <Spinner key="1" />;
    const state = this.state;
    const {
      activeTab,
      showCancelConfirmation,
      showDriverModal,
      showCompleteConfirmation,
    } = state;
    const smallLoader = <WhiteSpinner key="1" />;

    return (
      <div>
        {showDriverModal ? (
          <Modal
            visible={showDriverModal}
            dialogClassName="delete-modal-dialog"
          >
            <div>
              <button
                type="button"
                className="close close-x"
                aria-label="Close"
                onClick={this.cancelModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {state.loadingDriverInfo ? (
              [loader]
            ) : (
              <>
                <div className="modal-heading">
                  <h5 className="car-and-device-assig">
                    {state.selectedVehicleForDriver.label}
                  </h5>
                </div>
                <div className="modal-body">
                  <h6>
                    Driver Name:{" "}
                    {state.driverInfo ? state.driverInfo.name : "N/A"}
                  </h6>
                  <h6>
                    Serial Number:{" "}
                    {state.driverInfo ? state.driverInfo.serialNumber : "N/A"}
                  </h6>
                </div>
                <div className="modal-footer model-btn">
                  <button
                    className="btn auxo-primary-btn"
                    onClick={this.cancelModal}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </Modal>
        ) : (
          ""
        )}

        {showCancelConfirmation || showCompleteConfirmation ? (
          <Modal visible={true} dialogClassName="delete-modal-dialog">
            <div>
              <button
                type="button"
                className="close close-x"
                aria-label="Close"
                onClick={this.cancelModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-heading">
              <h5 className="car-and-device-assig">
                {showCompleteConfirmation ? "Complete" : "Cancel"} Rescue?
              </h5>
            </div>
            <div className="modal-body">
              <h6>
                Are you sure you want to{" "}
                {showCompleteConfirmation ? "Complete" : "Cancel"} Rescue?
              </h6>
            </div>
            <div className="modal-footer model-btn">
              <button
                className="btn auxo-primary-btn"
                onClick={this.cancelModal}
              >
                Close
              </button>
              {showCompleteConfirmation ? (
                <button
                  className="btn auxo-primary-btn"
                  onClick={this.completeInprogressRescue}
                >
                  {this.state.dc_loader ? [smallLoader] : `Confirm`}
                </button>
              ) : (
                <button
                  className="btn auxo-primary-btn"
                  onClick={this.cancelInprogressRescue}
                >
                  {this.state.dc_loader ? [smallLoader] : `Confirm`}
                </button>
              )}
            </div>
          </Modal>
        ) : (
          ""
        )}

        {state.loading ? (
          [loader]
        ) : (
          <div>
            <div className="row">
              <div className="col-md-9">
                <div className="m-b-30 patrolling-section">
                  {state.defaultCellSiteValue &&
                  state.defaultCellSiteValue.value ? (
                    <>
                      {!state.selectedRescueCellSitesInProgress && (
                        <div className="stepper-container">
                          <Stepper
                            activeStep={state.vehicleRescueStep}
                            steps={steps}
                            circleFontSize={13} titleFontSize={13}
                          />
                        </div>
                      )}
                      <div className="m-l-33">
                        {state.selectedRescueCellSitesInProgress ? (
                          <div className="patrolling-map-filters">
                            <div className="filter">
                              <input
                                type="checkbox"
                                name={"vehicles"}
                                checked={state.showVehiclesOnMap}
                                onChange={(e) => {
                                  const { target } = e;
                                  this.changeCheckValue(target);
                                }}
                                value={state.isPatrollingVehicle}
                              />{" "}
                              Vehicles
                            </div>
                            <div className="filter">
                              <input
                                type="checkbox"
                                name={"cellSites"}
                                checked={state.showCellSitesOnMap}
                                onChange={(e) => {
                                  const { target } = e;
                                  this.changeCheckValue(target);
                                }}
                                value={state.isPatrollingVehicle}
                              />{" "}
                              CellSites
                            </div>
                            <div className="filter">
                              <input
                                type="checkbox"
                                name={"trafficLayer"}
                                checked={state.showTrafficLayerOnMap}
                                onChange={(e) => {
                                  const { target } = e;
                                  this.changeCheckValue(target);
                                }}
                                value={state.isPatrollingVehicle}
                              />{" "}
                              Traffic Layer
                            </div>
                            <div className="filter">
                              <input
                                type="checkbox"
                                name={"label"}
                                checked={state.showLabelOnMap}
                                onChange={(e) => {
                                  const { target } = e;
                                  this.changeCheckValue(target);
                                }}
                                value={state.isPatrollingVehicle}
                              />{" "}
                              Label
                            </div>
                          </div>
                        ) : (
                          <>
                            {!state.directionVehicle &&
                              !this.state.loadingDirections && (
                                <span className="m-l-50">
                                  <button
                                    className="btn btn-success"
                                    onClick={this.rescueCellSite.bind(this)}
                                  >
                                    Rescue
                                  </button>
                                </span>
                              )}
                          </>
                        )}
                        {state.directionVehicle && (
                          <span>
                            <button
                              className="btn btn-danger"
                              onClick={this.cancelRescue.bind(this)}
                            >
                              Cancel
                            </button>
                          </span>
                        )}
                        {state.directionVehicle && (
                          <>
                            {state.vehicleRescueStep < 3 && (
                              <span>
                                <p className="m-r-l-20 display-inline">
                                  <b>
                                    Select Route for vehicle:{" "}
                                    {state.directionVehicle.plateNumber}
                                  </b>
                                </p>
                              </span>
                            )}
                            {state.vehicleRescueStep < 3 && (
                              <span>
                                <button
                                  className="btn btn-success m-l-20"
                                  onClick={this.assign.bind(this)}
                                >
                                  Assign
                                </button>
                              </span>
                            )}
                            {state.vehicleRescueStep < 3 &&
                              state.closestVehicles.length !== 1 && (
                                <span>
                                  {" "}
                                  <button
                                    className="btn btn-danger  m-l-20"
                                    onClick={this.skipCurrent.bind(this)}
                                  >
                                    Skip
                                  </button>
                                </span>
                              )}
                            {state.vehicleRescueStep === 3 && (
                              <span>
                                <button
                                  className="btn btn-success m-l-20"
                                  onClick={this.doneResue.bind(this)}
                                >
                                  {this.state.startingRescue
                                    ? [smallLoader]
                                    : "Initiate"}
                                </button>
                              </span>
                            )}
                            {state.vehicleRescueStep < 3 && (
                              <span>
                                <button
                                  className="btn btn-success m-l-20"
                                  onClick={this.skipVehicle.bind(
                                    this,
                                    state.directionVehicle._id
                                  )}
                                >
                                  Change Vehicle
                                </button>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="patrolling-map-filters">
                      <div className="filter">
                        <input
                          type="checkbox"
                          name={"vehicles"}
                          checked={state.showVehiclesOnMap}
                          onChange={(e) => {
                            const { target } = e;
                            this.changeCheckValue(target);
                          }}
                          value={state.isPatrollingVehicle}
                        />{" "}
                        Vehicles
                      </div>
                      <div className="filter">
                        <input
                          type="checkbox"
                          name={"cellSites"}
                          checked={state.showCellSitesOnMap}
                          onChange={(e) => {
                            const { target } = e;
                            this.changeCheckValue(target);
                          }}
                          value={state.isPatrollingVehicle}
                        />{" "}
                        CellSites
                      </div>
                      <div className="filter">
                        <input
                          type="checkbox"
                          name={"trafficLayer"}
                          checked={state.showTrafficLayerOnMap}
                          onChange={(e) => {
                            const { target } = e;
                            this.changeCheckValue(target);
                          }}
                          value={state.isPatrollingVehicle}
                        />{" "}
                        Traffic Layer
                      </div>
                      <div className="filter">
                        <input
                          type="checkbox"
                          name={"label"}
                          checked={state.showLabelOnMap}
                          onChange={(e) => {
                            const { target } = e;
                            this.changeCheckValue(target);
                          }}
                          value={state.isPatrollingVehicle}
                        />{" "}
                        Label
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div>
                  <div className="form-group">
                    <Select
                      name="form-field-name"
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Search CellSite"
                      onChange={this.searchCellSite}
                      value={state.defaultCellSiteValue}
                      options={this.props.cellSites.map((item) => {
                        return {
                          value: item._id,
                          label: item.name,
                          group: item.group,
                        };
                      })}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <Select
                      name="form-field-name"
                      isClearable={false}
                      isSearchable={false}
                      placeholder="Vehicles Status"
                      onChange={this.selectVehicleStatus}
                      value={state.defaultVehicleStatuses}
                      options={[
                        {
                          value: "ALL",
                          label: "All",
                        },
                        {
                          value: "ON_DUTY",
                          label: "On Duty",
                        },
                        {
                          value: "OFF_DUTY",
                          label: "Off Duty",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 form-group">
                    <Select
                      name="form-field-name"
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Search Vehicle"
                      onChange={this.searchVehicle}
                      value={state.defaultSearchValue}
                      options={state.vehicles.map((item) => {
                        return {
                          value: item._id,
                          label: item.plateNumber || item.imei.substr(-8),
                        };
                      })}
                    />
                  </div>
                  <div className="">
                    {/* driverIcon */}
                    {state.defaultSearchValue && (
                      <a
                        onClick={this.getVehicleDriverInfo.bind(
                          this,
                          state.defaultSearchValue
                        )}
                        className="driver-icon"
                      >
                        {" "}
                        <img
                          className="inline-block pin-icon-list"
                          src={driverIcon}
                          alt=""
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.loadingDirections ? (
              [loader]
            ) : (
              <div className="row">
                <div className="col-md-9 cluster-map-view">
                  <div>
                    <PatrollingMap
                      markers={state.vehicles}
                      cellSites={state.cellSites}
                      zoom={state.zoom}
                      center={{
                        lat: state.vehicleLocation.lat,
                        lng: state.vehicleLocation.lng,
                      }}
                      defaultCenter={{
                        lat: state.defaultCenter.lat,
                        lng: state.defaultCenter.lng,
                      }}
                      onCenterChanged={this.onCenterChanged.bind(this)}
                      onVehicleClick={this.searchVehicle.bind(this)}
                      defaultSearchValue={state.defaultSearchValue}
                      directions={state.directions}
                      getDirectionsRenderer={this.getDirectionsRenderer}
                      selectedRescueCellSitesInProgress={
                        state.selectedRescueCellSitesInProgress
                      }
                      selectedCellSite={
                        state.defaultCellSiteValue ? state.cellSites[0] : null
                      }
                      showVehiclesOnMap={state.showVehiclesOnMap}
                      showCellSitesOnMap={state.showCellSitesOnMap}
                      showTrafficLayerOnMap={state.showTrafficLayerOnMap}
                      showLabelOnMap={state.showLabelOnMap}
                      isPatrolling={true}
                    />
                  </div>
                </div>

                <div className="col-md-3 ">
                  {state.directionVehicle ? (
                    <div
                      id="panel-direction"
                      className="m-t-20 things-listing-with-maps element"
                    ></div>
                  ) : (
                    <>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            Vehicle
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            Rescue
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            Cellsites
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <div
                                className="things-listing-with-maps element"
                                id="containerElement"
                              >
                                {map(state.vehicles, (item) => {
                                  let style = {},
                                    vehicleStatus = item.vehicleStatus,
                                    status = "Unplug",
                                    statusClass = "fa fa-circle",
                                    pinIcon = pin_moving;

                                  switch (vehicleStatus) {
                                    case "IGNITION_ON":
                                      status = "Ignition";
                                      pinIcon = pin_ignition;
                                      statusClass = "fa fa-circle circle-green";
                                      break;
                                    case "VEHICLE_MOVING":
                                      status = "Moving";
                                      pinIcon = pin_moving;
                                      statusClass = "fa fa-circle circle-blue";
                                      break;
                                    case "IGNITION_OFF":
                                      status = "Parked";
                                      pinIcon = pin_parked;
                                      statusClass =
                                        "fa fa-circle circle-orange";
                                      break;
                                    case "DEVICE_UNPLUG":
                                      status = "Unplug";
                                      pinIcon = pin_pluggedout;
                                      statusClass = "fa fa-circle circle-red";
                                      break;
                                    case "DEVICE_PLUG_IN":
                                      status = "Parked";
                                      pinIcon = pin_parked;
                                      statusClass =
                                        "fa fa-circle circle-orange";
                                      break;
                                    case "NEVER_HEARD":
                                      status = "Not Installed";
                                      pinIcon = pin_pluggedout;
                                      statusClass = "fa fa-circle circle-red";
                                      break;
                                    case "NOT_RESPONDING":
                                      status = "Inactive"; 
                                      pinIcon = pin_pluggedout;
                                      statusClass = "fa fa-circle circle-red";
                                      break;
                                    case "IDLE":
                                      status = "Idle";
                                      pinIcon = pin_idle_shadow;
                                      statusClass = "fa fa-circle circle-gray";
                                      style = {
                                        transform: "rotate(200deg)",
                                        marginBottom: "4px",
                                        width: "20px",
                                      };
                                      break;
                                    default:
                                      break;
                                  }
                                  return (
                                    <Element
                                      className={`element section-side ${
                                        state.vehicleNumberPlate === item._id
                                          ? " selected"
                                          : ""
                                      }`}
                                      key={item._id}
                                      name={item._id}
                                      onClick={this.searchVehicle.bind(this, {
                                        value: item._id,
                                        label: item.plateNumber,
                                      })}
                                    >
                                      <p>
                                        <span className="section-icons">
                                          <img
                                            className="inline-block pin-icon-list"
                                            style={style}
                                            src={pinIcon}
                                            alt=""
                                          />
                                        </span>
                                        <span className="textbold">
                                          {item.plateNumber ||
                                            item.imei.substr(-8)}
                                        </span>
                                        <span className="pull-right list-status">
                                          <i className={statusClass} />
                                          {status}
                                        </span>
                                      </p>

                                      {status === "Moving" && (
                                        <p className="vehicle-info">
                                          <span className="section-icons">
                                            <img
                                              src={pointerIcon}
                                              alt=""
                                              className="inline-block"
                                            />
                                          </span>
                                          <span className="simple-txt">
                                            Speed:<span>{item.speed || 0}</span>
                                          </span>
                                        </p>
                                      )}

                                      <p className="vehicle-info">
                                        {!item.isAdminVehicle && (
                                          <span className="simple-txt">
                                            {item.isOnDuty
                                              ? "On Duty"
                                              : "Off Duty"}
                                          </span>
                                        )}
                                        <span className="simple-txt">
                                          {item.isAdminVehicle
                                            ? "Admin Vehicle"
                                            : "Patrolling Vehicle"}
                                        </span>
                                      </p>
                                      <p className="vehicle-info">
                                        <span className="section-icons">
                                          <img
                                            className="section-list-icons"
                                            src={last_updated}
                                            alt=""
                                          />
                                        </span>
                                        <span className="simple-txt">
                                          Last update:
                                          <span>
                                            {item.lastObservationTime
                                              ? moment(
                                                  item.lastObservationTime
                                                ).format("DD/MM/YYYY, h:mm a")
                                              : "N/A"}
                                          </span>
                                        </span>
                                      </p>
                                    </Element>
                                  );
                                })}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <div
                                className="things-listing-with-maps element"
                                id="containerElement"
                              >
                                {map(
                                  state.rescueCellSitesInProgress,
                                  (item) => {
                                    return (
                                      <>
                                        <Element
                                          className={`element section-side ${
                                            state.rescueSelectedCellSite ===
                                            item._id
                                              ? " selected"
                                              : ""
                                          }`}
                                          key={item._id}
                                          name={item._id}
                                          onClick={this.selectCellSiteRescuing.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          <p>
                                            <span className="section-icons">
                                              <img
                                                className="inline-block pin-icon-list"
                                                src={CellTower}
                                                alt=""
                                              />
                                            </span>
                                            <span className="textbold">
                                              {item.cellSite
                                                ? item.cellSite.name
                                                : "N/A"}
                                            </span>
                                            <span className="pull-right list-status">
                                              <i />
                                              {item.vehicleOne &&
                                              item.vehicleTwo
                                                ? "2 vehicles"
                                                : "1 vehicle"}
                                            </span>
                                          </p>

                                          <p className="vehicle-info">
                                            <span className="simple-txt">
                                              Vehicle one:
                                              <span>
                                                {" "}
                                                {item.vehicleOne.plateNumber}
                                              </span>
                                            </span>
                                            <br />
                                            {item.vehicleTwo && (
                                              <span className="simple-txt">
                                                Vehicle two:
                                                <span>
                                                  {" "}
                                                  {item.vehicleTwo.plateNumber}
                                                </span>
                                              </span>
                                            )}
                                          </p>

                                          <p className="vehicle-info">
                                            <span className="section-icons">
                                              <img
                                                className="section-list-icons"
                                                src={last_updated}
                                                alt=""
                                              />
                                            </span>
                                            <span className="simple-txt">
                                              Started at:
                                              <span>
                                                {moment(item.createdAt).format(
                                                  "DD/MM/YYYY, hh:mm a"
                                                )}
                                              </span>
                                            </span>
                                          </p>
                                        </Element>

                                        {state.selectedRescueCellSitesInProgress &&
                                          state
                                            .selectedRescueCellSitesInProgress
                                            ._id === item._id && (
                                            <>
                                              <div className="text-center m-b-10">
                                                <span>
                                                  <button
                                                    className="btn btn-success font-size-9"
                                                    onClick={
                                                      this.clearSelectedData
                                                    }
                                                  >
                                                    Clear Map
                                                  </button>
                                                </span>
                                                <span>
                                                  <button
                                                    className="btn btn-danger m-l-20 font-size-9"
                                                    onClick={() => {
                                                      this.confirmCancelRescue(
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    Cancel
                                                  </button>
                                                </span>
                                                <span>
                                                  <button
                                                    className="btn btn-success m-l-20 font-size-9"
                                                    onClick={() => {
                                                      this.confirmCompleteRescue(
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    Complete
                                                  </button>
                                                </span>
                                              </div>
                                            </>
                                          )}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col sm="12">
                             
                              <div className='things-listing-with-maps element' id='containerElement'>
                                {
                                  map(state.nearestCellsites, (item) => {
                                    return (
                                      <>
                                        <Element
                                          className={`element section-side ${state.selectedNearestCellsite && state.selectedNearestCellsite._id === item._id ? ' selected' : ''}`}
                                          key={item._id}
                                          name={item._id}
                                          onClick={this.selectNearestCellsite.bind(this, item)}
                                        >
                                          <p>
                                            <span className='section-icons'>
                                              <img
                                                className='inline-block pin-icon-list'
                                                src={CellTower}
                                                alt=''
                                              />
                                            </span>
                                            <span className='textbold'>{item ? item.name : 'N/A'}</span>
                                            <span className='pull-right list-status'><i />{item.isGuarded ? 'Guarded' : 'Not Guarded'}</span>
                                          </p>

                                          <p className='vehicle-info'>

                                            <span className='simple-txt'>
                                              Distance:
                              <span> {item.distance} KM</span>
                                            </span><br />


                                          </p>
                                          <p className='vehicle-info'>

                                            <span className='simple-txt'>
                                            Distt: {item.district || 'N/A'}
                             
                                            </span><br />


                                          </p>




                                        </Element>


                                      </>
                                    )

                                  })}
                              </div>
                                 
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

PatrollingDashboard.propTypes = {};
PatrollingDashboard.defaultProps = {};
const mapStateToProps = (state) => {
  return {
    cellSites: state.patrolling.cellSites,
    vehicles: state.patrolling.vehicles,
    permissions: state.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Patrolling.creators, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatrollingDashboard);
