import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as _ from 'lodash';
import Modal from 'react-bootstrap4-modal';
import CSVReader from 'react-csv-reader';
import * as R from 'ramda';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner, WhiteSpinner } from '../../common/Spinner/index';
import Hotspot from '../../../ducks/Hotspot';
import TextFieldGroup from '../../common/TextFieldGroup';
import searchIcon from '../../../assets/img/ic_search.png';
import editIcon from '../../../assets/img/ic_edit.png';
import trash_bin from '../../../assets/img/trash_bin.png';
import { isEmptyValue, isAlphaNumeric } from '../../../validations/validator';
import { URL } from '../../../constants';

class HotspotListing extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingRecords: true,
      search: '',
      hotspots: [],
      hotspotToBeDeleted: {},
      hotspotsImport: [],
      showImportHotspot: false,
      deletingHotspot: false,
    };
    this.searchHotspot = this.searchHotspot.bind(this);
    this.onChangeData = this.onChangeData.bind(this);
    this.checkBoxAlertChanged = this.checkBoxAlertChanged.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  loadHotspots = async (initLoad) => {
    await this
      .props
      .actions
      .getGroupHotspots1(this.state.search, initLoad);

    this.setState({
      ...this.state,
      hotspots: this.props.hotspots,
      isLoadingRecords: false
    })
  }
  async componentDidMount() {
   await this.loadHotspots(true);
  }

 

  async onChange(event) {
    const value = event.target.value;
    if (value) { this.setState({ ...this.state, search: value || '' }); } else {
      await this.setState({ ...this.state, search: value || '' })
      this.loadHotspots(true);
    }
  }
  onMapChange() { }
  navigateToCreateHotspot() {
    this
      .props
      .history
      .push('/hotspot');
  }

  updateHotspot(item) {
    this
      .props
      .history
      .push(`/hotspot/update/${item._id}`);

  }
  showDeleteHotspotConfirmation(item) {
    this.setState({
      ...this.state,
      hotspotToBeDeleted: item,
      showDeleteConfirmation: true
    })

  };
  
  async deleteHotspot() {
    if (!this.state.deletingHotspot) {
      await this.setState({
        deletingHotspot: true,
      });
      await this
        .props
        .actions
        .deleteHotspot({ id: this.state.hotspotToBeDeleted._id, vehicles: this.state.hotspotToBeDeleted.vehicles });

      toastr.success("Hotspot deleted successfully!");

      let hotspots = _.filter(this.state.hotspots, item => {
        return item._id !== this.state.hotspotToBeDeleted._id;
      })
      this.setState({
        ...this.state,
        showDeleteConfirmation: false,
        hotspots,
        deletingHotspot: false,
      })
    }
  }
  async uploadHotspot() {
    try {
      const { isLoading } = this.state;
      if (isLoading) {
        return;
      }
      const hotspots = this.state.hotspotsImport;
      const hasError = hotspots.reduce((error, hotspot) => {
        if (error) {
          return error;
        }
        if (!hotspot.name) {
          return true;
        }
        if (!hotspot.latlng) {
          return true;
        }
       
        return false;

      }, false);

      if (hasError) {
        return toastr.error('Some fields are missing.');
      }
      this.setState({ isLoading: true });
      let companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

      await this.props.actions.importHotspot({ hotspots: this.state.hotspotsImport, groupId: companyInfo.groupId });
      toastr.success("Hotspots will be imported in a while!");
      this.setState({
        ...this.state,
        isLoading: false,
        showImportHotspot: false,
        hotspotsImport: [],
      })
    } catch (e) {
      this.setState({ isLoading: false });
      toastr.error(e.response ? e.response.data.message : e);
    }

  }
  cancelDelete() {
    this.setState({ ...this.state, showDeleteConfirmation: false })
  }
  cancelUpload() {
    this.setState({ ...this.state, showImportHotspot: false })
  }

  async searchHotspot(e) {
    e.preventDefault();
    const value = this.state.search;
    await this.setState({
      ...this.state,
      search: value || ''
    });
    this.loadHotspots(true);
  }
  openHotspot(id) {
    let hotspots = _.map(this.state.hotspots, item => {
      if (item._id === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });

    this.setState({
      ...this.state,
      hotspots
    });
  }

  handleDarkSideForce() {
    toastr.error('Invalid file format, csv file is required!');
  }

  handleFileUpload(data) {

  
    if (data && data.length) {
      if (data.length <= 1) {
        return toastr.error('No date found.');
      }
      let heading = data[0];

      if (heading[0] !== 'Name' || heading[1] !== 'Description'  || heading[2] !== 'lat,lng' || heading[3] !== 'Radius') {
        return toastr.error('Invalid Columns in csv!');
      }

      let hotspotsImport = [];
      _.forEach(data, (item, index) => {

        const isAllEmpty = R.all(R.isEmpty, item);

        if (index !== 0 && item.length === 4 && !isAllEmpty) {

          let obj = { name: '', description: '', latlng: '', radius: 300, valid: true };
          _.forEach(item, (value, valueIndex) => {

            if (valueIndex === 0) {
              obj.name = _.trim(value);

              if (isEmptyValue(obj.name)) {
                toastr.error('Provide value for name');
                // obj.valid = false;
              }
              // else if (!isAlphaNumeric(obj.name)) {
              //   toastr.error('Hotspot name must be alphanumeric');
              //   obj.valid = false;
              // }
              // else if (obj.name.length > 100) {
              //   toastr.error('Max 100 characters are allowed in name');
              //   // obj.valid = false;
              // }

            }
            if (valueIndex === 1 && obj.valid) {
              obj.description = _.trim(value);
              if (!isAlphaNumeric(obj.description)) {
                toastr.error('Description must be alphanumeric');
                // obj.valid = false;
              }
              else if (obj.description.length > 500) {
                toastr.error('Max 500 characters are allowed in description.');
                // obj.valid = false;
              }
            }
           
            if (valueIndex === 2 && obj.valid) {
              obj.latlng = _.trim(value);
              if (!obj.latlng) {
                toastr.error('latlng not provided');
                // obj.valid = false;
              }
            }
            if (valueIndex === 3 && obj.valid) {
              obj.radius = parseInt(value, 10);
              if (isNaN(obj.radius)) {
                toastr.error('Radius must be a number');
                // obj.valid = false;
              }
              if (obj.radius < 100) {
                toastr.error(`Radius of ${obj.name} is converted to 100 m`);
                obj.radius = 100;
              }

            }
          })
          if (obj.valid) {
            hotspotsImport.push(obj);
          }
        }
      });
      if (hotspotsImport.length) {
        this.setState({ ...this.state, hotspotsImport, showImportHotspot: true });
      }

    }
  }
  onChangeData(e) {

    if (e && e.target) {
      const index = e.target.id;

      let hotspotsImport = this.state.hotspotsImport;
      let item = hotspotsImport[index]
      item[e.target.name] = e.target.value;
      hotspotsImport[index] = item;
      this.setState({ ...this.state, hotspotsImport })
    }
  };
  checkBoxAlertChanged(index, type) {

    let hotspotsImport = this.state.hotspotsImport;
    let item = hotspotsImport[index]
    item[type] = !item[type];
    hotspotsImport[index] = item;
    this.setState({ ...this.state, hotspotsImport })
  }

  render() {
    let notFound;
    let token = localStorage.getItem('refreshToken');
    const hotspots = this.state.hotspots;
    if (!this.state.isLoadingRecords && hotspots.length === 0) {
      notFound = (
        <div className='no-data-found' key='1'>
          <h2>No hotspot added</h2>
        </div>)
    };

    const loader = <Spinner key='1' />
    const smallLoader = <WhiteSpinner key="1" />

    return (

      <div>
       
     
        {this.state.showDeleteConfirmation ?
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName='delete-modal-dialog'>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Delete Hotspot?</h5>
            </div>
            <div className='modal-body'>
              <h6>Are you sure you want to delete Hotspot {this.state.hotspotToBeDeleted.name}?</h6>


            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .deleteHotspot
                  .bind(this)}>
                {this.state.deletingHotspot ? [smallLoader] : 'Confirm Delete'}
              </button>

              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
          : ''}
        {this.state.showImportHotspot &&
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName=''>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelUpload
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Import Hotspots</h5>
            </div>
            <div className='modal-body'>
              <table className='table-auxo'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th>LAT,LNG</th>
                    <th>RADIUS (METERS)</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.hotspotsImport && this
                    .state
                    .hotspotsImport
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td >
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.name}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'name'}
                              placeholder={'Hotspot name'}
                              isRequired={true}
                            />
                          </td>
                          <td >
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.description}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'description'}
                              placeholder={'Description'}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.latlng}
                              type={'text'}
                              id={index}
                              key={index}
                              field={'latlng'}
                              placeholder={'lat, lng'}
                              isRequired={true}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.radius}
                              type={'number'}
                              id={index}
                              key={index}
                              field={'radius'}
                              placeholder={'Radius'}
                              isRequired={true}
                            />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .uploadHotspot
                  .bind(this)}>
                Confirm
                    </button>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelUpload
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
        }

        <div className='row'>
          <div className='col-lg-3 col-md-3 col-sm-4 margin-top-20'>
            <form onSubmit={this.searchHotspot}>
              <TextFieldGroup
                field={"tsearch"}
                placeholder={"Search Hotspot"}
                value={this.state.search}
                type={'text'}
                onChange={this
                  .onChange
                  .bind(this)} />
              <a className='search-icon' onClick={this.searchHotspot}><img src={searchIcon} alt='' /></a>
            </form>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2 margin-top-20'>
          {!this.state.isLoadingRecords &&
        <div className="hotspotCount">Total Hotspot: {this.props.hotspotCount}</div>
        }
          </div>

          <div className='col-lg-7 col-md-7 col-sm-7'>
           
              <span>
                <button
                  className='btn auxo-primary-btn'
                  onClick={this
                    .navigateToCreateHotspot
                    .bind(this)}>Create Hotspot</button>

                <span>
               
                  <button className='btn auxo-primary-btn' onClick={() => {
                    document.getElementById('UniqueObiWan').click();
                  }}>
                     
                    <div className='import-hotspot'>Import Hotspots
                   </div>
                   {!this.state.showImportHotspot && <CSVReader
                        cssClass='things-input-reader'
                        accept='.csv'
                        onFileLoaded={this.handleFileUpload}
                        onError={this.handleDarkSideForce}
                        inputId='UniqueObiWan'
                      />
                      }
                  </button>
                </span>
                <a
                  className='download-hotspot-template btn auxo-primary-btn'
                  href={`${URL}/companies/files/HotspotTemplate.csv?token=${token}`}
                >Download Template</a>
              </span>
             
          </div>
        </div>
        {this.state.isLoadingRecords ?
          [loader] : 
        <div className='row'>
        <InfiniteScroll
          pageStart={0}
          className="w-100-p"
          loadMore={()=>{this.loadHotspots(false)}}
          hasMore={this.props.hasMoreItems}
          loader={loader}>
          {!this.state.isLoadingRecords && hotspots.length !== 0
            ? <div className='trip-table-heading'>
              <p className='width-30'>HOTSPOT</p>
              <p className='width-30'>DESCRIPTION</p>
              <p className='width-30'>LOCATION</p>
                <p className='width-10'>ACTIONS</p> 
            </div>
            : [notFound]}

          {hotspots.map((item, index) => {
            index = 'abc' + index + 'z';
            item = _.clone(item);

            return (

              <div className='width-100' key={index}>
                <div className='trip-table-data'>

                  <p className='width-30'>
                    {item.name}
                  </p>

                  <p className='width-30'>{item.description}
                    </p>

                  <p className='width-30'>{item.geoFenceLocation || 'N/A'}</p>
                  <span>
                    
                      <a className='padding-10' onClick={this
                        .updateHotspot
                        .bind(this, item)}><img src={editIcon} alt='' /></a> 
                   
                      <a onClick={this
                        .showDeleteHotspotConfirmation
                        .bind(this, item)}><img src={trash_bin} className='width16' alt='' /></a> 
                  </span>
                </div>
              </div>

            )
          })}
 </InfiniteScroll>
        </div>
        }
     </div>
    );
  }
}

HotspotListing.propTypes = {};
HotspotListing.defaultProps = {};
const mapStateToProps = state => ({ 
  hotspots: state.hotspot.hotspots,
  hotspotCount: state.hotspot.hotspotCount,
  hasMoreItems: state.hotspot.hasMoreItems});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Hotspot.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(HotspotListing);
