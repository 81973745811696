import Duck from 'extensible-duck'
import axios from "axios";
import {driverEndPoints} from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoDrivers',
    store: 'drivers',
    types: [
        'DRIVER_IS_LOADING', 'DETAILS_FETCHED', 'DRIVERS_FETCHED'
    ],
    initialState: {
        driver: {},
        drivers: [],
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.DRIVER_IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.DRIVERS_FETCHED:
                return {
                    ...state,
                    drivers: action.drivers
                };

            case duck.types.DETAILS_FETCHED:
                return {
                    ...state,
                    driver: action.driver
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (id) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().drivers.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {

                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: true});

                const response = await axios.get(driverEndPoints.individualDriver(id));
                let driver = response.data.data;
                dispatch({type: duck.types.DETAILS_FETCHED, driver});
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupDrivers: (id) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().drivers.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: true});
                const response = await axios.get(driverEndPoints.groupDrivers(id));
                let drivers = response.data.data;
                dispatch({type: duck.types.DRIVERS_FETCHED, drivers});
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
                throw e;
            }
        },

        updateDriver: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().drivers.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: true});

                await axios.put(driverEndPoints.individualDriver(state.id), state);

                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
                throw e;
            }
        },

        importDrivers: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().drivers.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: true});

                await axios.post(driverEndPoints.importDrivers(state.groupId), {drivers: state.drivers});

                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        addDriver: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().drivers.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                await axios.post(driverEndPoints.groupDrivers(companyInfo.groupId), state);

                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        deleteDriver: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().drivers.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: true});

                await axios.delete(driverEndPoints.individualDriver(state.driverId));

                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.DRIVER_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        exportDrivers: (state) => async(dispatch, getState) => {}
    })
})