import { compose, withProps, withStateHandlers, lifecycle } from "recompose";
import React, { useState } from "react";
import "./listmap.css";
import toastr from "toastr";

import { faBroadcastTower } from "@fortawesome/free-solid-svg-icons/faBroadcastTower";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polyline,
  OverlayView
} from "react-google-maps";
import { OVERLAY_VIEW } from "react-google-maps/lib/constants";
const {
  SearchBox,
} = require("react-google-maps/lib/components/places/SearchBox");
const _ = require("lodash");
let self = {};

const ListMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="geofence-map-container" />,
    mapElement: <div className="geofence-map-element" />,
  }),
  lifecycle({
    componentDidMount() {
      const refs = {};

      setTimeout(() => {
        this.setState({
          bounds: null,
          center: {
            lat: self.center.lat,
            lng: self.center.lng,
          },
          onMapMounted: (ref) => {
            refs.map = ref;
          },
          onBoundsChanged: () => {
            this.setState({
              bounds: refs.map.getBounds(),
              center: refs.map.getCenter(),
            });
          },
          onSearchBoxMounted: (ref) => {
            refs.searchBox = ref;
          },
          onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();

            places.forEach((place) => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            const nextMarkers = places.map((place) => ({
              position: place.geometry.location,
            }));
            const nextCenter = _.get(
              nextMarkers,
              "0.position",
              this.state.center
            );

            this.setState({
              center: nextCenter,
            });
            if(self.circle){
              self.circle.center = nextCenter;
              self.onMapChange(self.circle);
            }
            if(self.polygon){
              self.onMapChange(nextCenter, true);
            }
            // refs.map.fitBounds(bounds);
          },
        });
      }, 2000);
    },
  }),
  withScriptjs,
  withGoogleMap,
  withStateHandlers(({ circleReference }) => ({ circle: circleReference }), {
    onCircleMounted: (state) => (circle) => {
      return { circle };
    },
  })
)((props) => {
  self = props;
  const [paths, setPaths] = useState(props.paths || []);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [tourDetails , setTourDetails] = useState([]);
  function radiusToZoom() {
    const radius = props.radius / 1000;
    return Math.round(13 - Math.log(radius) / Math.LN2);
  }
  const zoom = parseInt(radiusToZoom(), 10);
  

  const addPoint = (event) => {
    if (!props.polygon) return;
    if (paths.length <= 19) {
      setPaths([
        ...paths,
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
      ]);
      props.path([
        ...paths,
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
      ]);
    }
    else {
      toastr.error("You can't add more than 20 points")
    }
    
  };



  const getTooltipPositionNorth = (center, radius) => {
    const earthRadius = 6371000; // radius of the Earth in meters
    const offset = (radius / earthRadius) * (180 / Math.PI); // offset in degrees

    return {
      lat: center.lat + offset * 1.1, // 1.1 factor to ensure the tooltip is outside the circle
      lng: center.lng,
    };
  };

  const getSelectedTourData = (id) => {
    setHoveredIndex(id);
    const tourDetails = props?.tourData?.filter(data => data.geofence === id);
    setTourDetails(tourDetails);
  }

 
  
  return (
    <>
      <GoogleMap
      zoom={zoom}
      onClick={addPoint}
      defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
      center={props.center}
    > 
    {props.startCord &&  (
        <Marker
          position={props.startCord}
          icon={{
            url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png', // URL to the green marker icon
          }}
        />
      )} 
      {props.endCord && (
        <Marker
        position={props.endCord}
      />
      )
    }
      {props.path && (
        <Polyline
        options={{
          strokeColor: '#03ABCE', strokeWeight: 4 ,
          path: props.path,
        }}
      />
      )}  
      
      {props.hasOwnProperty("editable") ? (
        ""
      ) : (
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
          options={{
            componentRestrictions: { country: "br" },
          }}
        >
          <input
            type="text"
            placeholder="Search places"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: "295px",
              height: "41px",
              marginTop: "9px",
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </SearchBox>
      )}
      
      {props.telcoSites.length > 0 && (
        <>
        {props.telcoSites.map((item , index)=>(
          <>
           <Circle
           key={index}
           center={{lat:item.center[1] , lng:item.center[0]}}
           onMouseOver={() => getSelectedTourData(item._id)}
           onMouseOut={() => setHoveredIndex(null)}
           radius={item.radius}
           options={{
            strokeColor: "grey",
            strokeOpacity: 0.8,
            fillColor: "white",
            strokeWeight: 10,
            fillOpacity: 0.42,
           }}
         />
         <OverlayView
          position={{lat:item.center[1] , lng:item.center[0]}}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={{ 
            position: 'absolute', 
            transform: 'translate(-50%, -50%)', 
            color:'#242a2b',
            fontSize: '35px' 
          }}>
            <FontAwesomeIcon icon={faBroadcastTower} />
          </div>
        </OverlayView>
        {hoveredIndex === item._id && tourDetails?.length > 0 && (
          <>
           <OverlayView
           position={getTooltipPositionNorth({ lat: item.center[1], lng: item.center[0] }, item.radius)}
           mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
         >
           <div style={{
             position: 'absolute',
             transform: 'translate(-50%, -100%)',
             color: 'white',
             backgroundColor: 'black',
             padding: '15px',
             borderRadius: '5px',
             boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
             maxHeight: '150px', // Set a fixed height
             overflowY: 'auto', // Enable vertical scrolling
             whiteSpace: 'nowrap'
           }}>
             {tourDetails?.map((item , index)=>(
              <>
              <label>{`id: ${item.geofenceName}`}</label>
              <label>{`Geofence Entry: ${item.entryTime}`}</label>
              <label>{`Geofence Exit: ${item.exitTime}`}</label>
              <label>{`Time Spend: ${item.durationInFence}`}</label>
              </>
             ))}
             <div style={{
               position: 'absolute',
               bottom: '-5px', 
               left: '50%',
               transform: 'translateX(-50%)',
               width: '0',
               height: '0',
               borderLeft: '5px solid transparent',
               borderRight: '5px solid transparent',
               borderTop: '5px solid black',
             }}></div>
           </div>
         </OverlayView>
         </>
          )}
         </>
        ))}
       </>
      )}
    </GoogleMap>
    </>
  );
});

export default ListMap;
