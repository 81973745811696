import {isEmpty} from 'lodash';
import {isEmptyValue} from './validator';

export function validateInput(data) {
    let errors = {};
    if (isEmptyValue(data.name)) {
        errors.name = 'This field is required';
    }
    if (data.access.length < 1) {
        errors.access = 'At least 1 permission is required for role';
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };
}
