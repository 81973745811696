import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Things from "../../ducks/Things";

class AboutUS extends Component {

  constructor(props) {
    super(props);

  };

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className=' margin-top-20'>
            <h1>About Us</h1>
            <br />
            <p>AUXO is a complete end-to-end vehicle management solution that provides valuable insights for users to bring operational efficiency and brining cost optimizations powered by advance telematics like live tracking along with Geo Fencing, Driving Behavior, Vehicle Maintenance information and much more</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ permissions: state.permissions });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AboutUS);
