import Duck from 'extensible-duck'
import axios from "axios";
import moment from "moment";
import { permissionEndPoints } from "../api/endpoints";
// import UserAuthentication from "./UserAuthentication";
import * as _ from 'lodash';

// const authenticationActions = UserAuthentication.creators;

export default new Duck({
  namespace: 'AuxoPermissions',
  store: 'permissions',
  types: [
    'PERMISSION_IS_LOADING', 'PERMISSION_FETCHED', 'USER_SET_PERMISSIONS', 'SET_COMPANY_FEATURES'
  ],
  initialState: {
    list: [],
    permissions: [],
    companies: [],
    isLoading: false,
    createUser: false,
    listUser: false,

    listStock: false,
    createRole: false,
    listRole: false,
    updateRole: false,
    updateVehicle: false,
    viewVehicle: false,
    viewGeoFence: false,
    updateGeoFence: false,
    deleteGeoFence: false,
    createGeoFence: false,
    createGroup: false,
    viewGroup: false,
    updateGroup: false,
    createDriver: false,
    viewDriver: false,
    updateDriver: false,
    deleteDriver: false,
    fuelGroup: false,
    trips: false,
    playBack: false,
    dashboard: false,
    observations: false,
    maintenance: false,
    viewRouteFence: false,
    updateRouteFence: false,
    deleteRouteFence: false,
    createRouteFence: false,
    reports: false,
    schedule: false,
    roster: false,
    AccountSetting: false,
    updateUser: false,
    deleteUser: false,
    createSchedule: false,
    listSchedule: false,
    updateSchedule: false,
    deleteSchedule: false,
    viewNotification: false,
    engineKill: false,
    patrolling: false,
    companyFeatures: [],
    companyReports: []
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.PERMISSION_IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };
      case duck.types.SET_COMPANY_FEATURES:
        const reports = action.companyId === "5d9c5a0662baef4ae6e4a669" ? [...action.packageInfo.reports, "vehicleHistoryReport"] : action.packageInfo.reports;
        return {
          ...state,
          companyFeatures: action.packageInfo.features,
          companyReports: reports
        };
      case duck.types.PERMISSION_FETCHED:
        return {
          ...state,
          list: action.permissions,
          companies: action.companies
        };
      case duck.types.USER_SET_PERMISSIONS: {
        let permissions = action.permissions[0].role.permissions;

        if (!permissions) {
          return
        }
        let permission = {
          createUser: false,
          listUser: false,
          listStock: false,
          createRole: false,
          listRole: false,
          updateRole: false,
          updateVehicle: false,
          viewVehicle: false,
          viewGeoFence: false,
          updateGeoFence: false,
          deleteGeoFence: false,
          createGeoFence: false,
          createGroup: false,
          updateGroup: false,
          viewGroup: false,
          createDriver: false,
          viewDriver: false,
          updateDriver: false,
          deleteDriver: false,
          fuelGroup: false,
          trips: false,
          playBack: false,
          dashboard: false,
          observations: false,
          maintenance: false,
          viewRouteFence: false,
          updateRouteFence: false,
          deleteRouteFence: false,
          createRouteFence: false,
          reports: false,
          schedule: false,
          roster: false,
          AccountSetting: false,
          updateUser: false,
          deleteUser: false,
          createSchedule: false,
          listSchedule: false,
          updateSchedule: false,
          deleteSchedule: false,
          viewNotification: false,
          engineKill: false,
          patrolling: false,
        }
        const isSuperAdminUser = localStorage.getItem('isSuperAdminUser');

        if (isSuperAdminUser) {
          permission = {
            createUser: true,
            listUser: true,
            listStock: true,
            createRole: true,
            listRole: true,
            updateRole: true,
            updateVehicle: true,
            viewVehicle: true,
            viewGeoFence: true,
            updateGeoFence: true,
            deleteGeoFence: true,
            createGeoFence: true,
            createGroup: true,
            updateGroup: true,
            viewGroup: true,
            createDriver: true,
            viewDriver: true,
            updateDriver: true,
            deleteDriver: true,
            fuelGroup: true,

            trips: true,
            playBack: true,
            dashboard: true,
            observations: true,
            maintenance: true,
            viewRouteFence: true,
            updateRouteFence: true,
            deleteRouteFence: true,
            createRouteFence: true,
            reports: true,
            schedule: true,
            roster: true,
            AccountSetting: true,
            updateUser: true,
            deleteUser: true,
            createSchedule: true,
            listSchedule: true,
            updateSchedule: true,
            deleteSchedule: true,
            viewNotification: true,
            engineKill: true,
            patrolling: true,

          }
        } else {
          _.forEach(permissions, item => {
            switch (item.permissionString) {
              case 'CREATE:USER': {
                permission.createUser = true;
                break;
              }
              case 'LIST:USER': {
                permission.listUser = true;
                break;
              }

              case 'CREATE:ROLE': {
                permission.createRole = true;
                break;
              }

              case 'LIST:ROLE': {
                permission.listRole = true;
                break;
              }

              case 'LIST:STOCK': {
                permission.listStock = true;
                break;
              }

              case 'UPDATE:ROLE': {
                permission.updateRole = true;
                break;
              }
              case 'UPDATE:VEHICLE': {
                permission.updateVehicle = true;
                break;
              }
              case 'VIEW:VEHICLE': {
                permission.viewVehicle = true;
                break;
              }
              case 'VIEW:GEOFENCE': {
                permission.viewGeoFence = true;
                break;
              }
              case 'UPDATE:GEOFENCE': {
                permission.updateGeoFence = true;
                break;
              }
              case 'DELETE:GEOFENCE': {
                permission.deleteGeoFence = true;
                break;
              }
              case 'CREATE:GEOFENCE': {
                permission.createGeoFence = true;
                break;
              }
              case 'CREATE:GROUP': {
                permission.createGroup = true;
                break;
              }
              case 'VIEW:GROUP': {
                permission.viewGroup = true;
                break;
              }
              case 'UPDATE:GROUP': {
                permission.updateGroup = true;
                break;
              }
              case 'CREATE:DRIVER': {
                permission.createDriver = true;
                break;
              }
              case 'UPDATE:DRIVER': {
                permission.updateDriver = true;
                break;
              }
              case 'VIEW:DRIVER': {
                permission.viewDriver = true;
                break;
              }
              case 'DELETE:DRIVER': {
                permission.deleteDriver = true;
                break;
              }
              case 'FUEL_GROUP': {
                permission.fuelGroup = true;
                break;
              }

              case 'TRIPS': {
                permission.trips = true;
                break;
              }
              case 'PLAYBACK': {
                permission.playBack = true;
                break;
              }
              case 'DASHBOARD': {
                permission.dashboard = true;
                break;
              }
              case 'OBSERVATIONS': {
                permission.observations = true;
                break;
              }
              case 'MAINTENANCE': {
                permission.maintenance = true;
                break;
              }
              case 'VIEW:ROUTEFENCE': {
                permission.viewRouteFence = true;
                break;
              }
              case 'UPDATE:ROUTEFENCE': {
                permission.updateRouteFence = true;
                break;
              }
              case 'DELETE:ROUTEFENCE': {
                permission.deleteRouteFence = true;
                break;
              }
              case 'CREATE:ROUTEFENCE': {
                permission.createRouteFence = true;
                break;
              }
              case 'REPORTS': {
                permission.reports = true;
                break;
              }
              case 'SCHEDULE': {
                permission.schedule = true;
                break;
              }
              case 'ROSTER': {
                permission.roster = true;
                break;
              }
              case 'AccountSetting': {
                permission.AccountSetting = true;
                break;
              }
              case 'UPDATE:USER': {
                permission.updateUser = true;
                break;
              }
              case 'DELETE:USER': {
                permission.deleteUser = true;
                break;
              }
              case 'CREATE:SCHEDULE': {
                permission.createSchedule = true;
                break;
              }
              case 'LIST:SCHEDULE': {
                permission.listSchedule = true;
                break;
              }
              case 'UPDATE:SCHEDULE': {
                permission.updateSchedule = true;
                break;
              }
              case 'DELETE:SCHEDULE': {
                permission.deleteSchedule = true;
                break;
              }
              case 'VIEW:NOTIFICATION': {
                permission.viewNotification = true;
                break;
              }
              case 'ENGINE:KILL': {
                permission.engineKill = true;
                break;
              }
              case 'PATROLLING': {
                permission.patrolling = true;
                break;
              }
              default: return

            }
          });
        }

        return {
          ...state,
          permissions: action.permissions,
          createUser: permission.createUser,
          listUser: permission.listUser,
          createRole: permission.createRole,
          listRole: permission.listRole,
          updateRole: permission.updateRole,
          updateVehicle: permission.updateVehicle,
          viewVehicle: permission.viewVehicle,
          viewGeoFence: permission.viewGeoFence,
          updateGeoFence: permission.updateGeoFence,
          deleteGeoFence: permission.deleteGeoFence,
          createGeoFence: permission.createGeoFence,
          createGroup: permission.createGroup,
          updateGroup: permission.updateGroup,
          viewGroup: permission.viewGroup,
          createDriver: permission.createDriver,
          viewDriver: permission.viewDriver,
          updateDriver: permission.updateDriver,
          deleteDriver: permission.deleteDriver,
          fuelGroup: permission.fuelGroup,
          trips: permission.trips,
          playBack: permission.playBack,
          dashboard: permission.dashboard,
          observations: permission.observations,
          maintenance: permission.maintenance,
          viewRouteFence: permission.viewRouteFence,
          updateRouteFence: permission.updateRouteFence,
          deleteRouteFence: permission.deleteRouteFence,
          createRouteFence: permission.createRouteFence,
          reports: permission.reports,
          schedule: permission.schedule,
          roster: permission.roster,
          AccountSetting: permission.AccountSetting,
          updateUser: permission.updateUser,
          deleteUser: permission.deleteUser,
          createSchedule: permission.createSchedule,
          listSchedule: permission.listSchedule,
          updateSchedule: permission.updateSchedule,
          deleteSchedule: permission.deleteSchedule,
          viewNotification: permission.viewNotification,
          engineKill: permission.engineKill,
          patrolling: permission.patrolling,
        };
      }
      default:
        return state;
    }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({
    getPermissionsAndCompanies: () => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
        // dispatch(authenticationActions.logoutUser());
      }
      if (getState().permissions.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.PERMISSION_IS_LOADING, isLoading: true });
        const response = await axios.get(permissionEndPoints.getPermissionsAndCompanies);
        const permissions = response.data.data.modules;
        const companies = response.data.data.companies;
        dispatch({ type: duck.types.PERMISSION_FETCHED, permissions, companies });
        dispatch({ type: duck.types.PERMISSION_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.PERMISSION_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    setUserPermissions: userId => async (dispatch, getState) => {
      try {
        const response = await axios.get(permissionEndPoints.userPermission + `/${userId}`);
        if (response && response.data && response.data.data) {
          const permissions = response.data.data.permissions;
          dispatch({ type: duck.types.USER_SET_PERMISSIONS, permissions });
        }

      } catch (e) {
        //dispatch({ isLoading: false });
        console.log(e)
        throw e
      }
    },
    setCompanyFeatures: companyId => async (dispatch, getState) => {
      try {
        const response = await axios.get(permissionEndPoints.companyFeatures + `/${companyId}`);
        if (response && response.data && response.data.data) {
          const packageInfo = response.data.data.company.packageInfo;
          dispatch({ type: duck.types.SET_COMPANY_FEATURES, packageInfo, companyId });
        }

      } catch (e) {
        console.log(e)
      }
    }
  })


})