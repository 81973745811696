import Duck from 'extensible-duck'
import axios from "axios";
import { companyEndPoints } from '../api/endpoints';

export default new Duck({
    namespace: 'AuxoCompany',
    store: 'company',
    type: [
        'GEOFENCE', 'ROUTEFENCE'
    ],
    initialState: {
        geofence: {},
        routefence: {}
    },
    reducer: (state, action, duck) => {
        switch(action.type) {
            case 'GEOFENCE': 
                return {
                    ...state,
                    geofence: action.payload
                }
            case 'ROUTEFENCE':
                return {
                    ...state,
                    routefence: action.payload
                }
            default:
                return state
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({
        getGeofence: () => async(dispatch, getState) => {
            let response = await axios.get(companyEndPoints.geofence(JSON.parse(localStorage.getItem('companyInfo')).companyId));
            dispatch({type: 'GEOFENCE', payload: response.data.data});
        },
        getRoutefence: (company) => async(dispatch, getState) => {
            let response = await axios.get(companyEndPoints.routefence(JSON.parse(localStorage.getItem('companyInfo')).companyId));
            dispatch({type: 'ROUTEFENCE', payload: response.data.data});
        }
    })
})