import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap4-modal";
import toastr from "toastr";
import * as R from "ramda";
import { WhiteSpinner } from "../common/Spinner";
import axios from "axios";
import { fuelEndPoint } from "../../api/endpoints";
import ic_visibility from '../../assets/img/ic_visibility.png';
import ic_edit from '../../assets/img/ic_edit.png';
import trashbin from '../../assets/img/trash_bin.png';
const CustomTableCell = (props) => {
  return (
    <th style={{ backgroundColor: "#F6F7F8", color: "#727272" }}>
      {props.children}
    </th>
  );
};

const Fab = (props) => {
    return <div className="fab flex justify-center item-center" onClick={() => props.onClick()}>
        {props.children}
    </div>
}

const FuelGroupModal = ({
  isNewFuelModalVisible,
  fuelGroup,
  cancelFuelGroup,
  updateFuelGroupName,
  saveFuelGroup,
  isSaveFuelLoading: isLoading,
}) => {
  
  return (
    <Modal visible={isNewFuelModalVisible} dialogClassName="error-modal">
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={cancelFuelGroup}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-heading">
        <h5 className="car-and-device-assig">Edit name of fuel group</h5>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            onChange={updateFuelGroupName}
            value={fuelGroup.groupName}
            className="textField"
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn auxo-primary-btn"
          onClick={!isLoading && saveFuelGroup}
        >
          {isLoading ? <WhiteSpinner /> : "Save"}
        </button>
      </div>
    </Modal>
  );
};

const FuelDeleteModal = ({
  isFuelDeleteVisible,
  cancelFuelDelete,
  onFuelDelete,
  isSaveFuelLoading: isLoading,
}) => {

  return (
    <Modal visible={isFuelDeleteVisible} dialogClassName="error-modal">
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={cancelFuelDelete}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-heading">
        <h5 className="car-and-device-assig">Fuel Group Delete</h5>
      </div>
      <div className="modal-body">Do you want to delete this fuel group?</div>
      <div className="modal-footer">
        <button
          className="btn auxo-primary-btn"
          onClick={!isLoading && onFuelDelete}
        >
          {isLoading ? <WhiteSpinner /> : "Confirm"}
        </button>
      </div>
    </Modal>
  );
};

const GroupSlotsModal = ({
  isFuelDetailVisible,
  toggleFuelDetailModal,
  fuelGroup,
  addNewSlot,
  saveSlot,
  handleSlotChange,
  toggleSlotEdit,
  onDeleteSlot,
}) => {
  return (
    <Modal visible={isFuelDetailVisible} dialogClassName="driver-modal-dialog">
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={toggleFuelDetailModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-heading">
        <h5 className="car-and-device-assig">
          {" "}
          Fuel Group Details : {fuelGroup.groupName}
        </h5>
      </div>
      <div className="modal-body">
        <div className="flex item-center space-between ">
          <div>
            <h6>Fuel Range</h6>
          </div>
          <div>
            <Fab
              aria-label="Add"
              onClick={addNewSlot}
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </Fab>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-sm-12">
            <table className="table-auxo">
              <thead className="tableHead">
                <tr>
                  <CustomTableCell className="tableCell">
                    Min Speed (KM/H)
                  </CustomTableCell>
                  <CustomTableCell className="tableCell">
                    Max Speed (KM/H)
                  </CustomTableCell>
                  <CustomTableCell className="tableCell">
                    Fuel Avg (KM/LTR)
                  </CustomTableCell>
                  <CustomTableCell className="tableCell">
                    Actions
                  </CustomTableCell>
                </tr>
              </thead>

              <tbody>
                {fuelGroup.slots &&
                  fuelGroup.slots.map((slot) => {
                    if (slot.isEditMode) {
                      return (
                        <tr key={slot._id}>
                          <td className="tableCell">
                            <div className="form-group">
                              <input
                                type="number"
                                onChange={(event) => { handleSlotChange('min', slot, event) }}
                                value={slot.min}
                                className="form-control modalTextField"
                              />
                            </div>
                          </td>
                          <td className="tableCell">
                            <div className="form-group">
                              <input
                                type="number"
                                onChange={(event) => { handleSlotChange('max', slot, event) }}
                                value={slot.max}
                                className="form-control modalTextField"
                                min="0"
                                max="999"
                              />
                            </div>
                          </td>
                          <td className="tableCell">
                            <div className="form-group">
                              <input
                                type="number"
                                onChange={(event) => { handleSlotChange('fuelAvg', slot, event) }}
                                value={slot.fuelAvg}
                                className="form-control modalTextField"
                                step="0.1"
                                max="999"
                              />
                            </div>
                          </td>
                          <td className="tableCell">
                            
                            <i className="fa fa-floppy-o" aria-hidden="true" onClick={() => saveSlot(slot)} style={{color: "#03ABCE", padding: "5px"}}></i>
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={slot._id}>
                        <td className="tableCell">{slot.min}</td>
                        <td className="tableCell">{slot.max}</td>
                        <td className="tableCell">
  {slot.fuelAvg && typeof slot.fuelAvg === 'object' && slot.fuelAvg.$numberDecimal
    ? parseFloat(slot.fuelAvg.$numberDecimal)
    : slot.fuelAvg}
</td>

                        <td className="tableCell">
                          <i className="fa fa-pencil" aria-hidden="true" onClick={() => toggleSlotEdit(slot._id)} style={{color: "#03ABCE", padding: "5px"}}></i>
                          <i className="fa fa-trash" aria-hidden="true" onClick={() => onDeleteSlot(slot._id)} style={{color: "#03ABCE", padding: "5px"}}></i>
                          
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

async function listFuelGroupsAction(companyId) {
  try {
    const endPoint = fuelEndPoint.fuelGroupBaseUrl(companyId);
    const response = await axios.get(endPoint);
    const { data } = response.data;
    return data;
  } catch (error) {}
}
async function SaveFuelGroupAction(companyId, fuelGroup) {
  try {
    let response;
    if (fuelGroup.isNew) {
      response = await axios.post(fuelEndPoint.fuelGroupBaseUrl(companyId), {
        groupName: fuelGroup.groupName,
      });
    } else {
      response = await axios.put(fuelEndPoint.updateFuelGroup(fuelGroup._id), {
        groupName: fuelGroup.groupName,
      });
    }
    const { data } = response.data;
    return data;
  } catch (error) {
    const { message: errorMessage } = error.response.data;
    throw new Error(errorMessage);
  }
}
async function DeleteFuelAction(fuelGroupId) {
  try {
    const response = await axios.delete(
      fuelEndPoint.updateFuelGroup(fuelGroupId)
    );
    const { data } = response.data;
    return data;
  } catch (error) {
    const { message: errorMessage } = error.response.data;
    throw new Error(errorMessage);
  }
}
async function SaveSlotAction(fuelGroupId, { _id, isNew, min, max, fuelAvg }) {
  try {
    if (isNew) {
      await axios.post(fuelEndPoint.updateFuelGroup(fuelGroupId), {
        min,
        max,
        fuelAvg,
      });
    } else {
      await axios.put(fuelEndPoint.fuelGroupSlot(_id), { min, max, fuelAvg });
    }
  } catch (error) {
    const { message: errorMessage } = error.response.data;
    throw new Error(errorMessage);
  }
}
async function SlotDeleteAction(id) {
  try {
    const response = await axios.delete(fuelEndPoint.fuelGroupSlot(id));
    return response;
  } catch (error) {
    const { message: errorMessage } = error.response.data;
    throw new Error(errorMessage);
  }
}
const FuelGroupListView = function(props) {
  const { companyId } = JSON.parse(localStorage.getItem("companyInfo"));
  const [isLoading, setLoading] = useState(false);
  const [isSaveFuelLoading, setSaveFuelLoading] = useState(false);
  const [fuelGroups, setFuelGroups] = useState([]);
  const [isNewFuelModalVisible, setNewFuelModalVisible] = useState(false);
  const [fuelGroup, setFuelGroup] = useState({});
  const [isFuelDetailVisible, setFuelDetailVisible] = useState(false);
  const [isFuelDeleteVisible, setFuelDeleteVisible] = useState(false);

  const loadFuelList = async () => {
    setLoading(true);
    const fuelGroups = await listFuelGroupsAction(companyId);
    setFuelGroups(fuelGroups);
    setLoading(false);
  };
  useEffect(() => {
    loadFuelList();
  }, []);

  const toggleFuelModal = () => {
    setNewFuelModalVisible(!isNewFuelModalVisible);
  };

  const addFuelGroup = () => {
    const _fuelGroup = { isNew: true, _id: fuelGroups.length, slots: [] };
    setFuelGroup(_fuelGroup);
    toggleFuelModal();
  };

  const editFuelGroup = (fuelGroup) => {
    setFuelGroup(fuelGroup);
    toggleFuelModal();
  };

  const cancelFuelGroup = () => {
    setFuelGroup({});
    toggleFuelModal();
  };

  const updateFuelGroupName = (event) => {
    const removeCh = "deleteContentBackward";
    const isRemoveCharacter = removeCh === event.nativeEvent.inputType;
    if (
      !isRemoveCharacter &&
      fuelGroup.groupName &&
      fuelGroup.groupName.length >= 20
    ) {
      return;
    }
    const { data: character } = event.nativeEvent;
    const alphaNumericRegx = new RegExp(/[a-zA-Z0-9 ]/);
    if (!alphaNumericRegx.test(character)) {
      return;
    }
    const { value } = event.target;
    setFuelGroup({ ...fuelGroup, groupName: value });
  };

  const saveFuelGroup = async () => {
    try {
      if (!fuelGroup.groupName) {
        return toastr.error("Please fill in the group name.");
      }
      setSaveFuelLoading(true);
      await SaveFuelGroupAction(companyId, fuelGroup);
      setSaveFuelLoading(false);
      toggleFuelModal();
      await loadFuelList();
      if (fuelGroup.isNew) {
        toastr.success("Fuel group has been created successfully.");
      } else {
        toastr.success("Fuel group has been updated successfully.");
      }
      setFuelGroup({});
    } catch (error) {
      toastr.error(error.message);
      setSaveFuelLoading(false);
      setLoading(false);
    }
  };

  const toggleFuelDetailModal = () => {
    if (isFuelDetailVisible) {
      loadFuelList();
    }
    setFuelDetailVisible(!isFuelDetailVisible);
  };
  const openFuelDetail = (fuelgroup) => {
    setFuelGroup(fuelgroup);
    toggleFuelDetailModal();
  };

  const addNewSlot = () => {
    const newSlot = {
      _id: fuelGroup.slots.length,
      isNew: true,
      isEditMode: true,
    };
    setFuelGroup({ ...fuelGroup, slots: [...fuelGroup.slots, newSlot] });
  };

  const handleSlotChange = (field, slot, event) => {
    const { value } = event.target;

    const withDefault = R.defaultTo("");
    const slots = fuelGroup.slots.map((_slot) => {
      if (slot._id !== _slot._id) {
        return _slot;
      }
      let _value;
      if (value === "") {
        _value = "";
      } else {
        _value = parseFloat(value);
        if (!isNaN(_value)) {
          _value = _value > 999 ? 999 : _value;
          _value = _value < 0 ? 0 : _value;
          _value = _value.toFixed(1);
        } else {
          _value = 0;
        }
      }

      if (field === "min") {
        _slot.min = withDefault(parseInt(_value, 10));
      } else if (field === "max") {
        _slot.max = withDefault(parseInt(_value, 10));
      } else if (field === "fuelAvg") {
        _slot.fuelAvg = parseFloat(_value, 10);
      }
      return slot;
    });
    setFuelGroup({ ...fuelGroup, slots });
  };

  const toggleSlotEdit = (slotId) => {
    const _fuelGroup = {
      ...fuelGroup,
      slots: fuelGroup.slots.map((slot) => {
        if (slot._id === slotId) {
          return { ...slot, isEditMode: !slot.isEditMode };
        }
        return slot;
      }),
    };
    setFuelGroup(_fuelGroup);
  };
  const saveSlot = async (slot) => {
    try {
      const isMinZero = slot.min === 0;
      const isMinNotZero = !isMinZero;
      if (isMinNotZero && !slot.min) {
        return toastr.error("Min speed is missing.");
      }

      if (!slot.max) {
        return toastr.error("Max speed is missing.");
      }
      if (!slot.fuelAvg) {
        return toastr.error("Fuel Average is missing.");
      }
      if (slot.min >= slot.max) {
        return toastr.error("Min speed should be less than Max speed.");
      }
      fuelGroup.slots = fuelGroup.slots.map((_slot) => {
        if (_slot._id === slot._id) {
          return slot;
        }
        return _slot;
      });
      await SaveSlotAction(fuelGroup._id, slot);
      await loadFuelList();
      toggleFuelDetailModal();
      setFuelGroup({});
      if (slot.isNew) {
        toastr.success("Fuel Range has been created successfully.");
      } else {
        toastr.success("Fuel Range has been updated successfully.");
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };
  const toggleFuelDelete = () => {
    setFuelDeleteVisible(!isFuelDeleteVisible);
  };
  const cancelFuelDelete = () => {
    setFuelGroup({});
    setFuelDeleteVisible(!isFuelDeleteVisible);
  };
  const onFuelDelete = async () => {
    try {
      setSaveFuelLoading(true);
      await DeleteFuelAction(fuelGroup._id);
      setSaveFuelLoading(false);
      toggleFuelDelete();
      await loadFuelList();
      setFuelGroup({});
      toastr.success("Fuel group has been deleted successfully.");
    } catch (error) {
      setSaveFuelLoading(false);
      toastr.error(error.message);
    }
  };
  const openFuelDelete = (fuelGroup) => {
    setFuelGroup(fuelGroup);
    toggleFuelDelete();
  };

  const onDeleteSlot = async (id) => {
    try {
      await SlotDeleteAction(id);
      const _fuelGroup = {
        ...fuelGroup,
        slots: fuelGroup.slots.filter((s) => s._id !== id),
      };
      setFuelGroup(_fuelGroup);
      toastr.success("Fuel Range has been deleted successfully");
    } catch (error) {
      toastr.error(error.message);
    }
  };
  return (
    <div>
      <div className="paper">
        <div className="flex item-center space-between m-b-20-px">
          <div item>
            <h4>Fuel Groups</h4>
          </div>
          <div item>
            <button className="btn auxo-primary-btn m-0" onClick={addFuelGroup}>
              Add fuel group
            </button>
          </div>
        </div>
        {!isLoading ? (
          <div className="row">
            <div className="col-sm-12">
              <table className="table-auxo">
                <thead>
                  <tr>
                    <CustomTableCell>Name</CustomTableCell>
                    <CustomTableCell>Fuel Range Count</CustomTableCell>
                    <CustomTableCell>Actions</CustomTableCell>
                  </tr>
                </thead>
                <tbody>
                  {fuelGroups.map((fuelgroup) => (
                    <tr key={fuelgroup._id}>
                      <td>{fuelgroup.groupName}</td>
                      <td>{fuelgroup.slots.length}</td>
                      <td style={{paddingTop: 0}}>
                        <a style={{color: "#03ABCE", padding: "5px"}} onClick={() => openFuelDetail(fuelgroup)}><img className="width16" src={ic_visibility} alt="" /></a>
                        <a style={{color: "#03ABCE", padding: "5px"}} onClick={() =>  editFuelGroup(fuelgroup)}><img className="width16" src={ic_edit} alt="" /></a>
                        <a style={{color: "#03ABCE", padding: "5px"}} onClick={() => openFuelDelete(fuelgroup)}><img className="width16" src={trashbin} alt="" /></a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-sm-12">
                <table className="table-auxo">
                  <thead className="tableHead">
                    <tr>
                      <CustomTableCell>Name</CustomTableCell>
                      <CustomTableCell>Fuel Range Count</CustomTableCell>
                      <CustomTableCell>Actions</CustomTableCell>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                      <td>
                        <span className="loadingSpan"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      {isNewFuelModalVisible && (
        <FuelGroupModal
          isNewFuelModalVisible={isNewFuelModalVisible}
          fuelGroup={fuelGroup}
          cancelFuelGroup={cancelFuelGroup}
          updateFuelGroupName={updateFuelGroupName}
          saveFuelGroup={saveFuelGroup}
          isSaveFuelLoading={isSaveFuelLoading}
        />
      )}
      {isFuelDeleteVisible && (
        <FuelDeleteModal
          isFuelDeleteVisible={isFuelDeleteVisible}
          cancelFuelDelete={cancelFuelDelete}
          onFuelDelete={onFuelDelete}
          isSaveFuelLoading={isSaveFuelLoading}
        />
      )}
      {isFuelDetailVisible && (
        <GroupSlotsModal
          isFuelDetailVisible={isFuelDetailVisible}
          toggleFuelDetailModal={toggleFuelDetailModal}
          fuelGroup={fuelGroup}
          addNewSlot={addNewSlot}
          saveSlot={saveSlot}
          handleSlotChange={handleSlotChange}
          toggleSlotEdit={toggleSlotEdit}
          onDeleteSlot={onDeleteSlot}
        />
      )}
    </div>
  );
};

export default FuelGroupListView;
