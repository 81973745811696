import {isEmpty} from 'lodash';
import {isEmptyValue, isAlphaNumeric} from './validator';

export function validateScheduleInput(data) {
    let errors = {};
  
    if (isEmptyValue(data.name)) {
        errors.name = 'This field is required';
    } else if(!isAlphaNumeric(data.name)){
        errors.name = 'Name should contain alphanumeric characters only';
    }
    else if(data.name.length > 30){
        errors.name = 'Max 30 characters are allowed in name';
    }
    if (isEmptyValue(data.description)) {
        errors.description = 'This field is required';
    }else if(!isAlphaNumeric(data.description)){
        errors.description = 'Description should contain alphanumeric characters only';
    }
    else if(data.description.length > 200){
        errors.description = 'Max 200 characters are allowed in description';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validateInput(data) {
    let errors = {};
  
    if (isEmptyValue(data.startDay)) {
        errors.startDay = 'Start day is required';
    }
    if (isEmptyValue(data.endDay)) {
        errors.endDay = 'End day is required';
    }

    if (isEmptyValue(data.startTime)) {
        errors.startTime = 'Start time is required';
    }
    if (isEmptyValue(data.endTime)) {
        errors.endTime = 'End time is required';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

