import React, { Component } from 'react';
import NavMenu from './NavMenu';
import { withRouter } from 'react-router-dom';
import UserAuthentication from "../../ducks/UserAuthentication";
import Users from "../../ducks/Users";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./Layout.css";
import Notifications from '../notifications/index';
import camera from "../../assets/img/ic_camera.png"
import { Modal, ModalHeader, ModalBody,ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, 
  UncontrolledDropdown, } from 'reactstrap';

class Layout extends Component {

  constructor() {
    super();
    this.state = {
      popoverOpen: false
    }
  }

  logoutUser = async () => {
    await this
      .props
      .authActions
      .logoutUser();
    this
      .props
      .history
      .push("/login");
  };
  nevigate = async (value) => {
    this
      .props
      .history
      .push(value);
  };

  handlePassengerCheck = async (event) => {
    let answer = event.target.value;
    let data = {
      modalStatus : false,
      driverAnswer : answer
    }
    await this.props.usersActions.setPassengerModalStatusandAnswer(data);
  }

  render() {
    const groupInfo = this.props.loggedInGroupInfo;
    const isSuperAdminUser = localStorage.getItem('isSuperAdminUser');

    return (

      <>

      <Modal className="modalParent" isOpen={this.props.passengerModal} >
        <ModalHeader >{`Passenger Check`}</ModalHeader>
          <ModalBody>
            <div>
              <label htmlFor="passenger"> {this.props.message} in {this.props.platenumber} ?</label>
              <button className='btn auxo-primary-btn' value={true} onClick={this.handlePassengerCheck}>Yes</button>
              <button className='btn auxo-primary-btn' value={false} onClick={this.handlePassengerCheck}>No</button>
            </div>
          </ModalBody>
      </Modal>

      <div className="container-fluid">
        <div className="row content-whole">
          <NavMenu/>
          <main className={"col-md-10 col-sm-9 ml-sm-auto pt-3 content"}>
            <header className="header">
              <nav className="navbar navbar-expand-md">
                <a
                  className="navbar-toggler d-lg-none"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"/>
                </a>

                <div className="collapse navbar-collapse ">
               
                  <div className="header-section mt-2 mt-md-0 ml-auto">
                  {this.props.permissions.companyFeatures.indexOf("NOTIFICATIONS:INAPP") !== -1 && 
                  (this.props.permissions.viewNotification ||  isSuperAdminUser) &&
                    <Notifications/> }
                    <img
                      src={groupInfo
                        ? groupInfo.groupImage || camera
                        : camera}
                      alt="group"
                      onClick={this.nevigate.bind(this, '/account')}
                      className='group-header-image'/>
                  </div>
                
                  <div className="dropdown">
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret className=" header-group-name">
                        {groupInfo
                          ? groupInfo.groupName
                          : ""}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={this.nevigate.bind(this, '/help')}>
                          <a>Help</a>
                        </DropdownItem>
                        <DropdownItem onClick={this.nevigate.bind(this, '/aboutus')}>
                          <a>About Us</a>
                        </DropdownItem>
                        { (this.props.permissions.AccountSetting ||  isSuperAdminUser) &&
                        <DropdownItem onClick={this.nevigate.bind(this, '/account')}>
                          <a>Account</a>
                        </DropdownItem>}
                        { (this.props.permissions.listUser ||  isSuperAdminUser) &&
                        <DropdownItem onClick={this.nevigate.bind(this, '/users')}>
                          <a>Users</a>
                        </DropdownItem>
                        }
                         { (this.props.permissions.listRole ||  isSuperAdminUser) &&
                        <DropdownItem onClick={this.nevigate.bind(this, '/roles')}>
                          <a>Roles</a>
                        </DropdownItem>
                         }
                        <DropdownItem onClick={this.logoutUser}>
                          <a>Logout</a>
                        </DropdownItem>

                      </DropdownMenu>
                    </UncontrolledDropdown>

                  </div>
                </div>
              </nav>
            </header>
            {this.props.children}
          </main>
        </div>
      </div>
      
      </>
    )
  }
}

Layout.propTypes = {};
Layout.defaultProps = {};
const mapStateToProps = state => ({ 
  loggedInGroupInfo: state.userAuthentication.loggedInGroupInfo, 
  permissions: state.permissions,
  passengerModal : state.users.passengerModal,
  message: state.users.message,
  platenumber : state.users.platenumber
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(UserAuthentication.creators, dispatch),
  usersActions: bindActionCreators(Users.creators, dispatch),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
