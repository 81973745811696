import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Modal from 'react-bootstrap4-modal';
import { clone, filter, map } from 'lodash';
import Things from '../../../ducks/Things';
import FuelGroup from '../../../ducks/FuelGroup';
import Groups from '../../../ducks/Groups';
import Patrolling from '../../../ducks/Patrolling';
import { filterVehicleListing } from '../../../utils/helper.util';
import TextFieldGroup from '../../common/TextFieldGroup';
import MarkerMap from '../../common/maps/markerMap';
//import ReactExport from 'react-export-excel';
import searchIcon from '../../../assets/img/ic_search.png';
import MapView from './Index';
import { BlueSmallSpinner, Spinner } from '../../common/Spinner/index';
import MapModal from '../../common/maps/MapModal';

const queryString = require('query-string');
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class VehicleListView extends Component {

  constructor(props) {
    super(props);

    const groupData = JSON.parse(localStorage.getItem('groupInfo')),
      companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    this.state = {
      selectedGroup: [],
      selectedStatus: [],
      things: [],
      search: '',
      status: '',
      count: 0,
      displayType: 'list',
      orderBy: 'asc',
      groupDepthLevel: groupData ? groupData.depthLevel : 3,
      loading: true,
      // regions: [],
      scheduleVisible: false,
      vehicleId: '',
      plateNumber: '',
      sortType: '',
      savingSingleRecord: [],
      isEngineKillVisible: false,
      showMap: false,
      excelExportItems: [],
      allThings: [],
      selectedThing: false,
      groupId: companyInfo ? companyInfo.groupId : '',
      companyId: companyInfo ? companyInfo.companyId : '',
    };
    this.closeModal = this.closeModal.bind(this);

  }

  componentDidMount() {
    (async () => {
      let { status, count, group, yesterday = false } = queryString.parse(this.props.location.search);
      yesterday === 'true' ? yesterday = true: yesterday = false;
      if (status === 'active') {
        const selectedStatus = ['IGNITION_OFF', 'DEVICE_PLUG_IN', 'IGNITION_ON', 'VEHICLE_MOVING', 'IDLE'];
        await this.setState({ ...this.state, selectedStatus, status: 'Active', count, yesterday })
      } else if (status === 'inActive') {
        const selectedStatus = ['NOT_RESPONDING', 'NEVER_HEARD', 'DEVICE_UNPLUG'];
        await this.setState({ ...this.state, selectedStatus, status: 'In Active', count, yesterday })
      }

      const actions = [this.fetchThings(group, yesterday)];

      await Promise.all(actions).then(() => this.setState({ ...this.state, loading: false }));

    })();
  }

  selectGroup = selectedGroup => this.setState({ selectedGroup });

  async fetchThings(group, yesterday) {
    group = group === 'null' ? null : group

    if (!yesterday) {
      await this.props.actions.fetchThings(true, group);
      this.setState({
        ...this.state, things: clone(this.props.things), loading: false, excelExportItems: [
          {
            columns: ['Trike', 'Group', 'Heard Time'],
            data: map(filterVehicleListing(this.props.things, this.state.selectedGroup, this.state.selectedStatus), item => {
              return [item.plateNumber, item.group, moment(item.lastObservationTime).format('DD/MM/YYYY, h:mm a')]
            })
          }
        ]
      });
    } else {
      const data = await this
        .props
        .actions
        .activeInactiveStats(group);
      let things = data.inActive;
      if (this.state.status === 'Active') {
        things = data.active;
      }
      this.setState({
        ...this.state, things: things, loading: false, allThings: things, excelExportItems: [
          {
            columns: ['Trike', 'Group', 'Heard Time'],
            data: map(things, item => {
              return [item.plateNumber, item.group, moment(item.lastObservationTime).format('DD/MM/YYYY, h:mm a')]
            })
          }
        ]
      });
    }
  }

  onChange(event) {
    const value = event.target.value;
    if (value) {
      this.setState({ ...this.state, search: value || '' });
    } else {
      this.setState({ ...this.state, search: value || '', things: this.state.yesterday ? this.state.allThings : this.props.things });
    }
  }


  searchVehicle = (e) => {
    e.preventDefault();
    const value = this.state.search;
    if (value) {
      let vehicles = filter(this.state.yesterday ? this.state.allThings : this.props.things, (item) => {
        if (item.plateNumber.search(new RegExp(value, 'i')) >= 0) {
          return item;
        }
      });

      this.setState({ ...this.state, things: vehicles, search: value || '' });

    } else {
      this.setState({ ...this.state, search: value || '', things: this.state.yesterday ? this.state.allThings : this.props.things });
    }
  };

  navigateToDashboard = () => {
    this.props.history.push('/dashboard');
  }


  async showMapLocation(item) {
    this.setState({ ...this.state, showMap: true, selectedThing: item })
  }
  async closeModal() {
    this.setState({ ...this.state, showMap: false })
  }
  async selectType(type) {
    this.props.actions.selectedStatus(this.state.selectedStatus);
    this.setState({ ...this.state, displayType: type });

  }

  render() {

    const loader = <Spinner key='1' />;

    const mapData = this.state.yesterday ? this.state.things : filterVehicleListing(this.state.things, this.state.selectedGroup, this.state.selectedStatus);
    return (
      <div>
        {this.state.showMap &&
          <MapModal showMap={this.state.showMap} close={this.closeModal} lat={this.state.selectedThing.lastLocation.lat} lng={this.state.selectedThing.lastLocation.lng}/>
          // <Modal
          //   visible={this.state.showMap}
          //   onClickBackdrop={this.modalBackdropClicked}
          // >
          //   <div>
          //     <button
          //       type='button'
          //       className='close close-x'
          //       aria-label='Close'
          //       onClick={this
          //         .closeModal
          //         .bind(this)}>
          //       <span aria-hidden='true'>&times;</span>
          //     </button>
          //   </div>
          //   <div className='modal-body'>
          //     <MarkerMap
          //       center={{ lat: this.state.selectedThing.lastLocation.lat, lng: this.state.selectedThing.lastLocation.lng }}
          //       radius={300}
          //       marker={this.state.selectedThing}
          //     >

          //     </MarkerMap>
          //   </div>
          // </Modal>}
        }
        {this.state.loading ? [loader] : <div>

          <div className='row height-50 p-t-9-px'>
            <div className='col-lg-6 col-md-6 col-sm-6 '>
              <span><button className='back-btn-listing' onClick={this.navigateToDashboard}>Back</button></span>
              <span><h4 className='m-l-20-px display-inline-block'>{this.state.status} ({this.state.count})</h4></span>

            </div>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <button className='btn auxo-primary-btn m-t-0-px' onClick={() => { this.selectType('map') }}>Map
              </button>
              <button className='btn auxo-primary-btn m-t-0-px' onClick={() => { this.selectType('list') }}>List
              </button>
            </div>
          </div>
          <hr />
          {this.state.displayType === 'list' ?
            <div>
              <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-4 margin-top-20'>
                  <form onSubmit={this.searchVehicle}>
                    <TextFieldGroup
                      field={'tsearch'}
                      placeholder={'Search Trikes'}
                      value={this.state.search}
                      type={'text'}
                      onChange={this.onChange.bind(this)}
                    />
                    <a className='search-icon' onClick={this.searchVehicle}><img src={searchIcon} alt='' /></a>
                  </form>
                </div>
                <div className='col-lg-8 col-md-8 col-sm-8 margin-top-20'>
                  {/* <div className='float-right'>
                    <ExcelFile filename='Trike Status Report' element={<button className='btn btn-success m-l-5-px'>Export</button>}>
                      <ExcelSheet dataSet={this.state.excelExportItems} name='Organization' />
                    </ExcelFile>
                  </div> */}
                </div>

              </div>



              <div className='row margin-top-20'>
                <table className='table-auxo'>
                  <thead>
                    <tr>
                      <th className='width-30'>Trikes</th>
                      <th className='width-20'>Group</th>
                      <th className='width-20'>Last Heard</th>
                      {!this.state.yesterday &&
                        <th className='width-30'>Location</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.things &&
                      map(mapData, (item) => {

                        return (
                          <tr className='vehicle-hover' key={item._id}>
                            <td className='width-30'>
                              <span>{item.plateNumber || 'N/A'}</span>

                            </td>

                            <td className='width-20'>
                              <span>{item.group || 'N/A'}</span>

                            </td>
                            <td className='width-20'>
                              <span>{item.lastObservationTime ? moment(item.lastObservationTime).format('DD/MM/YYYY, h:mm a') : 'N/A'}</span>
                            </td>
                            {!this.state.yesterday &&
                              <td className='width-30'>

                                <div>
                                  <a className='blue-color' onClick={() => this.showMapLocation(item)}>
                                   {this.state.companyId === '60e3fa684ebc8f1be843dd61' ? item.location : 'Map View'} 
                            </a>
                                </div>

                              </td>}

                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>


              </div>


            </div>
            : <MapView selectedStatus={this.state.selectedStatus} />}
        </div>
        }
      </div>
    );
  }
}

VehicleListView.propTypes = {};
VehicleListView.defaultProps = {};
const mapStateToProps = state => ({
  things: state.things.things,
  fuelGroups: state.fuelGroup.groups,
  canUpdateVehicle: state.permissions.updateVehicle,
  createGroup: state.permissions.createGroup,
  groupList: state.groups.subGroups,
  permissions: state.permissions

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Things.creators }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleListView);
