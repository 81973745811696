import React, { useState } from 'react';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import './index.scss';

const CheckListComponent = (props) => {
  const initialState = { search: '' },
    [state, setState] = useState(initialState);

  const selectAll = () => {
    props.selectAll();
  };

  const checkChildOptionList = (list, checked = false) => {
    forEach(list, i => {
      i.checked = checked;
      if (i[props.optionKeys.childKey] && i[props.optionKeys.childKey].length) checkChildOptionList(i[props.optionKeys.childKey], checked)
    });
  };

  const flattenOptionList = (list, resArr = []) => {
    forEach(list, i => {
      resArr.push(i);
      if (i[props.optionKeys.childKey] && i[props.optionKeys.childKey].length) flattenOptionList(i[props.optionKeys.childKey], resArr)
    });

    return resArr;
  };

  const onChange = index => {
    let data = [];
    const childKey = props.optionKeys.childKey,
      indexArr = map(index.split(''), i => parseInt(i, 10));
    if (props.singleSelect) {
      let newData = props.data.map(item => { item.checked = false; return item });
      data = newData[indexArr[0]];
    } else {
      data = props.data[indexArr[0]];
    }
    var obj;
    if (props.isGroupSelect) {
      obj = reduce(indexArr.slice(1), (res, val) => res[childKey][val], data);
    }
    else {
      let first;
      if (childKey && indexArr.length > 1 && indexArr[0] == 0) {
        first = indexArr.slice(1);
        first = [Number(first.join(""))];
      }
      obj = reduce(first, (res, val) => {
        return res[childKey][val]
      }, data);
    }
    obj.checked = !obj.checked;
    let resArr = [obj];

    if (obj[childKey] && obj[childKey].length) {
      checkChildOptionList(obj[childKey], obj.checked);
      resArr = resArr.concat(flattenOptionList(obj[childKey]))
    }
    props.onChange(props.data, resArr);

  };

  const search = e => {
    props.search(e);
    setState(prevState => ({ ...prevState, search: e.target.value || '' }));
  };

  const renderList = (list, padding, keyPrefix = '') => {
    return (
      <ul className='list-group checkbox-list'>
        {
          map(list, (item, index) => {
            const key = `${keyPrefix}${index}`,
              isExcluded = (
                !item[props.optionKeys.childKey] ||
                item[props.optionKeys.childKey].length === 0 ||
                (
                  props.excludeChild &&
                  includes(props.excludeChild, item[props.optionKeys.keyField])
                )
              );

            return item
              ? (
                <React.Fragment key={key}>
                  <li
                    className='list-group-item checkbox-list-item'
                    key={item[props.optionKeys.keyField]}
                    onClick={() => onChange(key)}
                    style={{ paddingLeft: `${padding}rem` }}
                  >
                    <div className='round'>
                      <input
                        type='checkbox'
                        checked={!!item.checked}
                        onChange={() => onChange(key)}
                        onClick={() => onChange(key)}
                        value={key}
                      />
                      <label htmlFor='checkbox' />
                    </div>
                    <div
                      className={`m-l-20-px text-dark ${!isExcluded ? 'group-heading' : ''}`}
                    >
                      {item[props.optionKeys.labelKey]}
                      {props.optionKeys.subLabelKey && item[props.optionKeys.subLabelKey] && (
                        <span className='text-secondary'>{item[props.optionKeys.subLabelKey]}</span>)}
                    </div>
                  </li>
                  {
                    !isExcluded &&
                    (
                      <li
                        className='list-group-item checkbox-list-item p-0'
                        style={{ paddingLeft: `${padding}rem`, borderLeft: 0 }}
                      >
                        {renderList(item[props.optionKeys.childKey], padding + 1, key)}
                      </li>
                    )
                  }
                </React.Fragment>
              )
              : '';
          })
        }
      </ul>
    )
  };

  return (
    <ul className='list-group p-0'>
      {
        props.enableSearch && (
          <li className='list-group-item border-top-0'>
            <input
              type='text'
              className='form-control'
              placeholder='Search'
              onChange={search}
              name='search'
              value={state.search}
            />
          </li>
        )
      }

      {
        props.selectAllEnabled && (
          <li className='list-group-item checkbox-list-item' onClick={() => selectAll()}>
            <div className='row'>
              <div className='round'>
                <input
                  type='checkbox'
                  checked={props.selectAllCheck}
                  onChange={selectAll}
                  onClick={selectAll}
                  value={1}
                />
                <label htmlFor='checkbox' />
              </div>
              <div className='col'><p className='ml-2'><b>Select All</b></p></div>
            </div>
          </li>
        )
      }
      <li className='list-group-item p-0'>{renderList(props.data, 1)}</li>

    </ul>
  );

};

export default CheckListComponent;

