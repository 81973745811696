import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import * as moment from "moment";
import Modal from "react-bootstrap4-modal";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Complaint from "../../../ducks/Complaint";
import TextFieldGroup from "../../common/TextFieldGroup";
import { bindActionCreators } from "redux";
import toastr from "toastr";
import * as _ from "lodash";
import Things from "../../../ducks/Things";
import './index.css';
import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';
import 'react-datepicker/dist/react-datepicker.css';

class ComplaintsListView extends Component {

  constructor() {
    super();
    this.state = {
      drivers: [],
      search: "",
      isLoadingRecords: true,
      commentsModal: false,
      showDeleteConfirmation: false,
      driverToBeDeleted: true,
      orderBy: "asc",
      scheduleVisible: false,
      savingSingleRecord: [],
      driverName: "",
      driverId: "",
      sortType: "",
      driverVehicles: [],
      page: 1,
      data: [],
      sizePerPage: 10,
      columns : [
        {
          dataField: 'Serial',
          text: 'Complaint Id'
        },
        {
          dataField: 'Reason',
          text: 'Reason'
        },
        {
          dataField: 'description',
          text: 'Description'
        }, 
        {
          dataField : 'createdAt',
          text : 'Creation Time '
        },
        {
          dataField : 'status',
          text : 'Status'
        },
        {
          dataField : 'comments',
          text : 'Comments Count',
          events: {
            onClick: async (e, column, columnIndex, row, rowIndex) => { 
              this.setState({ ...this.state, commentsModal:true,isLoadingRecords: true});
              await this.props.actions.getCommentsForComplaint(row.key);
              this.setState({...this.state, isLoadingRecords: false});
             },
          }
        }
      ]
    };
    this.getComplaints = this.getComplaints.bind(this);
  };

  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    if (row.status === "closed") {
      return (
        <button className="btn reopen-btn"
          onClick={() => {
            this.onUpdate(row);
          }}
        >
          Re-open
        </button>
      );
    } else {
      return ""
    }
  };

 async componentDidMount () {
    await this.props.actions.getComplaints(  this.composeFiltersForSearch(),this.state.sizePerPage,this.state.page);
    this.setState({ ...this.state,isLoadingRecords: false});
  }

  onUpdate() {
   console.log("onUpdate----------");
  }

  getComplaints() {
    this.props.actions.getComplaints(  this.composeFiltersForSearch());
  }

  composeFiltersForSearch() {
    const filters = {
      search: this.state.search ? this.state.search : ''
    };
    return filters;
  }

  navigateToAddComplaint() {
    this.props.history.push("/complaints/add");
  }

  async searchServerComplaint() {
    this.setState({ ...this.state,isLoadingRecords: true});
    let page = 1;
    await this.props.actions.getComplaints(  this.composeFiltersForSearch(),this.state.sizePerPage,page);
    this.setState({...this.state, page , isLoadingRecords: false});
  }

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState({ ...this.state,isLoadingRecords: true});
    await this.props.actions.getComplaints(  this.composeFiltersForSearch(),sizePerPage,page);
    this.setState({...this.state, page , isLoadingRecords: false});
  }

  searchComplaint = (e) => {
    const value = e.target.value;
    if (value) {
      this.setState({
        ...this.state,
        search: value || ""
      });
    } else {
      this.setState({
        ...this.state,
        search: ""
      });
    }
  }

  closeCommentsModal() {
    this.setState({ ...this.state, commentsModal:false});
  }

  render() {
    const isLoadingRecords = this.state.isLoadingRecords;
    const { data, sizePerPage, page } = this.state;
    const loader = <Spinner key="1" />;
    const smallLoader = <BlueSmallSpinner key="2" />;

    return (
      <>
      <Modal
          visible={this.state.commentsModal}
          onClickBackdrop={this.modalBackdropClicked}
          dialogClassName="driver-modal-dialog"
        >
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={this.closeCommentsModal.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              {" "}
              <p className="driver-heading">Comments</p>
            </div>
          </div>
          <div className="modal-body">
          <div className="row">
               <div className="col-lg-12">
               {
                this.props.complaintComments && this.props.complaintComments.length > 0 ? this.props.complaintComments.map((comment, index) => {
                  return (
                    <div key={index}>
                      <div className=''>
                        <sub>{comment.createdByUser}</sub> --  <sub>{moment(comment.createdAt).format('YYYY-MM-DD hh:mm') || 'N/A'}</sub> 
                      </div>
                      <div key={index} className='balloon'>
                        <div className='text'>{comment.comment}</div>
                      </div>
                    </div>

                  );
                }) : (<div>There are no comments</div>)
              }
                </div>
              </div>
          </div>
        </Modal>
        <div className='my-complaintscontainer'>
        <div className='tableRow'>
            <div className=" col-md-4 col-sm-4">
                        <TextFieldGroup
                          field={"search"}
                          placeholder={"Search Complaints....ComplaintId, Status"}
                          value={this.state.search}
                          type={"text"}
                          onChange={this
                            .searchComplaint
                            .bind(this)}
                            />
              </div>
              <div className=" col-md-3 col-sm-3">
                <button className="btn auxo-primary-btn addComplaintBtn" style={{'marginTop' : "0px"}} onClick={this.searchServerComplaint.bind(this)}>Search</button>
              </div>
        </div>
        <div>

          {
            isLoadingRecords ? loader : 
            <PaginationProvider
              pagination={
                paginationFactory({
                  custom: true,
                  page,
                  sizePerPage,
                  totalSize : this.props.complaintsCount
                })
              }
            >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                <div>
                  <BootstrapTable
                    remote
                    keyField="id"
                    data={ this.props.complaints }
                    columns={ this.state.columns }
                    onTableChange={ this.handleTableChange }
                    { ...paginationTableProps }
                  />
                                <div>
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </div>
                </div>
              )
            }
          </PaginationProvider>
          }
        </div>
      </div>
      </>
    );
  }
}

ComplaintsListView.propTypes = {};
ComplaintsListView.defaultProps = {};
const mapStateToProps = state => (
  { 
    vehicles: state.things.vehiclesPlateNumber, 
    complaints: state.complaints.complaints, 
    complaintsCount: state.complaints.complaintsCount,
    complaintComments: state.complaints.complaintComments,
    isLoading: state.complaints.isLoading, 
    permissions: state.permissions 
  }
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Complaint.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsListView);
