import React, { Component } from 'react';
import { connect } from "react-redux";
import Schedule from "../../../ducks/Schedule";
import Modal from 'react-bootstrap4-modal';
import { bindActionCreators } from "redux";
import './index.css';
import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';
import moment from 'moment';
import Select from 'react-select-nested-group';
import editIcon from "../../../assets/img/ic_edit.png";
import saveIcon from "../../../assets/img/ic_save.png";
import cancelIcon from "../../../assets/img/ic_cancel.png";
import trash_bin from "../../../assets/img/trash_bin.png";
import toastr from "toastr";
import * as _ from "lodash";

class VehicleAssignSchedule extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            schedule: {},
            selectedSchedule: {},
            vehicleId: "",
            plateNumber: "",
            drivers: [],
            savingSingleRecord: [],
            selectedDrivers: "",
            errors: {}
        }
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    loadData = async () => {

        this.setState({
            loading: true,
            vehicleId: "",
            plateNumber: "",
            selectedDrivers: "",
            drivers: [],
            schedule: {}
        })
        await this.props.actions.getSchedulesAndDrivers(this.props.vehicleId);
        this.setState({
            ...this.state,
            loading: false,
            plateNumber: this.props.plateNumber,
            vehicleId: this.props.vehicleId,
            drivers: this.props.drivers
        })
        if (this.props.schedules.length) {
            let data = this.props.schedules[0];
            this.handleScheduleChange({ value: data._id, label: data.name });
        }
    }

    async navigateToList() {
        this.props.closePopup()
    }
    async editMode(obj) {

        const index = obj.index;
        let schedule = this.state.schedule;
        let item = schedule.slots[index]
        item.editMode = obj.editMode;
        schedule.slots[index] = item;
        this.setState({ ...this.state, schedule })

    }
    handleDriverChange(index, selectedOption) {
        this.setState({ ...this.state, selectedDrivers: selectedOption ? selectedOption.value : null });
    }

    async assignSchedule(value) {
        try {

            let isEdit = false;

            // _.forEach(schedule.slots[value.index].occupiedBy, (item, index) => {
            //     if (item.thing === this.state.vehicleId) {
            //         schedule.slots[value.index].occupiedBy[index].driver = this.state.selectedDriver;
            //         isEdit = true;
            //     }
            // })
            // if (!isEdit) {
            //     schedule.slots[value.index].occupiedBy.push({ driver: this.state.selectedDriver, thing: this.state.vehicleId })
            // }
            if(!this.state.selectedDrivers){
                return  toastr.error("Please select a driver");
            }
            let obj = {
                scheduleId: this.state.schedule._id,
                slotId: this.state.schedule.slots[value.index]._id,
                vehicleId: this.state.vehicleId,
                driverId: this.state.selectedDrivers,
                vehicleSideEdit: isEdit
            }

            await this.props.actions.assignSlot(obj);
            setTimeout(() => {this.handleScheduleChange(this.state.selectedSchedule);
                this.setState({...this.state, selectedDrivers: ""});
            }, 600)

            toastr.success("Slots updated successfully!");


        } catch (e) {
            toastr.error(e.response ? e.response.data.message : e);
        }
    }
    async removeSchedule(obj) {

        let schedule = this.state.schedule;
        let slot = {
            scheduleId: this.state.schedule._id,
            slotId: obj.slotId,
            driverSlotId: obj.driverSlotId
        }
        await this.props.actions.removeSlot(slot);
        schedule.slots[obj.index].occupiedBy = _.filter(schedule.slots[obj.index].occupiedBy, o => {
            if (o._id.toString() !== obj.driverSlotId) {
                return o;
            }
        })
        this.setState({ ...this.state, schedule })
        toastr.success("Slots updated successfully!");

    }
    handleScheduleChange = async (selectedOption) => {
        if (selectedOption) {
            await this
                .props
                .actions
                .getSlots(selectedOption.value);

            this.setState({ ...this.state, schedule: this.props.schedule, selectedSchedule: selectedOption})
        }
    }


    render() {
        const loader = <Spinner key="1" />
        const smallLoader = <BlueSmallSpinner key="1" />;

        return (

            <Modal visible={this.props.scheduleVisible}  >
                <div className="popup-close-btn">
                    <button type="button" className="close close-x" aria-label="Close" onClick={this.navigateToList.bind(this)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                {this.state.loading ? [loader] :
                    <div>
                        <div className='col-lg-4 col-md-4 col-sm6 form-group'>
                            <h4>{this.state.plateNumber}</h4>
                            <Select
                                name="form-field-name"
                                isClearable={false}
                                isSearchable={true}
                                defaultValue={{ value: this.props.schedules[0]._id, label: this.props.schedules[0].name }}
                                placeholder="Select Schedule"
                                onChange={this.handleScheduleChange}
                                options={this.props.schedules && this
                                    .props
                                    .schedules
                                    .map(item => {
                                        return { value: item._id, label: item.name }
                                    })} />
                        </div>
                        <div>

                            <div className="modal-body model-hight">
                                {this.state.schedule && this.state.schedule.slots ?
                                    <div className="row">

                                        <table className="table-auxo">
                                            <thead>
                                                <tr>
                                                    <th>Start Day </th>
                                                    <th>End Day </th>
                                                    <th>Start Time </th>
                                                    <th>End Time </th>
                                                    <th>Driver</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.schedule && this
                                                    .state
                                                    .schedule.slots
                                                    .map((item, index) => {
                                                        let driverFound = { found: false, driverSlotId: null };
                                                        let timestart = moment().set({"hours":item.startTime, "minutes":item.startMinutes || 0});
                                                        let timeend = moment().set({"hours":item.endTime, "minutes":item.endMinutes || 0});

                                                        return (
                                                            <tr key={index} className="driver-listing">
                                                                <td className="edit-field">
                                                                    {moment().isoWeekday(item.startDay).format("dddd")}

                                                                </td>
                                                                <td className="edit-field">
                                                                    {moment().isoWeekday(item.endDay).format("dddd")}
                                                                </td>
                                                                <td className="edit-field">
                                                                    {moment(timestart).format("hh:mm A")}

                                                                </td>
                                                                <td className="edit-field">
                                                                    {moment(timeend).format("hh:mm A")}
                                                                </td>
                                                                <td className="edit-field">
                                                                    {item.editMode ?
                                                                        <Select
                                                                            name="form-field-name"
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            onChange={this.handleDriverChange.bind(this, index)}
                                                                            options={this.state.drivers && this
                                                                                .state
                                                                                .drivers
                                                                                .map(item => {

                                                                                    return { value: item._id, label: item.name }
                                                                                })} /> : <span>  {item.occupiedBy.length
                                                                                    ? item.occupiedBy
                                                                                        .map((obj) => {

                                                                                            return <span>{
                                                                                                obj.thing.toString() === this.state.vehicleId ? <span>{this.state.drivers && this
                                                                                                    .state
                                                                                                    .drivers
                                                                                                    .map(item => {
                                                                                                        driverFound = { found: true, driverSlotId: obj._id }
                                                                                                        return <span>{item._id.toString() === obj.driver.toString() ? item.name : ""} </span>
                                                                                                    })}</span>
                                                                                                    : ""
                                                                                            }</span>
                                                                                        }) : ""}
                                                                            {driverFound.found ? "" : "N/A"}
                                                                        </span>}

                                                                </td>
                                                                <td className="last-column-width">
                                                                    {this.state.savingSingleRecord[index] && this.state.savingSingleRecord[index] === true ? [smallLoader] : <div>
                                                                        {item.editMode ? <div>
                                                                            <a onClick={this.assignSchedule.bind(this, { index, editMode: false })}><img className="save-records" src={saveIcon} alt="" /></a>
                                                                            <a onClick={this.editMode.bind(this, { index, editMode: false })} ><img src={cancelIcon} alt="" /></a></div>
                                                                            : <div>

                                                                                 {driverFound.found ? <a onClick={this.removeSchedule.bind(this, { index, slotId: item._id, driverSlotId: driverFound.driverSlotId })}><img src={trash_bin} className="width16" alt="" /></a> :  <a onClick={this.editMode.bind(this, { index, editMode: true })}><img src={editIcon} alt="" /></a>
                                                                              }

                                                                            </div>}
                                                                    </div>}
                                                                </td>


                                                            </tr>
                                                        )
                                                    })}


                                            </tbody>
                                        </table>


                                    </div> : ""}



                            </div>
                            <div className="modal-footer model-btn width100">
                                <button
                                    className="btn close-btn"
                                    onClick={this.navigateToList.bind(this)}>
                                    Close
                    </button>

                            </div>
                        </div>

                    </div>}

            </Modal>

        );
    }
}

VehicleAssignSchedule.propTypes = {};
VehicleAssignSchedule.defaultProps = {};
const mapStateToProps = state => ({ schedule: state.schedule.schedule, schedules: state.schedule.schedules, drivers: state.schedule.drivers });

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Schedule.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleAssignSchedule);
