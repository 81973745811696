import React from 'react';
import { compose } from 'recompose';


export const ReportsListing = compose()(props => {

  return (
    <ul className='reports-list'>
      {props.isRoster ? 
      <li>
      <span>
        <h4>Roster</h4>
        <p>Roster Report</p>
      </span>
      <span className='select-report'><a onClick={() => props.selectType('rosterLog')}>select</a></span>
    </li> : 
     <li>
     <span>
       <h4>Rescue Cellsites</h4>
       <p>Rescue Cellsite report</p>
     </span>
     <span className='select-report'><a onClick={() => props.selectType('rescueCellsite')}>select</a></span>
   </li>
  }
     
      
      
      
       
    </ul>
  )
});
