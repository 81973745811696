import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import Modal from "react-bootstrap4-modal";

import toastr from "toastr";

import * as R from "ramda";
import { clone, filter, map, orderBy } from "lodash";

import { UncontrolledTooltip } from "reactstrap";
import Select from "react-select-nested-group";
import Icon from "react-icons-kit";
import DateTime from 'react-datetime';

import Complaint from "../../../ducks/Complaint";
import Things from "../../../ducks/Things";
import FuelGroup from "../../../ducks/FuelGroup";
import Groups from "../../../ducks/Groups";
import Patrolling from "../../../ducks/Patrolling";

import { partyFields,engrofields, kElectricFields } from "../../../api/endpoints";
import { BlueSmallSpinner, Spinner } from "../../common/Spinner/index";
import { isAlphaNumeric } from "../../../validations/validator";
import { filterVehicleList } from "../../../utils/helper.util";
import TextFieldGroup from "../../common/TextFieldGroup";
import SelectComponent from "../../select";
import iconsList from '../../../static/icons-List.static';

import "./index.css";

import { ic_warning } from "react-icons-kit/md/ic_warning";
import searchIcon from "../../../assets/img/ic_search.png";
import dropdownIcon from "../../../assets/img/ic_dropdown_down.png";
import editIcon from "../../../assets/img/ic_edit.png";
import complaint from "../../../assets/img/complaint.png";
import saveIcon from "../../../assets/img/ic_save.png";
import cancelIcon from "../../../assets/img/ic_cancel.png";
import VehicleAssignSchedule from "./schedule";
import EngineKillModal from "./engineKill";
import calenderIcon from "../../../assets/img/calender.png";
import ic_visibility from "../../../assets/img/score.png";
import pointerIcon from '../../../assets/img/ic_speedometer_grey.png';
import pin_moving from '../../../assets/img/pin_moving.png';
import pin_pluggedout from '../../../assets/img/pin_pluggedout.png';
import pin_parked from '../../../assets/img/pin_parked.png';
import pin_ignition from '../../../assets/img/pin_ignition.png';
import pin_idle_shadow from '../../../assets/img/pin_idle_shadow.png'; 



const { CustomOdoMeterSetting } = require('../../../config/config.json');



    const typesDict = [
      {
        value: "car",
        label: "car",
      },
      {
        value: "van",
        label: "van",
      },
      {
        value: "container",
        label: "container",
      },
      {
        value: "truck",
        label: "truck",
      },
      {
        value: "tanker",
        label: "tanker",
      },
      {
        value: "bike",
        label: "bike",
      },
    ];

    let companyExists=false;
    let engroExists=false;
    let kElectricExists=false;
    const vehicleStatusMap = {
      "IGNITION_ON": {
          'status' : "Ignition",
          'pinIcon': pin_ignition,
          'statusClass' : "fa fa-circle circle-green",
          'style' : { transform: "rotate(110deg)" }
        },
        "VEHICLE_MOVING": {
          'status' : "Moving",
          'pinIcon' : pin_moving,
          'statusClass' : "fa fa-circle circle-blue"
        },
        "IGNITION_OFF": {
          'status' : "Parked",
          'pinIcon' : pin_parked,
          'statusClass' : "fa fa-circle circle-orange",
        },
        "DEVICE_UNPLUG": {
          'status' : "Unplug",
          'pinIcon' : pin_pluggedout,
          'statusClass' : "fa fa-circle circle-red"
        },
        "DEVICE_PLUG_IN": {
          'status' : "Parked",
          'pinIcon' : pin_parked,
          'statusClass' : "fa fa-circle circle-orange",
        },
        "NEVER_HEARD": {
          'status' : "Not Installed",
          'pinIcon' : pin_pluggedout,
          'statusClass' : "fa fa-circle circle-red"
        },
        "NOT_RESPONDING" : {
          'status' : "Inactive",
          'pinIcon' : pin_pluggedout,
          'statusClass' : "fa fa-circle circle-red"
        },
        "SUSPENDED": {
          'status' : "Offline",
          'pinIcon' : pin_pluggedout,
          'statusClass' : "fa fa-circle circle-red"
        },
        "IDLE": {
          'status' : "Idle",
          'pinIcon' : pin_idle_shadow,
          'statusClass' : "fa fa-circle circle-gray",
          'style' : {
            transform: "rotate(200deg)",
            marginBottom: "4px",
            width: "20px",
          }
        },
        "HELMET_IDLE": {
          'status' : "Idle",
          'pinIcon' : pin_idle_shadow,
          'statusClass' : "fa fa-circle circle-gray",
          'style' : {
            transform: "rotate(200deg)",
            marginBottom: "4px",
            width: "20px",
          }
        },
        "HELMET_NOT_WEARING_DRIVING": {
          'status' : "Not wearing but driving",
          'pinIcon' : pin_pluggedout,
          'statusClass' : "fa fa-circle circle-red",
        },
        "HELMET_WEARING_DRIVING": {
          'status' : "Wearing Driving",
          'pinIcon' : pin_moving,
          'statusClass' : "fa fa-circle circle-blue"
        },
        "HELMET_WEARING": {
          'status' : "Wearing at Stop",
          'pinIcon' : pin_ignition,
          'statusClass' : "fa fa-circle circle-green",
          'style' : { transform: "rotate(110deg)" }
        },
        "HELMET_PARKED": {
          'status' : "Parked",
          'pinIcon' : pin_parked,
          'statusClass' :"fa fa-circle circle-orange"
        }
    };
    
  const defaulVehicletStatus = {
    status: 'Offline',
    pinIcon: pin_pluggedout,
    statusClass: 'fa fa-circle circle-red'
  };

class VehicleListView extends Component {
  constructor(props) {
    super(props);

    const groupData = JSON.parse(localStorage.getItem("groupInfo")),
      companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

      let result = partyFields.indexOf(companyInfo.companyId);
      let engroResult = engrofields.indexOf(companyInfo.companyId);
      let kElectricResult = kElectricFields.indexOf(companyInfo.companyId);
      if (result != -1) {
        companyExists = true;
      }

      if (engroResult != -1) {
        engroExists = true;
      }

      if (kElectricResult != -1) {
        kElectricExists = true;
      }


    this.state = {
      selectedGroup: [],
      selectedStatus: null,
      things: [],
      search: "",
      deviceImei: "",
      deviceMsisdn:'',
      devicePartyName:'',
      devicePartyId:'',
      deviceChasisNumber:'',
      devicePartyTerritory:'',
      devicedepartment:'',
      devicezone:'',
      deviceenginepower:'',
      deviceNoStatus:'',
      devicePlateNo:'',
      deviceMakeModel:'',
      devicePOCname:'',
      devicePOCNo:'',
      deviceLocation:'',
      description:'',
      selectedReason:{},
      reasonsList : [
        {'label':'Not Responding', 'value' : 'notResponding'},
        {'label':'Inactive Status', 'value' : 'inActiveStatus'},
        {'label':'Removal', 'value' : 'removal'},
        {'label':'Missing Data', 'value' : 'missingData'},
        {'label':'Delayed Reporting', 'value' : 'delayedReporting'},
        {'label':'Misc.', 'value' : 'misc'}
      ],
      recommendedDate : new Date(),
      orderBy: "asc",
      groupDepthLevel: groupData ? groupData.depthLevel : 3,
      loading: true,
      creaComplaint:false,
      // regions: [],
      scheduleVisible: false,
      vehicleId: "",
      plateNumber: "",
      sortType: "",
      savingSingleRecord: [],
      isEngineKillVisible: false,
      groupId: companyInfo ? companyInfo.groupId : "",
      editGroup: {},
      odoMeterUpdated: false,
      companyId : "",
      vehicleRegistrationNo: '',
      category: '',
      region: '',
      cluster: '',
      ibcDepartment: '',
      shiftHrs: '',
      benchmark: '',
      shiftTimings: ''  
    };
    this.showEngineKill = this.showEngineKill.bind(this);
    this.closeEngineKill = this.closeEngineKill.bind(this);
    this.onEngineKill = this.onEngineKill.bind(this);
    this.onEngineKillConfirm = this.onEngineKillConfirm.bind(this);
    this.onEngineRelease = this.onEngineRelease.bind(this);
    this.onEngineReleaseConfirm = this.onEngineReleaseConfirm.bind(this);
    this.getExpiry = this.getExpiry.bind(this);
    this.onAbortEngineKill = this.onAbortEngineKill.bind(this);
    this.onAbortEngineRelease = this.onAbortEngineRelease.bind(this);
  }

  componentDidMount() {
    (async () => {
      
      const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
      this.state.companyId = companyInfo.companyId;

      const actions = [this.fetchThings()];
      this.companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
      if (
        this.state.groupId !== "" &&
        !(this.props.groupList && this.props.groupList.length)
      )
        actions.push(
          this.props.dispatch(Groups.creators.getSubGroups(this.state.groupId))
        );

      await Promise.all(actions).then(() =>
        this.setState({ ...this.state, loading: false })
      );
      // if (this.props.permissions.companyFeatures.indexOf("PATROLLING") !== -1) {
      //   const regions = await this.props.actions.listPatrollingRegions();
      //   this.setState({ ...this.state, regions })
      // }
    })();
    
  }

  valid = ( current) =>  current.isAfter(moment()) && current.isBefore(moment().add(15,'days'))

  selectGroup = (selectedGroup) => this.setState({ selectedGroup });

  selectStatus = (selectedStatus) => this.setState({ selectedStatus });

  async onSubmitComplaint() {
    try {
      if (!this.state.selectedReason[0]){
        return toastr.error("Please Select Reason");
      }

      if (!this.state.devicePOCNo){
        return toastr.error("Please Select Device POC No");
      }

      if (!this.state.devicePOCname){
        return toastr.error("Please Select Device POC name");
      }

      if (!this.state.deviceLocation){
        return toastr.error("Please Select Device Location");
      }


      const complaintInfo = {
        deviceImei: this.state.deviceImei,
        deviceMsisdn:this.state.deviceMsisdn,
        deviceNoStatus:this.state.deviceNoStatus,
        devicePlateNo:this.state.devicePlateNo,
        description:this.state.description || null,
        deviceMakeModel:this.state.deviceMakeModel,
        devicePOCname:this.state.devicePOCname,
        devicePOCNo:this.state.devicePOCNo,
        deviceLocation:this.state.deviceLocation,
        selectedReason:this.state.selectedReason[0]['value'],
        deviceId : this.state.deviceId
      };

      if (companyExists) {
        complaintInfo['devicePartyName'] = this.state.devicePartyName;
        complaintInfo['devicePartyTerritory'] = this.state.devicePartyTerritory;
        complaintInfo['devicePartyId']=this.state.devicePartyId;
        complaintInfo['deviceChasisNumber']=this.state.deviceChasisNumber;
      }
      if (engroExists) {
        complaintInfo['devicedepartment'] = this.state.devicedepartment;
        complaintInfo['devicezone']=this.state.devicezone;
        complaintInfo['deviceenginepower']=this.state.deviceenginepower;
      }
      if (kElectricExists) {
        complaintInfo['vehicleRegistrationNo'] = this.state.vehicleRegistrationNo;
        complaintInfo['category']=this.state.category;
        complaintInfo['region']=this.state.region;
        complaintInfo['cluster']=this.state.cluster;
        complaintInfo['ibc']=this.state.ibcDepartment;
        complaintInfo['shifthours']=this.state.shiftHrs;
        complaintInfo['shifttime']=this.state.shiftTimings;
        complaintInfo['benchmark']=this.state.benchmark;
      }

      this.setState({ ...this.state,loading : true});
      await this.props.actions.addComplaint(complaintInfo);
      this.setState({ ...this.state,loading : false});
      toastr.success("Complaint Launched successfully!");
      this.props.history.push("/complaints");
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
}

  async fetchThings() {
    
    await Promise.all([
      this.props.actions.fetchThings(),
      this.props.actions.getFuelGroup(),
    ]).then(() => {
      this.setState({ ...this.state, things: clone(this.props.things) });
      
    });
  }

  onChangeComplaint = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: "",
      },
    });
  };

  onChange(event) {
    const value = event.target.value;
    if (value) {
      this.setState({ ...this.state, search: value || "" });
    } else {
      this.setState({
        ...this.state,
        search: value || "",
        things: this.props.things,
      });
    }
  }

  sortColumn(type) {
    let vehicles = [];
    const sortColumn = clone(type);
    if (type === "odoMeterReading") {
      type = function (o) {
        return Number(o.odoMeterReading);
      };
    } else {
      type = [type];
    }
    if (this.state.orderBy === "asc") {
      vehicles = orderBy(this.state.things, type, ["desc"]);
      this.setState({
        ...this.state,
        orderBy: "desc",
        things: vehicles,
        sortType: sortColumn,
      });
    } else {
      vehicles = orderBy(this.state.things, type, ["asc"]);
      this.setState({
        ...this.state,
        orderBy: "asc",
        things: vehicles,
        sortType: sortColumn,
      });
    }
  }

  async editMode(obj) {
    if (this.props.canUpdateVehicle) {
      let item = {};
      const index = obj.index;
      let things = this.state.things;
      if (obj.cancelSave) {
        item = { ...this.props.things[index] };
      } else {
        item = { ...things[index] };
      }

      item.editMode = obj.editMode;
      things[index] = item;
      this.setState({ ...this.state, things, editGroup: item.group });
    } else {
      toastr.error(
        "You have no access to perform this action. please contact system administrator!"
      );
    }
  }

  async openComplaint(item,status) {
    let data = {
      creaComplaint: true, 
      deviceImei : item.imei,
      deviceMsisdn:item.msisdn,
      deviceNoStatus:status,
      devicePlateNo:item.plateNumber,
      deviceMakeModel:item.vehicleMakeAndModel,
      deviceId : item._id
    };

    if (companyExists) {
      data['devicePartyName']=item.partyName;
      data['devicePartyTerritory']=item.partyTerritory;
      data['devicePartyId']=item.partyId;
      data['deviceChasisNumber']=item.chasisNumber;
    };
    if (engroExists) {
      data['devicedepartment'] = item.devicedepartment;
      data['devicezone']= item.devicezone;
      data['deviceenginepower']= item.deviceenginepower;
    }
    if (kElectricExists) {
      data['vehicleRegistrationNo'] = item.vehicleRegistrationNo;
      data['category']= item.category;
      data['cluster']= item.cluster;
      data['region'] = item.region;
      data['ibc']= item.ibc;
      data['shifthours']= item.shifthours;
      data['shifttime']= item.shifttime;
      data['benchmark']= item.benchmark;
    }
    this.setState(data);
  }

  closeComplaintModal() {
    this.setState({ creaComplaint: false });
  }

  setRecommendedDate(e) {
    this.setState({ recommendedDate : e });
  }

  selectReason = async (selectedReason) =>{
    if(selectedReason && selectedReason[0] && selectedReason[0].value){
      this.setState({ 'selectedReason' : selectedReason });
    } else {
      this.setState({ 'selectedReason' : {} });
    }
  };

  searchVehicle = (e) => {
    e.preventDefault();
    const value = this.state.search;
    if (value) {
      let vehicles = filter(this.props.things, (item) => {
        if (
          item.plateNumber.search(new RegExp(value, "i")) >= 0 ||
          item.imei.search(new RegExp(value, "i")) >= 0 ||
          item.vehicleMakeAndModel.search(new RegExp(value, "i")) >= 0
        ) {
          return item;
        }
      });

      this.setState({ ...this.state, things: vehicles, search: value || "" });
    } else {
      this.setState({
        ...this.state,
        search: value || "",
        things: this.props.things,
      });
    }
  };
  onChangeValue = (e) => {
    const index = e.target.id;
    let things = this.state.things;
    let item = things[index];
    item[e.target.name] = e.target.value;

    // if(this.state.odoMeterUpdated==true) {
    //   item["lastOdoMeterUpdateTime"] = new Date().toISOString() //moment(new Date()).format("DD:MM:YY hh:mm A")
    // }
    things[index] = item;

    if(e.target.name === "odoMeterReading") {
      item["lastOdoMeterUpdateTime"] = new Date().toISOString()
      //this.state.odoMeterUpdated = true;
    }
    this.setState({ ...this.state, things });
  };

  editGroupValue = (e, index) => {
    if (e) {
      let things = this.state.things;
      let item = things[index];
      item.group = e[0].label;
      things[index] = item;
      this.setState({ ...this.state, things, editGroup: e[0] });
    }
  };

  editIconValue = (e, index) => {
    if (e) {
      let things = this.state.things;
      let item = things[index];
      item.icon = e[0].label;
      things[index] = item;
      this.setState({ ...this.state, things });
    }
  };

  selectChangedThingType = ({ index, value }) => {
    let things = this.state.things;
    let item = things[index];
    item.thingType = value;
    this.setState({ ...this.state, things });
  };

  selectChanged = ({ index, value }) => {
    let things = this.state.things;
    let item = things[index];
    const [fuelGroup] = this.props.fuelGroups.filter(
      (fuelGroup) => fuelGroup._id === value
    );
    item.fuelGroup = fuelGroup;
    this.setState({ ...this.state, things });
  };
  selectRegionChanged = ({ index, value }) => {
    let things = this.state.things;
    let item = things[index];
    item.patrollingRegion = value;
    this.setState({ ...this.state, things });
  };
  changeCheckValue = (index, target) => {
    let things = this.state.things;
    let item = things[index];
    if (target.name === "patrolling") {
      item.isPatrollingVehicle = !item.isPatrollingVehicle;
    }
    if (target.name === "admin") {
      item.isAdminVehicle = !item.isAdminVehicle;
    }
    this.setState({ ...this.state, things });
  };

  showEngineKill({ thing }) {
    this.setState({ isEngineKillVisible: true, thing });
  }

  vehicleScrore(id) {

    this.props.history.push(`/vehicles/scorecard/${id.thing._id}/${id.thing.plateNumber}`);
  }

  closeEngineKill() {
    this.setState({ isEngineKillVisible: false, thing: null });
  }

  selectChanged2 = ({ index, value }) => {
    let things = this.state.things;
    let item = things[index];
    const [fuelGroup] = this.props.fuelGroups.filter(
      (fuelGroup) => fuelGroup._id === value
    );
    item.fuelGroup = fuelGroup;
    this.setState({ ...this.state, things });
  };

  async onSubmit(value) {
    try {
      const data = clone(this.state.things[value.index]);

      if (!data.plateNumber) {
        return toastr.error("Plate number is required!");
      }
      if (data.plateNumber.length > 12 || !isAlphaNumeric(data.plateNumber)) {
        return toastr.error(
          `Plate Number for ${data.plateNumber} must be alphanumeric and less than or equel to 12 characters.`
        );
      }
      if (
        data.vehicleMakeAndModel &&
        (data.vehicleMakeAndModel.length > 20 ||
          !isAlphaNumeric(data.vehicleMakeAndModel))
      ) {
        return toastr.error(
          `Make and model for ${data.vehicleMakeAndModel} must be alphanumeric and less than or equel to 20 characters.`
        );
      }
      data.odoMeterReading = data.odoMeterReading
        ? parseInt(data.odoMeterReading, 10)
        : 0;
      if (isNaN(data.odoMeterReading) || data.odoMeterReading < 0) {
        return toastr.error("Invalid odo meter for " + data.odoMeterReading);
      }
      // data.patrollingRegion = data.patrollingRegion && data.patrollingRegion.length ? map(data.patrollingRegion, item => {return item.value}): [];


      this.setState({
        ...this.state,
        savingSingleRecord: Object.assign([...this.state.savingSingleRecord], {
          [value.index]: true,
        }),
      });
     
      //console.log("Data to Save: \n", this.state);
      await this.props.actions.saveRecords({ data: [data] });
      this.setState({
        ...this.state,
        savingSingleRecord: Object.assign([...this.state.savingSingleRecord], {
          [value.index]: false,
        }),
      });
      this.editMode(value);
    } catch (err) {
      this.setState({
        ...this.state,
        savingSingleRecord: Object.assign([...this.state.savingSingleRecord], {
          [value.index]: false,
        }),
      });
      toastr.error(err.response.data.message);
    }
  }

  async exportVehicles() {
    try {
      let status=null;
      if (!!this.state.selectedStatus) { 
        status = this.state.selectedStatus.value
      }
      await this.props.actions.exportVehicles(status);
    } catch (err) {
      toastr.error(err.response.data.message);
    }
  }

  navigateToAssignVehicles() {
    if (this.props.canUpdateVehicle) {
      this.props.history.push("/vehicle/assignment");
    } else {
      toastr.error(
        "You have no access to perform this action. please contact system administrator!"
      );
    }
  }

  async showSchedule(data) {
    await this.setState({
      ...this.state,
      vehicleId: data._id,
      plateNumber: data.plateNumber,
      scheduleVisible: true,
    });
    this.child.loadData();
  }

  closePopup() {
    this.setState({
      ...this.state,
      scheduleVisible: false,
    });
  }

  async onEngineKill(_thing) {
    const { thing } = await this.props.actions.engineKill(_thing);
    await this.fetchThings();
    this.setState({ thing });
  }

  async onEngineKillConfirm(thing, code) {
    try {
      await this.props.actions.engineKillConfirm(thing, code);
      await this.fetchThings();
      toastr.success("Engine kill initiated.");
      this.closeEngineKill();
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  }

  async onAbortEngineKill(id) {
    try {
      await this.props.actions.abortEngineKill(id);
      this.closeEngineKill();
      await this.fetchThings();
      toastr.success("Engine Kill Aborted");
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  }

  async onEngineRelease(_thing) {
    const { thing } = await this.props.actions.releaseEngine(_thing);
    await this.fetchThings();
    this.setState({ thing });
  }

  async onEngineReleaseConfirm(thing, code) {
    try {
      await this.props.actions.engineReleaseConfirm(thing, code);
      await this.fetchThings();
      toastr.success("Engine release initiated.");
      this.closeEngineKill();
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  }

  async getExpiry() {
    const { thing } = this.state;
    const { _id } = thing;
    const code = await this.props.actions.engineKillCode(_id);
    return code;
  }

  async onAbortEngineRelease(id) {
    try {
      await this.props.actions.abortEngineRelease(id);
      this.closeEngineKill();
      await this.fetchThings();

      toastr.success("Engine Kill Aborted");
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  }

  async openDetailsPage(item) {
    if (this.props.permissions.companyFeatures.indexOf("MAINTENANCE") !== -1) {
      this.props.history.push("/vehicles/details/" + item._id);
    }
  }

  // setStartTime(e,index){
  //   let things = this.state.things;
  //   let item = things[index];
  //   item["shiftStart"] = moment(e).format("HH:mm");

  //   things[index] = item;
  //   this.setState({ ...this.state, things, shiftStart: moment(e).format("HH:mm")})
  // }
  // async setEndTime(e, index){
  //   let things = this.state.things;
  //   let item = things[index];
  //   item["shiftEnd"] = moment(e).format("HH:mm");

  //   things[index] = item;
  //   await this.setState({ ...this.state, things: {...this.state.things, shiftEnd: moment(e).format("HH:mm")}})
  // }

  render() {
    const { fuelGroups } = this.props;
    // const fuelGroupList = fuelGroups.map(fuelGroup => <MenuItem key={fuelGroup._id}
    //                                                             value={fuelGroup._id}>{fuelGroup.groupName}</MenuItem>);

    const loader = <Spinner key="1" />;
    const smallLoader = <BlueSmallSpinner key="1" />;

    const status = [
      { label: "OFFLINE", value: "OFFLINE" },
      { label: "UNPLUG", value: "DEVICE_UNPLUG" },
      { label: "IGNITION", value: "IGNITION_ON" },
      { label: "MOVING", value: "VEHICLE_MOVING" },
      { label: "PARKED", value: "IGNITION_OFF" },
      { label: "NOT INSTALLED", value: "NEVER_HEARD" },
      { label: "INACTIVE", value: "NOT_RESPONDING" },
      { label: "IDLE", value: "IDLE" },
    ];

    
    return (
      <div>
        {this.state.loading ? (
          [loader]
        ) : (
            <div>
            {( 
              <Modal
                visible={this.state.creaComplaint}
                onClickBackdrop={this.modalBackdropClicked}
                dialogClassName="driver-modal-dialog"
              >
                <div>
                  <button
                    type="button"
                    className="close close-x"
                    aria-label="Close"
                    onClick={this.closeComplaintModal.bind(this)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-heading row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    {" "}
                    <p className="driver-heading">New Complaint</p>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="row rowDiv">
                      <div className="col-lg-12 flex">
                        <span style={{width : "50%"}}>Device Imei </span>
                        <span style={{width : "50%"}}>{this.state.deviceImei}</span>
                      </div>
                  </div>
                  <div className="row rowDiv">
                      <div className="col-lg-12 flex">
                        <span style={{width : "50%"}}>Device Msisdn </span>
                        <span style={{width : "50%"}}>{this.state.deviceMsisdn}</span>
                      </div>
                  </div>

                  {
                    engroExists &&
                    <>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Department </span>
                            <span style={{width : "50%"}}>{this.state.devicedepartment}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Zone </span>
                            <span style={{width : "50%"}}>{this.state.devicezone}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Engine Power </span>
                            <span style={{width : "50%"}}>{this.state.deviceenginepower}</span>
                          </div>
                      </div>
                    </>
                  }

                  {
                    kElectricExists &&
                    <>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Vehicle Registration No.</span>
                            <span style={{width : "50%"}}>{this.state.vehicleRegistrationNo}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Category</span>
                            <span style={{width : "50%"}}>{this.state.category}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Region</span>
                            <span style={{width : "50%"}}>{this.state.region}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Cluster</span>
                            <span style={{width : "50%"}}>{this.state.cluster}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>IBC / VBC / Department</span>
                            <span style={{width : "50%"}}>{this.state.ibcDepartment}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Shift (Hrs)</span>
                            <span style={{width : "50%"}}>{this.state.shiftHrs}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Benchmark</span>
                            <span style={{width : "50%"}}>{this.state.benchmark}</span>
                          </div>
                      </div>
                    </>
                  }
                  {
                    companyExists &&
                    <>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Party Name </span>
                            <span style={{width : "50%"}}>{this.state.devicePartyName}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Party Id </span>
                            <span style={{width : "50%"}}>{this.state.devicePartyId}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Chasis No </span>
                            <span style={{width : "50%"}}>{this.state.deviceChasisNumber}</span>
                          </div>
                      </div>
                      <div className="row rowDiv">
                          <div className="col-lg-12 flex">
                            <span style={{width : "50%"}}>Device Party Territory</span>
                            <span style={{width : "50%"}}>{this.state.devicePartyTerritory}</span>
                          </div>
                      </div>
                    </>
                  }

                  <div className="row rowDiv">
                      <div className="col-lg-12 flex">
                        <span style={{width : "50%"}}>Device No Status </span>
                        <span style={{width : "50%"}}>{this.state.deviceNoStatus}</span>
                      </div>
                  </div>
                  <div className="row rowDiv">
                      <div className="col-lg-12 flex">
                        <span style={{width : "50%"}}>Device Plate No </span>
                        <span style={{width : "50%"}}>{this.state.devicePlateNo}</span>
                      </div>
                  </div>
                  <div className="row rowDiv">
                      <div className="col-lg-12 flex">
                        <span style={{width : "50%"}}>Device Make Model </span>
                        <span style={{width : "50%"}}>{this.state.deviceMakeModel}</span>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 flex">
                      <span style={{width : "50%"}}>Reason</span>
                      <span style={{width : "50%"}}>
                        <SelectComponent
                            field="form-field-name"
                            placeholder="Select Reason"
                            preSelected={this.state.selectedReason}
                            optionKeys={{ 'label' : 'label', 'value':'label'}}
                            options={this.state.reasonsList}
                            onChange={this.selectReason}
                          />
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 flex">
                      <span style={{width : "50%"}}>Complaint Description</span>
                        <span style={{width : "50%"}}>
                            <TextFieldGroup
                              field={"description"}
                              placeholder={"Enter Complaint Description"}
                              value={this.state.description}
                              type={"text"}
                              onChange={this.onChangeComplaint.bind(this)}
                            />
                        </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 flex">
                      <span style={{width : "50%"}}>POC NAME</span>
                        <span style={{width : "50%"}}>
                            <TextFieldGroup
                              field={"devicePOCname"}
                              placeholder={"Enter POC Name"}
                              value={this.state.devicePOCname}
                              type={"text"}
                              onChange={this.onChangeComplaint.bind(this)}
                            />
                        </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 flex">
                      <span style={{width : "50%"}}>POC Contact Number</span>
                        <span style={{width : "50%"}}>
                            <TextFieldGroup
                              field={"devicePOCNo"}
                              placeholder={"Enter POC Contact Number"}
                              value={this.state.devicePOCNo}
                              type={"text"}
                              onChange={this.onChangeComplaint.bind(this)}
                            />
                        </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 flex">
                      <span style={{width : "50%"}}>Vehicle Location</span>
                        <span style={{width : "50%"}}>
                            <TextFieldGroup
                              field={"deviceLocation"}
                              placeholder={"Enter Vehicle Location"}
                              value={this.state.deviceLocation}
                              type={"text"}
                              onChange={this.onChangeComplaint.bind(this)}
                            />
                        </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 flex">
                      <span style={{width : "50%"}}>Recommended Date</span>
                        <span style={{width : "50%"}}>
                          <DateTime isValidDate={ this.valid.bind(this) } value={this.state.recommendedDate} dateFormat="DD-MM-YYYY" timeFormat="HH:mm" onChange={this.setRecommendedDate.bind(this)} />
                        </span>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="close-btn" onClick={this.onSubmitComplaint.bind(this)}>
                      Save
                  </button>
                </div>
              </Modal>
            )}
              {this.state.isEngineKillVisible && (
                <EngineKillModal
                  isOpen={
                    this.state.isEngineKillVisible &&
                    this.props.permissions.companyFeatures.indexOf(
                      "ENGINE:KILL"
                    ) !== -1 &&
                    this.props.permissions.engineKill
                  }
                  closeAction={this.closeEngineKill}
                  thing={this.state.thing}
                  onEngineKillConfirm={this.onEngineKillConfirm}
                  onEngineKill={this.onEngineKill}
                  onAbortEngineKill={this.onAbortEngineKill}
                  onEngineRelease={this.onEngineRelease}
                  onEngineReleaseConfirm={this.onEngineReleaseConfirm}
                  onAbortEngineRelease={this.onAbortEngineRelease}
                  getExpiry={this.getExpiry}
                />
              )}
              <VehicleAssignSchedule
                plateNumber={this.state.plateNumber}
                vehicleId={this.state.vehicleId}
                scheduleVisible={this.state.scheduleVisible}
                onRef={(ref) => (this.child = ref)}
                closePopup={this.closePopup.bind(this)}
              />

              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 margin-top-20">
                  <form onSubmit={this.searchVehicle}>
                    <TextFieldGroup
                      field={"tsearch"}
                      placeholder={"Search Vehicle"}
                      value={this.state.search}
                      type={"text"}
                      onChange={this.onChange.bind(this)}
                    />
                    <a className="search-icon" onClick={this.searchVehicle}>
                      <img src={searchIcon} alt="" />
                    </a>
                  </form>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 margin-top-20">
                  {this.props.permissions.companyFeatures.indexOf("GROUPS") !==
                    -1 && (
                      <div>
                        <SelectComponent
                          field="form-field-name"
                          placeholder="Select Group"
                          preSelected={this.state.selectedGroup}
                          multi={true}
                          optionKeys={{ label: "name", value: "_id" }}
                          options={this.props.groupList}
                          onChange={this.selectGroup}
                        />
                      </div>
                    )}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 margin-top-20">
                  <div>
                    <div className="form-group">
                      <Select
                        name="form-field-name"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Select Status"
                        onChange={this.selectStatus}
                        value={this.state.selectedStatus}
                        options={status}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <button
                    className="btn auxo-primary-btn"
                    onClick={this.navigateToAssignVehicles.bind(this)}
                  >
                    Update
                </button>
                  <button
                    className="btn auxo-primary-btn"
                    onClick={this.exportVehicles.bind(this)}
                  >
                    Export
                </button>
                </div>
              </div>

              <div className="row margin-top-20">
                <table className="table-auxo">
                  <thead>
                    <tr>
                      <th>
                        DEVICE IMEI
                      <a onClick={this.sortColumn.bind(this, "imei")}>
                          <img
                            className={
                              this.state.sortType === "imei"
                                ? "transform-arrow-click"
                                : ""
                            }
                            src={dropdownIcon}
                            alt=""
                          />
                        </a>
                      </th>
                      <th>MSISDN</th>
                      {companyExists &&  <th> Party Name </th>}                     
                      {companyExists &&  <th> Party Id </th>}
                      {companyExists &&  <th> Chasis No </th>}
                      {companyExists &&  <th> Party Territory </th>}
                      {engroExists &&  <th> Department </th>}
                      {engroExists &&  <th> Zone </th>}
                      {engroExists &&  <th> Engine Power </th>}
                      {kElectricExists &&  <th> Vehicle Registration No. </th>}
                      {kElectricExists &&  <th> Category </th>}
                      {kElectricExists &&  <th> Cluster </th>}
                      {kElectricExists &&  <th> Region </th>}
                      {kElectricExists &&  <th> IBC / VBC / Department </th>}
                      {kElectricExists &&  <th> Shift (Hrs) </th>}
                      {kElectricExists &&  <th> Benchmark </th>}
                      <th>
                        PLATE NUMBER
                      <a onClick={this.sortColumn.bind(this, "plateNumber")}>
                          <img
                            className={
                              this.state.sortType === "plateNumber"
                                ? "transform-arrow-click"
                                : ""
                            }
                            src={dropdownIcon}
                            alt=""
                          />
                        </a>
                      </th>
                      <th>
                        MAKE & MODEL
                      <a
                          onClick={this.sortColumn.bind(
                            this,
                            "vehicleMakeAndModel"
                          )}
                        >
                          <img
                            className={
                              this.state.sortType === "vehicleMakeAndModel"
                                ? "transform-arrow-click"
                                : ""
                            }
                            src={dropdownIcon}
                            alt=""
                          />
                        </a>
                      </th>
                      {this.props.permissions.companyFeatures.indexOf("TEMPERATURE") === -1 &&
                        <th>
                          METER READING(KM)
                          <a onClick={this.sortColumn.bind(this, "odoMeterReading")} >
                            <img
                              className={
                                this.state.sortType === "odoMeterReading"
                                  ? "transform-arrow-click"
                                  : ""
                              }
                              src={dropdownIcon}
                              alt=""
                            />
                          </a>
                        </th>
                      }
                     

                      {  
                        CustomOdoMeterSetting.includes(this.state.companyId) && 
                        <th>
                          Last OdoMeter Setting Time
                        </th>
                      }
                      {this.props.permissions.companyFeatures.indexOf("FUEL:GROUP") !== -1 &&
                        <th>FUEL GROUP</th>
                      }
                      {
                        <th>Type</th>
                      }
                      {this.props.permissions.companyFeatures.indexOf(
                        "VEHICLE_PRIVATE_MODE"
                      ) !== -1 && <th>PRIVATE MODE</th>}
                      {this.props.permissions.companyFeatures.indexOf(
                        "PATROLLING"
                      ) !== -1 && (
                          <>
                            <th>Patrolling Vehicle</th>
                            <th>Admin Vehicle</th>
                            {/* <th>Patrolling Region</th> */}
                          </>
                        )}
                      {this.props.permissions.companyFeatures.indexOf(
                        "GROUPS"
                      ) !== -1 && (
                          <th>
                            GROUP
                        <a onClick={this.sortColumn.bind(this, "group")}>
                              <img
                                className={
                                  this.state.sortType === "group"
                                    ? "transform-arrow-click"
                                    : ""
                                }
                                src={dropdownIcon}
                                alt=""
                              />
                            </a>
                          </th>
                        )}
                      {/* <th>ICONS</th> */}
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.things &&
                      map(
                        filterVehicleList(
                          this.state.things,
                          this.state.selectedGroup,
                          this.state.selectedStatus
                        ),
                        (item, index) => {
                          let indexTooltip = `abc${index}z`,
                            vehicleStatus = item.vehicleStatus
                            //statusClass = "fa fa-circle",
                            //status = "Offline";

                          const { status, pinIcon, statusClass } = vehicleStatusMap[vehicleStatus] || defaulVehicletStatus;
                        

                          // switch (vehicleStatus) {
                          //   case "IGNITION_ON":
                          //     status = "Ignition";
                          //     statusClass = "fa fa-circle circle-green";
                          //     break;
                          //   case "VEHICLE_MOVING":
                          //     status = "Moving";
                          //     statusClass = "fa fa-circle circle-blue";
                          //     break;
                          //   case "IGNITION_OFF":
                          //     status = "Parked";
                          //     statusClass = "fa fa-circle circle-orange";
                          //     break;
                          //   case "DEVICE_UNPLUG":
                          //     status = "Unplug";
                          //     statusClass = "fa fa-circle circle-red";
                          //     break;
                          //   case "DEVICE_PLUG_IN":
                          //     status = "Parked";
                          //     statusClass = "fa fa-circle circle-orange";
                          //     break;
                          //   case "NEVER_HEARD":
                          //     status = "Not Installed";
                          //     statusClass = "fa fa-circle circle-red";
                          //     break;
                          //   case "NOT_RESPONDING":
                          //     status = "Inactive";
                          //     statusClass = "fa fa-circle circle-red";
                          //     break;
                          //   case "IDLE":
                          //     status = "IDLE";
                          //     statusClass = "fa fa-circle circle-gray";
                          //     break;
                          //   default:
                          //     break;
                          // }

                          const engineStatus = item.engineStatus;
                          let engineStatusColor = "";

                          const isEngineAwaiting = R.or(
                            R.equals(engineStatus, "awaiting_kill"),
                            R.equals(engineStatus, "awaiting_release")
                          ),
                            isEngineKilled = R.equals(engineStatus, "killed");

                          engineStatusColor = isEngineAwaiting
                            ? "#FFA500"
                            : isEngineKilled
                              ? "#FF0000"
                              : "#03ABCE";

                          return (
                            <tr className="vehicle-hover" key={item._id}>
                              <td
                                className="first-column-width things-imei"
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() => this.openDetailsPage(item)}
                              >
                                <span className="pull-left">
                                  <i
                                    href="#"
                                    id={indexTooltip}
                                    className={statusClass}
                                  />
                                </span>

                                <UncontrolledTooltip
                                  placement="top"
                                  target={indexTooltip}
                                >
                                  {status}
                                </UncontrolledTooltip>
                                <span>{item.imei}</span>
                              </td>
                              <td className="column-width-211">
                                <span>{item.msisdn}</span>
                              </td>
                              {companyExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.partyName}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"partyName"}
                                    placeholder={"Enter Party Name"}
                                  />
                                ) : (
                                    <span>{item.partyName || "N/A"}</span>
                                  )}
                              </td>}
                              
                              {companyExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.partyId}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"partyId"}
                                    placeholder={"Enter Party Id"}
                                  />
                                ) : (
                                    <span>{item.partyId || "N/A"}</span>
                                  )}
                              </td>}
                              {companyExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.chasisNumber}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"chasisNumber"}
                                    placeholder={"Enter Chasis No"}
                                  />
                                ) : (
                                    <span>{item.chasisNumber || "N/A"}</span>
                                  )}
                              </td>}
                              {companyExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.partyTerritory}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"partyTerritory"}
                                    placeholder={"Enter Party Territory"}
                                  />
                                ) : (
                                    <span>{item.partyTerritory || "N/A"}</span>
                                  )}
                              </td>}
                              {engroExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.department}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"department"}
                                    placeholder={"Enter Device department"}
                                  />
                                ) : (
                                    <span>{item.department || "N/A"}</span>
                                  )}
                              </td>}
                              {engroExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.zone}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"zone"}
                                    placeholder={"Enter Device Zone"}
                                  />
                                ) : (
                                    <span>{item.zone || "N/A"}</span>
                                  )}
                              </td>}
                              {engroExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.enginepower}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"enginepower"}
                                    placeholder={"Enter Device Engine Power"}
                                  />
                                ) : (
                                    <span>{item.enginepower || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.vehicleRegistrationNo}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"vehicleRegistrationNo"}
                                    placeholder={"Enter Vehicle Registration No."}
                                  />
                                ) : (
                                    <span>{item.vehicleRegistrationNo || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.category}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"category"}
                                    placeholder={"Enter Vehicle Category"}
                                  />
                                ) : (
                                    <span>{item.category || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.cluster}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"cluster"}
                                    placeholder={"Enter Device Cluster"}
                                  />
                                ) : (
                                    <span>{item.cluster || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.region}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"region"}
                                    placeholder={"Enter Device Region"}
                                  />
                                ) : (
                                    <span>{item.region || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.ibc}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"ibc"}
                                    placeholder={"Enter Device IBC / VBC / Department"}
                                  />
                                ) : (
                                    <span>{item.ibc || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.shifthours}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"shifthours"}
                                    placeholder={"Enter Device Shift Hours"}
                                  />
                                ) : (
                                    <span>{item.shifthours || "N/A"}</span>
                                  )}
                              </td>}
                              {kElectricExists && <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.benchmark}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"benchmark"}
                                    placeholder={"Enter Device Benchmark"}
                                  />
                                ) : (
                                    <span>{item.benchmark || "N/A"}</span>
                                  )}
                              </td>}
                              <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.plateNumber}
                                    type={"text"}
                                    id={index}
                                    key={index}
                                    field={"plateNumber"}
                                    placeholder={"Enter Plate Number"}
                                  />
                                ) : (
                                    <span>{item.plateNumber || "N/A"}</span>
                                  )}
                              </td>
                              <td className="column-width-211">
                                {item.editMode ? (
                                  <TextFieldGroup
                                    onChange={this.onChangeValue}
                                    value={item.vehicleMakeAndModel}
                                    id={index}
                                    key={index}
                                    type={"text"}
                                    field={"vehicleMakeAndModel"}
                                    placeholder={"Enter Car Make"}
                                  />
                                ) : (
                                    <span>{item.vehicleMakeAndModel || "N/A"}</span>
                                  )}
                              </td>
                              {this.props.permissions.companyFeatures.indexOf("TEMPERATURE") === -1 &&

                                <td className="column-width-211">
                                  {item.editMode ? (
                                    <TextFieldGroup
                                      onChange={this.onChangeValue}
                                      value={item.odoMeterReading || 0}
                                      type={"number"}
                                      id={index}
                                      key={index}
                                      field={"odoMeterReading"}
                                      placeholder={"Enter ODO Meter"}
                                    />
                                  ) : (
                                      <span> {item.odoMeterReading || 0}</span>
                                    )}
                                </td>
                              }
                              {
                                CustomOdoMeterSetting.includes(this.state.companyId) &&
                                <td className="column-width-311">
                                  <span>{moment(item.lastOdoMeterUpdateTime).format("MM:DD:YYYY hh:mm A").toString()}</span>
                              </td>
                              }
                              {this.props.permissions.companyFeatures.indexOf("FUEL:GROUP") !== -1 &&

                                <td className="column-width-211">
                                  {item.editMode ? (
                                    <Select
                                      className="marginbtm13"
                                      name="form-field-name"
                                      defaultValue={
                                        item.fuelGroup
                                          ? {
                                            value: item.fuelGroup._id,
                                            label: item.fuelGroup.groupName,
                                          }
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const { value } = e;
                                        this.selectChanged({ index, value });
                                      }}
                                      options={
                                        fuelGroups &&
                                        fuelGroups.map((item) => ({
                                          value: item._id,
                                          label: item.groupName,
                                        }))
                                      }
                                    />
                                  ) : (
                                      <span>
                                        {item.fuelGroup
                                          ? item.fuelGroup.groupName
                                          : "N/A"}
                                      </span>
                                    )}
                                </td>}
                                {
                                  <td className="column-width-211">
                                  {item.editMode ? (
                                    <Select
                                      className="marginbtm13"
                                      name="form-field-name"
                                      value={{value: item.thingType,label: item.thingType}}
                                      onChange={(e) => {
                                        const { value } = e;
                                        this.selectChangedThingType({ index, value });
                                      }}
                                      options={typesDict.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                    />
                                  ) : (
                                      <span>
                                        {item.thingType }
                                      </span>
                                    )}
                                </td>
                                }
                              {this.props.permissions.companyFeatures.indexOf(
                                "VEHICLE_PRIVATE_MODE"
                              ) !== -1 && (
                                  <td className="column-width-211">
                                    {item.editMode ? (
                                      <div className="toggle-switch">
                                        <label>Show SD</label>
                                        <input
                                          type="checkbox"
                                          className="toggle-switch-checkbox"
                                          checked={item.privateMode}
                                          onChange={(e) => {
                                            let temp = this.state.things;
                                            temp[index].privateMode =
                                              e.target.checked;
                                            // this.props.actions.updatePrivateMode(item._id, e.target.checked, item.plateNumber)
                                            this.setState({
                                              things: temp,
                                            });
                                          }}
                                          id={`${index}-privateMode`}
                                        />
                                        <label
                                          className="toggle-switch-label"
                                          htmlFor={`${index}-privateMode`}
                                        >
                                          <span className="toggle-switch-inner" />
                                          <span className="toggle-switch-switch" />
                                        </label>
                                      </div>
                                    ) : item.privateMode ? (
                                      "YES"
                                    ) : (
                                          "NO"
                                        )}
                                  </td>
                                )}

                              {this.props.permissions.companyFeatures.indexOf(
                                "PATROLLING"
                              ) !== -1 && (
                                  <>
                                    <td className="column-width-211">
                                      {item.editMode ? (
                                        <span>
                                          <input
                                            type="checkbox"
                                            name={"patrolling"}
                                            checked={item.isPatrollingVehicle}
                                            onChange={(e) => {
                                              const { target } = e;
                                              this.changeCheckValue(index, target);
                                            }}
                                            value={item.isPatrollingVehicle}
                                            disabled={item.isAdminVehicle}
                                          />{" "}
                                          Patrolling
                                    </span>
                                      ) : (
                                          <span>
                                            {item.isPatrollingVehicle ? "YES" : "NO"}
                                          </span>
                                        )}
                                    </td>
                                    <td className="column-width-211">
                                      {item.editMode ? (
                                        <span>
                                          <input
                                            type="checkbox"
                                            name={"admin"}
                                            checked={item.isAdminVehicle}
                                            onChange={(e) => {
                                              const { target } = e;
                                              this.changeCheckValue(index, target);
                                            }}
                                            value={item.isAdminVehicle}
                                            disabled={item.isPatrollingVehicle}
                                          />
                                          Admin
                                    </span>
                                      ) : (
                                          <span>
                                            {item.isAdminVehicle ? "YES" : "NO"}
                                          </span>
                                        )}
                                    </td>

                                  </>
                                )}

                              {this.props.permissions.companyFeatures.indexOf(
                                "GROUPS"
                              ) !== -1 && (
                                  <td className="column-width-211">
                                    {this.state.groupDepthLevel !== 7 &&
                                      item.editMode &&
                                      this.props.createGroup ? (
                                        // <TextFieldGroup
                                        //   onChange={this.onChangeValue.bind(this)}
                                        //   value={item.group}
                                        //   type={"text"}
                                        //   id={index}
                                        //   key={index}
                                        //   field={"group"}
                                        //   placeholder={"Enter Group"}
                                        // />
                                        <SelectComponent
                                          field="form-field-name"
                                          placeholder="Select Group"
                                          preSelected={this.state.editGroup}
                                          multi={false}
                                          isClearable={false}
                                          optionKeys={{ label: "name", value: "_id" }}
                                          options={this.props.groupList}
                                          onChange={(e) =>
                                            this.editGroupValue(e, index)
                                          }
                                        />
                                      ) : (
                                        // <Select
                                        //   name="form-field-name"
                                        //   isClearable={false}
                                        //   isSearchable={true}
                                        //   placeholder="Select Group"
                                        //   onChange={e => this.editGroupValue(e,index)}
                                        //   value={this.state.editGroup}
                                        //   options={this.props.groupList && this.props.groupList.map(item => ({
                                        //     value: item.name,
                                        //     label: item.name,
                                        //     item: item
                                        //   }))}
                                        // />
                                        <span>{item.group || "N/A"}</span>
                                      )}
                                  </td>
                                )}
                                 {/* {this.props.permissions.companyFeatures.indexOf(
                                "GROUPS"
                              ) !== -1 && (
                                  <td className="column-width-211">
                                    {console.log("iconsList---: ",iconsList), item.editMode ? (
                                        <SelectComponent
                                          field="form-field-name"
                                          placeholder="Select Icon"
                                          preSelected={item.icon}
                                          multi={false}
                                          isClearable={false}
                                          optionKeys={{ label: "name", value: "name" }}
                                          options={iconsList.list}
                                          onChange={(e) =>
                                            this.editIconValue(e, index)
                                          }
                                        />
                                      ) : (
                                        <span>{item.icon || "N/A"}</span>
                                      )}
                                  </td>
                                )} */}
                              <td className="last-column-width">
                                {this.state.savingSingleRecord[index] &&
                                  this.state.savingSingleRecord[index] === true ? (
                                    [smallLoader]
                                  ) : (
                                    <div>
                                      {item.editMode ? (
                                        <div>
                                          <a
                                            onClick={this.onSubmit.bind(this, {
                                              index,
                                              editMode: false,
                                            })}
                                          >
                                            <img
                                              className="save-records"
                                              src={saveIcon}
                                              alt=""
                                            />
                                          </a>
                                          <a
                                            onClick={this.editMode.bind(this, {
                                              index,
                                              editMode: false,
                                              cancelSave: true,
                                            })}
                                          >
                                            <img src={cancelIcon} alt="" />
                                          </a>
                                        </div>
                                      ) : (
                                          <div>
                                            {this.props.permissions.companyFeatures.indexOf(
                                              "DRIVING_BEHAVIOUR"
                                            ) !== -1 &&

                                              <a
                                                style={{ paddingLeft: 5 + "px" }}
                                                onClick={() =>
                                                  this.vehicleScrore({
                                                    thing: item,
                                                  })
                                                }
                                              >
                                                <img
                                                  className="width19"
                                                  src={ic_visibility}
                                                  alt=""
                                                />
                                              </a>}
                                            {this.props.permissions.companyFeatures.indexOf(
                                              "ENGINE:KILL"
                                            ) !== -1 &&
                                              this.props.permissions.engineKill && (
                                                <a
                                                  style={{ paddingLeft: 5 + "px" }}
                                                  onClick={() =>
                                                    this.showEngineKill({
                                                      thing: item,
                                                    })
                                                  }
                                                >
                                                  <Icon
                                                    size={16}
                                                    icon={ic_warning}
                                                    style={{
                                                      display: "inline",
                                                      color: engineStatusColor,
                                                    }}
                                                  />
                                                </a>
                                              )}

                                            {this.props.permissions.companyFeatures.indexOf(
                                              "DRIVER:MANAGEMENT"
                                            ) !== -1 &&
                                              this.props.permissions.listSchedule && (
                                                <a
                                                  style={{ paddingLeft: 5 + "px" }}
                                                  onClick={this.showSchedule.bind(
                                                    this,
                                                    item
                                                  )}
                                                >
                                                  <img
                                                    className="width19"
                                                    src={calenderIcon}
                                                    alt=""
                                                  />
                                                </a>
                                              )}
                                            {this.props.permissions.updateVehicle && (
                                              <a
                                                style={{ paddingLeft: 5 + "px" }}
                                                onClick={this.editMode.bind(this, {
                                                  index,
                                                  editMode: true,
                                                })}
                                              >
                                                <img src={editIcon} alt="" />
                                              </a>
                                            )}
                                            <a
                                                style={{ paddingLeft: 5 + "px" }}
                                                onClick={this.openComplaint.bind(this,item,status)}
                                              >
                                                <img src={complaint} alt="" />
                                              </a>
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
      </div>
    );
  }
}

VehicleListView.propTypes = {};
VehicleListView.defaultProps = {};
const mapStateToProps = (state) => ({
  things: state.things.things,
  fuelGroups: state.fuelGroup.groups,
  canUpdateVehicle: state.permissions.updateVehicle,
  createGroup: state.permissions.createGroup,
  groupList: state.groups.subGroups,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { ...Things.creators,...Complaint.creators, ...FuelGroup.creators, ...Patrolling.creators },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleListView);
