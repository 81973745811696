import Duck from 'extensible-duck'
import { thingsEndPoints } from "../api/endpoints"
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
import axios from "axios";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: "Visualization",
    store: "charts",
    types: ["LOAD_VIOLATION_CHART"],
    initialState: {
        violationChartData: {data: [], counts: {}},
        fuelChartData: {data: [], counts: {}},
        fuelChartDataNew: {data: [], counts: {}},
        tripsChartData : {data: [], counts: {}},
        gdThingsChartData : {data: [], counts: {}}
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case 'LOAD_VIOLATION_CHART':
                return { ...state, violationChartData: action.payload }
            case 'LOAD_TRIPS_CHART':
                return { ...state, tripsChartData: action.payload }
            case 'LOAD_GD_CHART':
                return {...state, gdThingsChartData : action.payload }
            case 'LOAD_FUEL_CHART':
                return { ...state, fuelChartData: action.payload}
            case 'LOAD_FUEL_CHART_NEW':
                return { ...state, fuelChartDataNew: action.payload}
            default:
                return state
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({
        loadViolationChartData: (state) => async(dispatch,getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
            const response = await axios.post(`${thingsEndPoints.violation()}`,state);
            dispatch({type: "LOAD_VIOLATION_CHART", payload: response.data.data});
        },
        loadFuelChartData: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
            const response = await axios.post(`${thingsEndPoints.fuelChart()}`,state);
            dispatch({type: "LOAD_FUEL_CHART", payload: response.data.data});
        },
        loadFuelChartDataNew: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
            const response = await axios.post(`${thingsEndPoints.fuelChartNew()}`,state);
            dispatch({type: "LOAD_FUEL_CHART_NEW", payload: response.data.data});
        },
        loadTripsChartData :  (state) => async(dispatch,getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
            const response = await axios.post(`${thingsEndPoints.tripsChart()}`,state);
            dispatch({type: "LOAD_TRIPS_CHART", payload: response.data});
        },
        loadGdChartData :  (id, data) => async(dispatch,getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
            const response = await axios.get(`${thingsEndPoints.gdChart(id, data)}`);
            dispatch({type: "LOAD_GD_CHART", payload: response.data});
        }
    })
})