
import React from 'react';
import * as R from 'ramda';
// import Slider from 'react-slick';
import { Card, CardBody, CardTitle } from 'reactstrap';
// import Icon from 'react-icons-kit';
import './Index.css';
import pin_moving from '../../../assets/img/pin_moving.png';
import pin_ignition from '../../../assets/img/pin_ignition.png';
import pin_idle_shadow from '../../../assets/img/pin_idle_shadow.png';
import pin_parked from '../../../assets/img/pin_parked.png';
import noSignal from '../../../assets/img/no signal.png';
import ic_plugout from '../../../assets/img/ic_plugout.png';
import active_signal from '../../../assets/img/active_signal.png';



const CardsWidget = (props) => {
  const {
    parkedCount,
    ignitionCount,
    idleCount,
    movingCount,
    simstatus,
    totalVehicles,  
    unplugCount,
    neverHeard,
    notResponding,
    onLabelClick,
    weakSignal
  } = props;

  return (
    <div style={{display:"flex" ,justifyContent:'center' ,  flexDirection:'row' , flexWrap:'wrap'}}>
          <Card>
            <CardBody>
              <CardTitle>
                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="col-md-3">
                    <div className="margin-top-12">
                      <img src={pin_ignition}
                        style={{ transform: 'rotate(110deg)' }}
                        alt="" className="inline-block height32" />
                    </div>
                    <div>
                      <span className="counter"
                        style={{ cursor: 'pointer' }}
                        onClick={() => onLabelClick([{
                          label: "IGNITION",
                          value: "IGNITION_ON"
                        }])}
                      >{ignitionCount < 10 ? (`0` + ignitionCount) : ignitionCount}</span>
                    </div>
                    <div>
                      <span className="card-heading font-15">
                        Ignition
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="margin-top-12">
                      <img src={pin_parked} alt="" className="inline-block height32" />
                    </div>
                    <div>
                      <span className="counter"
                        style={{ cursor: 'pointer' }}
                        onClick={() => onLabelClick([{
                          label: "PARKED",
                          value: "IGNITION_OFF"
                        }])}
                      >{parkedCount < 10 ? (`0` + parkedCount) : parkedCount}</span>
                    </div>
                    <div>
                      <span className="card-heading font-15">
                        Parked
                      </span>
                    </div>

                  </div>
                  <div className="col-md-3">
                  <div className="margin-top-12">
                        <img src={pin_moving} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter"
                          style={{ cursor: 'pointer' }}
                          onClick={() => onLabelClick([{
                            label: "MOVING",
                            value: "VEHICLE_MOVING"
                          }])}
                        >{movingCount < 10 ? (`0` + movingCount) : movingCount}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Moving
                        </span>
                      </div>

                  </div>
                  <div className="col-md-3">
                      <div className="margin-top-12">
                        <img src={pin_idle_shadow} alt="" style={{
                          height: '28px',
                          transform: 'rotate(200deg)',
                          marginBottom: '4px'
                        }} className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter"
                          style={{ cursor: 'pointer' }}
                          onClick={() => onLabelClick([{ label: "IDLE", value: "IDLE" }])}
                        >{idleCount < 10 ? (`0` + idleCount) : idleCount}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Idle
                                    </span>
                      </div>

                  </div>
                </div>
              </CardTitle>
            </CardBody>
          </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="row" style={{ textAlign: 'center' }}>
                    <div className="col-md-4">
                      <div className="margin-top-12">
                        <img src={ic_plugout} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter "
                          style={{ cursor: 'pointer' }}
                          onClick={() => onLabelClick([{
                            label: "UNPLUG",
                            value: "DEVICE_UNPLUG"
                          }])}

                        >{unplugCount < 10 ? (`0` + unplugCount) : unplugCount}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Unplug
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="margin-top-12">
                        <img src={noSignal} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter "
                          style={{ cursor: 'pointer' }}
                          onClick={() => onLabelClick([{
                            label: "NOT INSTALLED",
                            value: "NEVER_HEARD"
                          }])}
                        >{neverHeard < 10 ? (`0` + neverHeard) : neverHeard}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Not Installed
                        </span>
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div className="margin-top-12">
                        <img src={noSignal} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter "
                          style={{ cursor: 'pointer' }}
                          onClick={() => onLabelClick([{
                            label: "INACTIVE",
                            value: "NOT_RESPONDING"
                          }])}
                        >{notResponding < 10 ? (`0` + notResponding) : notResponding}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Inactive
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="margin-top-12">
                        <img src={noSignal} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter "
                          style={{ cursor: 'pointer' }}
                          onClick={() => onLabelClick([{
                            label: "SUSPENDED",
                            value: "SUSPENDED"
                          }])}
                        >{simstatus < 10 ? (`0` + simstatus) : simstatus}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Offline
                        </span>
                      </div>
                    </div>
                  </div>
                </CardTitle>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="row" style={{ textAlign: 'center' }}>
                    <div className="col-md-12">
                      <div className="margin-top-12">
                        <img src={active_signal} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter"
                          
                          
                        >{totalVehicles < 10 ? (`0` + totalVehicles) : totalVehicles}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Total
                        </span>
                      </div>

                    </div>

                  </div>
                </CardTitle>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="row" style={{ textAlign: 'center' }}>
                    <div className="col-md-12">
                      <div className="margin-top-12">
                        <img src={noSignal} alt="" className="inline-block height32" />
                      </div>
                      <div>
                        <span className="counter"   
                        >{weakSignal}</span>
                      </div>
                      <div>
                        <span className="card-heading font-15">
                          Connecting...
                        </span>
                      </div>

                    </div>

                  </div>
                </CardTitle>
              </CardBody>
            </Card>
          </div>
     );
}

export default React.memo(CardsWidget);