import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import { DVR_ACCOUNT, DVR_PASSWORD } from "../../constants";
import Select from "react-select-nested-group";
import Things from "../../ducks/Things";
import Iframe from 'react-iframe'
import { Card, CardHeader } from "reactstrap";
import { MVDR_URL } from "../../constants";

class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      selectedVehicle: "",
      showVideo: false,
      url: "",
    };

  }
  async componentDidMount() {
    await this.props.thingsActions.getVehiclesPlateNumber();
  }

  vehicleSelected = async (e) => {
    let rawUrl = `${MVDR_URL}/808gps/open/player/video.html?lang=en&devIdno=${e.item.mvdrDevId}&account=${DVR_ACCOUNT}&password=${DVR_PASSWORD}`;
    this.setState({ selectedVehicle: e, url: rawUrl, showVideo: true });
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
           
            <Select
              name="form-field-name"
              isClearable={true}
              isSearchable={true}
              placeholder="Select Vehicle"
              onChange={this.vehicleSelected}
              value={this.state.selectedVehicle}
              options={
                this.props.vehiclesPlateNumber &&
                this.props.vehiclesPlateNumber.map((item) => ({
                  value: item.mvdrDevId,
                  label: item.plateNumber || "N/A",
                  item: item,
                }))
              }
            />
            {/* </div> */}
            {/* {this.state.errors && (
                    <span className="text-danger">
                      {this.state.errors.vehicle}
                    </span>
                  )}
                </div>
              </form>
            </Card> */}
          </div>
        </div>
        <div className="row m-t-20">

          {this.state.showVideo && (
            <Card className="height-100-p " style={{ minWidth: "100%" }}>
              <CardHeader className="f-bold">Live Video</CardHeader>
              <div className="col-md-12">

                <div>
                  <Iframe
                    url={this.state.url}
                    width="100%"
                    height="600px"
                    id="liveVideo"
                    display="initial"
                    position="relative"
                  />
                </div>

              </div>

            </Card>
          )}
        </div>
      </div>


    );
  }
}

Live.propTypes = {};
Live.defaultProps = {};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  vehiclesPlateNumber: state.things.vehiclesPlateNumber,
});
const mapDispatchToProps = (dispatch) => ({
  thingsActions: bindActionCreators(Things.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Live);
