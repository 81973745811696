import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';


import Reports from '../../ducks/Reports';

import { ReportsListing } from './reportsList';
import ReportColumns from './columnSelection';
import DrivingScoreReportInterval from './driverInterval';
import ReportInterval from './interval';
import Observation from './observations';

import './index.css'

import forwardIcon from '../../assets/img/ic_forward.png';




class ReportsList extends Component {

  constructor(props) {
    super(props);
    let isRouteFence = false;
    if(this.props.match && this.props.match.params && this.props.match.params.id === 'route'){
      isRouteFence = true;
    }
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

   
    this.state = {
      reportType: isRouteFence ? 'routefenceReport' : '',
      alarmTypes: [],
      companyId: companyInfo.companyId,
      eventTypeList: [],
      geofences: [],
      routefences: [],
      step: isRouteFence ? 2 : 1,
      columns: [],
      interval: {},
      vehicles: [],
      drivers: []
    };
    this.scheduleReportClickHandler = this.scheduleReportClickHandler.bind(this);
   
  }
  async selectReportType(reportType) {

    if (reportType === 'geofenceSummaryReport'){
      this.props.history.push('/viewReports/geofenceSummaryReport');
    }
    if (reportType === 'mileageReport'){
      this.props.history.push('/viewReports/mileageReport');
    }
    if (reportType === 'NRReport' || reportType === 'vehiclesStatus') {
      await this.props.actions.exportReport({ reportType });
      // toastr.success('Report Exported Successfully.');
      return;
    }
    if (reportType === 'activityReport') {
      this.setState(prevState => ({ ...prevState, reportType, step: 3,  }));
      return;
    }
     this.setState(prevState => ({ ...prevState, reportType, step: 2,  }));
    
  }
 

  selectColumns(columns, alarmTypes, eventTypeList, geofences, routefences) {
    if (!columns || !columns.length) return toastr.error('Please select columns to export.');
    this.setState(prevState => ({ ...prevState, columns, alarmTypes, eventTypeList, geofences, step: 3, routefences }))
  }

  switchStep(step, vehicles, interval) {
    if (step === 1) {
      this.setState({
        ...this.state,
        reportType: '',
        alarmTypes: [],
        eventType: [],
        step: 1,
        columns: [],
        interval: {},
        vehicles: [],
        geofences: [],
      })
    }
    else this.setState(prevState => ({ ...prevState, step, interval, vehicles }));
  }

  async selectVehiclesAndRange(vehicles, interval, overSpeedingLimit, geofenceEntry = {}, geofenceExit = {}, distance, geoFenceId, routeFenceId) {


    if (this.state.reportType === "geofenceSummaryReport") {
      let data = {
        vehicles,
        interval,
        columns: this.state.columns,
        reportType: this.state.reportType,
        geofences: this.state.geofences,
        sizePerPage : 10,
        page :0
      };

      let response = await this.props.actions.reportsView(data);

      if (response.status === "success"){
        this.props.history.push('/viewReports/geofenceSummaryReport');
      }

    } else if (this.state.reportType === "newfuelAverage") {

      let data = {
        vehicles,
        interval,
        columns: this.state.columns,
        reportType: this.state.reportType,
        sizePerPage : 10,
        page :0
      }

      let response = await this.props.actions.fuelReportView(data);

      if (response.status === "success"){
        this.props.history.push('/viewReports/newFuelSummary');
      }


    } else if  (this.state.reportType === "newfuelActivity") {

      let data = {
        vehicles,
        interval,
        columns: this.state.columns,
        reportType: this.state.reportType,
        sizePerPage : 10,
        page :0
      }

      let response = await this.props.actions.fuelActivityReportView(data);

      if (response.status === "success"){
        this.props.history.push('/viewReports/newFuelActivity');
      }

    } else {

      if (!vehicles || !vehicles.length) {
        return toastr.error('Please select vehicles.')
      }
      geofenceEntry = geofenceEntry || {};
      geofenceExit = geofenceExit || {};
      this.setState({ ...this.state, interval, vehicles });
      if (this.state.reportType === 'geofenceTrackReport' && (!geofenceExit.value || !geofenceEntry.value))
        return toastr.error('Please select both source and destination');
  
      await this.props.actions.exportReport({
        vehicles,
        interval,
        sourceGeofenceId: geofenceEntry.value,
        destinationGeofenceId: geofenceExit.value,
        columns: this.state.columns,
        reportType: this.state.reportType,
        alarmTypes: this.state.alarmTypes,
        eventTypeList: this.state.eventTypeList,
        geofences: this.state.geofences,
        routefences: this.state.routefences,
        overSpeedingLimit,
        distance,
        geoFenceId, 
        routeFenceId
      });
  
    }
    // toastr.success('Report Exported Successfully.')
  }

  async selectDriversAndRange(drivers, interval) {
    if (!drivers || !drivers.length) return toastr.error('Please select drivers.');

    this.setState({ ...this.state, interval, drivers });

    await this.props.actions.exportReport({
      driversId: drivers,
      interval,
      columns: this.state.columns,
      reportType: this.state.reportType
    });
    // toastr.success('Report Exported Successfully.')
  }

  scheduleReportClickHandler() {
    this
      .props
      .history
      .push('/report-schedule');
  }

  render() {

    let componentToRender = (<div></div>);

    if (this.state.step === 1){
      componentToRender = (
        <ReportsListing selectType={this.selectReportType.bind(this)} reports={this.props.permissions.companyReports} features ={this.props.permissions.companyFeatures} companyId={this.state.companyId}/>
      );
    }
    else if (this.state.step === 2 ){

      if (this.state.reportType == 'newfuelAverage' || this.state.reportType == 'newfuelActivity') {
        componentToRender = (
          <ReportInterval
            selectVehiclesAndRange={this.selectVehiclesAndRange.bind(this)}
            reportType={this.state.reportType}
            switchStep={this.switchStep.bind(this)}
            interval={this.state.interval}
            vehicles={this.state.vehicles}
          />
        );
      } else {
        componentToRender = (
          <ReportColumns
            type={this.state.reportType}
            selectColumns={this.selectColumns.bind(this)}
            switchStep={this.switchStep.bind(this)}
            columnsSelected={{
              columns: this.state.columns,
              alarmTypes: this.state.alarmTypes,
              geofenceList: this.state.geofences,
              routefenceList: this.state.routefences,
              eventTypeList: this.state.eventTypeList
            }}
          />
        );}
      }
    else if (this.state.step === 3 && (this.state.reportType === 'drivingScore' || this.state.reportType === 'drivingScoreGraph')){
      componentToRender = (
        <DrivingScoreReportInterval
          selectDriversAndRange={this.selectDriversAndRange.bind(this)}
          reportType={this.state.reportType}
          switchStep={this.switchStep.bind(this)}
          interval={this.state.interval}
          drivers={this.state.drivers}
        />
      );}
    else if (this.state.step === 3 && this.state.reportType !== 'drivingScore' && this.state.reportType !== 'drivingScoreGraph'){
      if (this.state.reportType === 'activityReport') {
        componentToRender = (
          <Observation
            reportType={this.state.reportType}
            switchStep={this.switchStep.bind(this)}
            interval={this.state.interval}
            vehicles={this.state.vehicles}
          />
        );
      } else {
        componentToRender = (
          <ReportInterval
            selectVehiclesAndRange={this.selectVehiclesAndRange.bind(this)}
            reportType={this.state.reportType}
            switchStep={this.switchStep.bind(this)}
            interval={this.state.interval}
            vehicles={this.state.vehicles}
          />
        );
      }
    }

    return (
      <div>
        <div className='reports-header'><span className='headerchip export'>Export</span>
          <span className='selected-color headerchip'>Report type</span>
          <span className='headerchip'><img alt='' src={forwardIcon}/></span>
          <span className={`headerchip ${this.state.step === 2 || this.state.step === 3 ? 'selected-color' : ''}`}>Columns</span>
          <span className='headerchip'><img alt='' src={forwardIcon}/></span>
          <span className={`headerchip ${this.state.step === 3 ? 'selected-color' : ''}`}>Interval</span>
          {this.props.permissions.companyFeatures.indexOf("REPORT:SCHEDULE") !== -1 &&
          <span style={{ marginLeft: 'auto' }}>
              <button className='btn auxo-primary-btn' onClick={this.scheduleReportClickHandler}>Schedule Report</button>
          </span>
          }
        </div>
        {componentToRender}
      </div>
    );
  }
}

ReportsList.propTypes = {};
ReportsList.defaultProps = {};

const mapStateToProps = state => ({ permissions: state.permissions});
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(Reports.creators, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList)
