import React, { Component } from 'react';
import { connect } from "react-redux";
import Patrolling from "../../../ducks/Patrolling";
import Groups from '../../../ducks/Groups';
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import GeoFenceMap from "../../common/maps/geofence";
import { validateCellSiteInput } from "../../../validations/patrolling";
import { WhiteSpinner } from '../../common/Spinner/index';
import * as _ from "lodash";
import SelectComponent from '../../select';
import toastr from "toastr";

class CreateCellSite extends Component {

  constructor() {
    const groupData = JSON.parse(localStorage.getItem('groupInfo'))
    super();
    this.state = {
      name: "",
      district: "",
      description: "",
      center: [
        73.13915736973286, 33.59492064226822
      ],
      radius: 150,
      isGuarded: false,
      errors: {},
      selectedGroup: [],
      groupDepthLevel: groupData.depthLevel,
      companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
      loader: false
    }
  }

  async componentDidMount() {
    if (!(this.props.groupList && this.props.groupList.length))
    await this.props.dispatch(Groups.creators.getSubGroups(this.state.companyInfo.groupId));

  }
  handleGroupChange = (selectedOption) => {
    this.setState({
      ...this.state,
      selectedGroup: selectedOption
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  onMapChange = (circle) => {

    let radius = circle.getRadius();

    radius = Math.round(radius);

    this.setState({
      ...this.state,
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius
    });
    if (radius < 50) {
      this.setState({
        radius: 50
      });
    }

    return ({
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius: radius
    })
  }
  changeRadius = (event) => {
    let value = '';
    if (event.target.value) {
      value = parseInt(event.target.value, 10);
    } else {

    }
    this.setState({ radius: value });
  }
  isValid = () => {
    const { errors, isValid } = validateCellSiteInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };
 
  onSubmit = async (e) => {

    e.preventDefault();
    const { loader } = this.state;

    if (loader) {
      return;
    }
    if (this.isValid()) {
      try {
        const state = this.state;
        this.setState({
          ...this.state,
          loader: true
        })
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

        let cellSite = {
          name: _.trim(state.name),
          district: _.trim(state.district),
          description: _.trim(state.description),
          cords: state.center,
          radius: state.radius,
          isGuarded: state.isGuarded,
          group: this.state.selectedGroup ? this.state.selectedGroup[0].value : null,
          company: companyInfo.companyId,
        }
        await this
          .props
          .actions
          .createUpdateCellSite({ cellSite });
        toastr.success("CellSite Created Successfully!");
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push(`/patrolling/cellsites`);

      } catch (e) {
        toastr.error(e.response ? e.response.data.message : e);
        this.setState({
          ...this.state,
          loader: false
        });



      }
    }
  };
  changeCheckValue = (target) => {
    this.setState({ ...this.state, isGuarded: !this.state.isGuarded })
  };

  render() {
    const { name, description, isGuarded, district} = this.state;

    const loader = <WhiteSpinner key="1" />

    return (
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <TextFieldGroup
              onChange={this.onChange.bind(this)}
              value={name}
              label={"CellSite Name*"}
              type={"text"}
              field={"name"}
              error={this.state.errors.name}
              placeholder={"E.g Warehouse, school"} />
            <TextFieldGroup
              onChange={this.onChange.bind(this)}
              value={district}
              label={"CellSite district"}
              type={"text"}
              field={"district"}
              placeholder={"E.g Lahore"} />
            <TextAreaGroup
              onChange={this.onChange.bind(this)}
              value={description}
              label={"Description"}
              field={"description"}
              error={this.state.errors.description}
              placeholder={"Extra details about cellSite"} />
            <div className='form-group'>
              <div className='filter'>
                <input type='checkbox' name={'isGuarded'} checked={isGuarded} onChange={e => {
                  const { target } = e;
                  this.changeCheckValue(target)
                }} value={isGuarded} /> isGuarded
                                </div>
            </div>
            <div className='form-group'>
                <p>Radius: {this.state.radius}</p>
            </div>
            <div>
            
                  <div className='form-group'>
                      <SelectComponent
                        label='Select Group'
                        field='form-field-name'
                        placeholder='Select Group'
                        preSelected={this.state.selectedGroup}
                        optionKeys={{label: 'name', value: '_id'}}
                        options={this.props.groups}
                        error={this.state.errors.selectedGroup}
                        onChange={this.handleGroupChange}
                      />
                  </div> 

            </div>
            <div className="col padding0">


              <div className="margin-btm-50">
                <button className="btn auxo-primary-btn create-geofence-btn" type="submit">
                  {this.state.loader ? [loader] : 'Create'}</button>
              </div>

            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <GeoFenceMap
              center={{
                lat: this.state.center[1],
                lng: this.state.center[0]
              }}
              radius={this.state.radius || 150}
              onMapChange={this
                .onMapChange} />
          </div>

        </div>
      </form>

    );
  }
}

CreateCellSite.propTypes = {};
CreateCellSite.defaultProps = {};
const mapStateToProps = state => ({ groups: state.groups.subGroups });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Patrolling.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateCellSite);
