import React, { Component } from "react";
import { connect } from "react-redux";
import map from 'lodash/map';
import Things from "../../../ducks/Things";
import Complaint from "../../../ducks/Complaint";
import Modal from "react-bootstrap4-modal";
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import Groups from "../../../ducks/Groups";
import SelectComponent from "../../select";
import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';
import "./index.css";
import toastr from "toastr";
import * as _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select-nested-group";

class CreateComplaint extends Component {
  constructor() {
    super();

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    this.state = {
      name: "",
      isLoadingRecords: false,
      description: "",
      name: "",
      errors: {},
      selectedGroup: [],
      vehicleNumberPlate: "",
      groupId: companyInfo ? companyInfo.groupId : "",
      companyId: companyInfo ? companyInfo.companyId : "",
    };
  }
  async componentDidMount() {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    await this.props.thingsActions.getGroupVehicles({groupId: companyInfo.groupId});
    await this.props.groupActions.getSubGroups(this.state.groupId);
  }

  navigateToList() {
    this.props.history.push("/complaints");
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: "",
      },
    });
  };

  handlePlateNumberChange = async (selectedOption) => {
    this.setState({
      ...this.state,
      vehicleNumberPlate: selectedOption,
    });
  };

  async onSubmit() {
      try {
        const complaintInfo = {
          description: this.state.description,
          name: this.state.name,
          selectedGroup: this.state.selectedGroup[0].value,
          vehicleNumberPlate: _.map(this.state.vehicleNumberPlate,'value'),
        };

        this.setState({ ...this.state,isLoadingRecords : true});
        await this.props.actions.addComplaint(complaintInfo);
        this.setState({ ...this.state,isLoadingRecords : false});
        toastr.success("Complaint Launched successfully!");
        this.props.history.push("/complaints");
      } catch (e) {
        toastr.error(e.response ? e.response.data.message : e);
      }
  }
  handleDarkSideForce() {
    toastr.error("Invalid file format!");
  }

  selectGroup = async (selectedGroup) =>{
    if(selectedGroup && selectedGroup[0] && selectedGroup[0].value){
      await this.props.thingsActions.getGroupVehicles({ groupId: selectedGroup[0].value });
      const vehicles = map(this.props.vehicles, item => (
        {
          value: item._id, label: item.plateNumber
        }
      ));

      this.setState({ ...this.state, vehicles, haveVehicles: !!vehicles.length, selectedGroup, errors: {...this.state.errors, parentGroup: ''} });
    } else{
      this.setState({ ...this.state, vehicles: [], haveVehicles: false,vehicleNumberPlate:"", selectedGroup, errors: {...this.state.errors, parentGroup: ''} });
    }
  };

  render() {
    const isLoadingRecords = this.state.isLoadingRecords;
    const loader = <Spinner key="1" />;

    return (
      <>
            {
        isLoadingRecords ? loader :  ( <Modal
          visible={true}
          onClickBackdrop={this.modalBackdropClicked}
          dialogClassName="driver-modal-dialog"
        >
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={this.navigateToList.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              {" "}
              <p className="driver-heading">Add a new Complaint</p>
            </div>
          </div>
          <div className="modal-body">
          <div className="row">
               <div className="col-lg-12">
                  <TextFieldGroup
                    field={"name"}
                    placeholder={"Complaint Name"}
                    value={this.state.name}
                    type={"text"}
                    label={"Complaint Name"}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              </div>
            <div className="row">
              <div className="col-lg-6">
                <label>GROUP</label>
                  <SelectComponent
                    field="form-field-name"
                    placeholder="Select Group"
                    preSelected={this.state.selectedGroup}
                    // multi={true}
                    optionKeys={{ label: "name", value: "_id" }}
                    options={this.props.groupList}
                    onChange={this.selectGroup}
                  />
              </div>
            </div>
              <div className="row">
                <div className="col-lg-6">
                  <label>VEHICLE NUMBER PLATE</label>
                    <Select
                      name="form-field-name"
                      isClearable={true}
                      isSearchable={true}
                      isMulti
                      value={this.state.vehicleNumberPlate}
                      onChange={this.handlePlateNumberChange}
                      options={
                        this.props.vehicles &&
                        this.props.vehicles.map((item) => {
                          return { value: item._id, label: item.plateNumber };
                        })
                      }
                    />
                </div>
              </div>
              <div className="row">
               <div className="col-lg-12">
                  <TextFieldGroup
                    field={"description"}
                    placeholder={"Complaint Description"}
                    value={this.state.description}
                    type={"text"}
                    label={"Complaint Description"}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              </div>
          </div>
          <div className="modal-footer">
            <button className="close-btn" onClick={this.onSubmit.bind(this)}>
                Save
            </button>
          </div>
        </Modal>)
       
      }
      </>
    );
  }
}

CreateComplaint.propTypes = {};
CreateComplaint.defaultProps = {};
const mapStateToProps = (state) => ({
  vehicles: state.things.groupVehicles,
  permissions: state.permissions,
  groupList: state.groups.subGroups,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Complaint.creators, dispatch),
  groupActions: bindActionCreators(Groups.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateComplaint);
