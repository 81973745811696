import React, { useState } from "react";
import Modal from "react-bootstrap4-modal";
import moment from "moment";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import Stepper from "react-stepper-horizontal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const OtherMaintainanceModal = (props) => {
  const [otherMaintenanceType, setOtherMaintenanceType] = useState();
  const [description, setDescription] = useState();
  const [maintenanceDate, setMaintenanceDate] = useState(moment());
  const [cost, setCost] = useState();
  const [warrenty, setWarrenty] = useState();
  const [remarks, setRemarks] = useState();

  const [activeStep, setActiveStep] = React.useState(0);

  const [errorOtherMaintenanceType, setErrorOtherMaintenanceType] = useState();
  const [errorMaintenanceDate, setErrorMaintenanceDate] = useState();

  const steps = [
    { title: "Type" },
    { title: `Description` },
    { title: "Date" },
    { title: "Cost" },
    { title: "Warrenty" },
    { title: "Remarks" },
  ];

  function shouldGoNext(step) {
    if (step === 0) {
      if (!otherMaintenanceType) {
        setErrorOtherMaintenanceType("Maintainance type is required");
        return;
      } else {
        setErrorOtherMaintenanceType("");
      }
    } else if (step === 2) {
      if (!maintenanceDate) {
        setErrorMaintenanceDate("Date is required");
        return;
      } else {
        setErrorMaintenanceDate("");
      }
    }
    return true;
  }
  function handleNext() {
    if (shouldGoNext(activeStep)) {
      setActiveStep((activeStep) => activeStep + 1);
    }
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleOtherMaintenanceType(event) {
    const value = event.target.value;
    setOtherMaintenanceType(value);
  }
  function handleDescription(event) {
    const value = event.target.value;
    setDescription(value);
  }

  function handleMaintenanceDate(date) {
    setMaintenanceDate(date);
  }
  function handleCostChange(event) {
    const value = event.target.value;
    setCost(value);
  }
  function handleWarrentyChange(event) {
    const value = event.target.value;
    setWarrenty(value);
  }

  function handleChangeRemarks(event) {
    const value = event.target.value;
    setRemarks(value);
  }

  return (
    <Modal
      visible={props.isVisible}
      onClickBackdrop={() => {}}
      dialogClassName="driver-modal-dialog"
    >
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={props.onCancel}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-heading row">
        <div className="col-lg-4 col-md-4 col-sm-4">
          {" "}
          <p className="driver-heading">Other Maintainance</p>
        </div>
      </div>
      <div className="modal-body">
        <div className="stepper-container m-b-10-px">
          <Stepper
            activeStep={activeStep}
            orientation="horizontal"
            steps={steps}
            circleFontSize={13}
            titleFontSize={13}
          />
        </div>
        {/* {steps.map((step) => {
          return (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>

            </Step>)

        })}

      </Stepper> */}
        {activeStep === 0 && (
          <div>
            <TextFieldGroup
              required
              field={"otherMaintenanceType"}
              placeholder={"e.g Tunning"}
              value={otherMaintenanceType}
              type={"text"}
              label={"Maintenance Type"}
              error={errorOtherMaintenanceType}
              onChange={handleOtherMaintenanceType}
            />
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <TextAreaGroup
              field={"description"}
              placeholder={"description..."}
              value={description}
              type={"text"}
              label={"Description"}
              onChange={handleDescription}
            />
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <div className="form-group">
              <label>Date</label>
              <DatePicker
                className="form-control"
                selected={maintenanceDate}
                onChange={handleMaintenanceDate}
              />
              {errorMaintenanceDate && (
                <span className="error-maintainance">
                  {errorMaintenanceDate}
                </span>
              )}
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div>
            <TextFieldGroup
              field={"cost"}
              placeholder={"1000"}
              value={cost}
              type={"number"}
              label={"Cost"}
              onChange={handleCostChange}
            />
          </div>
        )}
        {activeStep === 4 && (
          <div>
            <TextFieldGroup
              field={"warrenty"}
              placeholder={"1 Year"}
              value={warrenty}
              type={"text"}
              label={"Warrenty"}
              onChange={handleWarrentyChange}
            />
          </div>
        )}
        {activeStep === 5 && (
          <div>
            <TextAreaGroup
              field={"Remarks"}
              placeholder={"Remarks..."}
              value={remarks}
              type={"text"}
              label={"Remarks"}
              onChange={handleChangeRemarks}
            />
          </div>
        )}
        {activeStep === 6 && (
          <div className="row">
            <div className="col-sm-12">
              <h3 style={{ fontSize: "2rem", paddingBottom: "2px" }}>
                Summary
              </h3>
            </div>
            <div className="col-sm-12">
              <div className="gridItem gridHeader">Maintenance Type</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem ">{otherMaintenanceType}</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem gridHeader">Description</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem">{description || "N/A"}</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem gridHeader">Date</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem">
                {maintenanceDate.format("DD/MM/YYYY")}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem gridHeader">Cost</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem">{cost || "0"}</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem gridHeader">Warrenty</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem">{warrenty || "N/A"}</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem gridHeader">Remarks</div>
            </div>
            <div className="col-sm-12">
              <div className="gridItem">{remarks || "N/A"}</div>
            </div>
          </div>
        )}
        <div className="actionsContainer">
          <div>
            <button
              disabled={activeStep === 0}
              onClick={handleBack}
              className="btn btn-success"
              style={{ outline: "none" }}
            >
              Back
            </button>
            <button
              onClick={handleNext}
              className="btn btn-contained"
              disabled={activeStep === 6}
              style={{ outline: "none" }}
            >
              {activeStep >= steps.length - 1 ? "Finish" : "Next"}
            </button>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          disabled={activeStep !== 6}
          className={`btn close-btn `}
          onClick={() => {
            props.onConfirm({
              otherMaintenanceType,
              description,
              maintenanceDate,
              cost,
              warrenty,
              remarks,
            });
          }}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default OtherMaintainanceModal;
