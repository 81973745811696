import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filter, map } from 'lodash';
import moment from 'moment';
import PJP from '../../../ducks/PJP';
import { Spinner } from '../../common/Spinner/index';
import TextFieldGroup from '../../common/TextFieldGroup';
import searchIcon from '../../../assets/img/ic_search.png';
// import ReactExport from 'react-export-excel';
const queryString = require('query-string');
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ViolationListView extends Component {

  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    this.state = {
      selectedGroup: [],
      selectedStatus: [],
      things: [],
      violations: [],
      allViolations: [],
      search: '',
      status: '',
      count: '',
      excelExportItems: [],
      loading: true,
      groupId: companyInfo ? companyInfo.groupId : '',
    };

  }

  async componentDidMount() {
    let { status, count, group } = queryString.parse(this.props.location.search);
    let qs = 'earlyExit';
    if (status === 'Late Entry') {
      qs = 'lateEntry';
    } else if (status === 'No Entry') {
      qs = 'noEntry';
    }
    group = group === 'null' ? null : group

    const violations = await this.props.actions.violationListing(qs, group);
    if (status === 'No Entry') {
      this.setState({
        ...this.state, status, count, violations, allViolations: violations, loading: false, excelExportItems: [
          {
            columns: ['PJP', 'Trike', 'Hotspot', 'Group'],
            data: map(violations, item => {
              return [item.pjp, item.vehicle, item.hotspot, item.group]

            })
          }
        ]
      })
    } else {
      let col5 = status === 'Late Entry' ? 'Hotspot Entry Time' : 'Hotspot Exit Time';
      let col6 = status === 'Late Entry' ? 'Actual Entry Time' : 'Actual Exit Time';

      this.setState({
        ...this.state, status, count, violations, allViolations: violations, loading: false, excelExportItems: [
          {
            columns: ['PJP', 'Trike', 'Hotspot', 'Group', `${col5}`, `${col6}`],
            data: map(violations, item => {
              return [item.pjp, item.vehicle, item.hotspot, item.group, moment(item.actualTime).format('h:mm a') || 'N/A', moment(item.eventTime).format('h:mm a') || 'N/A']

            })
          }
        ]
      })
    }

  }
  selectGroup = selectedGroup => this.setState({ selectedGroup });
  onChange(event) {
    const value = event.target.value;
    if (value) {
      this.setState({ ...this.state, search: value || '' });
    } else {
      this.setState({ ...this.state, search: value || '', things: this.props.things });
    }
  }
  searchVehicle = (e) => {
    e.preventDefault();
    const value = this.state.search;
    if (value) {
      let violations = filter(this.state.allViolations, (item) => {
        if (item.vehicle.search(new RegExp(value, 'i')) >= 0 || item.pjp.search(new RegExp(value, 'i')) >= 0) {
          return item;
        }
      });
      this.setState({ ...this.state, violations, search: value || '' });
    } else {
      this.setState({ ...this.state, search: value || '', violations: this.state.allViolations });
    }
  };
  navigateToDashboard = () => {
    this.props.history.push('/dashboard');
  }
  render() {
    const loader = <Spinner key='1' />;
    const { status } = this.state;
    return (
      <div>
        {this.state.loading ? [loader] : <div>

          <div className='row height-50 p-t-9-px'>
            <button className='back-btn-listing' onClick={this.navigateToDashboard}>Back</button>
            <h4 className='m-l-20-px'>Yesterday's Violations - {status} ({this.state.count})</h4>
          </div>
          <hr />

          <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-4 margin-top-20'>
              <form onSubmit={this.searchVehicle}>
                <TextFieldGroup
                  field={'tsearch'}
                  placeholder={'Search Trikes / PJP'}
                  value={this.state.search}
                  type={'text'}
                  onChange={this.onChange.bind(this)}
                />
                <a className='search-icon' onClick={this.searchVehicle}><img src={searchIcon} alt='' /></a>
              </form>
            </div>
            <div className='col-lg-8 col-md-8 col-sm-8 margin-top-20'>
              {/* <div className='float-right'>
                <ExcelFile filename='Violation Report' element={<button className='btn btn-success m-l-5-px'>Export</button>}>
                  <ExcelSheet dataSet={this.state.excelExportItems} name='Organization' />
                </ExcelFile>
              </div> */}
            </div>

          </div>


          <div className='row margin-top-20'>
            <table className='table-auxo'>
              <thead>
                <tr>
                  <th className='width-20'>PJP</th>
                  <th className='width-20'>Trike</th>
                  <th className='width-10'>Hotspot</th>
                  <th className='width-10'>Group</th>
                  {status !== 'No Entry' &&
                    <>
                      <th className='width-20'>{status === 'Late Entry' ? 'Hotspot Entry Time' : 'Hotspot Exit Time'}</th>
                      <th className='width-20'>{status === 'Late Entry' ? 'Actual Entry Time' : 'Actual Exit Time'}</th>
                    </>
                  }
                </tr>
              </thead>

              <tbody>


                {this.state.violations.map((item, index) => {
                  return (
                    <tr className='vehicle-hover' key={index}>
                      <td className='width-20'>
                        <span>{item.pjp || 'N/A'}</span>
                      </td>

                      <td className='width-20'>
                        <span>{item.vehicle || 'N/A'}</span>
                      </td>
                      <td className='width-10'>
                        <span>{item.hotspot || 'N/A'}</span>
                      </td>
                      <td className='width-10'>
                        <span>{item.group || 'N/A'}</span>
                      </td>
                      {status !== 'No Entry' &&
                        <>
                          <td className='width-20'>
                            <span>{moment(item.actualTime).format('h:mm a') || 'N/A'}</span>
                          </td>
                          <td className='width-20'>
                            <span>{moment(item.eventTime).format('h:mm a') || 'N/A'}</span>
                          </td>
                        </>
                      }
                    </tr>)
                })}
                {!this.state.violations.length && <div className='no-data-found' key='1'>
                  <h2>No Record Found</h2>
                </div>}



              </tbody>
            </table>

          </div>

        </div>
        }
      </div>
    );
  }
}

ViolationListView.propTypes = {};
ViolationListView.defaultProps = {};
const mapStateToProps = state => ({
  things: state.things.things,
  fuelGroups: state.fuelGroup.groups,
  canUpdateVehicle: state.permissions.updateVehicle,
  createGroup: state.permissions.createGroup,
  groupList: state.groups.subGroups,
  permissions: state.permissions

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(PJP.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViolationListView);
