import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import toastr from 'toastr';

import Users from '../../ducks/Users';

import { Spinner } from '../common/Spinner/index';
import TextFieldGroup from '../common/TextFieldGroup';
import { DeletePopUp } from '../common/DeletePopUp';

import searchIcon from '../../assets/img/ic_search.png';
import editIcon from '../../assets/img/ic_edit.png';
import trash_bin from '../../assets/img/trash_bin.png';


class UserList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      canCreateUser: false,
      searchTerm: '',
      users: [],
      isLoadingRecords: true,
      companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
      userToDelete: null,
      showDeleteConfirmation: false
    };
  };

  async componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    await this
      .props
      .actions
      .getUsers();
    this.setState({
      ...this.state,
      users: this.props.users,
      isLoadingRecords: false,
    });
  }


  searchUsers(event) {

    this.setState({
      ...this.state,
      searchTerm: event.target.value || ''
    });
    if (event.target.value === '') {
      this.setState({
        ...this.state,
        searchTerm: event.target.value || '',
        users: this.props.users
      });
    }
  }

  onSubmit = async e => {

    e.preventDefault();

    const value = this.state.searchTerm;
    if (value) {

      let users = _.filter(this.props.users, (item) => {
        let name = item.firstName + ' ' + item.lastName;
        if (name.search(new RegExp(value, 'i')) >= 0 || item.micUserName.search(new RegExp(value, 'i')) >= 0 || (item.phone && item.phone.search(new RegExp(value, 'i')) >= 0) || (item.email && item.email.search(new RegExp(value, 'i')) >= 0)) {
          return true;
        }
        return false;
      });
      this.setState({ ...this.state, users });

    } else {
      this.setState({ ...this.state, searchTerm: value || '', users: this.props.users });
    }
  };

  navigateToAddUser() {
    this
      .props
      .history
      .push(`/users/create/${this.state.companyInfo.groupId}`);

  }

  editUser(id) {

    this
      .props
      .history
      .push('/user/update/' + id);
  }

  confirmDelete = async () => {
    try {
      await this
        .props
        .actions
        .deleteUser(this.state.userToDelete);
      toastr.success('User deleted Successfully');
      this.fetchUsers();
      this.setState({ ...this.state, showDeleteConfirmation: false });
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  };
  cancelDelete = () => {
    this.setState({ ...this.state, showDeleteConfirmation: false });
  };
  showDeletePopup = (id) => {
    this.setState({ ...this.state, showDeleteConfirmation: true, userToDelete: id });
  };

  render() {
    const loader = <Spinner key='1' />;
    return (
      <div>
        {this.state.isLoadingRecords ?
          [loader] :
          <div>
            {this.state.showDeleteConfirmation ?
              <DeletePopUp confirmDelete={this.confirmDelete.bind(this)} cancelDelete={this.cancelDelete.bind(this)} /> :
              <div>
                <div className='row'>

                  <div className='col-lg-4 col-md-4 col-sm-4 margin-top-20'>

                    <form onSubmit={this.onSubmit.bind(this)}>
                      <TextFieldGroup
                        field={'search'}
                        placeholder={'Search Users'}
                        value={this.state.searchTerm}
                        type={'text'}
                        onChange={this
                          .searchUsers
                          .bind(this)} />
                      <a className='search-icon' onClick={this.onSubmit.bind(this)}><img src={searchIcon} alt='' /></a>
                    </form>
                  </div>
                  <div className='col-lg-8 col-md-8 col-sm-8'>
                    {this.props.permissions.createUser ? <button
                      className='btn auxo-primary-btn'
                      onClick={this
                        .navigateToAddUser
                        .bind(this)}>Add User</button> : ''}

                  </div>
                </div>


                <table className='table-auxo'>
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>USER NAME</th>
                      <th>EMAIL</th>
                      <th>PHONE</th>
                      <th>GROUP</th>
                      <th>ROLE</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users && this
                      .state
                      .users
                      .map(item => {
                        return (
                          <tr key={item['_id']}>
                            <td>{`${item.firstName} ${item.lastName}`}</td>
                            <td>{item.micUserName || ''}</td>
                            <td>{item.email || ''}</td>
                            <td>{item.phone || ''}</td>
                            <td>{item.group.name || ''}</td>
                            <td>{item.role ? item.role.name : ''}</td>
                            <td>
                              {this.props.permissions.updateUser &&
                                <a onClick={this.editUser.bind(this, item._id)}>
                                  <img src={editIcon} alt='' className='width-16' />
                                </a>}
                              {this.props.permissions.deleteUser &&
                                <a onClick={this.showDeletePopup.bind(this, item._id)}>
                                  <img src={trash_bin} alt='' className='width-16' />
                                </a>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>}
          </div>
        }
      </div>
    );
  }
}

UserList.propTypes = {};
UserList.defaultProps = {};
const mapStateToProps = state => ({ users: state.users.list, permissions: state.permissions });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Users.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
