import React, { Component,Fragment,useEffect,useState } from "react";
import { bindActionCreators } from "redux";
import { connect , useSelector } from "react-redux";
import toastr from "toastr";
import * as R from 'ramda';
import moment from "moment";
import { Card, CardHeader,CardTitle,CardBody } from "reactstrap";
import Select from "react-select-nested-group";
import Things from "../../../ducks/Things";
import ReactSpeedometer from "react-d3-speedometer";
import DateTime from 'react-datetime';
import { Spinner } from '../../common/Spinner';
import "./index.css";

import {
  LineChart,
  ResponsiveContainer,
  BarChart, Bar,
  XAxis,
  Legend,
  CartesianGrid,
  YAxis,
  Tooltip,
  Line
} from "recharts";

  const ChartData = props => {

    const filters = [
      { value: "company", label: "Company" },
      { value : "idividual" , label : "Individual Router"}
    ];

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    const [companyId, setCompanyId] = useState(companyInfo ? companyInfo.companyId : "");
    const [things, setThings] = useState([]);
    const [selectedRouter, setSelectedRouter] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState(moment());
    const [selectedEndDate, setSelectedEndDate] = useState(moment());
    const [selectedFilter , setSelectedFilter] = useState(filters[0]);
    const [screenDisplay, setScreenDisplay] = useState('company');
    const [isloader, setIsloader] = useState(false);
    const chartData = useSelector( state => state?.things?.chartsData);
    const RouterChartData = useSelector( state => state?.things?.RouterChartData);
    const actions = [props.actions.fetchThings(true)];

    useEffect(() => {
      setIsloader(true);
      loadThings();
      actions.push(props.actions.getCompanyRouterObservations(companyId));
    },[]);

    const loadThings = async () => {
      const things = await props.actions.fetchThings();
      const mappedValues = R.map(thing => ({ 'id':thing._id,value: thing.name, label: `${thing.plateNumber || 'N/A'}` }))(things);
      setThings(mappedValues);
    };



    const  handleRouterChange = (option) => {
      setSelectedRouter(option);
    };

    const  onSubmit = (option) => {

      if (selectedFilter.value == "idividual"){
        if (!selectedRouter){
          return toastr.error("Please Select Router");
        };

        let startDate = selectedStartDate['_d'];
        let endDate = selectedEndDate['_d'];

        let diff = endDate - startDate;
        let finalDiff = Math.ceil(diff/ (1000 * 60 * 60));
        if (finalDiff > 24 ) {
          return toastr.error("Duration Should be 1 Day Maximum");
        }
        setIsloader(true);
  
        let interval = {
                'startDate' : moment(startDate),
                'endDate':moment(endDate)
        };
        actions.push(props.actions.getIndividualRouterObservations(selectedRouter.id, JSON.stringify({ interval })));

      } else {
        actions.push(props.actions.getCompanyRouterObservations(companyId));
      }
    };

    const textColor = '#AAA'

    const  handleRouterClick = (event) => {
    };

    const  handleFilterChange = (option) => {
      setSelectedFilter(option);
      if (option?.value == "idividual"){
        let hours = selectedStartDate['_d'].getHours();
        selectedStartDate['_d'].setHours(hours - 1);
        setSelectedStartDate(selectedStartDate);
        return setScreenDisplay('idividual');
      } else {
        return setScreenDisplay('company');
      }
    };

    useEffect(() => {
      if (RouterChartData?.data || chartData?.data){
        setIsloader(false);
      }
    },[RouterChartData,chartData])



    return (
      <div className="routerData">
        <div className="row" style={{ margin: '20px',    marginBottom: "20px" }}>
          <span className="mainHeader">Power Analytics</span>
        </div>
        <div className="row" style={{ margin: '20px',    marginBottom: "30px" }}>
          <Card style={{ height: "140px" ,flexDirection: "column", minWidth: "100%" }}>
            <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Search </CardHeader>
            <div className="searchDiv" >
              <div className="col-lg-2 col-sm-2">
                <label>Select Filter</label>
                <Select
                  name="form-field-selectedThing"
                  value={selectedFilter}
                  searchable={true}
                  onChange={handleFilterChange}
                  options={filters} />
              </div>
              { selectedFilter?.value == "company" ? (
                <div className="col-lg-2" style={{ padding: '25px 0 0 25px' }}>
                  <button className={`btn btn-primary search-btn  search-button`} onClick={onSubmit}>Search</button>
                </div>
              ) :(
                <Fragment>
                  <div className="col-lg-2 col-sm-2">
                    <label>Select Router</label>
                    <Select
                      name="form-field-selectedThing"
                      value={selectedRouter}
                      searchable={true}
                      onChange={handleRouterChange}
                      options={things} />
                  </div>
                  <div className="col-lg-2 col-sm-2" >
                    <label>Start Date</label>
                    <DateTime value={selectedStartDate} dateFormat="DD-MM-YYYY" timeFormat="HH:mm" onChange={setSelectedStartDate} />
                  </div>
                  <div className="col-lg-2 col-sm-2" >
                    <label>End Date</label>
                    <DateTime value={selectedEndDate} dateFormat="DD-MM-YYYY" timeFormat="HH:mm" onChange={setSelectedEndDate} />
                  </div>
                  <div className="col-lg-2" style={{ padding: '25px 0 0 25px' }}>
                    <button className={`btn btn-primary search-btn  search-button`} onClick={onSubmit}>Search</button>
                  </div>
                </Fragment>
              )}
            </div>
          </Card>
          
        </div>
                  <div className="contentDiv">
                  { !isloader && chartData?.data?.length && screenDisplay == "company"  ?  (
                    <div>
                      <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Power</CardHeader>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart  width={1020} height={250} data={chartData.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis onClick={handleRouterClick} dataKey="router" label={{
                                value: "Router",
                                position: "insideBottomLeft",
                                offset: -8,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <YAxis label={{
                                value: "Power",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="power_1" fill="#8884d8" />
                              <Bar dataKey="power_2" fill="#82ca9d" />
                              <Bar dataKey="power_3" fill="#FF0000" />
                            </BarChart >
                          </ResponsiveContainer>              
                        </Card>
                      </div>
                      <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Current</CardHeader>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart  width={1020} height={250} data={chartData.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis onClick={(e) => handleRouterClick(e)}  dataKey="router" label={{
                                value: "Router",
                                position: "insideBottomLeft",
                                offset: -8,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Current",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="c_p_1" fill="#8884d8" />
                              <Bar dataKey="c_p_2" fill="#82ca9d" />
                              <Bar dataKey="c_p_3" fill="#FF0000" />
                            </BarChart >
                          </ResponsiveContainer>
                        </Card>
                      </div>          
                      <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Voltage</CardHeader>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart  width={1020} height={250} data={chartData.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis onClick={handleRouterClick} dataKey="router" label={{
                                value: "Router",
                                position: "insideBottomLeft",
                                offset: -8,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <YAxis label={{
                                value: "Voltage",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="v_p_1" fill="#8884d8" />
                              <Bar dataKey="v_p_2" fill="#82ca9d" />
                              <Bar dataKey="v_p_3" fill="#FF0000" />
                          </BarChart >
                          </ResponsiveContainer>
                        </Card>
                      </div>
                      <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Power Voltage</CardHeader>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart  width={1020} height={250} data={chartData.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis onClick={(e) => handleRouterClick(e)}  dataKey="router" label={{
                                value: "Router",
                                position: "insideBottomLeft",
                                offset: -8,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Power Voltage",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="p_v_1_2" fill="#8884d8" />
                              <Bar dataKey="p_v_2_3" fill="#82ca9d" />
                            </BarChart >
                          </ResponsiveContainer>
                        </Card>
                      </div>  
                    </div>
                  ) : isloader && screenDisplay == "company" ? <div style={{'marginTop': '20%'}}><Spinner/></div> : screenDisplay == "company" && 'There is no data To Display'}
               
                  { !isloader && RouterChartData?.data?.data?.length && screenDisplay == "idividual" ? (
                    <div>
                        <div className="consumedChartDiv">
                        <Card style={{height:"320px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Units Consumed </CardHeader>
                        <div className="row" style={{justifyContent: 'center'}}>
                          <div className="maintainance-card  powerConsumedRow row col-lg-3 col-sm-3">
                            <div className={"card-display maintainance-card-width"}>
                              <Card className="height-auto text-center cardInner">
                                <CardBody>
                                  <CardTitle>
                                    <div>
                                      <span className="card-heading">Units Consumed 1 </span>
                                    </div>
                                  </CardTitle>
                                  <div className="card-data">
                                    {RouterChartData?.data?.pConsumed?.p1Consumed} units
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                          <div className="maintainance-card powerConsumedRow row col-lg-3 col-sm-3">
                            <div className={"card-display maintainance-card-width"}>
                              <Card className="height-auto text-center cardInner">
                                <CardBody>
                                  <CardTitle>
                                    <div>
                                      <span className="card-heading">
                                      Units Consumed 2
                                      </span>
                                    </div>
                                  </CardTitle>
                                  <div className="card-data">
                                  {RouterChartData?.data?.pConsumed?.p2Consumed} units
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                          <div className="maintainance-card powerConsumedRow row col-lg-3 col-sm-3">
                            <div className={"card-display maintainance-card-width"}>
                              <Card className="height-auto text-center cardInner">
                                <CardBody>
                                  <CardTitle>
                                    <div>
                                      <span className="card-heading">Units Consumed 3</span>
                                    </div>
                                  </CardTitle>
                                  <div className="card-data">
                                  {RouterChartData?.data?.pConsumed?.p3Consumed} units
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </div>
                        </Card>
                      </div>

                      <div className="chartDiv">
                      < Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Power Last Observation {RouterChartData?.data?.data[RouterChartData?.data?.data.length-1]?.dateTime} </CardHeader>
                          <div className="powerChart row">
                            <div className="powerRow row col-lg-3 col-sm-3">
                            <div className="labelDiv">
                              <label className="labelText">Power 1</label>
                            </div>
                              <ReactSpeedometer
                                maxValue={2000}
                                value={RouterChartData?.data?.data[RouterChartData?.data?.data.length-1]?.power_1}
                                needleColor="red"
                                startColor="green"
                                segments={10}
                                endColor="blue"
                                textColor="grey"
                                textColor={textColor}
                              />
                            </div>
                            <div className="powerRow row col-lg-3 col-sm-3">
                              <div className="labelDiv">
                                <label className="labelText">Power 2</label>
                              </div>
                              <ReactSpeedometer
                                maxValue={2000}
                                value={RouterChartData?.data?.data[RouterChartData?.data?.data.length-1]?.power_2}
                                needleColor="red"
                                startColor="green"
                                segments={10}
                                endColor="blue"
                                textColor="grey"
                                textColor={textColor}
                              />
                            </div>
                            <div className="powerRow row col-lg-3 col-sm-3">
                              <div className="labelDiv">
                                <label className="labelText">Power 3</label>
                              </div>
                              <ReactSpeedometer
                                maxValue={2000}
                                value={RouterChartData?.data?.data[RouterChartData?.data?.data.length-1]?.power_3}
                                needleColor="red"
                                startColor="green"
                                segments={10}
                                endColor="blue"
                                textColor="grey"
                                textColor={textColor}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>

                      <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Units Consumed</CardHeader>
                          <ResponsiveContainer width="100%" height="100%">
                            <LineChart width={1020} height={250} data={RouterChartData?.data?.groupData || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis onClick={handleRouterClick} interval="preserveStartEnd" dataKey="groupKey" label={{
                                value: "Date and Hour",
                                position: "insideBottomLeft",
                                offset: -6,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Units Consumed",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="units_consumed_1" stroke="#8884d8" />
                              <Line type="monotone" dataKey="units_consumed_2" stroke="#82ca9d" />
                              <Line type="monotone" dataKey="units_consumed_3" stroke="#FF0000" />
                            </LineChart>
                          </ResponsiveContainer>
                        </Card>
                      </div>
        
                      <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Power</CardHeader>
                          <ResponsiveContainer width="100%" height="100%">
                            <LineChart width={1020} height={250} data={RouterChartData?.data?.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis onClick={handleRouterClick} interval="preserveStartEnd" dataKey="dateTime" label={{
                                value: "Date and Time",
                                position: "insideBottomLeft",
                                offset: -6,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Power",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="power_1" stroke="#8884d8" />
                              <Line type="monotone" dataKey="power_2" stroke="#82ca9d" />
                              <Line type="monotone" dataKey="power_3" stroke="#FF0000" />
                            </LineChart>
                          </ResponsiveContainer>
                        </Card>
                      </div>
        
                      {/* <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Current</CardHeader>
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart width={1020} height={250} data={RouterChartData.data?.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis onClick={handleRouterClick} interval="preserveStartEnd" dataKey="dateTime" label={{
                                  value: "Date and Time",
                                  position: "insideBottomLeft",
                                  offset: -8,
                                  fill: "#666666",
                                  fontFamily: "Roboto",
                                }} />
                                <YAxis label={{
                                  value: "Current",
                                  angle: -90,
                                  position: "left",
                                  offset: -4,
                                  fill: "#666666",
                                  fontFamily: "Roboto"
                                }} />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="c_p_1" stroke="#8884d8" />
                                <Line type="monotone" dataKey="c_p_2" stroke="#82ca9d" />
                                <Line type="monotone" dataKey="c_p_3" stroke="#FF0000" />
                              </LineChart>
                            </ResponsiveContainer>
                          </Card>
                        </div> */}
                        
                        {/* <div className="chartDiv">
                          <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                            <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Voltage</CardHeader>
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart width={1020} height={250} data={RouterChartData.data?.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis onClick={handleRouterClick} interval="preserveStartEnd" dataKey="dateTime" label={{
                                  value: "Date and Time",
                                  position: "insideBottomLeft",
                                  offset: -8,
                                  fill: "#666666",
                                  fontFamily: "Roboto",
                                }} />
                                <YAxis label={{
                                  value: "Voltage",
                                  angle: -90,
                                  position: "left",
                                  offset: -4,
                                  fill: "#666666",
                                  fontFamily: "Roboto"
                                }} />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="v_p_1" stroke="#8884d8" />
                                <Line type="monotone" dataKey="v_p_2" stroke="#82ca9d" />
                                <Line type="monotone" dataKey="v_p_3" stroke="#FF0000" />
                              </LineChart>
                            </ResponsiveContainer>
                          </Card>
                        </div> */}
        
                        {/* <div className="chartDiv">
                        <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                          <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Power Voltage</CardHeader>
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart width={1020} height={250} data={RouterChartData.data?.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis onClick={(e) => handleRouterClick(e)}  interval="preserveStartEnd"  dataKey="dateTime" label={{
                                  value: "Date and Time",
                                  position: "insideBottomLeft",
                                  offset: -8,
                                  fill: "#666666",
                                  fontFamily: "Roboto",
                                }} />
                                <YAxis label={{
                                  value: "Power Voltage",
                                  angle: -90,
                                  position: "left",
                                  offset: -4,
                                  fill: "#666666",
                                  fontFamily: "Roboto"
                                }} />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="p_v_1_2" stroke="#8884d8" />
                                <Line type="monotone" dataKey="p_v_2_3" stroke="#82ca9d" />
                              </LineChart>
                            </ResponsiveContainer>
                          </Card>
                        </div> */}
                      </div>
                  ) : isloader && screenDisplay == "idividual" ? <div style={{'marginTop': '20%'}}><Spinner/></div> : screenDisplay == "idividual" && 'There is no data To Display'}
                  {/* { !chartData.IsDisplay && !RouterChartData.IsDisplay && <div style={{'marginTop': '20%'}}><Spinner/></div> } */}
                </div>
      </div>
    );
}

const mapStateToProps = (state) => {
    // console.log("-----state---369--",state?.things);
  return {
    chartData: state?.things?.chartsData
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ChartData);
