import React from 'react';
import moment from 'moment';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const MileageChart = ({data}) => {
    // Get the start and end dates
    const startDate = moment(data[0].date, 'DD/MM/YYYY');
    const endDate = moment(data[data.length - 1].date, 'DD/MM/YYYY');
    const totalDays = endDate.diff(startDate, 'days');
    const interval = Math.round(totalDays/7) >  1 ? 6 : 0;

    // Custom tick formatter for Y-axis to add ' Km' to the values
    const formatYAxis = (tickItem) => {
        return `${tickItem} Km`;
    };

    // Custom tooltip formatter to add ' Km' to the values
    const tooltipFormatter = (value) => {
        return `${value} Km`;
    };

    return (
        <>
         <ResponsiveContainer width="100%" height="100%" aspect={3}> 
             <BarChart data={data} width={1020} height={250}>
                 <XAxis dataKey="date" stroke="#8884d8" interval={interval}/>
                 <YAxis tickFormatter={formatYAxis} />
                 <Tooltip formatter={tooltipFormatter} />
                 <Bar dataKey="totalDistance" fill='#8883d8'/>
             </BarChart>
        </ResponsiveContainer>
        </>
        
       );
}


export default MileageChart;