
import React from 'react';
import './maps.css'
import speedoMeter from '../../../assets/img/ic_speedometer_bubble.png';
import icFeed from '../../../assets/img/ic_feed.png';
// import CellTower from '../../../assets/img/tower.png';
import blackCircle from '../../../assets/img/black-circle.png';
// import car from '../../../assets/img/car.png';s
import moment from 'moment';

const { compose, withProps, withStateHandlers, lifecycle } = require("recompose");
const refs = {};
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
  Polyline,
  Circle
} = require("react-google-maps");

export const PatrollingMap = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `69vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withStateHandlers(
    ({ reference }) => ({ map: reference }), {
      onMapMounted: () => map => {
        
        // this.props.getMapInstance(map)
        // refs.map = ref
      
        return ({ map });

      },
      // onCenterChanged: ({ onCenterChanged }) => () => {

      //   (refs.map.getCenter().lat())
      //   console.log(refs.map.getCenter().lng())


      // }
    }
  ),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentWillMount() {

      this.setState({
        onDirectionsRendererMounted: ref => {
          refs.DirectionsRenderer = ref;
     
        },

      });
    }
  }),
)(props => {

  
  // 
  return (

    <GoogleMap
      ref={props.onMapMounted}
      zoom={props.zoom}
      defaultCenter={{ lat: props.defaultCenter.lat, lng: props.defaultCenter.lng }}

      center={{ lat: props.center.lat, lng: props.center.lng }}
      onDragEnd={() =>
        setTimeout(() => {
          props.onCenterChanged(props.map)
        }, 10)
      }
      onZoomChanged={() =>
        setTimeout(() => {
          props.onCenterChanged(props.map)
        }, 10)
      }
    >
      {props.directions && <> 
      <DirectionsRenderer 
        ref={props.onDirectionsRendererMounted}
        directions={props.directions} 
        panel={ document.getElementById('panel-direction') }
      /> 
      {
        setTimeout(()=>{
          props.getDirectionsRenderer(refs.DirectionsRenderer)           
        }, 2000)
        
        }
      </>}
     

        {props.showCellSitesOnMap && props.cellSites && props.cellSites.map((cellSite) => {
          return (
            <Marker
              key={cellSite._id}
              position={{ lat: parseFloat(cellSite.cords.lat), lng: parseFloat(cellSite.cords.lng) }}
              options={{
                icon: blackCircle,
              }}
            >
             {(props.selectedCellSite && props.selectedCellSite._id ===  cellSite._id)?  <InfoWindow options={{
                "backgroundColor": '#000000',
                "disableAutoPan": true,
              }}>
                <div className="info-bubble">

                <span>
                      <span>
                        <span>Plate Number:</span>
                        <span>{props.selectedCellSite.plateNumber || "N/A"}</span>
                      </span>
                      <br />
                      <span>
                        <span>Speed:</span>
                        <span>{props.selectedCellSite.speed || "0"}</span>
                      </span>
                      <br />
                      <span>
                        <span>Device IMEI:</span>
                        <span>{props.selectedCellSite.imei || "N/A"}</span>
                      </span>
                      <br/>
                      <span>
                        <span>Device MSISDN: </span>
                        <span>{props.selectedCellSite.phone || "N/A"}</span>
                      </span>
                      <br/>
                      <span>
                        <span>Last Update:</span>
                        <span>{props.selectedCellSite.lastObservationTime ? moment(props.selectedCellSite.lastObservationTime).format('DD/MM/YYYY, h:mm a') : "N/A"}</span>
                      </span>

                      <br />
                      <span>
                        <span>ODO Meter:</span>
                        <span>{props.selectedCellSite.odoMeterReading.toFixed(0) || "N/A"}</span>
                      </span>

                      <br />
                      <span>
                        <span>Model:</span>
                        <span>{props.selectedCellSite.vehicleMakeAndModel || "N/A"}</span>
                      </span>

                      <br />

                      <span>
                        <span >Thing Name:</span>
                        <span>{props.selectedCellSite.name || "N/A"}</span>
                      </span>


                    </span>
                {/* <span>
                    <span>
                      <span>{props.selectedCellSite.name || "N/A"}</span>
                    </span>
                   
                  </span> */}

                </div>
              </InfoWindow>: ''}

            </Marker>

          )
        })
        }
        {!props.showCellSitesOnMap && props.selectedCellSite && props.cellSites.map((cellSite) => {
          return (
          
            <Marker
              key={cellSite._id}
              position={{ lat: parseFloat(cellSite.cords.lat), lng: parseFloat(cellSite.cords.lng) }}
              options={{
                icon: blackCircle,
              }}
            >
              <InfoWindow options={{
                "backgroundColor": '#000000',
                "disableAutoPan": true,
              }}>
                <div className="info-bubble">

                  <span>
                    <span>
                      <span>{cellSite.name || "N/A"}</span>
                    </span><br/>
                    <span>
                      <span>{cellSite.district || "N/A"}</span>
                    </span><br/>
                    <span>
                      <span>{cellSite.isGuarded ? "Guarded" : 'Not Guarded'}</span>
                    </span>
                   
                  </span> 

                </div>
              </InfoWindow>

            </Marker>
          )})
          
        }

        {props.showTrafficLayerOnMap && 
          <TrafficLayer autoUpdate />
        }
     
        {props.showVehiclesOnMap && props.markers.map((marker) => {

          let vehicleStatus = marker.vehicleStatus;
          let arrowColor = "#03ABCE "
          if (vehicleStatus === "IGNITION_ON") {
            arrowColor = "#0abf60"
          }
          else if (vehicleStatus === "VEHICLE_MOVING") {
            arrowColor = "#03ABCE"
          }
          else if (vehicleStatus === "IGNITION_OFF") {
            arrowColor = "#FFA500"
          }
          else if (vehicleStatus === "DEVICE_UNPLUG" || vehicleStatus === "NEVER_HEARD" || vehicleStatus === "NOT_RESPONDING") {
            arrowColor = "#FF0000"
          }
          else if (vehicleStatus === "DEVICE_PLUG_IN") {
            arrowColor = "#FFA500"
          }
          else if (vehicleStatus === "IDLE") {
            arrowColor = "#b7b7b7"
          }

          let pathIcon = window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
          let  scale = 4.5;
          if(marker.isAdminVehicle){
            pathIcon = window.google.maps.SymbolPath.FORWARD_OPEN_ARROW;
            scale = 4
          }
          return (
            <Marker
              key={marker._id}
              position={{ lat: marker.latitude, lng: marker.longitude }}
              onClick={() => props.onVehicleClick({ value: marker._id, label: marker.plateNumber })}
              options={{
                icon: {
                  path: pathIcon,
                  scale,
                  fillColor: arrowColor,
                  fillOpacity: 3,
                  strokeWeight: 0.9,
                  rotation: marker.angle || 180,
                },
              }}
            >
              {props.showLabelOnMap ? <InfoWindow options={{
                "backgroundColor": '#000000',
                "disableAutoPan": true,

              }}>
                <div className="info-bubble">


                    <span>
                      <span>
                        <span>{marker.plateNumber || "N/A"}</span>
                      </span>
                      <br />
                      <span>
                        <span>Speed:</span>
                        <span>{marker.speed || "0"}</span>
                        
                      </span>
                      
                     

                    </span> 


                </div>
              </InfoWindow> : ""}

            </Marker>

          )
        })}
        {props.selectedCellSite &&
      
         <Circle
         center={{lat: parseFloat(props.selectedCellSite.cords.lat), lng: parseFloat(props.selectedCellSite.cords.lng)}}
         radius={parseInt(props.selectedCellSite.radius, 10)}
         options={
             { strokeColor: '#03ABCE', fillColor: '#03ABCE', fillOpacity: 0.42 }
         }
        />
        }
        
        {props.selectedRescueCellSitesInProgress &&
        <>
         <Polyline
         path={props.selectedRescueCellSitesInProgress.path1}
         options={{strokeColor: '#2741a5', fillColor: '#2741a5'}}
         />
         {props.selectedRescueCellSitesInProgress.path2 && 
          < Polyline
         path={props.selectedRescueCellSitesInProgress.path2}
         options={{strokeColor: '#2741a5', fillColor: '#2741a5'}}
         />
        }
        </>
        
        }

    </GoogleMap>)

}
);