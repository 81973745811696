import {isEmpty} from 'lodash';
import {isEmptyValue, isAlphaNumeric, isNumeric} from './validator';

export function validateInput(data) {
    let errors = {};
    if (isEmptyValue(data.serialNumber)) {
        errors.serialNumber = 'Serial Number is required';
    }
    else if (!isAlphaNumeric(data.serialNumber)) {
        errors.serialNumber = 'Serial Number must be alpha numeric';
    }
    else if (data.serialNumber.length >= 15)  {
        errors.name = 'Max 10 characters are allowed in Serial Number';
    }
    if (isEmptyValue(data.name)) {
        errors.name = 'Driver name is required';
    }
    else if (!isAlphaNumeric(data.name)) {
        errors.name = 'Driver name should be alphanumeric';
    }
    else if (data.name.length > 50)  {
        errors.name = 'Max 50 characters are allowed in name';
    }
    if (isEmptyValue(data.dateOfJoining)) {
        errors.dateOfJoining = 'Data of joining is required';
    }
    if(isEmpty(data.email)) {
        // errors.email = 'Email address is required'
    } else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)){
        errors.email = 'Enter a valid email address';
    }

    
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

