import { compose, withProps, withStateHandlers, lifecycle } from "recompose";
import React, {useEffect, useState} from 'react';
import "./geofence.css";
import { withScriptjs, withGoogleMap, GoogleMap, Circle, Marker } from "react-google-maps"
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const _ = require("lodash");
let self = {};


const MarkerMap = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div className="geofence-map-container" />,
        mapElement: <div className='geofence-map-element' />,
    }),
    lifecycle({
        componentDidMount() {
            const refs = {}
            self = {...self, ...this.props}
            setTimeout(() => {

                this.setState({
                    bounds: null,
                    center: {
                        lat: self.center.lat, lng: self.center.lng
                    },
                    onMapMounted: ref => {
                        refs.map = ref;
                    },
                    onBoundsChanged: () => {
                        this.setState({
                            bounds: refs.map.getBounds(),
                            center: refs.map.getCenter(),
                        })
                    },
                    onSearchBoxMounted: ref => {
                        refs.searchBox = ref;
                    },
                    onPlacesChanged: () => {
                        const places = refs.searchBox.getPlaces();
                        const bounds = new window.google.maps.LatLngBounds();

                        places.forEach(place => {
                            if (place.geometry.viewport) {
                                bounds.union(place.geometry.viewport)
                            } else {
                                bounds.extend(place.geometry.location)
                            }
                        });
                        const nextMarkers = places.map(place => ({
                            position: place.geometry.location,
                        }));
                        const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                        this.setState({
                            center: nextCenter
                        });
                        self.circle.center = nextCenter;
                        self.onMapChange(self.circle)
                        // refs.map.fitBounds(bounds);
                    },
                })
            }, 2000)
        },
    }),
    withScriptjs,
    withGoogleMap,
    withStateHandlers(
        ({ circleReference }) => ({ circle: circleReference }),
        {
            onCircleMounted: (state) => (circle) => {
                return ({ circle })
            }
        }

    )
)((props) => {
    self = props;
    function radiusToZoom() {
        const radius = (props.radius / 1000);
        return Math.round(13 - Math.log(radius) / Math.LN2);
    }
    const [data, changeData] = useState(props);

    useEffect(() => {
      changeData(props);
    }, [props.center.lat]);

    const zoom = parseInt(radiusToZoom(), 10);

    return (<GoogleMap
        zoom={zoom}
        defaultCenter={{ lat: data.center.lat, lng: data.center.lng }}
        center={data.center}
    >
         {props.hotspots && props.hotspots.map((hotspot) => {
           return(
        <Circle
        ref={props.onCircleMounted}
        center={{
          lat: hotspot.center[1],
          lng: hotspot.center[0]
        }}
        draggable={false}
        editable={false}
        radius={hotspot.radius}
        options={
            { strokeColor: '#03ABCE', fillColor: '#03ABCE', fillOpacity: 0.42 }
        }
       
         />)})}

{props.marker ?
        <Marker
              
              position={{ lat: data.marker.lastLocation.lat, lng: data.marker.lastLocation.lng }}
              options={{
                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 4.5,
                  fillColor: '#FF0000',
                  fillOpacity: 10,
                  strokeWeight: 0.8,
                  rotation: props.marker.angle || 180,
                }}
            >
              </Marker> : <Marker position={{lat: parseFloat(data.center.lat), lng: parseFloat(data.center.lng)}} ></Marker>
}
    </GoogleMap>);
});


export default MarkerMap;