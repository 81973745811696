import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as _ from 'lodash';
import Modal from 'react-bootstrap4-modal';
import CSVReader from 'react-csv-reader';
import * as R from 'ramda';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner, WhiteSpinner } from '../../common/Spinner/index';
import PJP from '../../../ducks/PJP';
import TextFieldGroup from '../../common/TextFieldGroup';
import searchIcon from '../../../assets/img/ic_search.png';
import editIcon from '../../../assets/img/ic_edit.png';
import trash_bin from '../../../assets/img/trash_bin.png';
import { isEmptyValue, isAlphaNumeric } from '../../../validations/validator';

class PJPListing extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingRecords: true,
      search: '',
      pjps: [],
      pjpToBeDeleted: {},
      pjpsImport: [],
      showImportPJP: false,
      deletingPJP: false,
      
    };
    this.searchPJP = this.searchPJP.bind(this);
    this.onChangeData = this.onChangeData.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  loadPJPs = async (initLoad) => {
    await this
      .props
      .actions
      .getGroupPJPs1(this.state.search, initLoad);
    this.setState({
      ...this.state,
      pjps: this.props.pjps,
      isLoadingRecords: false
    })
  }
  async componentDidMount() {
   await this.loadPJPs(true);
  }
  async onChange(event) {
    const value = event.target.value;
    if (value) { this.setState({ ...this.state, search: value || '' }); } else {
      await this.setState({ ...this.state, search: value || '' })
      this.loadPJPs(true);
    }
  }
  navigateToCreatePJP() {
    this
      .props
      .history
      .push('/pjp/create');
  }

  updatePJP(item) {
    this
      .props
      .history
      .push(`/pjp/update/${item._id}`);

  }
  showDeletePJPConfirmation(item) {
    this.setState({
      ...this.state,
      pjpToBeDeleted: item,
      showDeleteConfirmation: true
    })

  };
  
  async deletePJP() {
    if (!this.state.deletingPJP) {
      await this.setState({
        deletingPJP: true,
      });
      await this
        .props
        .actions
        .deletePJP({ id: this.state.pjpToBeDeleted._id, vehicles: this.state.pjpToBeDeleted.vehicles });

      toastr.success("PJP deleted successfully!");

      let pjps = _.filter(this.state.pjps, item => {
        return item._id !== this.state.pjpToBeDeleted._id;
      })
      this.setState({
        ...this.state,
        showDeleteConfirmation: false,
        pjps,
        deletingPJP: false,
      })
    }
  }
  async uploadPJP() {
    try {
      const { isLoading } = this.state;
      if (isLoading) {
        return;
      }
      const pjps = this.state.pjpsImport;
      const hasError = pjps.reduce((error, pjp) => {
        if (error) {
          return error;
        }
        if (!pjp.name) {
          return true;
        }
        if (!pjp.latlng) {
          return true;
        }
       
        return false;

      }, false);

      if (hasError) {
        return toastr.error('Some fields are missing.');
      }
      this.setState({ isLoading: true });
      let companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

      await this.props.actions.importPJP({ pjps: this.state.pjpsImport, groupId: companyInfo.groupId });
      toastr.success("PJPs will be imported in a while!");
      this.setState({
        ...this.state,
        isLoading: false,
        showImportPJP: false,
        pjpsImport: [],
      })
    } catch (e) {
      this.setState({ isLoading: false });
      toastr.error(e.response ? e.response.data.message : e);
    }

  }
  cancelDelete() {
    this.setState({ ...this.state, showDeleteConfirmation: false })
  }
  cancelUpload() {
    this.setState({ ...this.state, showImportPJP: false })
  }

  async searchPJP(e) {
    e.preventDefault();
    const value = this.state.search;
    await this.setState({
      ...this.state,
      search: value || ''
    });
    this.loadPJPs(true);
  }

  handleDarkSideForce() {
    toastr.error('Invalid file format, csv file is required!');
  }

  handleFileUpload(data) {
 
  
    if (data && data.length) {
      if (data.length <= 1) {
        return toastr.error('No date found.');
      }
      let heading = data[0];

      if (heading[0] !== 'Name' || heading[1] !== 'Description'  || heading[2] !== 'lat,lng' || heading[3] !== 'Radius') {
        return toastr.error('Invalid Columns in csv!');
      }

      let pjpsImport = [];
      _.forEach(data, (item, index) => {

        const isAllEmpty = R.all(R.isEmpty, item);

        if (index !== 0 && item.length === 4 && !isAllEmpty) {

          let obj = { name: '', description: '', latlng: '', radius: 300, valid: true };
          _.forEach(item, (value, valueIndex) => {

            if (valueIndex === 0) {
              obj.name = _.trim(value);

              if (isEmptyValue(obj.name)) {
                toastr.error('Provide value for name');
                // obj.valid = false;
              }
              // else if (!isAlphaNumeric(obj.name)) {
              //   toastr.error('PJP name must be alphanumeric');
              //   obj.valid = false;
              // }
              // else if (obj.name.length > 100) {
              //   toastr.error('Max 100 characters are allowed in name');
              //   // obj.valid = false;
              // }

            }
            if (valueIndex === 1 && obj.valid) {
              obj.description = _.trim(value);
              if (!isAlphaNumeric(obj.description)) {
                toastr.error('Description must be alphanumeric');
                // obj.valid = false;
              }
              else if (obj.description.length > 500) {
                toastr.error('Max 500 characters are allowed in description.');
                // obj.valid = false;
              }
            }
           
            if (valueIndex === 2 && obj.valid) {
              obj.latlng = _.trim(value);
              if (!obj.latlng) {
                toastr.error('latlng not provided');
                // obj.valid = false;
              }
            }
            if (valueIndex === 3 && obj.valid) {
              obj.radius = parseInt(value, 10);
              if (isNaN(obj.radius)) {
                toastr.error('Radius must be a number');
                // obj.valid = false;
              }
              if (obj.radius < 100) {
                toastr.error(`Radius of ${obj.name} is converted to 100 m`);
                obj.radius = 100;
              }

            }
          })
          if (obj.valid) {

            pjpsImport.push(obj);
          }
        }
      });
      if (pjpsImport.length) {
        this.setState({ ...this.state, pjpsImport, showImportPJP: true });
      }

    }
  }
  onChangeData(e) {

    if (e && e.target) {
      const index = e.target.id;

      let pjpsImport = this.state.pjpsImport;
      let item = pjpsImport[index]
      item[e.target.name] = e.target.value;
      pjpsImport[index] = item;
      this.setState({ ...this.state, pjpsImport })
    }
  };
  

  render() {
    let notFound;
    const pjps = this.state.pjps;
    if (!this.state.isLoadingRecords && pjps.length === 0) {
      notFound = (
        <div className='no-data-found' key='1'>
          <h2>No pjp added</h2>
        </div>)
    };

    const loader = <Spinner key='1' />
    const smallLoader = <WhiteSpinner key="1" />

    return (

      <div>
       
     
        {this.state.showDeleteConfirmation ?
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName='delete-modal-dialog'>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Delete PJP?</h5>
            </div>
            <div className='modal-body'>
              <h6>Are you sure you want to delete PJP {this.state.pjpToBeDeleted.name}?</h6>


            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .deletePJP
                  .bind(this)}>
                {this.state.deletingPJP ? [smallLoader] : 'Confirm Delete'}
              </button>

              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
          : ''}
        {this.state.showImportPJP &&
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName=''>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelUpload
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Import PJPs</h5>
            </div>
            <div className='modal-body'>
              <table className='table-auxo'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th>LAT,LNG</th>
                    <th>RADIUS (METERS)</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.pjpsImport && this
                    .state
                    .pjpsImport
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td >
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.name}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'name'}
                              placeholder={'PJP name'}
                              isRequired={true}
                            />
                          </td>
                          <td >
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.description}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'description'}
                              placeholder={'Description'}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.latlng}
                              type={'text'}
                              id={index}
                              key={index}
                              field={'latlng'}
                              placeholder={'lat, lng'}
                              isRequired={true}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.radius}
                              type={'number'}
                              id={index}
                              key={index}
                              field={'radius'}
                              placeholder={'Radius'}
                              isRequired={true}
                            />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .uploadPJP
                  .bind(this)}>
                Confirm
                    </button>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelUpload
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
        }

        <div className='row'>
          <div className='col-lg-3 col-md-3 col-sm-4 margin-top-20'>
            <form onSubmit={this.searchPJP}>
              <TextFieldGroup
                field={"tsearch"}
                placeholder={"Search PJP"}
                value={this.state.search}
                type={'text'}
                onChange={this
                  .onChange
                  .bind(this)} />
              <a className='search-icon' onClick={this.searchPJP}><img src={searchIcon} alt='' /></a>
            </form>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2 margin-top-20'>
          {!this.state.isLoadingRecords &&
        <div className="pjpCount">Total PJP: {this.props.pjpCount}</div>
        }
          </div>

          <div className='col-lg-7 col-md-7 col-sm-7'>
           
              <span>
                <button
                  className='btn auxo-primary-btn'
                  onClick={this
                    .navigateToCreatePJP
                    .bind(this)}>Create PJP</button>
                </span>
                {/* <span>
               
                  <button className='btn auxo-primary-btn' onClick={() => {
                    document.getElementById('UniqueObiWan').click();
                  }}>
                     
                    <div className='import-pjp'>Import PJPs
                   </div>
                   {!this.state.showImportPJP && <CSVReader
                        cssClass='things-input-reader'
                        accept='.csv'
                        onFileLoaded={this.handleFileUpload}
                        onError={this.handleDarkSideForce}
                        inputId='UniqueObiWan'
                      />
                      }
                  </button> */}
                {/* </span>
                <a
                  className='download-pjp-template btn auxo-primary-btn'
                  href={`${URL}/companies/files/PJPTemplate.csv?token=${token}`}
                >Download Template</a>
              </span> */}
             
          </div>
        </div>
        {this.state.isLoadingRecords ?
          [loader] : 
        <div className='row'>
        <InfiniteScroll
          pageStart={0}
          className="w-100-p"
          loadMore={()=>{this.loadPJPs(false)}}
          hasMore={this.props.hasMoreItems}
          loader={loader}>
          {!this.state.isLoadingRecords && pjps.length !== 0
            ? <div className='trip-table-heading'>
              <p className='width-30'>PJP</p>
              <p className='width-30'>DESCRIPTION</p>
              <p className='width-30'>HOTSPOTS</p>
                <p className='width-10'>ACTIONS</p> 
            </div>
            : [notFound]}

          {pjps.map((item, index) => {
            index = 'abc' + index + 'z';
            item = _.clone(item);

            return (

              <div className='width-100' key={index}>
                <div className='trip-table-data'>

                  <p className='width-30'>
                    {item.name}
                  </p>

                  <p className='width-30'>{item.description}
                    </p>

                  <p className='width-30'>{item.numOfHotspots || 'N/A'}</p>
                  <span>
                    
                      <a className='padding-10' onClick={this
                        .updatePJP
                        .bind(this, item)}><img src={editIcon} alt='' /></a> 
                   
                      <a onClick={this
                        .showDeletePJPConfirmation
                        .bind(this, item)}><img src={trash_bin} className='width16' alt='' /></a> 
                  </span>
                </div>
              </div>

            )
          })}
 </InfiniteScroll>
        </div>
        }
     </div>
    );
  }
}

PJPListing.propTypes = {};
PJPListing.defaultProps = {};
const mapStateToProps = state => ({ 
  pjps: state.pjp.pjps,
  pjpCount: state.pjp.pjpCount,
  hasMoreItems: state.pjp.hasMoreItems});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(PJP.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PJPListing);
