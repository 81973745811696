import Duck from 'extensible-duck'
import axios from "axios";
import moment from "moment";
import {rolesEndPoints} from "../api/endpoints";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
  namespace: 'AuxoRoles', store: 'roles',
  types: ['ROLES_SINGLE_FETCHED', 'ROLES_FETCHED', 'ROLES_IS_LOADING'],
  initialState: {
    list: [],
    isLoading: false,
    single: {}
},
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.ROLES_IS_LOADING:
          return {
              ...state,
              isLoading: action.isLoading
          };
      case duck.types.ROLES_FETCHED:
          return {
              ...state,
              list: action.roles
          };
      case duck.types.ROLES_SINGLE_FETCHED:
          return {
              ...state,
              single: action.role
          };
      default:
          return state;
  }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({
    getRoles: () => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().roles.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
      }
      try {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: true});
          const {companyId} = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.get(rolesEndPoints.companyRoles(companyId));
          const roles = response.data.data;
          dispatch({type: duck.types.ROLES_FETCHED, roles});
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
      }
      catch (e) {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
          throw e;
      }
  },
  createRole: state => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().roles.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
      }
      try {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: true});
    
         await axios.post(rolesEndPoints.role, {...state, token: localStorage.refreshToken});
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
      }
      catch (e) {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
          throw e;
      }
  },
  getSingle: (id) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().roles.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
      }
      try {
          
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: true});
      
              const response = await axios.get(rolesEndPoints.individualRole(id));
              let role = response.data.data;
              dispatch({type: duck.types.ROLES_SINGLE_FETCHED, role});
          
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
      }
      catch (e) {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
          throw e;
      }
  },
  deleteRole: (id) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().roles.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
      }
      try {
          
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: true});
      
               await axios.delete(rolesEndPoints.individualRole(id));
          
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
      }
      catch (e) {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
          throw e;
      }
  },
  updateRole: (id, state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().roles.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
      }
      try {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: true});
         
          delete state["_id"];
          await axios.put(rolesEndPoints.individualRole(id), {...state});
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
      }
      catch (e) {
          dispatch({type: duck.types.ROLES_IS_LOADING, isLoading: false});
          throw e;
      }
  },

  })
})