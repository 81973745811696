import React, {Component} from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import background from "../../assets/img/login-background.jpg";
import "./ConfirmCode.css";
import auxo from "../../assets/img/AUXO-Horiz-fleet.png";
import tooltipIcon from "../../assets/img/tooltip.png";
import toastr from "toastr";
import {connect} from "react-redux";
import UserAuthentication from "../../ducks/UserAuthentication";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import {validateConfirmCodeInput} from "../../validations/auth";
import {WhiteSpinner} from '../common/Spinner/index';

class ConfirmCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.match.params.userName,
            code:"",
            password:"",
            confirmPassword:"",
            errors: {},
            loader: false
        }
    }

    isValid = () => {
        const {errors, isValid} = validateConfirmCodeInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }

        return isValid;
    };
    
    onChange = e => {
        this.setState({[e.target.name]: e.target.value, errors:{...this.state.errors, [e.target.name]: ''}});
    };
    onSubmit = async e => {
        e.preventDefault();
        if(this.isValid()){

            try {
                this.setState({...this.state, loader: true})
                const userCred = {
                    username: this.state.username,
                    code:this.state.code,
                    password:this.state.password
                }
                await this.props.actions.confirmCode(userCred);
                toastr.success("New password is set successfully");
                this.setState({...this.state, loader: false})
                this.props.history.push("/")
            }
            catch (error){
                if (error.response) {
                    const data = error.response.data.message;
                    toastr.error(data);
                }
                else {
                    toastr.error(error);
                }
                this.setState({...this.state, loader: false})
            }
        }
    };

    render() {
        const labelIcon = {
            icon: tooltipIcon,
            text: "Password must be 8 characters long containing numbers, uppercase and lowercase letters."
        }
        const loader = <WhiteSpinner key="1"/>
        return (
            <div className="justify-content-md-center login-container " style={{backgroundImage: background}}>
                <div className="login-box mt-lg-5">
                    <div className="row">
                        <img className="login-logo" src={auxo} alt=""/>
                    </div>
                    <form className={"login-form"} onSubmit={this.onSubmit}>
                    
                        <TextFieldGroup
                            field={"code"}
                            placeholder={"Enter confirmation code sent to you"}
                            value={this.state.code}
                            label={"Confirmation Code"}
                            type={"text"}
                            onChange={this.onChange}
                            error={this.state.errors.code}/>
                        <TextFieldGroup
                            field={"password"}
                            placeholder={"Set new password"}
                            value={this.state.password}
                            label={"New Password"}
                            type={"password"}
                            onChange={this.onChange}
                            labelIcon={labelIcon}
                            error={this.state.errors.password}/>
                          
                        <TextFieldGroup
                            field={"confirmPassword"}
                            placeholder={"Confirm password"}
                            value={this.state.confirmPassword}
                            label={"Confirm Password"}
                            type={"password"}                           
                            onChange={this.onChange}
                            error={this.state.errors.confirmPassword}/>
                        <div className="mt-lg-5">
                            <button className="btn btn-success btn-login" type="submit">{this.state.loader ? [loader] :  'Submit'}</button>
                        </div>
                        <div className={"help-link-section"}>
                        <Link to="/login">Log In</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

ConfirmCode.propTypes = {};
ConfirmCode.defaultProps = {};
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(UserAuthentication.creators, dispatch)});
export default connect(null,mapDispatchToProps)(ConfirmCode);
