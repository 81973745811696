import Duck from 'extensible-duck'
import axios from "axios";
import {pjpEndPoints} from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoPJP',
    store: 'pjp',
    types: [
        'IS_LOADING', 'DETAILS_FETCHED', 'PJP_FETCHED', 'CLEAR_PJP', 'PJP_COUNT'
    ],
    initialState: {
        pjp: {},
        pjps: [],
        pjpCount: 0,
        hasMoreItems: false,
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.PJP_FETCHED:
                return {
                    ...state,
                    pjps: [...state.pjps, ...action.pjps],
                    hasMoreItems: action.pjps.length > 0 ? true : false
                };
            case duck.types.PJP_COUNT:
                return {
                    ...state,
                    pjpCount: action.pjpCount,
                };
            case duck.types.CLEAR_PJP:
                return {
                    ...state,
                    pjps: [],
                };

            case duck.types.DETAILS_FETCHED:
                return {
                    ...state,
                    pjp: action.pjp
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().pjp.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(pjpEndPoints.individualPJP(state.id, companyInfo.groupId));
                let pjp = response.data.data;
                dispatch({type: duck.types.DETAILS_FETCHED, pjp});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupPJPs: () => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().pjp.isLoading) {
                return;
            }
            
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                dispatch({type: duck.types.CLEAR_PJP});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(pjpEndPoints.pjp(companyInfo.groupId));
                let pjps = response.data.data;
                dispatch({type: duck.types.PJP_FETCHED, pjps});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return pjps
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupPJPs1: (search, clearRecord) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().pjp.isLoading) {
                return;
            }
            if(clearRecord){
              dispatch({type: duck.types.CLEAR_PJP});
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                let url = pjpEndPoints.pjpList(companyInfo.groupId) + `?search=${search}&offset=${getState().pjp.pjps.length}&limit=50`;
       
                const response = await axios.get(url);
                const {pjp, pjpCount} = response.data.data;
                dispatch({type: duck.types.PJP_FETCHED, pjps: pjp});
                dispatch({type: duck.types.PJP_COUNT, pjpCount});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return pjp;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },

     
        PJP: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().pjp.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                if(state.id){
                    await axios.put(pjpEndPoints.individualPJP(state.id, companyInfo.groupId), state.pjpObj);
                } else{
                    await axios.post(pjpEndPoints.pjp(companyInfo.groupId), state);
                }
               
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
              console.log(e)
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
        deletePJP: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().pjp.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                await axios.delete(pjpEndPoints.individualPJP(state.id, companyInfo.groupId), state.vehicles);

                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
        stats: (groupId) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().pjp.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(pjpEndPoints.stats(groupId ? groupId: companyInfo.groupId));
                const {data} = response.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return data;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        generalStats: (groupId) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(pjpEndPoints.generalStats(groupId ? groupId: companyInfo.groupId));
                const {data} = response.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return data;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        violationListing: (qs, groupId) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().pjp.isLoading) {
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(pjpEndPoints.violationListing(groupId? groupId: companyInfo.groupId, qs));
                const {data} = response.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return data;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
    })
})