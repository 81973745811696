import React, { Component } from "react";
import { connect } from "react-redux";
import Groups from "../../../ducks/Groups";
import GeoFence from "../../../ducks/GeoFence";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import CheckboxListComponent from "../../common/CheckboxListComponent";
import { CheckboxInlineComponent } from "../../common/CheckboxListComponent";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import GeoFenceMap from "../../common/maps/geofence";
import "../index.css";
import { validateInput } from "../../../validations/geofence";
import { WhiteSpinner, Spinner } from "../../common/Spinner/index";
import Slider from "rc-slider";
import { HandleSlider } from "../../common/HandleSlider";

import * as _ from "lodash";

import toastr from "toastr";

class UpdateGeoFence extends Component {
  constructor() {
    super();
    this.state = {
      geoFences : [],
      name: "",
      description: "",
      vehicles: [],
      groups: [],
      alertList: [
        {
          id: 1,
          mainTitle: "Entry",
          checked: false,
        },
        {
          id: 2,
          mainTitle: "Exit",
          checked: false,
        },
      ],
      speedList: [
        {
          id: 1,
          mainTitle: "Speed",
          checked: false,
        },
      ],
      selectedVehicles: [],
      unselectedVehicles: [],
      activeTab: "vehicles",
      center: 0,
      radius: 0,
      errors: {},
      loader: false,
      loadingData: true,
      polygon: false,
      path: [],
      geoFenceType: "",
    };
  }


  loadGeofences = async (initLoad) => {
    await this
      .props
      .geoFenceActions
      .getGroupGeoFences1("", initLoad);

    this.setState({
      ...this.state,
      geoFences: this.props.geoFences,
      isLoadingRecords: false
    })
  }

  async componentDidMount() {
    await this.loadGeofences(true);
    await this.props.actions.getGroupsAndVehicles();
    await this.props.geoFenceActions.getSingle({
      id: this.props.match.params.id,
    });

    let vehicles = this.props.groupsAndVehicles.vehicles;
    let groups = this.props.groupsAndVehicles.groups;
    let selectedVehicles = this.props.geoFence.vehicles;
    if (this.props.geoFence?.geoFenceType.toUpperCase() === "POLYGON") {
      this.setState({ 
        polygon: true, 
        path: this.props.geoFence?.polygonPath });
    }
    if (vehicles.length > 0) {
      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }

          groups[j] = groupItem;
        }
      };
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (_.indexOf(selectedVehicles, item._id) !== -1) {
          item.checked = true;
          vehicles[i] = item;
          const groupVehicle = _.find(vehicles, (veh) => {
            return veh.group === item.group && !veh.checked;
          });
          if (!groupVehicle) {
            selectGroup(item, true);
          }
        }
      }
    }
 
    this.setState({
      ...this.state,
      vehicles,
      groups,
      name: this.props.geoFence.name,
      description: this.props.geoFence.description,
      center: this.props.geoFence.geoFenceType.toUpperCase() === "POLYGON" ? [this.props.geoFence?.polygonPath[0].lng, this.props.geoFence?.polygonPath[0].lat] : this.props.geoFence.center,
      radius: this.props.geoFence.radius,
      overSpeedingThreshold: this.props.geoFence.speed,
      geoFenceType: this.props.geoFence?.geoFenceType.toUpperCase(),
      alertList: [
        {
          id: 1,
          mainTitle: "Entry",
          checked: this.props.geoFence.entryEnabled,
        },
        {
          id: 2,
          mainTitle: "Exit",
          checked: this.props.geoFence.exitEnabled,
        },
      ],
      speedList: [
        {
          id: 1,
          mainTitle: "Speed",
          checked: this.props.geoFence.speedEnabled,
        },
      ],
      selectedVehicles: this.props.geoFence.vehicles,
      loadingData: false,
    });
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      errors: { ...this.state.errors, [e.target.name]: "" },
    });
  }
  searchVehicles = (e) => {
    const value = e.target.value;
    let vehicles = [];

    let selectedVehicles = this.state.selectedVehicles;
    if (value) {
      _.forEach(this.props.groupsAndVehicles.vehicles, (item) => {
        if (item.plateNumber.search(new RegExp(value, "i")) >= 0) {
          let index = selectedVehicles.findIndex((obj) => {
            return obj === item._id;
          });

          vehicles.push({
            _id: item._id,
            plateNumber: item.plateNumber || "N/A",
            checked: index > -1 ? true : false,
            vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A",
          });
        }
      });
    } else {
      vehicles = _.map(this.props.groupsAndVehicles.vehicles, (item) => {
        let index = selectedVehicles.findIndex((obj) => {
          return obj === item._id;
        });
        return {
          _id: item._id,
          plateNumber: item.plateNumber || "N/A",
          checked: index > -1 ? true : false,
          vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A",
        };
      });
    }
    this.setState({
      ...this.state,
      vehicles,
    });
  };
  checkBoxListChanged = (value) => {
    let vehicles = this.state.vehicles;
    let groups = this.state.groups;
    let selectedVehicles = this.state.selectedVehicles;
    let unselectedVehicles = this.state.unselectedVehicles;

    if (vehicles.length > 0) {
      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groups[j] = groupItem;
        }
      };

      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          vehicles[i] = item;
          if (item.checked) {
            selectedVehicles.push(item._id);
            const groupVehicle = _.find(vehicles, (veh) => {
              return veh.group === item.group && !veh.checked;
            });
            if (!groupVehicle) {
              selectGroup(item, true);
            }
            let index = unselectedVehicles.findIndex((obj) => {
              return obj === item._id;
            });

            if (index > -1) {
              unselectedVehicles.splice(index, 1);
            }
          } else {
            selectGroup(item, false);

            let index = selectedVehicles.findIndex((obj) => {
              return obj === item._id;
            });
            unselectedVehicles.push(item._id);
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }
      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groups,
      selectedVehicles,
      unselectedVehicles,
    });
  };

  checkGroupChanged = (value) => {
    let groups = this.state.groups;
    let vehicles = this.state.vehicles;
    let selectedVehicles = this.state.selectedVehicles;
    let unselectedVehicles = this.state.unselectedVehicles;

    let groupSelected;

    for (let i = 0; i < groups.length; i++) {
      let item = groups[i];
      if (i === parseInt(value, 10)) {
        item.checked = !item.checked;
        groupSelected = item;
      }
      groups[i] = item;
    }
    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (groupSelected._id === item.group) {
          item.checked = groupSelected.checked;
          if (item.checked) {
            selectedVehicles.push(item._id);
          } else {
            let index = selectedVehicles.findIndex((obj) => {
              return obj === item._id;
            });
            unselectedVehicles.push(item._id);
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }
        vehicles[i] = item;
      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groups,
      selectedVehicles,
      unselectedVehicles,
    });
  };
  isTabActive = (name) => {
    return name === this.state.activeTab;
  };
  handleTabClick = (name) => {
    this.setState({
      ...this.state,
      activeTab: name,
    });
  };

  checkBoxAlertChanged = (value) => {
    let alertList = this.state.alertList;

    if (alertList.length > 0) {
      for (let i = 0; i < alertList.length; i++) {
        let item = alertList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
        }
        alertList[i] = item;
      }
      this.setState({
        ...this.state,
        alertList,
      });
    }
  };
  speedCheckBoxAlertChanged = (value) => {
    let { speedList } = this.state;

    if (speedList.length > 0) {
      for (let i = 0; i < speedList.length; i++) {
        let item = speedList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
        }
        speedList[i] = item;
      }
      this.setState({
        ...this.state,
        speedList,
      });
    }
  };
  onMapChange = (circle) => {
    let radius = circle.getRadius();
    radius = Math.round(radius);

    this.setState({
      ...this.state,
      center: [circle.getCenter().lng(), circle.getCenter().lat()],
      radius,
    });
    if (radius < 300) {
      this.setState({
        radius: 300,
      });
    }
    return {
      center: [circle.getCenter().lng(), circle.getCenter().lat()],
      radius,
    };
  };
  changeRadius = (event) => {
    let value = "";
    if (event.target.value) {
      value = parseInt(event.target.value, 10);
    } else {
    }
    this.setState({ radius: value });
  };

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };
  onSubmit = async (e) => {
   
    
    e.preventDefault();
    if (this.isValid()) {
      try {
        const state = this.state;
        this.setState({
          ...this.state,
          loader: true,
        });
        let geofenceObj = {
          name: _.trim(state.name),
          description: _.trim(state.description),
          vehicles: state.selectedVehicles,
          type: this.props.geoFence?.geoFenceType.toUpperCase(),
          path:
            this.props.geoFence?.geoFenceType.toUpperCase() !== "CIRCLE"
              ? state.path
              : [],
          alerts: {
            ENTRY: state.alertList[0].checked,
            EXIT: state.alertList[1].checked,
            SPEED: state.speedList[0].checked,
          },
          center: state.center,
          radius: state.radius,
          speed: state.overSpeedingThreshold,
          unselectedVehicles: state.unselectedVehicles,
        };

        await this.props.geoFenceActions.GeoFence({
          geofenceObj,
          id: this.props.match.params.id,
        });
        toastr.success("Geofence Updated Successfully!");
        this.setState({
          ...this.state,
          loader: false,
        });
        this.props.history.push(`/geofence/list`);
      } catch (err) {
        this.setState({
          ...this.state,
          loader: false,
        });
        toastr.error(err.response.data.message);
      }
    }
    else {
    }
  };
  changeOverspeedingThreshold = async (value) => {
    this.setState({ overSpeedingThreshold: value });
  };

  render() {
    const {
      speedList,
      name,
      description,
      overSpeedingThreshold,
      loadingData,
    } = this.state;

    const [speed] = speedList;
    const { checked: isSpeedEnabled } = speed;
    const loader = <WhiteSpinner key="1" />;
    const loader1 = <Spinner key="2" />;

    return (
      <div>
        {loadingData ? (
          [loader1]
        ) : (
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <TextFieldGroup
                  onChange={this.onChange.bind(this)}
                  value={name}
                  label={"Geofence Name*"}
                  type={"text"}
                  field={"name"}
                  error={this.state.errors.name}
                  placeholder={"E.g Warehouse, school"}
                />
                <TextAreaGroup
                  onChange={this.onChange.bind(this)}
                  value={description}
                  label={"Description"}
                  field={"description"}
                  error={this.state.errors.description}
                  placeholder={"Extra details about geofence"}
                />
                <div className="col padding0">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className={"nav-link active"}
                        onClick={() => this.handleTabClick("vehicles")}
                      >
                        Vehicles
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={"nav-link active"}
                        onClick={() => this.handleTabClick("groups")}
                      >
                        Groups
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content access-tab mb-5 heading-200">
                    <div
                      className={classnames("tab-pane fade", {
                        "show active": this.isTabActive("vehicles"),
                      })}
                    >
                      <CheckboxListComponent
                        enableSearch="true"
                        search={this.searchVehicles.bind(this)}
                        onChange={this.checkBoxListChanged}
                        data={
                          this.state.vehicles &&
                          this.state.vehicles.map((item) => ({
                            mainTitle: item.plateNumber || "N/A",
                            subTitle: item.vehicleMakeAndModel || "N/A",
                            checked: item.checked,
                          }))
                        }
                      />
                    </div>
                    <div
                      className={classnames("tab-pane fade", {
                        "show active": this.isTabActive("groups"),
                      })}
                    >
                      <CheckboxListComponent
                        onChange={this.checkGroupChanged}
                        data={
                          this.state.groups &&
                          this.state.groups.map((item) => ({
                            mainTitle: item.name || "N/A",
                            checked: item.checked,
                          }))
                        }
                      />
                    </div>
                    {this.state.errors && (
                      <span className="text-danger">
                        {this.state.errors.vehicles}
                      </span>
                    )}
                  </div>
                  <div className={"form-group"}>
                    <label>Geofence Type</label>
                    <p>{this.props.geoFence?.geoFenceType}</p>
                  </div>
                  {this.state.errors && (
                    <span className="text-danger">
                      {this.state.errors.path}
                    </span>
                  )}
                  <div className={"form-group"}>
                   {this.props.geoFence.geoFenceType.toUpperCase() !== "POLYGON" &&
                    <TextFieldGroup
                      onChange={this.changeRadius.bind(this)}
                      value={this.state.radius}
                      label={"Radius"}
                      type={"text"}
                      field={"name"}
                      error={this.state.errors.radius}
                    />}
                    <label>Enable Speed</label>
                    <CheckboxInlineComponent
                      onChange={this.speedCheckBoxAlertChanged}
                      selectAllEnabled={false}
                      data={this.state.speedList.map((item) => ({
                        id: item.id,
                        mainTitle: item.mainTitle,
                        checked: item.checked,
                      }))}
                    />{" "}
                    {this.state.errors && (
                      <span className="text-danger">
                        {this.state.errors.type}
                      </span>
                    )}
                  </div>
                  {isSpeedEnabled && (
                    <div className={"form-group"}>
                      <p>{overSpeedingThreshold}</p>
                      <Slider
                        min={0}
                        max={200}
                        defaultValue={overSpeedingThreshold}
                        handle={HandleSlider}
                        onChange={this.changeOverspeedingThreshold}
                      />
                    </div>
                  )}
                  <div className={"form-group"}>
                    <label>Alerts On</label>
                    <CheckboxInlineComponent
                      onChange={this.checkBoxAlertChanged}
                      selectAllEnabled={false}
                      data={this.state.alertList.map((item) => ({
                        id: item.id,
                        mainTitle: item.mainTitle,
                        checked: item.checked,
                      }))}
                    />{" "}
                    {this.state.errors && (
                      <span className="text-danger">
                        {this.state.errors.type}
                      </span>
                    )}
                  </div>

                  <div className="margin-btm-50">
                    <button
                      className="btn auxo-primary-btn create-geofence-btn"
                      type="submit"
                    >
                      {this.state.loader ? [loader] : "Update"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8">
                <GeoFenceMap
                  center={{
                    lat: this.state.center[1],
                    lng: this.state.center[0],
                  }}
                  radius={this.state.radius || 300}
                  geoFences={this.state.geoFences}
                  onMapChange={this.onMapChange}
                  polygon={this.state.polygon}
                  path={(path) => this.setState({ path })}
                  paths={this.state.path}
                />
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

UpdateGeoFence.propTypes = {};
UpdateGeoFence.defaultProps = {};
const mapStateToProps = (state) => ({
  groupsAndVehicles: state.groups.groupsAndVehicles,
  geoFence: state.geoFence.geoFence,
  geoFences: state.geoFence.geoFences,
});

const mapDispatchToProps = (dispatch) => ({
  geoFenceActions: bindActionCreators(GeoFence.creators, dispatch),
  actions: bindActionCreators(Groups.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateGeoFence);
