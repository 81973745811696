import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import * as moment from "moment";
import toastr from "toastr";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Reports from '../../../ducks/Reports';
import Things from "../../../ducks/Things";
import Select from 'react-select-nested-group';
import DateTime from "react-datetime";
import ToggleButton from "react-toggle-button";
import forEach from 'lodash/forEach';
import GeoFence from '../../../ducks/GeoFence';
import MapView from '../mapView/index';
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import './index.css';
import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';
import 'react-datepicker/dist/react-datepicker.css';
import TextFieldGroup from "../../common/TextFieldGroup";

const colors = {
  activeThumb: {
    base: "#fff",
  },
  inactiveThumb: {
    base: "#fff",
  },
  active: {
    base: "#03ABCE",
    hover: "#03ABCE",
  },
  inactive: {
    base: "rgb(65,66,68)",
    hover: "rgb(95,96,98)",
  },
};
class GeofenceSummaryReport extends Component {

  constructor() {
    super();

    this.state = {
      selectdRow:{},
      selectedGeofences: [{value: 'ALL', label: 'Select all'}],
      mapVisible:false,
      isLoadingRecords: true,
      driverToBeDeleted: true,
      selectAllVehicles:true,
      selectAllGeofences:true,
      notResponding:false,
      orderBy: "asc",
      scheduleVisible: false,
      savingSingleRecord: [],
      selectedVehicles: [{value: 'ALL', label: 'Select all'}],
      vehicles: [],
      selectedStartDate: moment(),
      selectedEndDate: moment(),
      selectedGeoFenceObject:{},
      geoFenceSelected: false,
      tablevisible:false,
      driverName: "",
      driverId: "",
      sortType: "",
      driverVehicles: [],
      page: 1,
      data: [],
      child: {},
      sizePerPage: 10,
      isCompleted: false,
      slipNumber : "",
      columns : [
        {
          dataField: 'plateNumber',
          text: 'PlateNumber'
        },
        {
          dataField: 'imei',
          text: 'Imei'
        },
        {
          dataField: 'Slip_No',
          text: 'Slip No',
          headerStyle: (colum, colIndex) => {
            return { minWidth: '100px' };
          }
        },
        {
          dataField: 'origin',
          text: 'Origin'
        },
        {
          dataField: 'destination',
          text: 'Destination'
        },
        {
          dataField: 'group',
          text: 'Group'
        },
        {
          dataField: 'tripStatus',
          text: 'TriP Status'
        },
        {
          dataField: 'oneWayStartTime',
          text: 'One Way StartTime'
        },
        {
          dataField : 'oneWayEndTime',
          text : 'One Way EndTime',
          headerStyle: (colum, colIndex) => {
            return { minWidth: '100px' };
          }
        },
        {
          dataField : 'oneWayDistance',
          text : 'One Way Distance',
          headerStyle: (colum, colIndex) => {
            return { minWidth: '100px' };
          }
        },
        {
          dataField : 'oneWayStatus',
          text : 'One Way Status',
          headerStyle: (colum, colIndex) => {
            return { minWidth: '100px' };
          }
        },
        {
          dataField : 'oneWayEventTime',
          text : 'One Way EventTime'
        },
        {
          dataField: 'returnStartTime',
          text: 'Return StartTime'
        },
        {
          dataField : 'returnEndTime',
          text : 'Return EndTime'
        },
        {
          dataField : 'returnDistance',
          text : 'Return Distance'
        },
        {
          dataField : 'returnStatus',
          text : 'Return Status'
        },
        {
          dataField : 'returnEventTime',
          text : 'Return EventTime'
        },
        {
          dataField : 'totalEventDistance',
          text : 'Total Event Distance'
        },
        {
          dataField : 'totalEventTime',
          text : 'Total Event Time'
        },
        {
          dataField : 'Map',
          text : 'Map',
          formatter: this.mapView,
        }
      ]
    };
  };

  mapView = (cell, row, rowIndex, formatExtraData) => {

      return (
        <button className="btn reopen-btn" onClick={() => { this.onUpdate(row); }} >
          View
        </button>
      );
  };

 async componentDidMount () {
    this.setState({ ...this.state,isLoadingRecords: false});

    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    await this
        .props
        .thingsActions
        .getGroupVehicles({groupId: companyInfo.groupId});

    await this
    .props
    .geofenceActions
    .getGroupGeoFences2();

    this.setState({
      ...this.state,
      vehicles: this.props.vehicles
    });
  }
  async onSlipChange(item) {
    await this.setState({...this.state, slipNumber: item.target.value});
  }

  async onUpdate(row) {
    await this.setState({
      ...this.state,
      selectdRow: row,
      mapVisible: true
    });
    this.child.loadData(row)
  }

  async searchServerComplaint() {

    if (!this.state.selectedVehicles.length) {
      toastr.error("Please Select Vehicle");
      return;
    }
    if (!this.state.selectedGeofences.length) {
      toastr.error("Please Select Geofence");
      return;
    }
    this.setState({ ...this.state,isLoadingRecords: true});

    let summaryState = this.props.summaryState

    if (Object.keys(summaryState).length == 0) {
      let selectedVehicles = [];
      if (this.state.selectAllVehicles) {
        selectedVehicles = this.state.vehicles.map(item => {
          return item._id;
        });
      } else {
        selectedVehicles = this.state.selectedVehicles.map(item => {
          return item.value;
        });
      }
      
      let selectedGeofences = [];
      if (this.state.selectAllGeofences) {
        selectedGeofences = this.props.geoFencesWpaging.map(item => {
          return item._id
        });
      } else {
        selectedGeofences = this.state.selectedGeofences.map(item => {
          return item.value;
        });
      }
  
      let data = {
        vehicles : selectedVehicles,
        interval : {
          "startDate": new Date(this.state.selectedStartDate).toISOString(),
          "endDate":  new Date(this.state.selectedEndDate).toISOString(),
          "startTime":  new Date(this.state.selectedStartDate).toISOString(),
          "endTime":  new Date(this.state.selectedEndDate).toISOString(),
          "key": "selection"
        },
        geofences: selectedGeofences,
        "reportType": "geofenceSummaryReport",
        sizePerPage : 10,
        page :0
      };

      if (this.state.selectAllGeofences) {
        data['geofenceAllSelection'] = true;
      }

      summaryState = data;
    };

    await this.props.reportsActions.exportReport(summaryState);
    this.setState({...this.state,isLoadingRecords: false});
    toastr.success("Report created and Can view in View Reports");
  }

  async searchDataForReport() {

    let selectedVehicles = [];
    if (!this.state.selectedVehicles.length && this.state.slipNumber === '') {
      toastr.error("Please Select Vehicle");
      return;
    }
    if (!this.state.selectedGeofences.length && this.state.slipNumber === '') {
      toastr.error("Please Select Geofence");
      return;
    }
    if (this.state.selectAllVehicles) {
      selectedVehicles = this.state.vehicles.map(item => {
        return item._id;
      });
    } else {
      selectedVehicles = this.state.selectedVehicles.map(item => {
        return item.value;
      });
    }
    
    let selectedGeofences = [];
    if (this.state.selectAllGeofences) {
      selectedGeofences = this.props.geoFencesWpaging.map(item => {
        return item._id
      });
    } else {
      selectedGeofences = this.state.selectedGeofences.map(item => {
        return item.value;
      });
    }

    let data = {
      vehicles : selectedVehicles,
      slip : this.state.slipNumber,
      interval : {
        "startDate": new Date(this.state.selectedStartDate).toISOString(),
        "endDate":  new Date(this.state.selectedEndDate).toISOString(),
        "startTime":  new Date(this.state.selectedStartDate).toISOString(),
        "endTime":  new Date(this.state.selectedEndDate).toISOString(),
        "key": "selection"
      },
      geofences: selectedGeofences,
      "reportType": "geofenceSummaryReport",
      sizePerPage : 10,
      page :0
    };
    if (this.state.selectAllGeofences) {
      data['geofenceAllSelection'] = true;
    }
    this.state.isCompleted == true && (data['concluded'] = true);

    this.setState({ ...this.state,isLoadingRecords: true,notResponding: false});
    await this.props.reportsActions.reportsView(data);
    this.setState({...this.state, isLoadingRecords: false,page:1,tablevisible : true});
  }

  handleTableChange = async (type, { page, sizePerPage }) => {
    let data = this.props.summaryState;
    data['sizePerPage'] = sizePerPage;
    data['page'] = page -1;
    this.setState({ ...this.state,isLoadingRecords: true,notResponding: false});
    await this.props.reportsActions.reportsView(data);
    this.setState({...this.state, page , isLoadingRecords: false});
  }

  async onclickIsCompleted(value){
    if (!value){ 
    }
    this.setState({ ...this.state, isCompleted: !value });

  }

closePopup() {
  this.setState({
    ...this.state,
    mapVisible: false
  });
}

// selectedGeofences = (value) => {

//   let selectAll = false;
//   forEach(value, item => {
//     if (item.value === 'ALL') {
//       selectAll = true;
//     }
//   });

//   if (selectAll) {
//     this.setState({
//       ...this.state,
//       selectAllGeofences:selectAll,
//       selectedGeofences : [{value: 'ALL', label: 'Select all'}]
//     });
//   } else {
//     if (value && value.length){
//       value = value;
//     } else {
//       value = [];
//     }
//     this.setState({
//       ...this.state,
//       selectAllGeofences:selectAll,
//       selectedGeofences: value,
//     });
//   }
// }

// searchVehicles = (value) => {
//   let selectAll = false;
//   forEach(value, item => {
//     if (item.value === 'ALL') {
//       selectAll = true;
//     }
//   });

//   if (selectAll) {
//     this.setState({
//       ...this.state,
//       selectAllVehicles : selectAll,
//       selectedVehicles : [{value: 'ALL', label: 'Select all'}]
//     });
//   } else {
//     if (value && value.length){
//       value = value;
//     } else {
//       value = [];
//     }
//     this.setState({
//       ...this.state,
//       selectAllVehicles : selectAll,
//       selectedVehicles: value,
//     });
//   }

// }

selectedGeofences = (value) => {
  let selectAll = true;
  let selectedValue = null;

  forEach(value, item => {
    if (item.value === 'ALL') {
      selectAll = false;
    } else if (selectedValue === null) {
      selectedValue = item;
    }
  });

  if (!selectAll && selectedValue !== null) {
    this.setState({
      ...this.state,
      selectAllGeofences: false,
      selectedGeofences: [selectedValue],
    });
  } else {
    this.setState({
      ...this.state,
      selectAllGeofences: true,
      selectedGeofences: [{ value: 'ALL', label: 'Select all' }],
    });
  }
}


searchVehicles = (value) => {
  let selectAll = true;
  let selectedValue = null;
  
  forEach(value, item => {
    if (item.value === 'ALL') {
      selectAll = false;
    } else if (selectedValue === null) {
      selectedValue = item;
    }
  });

  if (!selectAll && selectedValue !== null) {
    this.setState({
      ...this.state,
      selectAllVehicles: false,
      selectedVehicles: [selectedValue],
    });
  } else {
    this.setState({
      ...this.state,
      selectAllVehicles: true,
      selectedVehicles: [{ value: 'ALL', label: 'Select all' }],
    });
  }
}


filterNotResponding = () => {
  this.setState({
    ...this.state,
    notResponding: !this.state.notResponding
  });
}
filterCompleted = () => {
  this.setState({
    ...this.state,
    isCompleted: !this.state.isCompleted
  });
}

setSelectedStartDate = (value) => {
  this.setState({
    ...this.state,
    selectedStartDate: value,
  });
}

setSelectedEndDate = (value) => {
  this.setState({
    ...this.state,
    selectedEndDate: value,
  });
}

  render() {

    const isLoadingRecords = this.state.isLoadingRecords;
    const { data, sizePerPage, page } = this.state;
    const loader = <Spinner key="1" />;
    const smallLoader = <BlueSmallSpinner key="2" />;

    let dataToDisplay = this.props.geofenceSummary;
    

    if (this.state.notResponding) {
     
      dataToDisplay = dataToDisplay.filter((item) => item.tripStatus != "Not Responding");
    }

    //console.log("dataToDisplay", dataToDisplay);
    return (
      <>
      <MapView
          tripId={this.state.tripId}
          mapVisible={this.state.mapVisible}
          onRef={ref => (this.child = ref)}
          closePopup={this.closePopup.bind(this)}
        />
        <div className='my-complaintscontainer'>
        <div style={{ textAlign: 'center' }}>
          <label>
            <b>Select Filters OR Search by Slip Number</b>
            <br></br>
          </label>
        </div>
          <div className='searchDiv'>
            <div className='tableRow'>
              <div className="col-sm-2" >
                <label>GeoFences</label>
                <Select
                    isDisabled={this.state.slipNumber.length>0}
                    name='form-field-name'
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    placeholder='Select Geofence'
                    onChange={this.selectedGeofences}
                    value={this.state.selectedGeofences}
                    options={[{
                      value: 'ALL',
                      label: 'Select all'
                    }, ...this.props.geoFencesWpaging.map(item => {
                      return {
                        value: item._id,
                        label: item.name
                      }
                    })]}
                  /> 
              </div> 
              <div className="col-sm-2">
                <label>Vehicles</label>
                <Select
                    isDisabled={this.state.slipNumber.length>0}
                    name='form-field-name'
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    placeholder='Select Vehicles'
                    onChange={this.searchVehicles}
                    value={this.state.selectedVehicles}
                    options={[{
                      value: 'ALL',
                      label: 'Select all'
                    }, ...this.state.vehicles.map(item => {
                      return {
                        value: item._id,
                        label: item.plateNumber
                      }
                    })]}
                    /> 
              </div>
              
              <div className="col-md-2">
                <label>Start Date and Time</label>
                  <DateTime
                    inputProps={{
                      disabled: this.state.slipNumber.length > 0,
                      
                    }}
                    
                    value={this.state.selectedStartDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={this.setSelectedStartDate}
                  />
              </div>
              <div className="col-md-2">
                <label>End Date and Time</label>
                  <DateTime
                    inputProps={{
                      disabled: this.state.slipNumber.length > 0,
                     
                    }}
                    
                    value={this.state.selectedEndDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={this.setSelectedEndDate}
                  />
              </div>
              <div className="col-sm-2" >
                <label>Slip Number</label>
                  <TextFieldGroup
                      field={"tsearch"}
                      placeholder={"Slip Number"}
                      value={this.state.slipNumber}
                      type={"text"}
                      onChange={this.onSlipChange.bind(this)}
                    />
              </div>
              <div className="col-sm-2">
                <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.searchDataForReport.bind(this)}>Search</button>
              </div>
            </div>
            <div className='tableRow'>
              <div className='col-sm-3'>
              </div>
              <div className='col-sm-3'>
              </div>
                
              <div className="col-md-2">
                {/* <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.filterCompleted.bind(this)}>
                  {this.state.isCompleted ? "Show All" : "Show Completed"}
                </button> */}
                <label>Completed Trips</label>
                  <ToggleButton
                    style={{height:'30px'}}
                    colors={colors}
                    value={this.state.isCompleted || false}
                    onToggle={this.filterCompleted.bind(this)}
                  />
              </div> 
              <div className="col-md-2">
                <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.filterNotResponding.bind(this)}>
                  {this.state.notResponding ? "Display NotResponding" : "Hide NotResponding"}
                </button>
              </div>
              <div className="col-md-2">
                <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.searchServerComplaint.bind(this)}>Export</button>
              </div>
            </div>
          </div>
        <div>

          {
            isLoadingRecords ? loader : 
            this.state.tablevisible &&
            dataToDisplay.length ?  
            (<PaginationProvider
              pagination={
                paginationFactory({
                  custom: true,
                  page : this.state.page,
                  sizePerPage : this.props.sizePerPage,
                  totalSize : this.props.eventsCount
                })
              }
            >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                <div>
                  <BootstrapTable
                    remote
                    keyField="id"
                    data={dataToDisplay}
                    columns={ this.state.columns }
                    onTableChange={ this.handleTableChange }
                    { ...paginationTableProps }
                  />
                                <div>
                    <PaginationListStandalone
                      { ...paginationProps }
                    />
                  </div>
                </div>
              )
            }
          </PaginationProvider>) : <div>There is no Data To Display</div>
          }
        </div>
      </div>
      </>
    );
  }
}

GeofenceSummaryReport.propTypes = {};
GeofenceSummaryReport.defaultProps = {};
const mapStateToProps = state => (
  { 
    isLoading: state.complaints.isLoading,
    geoFencesWpaging: state.geoFence.geoFencesWpaging,
    geofenceSummary : state.Reports.geofenceSummary,
    eventsCount : state.Reports.eventsCount,
    sizePerPage : state.Reports.sizePerPage,
    page : state.Reports.page,
    summaryState : state.Reports.summaryState,
    vehicles: state.things.groupVehicles,
  }
);

const mapDispatchToProps = dispatch => ({
  reportsActions: bindActionCreators(Reports.creators, dispatch),
  geofenceActions: bindActionCreators(GeoFence.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeofenceSummaryReport);
