import React, { Component } from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import classnames from 'classnames';
import './UserCreate.css';
import CheckboxListComponent from '../common/CheckboxListComponent';
import { connect } from 'react-redux';
import Roles from '../../ducks/Roles';
import Groups from '../../ducks/Groups';
import { bindActionCreators } from 'redux';
import { validateInput } from '../../validations/userCreate';
import toastr from 'toastr';
import Users from '../../ducks/Users';
import * as _ from 'lodash';
import { WhiteSpinner, Spinner } from '../common/Spinner/index';
import SelectComponent from '../select';

class UserCreate extends Component {
  constructor(props) {
    const groupData = JSON.parse(localStorage.getItem('groupInfo'))
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      phone: '',
      type: 'Manager',
      activeTab: 'roles',
      errors: {},
      selectedGroup: groupData.depthLevel !== 6 ? [] : [{value: this.props.match.params.id}],
      roleList: [],
      role: '',
      groupDepthLevel: groupData.depthLevel,
      loader: false,
      companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
      isLoading: true
    }
  }

  async componentDidMount() {
    await this
      .props
      .roleActions
      .getRoles();
    let roles = _.filter(this.props.roles, (item) => {
      if (item.type && item.type === 'MANAGER') {
        return { _id: item._id, name: item.name, checked: false };
      }
    });

    if (!(this.props.groupList && this.props.groupList.length))
      await this.props.dispatch(Groups.creators.getSubGroups(this.state.companyInfo.groupId));

    this.setState({
      ...this.state,
      roleList: roles,
      isLoading: false
    });
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: ''
      }
    });
  };
  isTabActive = name => {
    return name === this.state.activeTab;
  };
  handleTabClick = name => {
    // this.setState({activeTab: name})
  };
  checkBoxListChanged = (value) => {
    let roleList = this.state.roleList;
    let role = '';
    if (roleList.length > 0) {
      for (let i = 0; i < roleList.length; i++) {
        let item = roleList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) {
            role = item._id;
          }
        } else {
          item.checked = false;
        }

        roleList[i] = {
          _id: item._id,
          name: item.name,
          checked: item.checked
        };

        this.setState({
          ...this.state,
          roleList,
          role: role.toString()
        });
      }
    }
  };


  onSubmit = async e => {

    e.preventDefault();
    if (this.isValid()) {
      try {
        const userInfo = {
          firstName: _.trim(this.state.firstName),
          lastName: _.trim(this.state.lastName),
          userName: _.trim(this.state.userName),
          email: _.trim(this.state.email),
          phone: _.replace(this.state.phone, '+92', '0'),
          type: this.state.type,
          roleId: this.state.role,
          userType: this.state.type,
          groupId: this.state.selectedGroup[0].value
        };

        if (userInfo.phone.slice(0, 2) === '92') {
          userInfo.phone = userInfo.phone.replace('92', '0')
        };
        if (userInfo.phone.length > 11) {
          return toastr.error('Invalid phone number');
        }
        if (userInfo.userType === '' || !userInfo.userType) {
          return toastr.error('Please select user type');
        }
        if (userInfo.userType === 'Manager' && !userInfo.groupId) {
          return toastr.error('Please select company and group');
        }
        this.setState({
          ...this.state,
          loader: true
        })
        await this
          .props
          .actions
          .createUser(userInfo);
        toastr.success('User created successfully!');
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push('/users');
      } catch (e) {
        this.setState({
          ...this.state,
          loader: false
        })
        toastr.error(e.response ? e.response.data.message : e);
      }
    }
  };

  handleGroupChange = (selectedOption) => {
    this.setState({
      ...this.state,
      selectedGroup: selectedOption
    });

    console.log(" selectedGroup _ ", selectedOption)
  }

  render() {
    const {
      firstName,
      lastName,
      userName,
      email,
      phone
    } = this.state;
    const loader = <WhiteSpinner key='1'/>
    const loaderRoles = <Spinner key='1'/>;

    return (
      <>
      {this.state.isLoading ? [loaderRoles] :
      <div>
        <div className='heading-container'>
          <h3 className='font-weight-bold'>New User</h3>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className='row'>
            <div className='col'>
              <TextFieldGroup
                onChange={this.onChange}
                value={firstName}
                type={'text'}
                field={'firstName'}
                label={'First Name'}
                placeholder={'First name of the user'}
                error={this.state.errors.firstName}/>
              <TextFieldGroup
                onChange={this.onChange}
                value={lastName}
                type={'text'}
                field={'lastName'}
                label={'Last Name'}
                placeholder={'Last name of the user'}
                error={this.state.errors.lastName}/>

              <TextFieldGroup
                onChange={this.onChange}
                value={userName}
                type={'text'}
                field={'userName'}
                label={'User Name'}
                placeholder={'User Name'}
                error={this.state.errors.userName}/>

              <TextFieldGroup
                onChange={this.onChange}
                value={email}
                type={'email'}
                field={'email'}
                label={'Email'}
                placeholder={'Email address of the user'}
                error={this.state.errors.email}/>
              <TextFieldGroup
                onChange={this.onChange}
                value={phone}
                type='number'
                field={'phone'}
                label={'Phone'}
                pattern={'[0-9]{10}'}
                placeholder={'03451234567'}
                error={this.state.errors.phone}/>

              {
                this.state.groupDepthLevel !== 6 ?
                  <div className={classnames('form-group')}>
                      <SelectComponent
                        label='Select Group'
                        field='form-field-name'
                        placeholder='Select Group'
                        preSelected={this.state.selectedGroup}
                        optionKeys={{label: 'name', value: '_id'}}
                        options={this.props.groups}
                        error={this.state.errors.selectedGroup}
                        onChange={this.handleGroupChange}
                      />
                  </div> : ''
              }

            </div>
            <div className='col'>
              <label>Access</label>
              <ul className='nav nav-tabs' role='tablist'>
                <li className='nav-item'>
                  <a
                    className={classnames('nav-link', {
                      'active': this.isTabActive('roles')
                    })}
                    onClick={() => this.handleTabClick('roles')}>Roles</a>
                </li>

              </ul>
              <div className='tab-content access-tab'>
                <div
                  className={classnames('tab-pane fade', {
                    'show active': this.isTabActive('roles')
                  })}>
                  <CheckboxListComponent
                    onChange={this.checkBoxListChanged}
                    selectAllEnabled={false}
                    data={this
                      .state
                      .roleList
                      .map(item => ({
                        id: item._id,
                        mainTitle: item.name,
                        checked: item.checked
                      }))}/> {this.state.errors && <span className='text-danger'>{this.state.errors.role}</span>}
                </div>

              </div>

            </div>

          </div>
          <button className='btn auxo-primary-btn'>{this.state.loader ? [loader] : 'Create User'}</button>
        </form>
      </div>
      }</>
    );
  }
}

UserCreate.propTypes = {};
UserCreate.defaultProps = {};
const mapStateToProps = state => ({ roles: state.roles.list, groups: state.groups.subGroups });
const mapDispatchToProps = dispatch => ({
  roleActions: bindActionCreators(Roles.creators, dispatch),
  actions: bindActionCreators(Users.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
