import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as _ from 'lodash';
import Modal from 'react-bootstrap4-modal';
import { Spinner, WhiteSpinner } from '../../common/Spinner/index';
import TextFieldGroup from '../../common/TextFieldGroup';
import searchIcon from '../../../assets/img/ic_search.png';
import editIcon from '../../../assets/img/ic_edit.png';
import trash_bin from '../../../assets/img/trash_bin.png';
import Patrolling from '../../../ducks/Patrolling';
import { URL } from '../../../constants';

const CellSiteListing = (props) => {

  const [cellSites, setCellSites] = useState([]);
  const [returnedCellSites, setReturnedCellSites] = useState([]);
  const [selected, setSelected] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletingRecord, setDeletingRecord] = useState(false);
  const [search, setSearch] = useState('');

  const [isLoading, setIsLoading] = useState(true);


  async function LoadData() {
    setIsLoading(true);
    const cellSites = await props.actions.listCellSites();
    setReturnedCellSites(cellSites);
    setCellSites(cellSites);
    setIsLoading(false);
  }
  useEffect(() => {
    LoadData();
  }, [])


  const onChange = (event) => {
    const value = event.target.value;
    if (value) { setSearch(value || '') } else {
      setSearch(value || '')
      setCellSites(returnedCellSites);
    }
  }
  const navigateToCreateCellSite = () => {
    props
      .history
      .push('/patrolling/cellsite/create');
  }

  const update = (item) => {
    props
      .history
      .push(`/patrolling/cellsite/${item._id}/update`);

  }
  const showDeleteConfirmationModel = (item) => {
    setSelected(item);
    setShowDeleteConfirmation(true);
  };

  const deleteCellSite = async () => {
    if (!deletingRecord) {
      setDeletingRecord(true);
      await props.actions.deleteCellSite({ id: selected._id });

      toastr.success("CellSite deleted successfully!");

      const list = _.filter(cellSites, item => {
        return item._id !== selected._id;
      });
      const list1 = _.filter(returnedCellSites, item => {
        return item._id !== selected._id;
      });
      setReturnedCellSites(list1);
      setCellSites(list);
      setDeletingRecord(false);
      setShowDeleteConfirmation(false);
    }
  }

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  }


  const searchCellSite = (e) => {
    e.preventDefault();
    const value = search;
    if (value) {
      let list = _.filter(cellSites, (item) => {
        if (item.name.search(new RegExp(value, 'i')) >= 0) {
          return item;
        }
      });
      setSearch(value || '')
      setCellSites(list);

    } else {
      setSearch(value || '')
      setCellSites(returnedCellSites);
    }
  }
  const handleFileUpload = async (event) =>{

    try {
      const sheet = event.target.files[0];
      await props.actions.bulkCellSites(sheet);
      toastr.success('Cellsites will be uploaded in a while')

    } catch (e) {
      toastr.error(e);
    }

  }
  // openGeofence(id) {
  //   let geoFences = _.map(this.state.geoFences, item => {
  //     if (item._id === id) {
  //       item.isSelected = !item.isSelected;
  //     }
  //     return item;
  //   });

  //   this.setState({
  //     ...this.state,
  //     geoFences
  //   });
  // }
  const notFound = (
    <div className='no-data-found' key='1'>
      <h2>No Cell Sites added</h2>
    </div>)


  const loader = <Spinner key='1' />
  const smallLoader = <WhiteSpinner key="1" />
  let token = localStorage.getItem("refreshToken");

  return (

    <div>
      {showDeleteConfirmation ?
        <Modal
          visible={true}
          dialogClassName='delete-modal-dialog'>
          <div>
            <button
              type='button'
              className='close close-x'
              aria-label='Close'
              onClick={cancelDelete}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-heading'>
            <h5 className='car-and-device-assig'>Delete CellSite?</h5>
          </div>
          <div className='modal-body'>
            <h6>Are you sure you want to delete CellSite {selected.name}?</h6>


          </div>
          <div className='modal-footer model-btn'>
            <button
              className='btn auxo-primary-btn'
              onClick={deleteCellSite}>
              {deletingRecord ? [smallLoader] : 'Confirm Delete'}
            </button>

            <button
              className='btn auxo-primary-btn'
              onClick={cancelDelete}>
              Cancel
                    </button>
          </div>
        </Modal>
        : ''}


      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-4 margin-top-20'>
          <form onSubmit={searchCellSite}>
            <TextFieldGroup
              field={"search"}
              placeholder={"Search CellSite"}
              value={search}
              type={'text'}
              onChange={onChange} />
            <a className='search-icon' onClick={searchCellSite}><img src={searchIcon} alt='' /></a>
          </form>
        </div>
        <div className='col-lg-9 col-md-9 col-sm-8'>
        <span>
              <a className="custom-vehicles-upload cellsite-template-link" href={`${URL}/companies/files/cellsites.xlsx?token=${token}`} target="_blank" rel="noopener noreferrer">Download template</a>
            </span>
        <label className='custom-file-upload-1'>
                <input
                  type='file'
                  className='file-input-1'
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  onChange={
                    handleFileUpload
                   } />
                Upload Cellsites
              </label>
          <span>
            <button
              className='btn auxo-primary-btn'
              onClick={navigateToCreateCellSite
              }>Create CellSite</button>
          </span>

        </div>
      </div>
      {isLoading ?
        [loader] : ''}
      <div className='row'>

        {!isLoading && cellSites && cellSites.length !== 0
          ? <div className='trip-table-heading'>
            <p className='width-10'>Name</p>
            <p className='width-20'>Description</p>
            <p className='width-20'>District</p>
            <p className='width-10'>Guarded</p>
            <p className='width-30'>LOCATION</p>
            <p className='width-10'>ACTIONS</p>
          </div>
          : [notFound]}

        {cellSites && cellSites.map((item, index) => {
          index = 'abc' + index + 'z';
          item = _.clone(item);

          return (

            <div className='width-100' key={index}>
              <div className='trip-table-data'>

                <p className='width-10'>
                  {item.name}
                </p>

                <p className='width-20'>{item.description}
                </p>
                <p className='width-20'>{item.district || 'N/A'}
                </p>
                <p className='width-10'>{item.isGuarded ? 'Guarded': 'Not Guarded'}
                </p>

                <p className='width-30'>{item.location}</p>

                <span>

                  <a className='padding-10' onClick={() => { update(item) }}><img src={editIcon} alt='' /></a>

                  <a onClick={() => { showDeleteConfirmationModel(item) }}><img src={trash_bin} className='width16' alt='' /></a>
                </span>
              </div>


            </div>

          )
        })}

      </div>
    </div>
  );
}


const mapStateToProps = null;
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Patrolling.creators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CellSiteListing);
