import Duck from 'extensible-duck'
import axios from "axios";
import {hotspotEndPoints} from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoHotspot',
    store: 'hotspot',
    types: [
        'IS_LOADING', 'DETAILS_FETCHED', 'HOTSPOTS_FETCHED', 'CLEAR_HOTSPOT', 'HOTSPOTS_COUNT'
    ],
    initialState: {
        hotspot: {},
        hotspots: [],
        hotspotCount: 0,
        hasMoreItems: false,
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.HOTSPOTS_FETCHED:
                return {
                    ...state,
                    hotspots: [...state.hotspots, ...action.hotspots],
                    hasMoreItems: action.hotspots.length > 0 ? true : false
                };
            case duck.types.HOTSPOTS_COUNT:
                return {
                    ...state,
                    hotspotCount: action.hotspotCount,
                };
            case duck.types.CLEAR_HOTSPOT:
                return {
                    ...state,
                    hotspots: [],
                };

            case duck.types.DETAILS_FETCHED:
                return {
                    ...state,
                    hotspot: action.hotspot
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().hotspot.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(hotspotEndPoints.individualHotspot(state.id, companyInfo.groupId));
                let hotspot = response.data.data;
                dispatch({type: duck.types.DETAILS_FETCHED, hotspot});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupHotspots: () => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().hotspot.isLoading) {
                return;
            }
            
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                dispatch({type: duck.types.CLEAR_HOTSPOT});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(hotspotEndPoints.hotspot(companyInfo.groupId));
                let hotspots = response.data.data;
                dispatch({type: duck.types.HOTSPOTS_FETCHED, hotspots});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return hotspots
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupHotspots1: (search, clearRecord) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().hotspot.isLoading) {
                return;
            }
            if(clearRecord){
              dispatch({type: duck.types.CLEAR_HOTSPOT});
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                let url = hotspotEndPoints.hotspotList(companyInfo.groupId) + `?search=${search}&offset=${getState().hotspot.hotspots.length}`;
       
                const response = await axios.get(url);
                let {hotspots, hotspotCount} = response.data.data;
                dispatch({type: duck.types.HOTSPOTS_FETCHED, hotspots});
                dispatch({type: duck.types.HOTSPOTS_COUNT, hotspotCount});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return hotspots;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },

     
        Hotspot: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().hotspot.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                if(state.id){
                    await axios.put(hotspotEndPoints.individualHotspot(state.id, companyInfo.groupId), state.hotspotObj);
                } else{
                    await axios.post(hotspotEndPoints.hotspot(companyInfo.groupId), state);
                }
               
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
              console.log(e)
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        importHotspot: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().hotspot.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
               
                await axios.post(hotspotEndPoints.importHotspot(state.groupId), {hotspots: state.hotspots});
                
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        deleteHotspot: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().hotspot.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                await axios.delete(hotspotEndPoints.individualHotspot(state.id, companyInfo.groupId), state.vehicles);

                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
    })
})