import React, { Component } from 'react';
import { connect } from "react-redux";
import Roles from "../../ducks/Roles";
import { bindActionCreators } from "redux";
import editIcon from "../../assets/img/ic_edit.png";
import searchIcon from '../../assets/img/ic_search.png';
import TextFieldGroup from "../common/TextFieldGroup";
import trash_bin from "../../assets/img/trash_bin.png";
import { DeletePopUp } from '../common/DeletePopUp';
import { Spinner } from '../common/Spinner/index';

import toastr from "toastr";

class RoleList extends Component {

  constructor() {
    super();
    this.state = {
      roleToDelete: null,
      showDeleteConfirmation: false,
      isLoading: true
    };
  };
  async componentDidMount() {

    await this
      .props
      .actions
      .getRoles();
    this.setState({ ...this.state, isLoading: false })
  }

  navigateToAddRole() {
    this
      .props
      .history
      .push(`/roles/create`);

  }
  editRole(id) {

    this
      .props
      .history
      .push("/role/update/" + id);
  }
  confirmDelete = async () => {
    try {
      await this
        .props
        .actions
        .deleteRole(this.state.roleToDelete);
      toastr.success("Role deleted Successfully");
      this
        .props
        .actions
        .getRoles();
      this.setState({ ...this.state, showDeleteConfirmation: false });
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  };
  cancelDelete = () => {
    this.setState({ ...this.state, showDeleteConfirmation: false });
  }
  showDeletePopup = (id) => {
    this.setState({ ...this.state, showDeleteConfirmation: true, roleToDelete: id });
  }
  render() {
    const loader = <Spinner key='1' />;

    return (
      <>
        {this.state.isLoading ? [loader] :
          <div>
            {this.state.showDeleteConfirmation ? <DeletePopUp confirmDelete={this.confirmDelete.bind(this)} cancelDelete={this.cancelDelete.bind(this)} /> :

              <div>
                <div className="row add-role">

                  <div className="col-lg-4 col-md-4 col-sm-4 margin-top-20">


                    <TextFieldGroup
                      field={"search"}
                      placeholder={"Search Roles"}
                      type={"text"}
                    />
                    <a className="search-icon"><img src={searchIcon} alt="" /></a>

                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    {this.props.canCreateRole ? <button
                      className="btn auxo-primary-btn"
                      onClick={this
                        .navigateToAddRole
                        .bind(this)}>Add Role</button> : ""}


                  </div>
                </div>
                <table className="table-auxo">
                  <thead>
                    <tr>
                      <th>ROLE</th>
                      {this.props.canUpdateRole ? <th>ACTIONS</th> : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.roles && this
                      .props
                      .roles
                      .map(item => {
                        return (
                          <tr key={item["_id"]}>
                            <td>


                              {item.name}
                            </td>
                            {this.props.canUpdateRole ?
                              <td >

                                <a onClick={this.editRole.bind(this, item._id)}><img className="width-16" src={editIcon} alt="" /></a>
                                <a onClick={this.showDeletePopup.bind(this, item._id)}><img className="width-16" src={trash_bin} alt="" /></a>
                              </td> : ""}

                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            }
          </div>
        }
      </>
    );
  }
}

RoleList.propTypes = {};
RoleList.defaultProps = {};
const mapStateToProps = state => ({ roles: state.roles.list, canUpdateRole: state.permissions.updateRole, canCreateRole: state.permissions.createRole });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Roles.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RoleList);