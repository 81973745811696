import { isEmpty } from "lodash";

export const validateInput = (data) => {
    let errors = {};
    if (!data.selectedVehicle) {
        errors.vehicle = "Please select vehicle";
    }
    if(isNaN(Date.parse(data.startDate))){
        errors.startDate = "Enter a start date"
    }
    if(data.loc == 0){
        errors.loc = "Please select an option"
    }

    return { errors, isValid: isEmpty(errors) };
}