import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Schedule from "../../ducks/Schedule";
import { Spinner } from '../common/Spinner/index';
import moment from 'moment';
import trash_bin from "../../assets/img/trash_bin.png";
import toastr from "toastr";


class ScheduleSlots extends Component {

    constructor() {
        super();
        this.state = {
            isLoadingRecords: true
        }
    }

    async componentDidMount() {
        await this
            .props
            .actions
            .getSlots(this.props.match.params.id);
        this.setState({ ...this.state, isLoadingRecords: false })
    }


    navigateToAddSlot() {
        this
            .props
            .history
            .push("/slot/add/" + this.props.schedule._id);
    }

    async deleteSlot(id){
        try{
            let data = {scheduleId: this.props.schedule._id, slotId: id} 
            await this
            .props
            .actions
            .deleteSlots(data);
            toastr.success("Slot deleted successfully");
        } catch(err){
            toastr.error(err.response.data.message);
        }
       
    }

    render() {
        const loader = <Spinner key="1" />;
        const isLoadingRecords = this.state.isLoadingRecords;

        return (
            <div>{!isLoadingRecords ?
                <div>


                    <div className="row">

                        <div className="col-lg-4 col-md-4 col-sm-4 margin-top-20">
                            <h2>Slots</h2>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8">

                            <button
                                className="btn auxo-primary-btn"
                                onClick={this
                                    .navigateToAddSlot
                                    .bind(this)}>Add Slot</button>


                        </div>
                    </div>


                    <div className="row">

                        <table className="table-auxo">
                            <thead>
                                <tr>
                                    <th>START DAY </th>
                                    <th>END DAY </th>
                                    <th>START TIME </th>
                                    <th>END TIME </th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.schedule && this
                                    .props
                                    .schedule.slots
                                    .map((item, index) => {

                                        let timestart = moment().set({"hours":item.startTime, "minutes":item.startMinutes || 0});
                                        let timeend = moment().set({"hours":item.endTime, "minutes":item.endMinutes || 0});

                                        return (
                                            <tr key={index} className="driver-listing">
                                                <td className="edit-field">
                                                    {moment().isoWeekday(item.startDay).format("dddd")}

                                                </td>
                                                <td className="edit-field">
                                                    {moment().isoWeekday(item.endDay).format("dddd")}

                                                </td>
                                                <td className="edit-field">
                                                    {moment(timestart).format("hh:mm A")}

                                                </td>
                                                <td className="edit-field">
                                                    {moment(timeend).format("hh:mm A")}


                                                </td>
                                                <td className="edit-field">
                                                    <div>
                                                        <a onClick={this.deleteSlot.bind(this, item._id)}><img src={trash_bin} className="width16" alt="" /></a>
                                                    </div>


                                                </td>

                                            </tr>
                                        )
                                    })}


                            </tbody>
                        </table>


                    </div>
                </div> : [loader]}
            </div>
        );
    }
}

ScheduleSlots.propTypes = {};
ScheduleSlots.defaultProps = {};
const mapStateToProps = state => ({ schedule: state.schedule.schedule });

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Schedule.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSlots);
