import React, { Component } from 'react';
import { connect } from "react-redux";
import GoogleMapComponent from "../GoogleMapComponent";

import Groups from "../../../../ducks/Groups";
import Patrolling from "../../../../ducks/Patrolling";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import CheckboxListComponent from "../../../common/CheckboxListComponent";
import { CheckboxInlineComponent } from "../../../common/CheckboxListComponent";
import TextFieldGroup from "../../../common/TextFieldGroup";
import TextAreaGroup from "../../../common/TextAreaGroup";
// import GeoFenceMap from "../../common/maps/geofence";
import { validateInput } from "../../../../validations/routeFence";
import { WhiteSpinner } from '../../../common/Spinner/index';
import Slider from 'rc-slider';
import { HandleSlider } from "../../../common/HandleSlider";
import * as _ from "lodash";
import toastr from "toastr";
import to_marker from '../../../../assets/img/to-marker.png';
import from_marker from '../../../../assets/img/from-marker.png';
import Select from 'react-select-nested-group';
import DateTime from 'react-datetime';

class CreateRoster extends Component {

  constructor() {

    super();
    this.state = {
      name: "",
      description: "",
      vehicles: [],
      allVehicles: [],
      groups: [],

      selectedVehicles: [],
      activeTab: "vehicles",
      center: [
        73.13915736973286, 33.59492064226822
      ],
      radius: 300,
      errors: {},
      loader: false,
      markerClickedMessage: '',
      markerMarked: undefined,
      fromValue: '',
      toValue: '',
      getRoutes: false,
      bufferEnabled: false,
      checkLatLngEnabled: false,
      addBuffer: false,
      bufferValue: 0,
      checkLatLng: false,
      checkLatLngValue: '',
      path: [],
      cellSites: [],
      selectedCellSites: [],
      selectedCellSites2: [],
      isRoundTrip: false,
      waypoints: [],
      startDate: new Date(),
      endDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      recursive: false
    };
    this.handler = this.handler.bind(this);
    this.markerClicked = this.markerClicked.bind(this);
    this.getRoutes = this.getRoutes.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.addBuffer = this.addBuffer.bind(this);
    this.checkLatLng = this.checkLatLng.bind(this);
  }

  async componentDidMount() {
    const data = await this
      .props.actions
      .listCellSitesAndVehiclesName();
    const vehicles = data.vehicles.filter(item => { return !item.isAdminVehicle });
    this.setState({
      ...this.state,
      vehicles: [],
      allVehicles: vehicles,
      cellSites: data.cellSites,
      loader: false
    });
    // this.setState({
    //   ...this.state,
    //   vehicles: this.props.groupsAndVehicles.vehicles,
    //   groups: this.props.groupsAndVehicles.groups
    // })
  };

  handler(key, value) {
    this.setState({ [key]: value })
  }

  markerClicked(type) {
    this.setState({ markerClickedMessage: `${type} marker marked`, markerMarked: type });
  }

  async getRoutes() {
    if (!this.state.selectedCellSites.length || this.state.selectedCellSites.length < 2) {
      return toastr.error('You must select minimum of two cell sites')
    }
    if(this.state.isRoundTrip){
      // let value1 = this.state.selectedCellSites[this.state.selectedCellSites.length - 1];
      let value2 = this.state.selectedCellSites[0];
      const fromValue = value2.cords;
      const toValue = value2.cords;
      let allPoints = [...this.state.selectedCellSites, ...this.state.selectedCellSites2];
      allPoints.shift();
      allPoints.pop();
  
      const waypoints = _.map(allPoints, item => {
        return item.cords
      });
      if(waypoints.length > 20){
        return toastr.error('Maximum 20 waypoints can be selected')
      }
       this.setState({ fromValue, toValue, waypoints, getRoutes: true })
    } else{
      let value1 = this.state.selectedCellSites[0];
      let value2 = this.state.selectedCellSites[this.state.selectedCellSites.length - 1];
      const fromValue = value1.cords;
      const toValue = value2.cords;
      let allPoints = [...this.state.selectedCellSites];
      allPoints.shift();
      allPoints.pop();
  
      const waypoints = _.map(allPoints, item => {
        return item.cords
      });
      if(waypoints.length > 20){
        return toastr.error('Maximum 20 waypoints can be selected')
      }
       this.setState({ fromValue, toValue, waypoints, getRoutes: true })
    }
    // this.setState({ getRoutes: true })
  }

  addBuffer() {
    this.setState({ addBuffer: true })
  }

  updateInputValue(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkLatLng() {
    this.setState({ checkLatLng: true })
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }
  searchVehicles = (e) => {

    const value = e.target.value;
    let vehicles = [];

    let selectedVehicles = this.state.selectedVehicles;
    if (value) {
      _.forEach(this.state.vehicles, (item) => {
        if (item.plateNumber.search(new RegExp(value, "i")) >= 0) {
          let index = selectedVehicles.findIndex(obj => {
            return obj === item._id;
          });

          vehicles.push({
            _id: item._id,
            plateNumber: item.plateNumber || "N/A",
            checked: index > -1
              ? true
              : false,
            vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A"
          });
        }
      });

    } else {
      vehicles = _.map(this.state.vehicles, (item) => {
        let index = selectedVehicles.findIndex(obj => {
          return obj === item._id;
        });
        return {
          _id: item._id,
          plateNumber: item.plateNumber || "N/A",
          checked: index > -1
            ? true
            : false,
          vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A"
        };
      });
    }
    this.setState({
      ...this.state,
      vehicles
    });
  }
  checkBoxListChanged = (value) => {
    let vehicles = this.state.vehicles;
    let selectedVehicles = this.state.selectedVehicles;

    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          vehicles[i] = item;
          if (item.checked) {
            selectedVehicles.push(item._id);
          } else {
            let index = selectedVehicles.findIndex(obj => {
              return obj === item._id;
            });
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }
      }
    }
    this.setState({
      ...this.state,
      vehicles,
      selectedVehicles
    });
  };

  isTabActive = name => {
    return name === this.state.activeTab;
  };
  handleTabClick = name => {
    this.setState({
      ...this.state,
      activeTab: name
    })
  };


  onMapChange = (circle) => {

  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };
  onSubmit = async (e) => {

    e.preventDefault();
    const { loader } = this.state;

    if (loader) {
      return;
    }
    if (this.isValid()) {
      try {
        const state = this.state;

        const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        if (!state.name) {
          return toastr.error('Please enter name')
        }
        if (!state.vehicles.length) {
          return toastr.error('Please select vehicles')
        }
        if (!state.path.length) {
          return toastr.error('Please select path')
        }
        if (!state.bufferValue) {
          return toastr.error('Please enter buffer size')
        }

        this.setState({
          ...this.state,
          loader: true
        });

        const Obj = {
          name: _.trim(state.name),
          description: _.trim(state.description),
          vehicles: state.selectedVehicles,
          path: state.path,
          bufferSize: state.bufferValue,
          startLatLng: state.fromValue,
          endLatLng: state.toValue,
          cellSitesw1: _.map(state.selectedCellSites, 'value'),
          cellSitesw2: _.map(state.selectedCellSites2, 'value'),
          fromValue: state.fromValue,
          toValue: state.toValue,
          waypoints: state.waypoints,
          isRoundTrip: state.isRoundTrip,
          isRecursive: state.recursive,
          startDate: state.startDate,
          endDate: state.endDate,
          endTime: state.endTime,
          startTime: state.startTime,
          company: companyInfo.companyId,
        };

        await this
          .props
          .actions
          .createRoster(Obj);
        toastr.success("Roster Created Successfully!");
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push(`/roster/list`);

      } catch (e) {
        toastr.error(e.response ? e.response.data.message : e);
        this.setState({
          ...this.state,
          loader: false
        });



      }
    } else {

    }

  };

  getPath = (path) => {
    this.state.path = path;
  }
  searchCellSite1 = (payload) => {

    let selectedCellSites2 = [];
   
    let vehicles = [];
    _.forEach(payload, (item) => {      
      _.forEach(this.state.allVehicles, vehicleItem => {
        
        if (vehicleItem.group === item.group) {
          vehicleItem.checked = false;
          vehicles.push(vehicleItem)
        }
      })
    });
    vehicles = _.uniq(vehicles);

    if (payload.length >= 2) {

      const value1 = payload[payload.length - 1];
      const value2 = payload[0];

      selectedCellSites2.push(value1);
      selectedCellSites2.push(value2);
    }


    this.setState({
      ...this.state,
      selectedCellSites: payload,
      selectedCellSites2,
      selectedVehicles: [],
      vehicles,
    });
  }
  searchCellSite2 = (payload) => {

    
    let vehicles = [];
    _.forEach([...payload, ...this.state.selectedCellSites], (item, index) => {
      _.forEach(this.state.allVehicles, vehicleItem => {
        if (vehicleItem.group === item.group) {
          vehicleItem.checked = false;
          vehicles.push(vehicleItem)
        }
      })
    });
    vehicles = _.uniq(vehicles);


    let value1 = this.state.selectedCellSites[this.state.selectedCellSites.length - 1];
    let value2 = this.state.selectedCellSites[0];

    payload = _.filter(payload, item => {
      if (item.value !== value1.value && item.value !== value2.value) {
        return true
      }
      return false;
    })
    let selectedCellSites2 = [value1, ...payload, value2]
   
    this.setState({
      ...this.state,
      selectedCellSites2,
      selectedVehicles: [],
      vehicles,
     
    });
  }
  changeCheckValue = () => {
    this.setState({
      ...this.state,
      recursive: !this.state.recursive,
    });
  }

  setStartDate = (value) => {
    this.setState({
      ...this.state,
      startDate: value,
    });
  }
  setEndDate = (value) => {
    this.setState({
      ...this.state,
      endDate: value,
    });
  }
  setStartTime = (value) => {
    this.setState({
      ...this.state,
      startTime: value,
    });
  }
  setEndTime = (value) => {
    this.setState({
      ...this.state,
      endTime: value,
    });
  }
  changeCheckValueOfRoundTrip = (target) => {
    this.setState({ ...this.state, isRoundTrip: !this.state.isRoundTrip })
  };

  render() {
    const { name, description, startDate, endDate, startTime, endTime, recursive } = this.state;

    const loader = <WhiteSpinner key="1" />

    return (
      <form onSubmit={this.onSubmit} className="m-b-30">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <TextFieldGroup
              onChange={this.onChange.bind(this)}
              value={name}
              label={"Roster Name*"}
              type={"text"}
              field={"name"}
              error={this.state.errors.name}
              placeholder={"Name"} />
            <TextAreaGroup
              onChange={this.onChange.bind(this)}
              value={description}
              label={"Description"}
              field={"description"}
              error={this.state.errors.description}
              placeholder={"Extra details about Roster"} />

            <span>
              <input type='checkbox' name={'vehicles'} checked={recursive} onChange={e => {
                const { target } = e;
                this.changeCheckValue(target)
              }} value={recursive} /> Recursive
                                </span>
            {!recursive && <>
              <div>
                <label>Start Date</label>
                <DateTime value={startDate} dateFormat="DD-MM-YYYY" timeFormat={false} onChange={this.setStartDate} />

              </div>
              <div>
                <label>End Date</label>
                <DateTime value={endDate} dateFormat="DD-MM-YYYY" timeFormat={false} onChange={this.setEndDate} />

              </div>
            </>}
            <div>
              <label>Duty Start Time</label>
              <DateTime value={startTime} dateFormat={false} timeFormat="HH:mm" onChange={this.setStartTime} />

            </div>
            <div>
              <label>Duty End Time</label>
              <DateTime value={endTime} dateFormat={false} timeFormat="HH:mm" onChange={this.setEndTime} />

            </div>

            <div>
            <label htmlFor="source" className='m-t-20'>Maximum 20 way points can be selected</label>
              <div className="form-group required control-label">

                <label htmlFor="source">CellSites w1</label>
                <div className='form-group'>

                  <Select
                    name='form-field-name'
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    placeholder='Select CellSite'
                    onChange={this.searchCellSite1}
                    value={this.state.selectedCellSites}
                    options={this.state.cellSites.map(item => {
                      return {
                        value: item._id,
                        label: item.name,
                        cords: item.cords,
                        group: item.group
                      }
                    })}
                  />
                </div>

              </div>
              <div className='filter'>
                <input type='checkbox' name={'isRoundTrip'} checked={this.state.isRoundTrip} onChange={e => {
                  const { target } = e;
                  this.changeCheckValueOfRoundTrip(target)
                }} value={this.state.isRoundTrip} /> Round Trip
                                </div>
              {this.state.isRoundTrip &&
                <div className="form-group required control-label">

                  <label htmlFor="source">CellSites w2</label>
                  <div className='form-group'>

                    <Select
                      name='form-field-name'
                      isClearable={true}
                      isSearchable={true}
                      isMulti
                      placeholder='Select CellSite'
                      onChange={this.searchCellSite2}
                      value={this.state.selectedCellSites2}
                      options={this.state.cellSites.map(item => {
                        return {
                          value: item._id,
                          label: item.name,
                          cords: item.cords,
                          group: item.group,
                          isFixed: item.isFixed || false

                        }
                      })}
                    />
                  </div>

                </div>}

              {/* <div className="form-group required control-label">
                
                <label htmlFor="destination">Destination/To</label>
                {/* <input type="text"
                  id="destination"
                  className="form-control"
                  step="0.01"
                  placeholder="Click on the icon to map"
                  disabled
                  required
                  value={this.state.toValue}
                /> */}
              {/* <Select
                  name='form-field-name'
                  isClearable={true}
                  isSearchable={true}

                  placeholder='Select CellSite'
                  onChange={this.searchCellSite2}
                  value={this.state.selectedCellSiteTwo}
                  options={this.state.cellSites.map(item => {
                    return {
                      value: item._id,
                      label: item.name,
                      cords: item.cords
                    }
                  })}
                />
              </div> */}
              <div className="form-group required control-label">

                <label htmlFor="bufferInput">Buffer (meters)</label>
                <input type="number"
                  id="bufferValue"
                  name="bufferValue"
                  className="form-control"
                  step="0.01"
                  placeholder="Enter buffer distance in meters"
                  disabled={!this.state.bufferEnabled}
                  required
                  value={this.state.bufferValue}
                  onChange={this.updateInputValue}
                />
              </div>



              <small id="message" className="text-success">{this.state.markerClickedMessage}</small>

              <div className="form-group">
                <div>
                  <button type="button"
                    className="btn btn-success mr-1"
                    onClick={this.getRoutes}
                  >
                    Get Route
                </button>
                  <button type="button"
                    className="btn btn-success mr-1"
                    disabled={!this.state.bufferEnabled}
                    onClick={this.addBuffer}
                  >
                    Add Buffer
                </button>

                </div>

              </div>

            </div>

            <div className="col padding0">

              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={"nav-link active"}
                    onClick={() => this.handleTabClick("vehicles")}>Vehicles</a>
                </li>
              </ul>
              <div className="tab-content access-tab mb-5 heading-200">
                <div
                  className={classnames("tab-pane fade", {
                    "show active": this.isTabActive("vehicles")
                  })}>
                  <CheckboxListComponent
                    enableSearch="true"
                    search={this
                      .searchVehicles
                      .bind(this)}
                    onChange={this.checkBoxListChanged}
                    data={this.state.vehicles && this
                      .state
                      .vehicles
                      .map(item => ({
                        mainTitle: item.plateNumber || "N/A",
                        subTitle: item.vehicleMakeAndModel || "N/A",
                        checked: item.checked
                      }))} />
                </div>

                {this.state.errors &&
                  <span className="text-danger">{this.state.errors.vehicles}</span>}
              </div>

              <div className="margin-btm-50">
                <button className="btn auxo-primary-btn create-geofence-btn" type="submit">
                  {this.state.loader ? [loader] : 'Create'}</button>
              </div>

            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 routefence-map">
            <GoogleMapComponent handler={this.handler}
              markerMarked={this.state.markerMarked}
              fromMarker={this.state.fromValue}
              waypoints={this.state.waypoints}
              toMarker={this.state.toValue}
              addBuffer={this.state.addBuffer}
              bufferValue={this.state.bufferValue}
              getRoutes={this.state.getRoutes}
              checkLatLng={this.state.checkLatLng}
              checkLatLngValue={this.state.checkLatLngValue}
              getPath={this.getPath.bind(this)}
            />
          </div>

        </div>
      </form>

    );
  }
}

CreateRoster.propTypes = {};
CreateRoster.defaultProps = {};
const mapStateToProps = state => ({ groupsAndVehicles: state.groups.groupsAndVehicles });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Patrolling.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateRoster);
