// authDuck.js
import jwt_decode from 'jwt-decode';
import axios from "axios";
import setAuthorizationToken, { setRefreshToken, setUserId, setCompanyInfo, setIdentityId, setGroupInfo , setMigratedStatus } from '../utils/setAuthorizationToken';
import { authEndpoints } from "../api/endpoints";
import Duck from 'extensible-duck';
import Permissions from "./Permissions";
import Users from "./Users";


const PermissionsActions = Permissions.creators;
const UsersActions = Users.creators;

export default new Duck({
  namespace: 'AuxoAuth', store: 'userAuthentication',
  types: ['USER_IS_LOADING', 'USER_AUTHENTICATED', 'USER_SET', 'THINGS_ASSIGNED', 'GROUP_LOGIN'],
  initialState: {
    user: {},
    loggedInGroupInfo: localStorage.getItem("groupInfo") ? JSON.parse(localStorage.getItem("groupInfo")) : {},
    isLoading: false,
    isAuthenticated: false,
    thingsAssigned: true
  },
  reducer: (state, action, duck) => {

    switch (action.type) {
      case duck.types.USER_IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };
      case duck.types.USER_AUTHENTICATED:
        return {
          ...state,
          isAuthenticated: action.isAuthenticated
        };
      case duck.types.USER_SET:
        return {
          ...state,
          user: action.user
        };
      case duck.types.USER_SET_PERMISSIONS:
        return {
          ...state,
          permissions: action.permissions
        };
      case duck.types.THINGS_ASSIGNED:
        return {
          ...state,
          thingsAssigned: action.thingsAssigned
        };
      case duck.types.GROUP_LOGIN:
        return {
          ...state,
          loggedInGroupInfo: action.group
        };
      default:
        return state;
    }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({
    updateLoggedInGroupInfo: state => async (dispatch, getState) => {
      const groupData = state;
      dispatch({ type: duck.types.GROUP_LOGIN, group: groupData });
      setGroupInfo(groupData);
    },

    setAdminUser: (userInfo) => async (dispatch, getState) => {
  
      const refreshToken = userInfo.refreshToken;
      const token = userInfo.token;
      const isMigrated = userInfo.isMigrated;
      const identityId = userInfo?.identityId;
      const userId = userInfo.userId;
      const info = userInfo.companyInfo;
      const groupData = userInfo.groupData;
      dispatch({ type: duck.types.GROUP_LOGIN, group: groupData });

      
      setIdentityId(identityId);
      setRefreshToken(refreshToken);
      setAuthorizationToken(token);
      localStorage.setItem("isSuperAdminUser", {isSuperAdminUser: true});

      let user = jwt_decode(token);

      dispatch({ type: duck.types.USER_AUTHENTICATED, isAuthenticated: true });
      dispatch({ type: duck.types.USER_SET, user });
      dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });

      await setUserId(userId);
      setCompanyInfo(info);
      setGroupInfo(groupData);
      setMigratedStatus(isMigrated);

      dispatch(PermissionsActions.setUserPermissions(userId));
      dispatch(PermissionsActions.setCompanyFeatures(info.companyId));

      dispatch(UsersActions.getUserNotificationsSetting());
      dispatch(UsersActions.subscribeNotifications());
    },
    loginUser: state => async (dispatch, getState) => {
      if (getState().userAuthentication.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
      }
      try {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });

        const response = await axios.post(authEndpoints.login, { userName: state.username, password: state.password,captchaValue:state.captchaValue, userType: "Manager" });

        const refreshToken = response.data.data.refreshToken;
        const token = response.data.data.token;
        const identityId = response.data.data.identityId;
        const userId = response.data.data.userId;
        const isMigrated = response.data.data.isMigrated;
        const info = {
          groupId: response.data.data.groupId,
          companyId: response.data.data.companyId,
        }

        const groupData = response.data.data.groupData;
        dispatch({ type: duck.types.GROUP_LOGIN, group: groupData });

        if (response.data.data.hasOwnProperty('thingsAssigned')) {
          dispatch({ type: duck.types.THINGS_ASSIGNED, thingsAssigned: response.data.data.thingsAssigned });
        }

        setIdentityId(identityId);
        setRefreshToken(refreshToken);
        setAuthorizationToken(token);
        let user = jwt_decode(token);

        dispatch({ type: duck.types.USER_AUTHENTICATED, isAuthenticated: true });
        dispatch({ type: duck.types.USER_SET, user });
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });

        await setUserId(userId);
        setCompanyInfo(info);
        setGroupInfo(groupData);
        setMigratedStatus(isMigrated);

        dispatch(PermissionsActions.setUserPermissions(userId));
        dispatch(PermissionsActions.setCompanyFeatures(info.companyId));

        dispatch(UsersActions.getUserNotificationsSetting());
        dispatch(UsersActions.subscribeNotifications());
        // Run(state.username, state.password)
      }
      catch (e) {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    forgotPassword: username => async (dispatch, getState) => {
      if (getState().userAuthentication.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
      }
      try {

        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
        await axios.post(authEndpoints.forgotPassword, { username });
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
      }
      catch (e) {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    confirmCode: state => async (dispatch, getState) => {
      if (getState().userAuthentication.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
      }
      try {

        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
        await axios.post(authEndpoints.confirmCode, state);
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
      }
      catch (e) {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
        throw e;
      }
    },

    refreshToken: state => async (dispatch, getState) => {

      try {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
        await axios.get(authEndpoints.refreshToken);
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
      }
      catch (e) {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    logoutUser: () => async (dispatch, getState) => {
      try {
        await axios.get(authEndpoints.logout);
        setAuthorizationToken(false);
        setRefreshToken(false);
        dispatch({ type: duck.types.USER_AUTHENTICATED, isAuthenticated: false });
        dispatch({ type: duck.types.USER_SET, user: null });
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
        window.history.pushState({}, '', '/login');
        window.dispatchEvent(new PopStateEvent('popstate'));
      }
      
      catch (e) {
        throw e;
      }
    },
    setUser: user => async (dispatch, getState) => {
      dispatch({ type: duck.types.USER_SET, user });
      dispatch({ type: duck.types.USER_AUTHENTICATED, isAuthenticated: true });
    },
    confirmUser: state => async (dispatch, getState) => {
      try {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
        await axios.post(authEndpoints.confirmUser, state);
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
      }
      catch (e) {
        dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
        throw e;
      }
    }

  })
})