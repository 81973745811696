import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

const TextFieldGroup = ({ field, value,readonly, label, placeholder, error, type, onChange, onBlur, pattern, id, key, labelIcon, isRequired, className }) => {
  let labelTag;
  if (label) {
    labelTag = <label>{label}</label>
  }
  return (
    <div className={`${classnames('form-group', { 'has-error': error })} ${className || ''}`}>
      {labelTag} {labelIcon ?
      <span> <img src={labelIcon.icon} alt="info" href="#" id="UncontrolledTooltipExample"/> <UncontrolledTooltip
        placement="top" target="UncontrolledTooltipExample">
                {labelIcon.text}
            </UncontrolledTooltip> </span> : ""}
      <input
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        type={type}
        name={field}
        readOnly={readonly}
        placeholder={placeholder}
        className={`form-control ${isRequired && !value ? ' border border-danger' : ''}`}
        pattern={pattern}
        id={id}
        key={key}
        style={{ height: 'calc(1.5em + .75rem + 6px)' }}
      />
      {error && <span className="text-danger">{error}</span>}
    </div>);
};

TextFieldGroup.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  pattern: PropTypes.string,
  id: PropTypes.number,
  key: PropTypes.number,
  readOnly: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checkUserExists: PropTypes.func
};

TextFieldGroup.defaultProps = {
  type: 'text'
};

export default TextFieldGroup;
