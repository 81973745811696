import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Groups from "../../ducks/Groups";
import Reports from "../../ducks/Reports";
import Visualization from "../../ducks/Visualization";
import Select from "react-select-nested-group";
import CheckboxListComponent from "../../components/common/CheckboxListComponent";
import * as _ from "lodash";
import classnames from "classnames";
import { validateInput } from "../../validations/violationBar";
import { WhiteSpinner } from "../common/Spinner/index";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import DateTime from "react-datetime";
import {
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import moment from "moment";
import placeholder from "../../assets/img/chart-placeholder.svg";
const ViolationBarChart = (props) => {
  const [activeTab, setActiveTab] = useState("vehicles");
  const [state, setState] = useState({
    selectedVehicles: [],
    errors: {},
    loader: false,
    violations: [
      {
        id: "HARSH_ACCELERATION",
        name: "Harsh acceleration",
      },
      {
        id: "HARSH_BRAKES",
        name: "Sudden brake",
      },
      {
        id: "HARSH_TURN",
        name: "Sharp turn",
      },
      {
        id: "SPEED_LIMIT",
        name: "Overspeeding",
      },
      {
        id: "UNFASTEN_SEATBELT",
        name: "Unfasten seatbelt",
      },
    ],
    selectedViolations: [],
    startDate: moment().subtract("1", "days"),
    endDate: moment(),
    data: [],
  });
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [eventName, setEventName] = useState('')
  useEffect(() => {
    props.actions.getGroupsAndVehicles();
  }, []);
  useEffect(() => {
    setState({
      ...state,
      vehicles: props.groupsAndVehicles.vehicles,
      groups: props.groupsAndVehicles.groups,
    });
  }, [props.groupsAndVehicles]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const isTabActive = (name) => {
    return name === activeTab;
  };
  const searchVehicles = (e) => {
    const value = e.target.value;
    let vehicles = [];

    let selectedVehicles = state.selectedVehicles;
    if (value) {
      _.forEach(props.groupsAndVehicles.vehicles, (item) => {
        if (item.plateNumber.search(new RegExp(value, "i")) >= 0) {
          let index = selectedVehicles.findIndex((obj) => {
            return obj === item._id;
          });

          vehicles.push({
            _id: item._id,
            plateNumber: item.plateNumber || "N/A",
            checked: index > -1 ? true : false,
            vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A",
          });
        }
      });
    } else {
      vehicles = _.map(props.groupsAndVehicles.vehicles, (item) => {
        let index = selectedVehicles.findIndex((obj) => {
          return obj === item._id;
        });
        return {
          _id: item._id,
          plateNumber: item.plateNumber || "N/A",
          checked: index > -1 ? true : false,
          vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A",
        };
      });
    }
    setState({
      ...state,
      vehicles,
    });
  };
  const checkBoxListChanged = (value) => {
    let vehicles = state.vehicles;
    let groups = state.groups;
    let selectedVehicles = state.selectedVehicles;

    if (vehicles.length > 0) {
      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groups[j] = groupItem;
        }
      };

      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          vehicles[i] = item;
          if (item.checked) {
            selectedVehicles.push(item._id);
            const groupVehicle = _.find(vehicles, (veh) => {
              return veh.group === item.group && !veh.checked;
            });
            if (!groupVehicle) {
              selectGroup(item, true);
            }
          } else {
            selectGroup(item, false);

            let index = selectedVehicles.findIndex((obj) => {
              return obj === item._id;
            });
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }
      }
    }
    setState({
      ...state,
      vehicles,
      groups,
      selectedVehicles,
    });
  };
  const checkGroupChanged = (value) => {
    let groups = state.groups;
    let vehicles = state.vehicles;
    let selectedVehicles = state.selectedVehicles;

    let groupSelected;

    for (let i = 0; i < groups.length; i++) {
      let item = groups[i];
      if (i === parseInt(value, 10)) {
        item.checked = !item.checked;
        groupSelected = item;
      }
      groups[i] = item;
    }
    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (groupSelected._id === item.group) {
          item.checked = groupSelected.checked;
          if (item.checked) {
            selectedVehicles.push(item._id);
          } else {
            let index = selectedVehicles.findIndex((obj) => {
              return obj === item._id;
            });
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }
        vehicles[i] = item;
      }
    }
    setState({
      ...state,
      vehicles,
      groups,
      selectedVehicles,
    });
  };
  const checkViolationChanged = (value) => {
    setState({
      ...state,
      selectedViolations: value,
    });
  };
  const onChangeStartDate = (e) => {
    setState({ ...state, startDate: new Date(e) });
  };
  const onChangeEndDate = (e) => {
    setState({ ...state, endDate: new Date(e) });
  };
  const isValid = () => {
    const { errors, isValid } = validateInput(state);
    if (!isValid) {
      setState({ ...state, errors });
    }
    return isValid;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (state.loader) {
      return;
    }
    if (isValid()) {
      delete state.errors;
      setState({
        ...state,
        loader: true,
      });
      const requestObject = {
        vehicles: state.selectedVehicles,
        violations: state.selectedViolations.map((sv) => sv.value),
        startDate: state.startDate.toISOString(),
        endDate: state.endDate.toISOString(),
      };
      //call api
      await props.chartActions.loadViolationChartData(requestObject);
      setState({
        ...state,
        loader: false,
      });
    }
  };
  const download = async () => {
    if (state.loader) {
      return;
    }
    if (isValid()) {
      delete state.errors;
      setState({
        ...state,
      });
      const requestObject = {
        vehicles: state.selectedVehicles,
        eventType: state.selectedViolations.map((sv) => sv.value),
        interval: {
          startDate: state.startDate.toISOString(),
          endDate: state.endDate.toISOString(),
        },
        reportType: "violationVisualReport",
      };
      await props.reportActions.exportReport(requestObject);
    }
  };
  const showModal = (event, eventName) => {
    setModalData(props.violation.count[event].events || [])
    setModal(true);
    setEventName(eventName);
  };
  const toggle = () => setModal(!modal);
  const loader = <WhiteSpinner key="1" />;
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{`${eventName} Violation Details`}</ModalHeader>
        <ModalBody>
          <table className='table-auxo'>
            <thead>
              <tr>
              <th>Plate Number</th>
              <th>Group</th>
              <th>Violation Time</th>
              <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((row, index) => {
                return (
                  <tr key={index.toString()}>
                    <td className="modal-cell">{row.thingId.plateNumber}</td>
                    <td className="modal-cell">{row.thingId.group.name}</td>
                    <td className="modal-cell">{moment(row.eventTime).format("DD-MMM-YYYY hh:mm a")}</td>
                    <td className="modal-cell"><a href={`https://google.com/maps/search/${row.thing.state.latlng}`} target="_blank" rel="noopener noreferrer">{row.location}</a></td>
                  </tr>
                )
              })}
              {
                modalData.length === 0 && <tr>
                  <td colSpan="4" style={{textAlign: "center"}}>
                    No Violations found.
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <button className="btn auxo-primary-btn create-geofence-btn" onClick={toggle}>Close</button>
        </ModalFooter>
      </Modal>
      <div className="row">
        <div className="col-md-4">
          <form onSubmit={onSubmit}>
            <Card className="height-100-p " style={{ minWidth: "100%" }}>
              <CardHeader className="f-bold">Filter Criteria</CardHeader>
              {/* <h3 className="p-10-px">Analytics</h3> */}
              <div className="col p-10-px">
                <div className="form-group">
                  <label>Select Violation</label>
                  <Select
                    name="form-field-name"
                    isClearable={true}
                    isMulti
                    isSearchable={true}
                    placeholder="Search"
                    onChange={checkViolationChanged}
                    value={state.selectedViolations}
                    options={
                      state.violations &&
                      state.violations.map((item) => ({
                        value: item.id,
                        label: item.name || "N/A",
                        item: item,
                      }))
                    }
                  />
                </div>
                {state.errors && (
                  <span className="text-danger">{state.errors.violations}</span>
                )}
                <div className="form-group">
                  <label>Start Date</label>
                  <DateTime
                    value={state.startDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat={false}
                    onChange={onChangeStartDate}
                  />
                </div>
                {state.errors && (
                  <span className="text-danger">{state.errors.startDate}</span>
                )}
                <div className="form-group">
                  <label>End Date</label>
                  <DateTime
                    value={state.endDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat={false}
                    onChange={onChangeEndDate}
                  />
                </div>
                {state.errors && (
                  <span className="text-danger">{state.errors.endDate}</span>
                )}

                <label>Select Vehicles</label>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={"nav-link active"}
                      onClick={() => handleTabClick("vehicles")}
                    >
                      Vehicles
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={"nav-link active"}
                      onClick={() => handleTabClick("groups")}
                    >
                      Groups
                    </a>
                  </li>
                </ul>
                <div className="tab-content access-tab mb-5 heading-200">
                  <div
                    className={classnames("tab-pane fade", {
                      "show active": isTabActive("vehicles"),
                    })}
                  >
                    <CheckboxListComponent
                      enableSearch="true"
                      search={searchVehicles}
                      onChange={checkBoxListChanged}
                      data={
                        state.vehicles &&
                        state.vehicles.map((item) => ({
                          mainTitle: item.plateNumber || "N/A",
                          subTitle: item.vehicleMakeAndModel || "N/A",
                          checked: item.checked,
                        }))
                      }
                    />
                  </div>
                  <div
                    className={classnames("tab-pane fade", {
                      "show active": isTabActive("groups"),
                    })}
                  >
                    <CheckboxListComponent
                      onChange={checkGroupChanged}
                      data={
                        state.groups &&
                        state.groups.map((item) => ({
                          mainTitle: item.name || "N/A",
                          checked: item.checked,
                        }))
                      }
                    />
                  </div>
                </div>
                {state.errors && (
                  <span className="text-danger">{state.errors.vehicles}</span>
                )}

                <div className="margin-btm-50">
                  <button
                    className="btn auxo-primary-btn create-geofence-btn"
                    type="submit"
                  >
                    {state.loader ? [loader] : "Filter"}
                  </button>
                </div>
              </div>
            </Card>
          </form>
        </div>
        <div className="col-md-8">
          <Card className="height-100-p" style={{ minWidth: "100%" }}>
            <CardHeader className="f-bold">
              Visualization
              {props.violation.data?.length > 0 && (
                <span>
                  <button
                    className="btn auxo-primary-btn m-btn-v"
                    type="button"
                    onClick={() => download()}
                  >
                    <i className="fa fa-download"></i>
                  </button>
                </span>
              )}
            </CardHeader>
            {props.violation.data?.length > 0 && (
              <div className="row" style={{ margin: "11px 0px 1px 0px" }}>
                <div className="col-md-2 offset-md-1 col-sm-6">
                  <div
                    className="stat-card card-hover"
                    onClick={() => showModal("HARSH_ACCELERATION", "Harsh Acceleration")}
                  >
                    <span className="counter">
                      {props.violation.count?.HARSH_ACCELERATION?.total || 0}
                    </span>
                    <p className="f-11-px text-center">Harsh Acceleration</p>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div
                    className="stat-card card-hover"
                    onClick={() => showModal("HARSH_BRAKES", "Sudden Brake" )}
                  >
                    <span className="counter">
                      {props.violation.count?.HARSH_BRAKES?.total || 0}
                    </span>
                    <p className="f-11-px text-center">Sudden Brake</p>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div
                    className="stat-card card-hover"
                    onClick={() => showModal("HARSH_TURN", "Sharp Turn")}
                  >
                    <span className="counter">
                      {props.violation.count?.HARSH_TURN?.total || 0}
                    </span>
                    <p className="f-11-px text-center">Sharp Turn</p>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div
                    className="stat-card card-hover"
                    onClick={() => showModal("SPEED_LIMIT", "Speed Limit")}
                  >
                    <span className="counter">
                      {props.violation.count?.SPEED_LIMIT?.total || 0}
                    </span>
                    <p className="f-11-px text-center">Speed Limit</p>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div
                    className="stat-card card-hover"
                    onClick={() => showModal("UNFASTEN_SEATBELT", "Unfasten Seatbelt")}
                  >
                    <span className="counter">
                      {props.violation.count?.UNFASTEN_SEATBELT?.total || 0}
                    </span>
                    <p className="f-11-px text-center">Unfasten Seatbelt</p>
                  </div>
                </div>
              </div>
            )}

            {props.violation.data?.length > 0 && (
              <ResponsiveContainer
                width="98%"
                height="60%"
                className="m-t-40-px"
              >
                <BarChart data={props.violation.data} width={700} height={300}>
                  <CartesianGrid strokeDasharray="3 3" />

                  <XAxis dataKey="plateNumber" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="harshAccelaration" fill="#FF0000" />
                  <Bar dataKey="harshBrakes" fill="#FFA500" />
                  <Bar dataKey="harshTurn" fill="#03ABCE" />
                  <Bar dataKey="lowIntensitySpeedLimit" fill="#FA8072" />
                  <Bar dataKey="mediumIntensitySpeedLimit" fill="#B22222" />
                  <Bar dataKey="highIntensitySpeedLimit" fill="#800000" />
                  <Bar dataKey="unfastenSeatbelt" fill="#d4aaff" />
                </BarChart>
              </ResponsiveContainer>
            )}
            {props.violation.data.length === 0 && (
              <div className="img-container">
                <img src={placeholder} width="600" height="400" />
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  groupsAndVehicles: state.groups.groupsAndVehicles,
  violation: state.visualization.violationChartData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Groups.creators, dispatch),
  chartActions: bindActionCreators(Visualization.creators, dispatch),
  reportActions: bindActionCreators(Reports.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViolationBarChart);
