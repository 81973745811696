import React, { Component } from 'react';
import Group from "../../../ducks/Groups";
import User from "../../../ducks/Users";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import './index.css'
import editIcon from "../../../assets/img/ic_edit.png";
import camera from "../../../assets/img/ic_camera.png"
import { UncontrolledCollapse } from 'reactstrap';
import ToggleButton from 'react-toggle-button'
import forwardIcon from '../../../assets/img/ic_forward.png';
import toastr from "toastr";
import Slider from 'rc-slider';
import { HandleSlider } from "../../common/HandleSlider";
const { Range } = Slider;
const colors = {
  activeThumb: {
    base: '#fff',
  },
  inactiveThumb: {
    base: '#fff',
  },
  active: {
    base: '#03ABCE',
    hover: '#03ABCE',
  },
  inactive: {
    base: 'rgb(65,66,68)',
    hover: 'rgb(95,96,98)',
  }
};

const notificationnObj = {
  timeCheck: false,
  complaint: false,
  ignitionOnRestrictedHours: false,
  vehicleMovingRestrictedHours: false,
  geoFenceEntry: false,
  geoFenceExit: false,
  rosterEntry: false,
  rosterExit: false,
  routeFenceEntry: false,
  routeFenceExit: false,
  harshAcceleration: false,
  harshBrake: false,
  overSpeeding: false,
  sharpTurn: false,
  vehicleMoving: false,
  ignitionOn: false,
  vehicleParked: false,
  vehicleOffline: false,
  vehicleOnline: false,
  unfasten_seatbelt: false,
  engineKilled: false,
  engineReleased: false,
  vehicleIdle: false,
  impact: false,
  fatigue: false,
  restFatigue: false,
  lowFuel: false,
  highRpm: false,
  engineHeatup: false,
  doorStatus: false,
  panic_button: false,
  jamming: false,
  unknownDriver: false,
}



class GroupAccount extends Component {
  constructor(props) {
    const groupData = JSON.parse(localStorage.getItem("groupInfo"));

    super(props);
    this.state = {
      name: "",
      image: camera,
      groupId: "",
      toggle: true,
      eHour:0,
      sHour:0,
      overSpeedingThreshold: null,
      lowIntensityOverSpeeding: null,
      highIntensityOverSpeeding: null,
      fatigueThreshold: null,
      restAfterFatigueThreshold: null,
      nrConfig: null,
      notifications: {...notificationnObj, warningPopup: false, buzzer: false},
      groupDepthLevel: groupData.depthLevel,
      sms: notificationnObj,
      email: notificationnObj,
      drivingScoreSetting: {
        harshAcceleration: false,
        harshBrake: false,
        overSpeeding: false,
        sharpTurn: false,
        unFastenSeatBelt: false,
      },
      overSpeedingOpen: false,
      fatigueOpen: false,
      notificationsOpen: false,
      drivingScoresOpen: false,
      driverHoursOpen: false,
      nrToggleOpen: false,
    }
  }

  async componentDidMount() {
    const { groupId, companyId } = JSON.parse(localStorage.getItem("companyInfo"));

    await Promise.all([
      this
        .props
        .actions
        .getSingle(groupId),

      this
        .props
        .actions
        .getDrivingScoreSetting(companyId),

      this
      .props
      .actions
      .getDriverHoursConfigSetting(companyId),

      this
        .props
        .userActions
        .getUserNotificationsSetting()
    ]).then(() => {

      this.setState({
        ...this.state,
        name: this.props.group.name,
        image: this.props.group.groupImage || camera,
        overSpeedingThreshold: this.props.group.overSpeedingThreshold || 120,
        lowIntensityOverSpeeding: this.props.group.lowIntensityOverSpeeding || this.props.group.overSpeedingThreshold + 10,
        highIntensityOverSpeeding: this.props.group.highIntensityOverSpeeding || this.props.group.overSpeedingThreshold +30,
        fatigueThreshold: this.props.group.fatigueThreshold,
        restAfterFatigueThreshold: this.props.group.restAfterFatigueThreshold,
        nrConfig: this.props.group.nrConfig,
        groupId,
        notifications: this.props.notifications,
        sms: this.props.smsSetting,
        email: this.props.emailSetting,
        drivingScoreSetting: this.props.drivingScoreSetting,
        sHour: this.props.driverHours?.start,
        eHour: this.props.driverHours?.end,
      });
    });
  }

  NotificationToggle(data, value) {
    let notifications = this.state.notifications;
    notifications[data] = !value;
    this.setState({
      ...this.state,
      notifications
    });
  }
  smsToggle(data, value) {
    let sms = this.state.sms;
    sms[data] = !value;
    this.setState({
      ...this.state,
      sms
    });
  }
  emailToggle(data, value) {
    let email = this.state.email;
    email[data] = !value;
    this.setState({
      ...this.state,
      email
    });
  }
  allToggle(data, value) {
    let email = this.state.email;
    let sms = this.state.sms;
    let notifications = this.state.notifications;
    email[data] = !value;
    sms[data] = !value;
    notifications[data] = !value;
    this.setState({
      ...this.state,
      email,
      notifications,
      sms
    });
  }

  scoreConfigToggle(data, value) {

    let drivingScoreSetting = this.state.drivingScoreSetting;
    drivingScoreSetting[data] = !value;
    this.setState({
      ...this.state,
      drivingScoreSetting
    });

  }
  changeOverspeedingThreshold = async (value) => {
    this.setState({ ...this.state, overSpeedingThreshold: value });
  }
  changeLowOverspeedingThreshold = async (value) => {
    this.setState({ ...this.state, lowIntensityOverSpeeding: value });
  }
  changeHighOverspeedingThreshold = async (value) => {
    this.setState({ ...this.state, highIntensityOverSpeeding: value });
  }
  changeFatigueThreshold = async (value) => {
    this.setState({ ...this.state, fatigueThreshold: value });
  }
  changerestAfterFatigueThreshold = async (value) => {
    this.setState({ ...this.state, restAfterFatigueThreshold: value });
  }
  changeNrThreshold = async (value) => {

    this.setState({ ...this.state, nrConfig: value });

  }
  saveOverspeedingThreshold = async (value) => {
    try {

      const { name, image, groupId, overSpeedingThreshold, nrConfig, lowIntensityOverSpeeding,
         highIntensityOverSpeeding
      } = this.state;
      // if(lowIntensityOverSpeeding <= overSpeedingThreshold || lowIntensityOverSpeeding >= highIntensityOverSpeeding){
      //   return toastr.error('Low intensity must be greter than overspeeding value and less than high intensity overspeeding value');
      // }
      
      // if(highIntensityOverSpeeding <= lowIntensityOverSpeeding){
      //   return toastr.error('High intensity must be greter than medium intensity overspeeding value.');
      // }
      await this
        .props
        .actions
        .UpdateGroup({
          name, image, groupId,
          overSpeedingThreshold, nrConfig, lowIntensityOverSpeeding, highIntensityOverSpeeding
        });

      toastr.success("Updated successfully!");
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  };
  saveFatigueThreshold = async (value) => {
    try {
      await this
        .props
        .actions
        .updateFatigueThreshold({ groupId: this.state.groupId, fatigueThreshold: this.state.fatigueThreshold, restAfterFatigueThreshold: this.state.restAfterFatigueThreshold });

      toastr.success("Updated successfully!");
    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }
  };

  async saveNotificationsSetting() {
    try {
      await this.props.userActions.updateNotificationsSetting({ notifications: this.state.notifications, smsSetting: this.state.sms, emailSetting: this.state.email });
      toastr.success("Settings updated successfully!");
    } catch (err) {
      toastr.error(err);
    }
  }
  async saveConfigSetting() {
    try {
      const { companyId } = JSON.parse(localStorage.getItem("companyInfo"));

      await this.props.actions.updateDrivingScoreSetting({ companyId, drivingScoreSetting: this.state.drivingScoreSetting });
      toastr.success("Configuration updated successfully!");
    } catch (err) {
      toastr.error(err);
    }
  }

  async saveDriverHoursConfig(){
    try {
      const { companyId } = JSON.parse(localStorage.getItem("companyInfo"));

      await this.props.actions.updateDriverHoursConfigSetting({ companyId, driverHours: {start : this.state.sHour,end:this.state.eHour} });
      toastr.success("Configuration updated successfully!");
    } catch (err) {
      toastr.error(err);
    }
  }
  async handleImageUpload(event) {

    try {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        let reader = new FileReader();
        reader.onload = async (e) => {
          this.setState({
            ...this.state,
            image: e.target.result,
            file
          });
          const { name, groupId, overSpeedingThreshold, nrConfig, lowIntensityOverSpeeding,
             highIntensityOverSpeeding
          } = this.state;
          await this
            .props
            .actions
            .UpdateGroup({
              name, image: file, groupId, overSpeedingThreshold,
              lowIntensityOverSpeeding, highIntensityOverSpeeding
            });
          toastr.success("Group image updated successfully!");
        };
        reader.readAsDataURL(file);
      }
    } catch (e) {
      toastr.error(e);
    }

  }
  onStartSliderChangeTemp = (value) => {
    this.setState({...this.state, sHour :value});
  };

  onEndSliderChangeTemp = (value) => {
    this.setState({...this.state, eHour: value});
  };


  render() {
    let { notifications, sms, email, drivingScoreSetting, overSpeedingOpen, fatigueOpen, notificationsOpen,
      drivingScoresOpen, nrToggleOpen,driverHoursOpen
    } = this.state;

    let driverHoursRangeValue = [this.state.sHour, this.state.eHour];
    const companyFeatures = this.props.permissions.companyFeatures;
    const inAppNotif = companyFeatures.indexOf("NOTIFICATIONS:INAPP") !== -1;
    const privateMode = companyFeatures.indexOf("VEHICLE_PRIVATE_MODE") !== -1;
    const smsNotif = companyFeatures.indexOf("NOTIFICATIONS:SMS") !== -1;
    const emailNotif = companyFeatures.indexOf("NOTIFICATIONS:EMAIL") !== -1;
    const anyNotif = companyFeatures.indexOf("NOTIFICATIONS:INAPP") !== -1 || companyFeatures.indexOf("NOTIFICATIONS:SMS") !== -1 || companyFeatures.indexOf("NOTIFICATIONS:EMAIL") !== -1
    const allNotif = companyFeatures.indexOf("NOTIFICATIONS:INAPP") !== -1 && companyFeatures.indexOf("NOTIFICATIONS:SMS") !== -1 && companyFeatures.indexOf("NOTIFICATIONS:EMAIL") !== -1
    return (
      <div>
        <div className="account-center">
          <div className="row group-info">

            <a className='image-input'>

              <label htmlFor="image-input">
                <img src={this.state.image || camera} alt="Company" className='group-image' />
              </label>
              {/* <input
                            accept='image/*'
                            type='file'
                            id="image-input"
                            onChange={this
                                .handleImageUpload
                                .bind(this)} /> */}
            </a>
            <span>
              <p className="group-name">{this.props.group.name}</p>
            </span>
            <span>
              <Link className="edit-icon" to={`/account/update/${this.state.groupId}`}>
                <img src={editIcon} alt="edit" />
              </Link>
            </span>

          </div>

          {(!privateMode || this.state.groupDepthLevel === 3) &&

          <section>

            <div className="notification-rectangle">
              <a id="overSpeedingToggle" onClick={() => this.setState({ ...this.state, overSpeedingOpen: !this.state.overSpeedingOpen })}>
                <img alt="" className={overSpeedingOpen ? 'list-view-transform width18' : 'width18'} src={forwardIcon} />
              </a>
              <p>Overspeeding threshold</p>
              <UncontrolledCollapse toggler="#overSpeedingToggle" className="collapse-nav">
                <ul>
                  <li className="notifications-list">

                    <div className="width100">
                      <label>Select Overspeeding threshold value {this.state.overSpeedingThreshold} km/h</label>
                      {this.state.overSpeedingThreshold !== null ? <Slider className="width100" min={0} max={200} defaultValue={this.state.overSpeedingThreshold} handle={HandleSlider} onChange={this.changeOverspeedingThreshold} /> : ""}

                    </div>

                  </li>


                  {/* <li className="notifications-list">

                    <div className="width100">
                      <label>Select Low Intensity threshold value {this.state.lowIntensityOverSpeeding} km/h</label>
                      {this.state.lowIntensityOverSpeeding !== null ? <Slider className="width100" min={0} max={200} defaultValue={this.state.lowIntensityOverSpeeding} handle={HandleSlider} onChange={this.changeLowOverspeedingThreshold} /> : ""}

                    </div>

                  </li>
                
                  <li className="notifications-list">

                    <div className="width100">
                      <label>Select high threshold value {this.state.highIntensityOverSpeeding} km/h</label>
                      {this.state.highIntensityOverSpeeding !== null ? <Slider className="width100" min={0} max={200} defaultValue={this.state.highIntensityOverSpeeding} handle={HandleSlider} onChange={this.changeHighOverspeedingThreshold} /> : ""}

                    </div>

                  </li> */}
                  <li className="notifications-list">

                    <div>
                      <button className="primary-btn btn blue-bg" onClick={this.saveOverspeedingThreshold.bind(this)}>Save</button>
                    </div>
                  </li>

                </ul>
              </UncontrolledCollapse>

            </div>

          </section>
          }
          {companyFeatures.indexOf("FATIGUE") !== -1 && (!privateMode || this.state.groupDepthLevel === 3) &&

            <section>

              <div className="notification-rectangle">
                <a id="fatigueToggle" onClick={() => this.setState({ ...this.state, fatigueOpen: !this.state.fatigueOpen })}>
                  <img alt="" className={fatigueOpen ? 'list-view-transform width18' : 'width18'} src={forwardIcon} />
                </a>
                <p>Fatigue threshold</p>
                <UncontrolledCollapse toggler="#fatigueToggle" className="collapse-nav">
                  <ul>
                    <li className="notifications-list">

                      <div className="width100">
                        <label>Select Fatigue threshold value {this.state.fatigueThreshold} minutes</label>
                        {this.state.fatigueThreshold !== null ? <Slider className="width100" min={60} max={1000} defaultValue={this.state.fatigueThreshold} handle={HandleSlider} onChange={this.changeFatigueThreshold} /> : ""}

                      </div>

                    </li>
                    <li className="notifications-list">

                      <div className="width100">
                        <label>Select Fatigue Rest threshold value {this.state.restAfterFatigueThreshold} minutes</label>
                        {this.state.restAfterFatigueThreshold !== null ? <Slider className="width100" min={10} max={1000} defaultValue={this.state.restAfterFatigueThreshold} handle={HandleSlider} onChange={this.changerestAfterFatigueThreshold} /> : ""}

                      </div>

                    </li>
                    <li className="notifications-list">

                      <div>
                        <button className="primary-btn btn blue-bg" onClick={this.saveFatigueThreshold.bind(this)}>Save</button>
                      </div>
                    </li>

                  </ul>
                </UncontrolledCollapse>

              </div>

            </section>
          }
          <section>

            <div className="notification-rectangle">
              <a id="nrToggle" onClick={() => this.setState({ ...this.state, nrToggleOpen: !this.state.nrToggleOpen })}>
                <img alt="" className={nrToggleOpen ? 'list-view-transform width18' : 'width18'} src={forwardIcon} />
              </a>
              <p>Not Responding threshold</p>
              <UncontrolledCollapse toggler="#nrToggle" className="collapse-nav">
                <ul>
                  <li className="notifications-list">

                    <div className="width100">
                      <label>Select NR threshold value {this.state.nrConfig} Hours</label>
                      {this.state.nrConfig !== null ? <Slider className="width100" min={2} max={50} defaultValue={this.state.nrConfig} handle={HandleSlider} onChange={this.changeNrThreshold} /> : ""}

                    </div>

                  </li>
                  <li className="notifications-list">

                    <div>
                      <button className="primary-btn btn blue-bg" onClick={this.saveOverspeedingThreshold.bind(this)}>Save</button>
                    </div>
                  </li>

                </ul>
              </UncontrolledCollapse>

            </div>

          </section>

          <section>
            {anyNotif &&
              <div className="notification-rectangle">
                <a id="notificationsToggle" onClick={() => this.setState({ ...this.state, notificationsOpen: !this.state.notificationsOpen })}>
                  <img alt="" className={notificationsOpen ? 'list-view-transform width18' : 'width18'} src={forwardIcon} />
                </a>
                <p>Notifications</p>

                <UncontrolledCollapse toggler="#notificationsToggle" className="collapse-nav">
                  <ul>
                    <div className="notif-label">
                      <div></div>
                      {inAppNotif &&
                        <label>InApp</label>}
                      {smsNotif &&
                        <label>SMS</label>
                      }
                      {emailNotif &&
                        <label>Email</label>
                      }
                      {allNotif &&
                        <label>All</label>
                      }


                    </div>
                    <li className="notifications-list">
                      <span>Complaint </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          </div>
                      }
                      {smsNotif &&
                        <div className="items-tog">
                          </div>
                      }
                      {emailNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={email.complaint || false}
                            onToggle={this.emailToggle.bind(this, "complaint")}
                          /></div>
                      }
                      {allNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={( email.complaint) || false}
                            onToggle={this.allToggle.bind(this, "complaint")}
                          /></div>
                      }
                    </li>
                    <li className="notifications-list">
                      <span>TimeCheck After Threshold </span>
                      {inAppNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={notifications.timeCheck || false}
                            onToggle={this.NotificationToggle.bind(this, "timeCheck")}
                          /></div>
                      }
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.timeCheck || false}
                            onToggle={this.smsToggle.bind(this, "timeCheck")}
                          /></div>
                      }
                      {emailNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={email.timeCheck || false}
                            onToggle={this.emailToggle.bind(this, "timeCheck")}
                          /></div>
                      }
                      {allNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={(notifications.timeCheck && sms.timeCheck && email.timeCheck) || false}
                            onToggle={this.allToggle.bind(this, "timeCheck")}
                          /></div>
                      }

                    </li>
                    <li className="notifications-list">
                      <span>Ignition On Restricted Hours</span>
                      {inAppNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={notifications.ignitionOnRestrictedHours || false}
                            onToggle={this.NotificationToggle.bind(this, "ignitionOnRestrictedHours")}
                          /></div>
                      }
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.ignitionOnRestrictedHours || false}
                            onToggle={this.smsToggle.bind(this, "ignitionOnRestrictedHours")}
                          /></div>
                      }
                      {emailNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={email.ignitionOnRestrictedHours || false}
                            onToggle={this.emailToggle.bind(this, "ignitionOnRestrictedHours")}
                          /></div>
                      }
                      {allNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={(notifications.ignitionOnRestrictedHours && sms.ignitionOnRestrictedHours && email.ignitionOnRestrictedHours) || false}
                            onToggle={this.allToggle.bind(this, "ignitionOnRestrictedHours")}
                          /></div>
                      }

                    </li>
                    <li className="notifications-list">
                      <span>Vehicle Moving Restricted Hours</span>
                      {inAppNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={notifications.vehicleMovingRestrictedHours || false}
                            onToggle={this.NotificationToggle.bind(this, "vehicleMovingRestrictedHours")}
                          /></div>
                      }
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.vehicleMovingRestrictedHours || false}
                            onToggle={this.smsToggle.bind(this, "vehicleMovingRestrictedHours")}
                          /></div>
                      }
                      {emailNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={email.vehicleMovingRestrictedHours || false}
                            onToggle={this.emailToggle.bind(this, "vehicleMovingRestrictedHours")}
                          /></div>
                      }
                      {allNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={(notifications.vehicleMovingRestrictedHours && sms.vehicleMovingRestrictedHours && email.vehicleMovingRestrictedHours) || false}
                            onToggle={this.allToggle.bind(this, "vehicleMovingRestrictedHours")}
                          /></div>
                      }

                    </li>
                    <li className="notifications-list">
                      <span>Harsh acceleration</span>
                      {inAppNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={notifications.harshAcceleration || false}
                            onToggle={this.NotificationToggle.bind(this, "harshAcceleration")}
                          /></div>
                      }
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.harshAcceleration || false}
                            onToggle={this.smsToggle.bind(this, "harshAcceleration")}
                          /></div>
                      }
                      {emailNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={email.harshAcceleration || false}
                            onToggle={this.emailToggle.bind(this, "harshAcceleration")}
                          /></div>
                      }
                      {allNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={(notifications.harshAcceleration && sms.harshAcceleration && email.harshAcceleration) || false}
                            onToggle={this.allToggle.bind(this, "harshAcceleration")}
                          /></div>
                      }

                    </li>

                    <li className="notifications-list">
                      <span>Sudden brakes</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.harshBrake || false}
                            onToggle={this.NotificationToggle.bind(this, "harshBrake")}
                          /></div>
                      }{smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.harshBrake || false}
                            onToggle={this.smsToggle.bind(this, "harshBrake")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.harshBrake || false}
                                onToggle={this.emailToggle.bind(this, "harshBrake")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.harshBrake && sms.harshBrake && email.harshBrake) || false}
                                    onToggle={this.allToggle.bind(this, "harshBrake")}
                                  /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Sharp turn</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.sharpTurn || false}
                            onToggle={this.NotificationToggle.bind(this, "sharpTurn")}
                          /></div>
                      }
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.sharpTurn || false}
                            onToggle={this.smsToggle.bind(this, "sharpTurn")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.sharpTurn || false}
                                onToggle={this.emailToggle.bind(this, "sharpTurn")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.sharpTurn && sms.sharpTurn && email.sharpTurn) || false}
                                    onToggle={this.allToggle.bind(this, "sharpTurn")}
                                  /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Vehicle Idle</span>
                      {inAppNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={notifications.vehicleIdle || false}
                            onToggle={this.NotificationToggle.bind(this, "vehicleIdle")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.vehicleIdle || false}
                            onToggle={this.smsToggle.bind(this, "vehicleIdle")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.vehicleIdle || false}
                                onToggle={this.emailToggle.bind(this, "vehicleIdle")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.vehicleIdle && sms.vehicleIdle && email.vehicleIdle) || false}
                                    onToggle={this.allToggle.bind(this, "vehicleIdle")}
                                  /></div>}

                    </li>
                  
                    <li className="notifications-list">
                      <span>Impact</span>
                      {inAppNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={notifications.impact || false}
                            onToggle={this.NotificationToggle.bind(this, "impact")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.impact || false}
                            onToggle={this.smsToggle.bind(this, "impact")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.impact || false}
                                onToggle={this.emailToggle.bind(this, "impact")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.impact && sms.impact && email.impact) || false}
                                    onToggle={this.allToggle.bind(this, "impact")}
                                  /></div>}

                    </li>
                  
                    <li className="notifications-list">
                      <span>Overspeeding</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.overSpeeding || false}
                            onToggle={this.NotificationToggle.bind(this, "overSpeeding")}
                          /></div>}{smsNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={sms.overSpeeding || false}
                                onToggle={this.smsToggle.bind(this, "overSpeeding")}
                              /></div>} {emailNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={email.overSpeeding || false}
                                    onToggle={this.emailToggle.bind(this, "overSpeeding")}
                                  /></div>}{allNotif &&
                                    <div className="items-tog">

                                      <ToggleButton
                                        colors={colors}
                                        value={(notifications.overSpeeding && sms.overSpeeding && email.overSpeeding) || false}
                                        onToggle={this.allToggle.bind(this, "overSpeeding")}
                                      /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Unfasten Seatbelt</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.unfasten_seatbelt || false}
                            onToggle={this.NotificationToggle.bind(this, "unfasten_seatbelt")}
                          /></div>}{smsNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={sms.unfasten_seatbelt || false}
                                onToggle={this.smsToggle.bind(this, "unfasten_seatbelt")}
                              /></div>} {emailNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={email.unfasten_seatbelt || false}
                                    onToggle={this.emailToggle.bind(this, "unfasten_seatbelt")}
                                  /></div>}{allNotif &&
                                    <div className="items-tog">

                                      <ToggleButton
                                        colors={colors}
                                        value={(notifications.unfasten_seatbelt && sms.unfasten_seatbelt && email.unfasten_seatbelt) || false}
                                        onToggle={this.allToggle.bind(this, "unfasten_seatbelt")}
                                      /></div>}
                    </li>



                    <li className="notifications-list">
                      <span>Ignition</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.ignitionOn || false}
                            onToggle={this.NotificationToggle.bind(this, "ignitionOn")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.ignitionOn || false}
                            onToggle={this.smsToggle.bind(this, "ignitionOn")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.ignitionOn || false}
                                onToggle={this.emailToggle.bind(this, "ignitionOn")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.ignitionOn && sms.ignitionOn && email.ignitionOn) || false}
                                    onToggle={this.allToggle.bind(this, "ignitionOn")}
                                  /></div>}
                    </li>

                    <li className="notifications-list">
                      <span>Vehicle moving</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.vehicleMoving || false}
                            onToggle={this.NotificationToggle.bind(this, "vehicleMoving")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.vehicleMoving || false}
                            onToggle={this.smsToggle.bind(this, "vehicleMoving")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.vehicleMoving || false}
                                onToggle={this.emailToggle.bind(this, "vehicleMoving")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.vehicleMoving && sms.vehicleMoving && email.vehicleMoving) || false}
                                    onToggle={this.allToggle.bind(this, "vehicleMoving")}
                                  /></div>}
                    </li>

                    <li className="notifications-list">
                      <span>Vehicle parked</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.vehicleParked || false}
                            onToggle={this.NotificationToggle.bind(this, "vehicleParked")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.vehicleParked || false}
                            onToggle={this.smsToggle.bind(this, "vehicleParked")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.vehicleParked || false}
                                onToggle={this.emailToggle.bind(this, "vehicleParked")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.vehicleParked && sms.vehicleParked && email.vehicleParked) || false}
                                    onToggle={this.allToggle.bind(this, "vehicleParked")}
                                  /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Vehicle offline</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.vehicleOffline || false}
                            onToggle={this.NotificationToggle.bind(this, "vehicleOffline")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.vehicleOffline || false}
                            onToggle={this.smsToggle.bind(this, "vehicleOffline")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.vehicleOffline || false}
                                onToggle={this.emailToggle.bind(this, "vehicleOffline")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.vehicleOffline && sms.vehicleOffline && email.vehicleOffline) || false}
                                    onToggle={this.allToggle.bind(this, "vehicleOffline")}
                                  /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Vehicle online</span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.vehicleOnline || false}
                            onToggle={this.NotificationToggle.bind(this, "vehicleOnline")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.vehicleOnline || false}
                            onToggle={this.smsToggle.bind(this, "vehicleOnline")}
                          /></div>
                      } {emailNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={email.vehicleOnline || false}
                            onToggle={this.emailToggle.bind(this, "vehicleOnline")}
                          /></div>}{allNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={(notifications.vehicleOnline && sms.vehicleOnline && email.vehicleOnline) || false}
                                onToggle={this.allToggle.bind(this, "vehicleOnline")}
                              /></div>}
                    </li>
                    {companyFeatures.indexOf("GEO-FENCE") !== -1 &&
                      <li className="notifications-list">
                        <span>GeoFence Entry</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.geoFenceEntry || false}
                              onToggle={this.NotificationToggle.bind(this, "geoFenceEntry")}
                            /></div>}
                        {smsNotif &&
                          <div className="items-tog">

                            <ToggleButton
                              colors={colors}
                              value={sms.geoFenceEntry || false}
                              onToggle={this.smsToggle.bind(this, "geoFenceEntry")}
                            /></div>} {emailNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={email.geoFenceEntry || false}
                                  onToggle={this.emailToggle.bind(this, "geoFenceEntry")}
                                /></div>}{allNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={(notifications.geoFenceEntry && sms.geoFenceEntry && email.geoFenceEntry) || false}
                                      onToggle={this.allToggle.bind(this, "geoFenceEntry")}
                                    /></div>}
                      </li>}
                    {companyFeatures.indexOf("GEO-FENCE") !== -1 &&
                      <li className="notifications-list">
                        <span>GeoFence Exit</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.geoFenceExit || false}
                              onToggle={this.NotificationToggle.bind(this, "geoFenceExit")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.geoFenceExit || false}
                                  onToggle={this.smsToggle.bind(this, "geoFenceExit")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.geoFenceExit || false}
                                      onToggle={this.emailToggle.bind(this, "geoFenceExit")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">
                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.geoFenceExit && sms.geoFenceExit && email.geoFenceExit) || false}
                                          onToggle={this.allToggle.bind(this, "geoFenceExit")} />
                                      </div>}
                      </li>
                    }
                    {companyFeatures.indexOf("ROSTER") !== -1 &&
                      <li className="notifications-list">
                        <span>Roster Entry</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.rosterEntry || false}
                              onToggle={this.NotificationToggle.bind(this, "rosterEntry")}
                            /></div>}
                        {smsNotif &&
                          <div className="items-tog">

                            <ToggleButton
                              colors={colors}
                              value={sms.rosterEntry || false}
                              onToggle={this.smsToggle.bind(this, "rosterEntry")}
                            /></div>} {emailNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={email.rosterEntry || false}
                                  onToggle={this.emailToggle.bind(this, "rosterEntry")}
                                /></div>}{allNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={(notifications.rosterEntry && sms.rosterEntry && email.rosterEntry) || false}
                                      onToggle={this.allToggle.bind(this, "rosterEntry")}
                                    /></div>}
                      </li>}
                    {companyFeatures.indexOf("ROSTER") !== -1 &&
                      <li className="notifications-list">
                        <span>Roster Exit</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.rosterExit || false}
                              onToggle={this.NotificationToggle.bind(this, "rosterExit")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.rosterExit || false}
                                  onToggle={this.smsToggle.bind(this, "rosterExit")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.rosterExit || false}
                                      onToggle={this.emailToggle.bind(this, "rosterExit")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">
                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.rosterExit && sms.rosterExit && email.rosterExit) || false}
                                          onToggle={this.allToggle.bind(this, "rosterExit")} />
                                      </div>}
                      </li>
                    }
                    {companyFeatures.indexOf("ROUTE-FENCE") !== -1 &&
                      <li className="notifications-list">
                        <span>RouteFence Entry</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.routeFenceEntry || false}
                              onToggle={this.NotificationToggle.bind(this, "routeFenceEntry")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.routeFenceEntry || false}
                                  onToggle={this.smsToggle.bind(this, "routeFenceEntry")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.routeFenceEntry || false}
                                      onToggle={this.emailToggle.bind(this, "routeFenceEntry")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">

                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.routeFenceEntry && sms.routeFenceEntry && email.routeFenceEntry) || false}
                                          onToggle={this.allToggle.bind(this, "routeFenceEntry")}
                                        /></div>}
                      </li>
                    }
                    {companyFeatures.indexOf("ROUTE-FENCE") !== -1 &&

                      <li className="notifications-list">
                        <span>RouteFence Exit</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.routeFenceExit || false}
                              onToggle={this.NotificationToggle.bind(this, "routeFenceExit")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.routeFenceExit || false}
                                  onToggle={this.smsToggle.bind(this, "routeFenceExit")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.routeFenceExit || false}
                                      onToggle={this.emailToggle.bind(this, "routeFenceExit")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">
                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.routeFenceExit && sms.routeFenceExit && email.routeFenceExit) || false}
                                          onToggle={this.allToggle.bind(this, "routeFenceExit")} />
                                      </div>}
                      </li>
                    }
                    {companyFeatures.indexOf("ENGINE:KILL") !== -1 &&

                      <li className="notifications-list">

                        <span>Engine Kill</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.engineKilled || false}
                              onToggle={this.NotificationToggle.bind(this, "engineKilled")}
                            />
                          </div>}{smsNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={sms.engineKilled || false}
                                onToggle={this.smsToggle.bind(this, "engineKilled")}
                              /></div>} {emailNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={email.engineKilled || false}
                                    onToggle={this.emailToggle.bind(this, "engineKilled")}
                                  /></div>}{allNotif &&
                                    <div className="items-tog">

                                      <ToggleButton
                                        colors={colors}
                                        value={(notifications.engineKilled && sms.engineKilled && email.engineKilled) || false}
                                        onToggle={this.allToggle.bind(this, "engineKilled")}
                                      /></div>}
                      </li>
                    }
                    {companyFeatures.indexOf("ENGINE:KILL") !== -1 &&
                      <li className="notifications-list">
                        <span>Engine Release</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.engineReleased || false}
                              onToggle={this.NotificationToggle.bind(this, "engineReleased")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.engineReleased || false}
                                  onToggle={this.smsToggle.bind(this, "engineReleased")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.engineReleased || false}
                                      onToggle={this.emailToggle.bind(this, "engineReleased")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">

                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.engineReleased && sms.engineReleased && email.engineReleased) || false}
                                          onToggle={this.allToggle.bind(this, "engineReleased")}
                                        /></div>}
                      </li>
                    }
                    {companyFeatures.indexOf("MAINTENANCE") !== -1 &&
                      <li className="notifications-list">
                        <span>Oil Change</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.oilChange || false}
                              onToggle={this.NotificationToggle.bind(this, "oilChange")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.oilChange || false}
                                  onToggle={this.smsToggle.bind(this, "oilChange")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.oilChange || false}
                                      onToggle={this.emailToggle.bind(this, "oilChange")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">

                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.oilChange && sms.oilChange && email.oilChange) || false}
                                          onToggle={this.allToggle.bind(this, "oilChange")}
                                        /></div>}
                      </li>
                    }
                    {companyFeatures.indexOf("MAINTENANCE") !== -1 &&

                      <li className="notifications-list">
                        <span>Tyre Change</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.tyreChange || false}
                              onToggle={this.NotificationToggle.bind(this, "tyreChange")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.tyreChange || false}
                                  onToggle={this.smsToggle.bind(this, "tyreChange")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.tyreChange || false}
                                      onToggle={this.emailToggle.bind(this, "tyreChange")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">

                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.tyreChange && sms.tyreChange && email.tyreChange) || false}
                                          onToggle={this.allToggle.bind(this, "tyreChange")}
                                        /></div>}
                      </li>
                    }
                    {companyFeatures.indexOf("FATIGUE") !== -1 &&

                      <li className="notifications-list">
                        <span>Fatigue</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.fatigue || false}
                              onToggle={this.NotificationToggle.bind(this, "fatigue")}
                            /></div>}{smsNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={sms.fatigue || false}
                                  onToggle={this.smsToggle.bind(this, "fatigue")}
                                /></div>} {emailNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={email.fatigue || false}
                                      onToggle={this.emailToggle.bind(this, "fatigue")}
                                    /></div>}{allNotif &&
                                      <div className="items-tog">

                                        <ToggleButton
                                          colors={colors}
                                          value={(notifications.fatigue && sms.fatigue && email.fatigue) || false}
                                          onToggle={this.allToggle.bind(this, "fatigue")}
                                        /></div>}
                      </li>
                    }
                    {companyFeatures.indexOf("FATIGUE") !== -1 &&

                      <li className="notifications-list">
                        <span>Fatigue Rest</span>
                        {inAppNotif &&
                          <div className="items-tog">
                            <ToggleButton
                              colors={colors}
                              value={notifications.restFatigue || false}
                              onToggle={this.NotificationToggle.bind(this, "restFatigue")}
                            /></div>}
                        {smsNotif &&
                          <div className="items-tog">

                            <ToggleButton
                              colors={colors}
                              value={sms.restFatigue || false}
                              onToggle={this.smsToggle.bind(this, "restFatigue")}
                            /></div>} {emailNotif &&
                              <div className="items-tog">

                                <ToggleButton
                                  colors={colors}
                                  value={email.restFatigue || false}
                                  onToggle={this.emailToggle.bind(this, "restFatigue")}
                                /></div>}{allNotif &&
                                  <div className="items-tog">

                                    <ToggleButton
                                      colors={colors}
                                      value={(notifications.restFatigue && sms.restFatigue && email.restFatigue) || false}
                                      onToggle={this.allToggle.bind(this, "restFatigue")}
                                    /></div>}
                      </li>}

                    <li className="notifications-list">
                      <span>Low Fuel </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.lowFuel || false}
                            onToggle={this.NotificationToggle.bind(this, "lowFuel")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.lowFuel || false}
                            onToggle={this.smsToggle.bind(this, "lowFuel")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.lowFuel || false}
                                onToggle={this.emailToggle.bind(this, "lowFuel")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.lowFuel && sms.lowFuel && email.lowFuel) || false}
                                    onToggle={this.allToggle.bind(this, "lowFuel")}
                                  /></div>}
                    </li>


                    <li className="notifications-list">
                      <span>High RPM </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.highRpm || false}
                            onToggle={this.NotificationToggle.bind(this, "highRpm")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.highRpm || false}
                            onToggle={this.smsToggle.bind(this, "highRpm")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.highRpm || false}
                                onToggle={this.emailToggle.bind(this, "highRpm")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.highRpm && sms.highRpm && email.highRpm) || false}
                                    onToggle={this.allToggle.bind(this, "highRpm")}
                                  /></div>}
                    </li>

                    <li className="notifications-list">
                      <span>Engine Heatup </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.engineHeatup || false}
                            onToggle={this.NotificationToggle.bind(this, "engineHeatup")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.engineHeatup || false}
                            onToggle={this.smsToggle.bind(this, "engineHeatup")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.engineHeatup || false}
                                onToggle={this.emailToggle.bind(this, "engineHeatup")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.engineHeatup && sms.engineHeatup && email.engineHeatup) || false}
                                    onToggle={this.allToggle.bind(this, "engineHeatup")}
                                  /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Door Status </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.doorStatus || false}
                            onToggle={this.NotificationToggle.bind(this, "doorStatus")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.doorStatus || false}
                            onToggle={this.smsToggle.bind(this, "doorStatus")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.doorStatus || false}
                                onToggle={this.emailToggle.bind(this, "doorStatus")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.doorStatus && sms.doorStatus && email.doorStatus) || false}
                                    onToggle={this.allToggle.bind(this, "doorStatus")}
                                  /></div>}
                    </li>
                
                    <li className="notifications-list">
                      <span>Panic Button </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.panic_button || false}
                            onToggle={this.NotificationToggle.bind(this, "panic_button")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.panic_button || false}
                            onToggle={this.smsToggle.bind(this, "panic_button")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.panic_button || false}
                                onToggle={this.emailToggle.bind(this, "panic_button")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.panic_button && sms.panic_button && email.panic_button) || false}
                                    onToggle={this.allToggle.bind(this, "panic_button")}
                                  /></div>}
                    </li>
                    <li className="notifications-list">
                      <span>Unknown Driver </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.unknownDriver || false}
                            onToggle={this.NotificationToggle.bind(this, "unknownDriver")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.unknownDriver || false}
                            onToggle={this.smsToggle.bind(this, "unknownDriver")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.unknownDriver || false}
                                onToggle={this.emailToggle.bind(this, "unknownDriver")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.unknownDriver && sms.unknownDriver && email.unknownDriver) || false}
                                    onToggle={this.allToggle.bind(this, "unknownDriver")}
                                  /></div>}
                    </li>

                    <li className="notifications-list">
                      <span>Jamming </span>
                      {inAppNotif &&
                        <div className="items-tog">
                          <ToggleButton
                            colors={colors}
                            value={notifications.jamming || false}
                            onToggle={this.NotificationToggle.bind(this, "jamming")}
                          /></div>}
                      {smsNotif &&
                        <div className="items-tog">

                          <ToggleButton
                            colors={colors}
                            value={sms.jamming || false}
                            onToggle={this.smsToggle.bind(this, "jamming")}
                          /></div>} {emailNotif &&
                            <div className="items-tog">

                              <ToggleButton
                                colors={colors}
                                value={email.jamming || false}
                                onToggle={this.emailToggle.bind(this, "jamming")}
                              /></div>}{allNotif &&
                                <div className="items-tog">

                                  <ToggleButton
                                    colors={colors}
                                    value={(notifications.jamming && sms.jamming && email.jamming) || false}
                                    onToggle={this.allToggle.bind(this, "jamming")}
                                  /></div>}
                    </li>

                    
                    



                    <li className="notifications-list">
                      <span style={{ width: '750px' }}>Warning Popups</span>
                      <div className="items-tog">
                        <ToggleButton
                          colors={colors}
                          value={notifications.warningPopup || false}
                          onToggle={this.NotificationToggle.bind(this, "warningPopup")}
                        /></div>
                    </li>
                    <li className="notifications-list">
                      <span style={{ width: '750px' }}>Notification Sound</span>
                      <div className="items-tog">
                        <ToggleButton
                          colors={colors}
                          value={notifications.buzzer || false}
                          onToggle={this.NotificationToggle.bind(this, "buzzer")}
                        /></div>
                    </li>
                    <li className="notifications-list">
                      <span></span>
                      <div className="items-tog">
                        <button className="primary-btn btn blue-bg" onClick={this.saveNotificationsSetting.bind(this)}>Save</button>
                      </div>
                    </li>

                  </ul>
                </UncontrolledCollapse>

              </div>
            }
          </section>
          <section>
            {companyFeatures.indexOf("DRIVER:MANAGEMENT") !== -1 &&

              <div className="notification-rectangle">
                <a id="scoreSettingToggle" onClick={() => this.setState({ ...this.state, drivingScoresOpen: !this.state.drivingScoresOpen })}>
                  <img alt="" className={drivingScoresOpen ? 'list-view-transform width18' : 'width18'} src={forwardIcon} />
                </a>
                <p>Driving Score Configuration</p>
                <UncontrolledCollapse toggler="#scoreSettingToggle" className="collapse-nav">
                  <ul>
                    <li className="notifications-list">
                      <span>Harsh acceleration</span>
                      <div>
                        <ToggleButton
                          colors={colors}
                          value={drivingScoreSetting.harshAcceleration || false}
                          onToggle={this.scoreConfigToggle.bind(this, "harshAcceleration")}
                        /></div>
                    </li>

                    <li className="notifications-list">
                      <span>Sudden brakes</span>
                      <div>
                        <ToggleButton
                          colors={colors}
                          value={drivingScoreSetting.harshBrake || false}
                          onToggle={this.scoreConfigToggle.bind(this, "harshBrake")}
                        /></div>
                    </li>
                    <li className="notifications-list">
                      <span>Sharp turn</span>
                      <div>
                        <ToggleButton
                          colors={colors}
                          value={drivingScoreSetting.sharpTurn || false}
                          onToggle={this.scoreConfigToggle.bind(this, "sharpTurn")}
                        /></div>
                    </li>
                    <li className="notifications-list">
                      <span>Overspeeding</span>
                      <div>
                        <ToggleButton
                          colors={colors}
                          value={drivingScoreSetting.overSpeeding || false}
                          onToggle={this.scoreConfigToggle.bind(this, "overSpeeding")}
                        /></div>
                    </li>
                    <li className="notifications-list">
                      <span>Unfasten Seatbelt</span>
                      <div>
                        <ToggleButton
                          colors={colors}
                          value={drivingScoreSetting.unFastenSeatBelt || false}
                          onToggle={this.scoreConfigToggle.bind(this, "unFastenSeatBelt")}
                        /></div>
                    </li>

                    <li className="notifications-list">
                      <span></span>
                      <div>
                        <button className="primary-btn btn blue-bg" onClick={this.saveConfigSetting.bind(this)}>Save</button>
                      </div>
                    </li>
                  </ul>
                </UncontrolledCollapse>

              </div>
            }
          </section>
          <section>
            {companyFeatures.indexOf("DRIVERHOURS") !== -1 &&

              <div className="notification-rectangle">
                <a id="driverHoursToggle" onClick={() => this.setState({ ...this.state, driverHoursOpen: !this.state.driverHoursOpen })}>
                  <img alt="" className={driverHoursOpen ? 'list-view-transform width18' : 'width18'} src={forwardIcon} />
                </a>
                <p>Driving Hours Configuration</p>
                <UncontrolledCollapse toggler="#driverHoursToggle" className="collapse-nav">
                  <ul>
                  <li className="notifications-list">
                    <div className="width100">
                      <div style={{display:'flex', flexDirection:'column'}}>
                        <label>Starting Hour : {this.state.sHour}</label>
                      </div>
                      <Slider 
                        className="width100" 
                        min={0} 
                        max={23} 
                        value={this.state.sHour} 
                        handle={HandleSlider} 
                        onChange={this.onStartSliderChangeTemp.bind(this)}
                      />
                      <label>Ending Hour : {this.state.eHour}</label>
                      <Slider 
                        className="width100" 
                        min={0} 
                        max={23} 
                        value={this.state.eHour} 
                        handle={HandleSlider} 
                        onChange={this.onEndSliderChangeTemp.bind(this)}
                      />
                    </div>
                  </li>
                    <li className="notifications-list">
                      <span></span>
                      <div>
                        <button className="primary-btn btn blue-bg" onClick={this.saveDriverHoursConfig.bind(this)}>Save</button>
                      </div>
                    </li>
                  </ul>
                </UncontrolledCollapse>

              </div>
            }
          </section>
        </div>
      </div>
    );
  }
}

GroupAccount.propTypes = {};
GroupAccount.defaultProps = {};

const mapStateToProps = state => ({
  group: state.groups.group, notifications: state.users.notificationsSetting,
  smsSetting: state.users.smsSetting, emailSetting: state.users.emailSetting,
  drivingScoreSetting: state.groups.scoreSetting,driverHours: state.groups.driverHours,
  permissions: state.permissions
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Group.creators, dispatch),
  userActions: bindActionCreators(User.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupAccount);
