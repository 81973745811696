import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import * as R from 'ramda';
import ListMap from './listMap';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../../assets/img/calender.png';
import { subDays } from 'date-fns';
import { isEmptyValue, isAlphaNumeric } from '../../../validations/validator';
import { Popover, PopoverBody } from 'reactstrap';
import Select from "react-select-nested-group";
import TelcoSite from '../../../ducks/TelcoSite';
import CSVReader from 'react-csv-reader';
import moment from 'moment';
import { map } from "lodash";
import toastr from "toastr";
import { Spinner } from '../../common/Spinner';
import '../index.css';

const TelcoList = (props) => {

    const [center] = useState([73.13915736973286, 33.59492064226822])
    const [popoverOpen, setPopoverOpen] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState([])
    const [searchVehicleValues, setSearchVehicleValues] = React.useState([])
    const [vehiclePath, setvehiclePath] = useState([])
    const [tourData, setTourData] = useState();
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [geofences, setGeofences] = useState(null);
    const [loading , setLoading] = useState(false);

    const [dateRangePicker, setDateRange] = React.useState({
        selection: {
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
            key: 'selection',
        },
    },)

    useEffect(()=>{
      if(geofences!== null){
        makeUploadCall()
      }
    } , [geofences])


    const fetchOptions = async () => {
        await props.action.getThings();
    }

    const makeUploadCall = async () => {
        try {
            let companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
            setLoading(true);
            await props.action.bulkUpload({ geofences: geofences, groupId: companyInfo.groupId });
            toastr.success("Geofences will be imported in a while!");
            setLoading(false);
            setShowBulkUpload(false);
            setGeofences(null);
            
        } catch (e) {
            setLoading(false);
            toastr.error(e.response ? e.response.data.message : e);
        }
    }

    const fetchSites = async () => {
        await props.action.getTelcoSites()
    }

    const handleRangeChange = async (payload) => {
        setDateRange({ ...payload })
    }

    const handleDarkSideForce = async () => {
        toastr.error('Invalid file format, csv file is required!');
    }

    const handleFileUpload = async (data) => {
        if (data && data.length) {
            if (data.length <= 1) {
                return toastr.error('No date found.');
            }
            let heading = data[0];

            if (heading[0] !== 'Name' || heading[1] !== 'Description') {
                return toastr.error('Invalid Columns in csv!');
            }

            let geofencesImport = [];
            _.forEach(data, (item, index) => {

                const isAllEmpty = R.all(R.isEmpty, item);

                if (index !== 0  && !isAllEmpty) {

                    let obj = { name: '', description: '', radius: 200 ,  valid: true };
                    _.forEach(item, (value, valueIndex) => {
                        if (valueIndex === 0) {
                            obj.name = _.trim(value);

                            if (isEmptyValue(obj.name)) {
                                toastr.error('Provide value for name');
                                // obj.valid = false;
                            }
                            // else if (!isAlphaNumeric(obj.name)) {
                            //   toastr.error('Geofence name must be alphanumeric');
                            //   obj.valid = false;
                            // }
                            // else if (obj.name.length > 100) {
                            //   toastr.error('Max 100 characters are allowed in name');
                            //   // obj.valid = false;
                            // }

                        }
                        if (valueIndex === 1 && obj.valid) {
                            obj.description = _.trim(value);
                            if (!isAlphaNumeric(obj.description)) {
                                toastr.error('Description must be alphanumeric');
                                // obj.valid = false;
                            }
                            else if (obj.description.length > 500) {
                                toastr.error('Max 500 characters are allowed in description.');
                                // obj.valid = false;
                            }
                        }


                        if (valueIndex === 2 && obj.valid) {
                            obj.latlng = _.trim(value);
                            if (!obj.latlng) {
                                toastr.error('latlng not provided');
                                // obj.valid = false;
                            }

                        }

                    })
                    if (obj.valid === true) {
                        
                        geofencesImport.push(obj);
                    }
                }
            });
            if (geofencesImport.length) {
                setGeofences(geofencesImport);
            }

        }
        
    }

    const fetchVehiclePath = async () => {

        const start = dateRangePicker.selection.startDate;
        const end = dateRangePicker.selection.endDate;
        // const difference = moment(start).diff(moment(end), 'minutes');
        let interval;
        if (moment(start).isSame(moment(end), 'day')) {
            // If they are the same, set the end date to be 24 hours later
            const adjustedEnd = moment(end).add(24, 'hours').toISOString();
            interval = {
                "startDate": moment(start).format(),
                "endDate": moment(adjustedEnd).format()
            };
        }
        else {
            interval = { "startDate": moment(start).format(), "endDate": moment(end).format() };
        }


        // this.setState({ ...this.state, mapLoading: true });

        const path = await props.action.getVehiclePath(JSON.stringify({ interval }), searchValue.value) || {};
        if (path.length === 0) {
            toastr.error("No distance covered during selected time frame")
        }
        let data = {
            interval: JSON.stringify({ interval }),
            id: searchValue.value,
            geofenceIds: []
        }
        setvehiclePath(path)
        const siteTour = await props.action.getTelcoSiteTour(data)
        setTourData(siteTour)
    }


    const toggle = (e) => {
        setPopoverOpen(!popoverOpen)
    };
    useEffect(() => {
        fetchOptions();
        fetchSites();
    }, []);

    useEffect(() => {
        setSearchVehicleValues(map(props?.things, (item) => ({
            value: item._id,
            label: item.plateNumber || item.imei.substr(-8),
            item: item,
        })))
    }, [props.things]);

    const filterVehicle = async (value) => {
        setSearchValue({ ...value });
    };

    const navigateToCreateGeoFence = () => {
        props.history.push('/addTelcoSite');
    }

    const loader = <Spinner key='1' />

    return (
        <>
            <div className='row m-b-10'>

                <div className="col-md-3">
                    <Select
                        name="form-field-name"
                        isSearchable={true}
                        placeholder="Search Vehicle"
                        onChange={filterVehicle}
                        value={searchValue}
                        options={searchVehicleValues}
                    />
                </div>

                <div className="col-md-3">
                    <div className="float-right">
                        <a onClick={toggle} id="Popover1">
                            <img className="rag-picker" src={calenderIcon} alt="" />
                        </a>
                        <Popover placement="bottom" isOpen={popoverOpen} toggle={toggle} target="Popover1">
                            <PopoverBody>
                                <div>
                                    <DateRangePicker
                                        onChange={handleRangeChange}
                                        showSelectionPreview={false}
                                        moveRangeOnFirstSelection={false}
                                        className={'PreviewArea'}
                                        months={1}
                                        ranges={[dateRangePicker.selection]}
                                        direction="horizontal"
                                        maxDate={new Date()}
                                    />
                                </div>
                            </PopoverBody>
                        </Popover>
                        <button onClick={fetchVehiclePath} className='btn rag-primary-btn rag-score-filter-btn' >Filter
                        </button>
                    </div>
                </div>
                <div className='col-md-6'>
                    <button onClick={() => {
                        document.getElementById('UniqueObiWan').click();
                    }} className='btn rag-primary-btn rag-score-filter-btn' >Bulk Upload
                    </button>
                    <button onClick={navigateToCreateGeoFence} className='btn rag-primary-btn rag-score-filter-btn' >Add Site
                    </button>
                </div>
                {!showBulkUpload && <CSVReader
                    cssClass='things-input-reader'
                    accept='.csv'
                    onFileLoaded={handleFileUpload}
                    onError={handleDarkSideForce}
                    inputId='UniqueObiWan'
                />
                }
            </div>
            {loading ? [loader] : (
                <ListMap
                center={{
                    lat: center[1],
                    lng: center[0],
                }}
                telcoSites={props.sites}
                startCord={vehiclePath.length > 1 ? vehiclePath[0] : null}
                endCord={vehiclePath.length > 1 ? vehiclePath[vehiclePath.length - 1] : null}
                path={vehiclePath.length > 1 ? vehiclePath : null}
                tourData={tourData}
                radius={200}
            />
            )
            }
            

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        things: state.telcosites.things,
        sites: state.telcosites.sites
    };
};

const mapDispatchToProps = (dispatch) => ({
    action: bindActionCreators(TelcoSite.creators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TelcoList)

