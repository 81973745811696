import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clone, map, filter } from "lodash";
import moment from "moment";
import { scroller } from "react-scroll";
import Things from "../../../ducks/Things";
import Company from "../../../ducks/Company";
import Groups from "../../../ducks/Groups";
import GeoFence from '../../../ducks/GeoFence';
import { Spinner } from "../../common/Spinner/index";
import { MapWithAMarkerClusterer } from "../../common/maps/Maps";
import ShareErrorHandler from '../shareError/index';
import { filterVehicleListing } from "../../../utils/helper.util";
import "./Index.css";


function radiusToZoom(distance) {
  let radius = distance / 2;
  radius = radius || 5;
  return Math.round(16 - Math.log(radius) / Math.LN2);
}

class MapToShare extends Component {

  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    let selectedStatus = [];
    let movingTracks = [];

    this.state = {
      selectedGroup: [],
      selectedGeofence: [],
      selectedStatus,
      startSelectedDate : moment(),
      endSelectedDate : moment(),
      selectedGeoFenceObject:{},
      isSettingsVisible: false,
      shareModal:false,
      geoFenceSelected: false,
      defaultSearchValue: null,
      searchValue: [],
      searchTerm: "",
      defaultCenter: {
        lat: 30.1575,
        lng: 71.5249,
      },
      vehicleLocation: {
        lat: 30.1575,
        lng: 71.5249,
      },
      zoom: 5.5,
      vehicleNumberPlate: "",
      loading: true,
      isLabelEnable: false,
      selectedVehicle: null,
      searchVehicleValues: [],
      groupId: companyInfo ? companyInfo.groupId : "",
      companyId: companyInfo ? companyInfo.companyId : "",
      geofence: false,
      routefence: false,
      mapLoading: false,
      noURL : false,
      todayTrack: [],
      selectedThing: null,
      todayEvents: {},
      todayStops: [],
      tracks: [],
      movingTracks: [],
      trackFootprints: false,
    };
  }

  async componentDidMount() {

    const {sharedId} = this.props.match.params;
    const actions = [this.props.actions.fetchShareVehicle(sharedId)];

      let selectedStatus = [];
      await Promise.all(actions).then(() => {
        if (!this.props.wrongURL ) {
          this.props.actions.getMqtt({ groupId :  this.props.things[0]['groupId']});
          this.setState({
            ...this.state,
            loading: false,
            selectedStatus,
            searchVehicleValues: map(this.props.things, (item) => ({
              value: item._id,
              label: item.plateNumber || item.imei.substr(-8),
              item: item,
            })),
          });
        } else {
            this.setState({
              ...this.state,
              loading: false,
              noURL : true,
              message : 'Given URL is Wrong or Expired'
            });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.center) !== JSON.stringify(this.props.center) &&
      this.state.trackFootprints && this.props.selectedVehicleCords && this.props.selectedVehicleCords.lat
    ) {

      this.setState({
        ...this.state,
        movingTracks: [...this.state.movingTracks, this.props.selectedVehicleCords],
      });
    }
  }
  componentWillUnmount() {
    this.props.actions.disconnectMqtt();
  }

  searchVehicle = async (selectedOption) => {
    const thingId = selectedOption ? selectedOption.value : null;

    if (thingId) {
      let things = clone(this.props.things);
      let vehicleLocation = filter(things, (item) => {
        if (item._id === thingId) return item;
      });
      if (vehicleLocation.length) {

        this.setState({
          ...this.state,
          defaultCenter: {
            lat: vehicleLocation[0].latitude,
            lng: vehicleLocation[0].longitude,
          },
          defaultSearchValue: selectedOption,
          zoom: 17,
          vehicleNumberPlate: selectedOption.value,
          selectedThing: vehicleLocation[0],
          todayTrack: [],
          todayEvents: {},
          todayStops: [],
          movingTracks: [],
        });
        this.props.actions.ChangeCenter({
          lat: vehicleLocation[0].latitude,
          lng: vehicleLocation[0].longitude,
        });

        scroller.scrollTo(thingId, {
          duration: 1500,
          smooth: true,
          containerId: "containerElement",
          offset: -120,
        });
      }
    } else {
      this.setState({
        ...this.state,
        searchTerm: "",
        defaultCenter: {
          lat: 30.1575,
          lng: 71.5249,
        },

        zoom: 5.5,
        vehicleNumberPlate: "",
        selectedVehicle: null,
        selectedThing: null,
        defaultSearchValue: null,
      });
      this.props.actions.ChangeCenter({
        lat: 30.1575,
        lng: 71.5249,
      });
    }
  };

  onCenterChanged(map) {
    this.props.actions.ChangeCenter({
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    });
  }

  shareLocations = (item) => {
    this.setState({
      ...this.state,
      shareModal: true,
    });
  }

  setStartSelectedDate = async (item) => {
    this.setState({
      ...this.state,
      startSelectedDate: item,
    });
  };

  endStartSelectedDate = async (item) => {
    this.setState({
      ...this.state,
      endSelectedDate: item,
    });
  };

  onSubmit = async () => {
  };

  closeShareModal() {
    this.setState({ ...this.state, shareModal:false});
  }

  render() {
    let loader = <Spinner key="1" />;

    return (
      <>
        <div>
        {this.state.loading ? (
          [loader]
        ) : (
            <div>
              <div className="row">
                <div className="col-md-12 cluster-map-view">
                  <div>
                    {this.state.mapLoading ? (
                      [loader]
                    ) : (
                      <>
                      {
                        this.state.noURL ? <ShareErrorHandler /> : <MapWithAMarkerClusterer
                        markers={this.props.things}
                        smoothCall={false}
                        zoom={this.state.geoFenceSelected ? 11.5 : this.state.zoom}
                        center={{
                          lat: this.state.geoFenceSelected ? this.state.selectedGeoFenceObject.center[1]  : this.props.center.lat,
                          lng: this.state.geoFenceSelected ? this.state.selectedGeoFenceObject.center[0] : this.props.center.lng,
                        }}
                        defaultCenter={{
                          lat: this.state.defaultCenter.lat,
                          lng: this.state.defaultCenter.lng,
                        }}
                        isColdStorage={this.props.permissions.companyFeatures.indexOf("TEMPERATURE") !== -1}
                        onCenterChanged={this.onCenterChanged.bind(this)}
                        onVehicleClick={this.searchVehicle.bind(this)}
                        defaultSearchValue={this.state.defaultSearchValue}
                        showGeofence={this.state.geoFenceSelected ? true : this.state.geofence}
                        geofence={this.state.geoFenceSelected ? [this.state.selectedGeoFenceObject] : this.props.company.geofence}
                        showRoutefence={this.state.routefence}
                        routefence={this.props.company.routefence}
                        track={this.state.todayTrack}
                        events={this.state.todayEvents}
                        stops={this.state.todayStops}
                        trackFootprints={this.state.trackFootprints}
                        tracks={this.state.movingTracks}
                      />
                      }
                      </>
                        
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      </>
    );
  }
}

MapToShare.propTypes = {};
MapToShare.defaultProps = {};
const mapStateToProps = (state) => {
  return {
    things: state.things.markers,
    wrongURL: state.things.wrongURL,
    geoFencesWpaging: state.geoFence.geoFencesWpaging,
    center: state.things.center,
    observation: state.things.observation,
    widgets: state.things.widgets,
    helmetWidgets: state.things.helmetWidgets,
    widgetsConfig: state.things.widgetsConfig,
    groupList: state.groups.subGroups,
    permissions: state.permissions,
    company: state.company,
    selectedVehicleCords: state.things.selectedVehicleCords
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch),
  groupActions: bindActionCreators(Groups.creators, dispatch),
  companyActions: bindActionCreators(Company.creators, dispatch),
  geofenceActions: bindActionCreators(GeoFence.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MapToShare);
