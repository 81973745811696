import axios from 'axios';

export class MqexIot {
    constructor(url, token) {
      this.url = url;
      this.token = token;
      this.callbacks = {};
      this.reconnectAttempts = 3;
      this.reconnectDelay = 3000;
      this.maxReconnectDelay = 30000;
      this.connect();
    }
  
    connect() {
      axios.post(this.url, { refresh_token: this.token })
        .then(resp => {
          const { data } = resp;
          if (!data.url) {
            this.emit('error', new Error('No URL received'));
            return;
          }
          this.socket = this.createSocket(data.url);
          this.reconnectAttempts = 0;
        })
        .catch(error => {
          this.emit('error', error);
          this.scheduleReconnect();
        });
    }
  
    createSocket(url) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      const socket = new WebSocket(url);
      socket.onopen = event => {
        this.emit('connect', event);
        this.interval = setInterval(() => socket.send('ping'), 60000);
      };
      socket.onclose = event => {
        clearInterval(this.interval);
        this.emit('close', event);
        // this.scheduleReconnect();
      };
      socket.onerror = error => this.emit('error', error);
      socket.onmessage = event => {
        const msg = JSON.parse(event.data);
        const { data, topic }  =  msg
      
        this.emit('message', {data, topic});
      }
      return socket;
    }
  
    scheduleReconnect() {
      if (this.reconnectAttempts === 0) {
        this.emit('reconnecting', this.reconnectDelay);
      }
      if (this.reconnectDelay < this.maxReconnectDelay) {
        this.reconnectAttempts += 1;
        setTimeout(() => {
          this.connect();
        }, this.reconnectDelay);
        this.reconnectDelay *= 2;
      } else {
        this.emit('error', new Error('Max reconnect attempts reached'));
      }
    }
  
    subscribe(topic) {
      if (this.socket) {
        this.socket.send(JSON.stringify({
          action: 'Add',
          topic
        }));
      }
    }
  
    on(event, cb) {
      if (!this.callbacks[event]) this.callbacks[event] = [];
      this.callbacks[event].push(cb);
    }
  
    emit(event, data) {
      const cbs = this.callbacks[event];
      if (cbs) {
        cbs.forEach(cb => cb(data));
      }
    }
  }
  



// import axios from 'axios';

// export class MqexIot {
//   constructor(url, token) {
//     this.url = url;
//     this.token = token;
//     this.callbacks = {};
//     this.reconnectAttempts = 3;
//     this.reconnectDelay = 3000;
//     this.maxReconnectDelay = 30000;
//     this.retryAttempts = 4; 
//     this.connect();
//   }

//   connect() {
//     this.retryRequest({
//       method: 'post',
//       url: this.url,
//       data: { refresh_token: this.token },
//       timeout: 10000 
//     }, this.retryAttempts)
//       .then(resp => {
//         const { data } = resp;
//         if (!data.url) {
//           this.emit('error', new Error('No URL received'));
//           return;
//         }
//         this.socket = this.createSocket(data.url);
//         this.reconnectAttempts = 0;
//       })
//       .catch(error => {
//         this.emit('error', error);
//         this.scheduleReconnect();
//       });
//   }

//   retryRequest(config, retries) {
//     return axios(config)
//       .then(response => response)
//       .catch(error => {
//         console.log("retries -- > ", retries)
//         if (error.code === 'ECONNABORTED' && retries > 0) {
//           return this.retryRequest(config, retries - 1);
//         } else if (retries === 0) {
//           throw new Error('Max retry attempts reached');
//         }
//         throw error;
//       });
//   }

//   createSocket(url) {
//     if (this.interval) {
//       clearInterval(this.interval);
//     }
//     const socket = new WebSocket(url);
//     socket.onopen = event => {
//       this.emit('connect', event);
//       this.interval = setInterval(() => socket.send('ping'), 60000);
//     };
//     socket.onclose = event => {
//       clearInterval(this.interval);
//       this.emit('close', event);
//     };
//     socket.onerror = error => this.emit('error', error);
//     socket.onmessage = event => this.emit('message', event);
//     return socket;
//   }

//   scheduleReconnect() {
//     if (this.reconnectAttempts === 0) {
//       this.emit('reconnecting', this.reconnectDelay);
//     }
//     if (this.reconnectDelay < this.maxReconnectDelay) {
//       this.reconnectAttempts += 1;
//       setTimeout(() => {
//         this.connect();
//       }, this.reconnectDelay);
//       this.reconnectDelay *= 2;
//     } else {
//       this.emit('error', new Error('Max reconnect attempts reached'));
//     }
//   }

//   subscribe(topic) {
//     if (this.socket) {
//       this.socket.send(JSON.stringify({
//         action: 'Add',
//         topic
//       }));
//     }
//   }

//   on(event, cb) {
//     if (!this.callbacks[event]) this.callbacks[event] = [];
//     this.callbacks[event].push(cb);
//   }

//   emit(event, data) {
//     const cbs = this.callbacks[event];
//     if (cbs) {
//       cbs.forEach(cb => cb(data));
//     }
//   }
// }
