import React from 'react';
import { connect } from "react-redux";
import Modal from 'react-bootstrap4-modal';
import { withStateHandlers, compose } from 'recompose';

const queryString = require('query-string');

const ErrorHandler = compose(withStateHandlers(
    ({ isOpen = false }) => ({ isOpen }),
    {
        onClick: (state) => () => {
            return ({ isOpen: !state.isOpen });
        }
    }
)

)(props => {
    const { error } = queryString.parse(props.location.search);

    return (
    

        <Modal
            visible={true}
            dialogClassName='error-modal '>

            <div className='modal-heading row'>

            </div>
            <div className="modal-body">
                <h4 className="text-center">
                    Network Error, Please Reload Page
                    <a onClick={props.onClick} className="pull-right error-detail-link">Details</a>

                </h4>
                {props.isOpen ?
                    <p className="network-error-details">
                        {error} </p> : ""}

            </div>
            <div className="modal-footer model-btn">
                <button
                    className="btn auxo-primary-btn"
                    onClick={props.history.goBack}>
                    Reload Page
                    </button>

            </div>
        </Modal>


    )
});
ErrorHandler.propTypes = {};
ErrorHandler.defaultProps = {};
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
