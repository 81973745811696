import Duck from 'extensible-duck'
import axios from "axios";
import { fuelEndPoint } from "../api/endpoints";


export default new Duck({
  namespace: 'AuxFuelGroups',
  store: 'fuel_group',
  types: [
    'FUEL_GROUP_IS_LOADING', 'FUEL_GROUP_FETCHED', 'UPDATE_FUEL_GROUP', 'APPEND_FUEL_GROUP'
  ],
  initialState: {
    groups: [],
    isLoading: false
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.FUEL_GROUP_IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };

      case duck.types.FUEL_GROUP_FETCHED:
        return {
          ...state,
          groups: action.groups
        };
      case duck.types.APPEND_FUEL_GROUP:
        const groups = [
          { editMode: true, isNew: true, _id: state.groups.length },
          ...state.groups
        ];
        return {
          ...state,
          groups
        };
      default:
        return state;
    }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({

    getFuelGroup: () => async (dispatch, getState) => {

      try {
        if(localStorage.getItem("companyInfo")){
            dispatch({ type: duck.types.FUEL_GROUP_IS_LOADING, isLoading: true });
        
            const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    
            const response = await axios.get(fuelEndPoint.fuelGroupBaseUrl(companyInfo.companyId));
            const groups = response.data.data;
            dispatch({ type: duck.types.FUEL_GROUP_FETCHED, groups });
            dispatch({ type: duck.types.FUEL_GROUP_IS_LOADING, isLoading: false });
        }
      
      } catch (e) {
        dispatch({ type: duck.types.FUEL_GROUP_IS_LOADING, isLoading: false });
        // throw e;
      }
    },
    appendFuelGroup: () => async (dispatch, getState) => {
      try {
        dispatch({ type: duck.types.APPEND_FUEL_GROUP });
      } catch (e) {
        throw e;
      }
    },
    saveFuelGroup: (fuelGroup) => async (dispatch, getState) => {
      try {
        const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        let response;
        delete fuelGroup.editMode;
        if (fuelGroup.isNew) {
          delete fuelGroup.isNew;
          delete fuelGroup._id;
          response = await axios.post(fuelEndPoint.fuelGroupBaseUrl(companyInfo.companyId), fuelGroup);
        } else {
          const { _id } = fuelGroup;
          response = await axios.put(fuelEndPoint.updateFuelGroup(companyInfo.companyId, _id), fuelGroup);
        }
        const groups = response.data.data;
        dispatch({ type: duck.types.FUEL_GROUP_FETCHED, groups });
      } catch (e) {
        throw e;
      }
    },
  })
})