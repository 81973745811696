import axios from "axios";
import moment from "moment";
import toastr from "toastr";
import { URL, apiV1 } from "../constants";
const urlV1 = URL + apiV1;
let refreshThreshold = moment().add(55, 'minutes');
const axiosInstance = axios.create({
    baseURL: urlV1
});
let retry = false;

let isRefreshing = false;


// const intercept = (store, logoutAction, history) => {
//   axios.defaults.baseURL = urlV1;

//   axios.interceptors.response.use(
//       response => {
//           if (response.token) {
//               localStorage.setItem("token", response.token);
//           }
//           if (response.identityId) {
//               localStorage.setItem("identityId", response.identityId);
//           }
//           return response;
//       },
//       function (error) {
//           console.log("axios interceptor error -> ", error);

//           let errorMessage = error.toString();

//           if (error.response) {
//               errorMessage = error.response.data?.message || error.message || "";
//           } else {
//               const errorMatch = errorMessage.match(/status code (\d+)/);
//               if (errorMatch) {
//                   const statusCode = parseInt(errorMatch[1], 10);
//                   error.response = { status: statusCode, data: { message: errorMessage } };
//               }
//           }

//           try {
//               if (error && !error.response && error.message) {
//                   toastr.error(`Something went wrong!`);
//                   throw new Error(error);
//               }

//               if (error.response?.status === 408 || error.code === 'ECONNABORTED') {
//                   toastr.error(`A timeout happened on url ${error.config.url}`);
//                   return;
//               }

//               if (error.response?.status === 401) {
//                   if (!retry) {
//                       toastr.error("Login Expired");
//                       store.dispatch(logoutAction());
//                       localStorage.clear();
//                   }
//                   retry = true;
//               }

//               if (error.response?.status === 405 && error.response.data) {
//                   if (error.response.data.code === 405 && error.response.data.message === "METHOD_NOT_ALLOWED") {
//                       toastr.error("Login Expired");
//                       store.dispatch(logoutAction());
//                       localStorage.clear();
//                   }
//               }

//               if (error.response?.status >= 500) {
//                   //toastr.error(`Something went wrong, Please check your internet`);
 
//                   setTimeout(() => {
//                       window.location.reload();
//                   }, 10000);
//                   return Promise.reject(error);
//               }

//               return Promise.reject(error || "Something went wrong");
//           } catch (err) {
//               const errMsg = err && err.message ? err.message : err;
//               return Promise.reject(errMsg ? { response: { data: { message: errMsg } } } : "Something went wrong");
//           }
//       }
//   );

//   axios.interceptors.request.use(async (config) => {
//       if (localStorage.refreshToken) {
//           config.headers.token = localStorage.refreshToken;
//           const isSuperAdminUser = localStorage.getItem('isSuperAdminUser');

//           if (isSuperAdminUser) {
//               config.headers.isSuperAdminUser = 'true';
//           }
//       }
//       config.headers["Access-Control-Allow-Origin"] = '*';
//       return config;
//   }, function (error) {
//       return Promise.reject(error || "Something went wrong");
//   });
// };

const intercept = (store, logoutAction, history) => {
  axios.defaults.baseURL = urlV1;
  const MAX_RETRIES = 1;

  axios.interceptors.response.use(
    response => {
      if (response.token) {
        localStorage.setItem("token", response.token);
      }
      if (response.identityId) {
        localStorage.setItem("identityId", response.identityId);
      }
      return response;
    },
    async function (error) {
      console.log("axios interceptor error -> ", error);

      let errorMessage = error.toString();
      if (error.response) {
        errorMessage = error.response.data?.message || error.message || "Something went wrong! Please check your internet";
      } else {
        const errorMatch = errorMessage.match(/status code (\d+)/);
        if (errorMatch) {
          const statusCode = parseInt(errorMatch[1], 10);
          error.response = { status: statusCode, data: { message: errorMessage } };
        }
      }

      if (!error.config) {
        return Promise.reject(error);
      }

      if (!error.config.__retryCount) {
        error.config.__retryCount = 0;
      }

      if (error.config.__retryCount < MAX_RETRIES) {
        error.config.__retryCount += 1;

        const delay = new Promise(resolve => setTimeout(resolve, 1000));
        await delay;

        // Retry the request
        return axios(error.config);
      }

      try {
        if (!error.response && error.message) {
          console.log(`Something went wrong! ${error.message}`)
          //toastr.error(`Something went wrong!`);
          throw new Error(error);
        }

        if (error.response?.status === 408 || error.code === 'ECONABORTED') {
          console.log(`A timeout happened on url ${error.config.url}`);
          return;
        }

        if (error.response?.status === 401) {
          if (!retry) {
            toastr.error("Login Expired");
            store.dispatch(logoutAction());
            localStorage.clear();
          }
          retry = true;
        }

        if (error.response?.status === 405 && error.response.data) {
          if (error.response.data.code === 405 && error.response.data.message === "METHOD_NOT_ALLOWED") {
            toastr.error("Login Expired");
            store.dispatch(logoutAction());
            localStorage.clear();
          }
        }

        if (error.response?.status >= 500) {
          console.log(`Something went wrong, Please check your internet`);
          return Promise.reject(error);
        }

        return Promise.reject(error || "");
      } catch (err) {
        const errMsg = err && err.message ? err.message : err;
        return Promise.reject(errMsg ? { response: { data: { message: errMsg } } } : "Something went wrong");
      }
    }
  );

  axios.interceptors.request.use(async (config) => {
    if (localStorage.refreshToken) {
      config.headers.token = localStorage.refreshToken;
      const isSuperAdminUser = localStorage.getItem('isSuperAdminUser');
      if (isSuperAdminUser) {
        config.headers.isSuperAdminUser = 'true';
      }
    }
    config.headers["Access-Control-Allow-Origin"] = '*';
    return config;
  }, function (error) {
    return Promise.reject(error || "Something went wrong");
  });
};


export default intercept