import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';
import GeoJSONWriter from 'jsts/org/locationtech/jts/io/GeoJSONWriter';
import { BufferOp } from 'jsts/org/locationtech/jts/operation/buffer';
import to_marker from '../../assets/img/to-marker.png';
import from_marker from '../../assets/img/from-marker.png';
import SearchBox from './SearchBox';


class GoogleMapComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      maps: null,
      map: null,
      fromMarker: null,
      toMarker: null,
      polyLines: [],
      shadowLines: [],
      polyLinesData: [],
      infoWindow: null,
      polyLinesSegments: [],
      lineSelectedIndex: 0,
      bufferedPolygon: null,
      apiReady: false,
      places: null,
      zoom: 15,
      center: this.props.center ? this.props.center : [33.58784843822045, 73.0494633588822]
    };

    this.saveMapInstance = this.saveMapInstance.bind(this);
    this.onPlacesChanged = this.onPlacesChanged.bind(this)
    this.createMarker = this.createMarker.bind(this);
    this.mapClicked = this.mapClicked.bind(this);
  }

  componentDidUpdate() {
    if (this.props.getRoutes && this.state.fromMarker && this.state.toMarker) this.renderRoutes();
    if (this.props.addBuffer) this.addBuffer();
    if (this.props.checkLatLng) this.checkLatLngPolygon();
  }

  saveMapInstance(maps) {
    maps.maps.Polyline.prototype.getBounds = function (startBounds) {
      const bounds = startBounds || new maps.maps.LatLngBounds();
      this.getPath().forEach((item) => bounds.extend(new maps.maps.LatLng(item.lat(), item.lng())));
      return bounds;
    };

    maps.maps.Polygon.prototype.containsOffline = function (point) {

      let crossings = 0,
        path = this.getPath();
      // For each edge
      for (let i = 0; i < path.getLength(); i++) {
        let a = path.getAt(i), j = i + 1;
        if (j >= path.getLength()) j = 0;
        let b = path.getAt(j);
        if (rayCrossesSegment(point, a, b)) crossings++;
      }

      // Check odd number of crossings
      return crossings % 2 === 1;

      function rayCrossesSegment(point, a, b) {

        let px = point.lng(),
          py = point.lat(),
          ax = a.lng(),
          ay = a.lat(),
          bx = b.lng(),
          by = b.lat();

        if (ay > by) {
          ax = b.lng();
          ay = b.lat();
          bx = a.lng();
          by = a.lat();
        }
        // Alter longitude for catering for 180 degree crossings
        if (px < 0) px += 360;
        if (ax < 0) ax += 360;
        if (bx < 0) bx += 360;

        if (py === ay || py === by) py += 0.00000001;
        if ((py > by || py < ay) || (px > Math.max(ax, bx))) return false;
        if (px < Math.min(ax, bx)) return true;

        let red = (ax !== bx) ? ((by - ay) / (bx - ax)) : Infinity;
        let blue = (ax !== px) ? ((py - ay) / (px - ax)) : Infinity;
        return (blue >= red);

      }

    };
    if (maps.map && maps.maps) {
      this.setState({ apiReady: true, maps: maps.maps, map: maps.map });
    }
  }

  mapClicked(e) {
    this.createMarker({ lat: e.lat, lng: e.lng })
  }

  createMarker(latlng) {
    // debugger
    if (this.state.fromMarker || this.state.toMarker)
      switch (this.props.markerMarked) {

        case 'from':

          if (this.state.fromMarker) {

            // Deleting previous markers
            this.state.fromMarker.setMap(null);
            if (this.state.toMarker) {
              this.state.toMarker.setMap(null);
            }
            this.setState({ fromMarker: null, toMarker: null });
            if (this.state.map) {
              //  this.state.map.clear();
            }

          }
          break;

        case 'to':

          if (this.state.toMarker) {
            // Deleting previous marker
            this.state.toMarker.setMap(null);
            this.setState({ toMarker: null });
          }
          break;
      }
    if (this.props.markerMarked === 'from' || this.props.markerMarked === 'to') {
      const icon = this.props.markerMarked === 'from' ? from_marker : to_marker;

      this.setState({
        [`${this.props.markerMarked}Marker`]: new this.state.maps.Marker({
          map: this.state.map,
          animation: this.state.maps.Animation.DROP,
          position: latlng,
          icon: icon
        })
      });
    }




    this.props.handler('markerClickedMessage', null);
    this.props.handler(`${this.props.markerMarked}Value`, Object.values(latlng).join(','));
    this.props.handler('markerMarked', null);
  }

  renderRoutes() {
    const directionsService = new this.state.maps.DirectionsService();
    directionsService.route(
      {
        origin: { lat: this.state.fromMarker.position.lat(), lng: this.state.fromMarker.position.lng() },
        destination: { lat: this.state.toMarker.position.lat(), lng: this.state.toMarker.position.lng() },
        travelMode: this.state.maps.TravelMode['DRIVING'],
        unitSystem: this.state.maps.UnitSystem.METRIC,
        provideRouteAlternatives: true
      },
      (res, status) => {
        this.props.handler('getRoutes', false);

        // Clear all previous paths before rendering
        for (const j in this.state.polyLines) {
          if (!this.state.polyLines.hasOwnProperty(j)) continue;
          this.state.polyLines[j].setMap(null);
          this.state.shadowLines[j].setMap(null);
        }
        this.setState({ polyLines: this.state.polyLines, shadowLines: this.state.shadowLines });

        if (status === this.state.maps.DirectionsStatus.OK) {
          this.props.handler('bufferEnabled', true);

          const polyLines = [],
            shadowLines = [],
            polyLinesSegments = [],
            polyLinesData = [];

          let bounds = this.state.maps.LatLngBounds();

          res.routes.filter((route, index) => {

            const { shadowLine, polyLine, polyLineSegments } = this.drawPolyShadowLine(route.legs, index === 0);

            shadowLines.push(shadowLine);
            polyLines.push(polyLine);
            polyLinesSegments.push(polyLineSegments);
            polyLinesData.push({
              distance: route.legs[0].distance,
              duration: route.legs[0].duration,
              end_address: route.legs[0].end_address,
              start_address: route.legs[0].start_address,
              end_location: route.legs[0].end_location,
              start_location: route.legs[0].start_location
            });


            const _this = this; // Maintain original lexical scope in cb function

            bounds = polyLine.getBounds(bounds);
            this.state.maps.event.addListener(shadowLine, 'click', function (e) {
              // @ts-ignore
              _this.highlightGetRoute(shadowLines.indexOf(this), e);
            });

            this.state.map.fitBounds(bounds);
          });

          this.setState({ polyLines, shadowLines, polyLinesData, polyLinesSegments });
        }
      }
    );
  }

  highlightGetRoute(routeClickedIndex, e) {

    for (const j in this.state.polyLines) {

      const index = +j; // For implicit typecast

      if (index === routeClickedIndex) {
        let contentString = `<span>${this.state.polyLinesData[j].distance.text}</span><br/>`;
        contentString += `<span>${this.state.polyLinesData[j].duration.text}</span><br/>`;
        contentString += `<span>route: ${j}</span><br/>`;
        // contentString += `From: <span>${this.state.polyLinesData[j].start_address}</span><br/>`;
        // contentString += `To: <span>${this.state.polyLinesData[j].end_address}</span><br/>`;

        this.state.polyLines[index].setMap(this.state.map);

        if (e) {
          const position = new this.state.maps.LatLng(e.latLng.lat(), e.latLng.lng()); // Needed to close previous infoWindow
          if (this.state.infoWindow) {
            this.state.infoWindow.close();
            this.setState({ infoWindow: null });
          }

          this.setState({
            // infoWindow: new this.state.maps.InfoWindow({ content: contentString, position: position, map: this.state.map }),
            lineSelectedIndex: index
          });
        }

      }
      else this.state.polyLines[j].setMap(null);

    }

  }

  drawPolyShadowLine(routeLegs, polyLineShow, color, strokeOpacity, strokeWeight) {

    // @ts-ignore
    const shadowLine = new this.state.maps.Polyline({
      path: [],
      strokeColor: color || '#666666',
      strokeOpacity: strokeOpacity || 0.4,
      strokeWeight: strokeWeight || 10
    });

    // @ts-ignore
    const polyLine = new this.state.maps.Polyline({
      path: [],
      strokeColor: color || '#0000ff',
      strokeOpacity: strokeOpacity || 0.9,
      strokeWeight: strokeWeight || 3
    });

    const polyLineSegments = [];

    for (let i = 0; i < routeLegs.length; i++) {
      const steps = routeLegs[i].steps;

      for (let j = 0; j < steps.length; j++) {
        const nextSegment = steps[j].path;

        for (let k = 0; k < nextSegment.length; k++) {
          polyLine.getPath().push(nextSegment[k]);
          shadowLine.getPath().push(nextSegment[k]);
          polyLineSegments.push([nextSegment[k].lng(), nextSegment[k].lat()])
        }
      }
    }

    if (polyLineShow) polyLine.setMap(this.state.map);
    shadowLine.setMap(this.state.map);

    return { polyLine, shadowLine, polyLineSegments };
  }

  addBuffer() {
    // Remove Previous Polygon
    this.props.handler('addBuffer', false);
    this.props.handler('checkLatLngEnabled', false);
    if (this.state.bufferedPolygon) this.state.bufferedPolygon.setMap(null);
    this.setState({ bufferedPolygon: null });

    if (this.props.bufferValue > 0) {
      const bufferedPolygon = new this.state.maps.Polygon({
        paths: this.createBufferPolygon(this.state.polyLinesSegments[this.state.lineSelectedIndex]),
        fillColor: '#2741a5'
      });

      bufferedPolygon.setMap(this.state.map);
      this.setState({ bufferedPolygon: bufferedPolygon });
      this.props.handler('checkLatLngEnabled', true);


    }

  }

  createBufferPolygon(polyLineSegments) {
    if (polyLineSegments && polyLineSegments.length >= 0) {

      let distance = (this.props.bufferValue / 1000) / 111.12,  // In km.
        geoInput = { type: 'LineString', coordinates: polyLineSegments },

        geoReader = new GeoJSONReader(),
        geoWriter = new GeoJSONWriter(),

        // @ts-ignore
        geometry = geoReader.read(geoInput),
        spBuffer = BufferOp.bufferOp(geometry, distance),
        polygon = geoWriter.write(spBuffer),

        // @ts-ignore
        polygonCoordinates = polygon.coordinates[0],
        areaCoordinates = [];

      for (let i = 0; i < polygonCoordinates.length; i++) {
        const coordinate = polygonCoordinates[i];
        areaCoordinates.push(new this.state.maps.LatLng(coordinate[1], coordinate[0]));
      }
      this.props.getPath(polygonCoordinates);
      return areaCoordinates;
    }
  }

  addBufferOnUpdate(value, path, center) {

    this.setState({ ...this.state, center })
    // Remove Previous Polygon
    this.props.handler('addBuffer', false);
    this.props.handler('checkLatLngEnabled', false);
    if (this.state.bufferedPolygon) this.state.bufferedPolygon.setMap(null);
    this.setState({ bufferedPolygon: null });

    if (value > 0) {
      const bufferedPolygon = new this.state.maps.Polygon({
        paths: this.createBufferPolygonOnUpdate(path, value),
        fillColor: '#2741a5'
      });

      bufferedPolygon.setMap(this.state.map);
      this.setState({ bufferedPolygon: bufferedPolygon });
      this.props.handler('checkLatLngEnabled', true);

    }

  }

  createBufferPolygonOnUpdate(path, value) {
    if (path && path.length >= 0) {

      const areaCoordinates = [];

      for (let i = 0; i < path.length; i++) {
        const coordinate = path[i];
        areaCoordinates.push(new this.state.maps.LatLng(coordinate[1], coordinate[0]));
      }
      return areaCoordinates;
    }
  }


  checkLatLngPolygon() {

    let latlng = this.props.checkLatLngValue.toString().split(',');
    const resPolygon = this.state.bufferedPolygon.containsOffline(new this.state.maps.LatLng(latlng[0], latlng[1]));
    window.alert(`LatLng does ${!resPolygon ? 'not' : ''} lie in the buffered path`);
    this.props.handler('checkLatLng', false)
  }
  onPlacesChanged(places) {
    const map = this.state.map
    const bounds = new this.state.maps.LatLngBounds();
    let markers = [];
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      const icon = {
        url: place.icon,
        size: new this.state.maps.Size(71, 71),
        origin: new this.state.maps.Point(0, 0),
        anchor: new this.state.maps.Point(17, 34),
        scaledSize: new this.state.maps.Size(25, 25),
      };


      // Create a marker for each place.
      const contentString = `<div style="color:white">${place.geometry.location}</div>`;
      const infowindow = new this.state.maps.InfoWindow({
        content: contentString,
        ariaLabel: "Uluru",
      });
      const marker = new this.state.maps.Marker({
        map,
        title: place.name,
        position: place.geometry.location,
      })
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
        });
      })
      markers.push(marker);
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  }

  render() {
    return (
      <>
        {this.state.apiReady && (<SearchBox
          onPlacesChanged={this.onPlacesChanged}
          map={this.state.map}
          mapsapi={this.state.maps} />)}
        <GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns', libraries: ['places'] }}
          defaultCenter={this.state.center}
          center={this.state.center}
          defaultZoom={this.state.zoom}
          options={{ mapTypeControl: true, zoomControl: true, scaleControl: true, streetViewControl: true, fullscreenControl: true, }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.saveMapInstance}
          onClick={this.mapClicked}
        >
        </GoogleMapReact>
      </>
    );
  }

}


export default GoogleMapComponent;
