import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import Things from "../../ducks/Things";
import { bindActionCreators } from "redux";
import "./index.css"
import moment from 'moment';
import Select from 'react-select-nested-group';
import { Spinner } from '../common/Spinner/index';
import { TripMap } from '../common/maps/tripMap';
import Modal from 'react-bootstrap4-modal';
import Stepper from 'react-stepper-horizontal';
import DateTime from 'react-datetime';
import toastr from "toastr";
import Table from 'react-bootstrap/Table'

const {
  InfoWindow
} = require("react-google-maps");

class TrackPlayback extends Component {

  constructor() {
    super();
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    this.state = {
      mapHeight: "40vh",
      selectedDate: null,
      selectedVehicle: null,
      selectedHours: null,
      isLoadingRecords: true,
      loadingPlateNumber: true,
      loading: false,
      vehicleNumberPlate: "",
      thingId: "",
      path: [],
      ignitionOn: [],
      ignitionOff: [],
      loadMap: false,
      geofence: [],
      geofencesArea: [],
      distance: null,
      harshAcceleration: [],
      voilations: {},
      enableHarshAcceleration: true,
      enableHarshBrakes: true,
      enableHarshTurn: true,
      enableOverSpeeding: true,
      isSearchCriteriaVisible: true,
      stops: [],
      position: {},
      playbackSpeed: { value: 1, label: '1x' },
      playbackStatus: null,
      companyId: companyInfo ? companyInfo.companyId : "",
      infoWindowContent: {
        angle: 0,
        latlng: {
          lat: 0.0,
          lng: 0.0
        },
        address: "",
        speed: 0,
        distance: 0
      }
    }
    this.gridElement = React.createRef();
    this.tbody = React.createRef();
  }

  async componentDidMount() {

    await this
      .props
      .actions
      .getVehiclesPlateNumber();
    if (this.props.vehiclesPlateNumber.length) {
      await this.setState({
        ...this.state,
        thingId: this.props.vehiclesPlateNumber[0]._id,
        isLoadingRecords: true,
        loadingPlateNumber: false,
      });
      this.handlePlateNumberChange({ value: this.props.vehiclesPlateNumber[0]._id })
    } else {
      this.setState({
        ...this.state,
        loadingPlateNumber: false,
        isLoadingRecords: false,
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearVehicleDetails();
    this.setState({ ...this.state, playbackStatus: null })
  }
  // animate = () => {
  //   if (this.state.playbackStatus === 'playing') {
  //     const { currentIndex } = this.state;
  //     this.setState({ ...this.state, mapHeight: "80vh" });

  //     const nextIndex = currentIndex + 1;
  //     const { length } = this.state.completePath
  //     if (length === nextIndex) {
  //       return this.setState({ ...this.state, playbackStatus: 'stop' });
  //     }
  //     const position = this.state.path[nextIndex];
  //     const { angle } = this.state.completePath[nextIndex];
  //     const SKIP = 4;
  //     const ROW_HEIGHT = this.tbody.current.clientHeight / this.state.path.length;
  //     if (nextIndex === SKIP) {
  //       this.gridElement.current.scrollTo({ top: (ROW_HEIGHT * (nextIndex + 1)), behavior: 'smooth' });
  //     } else if (nextIndex > 5 && nextIndex % 5 === 4) {
  //       this.gridElement.current.scrollTo({ top: (ROW_HEIGHT * (nextIndex + 1)), behavior: 'smooth' });
  //     }

  //     setTimeout(() => {
  //       this.setState({ ...this.state, currentIndex: nextIndex, position, angle });
  //       setTimeout(() => {
  //         this.animate();
  //       }, Math.round(600 / this.state.playbackSpeed.value));
  //     }, 10);

  //   } else if(this.state.playbackStatus === 'stop') {
  //     this.setState({ ...this.state, mapHeight: "40vh" });
  //   } else {
  //     this.setState({ ...this.state, mapHeight: "40vh" });
  //   }

  // }

  animate = () => {
    if (this.state.playbackStatus === 'playing') {
      const { currentIndex } = this.state;
      this.setState({ ...this.state, mapHeight: "80vh" });

      const nextIndex = currentIndex + 1;
      const { length } = this.state.completePath;
      if (length === nextIndex) {
        return this.setState({ ...this.state, playbackStatus: 'stop' });
      }
      const position = this.state.path[nextIndex];
      const startDist = this.state.completePath[0].OdoMeter;
      const { angle, latlng, address, speed, event, OdoMeter, time } = this.state.completePath[nextIndex];
      // console.log("this.state.completePath[0]  ", this.state.completePath[0])
      // console.log("startDist OdoMeter  ", startDist)
      // console.log("OdoMeter  ", OdoMeter)
      // console.log("distance  ", (OdoMeter - startDist)/1000)

      this.setState(prevState => {
        if (prevState.infoWindowContent.latlng && prevState.infoWindowContent.latlng.lat === latlng.lat && prevState.infoWindowContent.latlng.lng === latlng.lng) {
          return null;
        }
        return {
          infoWindowContent: {
            angle,
            latlng,
            address,
            speed,
            distance: (OdoMeter - startDist) / 1000,
            time
          }
        };
      });

      if (nextIndex % 5 === 0) {
        this.gridElement.current.scrollTo({ top: this.tbody.current.clientHeight / this.state.path.length * (nextIndex + 1), behavior: 'smooth' });
      }

      setTimeout(() => {
        this.setState({
          ...this.state,
          currentIndex: nextIndex,
          position,
          angle
        });
        setTimeout(() => {
          this.animate();
        }, Math.round(600 / this.state.playbackSpeed.value));
      }, 10);

    } else if (this.state.playbackStatus === 'stop') {
      this.setState({ ...this.state, mapHeight: "40vh" });
    } else {
      this.setState({ ...this.state, mapHeight: "40vh" });
    }
  }

  handlePlateNumberChange = async (selectedOption) => {
    const thingId = selectedOption ? selectedOption.value : null;
    if (thingId) {
      await this.setState({
        ...this.state,
        isLoadingRecords: true,
        loading: true,
        mapLoading: false,
        vehicleNumberPlate: selectedOption
          ? selectedOption.value
          : "",
        thingId
      });
      this.props.actions.clearVehicleDetails();

      await this
        .props
        .actions
        .GetVehicleDetails({ id: thingId });
      this.setState({ ...this.state, loading: false });
    }

  }
  searchNewCriteria = async ({ selectedDate, endDate, selectedVehicle }) => {
    await this.setState({
      ...this.state,
      selectedDate,
      endDate,
      selectedVehicle,
      isSearchCriteriaVisible: false,
      completePath: []
    });

    await this.handlePlateNumberChange(selectedVehicle);

    await this.fetchTrackPlayback({ selectedDate, endDate, selectedVehicle });
  }
  changeCriteria = async () => {
    this.setState({ ...this.state, isSearchCriteriaVisible: true });
  }
  fetchTrackPlayback = async ({ selectedDate, endDate, selectedVehicle }) => {

    const start = selectedDate;
    const end = endDate;
    // const difference = moment(start).diff(moment(end), 'minutes');

    const interval = { "startDate": start.format(), "endDate": end.format() };

    this.setState({ ...this.state, mapLoading: true });

    var data = null;

    if (this.state.companyId === "664f4f93e440b860d2c1c93c") {
      data = await this.props.actions.getTrackPointsBigWay(JSON.stringify({ interval }), selectedVehicle.value) || {};
    } else {
      data = await this.props.actions.getTrackPoints(JSON.stringify({ interval }), selectedVehicle.value) || {};
    }


    let { path: _path = [] } = data;

    if (!_path.length) {
      toastr.error("No record found, Select another criteria")
      await this.setState({ ...this.state, selectedVehicle: null });
      this.changeCriteria();
    } else {
      // _path = _path
      // .map((point, index, _path) => {
      //     if(point.type==='event'){
      //        const _point =  R.findLast(_point=>_point.type==='observation'&&R.equals(_point.latlng,point.latlng))(_path);
      //      console.log({_point});
      //        return _point?{...point,time:_point.time}:point;
      //     }
      //     return point;
      // })
      // .sort((a,b)=>moment(a.time,'MM/DD/YYYY HH:mm:SS').diff(moment(b.time,'MM/DD/YYYY HH:mm:SS')))
      const path = _path.map(point => point.latlng);
      // console.log({data});
      // debugger;
      const {
        geofence = {},
        geofencesArea = [],
        distance = 0,
        counts: voilations = {},
        geoFenceCounts = {},
        fatigueList: _fatigueList = [],

        eventsListing: {
          HARSH_ACCELERATION: _harshAcceleration = [],
          HARSH_TURN: _harshTurn = [],
          SPEED_LIMIT: _overspeeding = [],
          HARSH_BRAKES: _harshBrakes = [],
          IGNITION_OFF: _ignitionOff = [],
          IGNITION_ON: _ignitionOn = [],

        } = {},
        eventsListing: voilationListTypes = [],
        duration = 0,
        idleTime = 0,
        idle = [],
        averageSpeed = 0,
        fuelConsumed = 0,
        stops = [],
      } = data;
      // const mapCoordinates = acc => {
      //   const [lat, lng] = acc.latlng.split(',');
      //   return { ...acc, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) } }
      // }

      const mapCoordinates = acc => {
        if (acc.latlng && typeof acc.latlng === 'string') {
          const [lat, lng] = acc.latlng.split(',');
          return { ...acc, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) } };
        }
        return acc; // Return unchanged if latlng is invalid
      };
      const harshAcceleration = _harshAcceleration.map(mapCoordinates);
      const harshTurn = _harshTurn.map(mapCoordinates);
      const ignitionOff = _ignitionOff.map(mapCoordinates);
      const ignitionOn = _ignitionOn.map(mapCoordinates);
      const overspeeding = _overspeeding.map(mapCoordinates);
      const harshBrakes = _harshBrakes.map(mapCoordinates);
      const fatigueList = _fatigueList.map(mapCoordinates);
      const cellSiteCoordinates = point => {
        if (point.eventType === 'ENTERING PATROLLING CELLSITE') {
          const { lat, lng } = point.latlng;
          return { ...point, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) } }
        }
        return;
      }
      const cellSitePoints = _path.filter(point => point.eventType === 'ENTERING PATROLLING CELLSITE').map(cellSiteCoordinates);
      // const idle = _idle.map(mapCoordinates);

      this.setState({
        ...this.state,
        path,
        completePath: _path,
        mapLoading: false,
        loadMap: true,
        geofences: geofence,
        geofencesArea,
        distance,
        harshAcceleration,
        harshTurn,
        ignitionOff,
        ignitionOn,
        overspeeding,
        harshBrakes,
        voilations,
        voilationListTypes,
        geoFenceCounts,
        duration,
        idleTime,
        idle,
        averageSpeed,
        fuelConsumed,
        stops,
        fatigueList,
        currentIndex: 0,
        position: path[0],
        angle: 180,
        enterCellSite: cellSitePoints
      });
    }
  }





  changeViolationData(data) {

    let voilationListTypes = Object.assign({}, this.state.voilationListTypes);
    if (data.type === "harshAcceleration") {


      voilationListTypes.HARSH_ACCELERATION = this.state.enableHarshAcceleration ? [] : this.state.voilationListTypes.HARSH_ACCELERATION

      this.setState({
        ...this.state,
        voilationListTypes,
        enableHarshAcceleration: !this.state.enableHarshAcceleration
      })

    }
    else if (data.type === "harshBrakes") {



      voilationListTypes.HARSH_BRAKES = this.state.enableHarshBrakes ? [] : this.state.voilationListTypes.HARSH_BRAKES

      this.setState({
        ...this.state,
        voilationListTypes,
        enableHarshBrakes: !this.state.enableHarshBrakes
      })
    }
    else if (data.type === "harshTurn") {

      voilationListTypes.HARSH_TURN = this.state.enableHarshTurn ? [] : this.state.voilationListTypes.HARSH_TURN

      this.setState({
        ...this.state,
        voilationListTypes,
        enableHarshTurn: !this.state.enableHarshTurn
      })

    }
    else if (data.type === "overSpeeding") {

      voilationListTypes.SPEED_LIMIT = this.state.enableOverSpeeding ? [] : this.state.voilationListTypes.SPEED_LIMIT

      this.setState({
        ...this.state,
        voilationListTypes,
        enableOverSpeeding: !this.state.enableOverSpeeding
      })
    }

  }
  changePlaybackSpeed = (playbackSpeed) => {
    this.setState({ ...this.state, playbackSpeed });
  }
  playbackRowSelected = (index, event) => {
    const currentIndex = index;
    const position = this.state.path[currentIndex];
    const { angle } = this.state.completePath[currentIndex];
    // const infoWindowContent = `Lat: ${event.latlng ? event.latlng.lat : ''}, Lng: ${event.latlng ? event.latlng.lng : ''}, Address: ${event.address || ''}, Speed: ${event.speed}, Event: ${event.eventType}`;
    // console.log("infoWindowContent --> < >", infoWindowContent)
    this.setState({ ...this.state, currentIndex: currentIndex, position, angle });

  }
  playPause = () => {
    const { playbackStatus } = this.state;
    if (!playbackStatus) {
      this.setState({ ...this.state, playbackStatus: 'playing' });
    }
    if (playbackStatus === 'playing') {
      this.setState({ ...this.state, playbackStatus: 'stop' });
    } else {
      this.setState({ ...this.state, playbackStatus: 'playing' });
    }
    setTimeout(() => { this.animate() }, 100);

  }
  resetPlayback = () => {

    this.gridElement.current.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ ...this.state, playbackStatus: null, currentIndex: 0 });

  }
  render() {

    let loader = <Spinner key="1" />
    const showContent = this.props.vehicleDetail && this.props.vehicleDetail.plateNumber;


    return (

      <div>


        <div>
          {showContent ? <div className="vehicle-plate-Number-model" >
            <p className="vehicle-plate-number">{this.props.vehicleDetail.plateNumber || "N/A"}</p>
            <p className="vehicle-model">{this.props.vehicleDetail.vehicleMakeAndModel || 'N/A'}</p>
            <p className="vehicle-model" style={{ fontSize: '16px' }} >Start Date: {this.state.selectedDate ? this.state.selectedDate.format('DD-MM-YYYY HH:mm') : 'Please Select a start date'}</p>
            <p className="vehicle-model" style={{ fontSize: '16px' }} >End Date: {this.state.endDate ? this.state.endDate.format('DD-MM-YYYY HH:mm') : 'Please Select a end date'}</p>
            <button
              className="btn auxo-primary-btn"
              style={{ margin: '0px' }}
              onClick={this.changeCriteria}
            >Change Criteria</button>
          </div> : ""
          }
          {this.state.mapLoading ? [loader] : <div>
            {this.state.loadMap ? <div>
              <div className="row">
                <div className="col-md-8">
                  {/* <div style={{position: "absolute", top: "0", left: "0"}}>
                {this.state.infoWindowContent.speed > 0 && this.state.playbackStatus === "playing" && (
          <InfoWindow position={this.state.infoWindowContent.latlng} >
            <div className="custom-info-window">
            <p>Lat: {this.state.infoWindowContent.latlng.lat}</p>
              <p>Lng: {this.state.infoWindowContent.latlng.lng}</p>
              <p>Address: {this.state.infoWindowContent.address}</p>
              <p>Speed: {this.state.infoWindowContent.speed}</p>
              <p>Distance: {this.state.infoWindowContent.distance} KM</p>
            </div>
          </InfoWindow>
        )}
        </div> */}
                  <TripMap
                    height={this.state.mapHeight}
                    playbackStatus={this.state.playbackStatus}
                    position={this.state.position}
                    angle={this.state.angle}
                    path={this.state.path}
                    distance={this.state.distance}
                    geofencesArea={this.state.geofencesArea}
                    harshAcceleration={this.state.enableHarshAcceleration ? this.state.harshAcceleration : []}
                    overSpeeding={this.state.enableOverSpeeding ? this.state.overspeeding : []}
                    harshTurn={this.state.enableHarshTurn ? this.state.harshTurn : []}
                    harshBrakes={this.state.enableHarshBrakes ? this.state.harshBrakes : []}
                    enterCellSite={this.state.enterCellSite || []}
                    ignitionOn={this.state.ignitionOn}
                    ignitionOff={this.state.ignitionOff}
                    stops={this.state.stops}
                    idle={this.state.idle}
                    company={this.state.companyId}
                    companyPath={this.state.completePath}
                    infoWindowContent={this.state.infoWindowContent}
                  />
                </div>
                <div className="col-md-4"  >

                  <div className="trip-detail-voilations" style={{ height: this.state.mapHeight, overflowY: 'scroll', border: '1px solid #F1F1F1', margin: '2px' }}>
                    <div className="voilations-section ">
                      <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshAcceleration" })}>
                        <input type="checkbox" checked={this.state.enableHarshAcceleration} onChange={() => this.changeViolationData.bind(this, { type: "harshAcceleration" })} onClick={() => this.changeViolationData.bind(this, { type: "harshAcceleration" })} value={1} />
                        <label htmlFor="checkbox" className="red-bg-bd" />
                      </div>
                      <div className="col">
                        <span className="pull-left voilation-name">Harsh Acceleration</span>
                        <span className="pull-right voilation-num">{this.state.voilations.HARSH_ACCELERATION || 0} times</span>
                      </div>

                    </div>
                    <div className="voilations-section">
                      <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshBrakes" })}>
                        <input type="checkbox" checked={this.state.enableHarshBrakes} onChange={() => this.changeViolationData.bind(this, { type: "harshBrakes" })} onClick={() => this.changeViolationData.bind(this, { type: "harshBrakes" })} value={1} />
                        <label htmlFor="checkbox" className="orange-bg-bd" />
                      </div>
                      <div className="col">
                        <span className="pull-left voilation-name">Sudden Brake</span>
                        <span className="pull-right voilation-num">{this.state.voilations.HARSH_BRAKES || 0} times</span>
                      </div>

                    </div>
                    <div className="voilations-section">
                      <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshTurn" })}>
                        <input type="checkbox" checked={this.state.enableHarshTurn} onChange={() => this.changeViolationData.bind(this, { type: "harshTurn" })} onClick={() => this.changeViolationData.bind(this, { type: "harshTurn" })} value={1} />
                        <label htmlFor="checkbox" className="blue-bg-bd" />
                      </div>
                      <div className="col">
                        <span className="pull-left voilation-name">Sharp Turn</span>
                        <span className="pull-right voilation-num">{this.state.voilations.HARSH_TURN || 0} times</span>
                      </div>

                    </div>

                    <div className="voilations-section">
                      <div className="round" onClick={this.changeViolationData.bind(this, { type: "overSpeeding" })}>
                        <input type="checkbox" checked={this.state.enableOverSpeeding} onChange={() => this.changeViolationData.bind(this, { type: "overSpeeding" })} onClick={() => this.changeViolationData.bind(this, { type: "overSpeeding" })} value={1} />
                        <label htmlFor="checkbox" className="purple-bg-bd" />
                      </div>
                      <div className="col">
                        <span className="pull-left voilation-name">Overspeeding</span>
                        <span className="pull-right voilation-num">{this.state.voilations.SPEED_LIMIT || 0} times</span>
                      </div>

                    </div>

                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Unfasten Seatbelt</span>
                      <span className="pull-right voilation-num">{this.state.voilations.UNFASTEN_SEATBELT || 0} times</span>
                    </div>
                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Ignition</span>
                      <span className="pull-right voilation-num">{this.state.voilations.IGNITION_ON || 0} times</span>
                    </div>

                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">GeoFence Entry</span>
                      <span className="pull-right voilation-num">{this.state.geoFenceCounts.VEHICLE_ENTERING || 0} times</span>
                    </div>
                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">GeoFence Exit</span>
                      <span className="pull-right voilation-num">{this.state.geoFenceCounts.VEHICLE_LEAVING || 0} times</span>
                    </div>
                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Fatigue</span>
                      <span className="pull-right voilation-num">{this.state.fatigueList.length} times</span>
                    </div>

                    {/* <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Duration</span>
                                            <span className="pull-right voilation-num">{this.state.duration + " Min" || "N/A"} </span>
                                        </div> */}

                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Stops</span>
                      <span className="pull-right voilation-num">{this.state.stops.length || "N/A"} </span>
                    </div>
                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Distance</span>
                      <span className="pull-right voilation-num">{this.state.distance || 0} KM</span>
                    </div>
                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Idle Time</span>
                      <span className="pull-right voilation-num">{this.state.idleTime || 0} Min</span>
                    </div>
                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Idle Count</span>
                      <span className="pull-right voilation-num">{this.state.idle.length || 0}</span>
                    </div>


                    <div className="voilations-info-section">
                      <span className="pull-left voilation-name">Average Speed</span>
                      <span className="pull-right voilation-num">{this.state.averageSpeed ? this.state.averageSpeed + ' KM/H' : "N/A"} </span>
                    </div>
                    {/* <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Fuel Consumed</span>
                                            <span className="pull-right voilation-num">{this.state.fuelConsumed ? this.state.fuelConsumed + ' LTR' : "N/A"} </span>
                                        </div> */}
                  </div>

                </div>
              </div>
              <div className="row" >
                <div className="col-md-3">
                  <label >Speed</label>
                  <Select
                    name="form-field-name"

                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Speed"
                    defaultValue={this.state.playbackSpeed}
                    onChange={this.changePlaybackSpeed}
                    options = {
                      this.state.companyId === "664f4f93e440b860d2c1c93c"
                        ? [
                            { value: 1, label: '1x' },
                            { value: 2, label: '2x' },
                            { value: 4, label: '4x' },
                          ]
                        : [
                            { value: 1, label: '1x' },
                            { value: 2, label: '2x' },
                            { value: 4, label: '4x' },
                            { value: 6, label: '6x' },
                          ]
                    }                    
                    />

                </div>
                <div className="col-md-2">
                  <button
                    className="btn auxo-primary-btn"
                    style={{ marginTop: '25px', width: '150px' }}
                    onClick={this.playPause}
                  >{this.state.playbackStatus === 'playing' ? 'Pause' : 'Play'}</button>

                </div>
                <div className="col-md-2">
                  <button
                    className="btn auxo-primary-btn"
                    style={{ marginTop: '25px', width: '150px' }}
                    onClick={this.resetPlayback}
                  >Reset</button>

                </div>
              </div>


              <div className="row" style={{ marginTop: '5px', height: '40vh', overflowY: 'scroll', }} ref={this.gridElement}>
                <div className="col-md-12" >
                  <Table bordered >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date Time</th>
                        <th>Location</th>
                        <th>Speed</th>
                        <th>Angle</th>
                        <th>Status</th>
                        <th>Event</th>
                      </tr>
                    </thead>
                    <tbody ref={this.tbody}>
                      {this.state.completePath && this.state.completePath.map((event, index) => {
                        return (<tr style={{ background: index === this.state.currentIndex ? '#00abce69' : 'none' }} onClick={(event) => this.playbackRowSelected(index, event)} key={index} >
                          <td style={{ height: '42px' }}><span style={{ height: '42px' }}>{index + 1}</span></td>
                          <td style={{ height: '42px' }}>{moment(event.time, 'MM/DD/YYYY HH:mm:SS').format('DD-MM-YYYY HH:mm:SS')}</td>
                          <td style={{ height: '42px' }}>{this.state.companyId !== '5f9fe73eb44c0b1a122a38fd' ? event.latlng ? `${event.latlng.lat},${event.latlng.lng}` : '' : event.address}</td>
                          <td style={{ height: '42px' }}>{event.speed}</td>
                          <td style={{ height: '42px' }}>{event.angle}</td>
                          <td style={{ height: '42px' }}>{event.status}</td>
                          <td style={{ height: '42px' }}>{event.eventType}</td>
                        </tr>);
                      })}

                    </tbody>
                  </Table>
                </div>

              </div>
            </div> : <div></div>}


          </div>}

        </div>

        {
          this.state.isSearchCriteriaVisible
            && !this.state.loadingPlateNumber
            && this.state.companyId === "664f4f93e440b860d2c1c93c" ? (<ChangeModalWithOutStepper isVisible={this.state.isSearchCriteriaVisible}
              onCancel={() => {

                if (this.state.selectedVehicle) {
                  this.setState({ ...this.state, isSearchCriteriaVisible: false });
                } else {
                  this.props.history.push('/')
                }

              }}
              title={'Search Criteria'}
              onConfirm={this.searchNewCriteria}
              vehicles={this.props.vehiclesPlateNumber}
              companyId={this.state.companyId}
            />) : this.state.isSearchCriteriaVisible
            && !this.state.loadingPlateNumber && <ChangeModal isVisible={this.state.isSearchCriteriaVisible}
              onCancel={() => {

                if (this.state.selectedVehicle) {
                  this.setState({ ...this.state, isSearchCriteriaVisible: false });
                } else {
                  this.props.history.push('/')
                }

              }}
              title={'Search Criteria'}
              onConfirm={this.searchNewCriteria}
              vehicles={this.props.vehiclesPlateNumber}
              companyId={this.state.companyId}
          />
        }

      </div>


    );
  }
}





const ChangeModal = (props) => {


  const getDefaultVehicle = () => {
    const storedVehicle = localStorage.getItem('selectedVehicle');
    if (storedVehicle) {
      return JSON.parse(storedVehicle);
    } else if (props.vehicles && props.vehicles.length > 0) {
      return {
        label: props.vehicles[0].plateNumber,
        value: props.vehicles[0]._id
      };
    } else {
      return null;
    }
  };


  const [selectedVehicle, setSelectedVehicle] = useState(getDefaultVehicle);

  useEffect(() => {
    if (selectedVehicle) {
      localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
    }
  }, [selectedVehicle]);




  const [errorDate] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const steps = [{ title: 'Select Vehicle' }, { title: `Select Date` }];
  const [activeStep, setActiveStep] = React.useState(0);

  function handleNext() {
    const { companyId } = props;
    if (activeStep === 1) {
      const hasValidSelectedDate = selectedDate && moment(selectedDate).isValid();
      const hasValidEndDate = endDate && moment(endDate).isValid();
      if (!hasValidSelectedDate) {
        return toastr.error("Start date is missing.");
      } else if (!hasValidEndDate) {
        return toastr.error("End date is missing.");
      }

      var difference = 0;
      companyId === "664f4f93e440b860d2c1c93c"
        ? difference = moment(endDate).diff(moment(selectedDate), 'days')
        : difference = moment(endDate).diff(moment(selectedDate), 'minutes');
  
        console.log("difference -- ", difference)
  
      if (difference <= 0) {
        return toastr.error("Start date should be less than the end date.");
      } else if (companyId === "664f4f93e440b860d2c1c93c" && difference > 15) {
        return toastr.error("You cannot select more than 15 days.");
      } else if (difference > 1440) {
        return toastr.error("You cannot select more than 24 hours.");
      }
      // localStorage.setItem('playbackSelectedVechicle', ...selectedVehicle)
      return props.onConfirm({ selectedVehicle, selectedDate: moment(selectedDate), endDate: moment(endDate) });
    }
    setActiveStep(activeStep => activeStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  return (
    <Modal
      visible={props.isVisible}
      onClickBackdrop={() => { }}
      dialogClassName='wizard-modal-dialog'>
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={props.onCancel}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className='modal-heading row'>
        <div className="col-md-offset-3 col-md-6">
          <p className="driver-heading">{props.title}</p>
        </div>
      </div>
      <div className="modal-body">
        <div className='stepper-container m-b-20-px'>
          <Stepper activeStep={activeStep} steps={steps} circleFontSize={13} titleFontSize={13} />
        </div>
        <div style={{ minHeight: "140px" }}>
          {activeStep === 0 && (
            <div>
              <label>Select Vehicle</label>
              <Select
                name="form-field-name"
                isClearable={false}
                isSearchable={true}
                placeholder="Select Vehicle"
                defaultValue={selectedVehicle}
                onChange={setSelectedVehicle}
                options={props.vehicles &&
                  props.vehicles.map(item => {
                    return { value: item._id, label: item.plateNumber }
                  })} />
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <label>Start Date</label>
              <DateTime
                value={selectedDate}
                dateFormat="DD-MM-YYYY"
                timeFormat="hh:mm A"
                onChange={setSelectedDate} />
              {errorDate && <div><label style={{ color: 'red' }}>{errorDate}</label></div>}
              <label>End Date</label>
              <DateTime
                value={endDate}
                dateFormat="DD-MM-YYYY"
                timeFormat="hh:mm A"
                onChange={setEndDate} />
            </div>
          )}
        </div>
        <div style={{ marginBottom: "2px" }}>
          <div>
            <button
              disabled={activeStep === 0}
              onClick={handleBack}
              className="btn btn-success"
              variant="contained"
              style={{ outline: 'none' }}>
              Back
            </button>
            <button
              onClick={handleNext}
              className="btn btn-contained"
              disabled={activeStep === 2}
              style={{ outline: 'none' }}>
              {activeStep >= steps.length - 1 ? 'Confirm' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}


const ChangeModalWithOutStepper = (props) => {
  const getDefaultVehicle = () => {
    const storedVehicle = localStorage.getItem('selectedVehicle');
    if (storedVehicle) {
      return JSON.parse(storedVehicle);
    } else if (props.vehicles && props.vehicles.length > 0) {
      return {
        label: props.vehicles[0].plateNumber,
        value: props.vehicles[0]._id
      };
    } else {
      return null;
    }
  };

  const [selectedVehicle, setSelectedVehicle] = useState(getDefaultVehicle);
  const [selectedDate, setSelectedDate] = useState(() => {
    const now = new Date();
    return new Date(now.setHours(0, 0, 0, 0));
  });

  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    return new Date(now.setHours(23, 59, 59, 999));
  });

  useEffect(() => {
    if (selectedVehicle) {
      localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
    }
  }, [selectedVehicle]);

  const handleConfirm = () => {
    const { companyId } = props;
    const hasValidSelectedDate = selectedDate && moment(selectedDate).isValid();
    const hasValidEndDate = endDate && moment(endDate).isValid();

    if (!hasValidSelectedDate) {
      return toastr.error("Start date is missing.");
    } else if (!hasValidEndDate) {
      return toastr.error("End date is missing.");
    }

    const startDateTime = moment(selectedDate);
    const endDateTime = moment(endDate);

    // Initial check for the same day
    if (startDateTime.isSame(endDateTime, 'day')) {
      if (endDateTime.isBefore(startDateTime)) {
        return toastr.error("Error: The end time should be greater than the start time.");
      }
    } else if (endDateTime.isBefore(startDateTime)) {
      return toastr.error("Start date should be less than the end date.");
    }


    var difference = 0;
    var isSameDay = moment(endDate).isSame(moment(selectedDate), 'day');
    
    if (companyId === "664f4f93e440b860d2c1c93c") {
      if (isSameDay) {
        difference = moment(endDate).diff(moment(selectedDate), 'minutes');
      } else {
        difference = moment(endDate).diff(moment(selectedDate), 'days');
      }
    } else {
      difference = moment(endDate).diff(moment(selectedDate), 'minutes');
    }
    
    console.log("difference -- ", difference);
    
    if (difference <= 0) {
      return toastr.error("Start date should be less than the end date.");
    } else if (companyId === "664f4f93e440b860d2c1c93c") {
      if (!isSameDay && difference > 15) {
        return toastr.error("You cannot select more than 15 days.");
      } else if (isSameDay && difference > 1440) {
        return toastr.error("You cannot select more than 24 hours.");
      }
    } else if (difference > 1440) {
      return toastr.error("You cannot select more than 24 hours.");
    }
    



    // var difference = companyId === "664f4f93e440b860d2c1c93c"
    //   ? moment(endDate).diff(moment(selectedDate), 'days')
    //   : moment(endDate).diff(moment(selectedDate), 'minutes');
    // const startDateTime = moment(selectedDate);
    // const endDateTime = moment(endDate);

    // if (startDateTime.isSame(endDateTime, 'day')) {
    //   if (startDateTime.isSame(endDateTime, 'minute')) {
    //     return toastr.error("Error: The end time should be greater than the start time.");
    //   } else if (endDateTime.isBefore(startDateTime)) {
    //     return toastr.error("Error: The end time should be greater than the start time.");
    //   } else {
    //     difference = 1;
    //     console.log("The difference is valid.");
    //   }
    // } else {
    //   difference = 1;
    //   console.log("The difference is valid.");
    // }

    // if (difference <= 0) {
    //   return toastr.error("Start date should be less than the end date.");
    // } else if (companyId === "664f4f93e440b860d2c1c93c" && difference > 15) {
    //   return toastr.error("You cannot select more than 15 days.");
    // } else if (companyId !== "664f4f93e440b860d2c1c93c" && difference > 1440) {
    //   return toastr.error("You cannot select more than 24 hours.");
    // }


    return props.onConfirm({ selectedVehicle, selectedDate: moment(selectedDate), endDate: moment(endDate) });
  };

  return (
    <Modal
      visible={props.isVisible}
      onClickBackdrop={() => { }}
      dialogClassName='wizard-modal-dialog'>
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={props.onCancel}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className='modal-heading row'>
        <div className="col-md-offset-3 col-md-6">
          <p className="driver-heading">{props.title}</p>
        </div>
      </div>
      <div className="modal-body">
        <div style={{ minHeight: "140px" }}>
          <div>
            <label>Select Vehicle</label>
            <Select
              name="form-field-name"
              isClearable={false}
              isSearchable={true}
              placeholder="Select Vehicle"
              defaultValue={selectedVehicle}
              onChange={setSelectedVehicle}
              options={props.vehicles &&
                props.vehicles.map(item => {
                  return { value: item._id, label: item.plateNumber }
                })} />
          </div>
          <div>
            <label>Start Date</label>
            <DateTime
              value={selectedDate}
              dateFormat="DD-MM-YYYY"
              timeFormat="hh:mm A"
              onChange={setSelectedDate} />
            <label>End Date</label>
            <DateTime
              value={endDate}
              dateFormat="DD-MM-YYYY"
              timeFormat="hh:mm A"
              onChange={setEndDate} />
          </div>
        </div>
        <div style={{ marginBottom: "2px" }}>
          <div>
            <button
              onClick={handleConfirm}
              className="btn btn-contained mt-3"
              style={{ outline: 'none' }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
TrackPlayback.propTypes = {};
TrackPlayback.defaultProps = {};
const mapStateToProps = state => ({
  vehiclesPlateNumber: state.things.vehiclesPlateNumber,
  vehicleDetail: state.things.vehicleDetail,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TrackPlayback));
