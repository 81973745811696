import {isEmpty} from 'lodash';
import {isEmptyValue, isAlphaNumeric} from './validator';

export function validateInput(data) {
    let errors = {};

    if (isEmptyValue(data.name)) {
        errors.name = 'This field is required';
    }
    else if (data.name.length > 100) {
        errors.name = 'Max 100 characters are allowed in name';
    }
    if (!isAlphaNumeric(data.description)) {
        errors.description = 'Description must be alphanumeric';
    }
    else if (data.description.length > 200) {
        errors.description = 'Max 200 characters are allowed in description.';
    }
    
    if(!data.radius){
        errors.radius = 'Radius is required';
    }
    else if (data.radius<100){
        errors.radius = 'Radius should be greater than 100 meters';
    }

    return {errors, isValid: isEmpty(errors)};
}
