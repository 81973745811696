const groupThList = (canUpdate) =>  {
  const thList = [
    {
      text: 'GROUP NAME',
      dataField: 'name',
      align: 'center',
      headerAlign: 'center'
    },
    {
      text: 'TOTAL VEHICLES',
      dataField: 'totalVehicles',
      align: 'center',
      headerAlign: 'center'
    },
    {
      text: 'ASSIGNED VEHICLES',
      dataField: 'assignedvehicles',
      align: 'center',
      headerAlign: 'center'
    },
    {
      text: 'UNASSIGNED VEHICLES',
      dataField: 'unAssignedvehicles',
      align: 'center',
      headerAlign: 'center'
    },
    {
      text: 'NUMBER OF USERS',
      dataField: 'users',
      align: 'center',
      headerAlign: 'center'
    }
  ];
  if (canUpdate) thList.push({ text: 'ACTION', dataField: 'action', align: 'center', headerAlign: 'center' });
  return thList;
};

export default groupThList;
