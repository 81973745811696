import * as  R from 'ramda';
import moment from 'moment';
import React from 'react';

const IO_EVENT_MAPPING = {
  a239: 'Ignition',
  a240: 'Movement',
  a80: 'Data_Mode',
  a21: 'GSM_Signal',
  a200: 'Sleep_Mode',
  a69: 'GNSS_Status',
  a181: 'GNSS_PDOP',
  a182: 'GNSS_HDOP',
  a66: 'External_Voltage',
  a24: 'Speed',
  a205: 'GSM_Cell_ID',
  a206: 'GSM_Area_Code',
  a241: 'Active_GSM_Operator',
  a199: 'Trip_Odometer',
  a16: 'Total_Odometer',
  a1: 'Digital_Input_1',
  a9: 'Analog_input_1',
  a179: 'Digital_Output_1',
  a12: 'Fuel_Used_GPS',
  a13: 'Fuel_Rate_GPS',
  a17: 'Axis_X',
  a18: 'Axis_Y',
  a19: 'Axis_Z',
  a11: 'SIM_ICCID_number_part_1',
  a10: 'SD_Status',
  a15: 'Eco_Score',
  a30: 'Number_of_DTC',
  a31: 'Calculated_engine_load_value',
  a32: 'Engine_coolant_temperature',
  a33: 'Short_term_fuel_trim_1',
  a34: 'Fuel_pressure',
  a35: 'Intake_manifold_absolute_pressure',
  a36: 'Engine_RPM',
  a37: 'Vehicle_speed',
  a38: 'Timing_advance',
  a39: 'Intake_air_temperature',
  a40: 'MAF_air_flow_rate',
  a41: 'Throttle_position',
  a42: 'Run_time_since_engine_start',
  a43: 'Distance_traveled_MIL_on',
  a44: 'Relative_fuel_rail_pressure',
  a45: 'Direct_fuel_rail_pressure',
  a46: 'Commanded_EGR',
  a47: 'EGR_error',
  a48: 'Fuel_level',
  a49: 'Distance_ormattin_since_codes_cleared',
  a50: 'Barometric_pressure',
  a51: 'Control_module_voltage',
  a52: 'Absolute_load_value',
  a53: 'Ambient_air_temperature',
  a54: 'Time_run_with_MIL_on',
  a55: 'Time_since_trouble_codes_cleared',
  a56: 'Absolute_fuel_rail_pressure',
  a57: 'Hybrid_battery_pack_remaining_life',
  a58: 'Engine_oil_temperature',
  a59: 'Fuel_injection_timing',
  a60: 'Engine_fuel_rate',
  a175: 'Auto_Geofence',
  a250: 'Trip',
  a255: 'Over_Speeding',
  a251: 'Idling',
  a253: 'Green_driving_type',
  a246: 'Towing',
  a247: 'Crash_detection',
  a248: 'Immobilizer',
  a254: 'Green_driving_value',
  a249: 'Jamming',
  a14: 'SIM_ICCID_number_part_2',
  a67: 'Battery_Voltage',
  a68: 'Battery_Current',
  a72: 'Wire_temperatur_sensor_data',
  a86: 'humidity',
  a25: 'temperature',
  a252: 'Unplug',
  a3: 'Generator',
  ts: 'gps_time',
  timestamp: 'haveTimestamp',
  latlng: 'latlng',
  a270: 'FuelValue',
  a29: 'bleBattery',

};

const getData = (plate) => (state) => {
  let _state = R.path(['_source', 'state'])(state)
 // if (_state.tcxn) {
  //   _state = { connection_status: _state.tcxn.connection_status };
  // }
  if(_state.tcxn){
    _state.connection_status = _state.tcxn.connection_status;
    delete _state.tcxn;
  }
  const pairedState = R.toPairs(_state);
  const replaceKeysMap = (obs) => {
    const [key, value] = obs;
    return [IO_EVENT_MAPPING[key] || key, value];
  }
  const mappedState = R.map(replaceKeysMap)(pairedState);
  const obs = R.fromPairs(mappedState);
  const withDefaultValue = R.defaultTo('N/A');
  const gpsTime = obs['gps_time'] ? moment(obs['gps_time']).format('DD/MM/YYYY hh:mm:ss A') : 'N/A';
  const fuelValue = obs['FuelValue'] ? ((47.649) + (2.1224 * obs['FuelValue']) + (0.00007 * (obs['FuelValue'] * obs['FuelValue']))).toFixed(3) : 'N/A';
//   const latlng = <a
//   href={`https://www.google.com/maps/dir/${obs['latlng']}`}
//   target="_blank"
//   rel="noopener noreferrer">
//  {withDefaultValue(obs['latlng'])}
// </a>;

const latlng = withDefaultValue(obs['latlng']);
const Wire_temperatur_sensor_data = obs['Wire_temperatur_sensor_data'] ||  obs['Wire_temperatur_sensor_data'] === 0 ? obs['Wire_temperatur_sensor_data'] / 10 : 'N/A';
const temperature = obs['temperature'] ||  obs['temperature'] === 0 ? obs['temperature'] / 10 : 'N/A';
const humidity = obs['humidity'] ||  obs['humidity'] === 0 ? obs['humidity'] / 10 : 'N/A';
const bleBattery = obs['bleBattery'] ||  0;

  const withOpenValue = (value) => {
    if (value === 'N/A') return value;
    if (value === 1) return 'ON';
    return 'OFF';
  }
  const withTruthyValue = (value) => {
    if (value === 'N/A') return value;
    if (value === 1) return 'Yes';
    return 'No';
  }
  const withDefaultOpenValue = R.compose(withOpenValue, withDefaultValue);
  const withDefaultTruthyValue = R.compose(withTruthyValue, withDefaultValue);

  const data = JSON.parse(localStorage.getItem("companyInfo"));
  const companyId = data.companyId;


  let values = {};
  if (companyId === '603f24e4fe792949f28592c4') {
    values = {
      plate,
      gpsTime,
      sensorData:Wire_temperatur_sensor_data,
      generator:withDefaultOpenValue(obs['Generator']),
    };
  }
  if (companyId === '60e834a9a035ca2af17a4521') {
    values = {
      plate,
      gpsTime,
      latlng,
      sensorData:temperature,
      humidity,
      bleBattery,

    };
  }
  else if (companyId === '603f7be06a23817d9e06a2f4') {
    values = {
      plate,
      gpsTime,
      fuelValue,
      latlng,
      ignition:withDefaultTruthyValue(obs['Ignition']),
      speed:withDefaultValue(obs['Speed']),
      trip:withDefaultTruthyValue(obs['Trip']),
      idling:withDefaultTruthyValue(obs['Idling']),
    };
  }
  else {
    values = {
      plate,
      gpsTime,
      latlng,
      sensorData:Wire_temperatur_sensor_data,
    };
  }

  return values;
}

export default getData;

