import { isEmpty } from "lodash";

export const validateInput = (data) => {
    let errors = {};

    if (data.selectedVehicles.length === 0) {
        errors.vehicles = "Please select vehicles";
    }
    if(data.selectedViolations.length === 0) {
        errors.violations = "Please select violations";
    }
    if(isNaN(Date.parse(data.startDate))){
        errors.startDate = "Enter a start date"
    }
    if(isNaN(Date.parse(data.endDate))){
        errors.endDate = "Enter a end date"
    }
    if(data.startDate > data.endDate){
        errors.startDate = "Start date cannot be greater than end date"
    }
    return { errors, isValid: isEmpty(errors) };
}