import React, { Component } from 'react';
import { connect } from "react-redux";
import Things from "../../../ducks/Things";
import Modal from 'react-bootstrap4-modal';
import { TripMap } from '../../common/maps/tripMap';
import { bindActionCreators } from "redux";
import './index.css';
import { Spinner } from '../../common/Spinner/index';

class TripMapView extends Component {
    // define voilaions bool in state and function;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            trip: {},
            enableHarshAcceleration: true,
            enableHarshBrakes: true,
            enableHarshTurn: true,
            enableOverSpeeding: true,
            enableUnfastenSeatbelt: true,
            enableIgnition: false,
            enableIdle: true,

        }
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    loadData = async () => {

        const tripId = this.props.tripId;

        if (tripId) {
            this.setState({
                loading: true,
                trip: {},
                enableHarshAcceleration: true,
                enableHarshBrakes: true,
                enableHarshTurn: true,
                enableOverSpeeding: true,
                enableUnfastenSeatbelt: true,
                enableIgnition: false,
                enableIdle: true,

            })
            await this.props.actions.GetTripDetails({ tripId });
            this.setState({
                ...this.state,
                loading: false,
                trip: this.props.trip,
                voilationListTypes: {...this.props.trip.voilationListTypes, ignitionOn: this.props.trip.ignitionOn, idle: this.props.trip.idle}
            })
        }
    }

    changeViolationData(data) {

        let voilationListTypes = Object.assign({}, this.state.voilationListTypes);
        if (data.type === "harshAcceleration") {


            voilationListTypes.HARSH_ACCELERATION = this.state.enableHarshAcceleration ? [] : this.props.trip.voilationListTypes.HARSH_ACCELERATION

            this.setState({
                ...this.state,
                voilationListTypes,
                enableHarshAcceleration: !this.state.enableHarshAcceleration
            })

        }
        else if (data.type === "harshBrakes") {



            voilationListTypes.HARSH_BRAKES = this.state.enableHarshBrakes ? [] : this.props.trip.voilationListTypes.HARSH_BRAKES

            this.setState({
                ...this.state,
                voilationListTypes,
                enableHarshBrakes: !this.state.enableHarshBrakes
            })
        }
        else if (data.type === "harshTurn") {

            voilationListTypes.HARSH_TURN = this.state.enableHarshTurn ? [] : this.props.trip.voilationListTypes.HARSH_TURN

            this.setState({
                ...this.state,
                voilationListTypes,
                enableHarshTurn: !this.state.enableHarshTurn
            })

        }
        else if (data.type === "overSpeeding") {

            voilationListTypes.SPEED_LIMIT = this.state.enableOverSpeeding ? [] : this.props.trip.voilationListTypes.SPEED_LIMIT

            this.setState({
                ...this.state,
                voilationListTypes,
                enableOverSpeeding: !this.state.enableOverSpeeding
            })
        }
        
        else if (data.type === "unfastenSeatbelt") {

            voilationListTypes.UNFASTEN_SEATBELT = this.state.enableUnfastenSeatbelt ? [] : this.props.trip.voilationListTypes.UNFASTEN_SEATBELT

            this.setState({
                ...this.state,
                voilationListTypes,
                enableUnfastenSeatbelt: !this.state.enableUnfastenSeatbelt
            })
        }
        else if (data.type === "ignition") {

            voilationListTypes.ignitionOn = this.state.enableIgnition ? [] : this.props.trip.ignitionOn

            this.setState({
                ...this.state,
                voilationListTypes,
                enableIgnition: !this.state.enableIgnition
            })
        }
        else if (data.type === "idle") {

            voilationListTypes.idle = this.state.enableIdle ? [] : this.props.trip.idle

            this.setState({
                ...this.state,
                voilationListTypes,
                enableIdle: !this.state.enableIdle
            })
        }

    }

    componentWillUnmount() {
        //this.props.ref(undefined)
        this
            .props
            .actions
            .RemoveTripDetails();
    }
    async navigateToTrips() {
        await this
            .props
            .actions
            .RemoveTripDetails();
        this.props.closePopup()
    }

    async makeTripPrivate() {
        await this
            .props
            .actions
            .makeTripPrivateInDatabase(this.state.trip._id);
        this.props.closePopup()
    }

    render() {
        const loader = <Spinner key="1" />

        let trip = this.state.trip;
        let voilationListTypes = this.state.voilationListTypes;
        return (

            <Modal visible={this.props.tripVisible} dialogClassName='trip-modal-width' >
                <div className="popubtnparent">
                    <div className="ispriavteText">
                        {this.props.permissions.companyFeatures.indexOf("VEHICLE_PRIVATE_MODE") !== -1 && !trip?.isPrivate && <a onClick={this.makeTripPrivate.bind(this)}>
                            <span >Mark as private trip</span>
                        </a>}
                    </div>
                    <div className="popup-close-btn closeBtn">
                        <button type="button" className="close" aria-label="Close" onClick={this.navigateToTrips.bind(this,{ 'trip': trip })}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                {this.state.loading ? [loader] : <div>

                    {trip.pathCords ?
                        <div>

                            <div className="modal-body">
                                <div>

                                    <TripMap
                                        distance={trip.distance}
                                        geofencesArea={trip.geofencesArea}
                                        path={trip.pathCords}
                                        harshAcceleration={voilationListTypes.HARSH_ACCELERATION}
                                        harshTurn={voilationListTypes.HARSH_TURN}
                                        harshBrakes={voilationListTypes.HARSH_BRAKES}
                                        overSpeeding={voilationListTypes.SPEED_LIMIT}
                                        ignitionOn={voilationListTypes.ignitionOn}
                                        unfastenSeatbelt={voilationListTypes.UNFASTEN_SEATBELT}
                                        geoFence={trip.geoFence}
                                        idle={voilationListTypes.idle}
                                        fatigueList={trip.fatigueList}
                                          />
                                </div>
                                <div className="trip-detail-voilations">
                                    <div className="voilations-section ">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshAcceleration" })}>
                                            <input type="checkbox" checked={this.state.enableHarshAcceleration} onChange={() => this.changeViolationData.bind(this, { type: "harshAcceleration" })} onClick={() => this.changeViolationData.bind(this, { type: "harshAcceleration" })} value={1} />
                                            <label htmlFor="checkbox" className="red-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Harsh Acceleration</span>
                                            <span className="pull-right voilation-num">{trip.voilations.HARSH_ACCELERATION || 0} times</span>
                                        </div>

                                    </div>
                                    <div className="voilations-section">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshBrakes" })}>
                                            <input type="checkbox" checked={this.state.enableHarshBrakes} onChange={() => this.changeViolationData.bind(this, { type: "harshBrakes" })} onClick={() => this.changeViolationData.bind(this, { type: "harshBrakes" })} value={1} />
                                            <label htmlFor="checkbox" className="orange-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Sudden Brake</span>
                                            <span className="pull-right voilation-num">{trip.voilations.HARSH_BRAKES || 0} times</span>
                                        </div>

                                    </div>
                                    <div className="voilations-section">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshTurn" })}>
                                            <input type="checkbox" checked={this.state.enableHarshTurn} onChange={() => this.changeViolationData.bind(this, { type: "harshTurn" })} onClick={() => this.changeViolationData.bind(this, { type: "harshTurn" })} value={1} />
                                            <label htmlFor="checkbox" className="blue-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Sharp Turn</span>
                                            <span className="pull-right voilation-num">{trip.voilations.HARSH_TURN || 0} times</span>
                                        </div>

                                    </div>

                                    <div className="voilations-section">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "overSpeeding" })}>
                                            <input type="checkbox" checked={this.state.enableOverSpeeding} onChange={() => this.changeViolationData.bind(this, { type: "overSpeeding" })} onClick={() => this.changeViolationData.bind(this, { type: "overSpeeding" })} value={1} />
                                            <label htmlFor="checkbox" className="purple-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Overspeeding</span>
                                            <span className="pull-right voilation-num">{trip.voilations.SPEED_LIMIT || 0} times</span>
                                        </div>

                                    </div>
                                    <div className="voilations-section">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "unfastenSeatbelt" })}>
                                            <input type="checkbox" checked={this.state.enableUnfastenSeatbelt} onChange={() => this.changeViolationData.bind(this, { type: "unfastenSeatbelt" })} onClick={() => this.changeViolationData.bind(this, { type: "unfastenSeatbelt" })} value={1} />
                                            <label htmlFor="checkbox" className="green-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Unfasten Seatbelt</span>
                                            <span className="pull-right voilation-num">{trip.voilations.UNFASTEN_SEATBELT || 0} times</span>
                                        </div>
                                      
                                    </div>

                                    <div className="voilations-section">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "ignition" })}>
                                            <input type="checkbox" checked={this.state.enableIgnition} onChange={() => this.changeViolationData.bind(this, { type: "ignition" })} onClick={() => this.changeViolationData.bind(this, { type: "ignition" })} value={1} />
                                            <label htmlFor="checkbox" className="green-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Ignition</span>
                                            <span className="pull-right voilation-num">{trip.ignitionOnCounts || 0} times</span>
                                        </div>

                                    </div>
                                    <div className="voilations-section">
                                        <div className="round" onClick={this.changeViolationData.bind(this, { type: "idle" })}>
                                            <input type="checkbox" checked={this.state.enableIdle} onChange={() => this.changeViolationData.bind(this, { type: "idle" })} onClick={() => this.changeViolationData.bind(this, { type: "idle" })} value={1} />
                                            <label htmlFor="checkbox" className="grey-bg-bd" />
                                        </div>
                                        <div className="col">
                                            <span className="pull-left voilation-name">Idle</span>
                                            <span className="pull-right voilation-num">{trip.idle.length || 0} times</span>
                                        </div>

                                    </div>
                                    

                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Idle Time</span>
                                        <span className="pull-right voilation-num">{trip.idleTime || 0} Min</span>
                                    </div>
                                   
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">GeoFence Entry</span>
                                        <span className="pull-right voilation-num">{trip.geoFenceCounts.VEHICLE_ENTERING || 0} times</span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">GeoFence Exit</span>
                                        <span className="pull-right voilation-num">{trip.geoFenceCounts.VEHICLE_LEAVING || 0} times</span>
                                    </div>

                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Fatigue</span>
                                        <span className="pull-right voilation-num">{trip.fatigueList?trip.fatigueList.length: 0} times</span>
                                    </div>

                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Duration</span>
                                        <span className="pull-right voilation-num">{trip.duration + " Min" || "N/A"} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Distance</span>
                                        <span className="pull-right voilation-num">{trip.distance || 0} KM</span>
                                    </div>
                                    

                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Driver Name</span>
                                        <span className="pull-right voilation-num">{trip.driverName || ""} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Driver Serial Number</span>
                                        <span className="pull-right voilation-num">{trip.driverSerialNumber || ""} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Average Speed</span>
                                        <span className="pull-right voilation-num">{trip.avg_speed ? trip.avg_speed + ' KM/H' : "N/A"} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Max Speed</span>
                                        <span className="pull-right voilation-num">{trip.maxSpeed ? trip.maxSpeed + ' KM/H' : "N/A"} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Fuel Consumed</span>
                                        <span className="pull-right voilation-num">{trip.fuelConsumed ? trip.fuelConsumed + ' LTR' : "N/A"} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Fuel Average</span>
                                        <span className="pull-right voilation-num">{trip.fuelAverage ? trip.fuelAverage + " KM/LTR" : "N/A"} </span>
                                    </div>
                                    <div className="voilations-info-section">
                                        <span className="pull-left voilation-name">Fuel Cost</span>
                                        <span className="pull-right voilation-num">{trip.priceConsumed ? trip.priceConsumed + " RS" : "N/A"} </span>
                                    </div>

                                </div>
                            </div>




                        </div> : ""}


                </div>}


            </Modal>

        );
    }
}

TripMapView.propTypes = {};
TripMapView.defaultProps = {};
const mapStateToProps = state => ({ trip: state.things.trip,permissions: state.permissions
 });

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TripMapView);
