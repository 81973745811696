import Duck from 'extensible-duck'
import axios from "axios";
import { scheduleEndPoints } from "../api/endpoints";
import * as _ from "lodash";

export default new Duck({
  namespace: 'AuxoSchedule',
  store: 'schedule',
  types: [
    'SCHEDULE_IS_LOADING', 'DETAILS_FETCHED', 'SCHEUDLES_FETCHED', 'DRIVERS_FETCHED', 'UPDATE_SCHEDULE', 'DELETE_SCHEDULE', 'SLOT_DELETED'
  ],
  initialState: {
    schedule: {},
    schedules: [],
    drivers: [],
    isLoading: false
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.SCHEDULE_IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };

      case duck.types.SCHEUDLES_FETCHED:
        return {
          ...state,
          schedules: action.schedules
        };
      case duck.types.UPDATE_SCHEDULE:
        if (action.data) {
          let data = action.data;
          let schedules = _.map(state.schedules, item => {
            if (item._id === data.scheduleId) {
              item.name = data.name;
              item.description = data.description;
            }
            return item;
          })
          return {
            ...state,
            schedules: schedules
          };
        }

      case duck.types.DELETE_SCHEDULE:
        if (action.data) {
          const scheduleId = action.data;
          const schedules = state.schedules.filter(item => item._id !== scheduleId);
          return {
            ...state,
            schedules: schedules
          };
        }
        return state;


      case duck.types.SLOT_DELETED:
        if (action.data) {
          let data = action.data;
          let slots = _.filter(state.schedule.slots, item => {
            if (item._id !== data.slotId) {
              return item;
            }

          })

          state.schedule.slots = [...slots];

          return {
            ...state,
            schedule: { ...state.schedule }
          };
        }

      case duck.types.DRIVERS_FETCHED:
        return {
          ...state,
          drivers: action.drivers
        };
      case duck.types.DETAILS_FETCHED:
        return {
          ...state,
          schedule: action.schedule
        };

      default:
        return state;
    }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({

    getSchedules: () => async (dispatch, getState) => {

      if (getState().schedule.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        if (companyInfo) {
          const response = await axios.get(scheduleEndPoints.schedule(companyInfo.companyId));
          let schedules = response.data.data;
          dispatch({ type: duck.types.SCHEUDLES_FETCHED, schedules });
        }

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    getSchedulesAndDrivers: (vehicelId) => async (dispatch, getState) => {

      if (getState().schedule.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        const response = await axios.get(scheduleEndPoints.scheduleAndDrivers(companyInfo.companyId, vehicelId));
        let schedules = response.data.data.schedules;
        let drivers = response.data.data.drivers;
        dispatch({ type: duck.types.SCHEUDLES_FETCHED, schedules });
        dispatch({ type: duck.types.DRIVERS_FETCHED, drivers });
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    getSlots: (id) => async (dispatch, getState) => {

      if (getState().schedule.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });

        const response = await axios.get(scheduleEndPoints.slots(id));
        let schedule = response.data.data;
        dispatch({ type: duck.types.DETAILS_FETCHED, schedule });
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },

    AddSchedule: (data) => async (dispatch, getState) => {

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.post(scheduleEndPoints.schedule(companyInfo.companyId), data);

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    EditSchedule: (data) => async (dispatch, getState) => {

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.put(scheduleEndPoints.schedule(companyInfo.companyId), data);

        dispatch({ type: duck.types.UPDATE_SCHEDULE, data });
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },

    DeleteSchedule: (data) => async (dispatch, getState) => {

      console.log("DeleteSchedule Duck -> ", data)

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }

      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.delete(scheduleEndPoints.scheduleDelete(companyInfo.companyId, data));

        dispatch({ type: duck.types.DELETE_SCHEDULE, data });
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    addSlot: (slotData) => async (dispatch, getState) => {

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.post(scheduleEndPoints.slots(companyInfo.companyId), slotData);

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    deleteSlots: (slotData) => async (dispatch, getState) => {

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.patch(scheduleEndPoints.slots(companyInfo.companyId), slotData);

        dispatch({ type: duck.types.SLOT_DELETED, data: slotData });
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    assignSlot: (data) => async (dispatch, getState) => {

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.post(scheduleEndPoints.assignSlot(companyInfo.companyId), data);

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    removeSlot: (data) => async (dispatch, getState) => {

      if (getState().drivers.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.put(scheduleEndPoints.assignSlot(companyInfo.companyId), data);

        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.SCHEDULE_IS_LOADING, isLoading: false });
        throw e;
      }
    },
  })
})