import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import moment from "moment";
import PlayBackDuck from "../../ducks/PlayBack";
import { ReactFlvPlayer } from "react-flv-player";
import { DVR_ACCOUNT, DVR_PASSWORD } from "../../constants";
import { PlayBackEndPoints } from "../../api/endpoints";
import { Spinner } from "../common/Spinner/index";
import DateTime from "react-datetime";
import { Card, CardHeader } from "reactstrap";
import Select from "react-select-nested-group";
import Things from "../../ducks/Things";
import { validateInput } from "../../validations/playback";
const pbImage = require("../../assets/img/pbImage.png");

class PlayBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      selectedVehicle: "",
      startDate: moment().subtract("1", "days"),
      endDate: moment(),
      errors: {},
      loading: true,
      loadVideo: false,
      isLoadingRecords: true,
      data: [],
      mon: 0,
      day: 0,
      year: 0,
      loc: 0,
      showTable: false,
      notFoundError: false,
      showLoaderImage: false,
      url: "",
    };
    this.getChartData();
  }
  async componentDidMount() {
    await this.props.thingsActions.getVehiclesPlateNumber();
  }
  componentWillMount() { }
  async getChartData() {
    this.setState({
      ...this.state,
      vehicles: [],
    });
    await fetch(PlayBackEndPoints.login(DVR_ACCOUNT, DVR_PASSWORD))
      .then((response) => response.json())
      .then((result) => {
        this.setState({ ...this.state, jsession: result.jsession });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ ...this.state, errors });
    }
    return isValid;
  };
  submit = async (e) => {
    this.setState({ showLoaderImage: true, showTable: false, loading: true });
    e.preventDefault();
    if (this.isValid()) {
      await fetch(
        PlayBackEndPoints.fetchForPlateNumber(
          this.state.jsession,
          this.state.selectedVehicle.item.mvdrDevId,
          this.state.mon,
          this.state.year,
          this.state.day,
          this.state.loc
        )
      )
        .then((response) => response.json())
        .then((result) => {
          if (!_.has(result, "files")) {
            this.setState({
              data: result,
              loading: true,
              showTable: false,
              notFoundError: true,
              showLoaderImage: false,
              loadVideo: false,
            });
          } else {
            this.setState({
              data: result,
              loading: false,
              showTable: true,
              notFoundError: false,
              showLoaderImage: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  };
  vehicleSelected = async (e) => {
    this.setState({ selectedVehicle: e });
  };

  onChangeStartDate = (e) => {
    var check = moment(e, "YYYY/MM/DD");
    this.setState({
      startDate: new Date(e),
      mon: check.format("M"),
      day: check.format("D"),
      year: check.format("YYYY"),
    });
  };
  fileLocationHandler = async (value) => {
    await this.setState({ loc: value.target.value });
  };
  onDoubleClickHandler = (point) => {
    this.setState({ loadVideo: false }, function () {
      this.setState({ url: point.PlaybackUrl, loadVideo: true });
    });
  };

  render() {
    let loaderX = <Spinner key="1" />;
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <Card className="height-100-p " style={{ minWidth: "100%" }}>
              <CardHeader className="f-bold">Filter Criteria</CardHeader>
              <form onSubmit={this.submit}>
                <div className="col p-10-px">
                  <div className="form-group">
                    <label>Select Vehicles</label>
                    <Select
                      name="form-field-name"
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Vehicle"
                      onChange={this.vehicleSelected}
                      value={this.state.selectedVehicle}
                      options={
                        this.props.vehiclesPlateNumber &&
                        this.props.vehiclesPlateNumber.map((item) => ({
                          value: item.mvdrDevId,
                          label: item.plateNumber || "N/A",
                          item: item,
                        }))
                      }
                    />
                  </div>
                  {this.state.errors && (
                    <span className="text-danger">
                      {this.state.errors.vehicle}
                    </span>
                  )}
                  <div className="form-group">
                    <label> Date</label>
                    <DateTime
                      value={this.state.startDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.onChangeStartDate}
                    />
                  </div>
                  {this.state.errors && (
                    <span className="text-danger">
                      {this.state.errors.startDate}
                    </span>
                  )}
                  <div
                    className="form-group"
                    onChange={this.fileLocationHandler.bind(this)}
                  >
                    <div
                      className="form-control"
                      style={{ marginBottom: "5px" }}
                    >
                      <input type="radio" value="1" name="loc" />
                      <span>Terminal Device</span>
                    </div>
                    {/* <div
                      className="form-control"
                      style={{ marginBottom: "5px" }}
                    >
                      <input type="radio" value="2" name="loc" />
                      <span>Storage Server</span>
                    </div>
                    <div
                      className="form-control"
                      style={{ marginBottom: "5px" }}
                    >
                      <input type="radio" value="4" name="loc" />
                      <span>Download Server</span>
                    </div> */}
                  </div>
                  {this.state.errors && (
                    <span className="text-danger">{this.state.errors.loc}</span>
                  )}
                  {this.state.notFoundError && (
                    <span className="text-danger">No data found</span>
                  )}
                  <div className="margin-btm-50">
                    <button
                      className="btn auxo-primary-btn create-geofence-btn"
                      type="submit"
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </form>
            </Card>
          </div>
          <div className="col-md-8">
            <Card className="height-100-p " style={{ minWidth: "100%" }}>
              <CardHeader className="f-bold">Video Player</CardHeader>
              {this.state.loadVideo ? (
                <ReactFlvPlayer
                  url={this.state.url}
                  height="100%"
                  width="100%"
                  isMuted={true}
                  handleError={(err) => {
                    console.log(err);
                  }}
                />
              ) : (
                  <div style={{ textAlign: 'center' }}>
                    <img src={pbImage} style={{ width: '200px', marginTop: '11%' }}></img>
                  </div>
                )}
            </Card>
          </div>
        </div>
        <div className="row">
          {this.state.showLoaderImage && (
            <div className="col-md-12">{[loaderX]}</div>
          )}
          {this.state.loading ? (
            this.state.loading && this.state.showTable ? (
              <div>
                <h4
                  style={{ textAlignVertical: "center", textAlign: "center" }}
                >
                  No record found!
                </h4>
              </div>
            ) : (
                ""
              )
          ) : (
              <div
                className="col-md-12"
                style={{
                  marginTop: "5px",
                  height: "40vh",
                  overflowY: "scroll",
                }}
              >
                <table className="table-auxo m-t-20-px">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date Time</th>
                      <th>Device Number</th>
                      <th>Channel</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.files.map((point, index) => {
                      return (
                        <tr
                          key={index.toString()}
                          onDoubleClick={() => this.onDoubleClickHandler(point)}
                        >
                          <td>{index + 1}</td>
                          <DateFormatter point={point} />
                          <td>{point.devIdno}</td>
                          <td>{`CH ${++point.chn}`}</td>
                          {point.loc == 1 && <td>Device</td>}
                          {point.loc == 2 && <td>Storage server</td>}
                          {point.loc == 4 && <td>Download server</td>}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const DateFormatter = ({ point }) => {

  function formate(val) {
    var date = new Date(null);
    date.setSeconds(val);
    return date.toISOString().substr(11, 8);
  }

  return (
    <td>
      {point.day}-{point.mon}-{2000 + point.year}   {formate(point.beg)}{" "}
      {formate(point.end)}
    </td>
  );
};

PlayBack.propTypes = {};
PlayBack.defaultProps = {};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  vehiclesPlateNumber: state.things.vehiclesPlateNumber,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PlayBackDuck.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayBack);
