import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Users from "../../ducks/Users";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./index.css";
import ic_notification from "../../assets/img/ic_notification.png"
import ic_violations from "../../assets/img/ic_violations.png"
import ic_harsh_accelerations from "../../assets/img/ic_harsh_accelerations.png"
import ic_harsh_brakes from "../../assets/img/ic_harsh_brakes.png"
import ic_harshturn from "../../assets/img/ic_harshturn.png"
import ic_speed_limit from "../../assets/img/ic_speed_limit.png"
import ic_vehicle_entering from "../../assets/img/geofence_entry.png"
import fatigue from "../../assets/img/fatigue.png";
import ic_vehicle_leaving from "../../assets/img/geofence_exit.png"
import ic_vehicle_idle from "../../assets/img/ic_vehicle_idle.png"
import ic_plugout from "../../assets/img/ic_plugout.png"
import ic_plugin from "../../assets/img/ic_plugin.png"
import ic_ignition_on from "../../assets/img/ic_ignition_on.png"
import ic_ignition_off from "../../assets/img/ic_ignition_off.png"
import {ic_notifications_none} from 'react-icons-kit/md/ic_notifications_none'
import Icon from 'react-icons-kit'
import seatbelt from "../../assets/img/seatbelt.png"
import { Spinner } from '../common/Spinner/index';
import { Link } from "react-router-dom";
import moment from 'moment';


import {
  Popover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap';



class Notifications extends Component {

  constructor() {
    super();
    this.state = {
      popoverOpen: false,
      loading: true
    }
  }

  async getNotification() {

    await this.props.actions.getUserNotifications({}, false);
    this.setState({
      ...this.state,
      loading: false
    })
  }

  async componentDidMount() {

    this.getNotification();

  }

  toggle() {
    this.props.actions.viewNotifications();
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen
    });
  }
  gotoNotificationPage = (item) => {
    this.setState({ popoverOpen: false });
    this.props.history.push(`/notifications?date=${item.createdAt}&thing=${item.thing}&plate=${item.plateNumber}`);
  }
  render() {
    const loader = <Spinner key="1" />

    return (

      <span className="notification-bell">
        <a>
        <Icon size={30} icon={ic_notifications_none} className="nav-item-icon" onClick={this.toggle.bind(this)} alt="group" id="notificationPopup"/>
          {/* <img src={ic_notification} onClick={this.toggle.bind(this)} alt="group" id="notificationPopup" /> */}

          <Popover className="notif-popover" placement="bottom" isOpen={this.state.popoverOpen} target="notificationPopup" toggle={this.toggle.bind(this)}>

            <PopoverBody >
              {this.state.loading ? [loader] : <div>
                {this.props.notifications.length > 0 ?

                  <div >

                    {this.props.notifications.map((item, index) => {

                      let icon = ic_violations;
                      if (item.type === 'harshAcceleration') {
                        icon = ic_harsh_accelerations;
                      } else if (item.type === 'harshBrake') {
                        icon = ic_harsh_brakes;
                      } else if (item.type === 'sharpTurn') {
                        icon = ic_harshturn;
                      } else if (item.type === 'overSpeeding') {
                        icon = ic_speed_limit;
                      } else if (item.type === 'geoFenceEntry') {
                        icon = ic_vehicle_entering;
                      } else if (item.type === 'geoFenceExit') {
                        icon = ic_vehicle_leaving;
                      } else if (item.type === 'ignitionOn') {
                        icon = ic_ignition_on;
                      } else if (item.type === 'vehicleMoving') {
                        icon = ic_ignition_on;
                      }
                      else if (item.type === 'vehicleParked') {
                        icon = ic_ignition_off;
                      } else if (item.type === 'vehicleOffline') {
                        icon = ic_plugout;
                      } else if (item.type === 'vehicleOnline') {
                        icon = ic_plugin;
                      } else if (item.type === 'vibration') {
                        icon = ic_plugin;
                      } else if (item.type === 'unfasten_seatbelt') {
                        icon = seatbelt;

                      } else if (item.type === 'vehicleIdle') {
                        icon = ic_vehicle_idle;
                      }
                      else if (item.type === 'fatigue') {
                        icon = fatigue;
                      }
                      else if (item.type === 'restFatigue') {
                        icon = fatigue;
                      }


                      return (
                        <div key={index} className="notification-area" style={{ cursor: 'pointer' }} onClick={() => this.gotoNotificationPage(item)}>
                          <span><img src={icon} className="width36" alt="" /></span>
                          <span>
                            <div>{item.message}</div>

                            <span className="notif-p-popup">{item.plateNumber || ""}</span>

                            <span className="notif-p-popup margin-left55">
                              {moment(item.createdAt).format('DD-MMM-YY h:mm a') || 'N/A'}
                            </span>
                          </span>
                        </div>
                        // <div key={index} className="notification-area">
                        //     <span><img src={icon} className="width36" alt="" /></span>
                        //     <span ><div className="notif-span-status">{item.message}</div>
                        //     <span className="notif-p-popup">
                        //         <span>{item.plateNumber || ""}</span>
                        //         <span className="pull-right">{moment(item.createdAt).format('DD-MMM-YY h:mm a') || 'N/A'}</span>
                        //         </span>
                        //         {/* <p className="notif-p-popup"></p> */}
                        //     </span>
                        // </div>
                      )
                    })}

                  </div> : <p className="no-notifications">No Notifications Yet!</p>}
              </div>}

            </PopoverBody>
            <PopoverHeader className="text-center">  <Link to="/notifications" className="text-center black-text bold-text">View All</Link></PopoverHeader>


          </Popover>


        </a>
        {this.props.notificationsCount > 0 ?
          <span>
            {this.props.notificationsCount > 99 ? "99+" : this.props.notificationsCount}

          </span>
          : ""}
      </span>
    )
  }
}

Notifications.propTypes = {};
Notifications.defaultProps = {};
const mapStateToProps = state => ({ notifications: state.users.notifications.notifications, notificationsCount: state.users.notifications.notificationsCount, hasMoreItems: state.users.hasMoreNotifications });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Users.creators, dispatch)
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
