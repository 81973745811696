import React, { Component } from 'react';
import { connect } from "react-redux";
import Schedule from "../../ducks/Schedule";
import Modal from 'react-bootstrap4-modal';
import { bindActionCreators } from "redux";
import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaGroup from "../common/TextAreaGroup";
import { validateScheduleInput } from "../../validations/schedule";
// import './index.css';
import toastr from "toastr";
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';

class EditSchedule extends Component {

    constructor() {
        super();
        this.state = {
            scheduleId: "",
            name: "",
            description: "",
            errors: {}
        }
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    navigateToList() {
        this.props.closePopup();
    }
    loadData = async () => {
        let schedule = this.props.scheduleToEdit;
        if (schedule) {
            this.setState({
                ...this.state,
                name: schedule.name,
                description: schedule.description,
                scheduleId: schedule._id,
            })
        }
    }

    isValid = () => {
        const { errors, isValid } = validateScheduleInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    };

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
    }
    async onSubmit() {

        if (this.isValid()) {
            try {
                const state = this.state;
                const scheduleData = {
                    scheduleId: state.scheduleId,
                    name: state.name,
                    description: state.description,
                }
                await this.props.actions.EditSchedule(scheduleData);
                toastr.success("Schedule updated successfully!");
                this.navigateToList();
            } catch (e) {
                toastr.error(e.response ? e.response.data.message : e);
            }
        }
    };

    render() {
        return (

            <Modal
                visible={this.props.popupVisible}
                onClickBackdrop={this.modalBackdropClicked}
                dialogClassName='schedule-modal-dialog'>
                <div>
                    <button
                        type="button"
                        className="close close-x"
                        aria-label="Close"
                        onClick={this
                            .navigateToList
                            .bind(this)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='modal-heading row'>
                    <div className="col-lg-8 col-md-8 col-sm-8"> <p className="car-and-device-assig">Edit Schedule</p></div>

                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">

                            <TextFieldGroup
                                onChange={this.onChange.bind(this)}
                                value={this.state.name}
                                type={"text"}
                                field={"name"}
                                label={"Schedule Name"}
                                placeholder={"Schedule name"}
                                error={this.state.errors.name} />
                            <TextAreaGroup
                                onChange={this.onChange.bind(this)}
                                value={this.state.description}
                                label={"Description"}
                                field={"description"}
                                placeholder={"Extra details about Schedule"} 
                                error={this.state.errors.description}/>

                        </div>

                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn close-btn"
                        onClick={this
                            .onSubmit
                            .bind(this)}
                    >Edit Schedule</button>
                </div>
            </Modal>

        )
    }

}

EditSchedule.propTypes = {};
EditSchedule.defaultProps = {};
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Schedule.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule);
