import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filter, forEach, indexOf, map } from 'lodash';
import toastr from 'toastr';
import classnames from 'classnames';

import Reports from '../../ducks/Reports';
import GeoFence from '../../ducks/GeoFence';
import RouteFence from '../../ducks/RouteFence';

import CheckboxListComponent from '../common/CheckboxListComponent';
import CheckListComponent from '../check-list';
import {
  alarmColumns,
  alarmTypesCollection,
  distanceTraveledCollection,
  vehicleTravelColumns,
  drivingScoreColumns,
  vehicleDrivingScoreColumns,
  eventTypeColumnList,
  fuelAverageColumns,
  newfuelAverageColumns,
  geoFenceColumns,
  geofenceTrackColumns,
  oilChangeReportColumns,
  overspeedingColumns,
  stopageReportColumns,
  vehicleTrackingReportColumns,
  byceTrackingReportColumns,
  dliReportColumns,
  tripColumns,
  tyreChangeReportColumns,
  violationColumns,
  performanceReportColumnList,
  otherMaintainanceColumns,
  routeFenceColumns,
  vehiclesGeoFenceReportColumns,
  vehiclesRouteFenceReportColumns,
  geoFenceFuelReportColumns,
  pjpReportCloumns,
  violationTypeColumnList,
  customViolationColumns,
  vehicleHistoryColumns,
  idleColumns,
  geoFenceTourReportColumns,
  helmetStatusColumns,
  helmetStatusTypesCollection,
  geoFenceSummaryColumns,
  mileageColumns
} from './columns';

import './index.css';

let restrictedHours = false;
let timeCheckCondition = false;

class ReportColumns extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      selectAll: false,
      selectAllGeoFence: false,
      selectedColumns: [],
      selectedAlarmTypes: [],
      alarmTypes: [],
      eventTypeList: [],
      selectedEventTypeList: [],
      geofenceList: [],
      selectedGeofenceList: [],
      activeTab: 'columns'
    }
  }

  mapColumns(array) {
    array = map(array, item => {
      item.checked = false;
      return item;
    });
    return array;
  }

  componentDidMount = async () => {

    let geofences = [];
    let routefences = [];
    const { type: reportType } = this.props;

    if (reportType === 'geofenceSummaryReport' || reportType === 'geofenceReport' || reportType === 'geoFenceFuelReport' || reportType === 'geoFenceTourReport') {
      geofences = await this.props.geoFenceActions.getGroupGeoFences();
    }
    if (reportType === 'routefenceReport') {
      routefences = await this.props.routeFenceActions.getGroupRouteFences();
    }

    let columns = [],
      alarmTypes = [],
      eventTypeList = [],
      selectedColumns = [],
      selectedAlarmTypes = [],
      selectedEventTypeList = [],
      selectedGeofenceList = [],
      selectedRoutefenceList = [],
      geofenceList = [],
      routefenceList = [],
      selectAllGeoFence = false;


    switch (reportType) {
      case 'mileageReport':
        columns = this.mapColumns(mileageColumns);
        break;
      case 'tripReport':
        columns = this.mapColumns(tripColumns);
        break;
      case 'tripMilageReport':
        columns = this.mapColumns(tripColumns);
        break;
      case 'geofenceTrackReport':
        columns = this.mapColumns(geofenceTrackColumns);
        break;
      case 'fuelAverage':
        columns = this.mapColumns(fuelAverageColumns);
      case 'newfuelAverage':
        columns = this.mapColumns(newfuelAverageColumns);
      case 'newfuelActivity':
        columns = this.mapColumns(newfuelAverageColumns);
        break;
      case 'alarmReport':

        const companyFeatures = this.props.permissions.companyFeatures;
        if (companyFeatures.indexOf("DRIVERHOURS") !== -1) {
          if (!restrictedHours) {
            restrictedHours = true;
            alarmTypesCollection.push({
              id: 'IGNITION_ON_RESTRICTED_HOURS',
              name: 'Ignition ON in Restricted Hours',
              checked: false
            });
            alarmTypesCollection.push({
              id: 'VEHICLE_MOVING_RESTRICTED_HOURS',
              name: 'Moving in Restricted Hours',
              checked: false
            });
          }
        }

        if (companyFeatures.indexOf("TIMECHECK") !== -1) {
          if (!timeCheckCondition) {
            timeCheckCondition = true;
            alarmTypesCollection.push({
              id: 'TIME_CHECK',
              name: 'Vehcile Time Exceed',
              checked: false
            });
          }
        }


        columns = this.mapColumns(alarmColumns);
        alarmTypes = this.mapColumns(alarmTypesCollection);
        selectedAlarmTypes = this.filterSelectedColumnList(this.props.columnsSelected.alarmTypes, alarmTypes);
        break;
      case 'helmetStatusReport':
        columns = this.mapColumns(helmetStatusColumns);
        alarmTypes = this.mapColumns(helmetStatusTypesCollection);
        selectedAlarmTypes = this.filterSelectedColumnList(this.props.columnsSelected.alarmTypes, alarmTypes);
        break;
      case 'eventReport':
        const { companyId } = JSON.parse(localStorage.getItem("companyInfo"))
        columns = this.mapColumns(violationColumns);
        if (companyId === "606b15ed8d1b9f7336b0bee5") {
          columns.push({
            id: "overspeedingDuration",
            name: "Over Speeding Duration",
            checked: false
          })
        }
        eventTypeList = this.mapColumns(eventTypeColumnList);
        selectedEventTypeList = this.filterSelectedColumnList(this.props.columnsSelected.eventTypeList, eventTypeList);
        break;
      case 'eventGraphReport':
        columns = this.mapColumns(violationColumns);
        eventTypeList = this.mapColumns(eventTypeColumnList);
        selectedEventTypeList = this.filterSelectedColumnList(this.props.columnsSelected.eventTypeList, eventTypeList);
        break;
      case 'violationReportBATCustom':
        columns = this.mapColumns(customViolationColumns);
        eventTypeList = this.mapColumns(violationTypeColumnList);
        selectedEventTypeList = this.filterSelectedColumnList(this.props.columnsSelected.eventTypeList, eventTypeList);
        break;
      case 'overSpeeding':
        columns = this.mapColumns(overspeedingColumns);
        break;
      case 'geofenceSummaryReport':
        columns = this.mapColumns(geoFenceSummaryColumns);
        geofenceList = this.mapColumns(geofences.map(geofence => ({ id: geofence._id, name: geofence.name })));
        selectAllGeoFence = geofenceList.length === this.props?.columnsSelected?.geofenceList?.length;
        selectedGeofenceList = this.filterSelectedColumnList(this.props.columnsSelected.geofenceList, geofenceList);
        break;
      case 'geofenceReport':
        columns = this.mapColumns(geoFenceColumns);
        geofenceList = this.mapColumns(geofences.map(geofence => ({ id: geofence._id, name: geofence.name })));
        selectAllGeoFence = geofenceList.length === this.props?.columnsSelected?.geofenceList?.length;
        selectedGeofenceList = this.filterSelectedColumnList(this.props.columnsSelected.geofenceList, geofenceList);
        break;
      case 'geoFenceFuelReport':
        columns = this.mapColumns(geoFenceFuelReportColumns);
        geofenceList = this.mapColumns(geofences.map(geofence => ({ id: geofence._id, name: geofence.name })));
        selectedGeofenceList = this.filterSelectedColumnList(this.props.columnsSelected.geofenceList, geofenceList);
        break;
      case 'routefenceReport':
        columns = this.mapColumns(routeFenceColumns);
        routefenceList = this.mapColumns(routefences.map(routefence => ({ id: routefence._id, name: routefence.name })));
        selectedRoutefenceList = this.filterSelectedColumnList(this.props.columnsSelected.routefenceList, routefenceList);
        break;
      case 'drivingScore':
        columns = this.mapColumns(drivingScoreColumns);
        break;
      case 'vehicledrivingScore':
        columns = this.mapColumns(vehicleDrivingScoreColumns);
        break;
      case 'drivingScoreGraph':
        columns = this.mapColumns(drivingScoreColumns);
        break;
      case 'stopageReport':
        columns = this.mapColumns(stopageReportColumns);
        break;
      case 'vehicleReport':
        columns = this.mapColumns(vehicleTrackingReportColumns);
        break;
      case 'bycetrackingreport':
        columns = this.mapColumns(byceTrackingReportColumns);
        break;
      case 'DLIReport':
        columns = this.mapColumns(dliReportColumns);
        break;
      case 'vehicleReportTest':
        columns = this.mapColumns(vehicleTrackingReportColumns);
        break;
      case 'OIL_CHANGE':
        columns = this.mapColumns(oilChangeReportColumns);
        break;
      case 'TYRE_CHANGE':
        columns = this.mapColumns(tyreChangeReportColumns);
        break;
      case 'otherMaintainance':
        columns = this.mapColumns(otherMaintainanceColumns);
        break;
      case 'distanceTraveled':
        columns = this.mapColumns(distanceTraveledCollection);
        break;
      case 'vehicleTravelReport':
        columns = this.mapColumns(vehicleTravelColumns);
        break;
      case 'vehicleTravelReportGraph':
        columns = this.mapColumns(vehicleTravelColumns);
        break;
      case 'performanceReport':
        columns = this.mapColumns(performanceReportColumnList);
        break;
      case 'vehiclesGeoFenceReport':
        columns = this.mapColumns(vehiclesGeoFenceReportColumns);
        break;
      case 'vehiclesRouteFenceReport':
        columns = this.mapColumns(vehiclesRouteFenceReportColumns);
        break;
      case 'pjpReport':
        columns = this.mapColumns(pjpReportCloumns);
        break;
      case 'vehicleHistoryReport':
        columns = this.mapColumns(vehicleHistoryColumns);
        break;
      case 'idleReport':
        columns = this.mapColumns(idleColumns);
        break;
      case 'geoFenceTourReport':
        columns = this.mapColumns(geoFenceTourReportColumns);
        geofenceList = this.mapColumns(geofences.map(geofence => ({ id: geofence._id, name: geofence.name })));
        selectedGeofenceList = this.filterSelectedColumnList(this.props.columnsSelected.geofenceList, geofenceList);

        break;
    }

    selectedColumns = this.filterSelectedColumnList(this.props.columnsSelected.columns, columns);

    this.setState(prevState => ({
      ...prevState,
      columns,
      alarmTypes,
      selectedAlarmTypes,
      eventTypeList,
      selectedEventTypeList,
      geofenceList,
      selectAllGeoFence,
      routefenceList,
      selectedGeofenceList,
      selectedRoutefenceList,
      selectedColumns,
      routefences
    }))

  };

  filterSelectedColumnList(columnList = [], checkList = []) {
    const resList = [];
    if (columnList.length > 0) {
      forEach(checkList, (item, index) => {
        if (indexOf(columnList, item.id) > -1) {
          checkList[index].checked = true;
          resList.push(item.id);
        }
      })
    }
    return resList;
  }

  checkBoxListSelectAll = () => {

    let columns = this.state.columns;

    let selectedColumns = this.state.selectedColumns;

    let isSelectAll = this.state.selectAll;
    if (columns.length > 0) {
      for (let i = 0; i < columns.length; i++) {
        let item = columns[i];

        if (!isSelectAll) {
          item.checked = true;
          selectedColumns.push(item.id);
        } else {
          item.checked = false;
          let index = selectedColumns.findIndex(obj => {
            return obj === item.id;
          });
          if (index > -1) {
            selectedColumns.splice(index, 1);
          }
        }

        columns[i] = item;
      }
    }
    this.setState({
      ...this.state,
      columns,
      selectedColumns,
      selectAll: !isSelectAll
    });
  };

  checkBoxListSelectAllGeoFence = () => {

    // let columns = this.state.columns;
    let geofenceList = this.state.geofenceList;
    let isSelectAll = this.state.selectAllGeoFence;
    let selectedGeofenceList = [];

    if (geofenceList.length > 0) {
      for (let i = 0; i < geofenceList.length; i++) {
        let item = geofenceList[i];

        if (!isSelectAll) {
          item.checked = true;
          selectedGeofenceList.push(item.id);
        } else {
          item.checked = false;
          selectedGeofenceList.push(item.id);
        }

        geofenceList[i] = item;
      }
    }
    this.setState({
      ...this.state,
      geofenceList,
      selectedGeofenceList,
      selectAllGeoFence: !isSelectAll
    });
  };

  checkBoxListChanged = (value) => {
    let columns = this.state.columns;

    let selectedColumns = this.state.selectedColumns;

    let isSelectAll = this.state.selectAll;
    if (columns.length > 0) {
      for (let i = 0; i < columns.length; i++) {
        let item = columns[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) {
            selectedColumns.push(item.id);
          } else {
            let index = selectedColumns.findIndex(obj => {
              return obj === item.id;
            });
            if (index > -1) {
              if (isSelectAll) {
                isSelectAll = false;
              }
              selectedColumns.splice(index, 1);
            }
          }
        }
        columns[i] = item;
      }
    }
    this.setState({
      ...this.state,
      columns,
      selectedColumns,
      selectAll: isSelectAll
    });
  };

  alarmTypesListChanged = (value) => {
    let alarmTypes = this.state.alarmTypes;

    let selectedAlarmTypes = this.state.selectedAlarmTypes;


    if (alarmTypes.length > 0) {
      for (let i = 0; i < alarmTypes.length; i++) {
        let item = alarmTypes[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) {
            selectedAlarmTypes.push(item.id);
          } else {
            let index = selectedAlarmTypes.findIndex(obj => {
              return obj === item.id;
            });
            if (index > -1) {

              selectedAlarmTypes.splice(index, 1);
            }
          }
        }
        alarmTypes[i] = item;
      }
    }
    this.setState({
      ...this.state,
      alarmTypes,
      selectedAlarmTypes
    });
  };

  eventTypeListChanged = eventListChanged => {
    const selectedEventTypeList = map(filter(eventListChanged, 'checked'), 'id');
    this.setState({ ...this.state, eventTypeList: eventListChanged, selectedEventTypeList });
  };

  geofenceListChanged = (value) => {

    let geofenceList = this.state.geofenceList;
    let selectedGeofenceList = this.state.selectedGeofenceList;

    if (geofenceList.length > 0) {
      for (let i = 0; i < geofenceList.length; i++) {
        let item = geofenceList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) {
            selectedGeofenceList.push(item.id);
          } else {
            let index = selectedGeofenceList.findIndex(obj => {
              return obj === item.id;
            });
            if (index > -1) {

              selectedGeofenceList.splice(index, 1);
            }
          }
        }
        geofenceList[i] = item;
      }
    }
    this.setState({
      ...this.state,
      geofenceList,
      selectedGeofenceList
    });
  };
  routefenceListChanged = (value) => {

    let routefenceList = this.state.routefenceList;
    let selectedRoutefenceList = this.state.selectedRoutefenceList;

    if (routefenceList.length > 0) {
      for (let i = 0; i < routefenceList.length; i++) {
        let item = routefenceList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) {
            selectedRoutefenceList.push(item.id);
          } else {
            let index = selectedRoutefenceList.findIndex(obj => {
              return obj === item.id;
            });
            if (index > -1) {

              selectedRoutefenceList.splice(index, 1);
            }
          }
        }
        routefenceList[i] = item;
      }
    }
    this.setState({
      ...this.state,
      routefenceList,
      selectedRoutefenceList
    });
  };
  isTabActive = name => name === this.state.activeTab;

  selectColumns() {
    if (this.props.type === 'alarmReport' && !this.state.selectedAlarmTypes.length) {
      return toastr.error('Please select alarm types to have in report')
    }
    if (this.props.type === 'helmetStatusReport' && !this.state.selectedAlarmTypes.length) {
      return toastr.error('Please select status types to have in report')
    }
    if (this.props.type === 'violationReportBATCustom' && !this.state.selectedEventTypeList.length) {
      return toastr.error('Please select violation type to have in report')
    }
    this.props.selectColumns(this.state.selectedColumns, this.state.selectedAlarmTypes, this.state.selectedEventTypeList, this.state.selectedGeofenceList, this.state.selectedRoutefenceList);
  }

  back() {
    this.props.switchStep(1);
  }

  handleTabClick = name => {
    this.setState(prevState => ({ ...prevState, activeTab: name }))
  };

  render() {
    const { type: reportType } = this.props;

    return (
      <div>
        <div>
          <ul className='reports-list'>
            <li>
              <span>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.back.bind(this)}
                >
                  Previous
                </button>
              </span>
              <span className='text-center'>
                <h4>Select Columns</h4>
                <p>Selected columns to be displayed in report</p>
              </span>
              <span className='select-report'>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.selectColumns.bind(this)}
                >
                  Next
                </button>
              </span>
            </li>
          </ul>
        </div>

        <div>
          <div className='col-lg-4 col-md-4 col-sm-6'>
            <ul className='nav nav-tabs' role='tablist'>
              <li className='nav-item'>
                <a className={'nav-link active'} onClick={() => this.handleTabClick('columns')}>Columns</a>
              </li>
              {
                reportType === 'alarmReport'
                  ? (
                    <li className='nav-item'>
                      <a
                        className={'nav-link active'}
                        onClick={() => this.handleTabClick('alarmType')}
                      >
                        Alarm Type
                      </a>
                    </li>
                  )
                  : ''
              }
              {
                reportType === 'helmetStatusReport'
                  ? (
                    <li className='nav-item'>
                      <a
                        className={'nav-link active'}
                        onClick={() => this.handleTabClick('alarmType')}
                      >
                        Status Type
                      </a>
                    </li>
                  )
                  : ''
              }
              {
                reportType === 'eventReport' || reportType === 'violationReportBATCustom'
                  ? (
                    <li className='nav-item'>
                      <a
                        className={'nav-link active'}
                        onClick={() => this.handleTabClick('eventType')}
                      >
                        Event Type
                      </a>
                    </li>
                  )
                  : ''
              }
              {
                reportType === 'geofenceReport' || reportType === 'geoFenceFuelReport' || reportType === 'geoFenceTourReport'
                  ? (
                    <li className='nav-item'>
                      <a
                        className={'nav-link active'}
                        onClick={() => this.handleTabClick('geofenceReport')}>Geofences</a>
                    </li>
                  )
                  : ''
              }
              {
                reportType === 'geofenceSummaryReport'
                  ? (
                    <li className='nav-item'>
                      <a
                        className={'nav-link active'}
                        onClick={() => this.handleTabClick('geofenceSummaryReport')}>Geofences</a>
                    </li>
                  )
                  : ''
              }
              {
                reportType === 'routefenceReport'
                  ? (
                    <li className='nav-item'>
                      <a
                        className={'nav-link active'}
                        onClick={() => this.handleTabClick('routefenceReport')}>Routefences</a>
                    </li>
                  )
                  : ''
              }
            </ul>

            <div className='tab-content access-tab mb-5 columns-to-select'>
              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('columns') })}>
                <CheckboxListComponent
                  selectAllCheck={this.state.selectAll}
                  selectAllEnabled='true'
                  selectAll={this.checkBoxListSelectAll}
                  onChange={this.checkBoxListChanged}
                  data={this.state.columns && this.state.columns.map(item => ({ mainTitle: item.name, checked: item.checked }))}
                />
              </div>

              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('alarmType') })}>
                <CheckboxListComponent
                  onChange={this.alarmTypesListChanged}
                  data={this.state.alarmTypes && this.state.alarmTypes.map(item => ({ mainTitle: item.name || 'N/A', checked: item.checked }))}
                />
              </div>

              <div className={`tab-pane fade ${this.isTabActive('eventType') ? 'show active' : ''}`}>
                <CheckListComponent
                  onChange={this.eventTypeListChanged}
                  isGroupSelect={false}
                  reportType={reportType}
                  singleSelect={reportType === 'violationReportBATCustom'}
                  data={this.state.eventTypeList}
                  optionKeys={{ labelKey: 'name', valueKey: 'id', keyField: 'id' }}
                />
              </div>

              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('geofenceReport') })}>
                <CheckboxListComponent
                  selectAllCheck={this.state.selectAllGeoFence}
                  selectAllEnabled='true'
                  selectAll={this.checkBoxListSelectAllGeoFence}
                  onChange={this.geofenceListChanged}
                  data={this.state.geofenceList && this.state.geofenceList.map(item => ({ mainTitle: item.name || 'N/A', checked: item.checked }))}
                />
              </div>

              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('geofenceSummaryReport') })}>
                <CheckboxListComponent
                  selectAllCheck={this.state.selectAllGeoFence}
                  selectAllEnabled='true'
                  selectAll={this.checkBoxListSelectAllGeoFence}
                  onChange={this.geofenceListChanged}
                  data={this.state.geofenceList && this.state.geofenceList.map(item => ({ mainTitle: item.name || 'N/A', checked: item.checked }))}
                />
              </div>

              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('routefenceReport') })}>
                <CheckboxListComponent
                  onChange={this.routefenceListChanged}
                  data={this.state.routefenceList && this.state.routefenceList.map(item => ({ mainTitle: item.name || 'N/A', checked: item.checked }))}
                />
              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

ReportColumns.propTypes = {};
ReportColumns.defaultProps = {};

const mapStateToProps = state => ({ geofences: state.geoFence.geoFences, permissions: state.permissions });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Reports.creators, dispatch),
  geoFenceActions: bindActionCreators(GeoFence.creators, dispatch),
  routeFenceActions: bindActionCreators(RouteFence.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportColumns);
