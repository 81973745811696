import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { subDays } from 'date-fns';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Popover, PopoverBody } from 'reactstrap';
import classnames from 'classnames';
import { DateRangePicker } from 'react-date-range';
import Groups from '../../ducks/Groups';
import CheckboxListComponent from '../common/CheckboxListComponent';
import './index.css'
import DateTime from 'react-datetime';
import calenderIcon from '../../assets/img/calender.png';

class DrivingScoreReportInterval extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {

      drivers: [],
      groups: [],
      selectedDrivers: [],
      activeTab: 'drivers',
      errors: {},
      dateRangePicker: {
        selection: {
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
          key: 'selection',
        },
      },
      popoverOpen: false,
      overSpeedingLimit: 80,
    }
  }

  async componentDidMount() {
    await this
      .props
      .actions
      .getGroupsAndDrivers();

    let dateRangePicker = {
      selection: {
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
        startTime: moment(),
        endTime: moment(),
        key: 'selection',
      },
    };

    if (this.props.interval.startDate) dateRangePicker = { selection: this.props.interval };

    this.setState({
      ...this.state,
      drivers: this.props.groupsAndDrivers.drivers,
      groups: this.props.groupsAndDrivers.groups,
      dateRangePicker,
    })
  }

  toggle() {
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  searchDrivers = (e) => {

    const value = e.target.value;
    let drivers = [];

    let selectedDrivers = this.state.selectedDrivers;
    if (value) {
      _.forEach(this.props.groupsAndDrivers.drivers, (item) => {
        if (item.name.search(new RegExp(value, 'i')) >= 0) {
          let index = selectedDrivers.findIndex(obj => {
            return obj === item._id;
          });

          drivers.push({
            _id: item._id,
            name: item.name || 'N/A',
            checked: index > -1,
            serialNumber: item.serialNumber || 'N/A'
          });
        }
      });

    } else {
      drivers = _.map(this.props.groupsAndDrivers.drivers, (item) => {
        let index = selectedDrivers.findIndex(obj => obj === item._id);
        return {
          _id: item._id,
          name: item.name || 'N/A',
          checked: index > -1,
          serialNumber: item.serialNumber || 'N/A'
        };
      });
    }
    this.setState({
      ...this.state,
      drivers
    });
  };
  checkBoxListChanged = (value) => {
    let drivers = this.state.drivers;
    let groups = this.state.groups;
    let selectedDrivers = this.state.selectedDrivers;

    if (drivers.length > 0) {

      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groups[j] = groupItem;
        }
      };

      for (let i = 0; i < drivers.length; i++) {
        let item = drivers[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          drivers[i] = item;
          if (item.checked) {
            selectedDrivers.push(item._id);
            const groupDrivers = _.find(drivers, driver => {
              return driver.group === item.group && !driver.checked
            });
            if (!groupDrivers) {
              selectGroup(item, true);
            }
          } else {
            selectGroup(item, false);

            let index = selectedDrivers.findIndex(obj => {
              return obj === item._id;
            });
            if (index > -1) {
              selectedDrivers.splice(index, 1);
            }
          }
        }

      }
    }
    this.setState({
      ...this.state,
      drivers,
      groups,
      selectedDrivers
    });
  };
  selectAllDrivers = (value) => {
    let drivers = this.state.drivers;
    let groups = this.state.groups;
    let selectedDrivers = this.state.selectedDrivers;

    if (drivers.length > 0) {

      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groups[j] = groupItem;
        }
      };

      for (let i = 0; i < drivers.length; i++) {
        let item = drivers[i];

        item.checked = value;
        drivers[i] = item;
        if (item.checked) {
          selectedDrivers.push(item._id);
          const groupDrivers = _.find(drivers, driver => {
            return driver.group === item.group && !driver.checked
          });
          if (!groupDrivers) {
            selectGroup(item, true);
          }
        } else {
          selectGroup(item, false);

          let index = selectedDrivers.findIndex(obj => {
            return obj === item._id;
          });
          if (index > -1) {
            selectedDrivers.splice(index, 1);
          }
        }

      }
    }
    this.setState({
      ...this.state,
      drivers,
      groups,
      selectedDrivers
    });
  };

  handleChange(which, payload) {

    this.setState({
      [which]: payload,
    });
  }

  handleRangeChange(which, payload) {

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }

  checkGroupChanged = (value) => {
    let groups = this.state.groups;
    let drivers = this.state.drivers;
    let selectedDrivers = this.state.selectedDrivers;

    let groupSelected;

    for (let i = 0; i < groups.length; i++) {
      let item = groups[i];
      if (i === parseInt(value, 10)) {
        item.checked = !item.checked;
        groupSelected = item;
      }
      groups[i] = item;
    }
    if (drivers.length > 0) {
      for (let i = 0; i < drivers.length; i++) {
        let item = drivers[i];
        if (groupSelected._id === item.group) {
          item.checked = groupSelected.checked;
          if (item.checked) {
            selectedDrivers.push(item._id);
          } else {
            let index = selectedDrivers.findIndex(obj => {
              return obj === item._id;
            });
            if (index > -1) {
              selectedDrivers.splice(index, 1);
            }
          }
        }
        drivers[i] = item;
      }
    }
    this.setState({
      ...this.state,
      drivers,
      groups,
      selectedDrivers
    });
  };
  isTabActive = name => {
    return name === this.state.activeTab;
  };
  handleTabClick = name => {
    this.setState({
      ...this.state,
      activeTab: name
    })
  };

  handleSelect(range) {

    // An object with two keys,
    // 'startDate' and 'endDate' which are Momentjs objects.
  }
  setStartDate(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { startDate: payload, endDate: selection.endDate, startTime: selection.startTime, endTime: selection.endTime } } })
  }
  setEndDate(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { endDate: payload, startDate: selection.startDate, startTime: selection.startTime, endTime: selection.endTime } } })
  }
  setStartTime(payload) {

    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { startDate: selection.startDate, endDate: selection.endDate, startTime: payload, endTime: selection.endTime } } })
  }
  setEndTime(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { endDate: selection.endDate, startDate: selection.startDate, startTime: selection.startTime, endTime: payload } } })
  }

  selectDriversAndRange() {
    this.props.selectDriversAndRange(this.state.selectedDrivers, this.state.dateRangePicker.selection)
  }

  back() {
    this.props.switchStep(2, this.state.selectedDrivers, this.state.dateRangePicker.selection);
  }

  render() {

    return (

      <div>
        <div>
          <ul className='reports-list'>
            <li className='interval-report'>
              <span>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.back.bind(this)}
                >
                  Previous
                </button>
              </span>
              <span className='text-center interval-icon'>
                <h4>Drivers and Interval</h4>
                <p>Select the Drivers and date interval for the report</p>
              </span>
              <span className='select-report'>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.selectDriversAndRange.bind(this)}
                >
                  Export
                </button>
              </span>
            </li>
          </ul>
        </div>

        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-6'>
            <ul className='nav nav-tabs' role='tablist'>
              <li className='nav-item'>
                <a
                  className={'nav-link active'}
                  onClick={() => this.handleTabClick('drivers')}
                >
                  Drivers
                </a>
              </li>
              <li className='nav-item'>
                <a className={'nav-link active'} onClick={() => this.handleTabClick('groups')}>Groups</a>
              </li>
            </ul>
            <div className='tab-content access-tab mb-5'>
              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('drivers') })}>
                <CheckboxListComponent
                  enableSearch='true'
                  search={this.searchDrivers.bind(this)}
                  selectAllEnabled={true}
                  selectAll={this.selectAllDrivers.bind(this)}
                  onChange={this.checkBoxListChanged}
                  data={
                    this.state.drivers && this.state.drivers.map(item => ({
                      mainTitle: item.name || 'N/A',
                      subTitle: item.serialNumber || 'N/A',
                      checked: item.checked
                    }))}
                />
              </div>
              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('groups') })}>
                <CheckboxListComponent
                  onChange={this.checkGroupChanged}
                  data={
                    this.state.groups && this.state.groups.map(item => ({
                      mainTitle: item.name || 'N/A',
                      checked: item.checked
                    }))}
                />
              </div>
              {this.state.errors && <span className='text-danger'>{this.state.errors.drivers}</span>}
            </div>

          </div>
          <div className='col-lg-3 col-md-3 col-sm-4'>
            <label>Select Interval</label>
            <div>
                <div>
                  <label >Start Date</label>
                  <DateTime timeFormat={false} value={this.state.dateRangePicker.selection.startDate} dateFormat="DD-MM-YYYY" onChange={this.setStartDate.bind(this)} />
                  {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                  <label >End Date</label>
                  <DateTime timeFormat={false} value={this.state.dateRangePicker.selection.endDate} dateFormat="DD-MM-YYYY" onChange={this.setEndDate.bind(this)} />
                </div>
                <div>
                  <label >Start Time</label>
                  <DateTime dateFormat={false} value={this.state.dateRangePicker.selection.startTime} timeFormat="HH:mm" onChange={this.setStartTime.bind(this)} />
                  {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                  <label >End Time</label>
                  <DateTime dateFormat={false} value={this.state.dateRangePicker.selection.endTime} timeFormat="HH:mm" onChange={this.setEndTime.bind(this)} />
                </div>
              </div>
            {/* <div>
              <a onClick={this.toggle} id='Popover1'>
                <img src={calenderIcon} alt='' />
              </a>
              <Popover placement='bottom' isOpen={this.state.popoverOpen} target='Popover1' toggle={this.toggle}>
                <PopoverBody>
                  <div>
                    <DateRangePicker
                      onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      className={'PreviewArea'}
                      months={2}
                      ranges={[this.state.dateRangePicker.selection]}
                      direction='horizontal'
                      maxDate={new Date()}
                    />

                  </div>
                </PopoverBody>
              </Popover>

            </div> */}
            <div className='selected-Interval'>

              <p><b>From:</b> {moment(this.state.dateRangePicker.selection.startDate).format('MMMM Do YYYY')}</p>
              <p><b>To:</b> {moment(this.state.dateRangePicker.selection.endDate).format('MMMM Do YYYY')}</p>

            </div>
          </div>

        </div>

      </div>
    );
  }
}

DrivingScoreReportInterval.propTypes = {};
DrivingScoreReportInterval.defaultProps = {};

const mapStateToProps = state => ({ groupsAndDrivers: state.groups.groupsAndDrivers });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(Groups.creators, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(DrivingScoreReportInterval);
