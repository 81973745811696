import Duck from 'extensible-duck'
import axios from "axios";
import { telcoSiteEndpoints } from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AndromedaTelcoSites',
    store: 'telcosites',
    types: [
        'GET_THINGS', 'IS_LOADING' , 'GET_SITES'
    ],
    initialState: {
        things: null,
        isLoading: false,
        sites: [],
    },
    reducer: (state, action, duck) => {
        switch (action.type) {

            case duck.types.GET_THINGS:
                return {
                    ...state,
                    things: action.things
                }
            case duck.types.IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };
                case duck.types.GET_SITES:
                    return {
                        ...state,
                        sites: [...state.sites, ...action.sites],
                    };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getThings: () => async (dispatch) => {
            try {
                const things = await axios.get(telcoSiteEndpoints.getThings)
                dispatch({ type: duck.types.GET_THINGS, things: things.data.data });
            } catch (error) {

            }
        },
        getTelcoSiteTour: (data) => async (dispatch , getState) => {
           try {

            const response = await axios.post(telcoSiteEndpoints.getTelcoSiteTour() , data);
            return response.data.data;
            
           } catch (error) {
                console.log(error)
                dispatch({ type: duck.types.IS_LOADING, isLoading: false });
                throw error
           }
        },
        bulkUpload: (state) => async(dispatch, getState) => {
            
            if (getState().telcosites.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
               
                await axios.post(telcoSiteEndpoints.bulkUpload(state.groupId), {geofences: state.geofences});
                
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getVehiclePath: (data, id) => async (dispatch , getState) => {
            try {

                if (getState().telcosites.isLoading) {
                    // Don't issue a duplicate request (we already have or are loading the requested
                    // data)
                    return;
                }
                const response = await axios.get(telcoSiteEndpoints.getVehiclePath(id), {
                    params: { data },
                  });
                  return response.data.data;
                
            } catch (error) {
                console.log(error)
                dispatch({ type: duck.types.IS_LOADING, isLoading: false });
                throw error
            }
        },
        getTelcoSites: (state) => async (dispatch , getState) => {

            try {

                if (getState().telcosites.isLoading) {
                    // Don't issue a duplicate request (we already have or are loading the requested
                    // data)
                    return;
                }
                dispatch({ type: duck.types.IS_LOADING, isLoading: true });
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

                const response = await axios.get(telcoSiteEndpoints.getTelcoSites(companyInfo.groupId));
                let sites = response.data.data;
                dispatch({type: duck.types.GET_SITES, sites});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return sites

                
            } catch (error) {
                console.log(error)
                dispatch({ type: duck.types.IS_LOADING, isLoading: false });
                throw error
            }

        },
        CreateSite: (state) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().telcosites.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }

            try {
                dispatch({ type: duck.types.IS_LOADING, isLoading: true });
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

                await axios.post(telcoSiteEndpoints.createSite(companyInfo.groupId), state);


                dispatch({ type: duck.types.IS_LOADING, isLoading: false });
            } catch (e) {
                console.log(e)
                dispatch({ type: duck.types.IS_LOADING, isLoading: false });
                throw e;
            }
        },

    })
})