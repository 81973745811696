import React, { Component } from 'react';
import { connect } from "react-redux";
import Groups from "../../../ducks/Groups";
import RouteFence from "../../../ducks/RouteFence";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import CheckboxListComponent from "../../common/CheckboxListComponent";
import { CheckboxInlineComponent } from "../../common/CheckboxListComponent";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import find from 'lodash/find';
import '../index.css';
import { validateInput } from "../../../validations/routeFence";
import { WhiteSpinner, Spinner } from '../../common/Spinner/index';
import Slider from 'rc-slider';
import { HandleSlider } from "../../common/HandleSlider";
import to_marker from '../../../assets/img/to-marker.png';
import from_marker from '../../../assets/img/from-marker.png';
import GoogleMapComponent from "../GoogleMapComponent";
import * as _ from "lodash";
import toastr from "toastr";

class UpdateRouteFence extends Component {

  constructor() {
    super();
    this.child = React.createRef();
    this.state = {
      name: "",
      description: "",
      vehicles: [],
      groups: [],
      alertList: [

        {
          id: 1,
          mainTitle: "Entry",
          checked: false
        }, {
          id: 2,
          mainTitle: "Exit",
          checked: false
        }
      ],
      speedList: [{
        id: 1,
        mainTitle: "Speed",
        checked: false
      }],
      selectedVehicles: [],
      unselectedVehicles: [],
      activeTab: "vehicles",
      center: 0,
      radius: 0,
      errors: {},
      loader: false,
      loadingData: true,
      markerClickedMessage: '',
      markerMarked: undefined,
      fromValue: '',
      toValue: '',
      getRoutes: false,
      bufferEnabled: false,
      checkLatLngEnabled: false,
      addBuffer: false,
      bufferValue: 0,
      checkLatLng: false,
      checkLatLngValue: '',
      path: []
    }
    this.handler = this.handler.bind(this);
    this.markerClicked = this.markerClicked.bind(this);
    this.getRoutes = this.getRoutes.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.addBuffer = this.addBuffer.bind(this);
    this.checkLatLng = this.checkLatLng.bind(this);
  }

  async componentDidMount() {
    await this
      .props
      .actions
      .getGroupsAndVehicles();
    await this
      .props
      .routeFenceActions
      .getSingle({ id: this.props.match.params.id });

    let vehicles = this.props.groupsAndVehicles.vehicles.filter(obj => obj.plateNumber !== "No Vehicle" && obj.vehicleMakeAndModel !== "");
    let groups = this.props.groupsAndVehicles.groups;
    let selectedVehicles = this.props.routeFence.things;

    if (vehicles.length > 0) {
      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }

          groups[j] = groupItem;
        }
      }
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (_.indexOf(selectedVehicles, item._id) !== -1) {
          item.checked = true;
          vehicles[i] = item;
          const groupVehicle = _.find(vehicles, veh => {
            return veh.group === item.group && !veh.checked
          })
          if (!groupVehicle) {
            selectGroup(item, true);
          }
        }

      }
    }
    const routeFence = this.props.routeFence

    await this.setState({
      ...this.state,
      vehicles,
      groups,
      name: routeFence.name,
      description: routeFence.description,

      overSpeedingThreshold: routeFence.speedLimit,
      alertList: [

        {
          id: 1,
          mainTitle: "Entry",
          checked: routeFence.entryEnabled
        },
        {
          id: 2,
          mainTitle: "Exit",
          checked: routeFence.exitEnabled
        },


      ],
      speedList: [
        {
          id: 1,
          mainTitle: "Speed",
          checked: routeFence.speedEnabled
        }
      ],
      selectedVehicles: routeFence.things,
      bufferValue: routeFence.bufferSize,
      fromValue: routeFence.startLatLng,
      toValue: routeFence.endLatLng,
      path: routeFence.path,
      loadingData: false,
      markerMarked: 'from'
    });


    setTimeout(async () => {
      const latlngFrom = routeFence.startLatLng.split(',');
      const latlngTo = routeFence.endLatLng.split(',');
      const objFrom = {
        lat: parseFloat(latlngFrom[0]),
        lng: parseFloat(latlngFrom[1]),
      }
      const objTo = {
        lat: parseFloat(latlngTo[0]),
        lng: parseFloat(latlngTo[1]),
      }
      const center = [];
      center.push(parseFloat(latlngFrom[0]));
      center.push(parseFloat(latlngFrom[1]));
      this.child.current.createMarker(objFrom);
      await this.setState({...this.state, markerMarked: 'to'});
      this.child.current.createMarker(objTo);
      this.child.current.addBufferOnUpdate(routeFence.bufferSize, routeFence.path, center) 
    }, 1000);

  }
  getPath = (path) => {
    this.state.path = path;
  }

  handler(key, value) {
    this.setState({ [key]: value })
  }

  markerClicked(type) {
    this.setState({ markerClickedMessage: `${type} marker marked`, markerMarked: type });
  }

  getRoutes() {
    this.setState({ getRoutes: true })
  }

  addBuffer() {
    this.setState({ addBuffer: true })
  }

  updateInputValue(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkLatLng() {
    this.setState({ checkLatLng: true })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }
  searchVehicles = (e) => {

    const value = e.target.value;
    let vehicles = [];

    let selectedVehicles = this.state.selectedVehicles;
    if (value) {
      _.forEach(this.props.groupsAndVehicles.vehicles, (item) => {
        if (item.plateNumber.search(new RegExp(value, "i")) >= 0) {
          let index = selectedVehicles.findIndex(obj => {
            return obj === item._id;
          });

          vehicles.push({
            _id: item._id,
            plateNumber: item.plateNumber || "N/A",
            checked: index > -1
              ? true
              : false,
            vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A"
          });
        }
      });

    } else {
      vehicles = _.map(this.props.groupsAndVehicles.vehicles, (item) => {
        let index = selectedVehicles.findIndex(obj => {
          return obj === item._id;
        });
        return {
          _id: item._id,
          plateNumber: item.plateNumber || "N/A",
          checked: index > -1
            ? true
            : false,
          vehicleMakeAndModel: item.vehicleMakeAndModel || "N/A"
        };
      });
    }
    this.setState({
      ...this.state,
      vehicles
    });
  }
  checkBoxListChanged = (value) => {
    let vehicles = this.state.vehicles;
    let groups = this.state.groups;
    let selectedVehicles = this.state.selectedVehicles;
    let unselectedVehicles = this.state.unselectedVehicles;

    if (vehicles.length > 0) {

      const selectGroup = (item, check) => {
        for (let j = 0; j < groups.length; j++) {
          let groupItem = groups[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groups[j] = groupItem;
        }
      }

      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          vehicles[i] = item;
          if (item.checked) {
            selectedVehicles.push(item._id);
            const groupVehicle = _.find(vehicles, veh => {
              return veh.group === item.group && !veh.checked
            })
            if (!groupVehicle) {
              selectGroup(item, true);
            }
            let index = unselectedVehicles.findIndex(obj => {
              return obj === item._id;
            });

            if (index > -1) {
              unselectedVehicles.splice(index, 1);
            }
          } else {
            selectGroup(item, false);

            let index = selectedVehicles.findIndex(obj => {
              return obj === item._id;
            });
            unselectedVehicles.push(item._id);
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }

      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groups,
      selectedVehicles,
      unselectedVehicles
    });
  };

  checkGroupChanged = (value) => {
    let groups = this.state.groups;
    let vehicles = this.state.vehicles;
    let selectedVehicles = this.state.selectedVehicles;
    let unselectedVehicles = this.state.unselectedVehicles;

    let groupSelected;

    for (let i = 0; i < groups.length; i++) {
      let item = groups[i];
      if (i === parseInt(value, 10)) {
        item.checked = !item.checked;
        groupSelected = item;
      }
      groups[i] = item;
    }
    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (groupSelected._id === item.group) {
          item.checked = groupSelected.checked;
          if (item.checked) {
            selectedVehicles.push(item._id);
          } else {
            let index = selectedVehicles.findIndex(obj => {
              return obj === item._id;
            });
            unselectedVehicles.push(item._id)
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        }
        vehicles[i] = item;
      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groups,
      selectedVehicles,
      unselectedVehicles
    });
  };
  isTabActive = name => {
    return name === this.state.activeTab;
  };
  handleTabClick = name => {
    this.setState({
      ...this.state,
      activeTab: name
    })
  };
  checkBoxAlertChanged = (value) => {
    let alertList = this.state.alertList;

    if (alertList.length > 0) {
      for (let i = 0; i < alertList.length; i++) {
        let item = alertList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
        }
        alertList[i] = item;
      }
      this.setState({
        ...this.state,
        alertList
      });
    }
  }
  speedCheckBoxAlertChanged = (value) => {

    let { speedList } = this.state;

    if (speedList.length > 0) {
      for (let i = 0; i < speedList.length; i++) {
        let item = speedList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
        }
        speedList[i] = item;
      }
      this.setState({
        ...this.state,
        speedList
      });
    }

  };
  onMapChange = (circle) => {

    let radius = circle.getRadius();
    radius = Math.round(radius);

    this.setState({
      ...this.state,
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius
    });
    if (radius < 300) {
      this.setState({
        radius: 300
      });
    }
    return ({
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius
    })
  }
  changeRadius = (event) => {
    let value = '';
    if (event.target.value) {
      value = parseInt(event.target.value, 10);
    } else {

    }
    this.setState({ radius: value });
  }
  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };
  selectAllVehicles = (value) => {

    let vehicles = this.state.vehicles;
    let groupList = this.state.groups;
    let selectedVehicles = this.state.selectedVehicles;

    if (vehicles.length > 0) {

      const selectGroup = (item, check) => {
        for (let j = 0; j < groupList.length; j++) {
          let groupItem = groupList[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groupList[j] = groupItem;
        }
      };

      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];

        item.checked = value;
        vehicles[i] = item;
        if (item.checked) {
          selectedVehicles.push(item._id);
          const groupVehicle = find(vehicles, veh => {
            return veh.group === item.group && !veh.checked
          });
          if (!groupVehicle) {
            selectGroup(item, true);
          }
        } else {
          selectGroup(item, false);

          let index = selectedVehicles.findIndex(obj => {
            return obj === item._id;
          });
          if (index > -1) {
            selectedVehicles.splice(index, 1);
          }
        }


      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groupList,
      selectedVehicles
    });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    if (this.isValid()) {
      try {
        const state = this.state;
        this.setState({
          ...this.state,
          loader: true
        })
        let routeFenceObj = {
          name: _.trim(state.name),
          description: _.trim(state.description),
          things: state.selectedVehicles,
          entryEnabled: state.alertList[0].checked,
          exitEnabled: state.alertList[1].checked,
          speedEnabled: state.speedList[0].checked,
          path: state.path,
          bufferSize: state.bufferValue,
          startLatLng: state.fromValue,
          endLatLng: state.toValue,
          speedLimit: state.overSpeedingThreshold,
        }
        await this
          .props
          .routeFenceActions
          .RouteFence({ routeFenceObj, id: this.props.match.params.id });
        toastr.success("Routefence Updated Successfully!");
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push(`/routefence/list`);

      } catch (err) {
        this.setState({
          ...this.state,
          loader: false
        })
        toastr.error(err.response.data.message);
      }
    }
  };
  changeOverspeedingThreshold = async (value) => {
    this.setState({ overSpeedingThreshold: value });
  };

  render() {
    const { speedList, name, description, overSpeedingThreshold, loadingData } = this.state;

    const [speed] = speedList;
    const { checked: isSpeedEnabled } = speed;
    const loader = <WhiteSpinner key="1" />;
    const loader1 = <Spinner key="2" />

    return (
      <div>{loadingData ? [loader1] :
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <TextFieldGroup
                onChange={this.onChange.bind(this)}
                value={name}
                label={"Routefence Name*"}
                type={"text"}
                field={"name"}
                error={this.state.errors.name}
                placeholder={"E.g Warehouse, school"} />
              <TextAreaGroup
                onChange={this.onChange.bind(this)}
                value={description}
                label={"Description"}
                field={"description"}
                error={this.state.errors.description}
                placeholder={"Extra details about routefence"} />

              <div>
                <div className="form-group required control-label">
                  <img id="from"
                    height="20"
                    src={from_marker}
                    alt="to"
                    onClick={() => this.markerClicked('from')}
                  />
                  <label htmlFor="source">Source/From</label>
                  <input type="text"
                    id="source"
                    className="form-control"
                    step="0.01"
                    placeholder="Click on the icon to map"
                    disabled
                    required
                    value={this.state.fromValue}
                  />
                </div>

                <div className="form-group required control-label">
                  <img id="to"
                    height="20"
                    src={to_marker}
                    alt="to"
                    onClick={() => this.markerClicked('to')}
                  />
                  <label htmlFor="destination">Destination/To</label>
                  <input type="text"
                    id="destination"
                    className="form-control"
                    step="0.01"
                    placeholder="Click on the icon to map"
                    disabled
                    required
                    value={this.state.toValue}
                  />
                </div>
                <div className="form-group required control-label">

                  <label htmlFor="bufferInput">Buffer (meters)</label>
                  <input type="number"
                    id="bufferValue"
                    name="bufferValue"
                    className="form-control"
                    step="0.01"
                    placeholder="Enter buffer distance in meters"
                    disabled={!this.state.bufferEnabled}
                    required
                    value={this.state.bufferValue}
                    onChange={this.updateInputValue}
                  />
                </div>

                <small id="message" className="text-success">{this.state.markerClickedMessage}</small>

                <div className="form-group">
                  <div>
                    <button type="button"
                      className="btn btn-success mr-1"
                      onClick={this.getRoutes}
                    >
                      Get Route
                </button>
                    <button type="button"
                      className="btn btn-success mr-1"
                      disabled={!this.state.bufferEnabled}
                      onClick={this.addBuffer}
                    >
                      Add Buffer
                </button>

                  </div>

                </div>

              </div>




              <div className="col padding0">

                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={"nav-link active"}
                      onClick={() => this.handleTabClick("vehicles")}>Vehicles</a>

                  </li>
                  <li className="nav-item">
                    <a className={"nav-link active"} onClick={() => this.handleTabClick("groups")}>Groups</a>

                  </li>
                </ul>
                <div className="tab-content access-tab mb-5 heading-200">
                  <div
                    className={classnames("tab-pane fade", {
                      "show active": this.isTabActive("vehicles")
                    })}>
                    <CheckboxListComponent
                      enableSearch="true"
                      selectAllEnabled={true}
                      selectAll={this.selectAllVehicles.bind(this)}
                      search={this
                        .searchVehicles
                        .bind(this)}
                      onChange={this.checkBoxListChanged}
                      data={this.state.vehicles && this
                        .state
                        .vehicles
                        .map(item => ({
                          mainTitle: item.plateNumber || "N/A",
                          subTitle: item.vehicleMakeAndModel || "N/A",
                          checked: item.checked
                        }))} />
                  </div>
                  <div
                    className={classnames("tab-pane fade", {
                      "show active": this.isTabActive("groups")
                    })}>
                    <CheckboxListComponent
                      onChange={this.checkGroupChanged}
                      data={this.state.groups && this
                        .state
                        .groups
                        .map(item => ({
                          mainTitle: item.name || "N/A",
                          checked: item.checked
                        }))} />
                  </div>
                  {this.state.errors &&
                    <span className="text-danger">{this.state.errors.vehicles}</span>}
                </div>
                <div className={'form-group'}>


                  <label>Enable Speed</label>

                  <CheckboxInlineComponent
                    onChange={this.speedCheckBoxAlertChanged}
                    selectAllEnabled={false}
                    data={this
                      .state
                      .speedList
                      .map(item => ({ id: item.id, mainTitle: item.mainTitle, checked: item.checked }))} /> {this.state.errors && <span className="text-danger">{this.state.errors.type}</span>}

                </div>
                {isSpeedEnabled && <div className={'form-group'}>
                  <p>{overSpeedingThreshold}</p>
                  <Slider min={0} max={200} defaultValue={overSpeedingThreshold} handle={HandleSlider} onChange={this.changeOverspeedingThreshold} />
                </div>}
                <div className={'form-group'}>
                  <label>Alerts On</label>

                  <CheckboxInlineComponent
                    onChange={this.checkBoxAlertChanged}
                    selectAllEnabled={false}
                    data={this
                      .state
                      .alertList
                      .map(item => ({ id: item.id, mainTitle: item.mainTitle, checked: item.checked }))} /> {this.state.errors && <span className="text-danger">{this.state.errors.type}</span>}

                </div>

                <div className="margin-btm-50">
                  <button className="btn auxo-primary-btn create-geofence-btn" type="submit">
                    {this.state.loader ? [loader] : 'Update'}</button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 routefence-map">
              <GoogleMapComponent
                ref={this.child}
                handler={this.handler}
                markerMarked={this.state.markerMarked}
                addBuffer={this.state.addBuffer}
                bufferValue={this.state.bufferValue}
                getRoutes={this.state.getRoutes}
                checkLatLng={this.state.checkLatLng}
                checkLatLngValue={this.state.checkLatLngValue}
                getPath={this.getPath.bind(this)}
              />
            </div>

          </div>
        </form>
      }</div>
    );
  }
}

UpdateRouteFence.propTypes = {};
UpdateRouteFence.defaultProps = {};
const mapStateToProps = state => ({ groupsAndVehicles: state.groups.groupsAndVehicles, routeFence: state.routeFence.routeFence });

const mapDispatchToProps = dispatch => ({
  routeFenceActions: bindActionCreators(RouteFence.creators, dispatch),
  actions: bindActionCreators(Groups.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateRouteFence);
