import React, {Component} from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import background from "../../assets/img/login-background.jpg";
import "./Login.css";
import auxo from "../../assets/img/AUXO-Horiz-fleet.png";
import toastr from "toastr";
import {connect} from "react-redux";
import UserAuthentication from "../../ducks/UserAuthentication";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import {validateForgotPassword} from "../../validations/auth";
import {WhiteSpinner} from '../common/Spinner/index';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            errors: {},
            loader: false
        }
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value, errors:{...this.state.errors, [e.target.name]: ''}});
    };

    isValid = () => {
        const {errors, isValid} = validateForgotPassword(this.state);

        if (!isValid) {
            this.setState({errors});
        }

        return isValid;
    };


    onSubmit = async e => {
        e.preventDefault();
        if (this.isValid()) {
        try {
            this.setState({...this.state, loader: true})
            await this.props.actions.forgotPassword(this.state.username);
            toastr.success('Please check your resgistered email to reset password')
            this.props.history.push("/");
            this.setState({...this.state, loader: false})
        }
        catch (error) {
            if (error.response) {
                const data = error.response.data.message;
                toastr.error(data);
            }
            else {
                toastr.error(error);
            }
            this.setState({...this.state, loader: false})
        }
    }
    };

    render() {
        const loader = <WhiteSpinner key="1"/>
        return (
            <div className="justify-content-md-center login-container" style={{backgroundImage: background}}>
                <div className="login-box mt-lg-5">
                    <div className="row">
                        <img className="login-logo" src={auxo} alt=""/>
                    </div>
                    <form className={"login-form"} onSubmit={this.onSubmit}>
                        <TextFieldGroup
                            field={"username"}
                            placeholder={"Enter username provided to you"}
                            value={this.state.username}
                            label={"Username"}
                            type={"text"}
                            onChange={this.onChange}
                            error={this.state.errors.username}/>
                        <div className="mt-lg-5">
                            <button className="btn btn-success btn-login" type="submit">{this.state.loader ? [loader] :  'Reset Password'}</button>
                        </div>
                        <div className={"help-link-section"}>
                            <Link to="/login">Log In</Link>
                           
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(UserAuthentication.creators, dispatch)});
export default connect(null,mapDispatchToProps)(ForgotPassword);
