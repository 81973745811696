import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as _ from 'lodash';
import Modal from 'react-bootstrap4-modal';
import CSVReader from 'react-csv-reader';
import * as R from 'ramda';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner, WhiteSpinner } from '../../common/Spinner/index';
import GeoFence from '../../../ducks/GeoFence';
import TextFieldGroup from '../../common/TextFieldGroup';
import searchIcon from '../../../assets/img/ic_search.png';
import editIcon from '../../../assets/img/ic_edit.png';
import trash_bin from '../../../assets/img/trash_bin.png';
import { CheckboxInlineComponent } from '../../common/CheckboxListComponent';
import { isEmptyValue, isAlphaNumeric } from '../../../validations/validator';
import { URL } from '../../../constants';
import '../index.css';

class GeoFenceListing extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingRecords: true,
      search: '',
      geoFences: [],
      geoFenceToBeDeleted: {},
      geofencesImport: [],
      showImportGeofence: false,
      deletingGeofence: false,
    };
    this.searchGeoFence = this.searchGeoFence.bind(this);
    this.onChangeData = this.onChangeData.bind(this);
    this.checkBoxAlertChanged = this.checkBoxAlertChanged.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  loadGeofences = async (initLoad) => {
    await this
      .props
      .actions
      .getGroupGeoFences1(this.state.search, initLoad);

    this.setState({
      ...this.state,
      geoFences: this.props.geoFences,
      isLoadingRecords: false
    })
  }
  async componentDidMount() {
   await this.loadGeofences(true);
  }

 

  async onChange(event) {
    const value = event.target.value;
    if (value) { this.setState({ ...this.state, search: value || '' }); } else {
      await this.setState({ ...this.state, search: value || '' })
      this.loadGeofences(true);
    }
  }
  onMapChange() { }
  navigateToCreateGeoFence() {
    this
      .props
      .history
      .push('/geofence');
  }

  updateGeoFence(item) {
    this
      .props
      .history
      .push(`/geofence/update/${item._id}`);

  }
  showDeleteGeoFenceConfirmation(item) {
    this.setState({
      ...this.state,
      geoFenceToBeDeleted: item,
      showDeleteConfirmation: true
    })

  };
  
  async deleteGeoFence() {
    if (!this.state.deletingGeofence) {
      await this.setState({
        deletingGeofence: true,
      });
      await this
        .props
        .actions
        .deleteGeoFence({ id: this.state.geoFenceToBeDeleted._id, vehicles: this.state.geoFenceToBeDeleted.vehicles });

      toastr.success("Geofence deleted successfully!");

      let geoFences = _.filter(this.state.geoFences, item => {
        return item._id !== this.state.geoFenceToBeDeleted._id;
      })
      this.setState({
        ...this.state,
        showDeleteConfirmation: false,
        geoFences,
        deletingGeofence: false,
      })
    }
  }
  async uploadGeoFence() {
    try {
      const { isLoading } = this.state;
      if (isLoading) {
        return;
      }
      const geofences = this.state.geofencesImport;
      const hasError = geofences.reduce((error, geofence) => {
        if (error) {
          return error;
        }
        if (!geofence.name) {
          return true;
        }
        if (!geofence.latlng) {
          return true;
        }
        if (!geofence.vehicles) {
          return true;
        }
        return false;

      }, false);

      if (hasError) {
        return toastr.error('Some fields are missing.');
      }
      this.setState({ isLoading: true });
      let companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

      await this.props.actions.importGeofence({ geofences: this.state.geofencesImport, groupId: companyInfo.groupId });
      toastr.success("Geofences will be imported in a while!");
      this.setState({
        ...this.state,
        isLoading: false,
        showImportGeofence: false,
        geofencesImport: [],
      })
    } catch (e) {
      this.setState({ isLoading: false });
      toastr.error(e.response ? e.response.data.message : e);
    }

  }
  cancelDelete() {
    this.setState({ ...this.state, showDeleteConfirmation: false })
  }
  cancelUpload() {
    this.setState({ ...this.state, showImportGeofence: false })
  }

  async searchGeoFence(e) {
    e.preventDefault();
    const value = this.state.search;
    await this.setState({
      ...this.state,
      search: value || ''
    });
    this.loadGeofences(true);
  }
  openGeofence(id) {
    let geoFences = _.map(this.state.geoFences, item => {
      if (item._id === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });

    this.setState({
      ...this.state,
      geoFences
    });
  }

  handleDarkSideForce() {
    toastr.error('Invalid file format, csv file is required!');
  }

  handleFileUpload(data) {
  
    if (data && data.length) {
      if (data.length <= 1) {
        return toastr.error('No data found.');
      }
      let heading = data[0];

      if (heading[0] !== 'Name' || heading[1] !== 'Description' || heading[2] !== 'Vehicles' || heading[3] !== 'Enable Speed' || heading[4] !== 'Speed Limit' || heading[5] !== 'Entry Alerts' || heading[6] !== 'Exit Alerts' || heading[7] !== 'lat,lng' || heading[8] !== 'Radius') {
        return toastr.error('Invalid Columns in csv!');
      }

      let geofencesImport = [];
      _.forEach(data, (item, index) => {

        const isAllEmpty = R.all(R.isEmpty, item);

        if (index !== 0 && item.length === 9 && !isAllEmpty) {

          let obj = { name: '', description: '', vehicles: '', enableSpeed: false, speedLimit: 0, entryAlerts: true, exitAlerts: true, latlng: '', radius: 300, valid: true };
          _.forEach(item, (value, valueIndex) => {

            if (valueIndex === 0) {
              obj.name = _.trim(value);

              if (isEmptyValue(obj.name)) {
                toastr.error('Provide value for name');
                // obj.valid = false;
              }
              // else if (!isAlphaNumeric(obj.name)) {
              //   toastr.error('Geofence name must be alphanumeric');
              //   obj.valid = false;
              // }
              // else if (obj.name.length > 100) {
              //   toastr.error('Max 100 characters are allowed in name');
              //   // obj.valid = false;
              // }

            }
            if (valueIndex === 1 && obj.valid) {
              obj.description = _.trim(value);
              if (!isAlphaNumeric(obj.description)) {
                toastr.error('Description must be alphanumeric');
                // obj.valid = false;
              }
              else if (obj.description.length > 500) {
                toastr.error('Max 500 characters are allowed in description.');
                // obj.valid = false;
              }
            }
            if (valueIndex === 2 && obj.valid) {
              obj.vehicles = _.trim(value);
              if (obj.vehicles.length === 0) {
                toastr.error('Please select vehicles');
                // obj.valid = false;
              }
            }
            if (valueIndex === 3 && obj.valid) {
              let bool = _.trim(value);
              if (bool === 'yes') {
                obj.enableSpeed = true;
              } else if (bool === 'no') {
                obj.enableSpeed = false;
              } else {
                toastr.error('Enable speed value must be yes or no');
                // obj.valid = false;
              }

            }
            if (valueIndex === 4 && obj.valid && obj.enableSpeed) {
              obj.speedLimit = parseInt(value, 10);
              if (isNaN(obj.speedLimit)) {
                toastr.error('Speed Limit value must be a number');
                // obj.valid = false;
              }
            }
            if (valueIndex === 5 && obj.valid) {
              let bool = _.trim(value);
              if (bool === 'yes') {
                obj.entryAlerts = true;
              } else if (bool === 'no') {
                obj.entryAlerts = false;
              } else {
                toastr.error('Entry Alerts value must be yes or no');
                // obj.valid = false;
              }

            }
            if (valueIndex === 6 && obj.valid) {
              let bool = _.trim(value);
              if (bool === 'yes') {
                obj.exitAlerts = true;
              } else if (bool === 'no') {
                obj.exitAlerts = false;
              } else {
                toastr.error('Entry Alerts value must be yes or no');
                // obj.valid = false;
              }

            }
            if (valueIndex === 7 && obj.valid) {
              obj.latlng = _.trim(value);
              if (!obj.latlng) {
                toastr.error('latlng not provided');
                // obj.valid = false;
              }

            }
            if (valueIndex === 8 && obj.valid) {
              obj.radius = parseInt(value, 10);
              if (isNaN(obj.radius)) {
                toastr.error('Radius must be a number');
                // obj.valid = false;
              }
              if (obj.radius < 300) {
                toastr.error(`Radius of ${obj.name} is converted to 300 m`);
                obj.radius = 300;
              }

            }
          })

          if (obj.valid === true) {
            geofencesImport.push(obj);
          }
        }
      });

      if (geofencesImport.length) {
        this.setState({ ...this.state, geofencesImport, showImportGeofence: true });
      }

    }
  }
  onChangeData(e) {

    if (e && e.target) {
      const index = e.target.id;

      let geofencesImport = this.state.geofencesImport;
      let item = geofencesImport[index]
      item[e.target.name] = e.target.value;
      geofencesImport[index] = item;
      this.setState({ ...this.state, geofencesImport })
    }
  };
  checkBoxAlertChanged(index, type) {

    let geofencesImport = this.state.geofencesImport;
    let item = geofencesImport[index]
    item[type] = !item[type];
    geofencesImport[index] = item;
    this.setState({ ...this.state, geofencesImport })
  }

  render() {
    let notFound;
    let token = localStorage.getItem('refreshToken');
    const geoFences = this.state.geoFences;
    if (!this.state.isLoadingRecords && geoFences.length === 0) {
      notFound = (
        <div className='no-data-found' key='1'>
          <h2>No geofence added</h2>
        </div>)
    };

    const loader = <Spinner key='1' />
    const smallLoader = <WhiteSpinner key="1" />

    return (

      <div>
       
     
        {this.state.showDeleteConfirmation ?
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName='delete-modal-dialog'>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Delete Geofence?</h5>
            </div>
            <div className='modal-body'>
              <h6>Are you sure you want to delete Geofence {this.state.geoFenceToBeDeleted.name}?</h6>


            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .deleteGeoFence
                  .bind(this)}>
                {this.state.deletingGeofence ? [smallLoader] : 'Confirm Delete'}
              </button>

              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
          : ''}
        {this.state.showImportGeofence &&
          <Modal
            visible={this.state.showImportGeofence}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName=''>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelUpload
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Import Geofences</h5>
            </div>
            <div className='modal-body'>
              <table className='table-auxo'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th>VEHICLES</th>
                    <th>Enable Speed</th>
                    <th>SPEED LIMIT</th>
                    <th>Entry Alerts</th>
                    <th>EXIT Alerts</th>
                    <th>LAT,LNG</th>
                    <th>RADIUS (METERS)</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.geofencesImport && this
                    .state
                    .geofencesImport
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td >
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.name}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'name'}
                              placeholder={'Geofence name'}
                              isRequired={true}
                            />
                          </td>
                          <td >
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.description}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'description'}
                              placeholder={'Description'}
                            />

                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.vehicles}
                              id={index}
                              key={index}
                              type={'text'}
                              field={'vehicles'}
                              placeholder={'Vehicles'}
                              isRequired={true}
                            />

                          </td>
                          <td>
                            <CheckboxInlineComponent
                              onChange={this.checkBoxAlertChanged.bind(this, index, 'enableSpeed')}
                              selectAllEnabled={false}
                              data={[{ id: index, mainTitle: '', checked: item.enableSpeed }]} />

                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.speedLimit}
                              type={'number'}
                              id={index}
                              key={index}
                              field={'speedLimit'}
                              placeholder={'Speed limit'}
                            />
                          </td>
                          <td>
                            <CheckboxInlineComponent
                              onChange={this.checkBoxAlertChanged.bind(this, index, 'entryAlerts')}
                              selectAllEnabled={false}
                              data={[{ id: index, mainTitle: '', checked: item.entryAlerts }]} />

                          </td>
                          <td>
                            <CheckboxInlineComponent
                              onChange={this.checkBoxAlertChanged.bind(this, index, 'exitAlerts')}
                              selectAllEnabled={false}
                              data={[{ id: index, mainTitle: '', checked: item.exitAlerts }]} />

                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.latlng}
                              type={'text'}
                              id={index}
                              key={index}
                              field={'latlng'}
                              placeholder={'lat, lng'}
                              isRequired={true}
                            />
                          </td>
                          <td>
                            <TextFieldGroup
                              onChange={this.onChangeData}
                              value={item.radius}
                              type={'number'}
                              id={index}
                              key={index}
                              field={'radius'}
                              placeholder={'Radius'}
                              isRequired={true}
                            />
                          </td>


                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .uploadGeoFence
                  .bind(this)}>
                Confirm
                    </button>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelUpload
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
        }

        <div className='row'>
          <div className='col-lg-3 col-md-3 col-sm-4 margin-top-20'>
            <form onSubmit={this.searchGeoFence}>
              <TextFieldGroup
                field={"tsearch"}
                placeholder={"Search Geofence"}
                value={this.state.search}
                type={'text'}
                onChange={this
                  .onChange
                  .bind(this)} />
              <a className='search-icon' onClick={this.searchGeoFence}><img src={searchIcon} alt='' /></a>
            </form>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2 margin-top-20'>
          {!this.state.isLoadingRecords &&
        <div className="geofenceCount">Total Geofence: {this.props.geoFenceCount}</div>
        }
          </div>

          <div className='col-lg-7 col-md-7 col-sm-7'>
            {this.props.permissions.createGeoFence ?
              <span>
                <button
                  className='btn auxo-primary-btn'
                  onClick={this
                    .navigateToCreateGeoFence
                    .bind(this)}>Create Geofence</button>

                <span>
               
                  <button className='btn auxo-primary-btn' onClick={() => {
                    document.getElementById('UniqueObiWan').click();
                  }}>
                     
                    <div className='import-geofence'>Import Geofences
                   </div>
                   {!this.state.showImportGeofence && <CSVReader
                        cssClass='things-input-reader'
                        accept='.csv'
                        onFileLoaded={this.handleFileUpload}
                        onError={this.handleDarkSideForce}
                        inputId='UniqueObiWan'
                      />
                      }
                  </button>
                </span>
                <a
                  className='download-geofence-template btn auxo-primary-btn'
                  href={`${URL}/companies/files/GeofenceTemplate.csv?token=${token}`}
                >Download Template</a>
              </span>
              : ''}
          </div>
        </div>
        {this.state.isLoadingRecords ?
          [loader] : 
        <div className='row'>
        <InfiniteScroll
          pageStart={0}
          className="w-100-p"
          loadMore={()=>{this.loadGeofences(false)}}
          hasMore={this.props.hasMoreItems}
          loader={loader}>
          {!this.state.isLoadingRecords && geoFences.length !== 0
            ? <div className='trip-table-heading'>
              <p className='width-20'>GEOFENCE</p>
              <p className='width-20'>APPLIED ON</p>
              <p className='width-20'>ALERTS ON</p>
              <p className='width-30'>LOCATION</p>
              {this.props.permissions.updateGeoFence || this.props.permissions.deleteGeoFence ?
                <p className='width-10'>ACTIONS</p> : ''}
            </div>
            : [notFound]}

          {geoFences.map((item, index) => {
            index = 'abc' + index + 'z';
            item = _.clone(item);


            let altersOn = 'Entry and Exit';
            if (item.entryEnabled && !item.exitEnabled) {
              altersOn = 'Entry Only';
            } else if (!item.entryEnabled && item.exitEnabled) {
              altersOn = 'Exit Only';
            } else if (!item.entryEnabled && !item.exitEnabled) {
              altersOn = 'None';
            }
            return (

              <div className='width-100' key={index}>
                <div className='trip-table-data'>

                  <p className='width-20'>

                    {/* <a id={`${index}`} className='trip-date width-10' onClick={this.openGeofence.bind(this, item._id)}>
                                            <img className={item.isSelected ? 'list-view-transform width18' : 'width18'}  alt='' src={forwardIcon} />
                                        </a> */}
                    {item.name}
                  </p>

                  <p className='width-20'>{`${item.vehicles.length} Vehicles`} </p>

                  <p className='width-20'>{altersOn}</p>

                  <p className='width-30'>{item.geoFenceLocation || 'N/A'}</p>
                  <span>
                    {this.props.permissions.updateGeoFence ?
                      <a className='padding-10' onClick={this
                        .updateGeoFence
                        .bind(this, item)}><img src={editIcon} alt='' /></a> : ''}
                    {this.props.permissions.deleteGeoFence ?
                      <a onClick={this
                        .showDeleteGeoFenceConfirmation
                        .bind(this, item)}><img src={trash_bin} className='width16' alt='' /></a> : ''}
                  </span>
                </div>

                {/* <UncontrolledCollapse toggler={`${index}`} className='collapse-nav '>
                                    <div className='geoFence-list-map col-lg-6 col-md-6 col-sm-8'>
                                        <GeoFenceMap
                                            center={{
                                                lat: _.clone(item.center[1]),
                                                lng: _.clone(item.center[0])
                                            }}
                                            draggable={false}
                                            editable={false}
                                            radius={_.clone(item.radius)}
                                            onMapChange={this.onMapChange} />
                                    </div>
                                </UncontrolledCollapse> */}
              </div>

            )
          })}
 </InfiniteScroll>
        </div>
        }
     </div>
    );
  }
}

GeoFenceListing.propTypes = {};
GeoFenceListing.defaultProps = {};
const mapStateToProps = state => ({ 
  geoFences: state.geoFence.geoFences,
  geoFenceCount: state.geoFence.geoFenceCount,
  hasMoreItems: state.geoFence.hasMoreItems,
  permissions: state.permissions });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(GeoFence.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(GeoFenceListing);
