import * as  R from 'ramda';
import moment from 'moment';
import React from 'react';

const IO_EVENT_MAPPING = {
  ID:"ID",
  IP:"IP",
  ST:"ST",
  RN:"RN",
  SN:"SN",
  VR:"VR",
  Date:"Date",
};

const getData = (plate) => (state) => {
  let _state = R.path(['_source', 'state'])(state)
  // if (_state.tcxn) {
  //   _state = { connection_status: _state.tcxn.connection_status };
  // }
  if(_state.tcxn){
    _state.connection_status = _state.tcxn.connection_status;
    delete _state.tcxn;
  }
  const pairedState = R.toPairs(_state);
  const replaceKeysMap = (obs) => {
    const [key, value] = obs;
    return [IO_EVENT_MAPPING[key] || key, value];
  }
  const mappedState = R.map(replaceKeysMap)(pairedState);
  const obs = R.fromPairs(mappedState);
  const gpsTime = obs['Date'] ? moment(obs['Date']).format('DD/MM/YYYY hh:mm:ss A') : 'N/A';
  // const fuelValue = obs['FuelValue'] ? ((47.649) + (2.1224 * obs['FuelValue']) + (0.00007 * (obs['FuelValue'] * obs['FuelValue']))).toFixed(3) : 'N/A';
  obs['VR'] = JSON.parse( obs['VR']);
  const id = obs['ID'] ? obs['ID'] : 'N/A';
  const ip = obs['IP'] ? obs['IP'] : 'N/A';
  const st = obs['ST'] ? obs['ST'] : 'N/A';
  const rn = obs['RN'] ? obs['RN'] : 'N/A';
  const sn = obs['SN'] ? obs['SN'] : 'N/A';
  const v_p_1 = obs['VR'] && obs['VR'].length && obs['VR'][0] ? obs['VR'][0] : 'N/A';
  const v_p_2 = obs['VR'] && obs['VR'].length && obs['VR'][1] ? obs['VR'][1] : 'N/A';
  const v_p_3 = obs['VR'] && obs['VR'].length && obs['VR'][2] ? obs['VR'][2] : 'N/A';
  const c_p_1 = obs['VR'] && obs['VR'].length && obs['VR'][3] ? obs['VR'][3] : 'N/A';
  const c_p_2 = obs['VR'] && obs['VR'].length && obs['VR'][4] ? obs['VR'][4] : 'N/A';
  const c_p_3 = obs['VR'] && obs['VR'].length && obs['VR'][5] ? obs['VR'][5] : 'N/A';
  const p_v_1_2 = obs['VR'] && obs['VR'].length && obs['VR'][6] ? obs['VR'][6] : 'N/A';
  const p_v_2_3 = obs['VR'] && obs['VR'].length && obs['VR'][7] ? obs['VR'][7] : 'N/A';

  const power_1 = v_p_1 !== 'N/A' && c_p_1 !== 'N/A'? v_p_1*c_p_1 : 'N/A';
  const power_2 = v_p_2 !== 'N/A' && c_p_2 !== 'N/A'? v_p_2*c_p_2 : 'N/A';
  const power_3 = v_p_3 !== 'N/A' && c_p_3 !== 'N/A'? v_p_3*c_p_3 : 'N/A';


    const values = {
      gpsTime,
      id,
      ip,
      st,
      rn,
      sn,
      v_p_1,
      v_p_2,
      v_p_3,
      c_p_1,
      c_p_2,
      c_p_3,
      p_v_1_2,
      p_v_2_3,
      power_1,
      power_2,
      power_3,
    };
  
 

  return values;
}

export default getData;

