import Duck from 'extensible-duck'
import axios from "axios";
import { userEndpoints, groupEndPoints,thingsEndPoints } from "../api/endpoints";
import toastr from "toastr";
import socketIOClient from "socket.io-client";
import { socketAuth, SOCKET_URL } from '../constants';
import Things from "./Things";
import * as moment from 'moment';
import notificationSound from "../assets/audio/notification2.mp3";
// import UserAuthentication from "./UserAuthentication";

const thingsActions = Things.creators;
// const userAuthentication = UserAuthentication.creators;


let socket = {};
export default new Duck({
    namespace: 'AuxoUsers', store: 'users',
    types: ['ASK_FOR_PASSENGER_SEAT','USER_IS_LOADING', 'USER_FETCHED', 'USER_NOTIFICATIONS_FETCHED', 'RESET_LIST', 
    'SINGLE_USER_FETCHED', 'USER_NOTIFICATIONS_SETTING', 'NEW_NOTIFICATION', 'VIEW_NOTIFICATIONS', 'CLEAR_NOTIFICATIONS'],
    initialState: {
        list: [],
        user: {},
        offset: 0,
        thingId:'',
        notificationsOffset: 0,
        notificationsSetting: {
            geoFenceEntry: false,
            geoFenceExit: false,
            harshAcceleration: false,
            harshBrake: false,
            overSpeeding: false,
            sharpTurn: false,
            vehicleMoving: false,
            vehicleParked: false,
            vehicleOffline: false,
            vehicleOnline: false,
            unfasten_seatbelt: false,
            engineKilled: false,
            engineReleased: false,
            warningPopup: false,
            buzzer: false,
        },
        smsSetting: {
            geoFenceEntry: false,
            geoFenceExit: false,
            harshAcceleration: false,
            harshBrake: false,
            overSpeeding: false,
            sharpTurn: false,
            vehicleMoving: false,
            vehicleParked: false,
            vehicleOffline: false,
            vehicleOnline: false,
            engineKilled: false,
            engineReleased: false,
            unfasten_seatbelt: false,
        },
        emailSetting: {
            geoFenceEntry: false,
            geoFenceExit: false,
            harshAcceleration: false,
            harshBrake: false,
            overSpeeding: false,
            sharpTurn: false,
            vehicleMoving: false,
            vehicleParked: false,
            vehicleOffline: false,
            vehicleOnline: false,
            engineKilled: false,
            engineReleased: false,
            unfasten_seatbelt: false,
        },
        notifications: { notifications: [], notificationsCount: 0 },
        hasMoreNotifications: false,
        hasMoreItems: true,
        isLoading: false,
        passengerModal:false,
        message: '',
        platenumber : ''
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.USER_IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };
            case duck.types.USER_FETCHED:
                return {
                    ...state,
                    list: action.users,
                    offset: state.offset + action.users.length,
                    hasMoreItems: action.users.length > 0 ? true : false
                };
            case duck.types.RESET_LIST:
                return {
                    ...state,
                    list: [],
                    offset: 0,
                    hasMoreItems: true
                };
            case duck.types.USER_NOTIFICATIONS_SETTING:

                let smsSetting = state.smsSetting;
                let emailSetting = state.emailSetting;
                if (action.smsSetting) {
                    smsSetting = action.smsSetting
                }
                if (action.emailSetting) {
                    emailSetting = action.emailSetting
                }
                return {
                    ...state,
                    notificationsSetting: action.notificationsSetting,
                    smsSetting,
                    emailSetting
                };
            case duck.types.USER_NOTIFICATIONS_FETCHED:

                try {
                    return {
                        ...state,
                        notifications: {
                            notifications: [...state.notifications.notifications, ...action.notifications.notifications],
                            notificationsCount: action.notifications.notificationsCount
                        },
                        notificationsOffset: state.notificationsOffset + action.notifications.notifications.length,
                        hasMoreNotifications: action.notifications.notifications.length < 50 ? false : true
                    };
                } catch (err) {
                    return state;
                }
            case duck.types.NEW_NOTIFICATION:

                return {
                    ...state,
                    notifications: {
                        notifications: [...action.data, ...state.notifications.notifications],
                        notificationsCount: state.notifications.notificationsCount + 1
                    },
                    notificationsOffset: state.notificationsOffset + 1
                };
            case duck.types.CLEAR_NOTIFICATIONS:

                return {
                    ...state,
                    notifications: {
                        notifications: [],
                        notificationsCount: state.notificationsCount
                    },
                    notificationsOffset: 0
                };
            case duck.types.VIEW_NOTIFICATIONS:

                return {
                    ...state,
                    notifications: {
                        notifications: [...state.notifications.notifications],
                        notificationsCount: 0
                    },
                };
            case duck.types.SINGLE_USER_FETCHED:
                return {
                    ...state,
                    user: action.user
                };
            case duck.types.ASK_FOR_PASSENGER_SEAT :
                return {
                    ...state,
                    passengerModal: action?.Data?.modal , 
                    platenumber: action?.Data?.plateNumber , 
                    message: action?.Data?.message , 
                    thingId : action?.Data?.thingId ? action?.Data?.thingId : ''
                };
            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({
        setPassengerModalStatusandAnswer: (data) => async (dispatch, getState) => {
            try {
                let thingId = getState().users.thingId;
                if (thingId) {
                    const response = await axios.get(thingsEndPoints.setPassengerSeatBeltCheck({thingId, 'isPassenger' : data.driverAnswer }));
                    // const response = await axios.get(`http://localhost:8080/api/v1/things/passengercheck/${thingId}/${data.driverAnswer}`);
                    dispatch({ type: duck.types.ASK_FOR_PASSENGER_SEAT, Data : {'modal':data.modalStatus} });
                }
            }
            catch (e) {
                console.log("-----e----",e);
                // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                // throw e;
            }
        },
        getUsers: () => async (dispatch, getState) => {

            try {
                // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                let companyInfo = localStorage.getItem("companyInfo");
                if (companyInfo) {
                    const { groupId } = JSON.parse(localStorage.getItem("companyInfo"));
                    const response = await axios.get(groupEndPoints.groupUsers(groupId));
                    const users = response.data.data;
                    dispatch({ type: duck.types.USER_FETCHED, users });
                }

                // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
            }
            catch (e) {
                // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                // throw e;
            }
        },
        getUser: (userId) => async (dispatch, getState) => {
            try {
                const response = await axios.get(userEndpoints.user + `/${userId}`);
                const user = response.data.data;
                dispatch({ type: duck.types.SINGLE_USER_FETCHED, user });
                return user;
                
            }
            catch (e) {
                toastr.error(e);
                throw e;
            }
        },
        deleteUser: (userId) => async (dispatch, getState) => {
            try {
                await axios.delete(userEndpoints.user + `/${userId}`);              
            }
            catch (e) {
                throw e;
            }
        },
        createUser: (state) => async (dispatch, getState) => {

            if (getState().users.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                await axios.post(userEndpoints.user, state);
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        updateUser: (userId, state) => async (dispatch, getState) => {

            if (getState().users.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                await axios.put(userEndpoints.user + `/${userId}`, state);
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        getUserNotificationsSetting: () => async (dispatch, getState) => {
            if (getState().users.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                let userId = localStorage.getItem("user_id");
                const res = await axios.get(userEndpoints.user + `/${userId}/notifications/settings`);
                const smsSetting = res.data.data.smsSetting;
                const emailSetting = res.data.data.emailSetting;

                dispatch({ type: duck.types.USER_NOTIFICATIONS_SETTING, notificationsSetting: res.data.data.notifications, smsSetting, emailSetting });
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        getUserNotifications: (state, loadMore) => async (dispatch, getState) => {

            try {

                let userId = localStorage.getItem("user_id");
                if (userId) {
                    dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                    let notificationsOffset = getState().users.notificationsOffset;

                    state = JSON.stringify(state);
                    if (!loadMore) {
                        dispatch({ type: duck.types.CLEAR_NOTIFICATIONS });
                        notificationsOffset = 0;
                    }
                    const { groupId } = JSON.parse(localStorage.getItem("companyInfo"));

                    const res = await axios.get(userEndpoints.user + `/${userId}/group/${groupId}/notifications?offset=${notificationsOffset}&filter=${state}`);

                    dispatch({ type: duck.types.USER_NOTIFICATIONS_FETCHED, notifications: res.data.data });
                    dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                }
            }
            catch (e) {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        newComment: (notificationId,comment) => async (dispatch, getState) => {

            try {

                let userId = localStorage.getItem("user_id");
                if (userId) {
                    // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                    // let notificationsOffset = getState().users.notificationsOffset;

                    // state = JSON.stringify(state);
                    // if (!loadMore) {
                        // dispatch({ type: duck.types.CLEAR_NOTIFICATIONS });
                        // notificationsOffset = 0;

                    // }
                    const res = await axios.post(userEndpoints.user + `/notifications/${notificationId}/comments`,{comment});
                    return res;
                    // dispatch({ type: duck.types.USER_NOTIFICATIONS_FETCHED, notifications: res.data.data });
                    // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                }
            }
            catch (e) {
                // dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        updateNotificationsSetting: (state) => async (dispatch, getState) => {

            if (getState().users.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                let userId = localStorage.getItem("user_id");
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: true });
                await axios.put(userEndpoints.user + `/${userId}/notifications/settings`, state);
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.USER_IS_LOADING, isLoading: false });

                throw e;
            }
        },
        subscribeNotifications: () => async (dispatch, getState) => {

            try {
                const userId = localStorage.getItem("user_id");
                socket = socketIOClient(SOCKET_URL + "/mic-webhooks-notifications", {
                    path: '/socket.io',
                    transports: ['websocket'],
                    query: {
                        token: socketAuth
                    }
                });

                // const response = await axios.get(userEndpoints.user + `/${userId}`);
                // const user = response.data.data;
                // console.log("userrrr ----.", user)

                // if(user && user.userType === "Manager"){
                //   socket.on("connect", () => {
                //     socket.emit("room", { user });
                // });
                // }

                socket.on("connect", () => {
                  socket.emit("room", { userId });
              });


                // socket.on('reconnect_attempt', () => {
                //     socket.io.opts.transports = ['polling', 'websocket'];
                // });
                socket.on("notification", (data) => {

                    if (data.passengerCheck){
                        dispatch({ type: duck.types.ASK_FOR_PASSENGER_SEAT, Data : {'modal':true,'thingId':data.thingId,plateNumber:data.plateNumber,message:data.message} });
                    }
                    if (getState().users.notificationsSetting.warningPopup === true) {
                      if(  getState().users.notificationsSetting.buzzer === true){
                        const audio = new Audio(notificationSound);
                        audio.play();
                    }
                        setTimeout(()=>{
                            toastr.options.closeButton = true;
                            toastr.remove();
                            toastr.success(`
                            [${moment(data.createdAt).format('DD-MM-YYYY hh:mm a')}]
                            (${data.plateNumber})
                            ${data.message}
                            `)}
                            ,10);
                    }

                    dispatch(thingsActions.widgetsChanges(data));
                    dispatch({ type: duck.types.NEW_NOTIFICATION, data: [{ _id: "new", ...data }] });
                })
                socket.on("vehicleStatusChanged", (data) => {
                    dispatch(thingsActions.statusChanged(data));
                });
                socket.on("logout", (data) => {                    
                  const {refreshToken:token}= data;
                  const refreshToken = localStorage.getItem('refreshToken')
                  if(refreshToken===token){
                    setTimeout(()=>{
                        localStorage.clear();
                        window.location.reload('/');
                    }, 500);
                }
                });
            }
            catch (e) {
                throw e;
            }
        },
        viewNotifications: (state) => async (dispatch) => {

            try {
                const userId = localStorage.getItem("user_id");
                socket.emit("seeNotifications", { userId });
                dispatch({ type: duck.types.VIEW_NOTIFICATIONS });

            }
            catch (e) {
                throw e;
            }
        },


    })
})