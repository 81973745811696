import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as _ from 'lodash';
import moment from 'moment';
import Modal from 'react-bootstrap4-modal';
import '../index.css';
import { Spinner, WhiteSpinner } from '../../../common/Spinner/index';
import Patrolling from "../../../../ducks/Patrolling";
import editIcon from '../../../../assets/img/ic_edit.png';
import trash_bin from '../../../../assets/img/trash_bin.png';

class RosterListing extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingRecords: true,
      search: '',
      rosters: [],
      rostersToBeDeleted: {},
      deletingRoster: false,
    };
  }

  async componentDidMount() {

    const data = await this
      .props
      .actions
      .getRosterList();

    this.setState({
      ...this.state,
      rosters: data,
      isLoadingRecords: false
    })
  }


  deleteRosnavigateToCreateRoster() {
    this
      .props
      .history
      .push('/roster/create');
  }

  updateRoster(item) {
    this
      .props
      .history
      .push(`/roster/update/${item._id}`);
  }
  showdeleteRosterConfirmation(item) {
    this.setState({
      ...this.state,
      rostersToBeDeleted: item,
      showDeleteConfirmation: true
    })
  };

  async deleteRoster() {
    if (!this.state.deletingRoster) {
      await this.setState({
        deletingRoster: true,
      });
      await this
        .props
        .actions
        .deleteRoster(this.state.rostersToBeDeleted);

      toastr.success("RouteFence deleted successfully!");

      let rosters = _.filter(this.state.rosters, item => {
        return item._id !== this.state.rostersToBeDeleted;
      })
      this.setState({
        ...this.state,
        showDeleteConfirmation: false,
        rosters,
        deletingRoster: false,
      })
    }
  }

  cancelDelete() {
    this.setState({ ...this.state, showDeleteConfirmation: false })
  }


  render() {
    let notFound;
    const rosters = this.state.rosters;
    if (!this.state.isLoadingRecords && rosters.length === 0) {
      notFound = (
        <div className='no-data-found' key='1'>
          <h2>No Roster added</h2>
        </div>)
    };

    const loader = <Spinner key='1' />
    const smallLoader = <WhiteSpinner key="1" />

    return (

      <div>
        {this.state.showDeleteConfirmation ?
          <Modal
            visible={true}
            onClickBackdrop={this.modalBackdropClicked}
            dialogClassName='delete-modal-dialog'>
            <div>
              <button
                type='button'
                className='close close-x'
                aria-label='Close'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-heading'>
              <h5 className='car-and-device-assig'>Delete Roster?</h5>
            </div>
            <div className='modal-body'>
              <h6>Are you sure you want to delete Roster{this.state.rostersToBeDeleted.name}?</h6>


            </div>
            <div className='modal-footer model-btn'>
              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .deleteRoster
                  .bind(this)}>
                {this.state.deletingRoster ? [smallLoader] : 'Confirm Delete'}
              </button>

              <button
                className='btn auxo-primary-btn'
                onClick={this
                  .cancelDelete
                  .bind(this)}>
                Cancel
                    </button>
            </div>
          </Modal>
          : ''}

        <div className='row'>

          <div className='col-lg-12 col-md-12 col-sm-12'>
            
              <span>
                <button
                  className='btn auxo-primary-btn margin-bottom-20'
                  onClick={this
                    .deleteRosnavigateToCreateRoster
                    .bind(this)}>Create Roster</button>

                <span>

                </span>

              </span>
            
          </div>
        </div>
        {this.state.isLoadingRecords ?
        [loader] : ''}
        <div className='row'>

          {!this.state.isLoadingRecords && rosters.length !== 0
            ? <div className='trip-table-heading'>
              <p className='width-20'>Name</p>
              <p className='width-30'>Description</p>
              <p className='width-10'>Vehicles</p>
              <p className='width-10'>Start Date</p>
              <p className='width-10'>End Date</p>
              <p className='width-10'>Is Recursive</p>
              <p className='width-10'>ACTIONS</p>
            </div>
            : [notFound]}

          {rosters.map((item, index) => {
            index = 'abc' + index + 'z';
            item = _.clone(item);


            
            return (

              <div className='width-100' key={index}>
                <div className='trip-table-data'>

                  <p className='width-20'>{item.name}  </p>

                  <p className='width-30'>{item.description}</p>
                  <p className='width-10'>{item.vehicles.length}</p>
                  <p className='width-10'>{!item.isRecursive ? moment(item.startDate).format('DD-MM-YY'): "N/A"}</p>
                  <p className='width-10'>{!item.isRecursive ? moment(item.endDate).format('DD-MM-YY'): "N/A"}</p>
                  <p className='width-10'>{item.isRecursive ? 'Yes':'No'}</p>
                  <span>
                    
                      <a className='padding-10' onClick={this
                        .updateRoster
                        .bind(this, item)}><img src={editIcon} alt='' /></a>
                   
                      <a onClick={this
                        .showdeleteRosterConfirmation
                        .bind(this, item._id)}><img src={trash_bin} className='width16' alt='' /></a>
                    
                  </span>



                </div>
              </div>

            )
          })}

        </div>
      </div>
    );
  }
}

RosterListing.propTypes = {};
RosterListing.defaultProps = {};
const mapStateToProps = state => ({ rosters: state.routeFence.rosters, permissions: state.permissions });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Patrolling.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RosterListing);
