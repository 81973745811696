import React from 'react'
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";

const PaginatedTable = (props) => {
    return (
        <DataTable
        title={props.title}
        columns={props.columns}
        data={props.data.map((value) => {
          return value;
        })}
        pagination={true}
        subHeader={true}
        subHeaderComponent={props.data.length > 0 && <CSVLink filename={"Observations.csv"} data={props.data} headers={props.headers} filename={`${props.fileName}.csv`}><button className="btn btn-success"><i className="fa fa-cloud-download" aria-hidden="true"></i>&nbsp; Download</button></CSVLink>}
        paginationPerPage={100}
        paginationRowsPerPageOptions={[100,300,500]}
        fixedHeader={true} />
    )
}

export default PaginatedTable
