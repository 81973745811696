// ThingsDuck.js
import axios from "axios";
import {
  thingsEndPoints,
  groupEndPoints,
  userEndpoints,
  reportsEndPoints,
} from "../api/endpoints";
import Duck from "extensible-duck";
import { MqexIot } from "../utils/mqex-sdk.js";
import * as _ from "lodash";
import HashMap from "hashmap";
import _async from "async";
import toastr from "toastr";
import retryApiCall from "../utils/apiRetry";
// import mqexRetry from "../utils/mqexRetry"
// import Geocode from "react-geocode";
// Geocode.setApiKey("AIzaSyCZI1nlQrNvcDVSrAcnnSxJlou3uejXyQY");

let map = new HashMap();
let mqttCache = {};
let mqtt;
let vehicelSelected = {
  isSelected: false,
  vehicle: null,
  thingName: null,
};

let violationsCounts = {
  harshBrakes: 0,
  speedLimit: 0,
  harshTurn: 0,
  unFastenSeatBelt: 0,
  harshAcceleration: 0,
};

export default new Duck({
  namespace: "AuxoThings",
  store: "things",
  types: [
    'ENGINE_STATUS_UPDATED', 'THING_IS_LOADING', 'THINGS_FETCHED', "CLEAR_TRIPS", "TRIP_FETCHED","SHARED_FETCHED_WRONG_URL",
    'UPDATE_LOCATION_ADDRESS', 'UPDATE_BULK_LOCATION', 'PLATE_NUMBERS_FETCHED', 'GROUP_THINGS_FETCHED', 'VEHICLE_TRIPS_FETCHED',
    'UPDATE_VEHICLE_LOCATION', 'VEHICLE_DETAILS', 'WIDGETS_LOADED', 'HELMET_WIDGETS_LOADED', 'WIDGETS_SETTINGS_LOADED', 'STATUS_CHANGED',
    'WIDGETS_CHANGES', 'UPDATE_CENTER', 'SELECTED_STATUS', 'UPDATE_SELECTED_CORDS', "GET_VEHICLE_SCORE", "SET_CHART_DATA",
    "SET_ROUTER_CHART_DATA", "SET_ALL_THINGS_TEMPERATURE", "SET_SINGLE_THING_TEMPERATURE","UPDATE_SELECTED_THING", "GET_DELETED_THINGS"
  ],
  initialState: {
    selectedVehicleCords: null,
    things: [],
    deletedThings: [],
    groupVehicles: [],
    isLoading: false,
    wrongURL:false,
    markers: [],
    vehicleDetail: {},
    trip: {},
    center: {
      lat: 30.1575,
      lng: 71.5249,
    },
    vehicleTrips: [],
    hasMoreTrips: false,
    observation: { lat: 0, lng: 0 },
    vehiclesPlateNumber: [],
    widgets: {
      eventsSummary: {
        HARSH_ACCELERATION: 0,
        HARSH_BRAKES: 0,
        HARSH_TURN: 0,
        VEHICLE_ENTERING: 0,
        VEHICLE_LEAVING: 0,
        SPEED_LIMIT: 0,
        UNFASTEN_SEATBELT: 0,
      },
      vehicleStateSummary: {
        DEVICE_UNPLUG: 0,
        IGNITION_OFF: 0,
        IGNITION_ON: 0,
        IDLE: 0,
        VEHICLE_MOVING: 0,
      },
      tripSummary: {
        distance: 0,
        duration: 0,
      },
    },
    helmetWidgets: {
      vehicleStateSummary: {
        HELMET_IDLE: 0,
        HELMET_NOT_WEARING_DRIVING: 0,
        HELMET_WEARING_DRIVING: 0,
        HELMET_WEARING: 0,
        HELMET_PARKED: 0,
        totalVehicles: 0
      }
    },
    selectedStatus: null,
    scorecard: {},
    chartsData: {},
    TemperaturesData: {},
    SingleTemperatureData: {},
    RouterChartData: {}

  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_CENTER:
        return {
          ...state,
          center: { ...action.center },
        };
      case duck.types.UPDATE_SELECTED_CORDS:
        return {
          ...state,
          selectedVehicleCords: { ...action.cords },
        };
      case duck.types.SELECTED_STATUS:
        return {
          ...state,
          selectedStatus: action.selectedStatus,
        };
      case duck.types.THINGS_FETCHED:
        return {
          ...state,
          things: action.things,
          markers: action.things,
        };
      case duck.types.SHARED_FETCHED_WRONG_URL:
        return {
          ...state,
          things: [],
          markers: [],
          wrongURL:true,
        };
      case duck.types.VEHICLE_TRIPS_FETCHED:
        return {
          ...state,
          vehicleTrips: [...state.vehicleTrips, ...action.vehicleTrips],
          hasMoreTrips: action.vehicleTrips.length < 10 ? false : true,
        };
      case duck.types.CLEAR_TRIPS:
        return {
          ...state,
          vehicleTrips: [],
          hasMoreTrips: false,
        };
      case duck.types.TRIP_FETCHED:
        return {
          ...state,
          trip: action.trip,
        };

      case duck.types.PLATE_NUMBERS_FETCHED:
        return {
          ...state,
          vehiclesPlateNumber: action.vehiclesPlateNumber,
        };

      case duck.types.THING_IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading,
        };
      case duck.types.VEHICLE_DETAILS:
        return {
          ...state,
          vehicleDetail: action.vehicleDetail,
        };

      case duck.types.GROUP_THINGS_FETCHED:
        return {
          ...state,
          groupVehicles: action.groupVehicles,
        };
      case duck.types.SET_CHART_DATA:
        return {
          ...state,
          chartsData: {
            'IsDisplay': true,
            'data': action ?.response ?.data ?.data
          },
          RouterChartData: { ...state.RouterChartData, IsDisplay: false }
        };
      case duck.types.SET_ROUTER_CHART_DATA:
        return {
          ...state,
          RouterChartData: {
            'IsDisplay': true,
            'data': action ?.response ?.data ?.data
          },
          chartsData: { ...state.chartsData, IsDisplay: false }
        };
      case duck.types.SET_ALL_THINGS_TEMPERATURE:
        return {
          ...state,
          TemperaturesData: {
            'IsDisplay': true,
            'data': action ?.response ?.data ?.data
          },
          SingleTemperatureData: { ...state.SingleTemperatureData, IsDisplay: false }
        };
      case duck.types.UPDATE_SELECTED_THING:
        if (action.thing && action.thing['_id'] ) {
          let tempThings = JSON.parse(JSON.stringify(state.things));
          tempThings = tempThings.map((item) => {
            if (item && item._id == action.thing['_id'] ){
              return action.thing;
            } else {
              return item;
            }
          });
          return {
            ...state,
            things: tempThings,
            markers: tempThings,
          };
        }
      case duck.types.SET_SINGLE_THING_TEMPERATURE:
        return {
          ...state,
          SingleTemperatureData: {
            'IsDisplay': true,
            'data': action ?.response ?.data ?.data
          },
          TemperaturesData: { ...state.TemperaturesData, IsDisplay: false }
        };
      case duck.types.UPDATE_VEHICLE_LOCATION:
        if (action.thing && action.thing.clientToken) {
          try {
            
            let item = _.clone(state.vehicleDetail);
            if (action.thing.state.reported.latlng !== '0.000000,0.000000') {
              let latlng = _.split(action.thing.state.reported.latlng, ",", 2);
              item.lastLocation.lat = parseFloat(latlng[0]);
              item.lastLocation.lng = parseFloat(latlng[1]);
            }
            const speed = action.thing.state.reported.a37
              ? action.thing.state.reported.a37
              : action.thing.state.reported.a24;
            item.speed = parseFloat(speed);
            item.angle = parseFloat(action.thing.state.reported.ang);
            item.temperature = action.thing.state.reported.a72 || action.thing.state.reported.a72 === 0 ? action.thing.state.reported.a72 / 10 : 'N/A';
            item.bleTemperature = action.thing.state.reported.a25 || action.thing.state.reported.a25 === 0 ? action.thing.state.reported.a25 / 10 : 'N/A';
            item.engineTemperature = action.thing.state.reported.a115 ? action.thing.state.reported.a115 * 0.1 : null;
            // item.generator = action.thing.state.reported.a72 ? action.thing.state.reported.a72 /10 : 'N/A';
            item.lastObservationTime = new Date(action.thing.state.reported.ts);

            return {
              ...state,
              observation: { lat: item.latitude, lng: item.longitude },
              vehicleDetail: item,
            };
          } catch (err) {
            console.log(err);
          }
        }
        return state;


      case duck.types.UPDATE_LOCATION_ADDRESS: {
        const updatedMarkers = _.map(state.markers, (marker) => {
          if (marker._id === action.data.thingId) {
            return { ...marker, address: action.data.address };
          }
          return marker;
        });
        return {
          ...state,
          markers: updatedMarkers,
        };
      }

      case duck.types.UPDATE_BULK_LOCATION: {
        const { markers, center, selectedVehicleCords } = action
        if (center) {

          return {
            ...state,
            markers,
            center,
            selectedVehicleCords,
          };
        } else {
          return {
            ...state,
            markers,
          };
        }
      }
      case duck.types.STATUS_CHANGED:
        try {
          const { data } = action;
          if (data) {
            const { thname } = data;
            let thing = map.get(thname);
            if (thing) {
              let item = thing.item;
              let widgetsData = _.clone(state.widgets);

              if (
                !vehicelSelected.isSelected ||
                vehicelSelected.vehicle === item._id
              ) {
                switch (data.type) {
                  case "ignitionOn":
                    if (item.vehicleStatus === "IGNITION_OFF") {
                      --widgetsData.vehicleStateSummary.IGNITION_OFF;
                    } else if (item.vehicleStatus === "DEVICE_UNPLUG") {
                      --widgetsData.vehicleStateSummary.DEVICE_UNPLUG;
                    } else if (item.vehicleStatus === "NEVER_HEARD") {
                      --widgetsData.vehicleStateSummary.NEVER_HEARD;
                    } else if (item.vehicleStatus === "NOT_RESPONDING") {
                      --widgetsData.vehicleStateSummary.NOT_RESPONDING;
                    } else if (item.vehicleStatus === "IDLE") {
                      --widgetsData.vehicleStateSummary.IDLE;
                    } else if (item.vehicleStatus === "VEHICLE_MOVING") {
                      --widgetsData.vehicleStateSummary.VEHICLE_MOVING;
                    }
                    item.vehicleStatus = "IGNITION_ON";
                    ++widgetsData.vehicleStateSummary.IGNITION_ON;

                    break;
                  case "vehicleMoving":
                    if (item.vehicleStatus === "IGNITION_OFF") {
                      --widgetsData.vehicleStateSummary.IGNITION_OFF;
                    } else if (item.vehicleStatus === "DEVICE_UNPLUG") {
                      --widgetsData.vehicleStateSummary.DEVICE_UNPLUG;
                    } else if (item.vehicleStatus === "NEVER_HEARD") {
                      --widgetsData.vehicleStateSummary.NEVER_HEARD;
                    } else if (item.vehicleStatus === "NOT_RESPONDING") {
                      --widgetsData.vehicleStateSummary.NOT_RESPONDING;
                    } else if (item.vehicleStatus === "IDLE") {
                      --widgetsData.vehicleStateSummary.IDLE;
                    } else if (item.vehicleStatus === "IGNITION_ON") {
                      --widgetsData.vehicleStateSummary.IGNITION_ON;
                    }
                    item.vehicleStatus = "VEHICLE_MOVING";
                    ++widgetsData.vehicleStateSummary.VEHICLE_MOVING;

                    break;

                  case "vehicleParked":
                    if (item.vehicleStatus === "IGNITION_ON") {
                      --widgetsData.vehicleStateSummary.IGNITION_ON;
                    } else if (item.vehicleStatus === "DEVICE_UNPLUG") {
                      --widgetsData.vehicleStateSummary.DEVICE_UNPLUG;
                    } else if (item.vehicleStatus === "NEVER_HEARD") {
                      --widgetsData.vehicleStateSummary.NEVER_HEARD;
                    } else if (item.vehicleStatus === "NOT_RESPONDING") {
                      --widgetsData.vehicleStateSummary.NOT_RESPONDING;
                    } else if (item.vehicleStatus === "IDLE") {
                      --widgetsData.vehicleStateSummary.IDLE;
                    } else if (item.vehicleStatus === "VEHICLE_MOVING") {
                      --widgetsData.vehicleStateSummary.VEHICLE_MOVING;
                    }
                    item.vehicleStatus = "IGNITION_OFF";
                    ++widgetsData.vehicleStateSummary.IGNITION_OFF;

                    break;
                  case "vehicleOffline":
                    if (item.vehicleStatus === "IGNITION_ON") {
                      --widgetsData.vehicleStateSummary.IGNITION_ON;
                    } else if (item.vehicleStatus === "IGNITION_OFF") {
                      --widgetsData.vehicleStateSummary.IGNITION_OFF;
                    } else if (item.vehicleStatus === "NEVER_HEARD") {
                      --widgetsData.vehicleStateSummary.NEVER_HEARD;
                    } else if (item.vehicleStatus === "NOT_RESPONDING") {
                      --widgetsData.vehicleStateSummary.NOT_RESPONDING;
                    } else if (item.vehicleStatus === "IDLE") {
                      --widgetsData.vehicleStateSummary.IDLE;
                    } else if (item.vehicleStatus === "VEHICLE_MOVING") {
                      --widgetsData.vehicleStateSummary.VEHICLE_MOVING;
                    }
                    item.vehicleStatus = "DEVICE_UNPLUG";
                    ++widgetsData.vehicleStateSummary.DEVICE_UNPLUG;

                    break;
                  case "vehicleIdle":
                    if (item.vehicleStatus === "IGNITION_ON") {
                      --widgetsData.vehicleStateSummary.IGNITION_ON;
                    } else if (item.vehicleStatus === "DEVICE_UNPLUG") {
                      --widgetsData.vehicleStateSummary.DEVICE_UNPLUG;
                    } else if (item.vehicleStatus === "NEVER_HEARD") {
                      --widgetsData.vehicleStateSummary.NEVER_HEARD;
                    } else if (item.vehicleStatus === "NOT_RESPONDING") {
                      --widgetsData.vehicleStateSummary.NOT_RESPONDING;
                    } else if (item.vehicleStatus === "IGNITION_OFF") {
                      --widgetsData.vehicleStateSummary.IGNITION_OFF;
                    } else if (item.vehicleStatus === "VEHICLE_MOVING") {
                      --widgetsData.vehicleStateSummary.VEHICLE_MOVING;
                    }
                    item.vehicleStatus = "IDLE";
                    ++widgetsData.vehicleStateSummary.IDLE;

                    break;

                  default:
                    break;
                }
                return {
                  ...state,
                  widgets: widgetsData,
                  markers: Object.assign([...state.markers], {
                    [thing.index]: Object.assign(
                      {},
                      state.markers[thing.index],
                      item
                    ),
                  }),
                };
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
        return state;

      case duck.types.WIDGETS_CHANGES:
        try {
          const data = action.data;
          const { thname } = data;

          let thing = map.get(thname);
          if (thing) {
            const item = thing.item;
            let widgetsData = _.clone(state.widgets);

            if (
              !vehicelSelected.isSelected ||
              vehicelSelected.vehicle === item._id
            ) {
              switch (data.type) {
                case "geoFenceEntry":
                  ++widgetsData.eventsSummary.VEHICLE_ENTERING;
                  break;
                case "geoFenceExit":
                  ++widgetsData.eventsSummary.VEHICLE_LEAVING;
                  break;
                case "harshAcceleration":
                  ++widgetsData.eventsSummary.HARSH_ACCELERATION;
                  break;
                case "harshBrake":
                  ++widgetsData.eventsSummary.HARSH_BRAKES;
                  break;
                case "sharpTurn":
                  ++widgetsData.eventsSummary.HARSH_TURN;
                  break;
                case "overSpeeding":
                  ++widgetsData.eventsSummary.SPEED_LIMIT;
                  break;
                case "unfasten_seatbelt":
                  ++widgetsData.eventsSummary.UNFASTEN_SEATBELT;
                  break;
                default:
                  break;
              }
              return {
                ...state,
                widgets: widgetsData,
              };
            }
          }
        } catch (err) {
          console.log(err);
        }
        return state;

      case duck.types.WIDGETS_LOADED:
        const { widgets } = action;
        return { ...state, widgets };

      case duck.types.HELMET_WIDGETS_LOADED:
        const { helmetWidgets } = action;
        return { ...state, helmetWidgets: helmetWidgets };

      case duck.types.WIDGETS_SETTINGS_LOADED:
        const { widgetsConfig } = action;
        return { ...state, widgetsConfig };
      case duck.types.ENGINE_STATUS_UPDATED:
        return {
          ...state,
          things: state.things.map((thing) => {
            if (thing._id === action.thing._id) {
              return { ...thing, engineStatus: action.thing.engineStatus };
            }
            return thing;
          }),
        };
      case duck.types.GET_VEHICLE_SCORE:
        return { ...state, scorecard: action.data };

        case duck.types.GET_DELETED_THINGS:
          console.log("action.data -- ", action.data)
          return { ...state, deletedThings: action.data };
      default:
        return state;
    }
  },
  selectors: {
    root: (state) => state,
  },
  creators: (duck) => ({
    getVehicleScore: (id, startDate, endDate) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });
        let obj = { startDate, endDate };
        const response = await axios.post(thingsEndPoints.getVehicleScore(id), obj);

        const { data } = response.data;
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        dispatch({ type: duck.types.GET_VEHICLE_SCORE, data });
      } catch (e) { }
    },
    clearVehicleDetails: () => async (dispatch) => {
      try {
        const vehicleDetail = {};

        dispatch({ type: duck.types.VEHICLE_DETAILS, vehicleDetail });
      } catch (e) { }
    },
    selectedStatus: (status) => async (dispatch) => {
      dispatch({ type: duck.types.SELECTED_STATUS, selectedStatus: status });
    },
    RemoveTripDetails: () => async (dispatch) => {
      try {
        const trip = {};

        dispatch({ type: duck.types.TRIP_FETCHED, trip });
      } catch (e) {
        throw e;
      }
    },
    makeTripPrivateInDatabase: (id) => async (dispatch, getState) => {
      try {

        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });

        const response = await axios.get(thingsEndPoints.makeTripPrivate(id));
        // const response = await axios.get(`http://localhost:8080/api/v1/things/maketripsprivate/${id}`);

        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    GetTripDetails: (state) => async (dispatch, getState) => {
      try {
        if (getState().things.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested
          // data)
          return;
        }

        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });

         const response = await axios.get(thingsEndPoints.getTripDetails(state.tripId));
        //const response = await axios.get(`http://localhost:8080/api/v1/things/trips/${state.tripId}`);

        const trip = response.data.data;
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        dispatch({ type: duck.types.TRIP_FETCHED, trip });
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    GetVehicleDetails: (state) => async (dispatch, getState) => {
      try {
        const response = await axios.get(
          thingsEndPoints.getVehicleDetails(state.id)
        );

        const vehicleDetail = response.data.data;

        dispatch({ type: duck.types.VEHICLE_DETAILS, vehicleDetail });
      } catch (e) {
        // throw e;
      }
    },
    clearTrips: (state) => async (dispatch, getState) => {
      dispatch({ type: duck.types.CLEAR_TRIPS });
    },
    statusChanged: (data) => async (dispatch, getState) => {
      dispatch({ type: duck.types.STATUS_CHANGED, data });
    },
    widgetsChanges: (data) => async (dispatch, getState) => {
      dispatch({ type: duck.types.WIDGETS_CHANGES, data });
    },
    getVehicleTrips: (state) => async (dispatch, getState) => {
      try {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });

        let skip = 0;
        if (state.loadMore) {
          skip = getState().things.vehicleTrips.length;
        } else {
          dispatch({ type: duck.types.CLEAR_TRIPS });
        }
        const response = await axios.get(
          thingsEndPoints.getVehicleTrips(
            state.thingId,
            skip,
            state.interval,
            state.sort || -1
          )
        );

        const vehicleTrips = response.data.data;
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        dispatch({ type: duck.types.VEHICLE_TRIPS_FETCHED, vehicleTrips });
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        //  throw e;
      }
    },

    getTrackPoints: (data, id) => async () => {
      try {
        const response = await axios.get(thingsEndPoints.getTrackPoints(id), {
          params: { data },
        });
        return response.data.data;
      } catch (error) { }
    },

    getTrackPointsBigWay: (data, id) => async () => {
      try {
        const response = await axios.get(thingsEndPoints.getTrackPointsBigWay(id), {
          params: { data },
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache', 
            'Expires': '0',
          },
        });
        return response.data.data;
      } catch (error) { }
    },

    getTrackPointsBigWayChunk: (data, id) => async () => {
      try {
        const response = await axios.get(thingsEndPoints.getTrackPointsBigWayChunk(id), {
          params: { data },
        });
        return response.data.data;
      } catch (error) { }
    },

    // getTrackPointsBigWayChunk: (data, id) => async () => {
    //   try {
    //     const response = await axios.get(thingsEndPoints.getTrackPointsBigWayChunk(id), {
    //       params: { data },
    //       responseType: 'stream'
    //     });
    
    //     const reader = response.data.getReader();
    //     const decoder = new TextDecoder('utf-8');
        
    //     let result = '';
    //     while (true) {
    //       const { done, value } = await reader.read();
    //       if (done) break;
          
    //       result += decoder.decode(value, { stream: true });

    //       let dataChunks = result.split('\n').filter(line => line.trim() !== '');

    //       result = dataChunks.pop() || '';
    
    //       dataChunks.forEach(chunk => {
    //         try {
    //           const dataObj = JSON.parse(chunk);
    //           console.log("Received data:", dataObj);
    //         } catch (err) {
    //           console.error("Error parsing JSON:", err);
    //         }
    //       });
    //     }
    
    //     if (result.trim()) {
    //       try {
    //         const dataObj = JSON.parse(result);
    //         console.log("Final received data:", dataObj);
    //       } catch (err) {
    //         console.error("Error parsing final JSON:", err);
    //       }
    //     }
    
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // },

    getGeoFenceTrackPoints: (data, id) => async () => {
      try {
        const response = await axios.get(thingsEndPoints.getGeoFenceTrackPoints(id), {
          params: { data },
        });


        return response.data.data;
      } catch (error) { }
    },
    
    getObservations: (thingName, data) => async (dispatch, getState) => {
      if (getState().things.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
      }

      const response = await axios.get(
        thingsEndPoints.observations(thingName, data)
      );
      return response.data.data;
    },
    getGroupVehicles: (state) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });

        const response = await axios.get(
          thingsEndPoints.getGroupVehicle(state.groupId)
        );

        const groupVehicles = response.data.data;
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        dispatch({ type: duck.types.GROUP_THINGS_FETCHED, groupVehicles });
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    getUnAssignedThings: () => async (dispatch) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });

        const response = await axios.get(
          thingsEndPoints.unAssignedThings(companyInfo.groupId)
        );

        const things = response.data.data;
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        dispatch({ type: duck.types.THINGS_FETCHED, things });
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    getVehiclesPlateNumber: () => async (dispatch) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });
        const response = await axios.get(
          thingsEndPoints.groupPlateNumber(companyInfo.groupId)
        );
        // console.log('group plate number');
        // console.log(response.data.data)
        const vehiclesPlateNumber = response.data.data;

        dispatch({
          type: duck.types.PLATE_NUMBERS_FETCHED,
          vehiclesPlateNumber,
        });
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        return vehiclesPlateNumber;
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    fetchThingSingle: (state) => async (dispatch) => {
      try {
        let response = await axios.post(thingsEndPoints.singleVehicles(),state);
        dispatch({ type : duck.types.UPDATE_SELECTED_THING, thing : response.data.data});
        return response.data.data;

      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    fetchThings: (isOnMap, groupId) => async (dispatch) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        if (companyInfo) {
          let response;
          if (isOnMap) {
            response = await axios.get(
              thingsEndPoints.mapVehicles(
                groupId ? groupId : companyInfo.groupId
              )
            );
          } else {
            response = await axios.get(
              thingsEndPoints.assignedThings(companyInfo.groupId)
            );
          }
          // response.data.data= response.data.data.map(t=>({...t,vehicleStatus:'IDLE'}))
          const things = _.map(response.data.data, (item, index) => {
            if (isOnMap) {
              // item.vehicleStatus='VEHICLE_MOVING';
              map.set(item.name, { index, item });
              item.latitude = item.lastLocation.lat;
              item.longitude = item.lastLocation.lng;
            }
            return item;
          });
          dispatch({ type: duck.types.THINGS_FETCHED, things });
          return things;
        }
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    fetchShareVehicle : (sharedId) => async (dispatch) => {
      try {
          let response = await axios.get( thingsEndPoints.mapShareVehicles(sharedId));
          if (response.data.data && response.data.data['message']) {
            dispatch({ type: duck.types.SHARED_FETCHED_WRONG_URL });
          } else {
            const things = _.map(response.data.data, (item, index) => {
              map.set(item.name, { index, item });
              item.latitude = item.lastLocation.lat;
              item.longitude = item.lastLocation.lng;
            return item;
          });
            dispatch({ type: duck.types.THINGS_FETCHED, things });
            return things;
          }
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    updatePrivateMode: (thingId, mode, plateNumber) => async (dispatch) => {
      await axios.put(thingsEndPoints.updatePrivateMode(thingId), { mode })
      if (mode) {
        toastr.success(`${plateNumber} is in Private Mode`)
      } else {
        toastr.success(`${plateNumber} is off Private Mode`);
      }
    },
    getCompanyTemperatureObservations: (id) => async (dispatch) => {

      // let url = `http://localhost:8080/api/v1/things/temperature/company/${id}`;
      // const response = await axios.get(url);
      const response = await axios.get(thingsEndPoints.getCompanyTemperatureObservationsURL(id));
      // console.log("-----651-response---",response);

      try {
        dispatch({ type: duck.types.SET_ALL_THINGS_TEMPERATURE, response });
      } catch (error) {
        throw error;
      }

    },

    getDeletedThings: (id) => async (dispatch) => {
      try {
        const response = await axios.get(thingsEndPoints.getDeletedThings(id));

        const data = response.data.data.oldData;
        dispatch({ type: duck.types.GET_DELETED_THINGS, data });
    
        return data;
      } catch (error) {
        console.error('Error in getDeletedThings:', error);
        throw error;
      }
    },


    getIndividualTemepratureObservations: (id, data) => async (dispatch) => {

      // console.log("-------thingsEndPoints.getChartsData()----",thingsEndPoints.getChartsData());
      // let url = `${thingsEndPoints.getChartsData(id,data)}`
      // let url = `http://localhost:8080/api/v1/things/temperature/${id}?data=${data}`
      // const response = await axios.get(url);
      // console.log("-----713-response---",response);
      const response = await axios.get(thingsEndPoints.getIndividualTemepratureObservationsUrl(id, data));
      // console.log("-----651-data---",data);

      try {
        dispatch({ type: duck.types.SET_SINGLE_THING_TEMPERATURE, response });
      } catch (error) {
        throw error;
      }

    },
    getCompanyRouterObservations: (id) => async (dispatch) => {

      // console.log("-------thingsEndPoints.getChartsData()----",thingsEndPoints.getChartsData());
      // let url = `http://localhost:8080/api/v1/router/company/${id}`
      // const response = await axios.get(url);
      const response = await axios.get(thingsEndPoints.getCompanyRouterObservationsURL(id));
      // console.log("-----651-data---",data);

      try {
        dispatch({ type: duck.types.SET_CHART_DATA, response });
      } catch (error) {
        throw error;
      }

    },


    getIndividualRouterObservations: (id, data) => async (dispatch) => {

      // console.log("-------thingsEndPoints.getChartsData()----",thingsEndPoints.getChartsData());
      // let url = `${thingsEndPoints.getChartsData(id,data)}`
      // let url = `http://localhost:8080/api/v1/router/${id}?data=${data}`
      const response = await axios.get(thingsEndPoints.getIndividualRouterObservationsUrl(id, data));
      // console.log("-----651-data---",data);

      try {
        dispatch({ type: duck.types.SET_ROUTER_CHART_DATA, response });
      } catch (error) {
        throw error;
      }

    },
    getSummaryWidgets:
      (thingId, thingName, groupId) => async (dispatch, getState) => {
        try {
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          if (thingId) {
            vehicelSelected.isSelected = true;
            vehicelSelected.vehicle = thingId;
            vehicelSelected.thingName = thingName;
            return;
            // let selectedVehicle;
            // selectedVehicle = _.find(getState().things.markers, t => t._id === thingId);

            // const response = await Geocode.fromLatLng(selectedVehicle.latitude, selectedVehicle.longitude);
            // const address = response.results[0].formatted_address;
            // dispatch({ type: duck.types.UPDATE_LOCATION_ADDRESS, data: { thingId, address } });
          } else {
            vehicelSelected.isSelected = false;
            vehicelSelected.thingName = null;
            vehicelSelected.vehicle = null;
            const response = await axios.get(
              thingsEndPoints.getSummaryWidgets(
                groupId ? groupId : companyInfo.groupId,
                thingId
              )
            );
            const { data: widgets = {} } = response.data;
            dispatch({ type: duck.types.WIDGETS_LOADED, widgets });
            dispatch({ type: duck.types.UPDATE_SELECTED_CORDS, cords: null });
          }
          // const response = await axios.get(thingsEndPoints.getSummaryWidgets(companyInfo.groupId, thingId));
          // const { data: widgets = {} } = response.data;
          // dispatch({ type: duck.types.WIDGETS_LOADED, widgets });
        } catch (e) {
          dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
          throw e;
        }
        // const response = await axios.get(thingsEndPoints.getSummaryWidgets(companyInfo.groupId, thingId));
        // const { data: widgets = {} } = response.data;
        // dispatch({ type: duck.types.WIDGETS_LOADED, widgets });

      },
    getHelmetSummaryWidgets: (thingId, thingName, groupId) => async (dispatch, getState) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        if (thingId) {
          vehicelSelected.isSelected = true;
          vehicelSelected.vehicle = thingId;
          vehicelSelected.thingName = thingName;
        } else {
          vehicelSelected.isSelected = false;
          vehicelSelected.thingName = null;
          vehicelSelected.vehicle = null;
          const response = await axios.get(thingsEndPoints.getHelmetSummaryWidgets(groupId ? groupId : companyInfo.groupId, thingId));
          const { data: helmetWidgets = {} } = response.data;
          dispatch({ type: duck.types.HELMET_WIDGETS_LOADED, helmetWidgets });
          dispatch({ type: duck.types.UPDATE_SELECTED_CORDS, cords: null });
        }

      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    activeInactiveStats: (groupId) => async (dispatch, getState) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        const response = await axios.get(
          thingsEndPoints.activeInactiveStats(
            groupId ? groupId : companyInfo.groupId
          )
        );
        return response.data.data;
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    getSummaryWidgetsConfig: () => async (dispatch, getState) => {
      try {
        let userId = localStorage.getItem("user_id");
        const url = `${userEndpoints.user}/${userId}/widgets`;
        const response = await axios.get(url);
        const widgetsConfig = response.data.data;
        dispatch({ type: duck.types.WIDGETS_SETTINGS_LOADED, widgetsConfig });
      } catch (e) {
        throw e;
      }
    },
    setSummaryWidgets: (settings) => async (dispatch, getState) => {
      let userId = localStorage.getItem("user_id");
      const url = `${userEndpoints.user}/${userId}/widgets`;
      const response = await axios.post(url, settings);
      const widgetsConfig = response.data.data;

      dispatch({ type: duck.types.WIDGETS_SETTINGS_LOADED, widgetsConfig });
    },
    setSharedUrl: (shareData) => async (dispatch, getState) => {
      const url = thingsEndPoints.createSharedUrl();
      const response = await axios.post(url, shareData);
      return response.data.data;
    },
    exportVehicles: (status) => async (dispatch, getState) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        window.open(
          thingsEndPoints.exportGroupVehicles(companyInfo.groupId,status) +
          "?token=" +
          localStorage.refreshToken,
          "_blank" // <- This is what makes it open in a new window.
        );
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },

    saveRecords: (state) => async (dispatch, getState) => {
      try {
        if (getState().things.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested
          // data)
          return;
        }
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        await axios.put(thingsEndPoints.assignedThings(companyInfo.companyId), {
          data: state.data,
        });
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },
    engineKill: (vehicle) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.killEngine(vehicle._id);
        const response = await axios.get(endPoint);
        const { thing, expiryTime } = response.data.data;

        dispatch({ type: duck.types.ENGINE_STATUS_UPDATED, thing });
        return { thing, expiryTime };
      } catch (e) {
        // throw e;
      }
    },
    engineKillCode: (id) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.killEngineCode(id);
        const response = await axios.get(endPoint);
        const { code } = response.data.data;
        return code;
      } catch (e) {
        console.log({ e });
      }
    },
    engineKillConfirm: (vehicle, code) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.killEngine(vehicle._id);
        const response = await axios.put(endPoint, { code });
        const { thing } = response.data.data;

        dispatch({ type: duck.types.ENGINE_STATUS_UPDATED, thing });
        return thing;
      } catch (e) {
        throw e;
        // throw e;
      }
    },
    abortEngineKill: (id) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.abortEngineKill(id);
        const response = await axios.get(endPoint);
        const { thing } = response.data.data;
        dispatch({ type: duck.types.ENGINE_STATUS_UPDATED, thing });
        return thing;
      } catch (e) {
        throw e;
        // throw e;
      }
    },
    releaseEngine: (vehicle) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.releaseEngine(vehicle._id);
        const response = await axios.get(endPoint);
        const { thing, expiryTime } = response.data.data;

        dispatch({ type: duck.types.ENGINE_STATUS_UPDATED, thing });
        return { thing, expiryTime };
      } catch (e) {
        // throw e;
      }
    },
    engineReleaseConfirm: (vehicle, code) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.releaseEngine(vehicle._id);
        const response = await axios.put(endPoint, { code });
        const { thing } = response.data.data;

        dispatch({ type: duck.types.ENGINE_STATUS_UPDATED, thing });
        return thing;
      } catch (e) {
        throw e;
        // throw e;
      }
    },
    abortEngineRelease: (id) => async (dispatch, getState) => {
      try {
        const endPoint = thingsEndPoints.abortEngineRelease(id);
        const response = await axios.get(endPoint);
        const { thing } = response.data.data;
        dispatch({ type: duck.types.ENGINE_STATUS_UPDATED, thing });
        return thing;
      } catch (e) {
        throw e;
        // throw e;
      }
    },
     getMqtt: (state) => async (dispatch, getState) => {
      try {
          const isMigrated = localStorage.getItem("isMigrated");
          const refreshToken = localStorage.getItem('refreshToken') || '';
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  
          if (isMigrated === true || isMigrated === 'true') {
              let mqtt = new MqexIot(
                  'https://tnpkb2b.mqex.io/wsrt/register',
                  refreshToken
              );
              if (mqtt) {
                  mqtt.on('connect', async () => {
                      try {
                          console.log("GROUP ID: ", state.groupId);
                          const apiCall = () => axios.get(groupEndPoints.domainPath(state.groupId));
                          let response = await retryApiCall(apiCall);
                          let domainPath = response.data.data;
                          if (!domainPath.includes("user/")) {
                              domainPath = "user/" + domainPath;
                          }
                          mqtt.subscribe("thing-update/" + domainPath + "/#");
                      } catch (error) {
                          console.error("Error during MQTT connect:", error);
                          throw error; 
                      }
                  });
                  mqtt.on('error', console.log);
                  mqtt.on('close', console.log);
                  mqtt.on('message', event => {
                      const thing = JSON.parse(event.data);
                      if (thing.clientToken) {
                          mqttCache[thing.clientToken.split("-")[0]] = thing;
                      }
                  });
              }
          }

          // ⁠   mqtt.on('message', event => {
          //   const msg = JSON.parse(event.data);
          //   const { data, topic }  =  msg
          //   const dataObj = JSON.parse(data);
          //   console.log(topic);
          //   console.log(dataObj);
          // });
        
  
          setInterval(async function () {
              const clonedMqtt = _.cloneDeep(mqttCache);
              mqttCache = {};
  
              let center = null;
              let selectedVehicleCords = null;
              _async.map(getState().things.markers, async (marker, cb) => {
                  if (marker) {
                      const { name } = marker;
                      const updatedThing = clonedMqtt[name];
                      if (!updatedThing) {
                          cb(null, { ...marker });
                      } else {
                          let thing = map.get(name);
                          const stateReported = updatedThing.state.reported;
                          let latlng = _.split(stateReported.latlng, ",", 2);
                          let { item } = thing;
                          if (stateReported.latlng !== '0.000000,0.000000') {
                              item.latitude = parseFloat(latlng[0]);
                              item.longitude = parseFloat(latlng[1]);
                          }
                          const speed = stateReported.a37 ? stateReported.a37 : stateReported.a24;
                          item.speed = parseFloat(speed);
                          item.angle = parseFloat(stateReported.ang);
                          item.temperature = stateReported.a72 || stateReported.a72 === 0 ? stateReported.a72 / 10 : 'N/A';
                          item.bleTemperature = stateReported.a25 || stateReported.a25 === 0 ? stateReported.a25 / 10 : 'N/A';
                          item.engineTemperature = stateReported.a115 ? stateReported.a115 * 0.1 : null;
                          item.lastObservationTime = new Date(stateReported.ts);
                          if (vehicelSelected.isSelected && item._id === vehicelSelected.vehicle) {
                              try {
                                  if (companyInfo.companyId === '5f9fe73eb44c0b1a122a38fd' || companyInfo.companyId === '60e3fa684ebc8f1be843dd61' || companyInfo.companyId === '619f69b25e588e3466add34a') {
                                      const apiCall = () => axios.post(thingsEndPoints.fetchAddress(), { latlng: stateReported.latlng });
                                      const response = await retryApiCall(apiCall);
                                      const { data: address } = response.data;
                                      item.address = address;
                                  }
                                  center = { lat: item.latitude, lng: item.longitude, angle: item.angle, id: item._id, location: item.address };
                                  selectedVehicleCords = center;
                              } catch (error) {
                                  console.error("Error fetching address:", error);
                                  throw error;
                              }
                          }
                          cb(null, { ...marker, ...item });
                      }
                  }
              }, (err, results) => {
                  if (err) {
                      console.error("Error in setInterval map:", err);
                  } else {
                      dispatch({
                          type: duck.types.UPDATE_BULK_LOCATION,
                          markers: results,
                          center,
                          selectedVehicleCords,
                      });
                  }
              });
          }, 1000);
      } catch (error) {
          console.error("Error in MQEX:", error);
          throw error; 
      }
  },
  
    ChangeCenter: (center) => async (dispatch) => {
      dispatch({ type: duck.types.UPDATE_CENTER, center });
    },
    gensetTempReport: (tripId) => async () => {
      window.open(
        reportsEndPoints.gensetTemp(tripId),
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    
     getVehicleMqtt: (state) => async (dispatch, getState) => {
      try {
          const isMigrated = localStorage.getItem("isMigrated");
          const refreshToken = localStorage.getItem('refreshToken') || '';
  
          if (isMigrated === true || isMigrated === 'true') {
              let mqtt = new MqexIot(
                  'https://tnpkb2b.mqex.io/wsrt/register',
                  refreshToken
              );
              if (mqtt) {
                  mqtt.on('connect', async () => {
                      try {
                          const apiCall = () => axios.get(groupEndPoints.domainPath(state.groupId));
                          let response = await retryApiCall(apiCall);
                          let domainPath = response.data.data;
  
                          if (!domainPath.includes("user/")) {
                              domainPath = "user/" + domainPath;
                          }
  
                          mqtt.subscribe(
                              "thing-update/" + domainPath + "/" + state.thingId
                          );
                      } catch (error) {
                          console.error("Error during MQTT connect:", error);
                          throw error;
                      }
                  });
                  mqtt.on('error', console.log);
                  mqtt.on('close', console.log);
                  mqtt.on('message', event => {
                      setTimeout(() => {
                          dispatch({
                              type: duck.types.UPDATE_VEHICLE_LOCATION,
                              thing: JSON.parse(event.data),
                          });
                      }, 20);
                      const msg = JSON.parse(event.data);
                      console.log(msg);
                  });
              }
          }
      } catch (error) {
          console.error("Error in MQEX getting Vehicle:", error);
          throw error;
      }
  },
  
  

    disconnectMqtt: (state) => async (dispatch, getState) => {
      if (mqtt && mqtt.socket) {
        mqtt.socket.close();
      }
    },
  }),
});
