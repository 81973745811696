import React, {Component} from 'react';
import {connect} from "react-redux";
import Things from "../../../ducks/Things";
import Drivers from "../../../ducks/Drivers";
import Modal from 'react-bootstrap4-modal';
import {bindActionCreators} from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import {validateInput} from "../../../validations/createDriver";
import './index.css';
import toastr from "toastr";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select-nested-group';
// import 'react-select/dist/react-select.css';
import moment from 'moment';

class EditDriver extends Component {

    constructor() {
        super();
        let driver;
        try{
           driver = JSON.parse(localStorage.getItem("driverToUpdate"))
           this.state = {
            id: driver._id,
            name: driver.name,
            dateOfJoining: driver.dateOfJoining ? moment(driver.dateOfJoining) : "",
            vehicleNumberPlate: driver.thing ? driver.thing.plateNumber : "",
            errors: {}
        }
        } catch(err){
            console.log(err);
        }


    }
    async componentDidMount() {
        await this
            .props
            .thingsActions
            .getVehiclesPlateNumber();
    }

    handleDateChange(date) {
        this.setState({
            ...this.state,
            dateOfJoining: date
        })

    }

    navigateToList() {
        this
            .props
            .history
            .push("/drivers");
    }
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    };

    isValid = () => {

        const {errors, isValid} = validateInput(this.state);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    };

    handlePlateNumberChange = async(selectedOption) => {

        this.setState({
            ...this.state,
            vehicleNumberPlate: selectedOption
        });
    }

    async onSubmit(){

        if (this.isValid()) {
            try {
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const driverInfo = {
                    id: this.state.id,
                    name: this.state.name,
                    dateOfJoining: this.state.dateOfJoining,
                    thing: this.state.vehicleNumberPlate || null,
                    group: companyInfo.groupId
                }

                await this.props.actions.updateDriver(driverInfo);
                toastr.success("Driver updated successfully!");
                this
                    .props
                    .history
                    .push("/drivers");
            } catch (e) {
                toastr.error(e.response ? e.response.data.message : e);
            }
        }
    };

    render() {
        return (

            <Modal
                visible={true}
                onClickBackdrop={this.modalBackdropClicked}
                dialogClassName='driver-modal-dialog'>
                <div>
                    <button
                        type="button"
                        className="close close-x"
                        aria-label="Close"
                        onClick={this
                        .navigateToList
                        .bind(this)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='modal-heading'>
                    <h5 className="car-and-device-assig">Add a new Driver</h5>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-6">

                            <TextFieldGroup
                                field={"name"}
                                placeholder={"Driver Name"}
                                value={this.state.name}
                                type={"text"}
                                label={"DRIVER NAME"}
                                error={this.state.errors.name}
                                onChange={this
                                .onChange
                                .bind(this)}/>

                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label >DATE OF JOINING</label>
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.dateOfJoining}
                                    onChange={this
                                    .handleDateChange
                                    .bind(this)}/> {this.state.errors.dateOfJoining && <span className="text-danger">{this.state.errors.dateOfJoining}</span>}
                            </div>
                            <div className='form-group'>
                                <label>VEHICLE NUMBER PLATE</label>
                                <Select
                                    name="form-field-name"
                                    defaultValue={this.state.vehicleNumberPlate}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    onChange={this.handlePlateNumberChange}
                                    options={this.props.vehicles && this
                                    .props
                                    .vehicles
                                    .map(item => {
                                        return {value: item._id, label: item.plateNumber}
                                    })}/>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="modal-footer">
                    <a
                        className="footer-links"
                        onClick={this
                        .onSubmit
                        .bind(this)}>
                        Save
                    </a>
                </div>
            </Modal>

        )
    }

}

EditDriver.propTypes = {};
EditDriver.defaultProps = {};
const mapStateToProps = state => ({vehicles: state.things.vehiclesPlateNumber});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Drivers.creators, dispatch),
    thingsActions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDriver);
