import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import * as moment from "moment";
import Chart from 'react-apexcharts'
import { Card } from "reactstrap";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Reports from '../../../ducks/Reports';
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import './index.css';
import { Spinner } from '../../common/Spinner/index';
import 'react-datepicker/dist/react-datepicker.css';

class NewFuelSummaryReport extends Component {

  constructor() {
    super();

    let selectedGeofences = [];
    this.state = {
      selectedVehicles: [],
      selectedGeofences,
      selectdRow:{},
      selectedGroup: [],
      options: {
        chart: {
          width: 240,
          type: 'pie',
        },
        labels: ['Fuel Filled','Fuel Consumed'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      fuelOptions : {
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px',
                  formatter: function (val) {
                    return val + ' Ltrs'
                  }
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91]
            },
          },
          labels: ['Current Fuel']
      },
      selectedStartDate:moment(),
      selectedEndDate:moment(),
      geoFences : [],
      drivers: [],
      search: "",
      isLoadingRecords: false,
      commentsModal: false,
      showDeleteConfirmation: false,
      driverToBeDeleted: true,
      orderBy: "asc",
      scheduleVisible: false,
      savingSingleRecord: [],
      driverName: "",
      driverId: "",
      sortType: "",
      driverVehicles: [],
      page: 1,
      data: [],
      child: {},
      sizePerPage: 10,
      columns : [
        {
          dataField: 'plateNumber',
          text: 'Plate Number'
        },
        {
          dataField: 'date_time',
          text: 'Day',
          formatter: this.mapView,
        },
        {
          dataField: 'fuelConsumed',
          text: 'Fuel Consumed'
        },
        {
          dataField: 'fuelFilled',
          text: 'Fuel Filled'
        },
        {
          dataField : 'initialFuel',
          text : 'Day Start Fuel Level'
        },
        {
          dataField: 'currentFuelLevel',
          text: 'Day End Fuel Level'
        }
      ]
    };
  };

  selectGroup = (selectedGroup) => this.setState({ selectedGroup });
  selectGeofences = (selectedOption) =>this.setState({ selectedGeofences: selectedOption });
  selectVehicles = (selectedOption) =>this.setState({ selectedVehicles: selectedOption });

  mapView = (cell, row, rowIndex, formatExtraData) => {

      return moment(row.date_time).format('MMM DD, YYYY')
  };

  async exportFuelSummaryReport() {
    this.setState({ ...this.state,isLoadingRecords: true});
    await this.props.reportsActions.exportReport(this.props.fuelState);
    this.setState({...this.state, isLoadingRecords: false});
  }

  handleTableChange = async (type, { page, sizePerPage }) => {
    let data = this.props.fuelState;
    data['sizePerPage'] = sizePerPage;
    data['page'] = page -1;
    this.setState({ ...this.state,isLoadingRecords: true});
    await this.props.reportsActions.fuelReportView(data);
    this.setState({...this.state, page , isLoadingRecords: false});
  }

  render() {
    const isLoadingRecords = this.state.isLoadingRecords;
    const loader = <Spinner key="1" />;

    return (
      <>
        <div className='my-complaintscontainer'>
          <div className='filterContainer'>
            <div className='tableRow'>
              <div className="col-lg-3 col-md-3 col-sm-3 margin-top-20">
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 margin-top-20">
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 margin-top-20">
              </div>
              <div className=" col-md-3 col-sm-3">
                <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.exportFuelSummaryReport.bind(this)}>Export</button>
              </div>
            </div>
          </div>
          <div style={{ width : "98%"}}>
            {
              isLoadingRecords ? loader : this.props.fuelRows && this.props.fuelRows.length ?
              <>
              <div className="row">
                <div className="col-md-6">
                  <Card style={{height:"270px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                    <div className='row' flexDirection="row">
                      <div  style={{display: "flex",width: "100%",justifyContent: 'center', maxHeight:"270px", paddingTop:"50px"}}>
                        <label style={{height:"40px;"}}>Current Fuel</label>
                      </div>
                      <div style={{display: "flex",width: "100%",justifyContent: 'center', maxHeight:"270px", paddingTop:"50px"}}>
                        <Chart options={this.state.fuelOptions} series={[this.props.fuelRowsGraph.currentFuel]} type="radialBar" width={500} height={240} />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card style={{height:"270px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                    <div style={{display: "flex",width: "100%",justifyContent: 'center', maxHeight:"270px"}}>
                        <Chart options={this.state.options} series={this.props.fuelRowsGraph.data} type="pie" width={500} height={240} />
                    </div>
                  </Card>
                </div>
              </div>
              <div style={{ marginTop : "20px"}}>
                <PaginationProvider
                  pagination={
                    paginationFactory({
                      custom: true,
                      page : this.state.page,
                      sizePerPage : this.props.sizePerPage,
                      totalSize : this.props.rowsCount
                    })
                  }
                >
                {
                  ({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <div>
                      <BootstrapTable
                        remote
                        keyField="id"
                        data={ this.props.fuelRows }
                        columns={ this.state.columns }
                        onTableChange={ this.handleTableChange }
                        { ...paginationTableProps }
                      />
                                    <div>
                        <PaginationListStandalone
                          { ...paginationProps }
                        />
                      </div>
                    </div>
                  )
                }
                </PaginationProvider>
              </div>
              </> : (<div>No data request</div>)
            }
            </div>
        </div>
      </>
    );
  }
}

NewFuelSummaryReport.propTypes = {};
NewFuelSummaryReport.defaultProps = {};
const mapStateToProps = state => (
  { 
    rowsCount : state.Reports.rowsCount,
    sizePerPage : state.Reports.sizePerPageFuel,
    page : state.Reports.pageFuel,
    fuelState : state.Reports.fuelState,
    fuelRows : state.Reports.fuelRows,
    fuelRowsGraph : state.Reports.fuelRowsGraph
  }
);

const mapDispatchToProps = dispatch => ({
  reportsActions: bindActionCreators(Reports.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewFuelSummaryReport);
