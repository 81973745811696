import React, { Component } from "react";
import { connect } from "react-redux";
import Groups from "../../../ducks/Groups";
import GeoFence from "../../../ducks/GeoFence";
import TelcoSite from "../../../ducks/TelcoSite";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import CheckboxListComponent from "../../common/CheckboxListComponent";
import { CheckboxInlineComponent } from "../../common/CheckboxListComponent";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import GeoFenceMap from "../../common/maps/geofence";
import "../index.css";
import { validateSiteInput } from "../../../validations/geofence";
import { WhiteSpinner } from "../../common/Spinner/index";
import Slider from "rc-slider";
import { HandleSlider } from "../../common/HandleSlider";
import * as _ from "lodash";
import * as conf from "../../../config/config.json"

import toastr from "toastr";
import { isValidLatitude } from "geolib";
import CreateMap from "./createMap";

class CreateTelcoSite extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            description: "", 
            center: [73.13915736973286, 33.59492064226822],
            radius: 300,
            errors: {},
            loader: false,
        };
    }

    

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            errors: { ...this.state.errors, [e.target.name]: "" },
        });
    }
    
   
   
   
   

    updateDrawState = (idx) => {
        let newDrawState = [];
        this.state.mapDrawTypes.map((item, index) => {
            if (index === idx) {
                item.checked = true;
            } else {
                item.checked = false;
            }
            newDrawState.push(item);
        });
        return newDrawState;
    };

    clearPathError = () => {
        return this.state.errors.map((error, index) => {
            error.path = null;
        });
    };

   

   
    onMapChange = (circle) => {
        
        let radius = circle.getRadius();

        radius = Math.round(radius);

        this.setState({
            ...this.state,
            center: [circle.getCenter().lng(), circle.getCenter().lat()],
            radius,
        });

        return {
            center: [circle.getCenter().lng(), circle.getCenter().lat()],
            radius: radius,
        };
    };
    changeRadius = (event) => {
        let value = "";
        if (event.target.value) {
            value = parseInt(event.target.value, 10);
        } else {
        }
        this.setState({ radius: value });
    };
    isValid = () => {
        const { errors, isValid } = validateSiteInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    };

    setChecked = () => {
        return this.state.mapDrawTypes.filter((item) => item?.checked === true);
    };

    onSubmit = async (e) => {
        e.preventDefault();
        const { loader } = this.state;

        if (loader) {
            return;
        }
        if (this.isValid()) {
            try {
                const state = this.state;
                //
                this.setState({
                    ...this.state,
                    loader: true,
                });
                let siteObj = {
                    name: _.trim(state.name),
                    description: _.trim(state.description),
                    alerts: {
                        ENTRY: true,
                        EXIT: true,
                    },
                    center: state.center,
                    radius:state.radius
                };


                await this.props.TelcoActions.CreateSite(siteObj);
                toastr.success("Site Created Successfully!");
                this.setState({
                    ...this.state,
                    loader: false,
                });
                // this.props.history.push(`/geofence/list`);
            } catch (e) {
                toastr.error(e.response ? e.response.data.message : e);
                this.setState({
                    ...this.state,
                    loader: false,
                });
            }
        } else {
        }
    };
   
    render() {
        const { name, description,} = this.state;
    

        const loader = <WhiteSpinner key="1" />;

        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <TextFieldGroup
                            onChange={this.onChange.bind(this)}
                            value={name}
                            label={"Site Name*"}
                            type={"text"}
                            field={"name"}
                            error={this.state.errors.name}
                            placeholder={"E.g Warehouse, school"}
                        />
                        <TextAreaGroup
                            onChange={this.onChange.bind(this)}
                            value={description}
                            label={"Description"}
                            field={"description"}
                            error={this.state.errors.description}
                            placeholder={"Extra details about site"}
                        />
                        <div className="col padding0">
                           

                            {this.state.errors && (
                                <span className="text-danger">{this.state.errors.path}</span>
                            )}
                           
                            
                            <div className="margin-btm-50">
                                <button
                                    className="btn auxo-primary-btn create-geofence-btn"
                                    type="submit"
                                >
                                    {this.state.loader ? [loader] : "Create"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <CreateMap
                            center={{
                                lat: this.state.center[1],
                                lng: this.state.center[0],
                            }}
                            Center={this.state.center}
                            geoFences={this.state.geoFences}
                            radius={200}
                            onMapChange={this.onMapChange}
                        />

                    </div>
                </div>
            </form>
        );
    }
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    TelcoActions: bindActionCreators(TelcoSite.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateTelcoSite);
