import React from 'react';
import { connect } from "react-redux";
import Modal from 'react-bootstrap4-modal';
import { withStateHandlers, compose } from 'recompose';

const queryString = require('query-string');

const ShareErrorHandler = compose(withStateHandlers(
    ({ isOpen = false }) => ({ isOpen }),
    {
        onClick: (state) => () => {
            return ({ isOpen: !state.isOpen });
        }
    }
)

)(props => {

    return (

        <Modal
            visible={true}
            dialogClassName='error-modal '>

            <div className='modal-heading row'>

            </div>
            <div className="modal-body">
                <h4 className="text-center">
                    Expired or Wrong Url , Please Contact Service Provider
                </h4>
            </div>
        </Modal>


    )
});
ShareErrorHandler.propTypes = {};
ShareErrorHandler.defaultProps = {};
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ShareErrorHandler);
