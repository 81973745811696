import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import toastr from 'toastr';

import Groups from '../../../ducks/Groups';

import * as localStorageManager from '../../../utils/localstorage-manager.util'
import { Spinner } from '../../common/Spinner/index';
import TableComponent from '../../table';
import { DeletePopUp } from '../../common/DeletePopUp';

import groupThList from '../../../static/group-th-list.static';

import editIcon from '../../../assets/img/ic_edit.png';
import trash_bin from '../../../assets/img/trash_bin.png';
// import searchIcon from '../../../assets/img/ic_search.png';

const GroupsListView = props => {

  const loader = <Spinner key='1'/>,
    groupId = JSON.parse(localStorageManager.getKey('companyInfo')).groupId;


  const initialState = {
      isLoading: true,
      search: '',
      groupList: [],
      groupToDelete: null,
      nonExpandable: [],
      showDeleteConfirmation: false
    },
    [state, setState] = useState(initialState),

    data = useSelector(store => ({ groupList: store.groups.subGroups, permissions: store.permissions }), shallowEqual),
    dispatch = useDispatch(),
    bindedActionCreators = useMemo(() => bindActionCreators(Groups.creators, dispatch), [dispatch]);

  useEffect(() => {
    (async () => {
      await bindedActionCreators.getSubGroups(groupId);
      setState(prevState => ({ ...prevState, groupList: data.groupList, isLoading: false }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDelete = async () => {
    try {
      await bindedActionCreators.deleteGroup(state.groupToDelete);
      (async () => {
        await bindedActionCreators.getSubGroups(groupId);
        setState(prevState => ({ ...prevState, groupList: data.groupList, isLoading: false }));
        cancelDelete();
        toastr.success('Group deleted Successfully');
      })();

    } catch (e) {
      toastr.error(e.response ? e.response.data.message : e);
    }

  };
  const cancelDelete = () => {
    setState(prevState => ({ ...prevState, showDeleteConfirmation: false }));
  };

  const formatTableData = (tableData) => {

    const nonExpandable = [],

      groupList = map(tableData, (child, index) => {

        const tr = {
          keyField: child._id || child.id || index,
          name: child.name,
          totalVehicles: child.totalVehicles || '0',
          assignedvehicles: child.assignedvehicles || '0',
          unAssignedvehicles: child.unAssignedvehicles || '0',
          users: child.users || '0',
          children: []
        };

        if (child.child && child.child.length > 0) tr.children = child.child;
        else nonExpandable.push(child._id || child.id || index);

        if (data.permissions.updateGroup)
          tr.action = child._id !== groupId
            ? (
              <div>
                <a onClick={() => props.history.push(`/groups/${child._id}/update`)}><img src={editIcon} alt=''/></a>
                <a onClick={() => setState(prevState => ({ ...prevState, showDeleteConfirmation: true, groupToDelete: child._id }))}>
                  <img src={trash_bin} alt=''/>
                </a>
              </div>
            )
            : '';

        return tr;
      });

    return { groupList, nonExpandable }
  };


  useEffect(() => {
    setState(prevState => ({ ...prevState, ...formatTableData(data.groupList) }));
  }, [data.groupList]);


  // const onChange = e => {
  //   const value = e.target.value;
  //   if (value) setState(prevState => ({ ...prevState, search: value || '' }));
  //   else setState(prevState => ({ ...prevState, search: value || '' }));
  // };

  // const searchGroup = e => {
  //   e.preventDefault();
  //   let groupList = data.groupList;
  //   if (state.search) groupList = filter(data.groupList, item => item.name.search(new RegExp(state.search, 'i')) >= 0);
  //   setState(prevState => ({ ...prevState, ...formatTableData(groupList), search: state.search || '' }));
  // };

  const onRowExpand = row => {
    // Exception for children empty
    if (row.children.length === 0) return (<></>);

    const { groupList, nonExpandable } = formatTableData(row.children);
    return (
      <TableComponent
        bordered={true}
        hover={false}
        thList={groupThList(data.permissions.updateGroup)}
        data={groupList}
        nonExpandable={nonExpandable}
        onRowExpand={onRowExpand}
      />
    )
  };

  return (
    state.isLoading
      ? loader
      : (
        state.showDeleteConfirmation ? <DeletePopUp confirmDelete={confirmDelete} cancelDelete={cancelDelete}/> :
          <div className='container-fluid m-t-20-px'>
            <div className='row'>
              <div className='col-lg-3 col-md-3 col-sm-4'>
                {/*<form onSubmit={searchGroup}>*/}
                {/*  <TextFieldGroup*/}
                {/*    className='m-b-0'*/}
                {/*    field={'search'}*/}
                {/*    placeholder={'Search Groups'}*/}
                {/*    value={state.search}*/}
                {/*    type={'text'}*/}
                {/*    onChange={onChange}/>*/}
                {/*  <a className='search-icon' onClick={searchGroup}><img src={searchIcon} alt=''/></a>*/}
                {/*</form>*/}
              </div>

              <div className='col-lg-9 col-md-9 col-sm-8'>
                {
                  data.permissions.createGroup
                    ? (
                      <button
                        className='btn auxo-primary-btn m-0'
                        onClick={() => props.history.push('/groups/add')}
                      >
                        Add Group
                      </button>
                    )
                    : ''
                }

              </div>
            </div>
            <div className='row m-t-20-px'>
              <div className='col-md-12'>

                <TableComponent
                  thList={groupThList(data.permissions.updateGroup)}
                  bordered={true}
                  hover={false}
                  showPagination={true}
                  data={state.groupList}
                  nonExpandable={state.nonExpandable}
                  onRowExpand={onRowExpand}
                />
              </div>
            </div>
          </div>
      )
  );
};

export default GroupsListView;
