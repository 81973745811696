import React, { Component } from 'react';
import Group from "../../ducks/Groups";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './index.css'

class HelpComponent extends Component {
  

  async componentDidMount() {
    const { groupId } = JSON.parse(localStorage.getItem("companyInfo"));
    this
      .props
      .actions
      .getSingle(groupId);
  }


  render() {

    const bam = this.props.group.bam;
    const partner = this.props.group.partner;
    const packageInfo = this.props.group.packageInfo;
    const emergencyHelpLineNumber = this.props.group.emergencyHelpLineNumber;

    return (
      <div>
        <div className="account-center">
          <section className="detail-section1">
            <h3>Package </h3>
            <p>
              <strong>{packageInfo ? packageInfo.name : 'N/A'}
                        </strong>
              </p>
            

          </section>
          {partner ? 
          <section className="detail-section2">
            <h3>Emergency Help Line </h3>
            <p>
              <strong>Number:
                        </strong>
              {partner ? partner.emergencyHelpLineNumber : 'N/A'}</p>
            <p>
              <strong>Email:
                        </strong>
              {partner ? partner.emergencyEmail : 'N/A'}</p>


          </section> : ''}
          {bam ?
            <section className="detail-section2">
              <h3>Business Acount Manager</h3>
              <p>
                <strong>Name:
                        </strong>
                {bam ? bam.firstName + ' ' + bam.lastName : "N/A"}</p>

              <p>
                <strong>Phone:
                        </strong>
                {bam ? bam.phone : "N/A"}</p>

              <p>
                <strong>Email:
                        </strong>
                {bam ? bam.email : "N/A"}</p>

            </section> : ''}
        </div>
      </div>
    );
  }
}

  HelpComponent.propTypes = {};
  HelpComponent.defaultProps = {};

  const mapStateToProps = state => ({
    group: state.groups.group
  });
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Group.creators, dispatch)
  });
  export default connect(mapStateToProps, mapDispatchToProps)(HelpComponent);
