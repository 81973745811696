import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toastr from 'toastr';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import map from 'lodash/map';
import trim from 'lodash/trim';
import forEach from 'lodash/forEach';

import Groups from '../../../ducks/Groups';
import Things from '../../../ducks/Things';

import { WhiteSpinner } from '../../common/Spinner/index';
import { validateInput } from '../../../validations/domainCreate';
import TextFieldGroup from '../../common/TextFieldGroup';
import SelectComponent from '../../select';
import CheckboxListComponent from '../../common/CheckboxListComponent';

const Handle = Slider.Handle;

const handle = props => {

  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls='rc-slider-tooltip'
      overlay={value}
      visible={dragging}
      placement='top'
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class GroupCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pointOfContactDetails: '',
      errors: {},
      overSpeedingThreshold: 120,
      roboCallPocPhoneNumber1:"",
      roboCallPocPhoneNumber2:"",
      vehicles: [],
      haveVehicles: false,
      selectAll: false,
      selectedVehicles: [],
      loader: false,
      selectedGroup: [],
      companyInfo: JSON.parse(localStorage.getItem('companyInfo') || '') || {}
    }
  }

  componentDidMount() {

    (async () => {
      if (!(this.props.groupList && this.props.groupList.length > 0))
        await this.props.dispatch(Groups.creators.getSubGroups(this.state.companyInfo.groupId));
    })();

  }

  isValid = () => {
    let { errors, isValid } = validateInput(this.state);
    if (!isValid) this.setState({ errors });
    if (!this.state.selectedGroup || this.state.selectedGroup.length === 0) {
      errors.parentGroup = 'Please select a parent group';
      isValid = false;
    }
    return isValid;
  };

  checkBoxListSelectAll = () => {

    let vehicles = this.state.vehicles,
      selectedVehicles = this.state.selectedVehicles,
      isSelectAll = this.state.selectAll;

    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (!isSelectAll) {
          item.checked = true;
          selectedVehicles.push(item._id);
        }
        else {
          item.checked = false;
          let index = selectedVehicles.findIndex(obj => obj === item._id);
          if (index > -1) selectedVehicles.splice(index, 1);
        }
        vehicles[i] = item;
      }
    }
    this.setState({ ...this.state, vehicles, selectedVehicles, selectAll: !isSelectAll });
  };

  checkBoxListChanged = value => {
    let vehicles = this.state.vehicles,
      selectedVehicles = this.state.selectedVehicles,
      isSelectAll = this.state.selectAll;

    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) selectedVehicles.push(item._id);
          else {
            let index = selectedVehicles.findIndex(obj => obj === item._id);
            if (index > -1) {
              if (isSelectAll) isSelectAll = false;
              selectedVehicles.splice(index, 1);
            }
          }
        }
        vehicles[i] = item;
      }
    }
    this.setState({ ...this.state, vehicles, selectedVehicles, selectAll: isSelectAll });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: ''
      }
    });
  };

  selectGroup = async selectedGroup => {

    if(selectedGroup && selectedGroup[0].value){
      await this.props.thingsActions.getGroupVehicles({ groupId: selectedGroup[0].value });
      const vehicles = map(this.props.vehicles, item => (
        {
          _id: item._id,
          plateNumber: item.plateNumber || 'N/A',
          imei: item.imei || 'N/A',
          checked: false
        }
      ));

      this.setState({ ...this.state, vehicles, haveVehicles: !!vehicles.length, selectedGroup, errors: {...this.state.errors, parentGroup: ''} });
    } else{
      this.setState({ ...this.state, vehicles: [], haveVehicles: false, selectedGroup, errors: {...this.state.errors, parentGroup: ''} });
    }

  };

  onSubmit = async e => {

    e.preventDefault();
    if (this.isValid()) {
      try {
        let body = {
          parentId: this.state.selectedGroup[0].value,
          name: trim(this.state.name),
          roboCallPocPhoneNumber1: this.state.roboCallPocPhoneNumber1,
          roboCallPocPhoneNumber2: this.state.roboCallPocPhoneNumber2,
          pointOfContact: trim(this.state.pointOfContactDetails),
          companyId: this.state.companyInfo.companyId,
          vehicles: this.state.selectedVehicles,
          overSpeedingThreshold: this.state.overSpeedingThreshold
        };

        this.setState({ ...this.state, loader: true });
        await this.props.actions.createGroup(body);
        toastr.success('Group Created Successfully!');
        this.setState({ ...this.state, loader: false });
        this.props.history.push('/groups');
      }
      catch (e) {
        this.setState({ ...this.state, loader: false });
        toastr.error(e.response ? e.response.data.message : e);
      }

    }
  };

  searchVehicles = e => {

    const value = e.target.value;

    let vehicles = [],
      selectedVehicles = this.state.selectedVehicles;

    if (value) {
      forEach(this.props.vehicles, item => {
        if (item.plateNumber.search(new RegExp(value, 'i')) >= 0) {
          let index = selectedVehicles.findIndex(obj => obj === item._id);
          vehicles.push({
            _id: item._id,
            plateNumber: item.plateNumber || 'N/A',
            checked: index > -1,
            imei: item.imei || 'N/A'
          });
        }
      });

    }
    else {
      vehicles = map(this.props.vehicles, item => {
        let index = selectedVehicles.findIndex(obj => obj === item._id);
        return { _id: item._id, plateNumber: item.plateNumber || 'N/A', checked: index > -1, imei: item.imei || 'N/A' };
      });
    }

    this.setState({ ...this.state, vehicles });
  };

  changeOverspeedingThreshold = value => {
    this.setState({ ...this.state, overSpeedingThreshold: value })
  };

  render() {

    const loader = <WhiteSpinner key='1'/>,
      { name, pointOfContactDetails,roboCallPocPhoneNumber1,roboCallPocPhoneNumber2 } = this.state,
      createBtnStyle = { 'marginTop': '30px' };

    return (
      <div>
        <div className='heading-container'><h3 className='font-weight-bold'>New Group</h3></div>
        <form onSubmit={this.onSubmit}>

          <div className='row'>
            <div className='col-md-6'>
            <SelectComponent
                label='Parent Group'
                field='form-field-name'
                placeholder='Select Parent Group'
                onChange={this.selectGroup}
                preSelected={this.state.selectedGroup}
                optionKeys={{label: 'name', value: '_id'}}
                options={this.props.groupList}
                error={this.state.errors.parentGroup}
              />

              <TextFieldGroup
                onChange={this.onChange}
                value={name}
                type={'text'}
                field={'name'}
                label={'Group Name'}
                placeholder={'Group name'}
                error={this.state.errors.name}
              />

              <TextFieldGroup
                onChange={this.onChange}
                value={pointOfContactDetails}
                type={'text'}
                field={'pointOfContactDetails'}
                label={'Description'}
                placeholder={'Description'}
              />

                {/* <TextFieldGroup
                  onChange={this.onChange}
                  value={roboCallPocPhoneNumber1}
                  type={"number"}
                  field={"roboCallPocPhoneNumber1"}
                  label={"Robo Call Poc Phone Number 1"}
                  placeholder={"Phone Number of the Robo Call POC 1"}
                /> */}
                {/* {this.state.errors && (
                  <span className="text-danger">
                    {this.state.errors.roboCallPocPhoneNumber1}
                  </span>
                )} */}

                {/* <TextFieldGroup
                  onChange={this.onChange}
                  value={roboCallPocPhoneNumber2}
                  type={"number"}
                  field={"roboCallPocPhoneNumber2"}
                  label={"Robo Call Poc Phone Number 2"}
                  placeholder={"Phone Number of the Robo Call POC 2"}
                /> */}
                {/* {this.state.errors && (
                  <span className="text-danger">
                    {this.state.errors.roboCallPocPhoneNumber2}
                  </span>
                )} */}

              <div>
                <label>Select Over Speeding Threshold Value {this.state.overSpeedingThreshold}</label>
                <Slider
                  min={50}
                  max={200}
                  defaultValue={120}
                  handle={handle}
                  onChange={this.changeOverspeedingThreshold}
                />
              </div>
              <button
                className='btn auxo-primary-btn'
                style={createBtnStyle}
                type='submit'
              >
                {this.state.loader ? [loader] : 'Create'}
              </button>
            </div>

            <div className='col-md-6'>
              {
                this.state.haveVehicles
                  ? <div>
                    <ul className='nav nav-tabs' role='tablist'>
                      <li className='nav-item'>
                        <a className={'nav-link active'}>Vehicles</a>
                      </li>
                    </ul>
                    <div className='tab-content access-tab mb-5'>
                      <div className={'tab-pane fade show active'}>
                        <CheckboxListComponent
                          enableSearch='true'
                          selectAllCheck={this.state.selectAll}
                          selectAllEnabled={!!this.state.vehicles.length}
                          search={this.searchVehicles}
                          selectAll={this.checkBoxListSelectAll}
                          onChange={this.checkBoxListChanged}
                          data={
                            this.state.vehicles && this.state.vehicles.map(item => ({
                              mainTitle: item.plateNumber,
                              subTitle: item.imei,
                              checked: item.checked
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  : ''
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

GroupCreate.propTypes = {};
GroupCreate.defaultProps = {};

const mapStateToProps = state => (
  {
    vehicles: state.things.groupVehicles,
    groupList: state.groups.subGroups
  }
);

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(Groups.creators, dispatch),
    thingsActions: bindActionCreators(Things.creators, dispatch),
    // permissionActions: bindActionCreators(permissionActions, dispatch)
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreate);
