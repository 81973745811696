import React, { Component } from 'react';
import { connect } from "react-redux";
import Groups from "../../../ducks/Groups";
import Hotspot from "../../../ducks/Hotspot";
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import GeoFenceMap from "../../common/maps/geofence";
import { validateInput } from "../../../validations/hotspot";
import { WhiteSpinner, Spinner } from '../../common/Spinner/index';

import * as _ from "lodash";

import toastr from "toastr";

class UpdateHotspot extends Component {

  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      center: 0,
      radius: 0,
      errors: {},
      loader: false,
      loadingData: true
    }
  }

  async componentDidMount() {

    await this
      .props
      .hotspotActions
      .getSingle({ id: this.props.match.params.id });

    this.setState({
      ...this.state,
      name: this.props.hotspot.name,
      description: this.props.hotspot.description,
      center: this.props.hotspot.center,
      radius: this.props.hotspot.radius,
      loadingData: false
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  onMapChange = (circle) => {

    let radius = circle.getRadius();
    radius = Math.round(radius);

    this.setState({
      ...this.state,
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius
    });
    if (radius < 300) {
      this.setState({
        radius: 300
      });
    }
    return ({
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius
    })
  }
  changeRadius = (event) => {
    let value = '';
    if (event.target.value) {
      value = parseInt(event.target.value, 10);
    } else {

    }
    this.setState({ radius: value });
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };
  onSubmit = async (e) => {
    e.preventDefault();
    if (this.isValid()) {
      try {
        const state = this.state;
        this.setState({
          ...this.state,
          loader: true
        })
        let hotspotObj = {
          name: _.trim(state.name),
          description: _.trim(state.description),
          center: state.center,
          radius: state.radius
        }
        await this
          .props
          .hotspotActions
          .Hotspot({ hotspotObj, id: this.props.match.params.id });
        toastr.success("Hotspot Updated Successfully!");
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push(`/hotspot/list`);

      } catch (err) {
        this.setState({
          ...this.state,
          loader: false
        })
        toastr.error(err.response.data.message);
      }
    }

  };

  render() {
    const { name, description, loadingData } = this.state;

    const loader = <WhiteSpinner key="1" />;
    const loader1 = <Spinner key="2" />

    return (
      <div>{loadingData ? [loader1] :
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <TextFieldGroup
                onChange={this.onChange.bind(this)}
                value={name}
                label={"Hotspot Name*"}
                type={"text"}
                field={"name"}
                error={this.state.errors.name}
                placeholder={"E.g Warehouse, school"} />
              <TextAreaGroup
                onChange={this.onChange.bind(this)}
                value={description}
                label={"Description"}
                field={"description"}
                error={this.state.errors.description}
                placeholder={"Extra details about hotspot"} />

                  <TextFieldGroup
                    onChange={this.changeRadius.bind(this)}
                    value={this.state.radius}
                    label={"Hotspot Radius"}
                    type={"text"}
                    field={"name"}
                    error={this.state.errors.radius} />


              <div className="col padding0">
              <label>The Hotspot radius is used to determine the location entries that are
                              associated to this place. You can use this hotspot to send an alert when a
                              location entry is here.
                            </label>
                <div className="margin-btm-50">
                  <button className="btn auxo-primary-btn create-hotspot-btn" type="submit">
                    {this.state.loader ? [loader] : 'Update'}</button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
              <GeoFenceMap
                center={{
                  lat: this.state.center[1],
                  lng: this.state.center[0]
                }}
                radius={this.state.radius || 300}
                onMapChange={this
                  .onMapChange} />
            </div>

          </div>
        </form>
      }</div>
    );
  }
}

UpdateHotspot.propTypes = {};
UpdateHotspot.defaultProps = {};
const mapStateToProps = state => ({ groupsAndVehicles: state.groups.groupsAndVehicles, hotspot: state.hotspot.hotspot });

const mapDispatchToProps = dispatch => ({
  hotspotActions: bindActionCreators(Hotspot.creators, dispatch),
  actions: bindActionCreators(Groups.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateHotspot);
