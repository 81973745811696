import React, { Component } from 'react';
import TextFieldGroup from '../common/TextFieldGroup';
import background from "../../assets/img/login-background.jpg";
import "./Login.css";
import auxo from "../../assets/img/AUXO-Horiz-fleet.png";
import toastr from "toastr";
import { connect } from "react-redux";
import UserAuthentication from "../../ducks/UserAuthentication";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { validateInput } from "../../validations/auth";
import { WhiteSpinner } from '../common/Spinner/index';
import ReCAPTCHA from "react-google-recaptcha";

class APPLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      captchaValue: "",
      isCaptchaVerified: false,
      data: '',
      isSuspended: false,
      errors: {},
      loading: false
    }
    
  }
  recaptchaRef = React.createRef()
  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  };
  handleCaptchaChange = (value) => {
    console.log("VALUE: ", value);
    this.setState({ isCaptchaVerified: !!value });
    this.setState({ captchaValue: value })
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  };
  
  onSubmit = async e => {
    e.preventDefault();
    if (this.isValid() && !this.state.loading) {
      this.setState({ ...this.state, loading: true });
      const userInfo = {
        username: this.state.username,
        password: this.state.password,
        captchaValue:this.state.captchaValue
      };
      try {
        await this.props.actions.loginUser(userInfo);
        localStorage.removeItem("userReset");
        if (!this.props.thingsAssigned) {
          this.setState({ ...this.state, loading: false });
          this.props.history.push("/vehicle/assignment");
        } else {
          this.setState({ ...this.state, loading: false });
          this.props.history.push("/");
        }

      }
      catch (error) {

        if (error.response) {
          this.recaptchaRef.current.reset();
          this.setState({ isCaptchaVerified: false });

          const data = error.response.data.message;

          if (error.response.data && error.response.data.messageKey === 'USER_RESET_PASSWORD') {
            localStorage.setItem("userReset", JSON.stringify(userInfo));
            this.setState({ ...this.state, loading: false });
            toastr.success(data);
            this.props.history.push("/user/reset")
          } else if (error.response.data && error.response.data.code === 403) {
            this.setState({ isSuspended: true, data: JSON.parse(data) });
          } else {
            this.setState({ ...this.state, loading: false });
            toastr.error(data);
          }

        }
        else {
          this.setState({ ...this.state, loading: false });
          toastr.error(error);
        }
      }
    }
  };

  render() {
    let spinner = <WhiteSpinner key="1" />
    
    return (
      <div className="justify-content-md-center login-container" style={{ backgroundImage: background }}>
        <div className="login-box">
          <div className="row">
            <img className="login-logo" src={auxo} alt="" />
          </div>
          {this.state.isSuspended ? <div className="suspend-item"> <div><strong className="red-color">Company is Suspended.<br></br> Please Contact Business Account Manager! </strong>

            {this.state.data && this.state.data.email ? <div>
              <p><strong>Name:</strong> {this.state.data.firstName} {this.state.data.lastName}</p>
              <p><strong>Email:</strong> {this.state.data.email}</p>
              <p><strong>Phone:</strong> {this.state.data.phone}</p>

            </div> : ''}

          </div> </div> :
            <form className={"login-form"} onSubmit={this.onSubmit}>
              <TextFieldGroup
                field={"username"}
                placeholder={"Enter username provided to you"}
                value={this.state.username}
                label={"Username"}
                type={"text"}
                onChange={this.onChange}
                error={this.state.errors.username} />
              <TextFieldGroup
                field={"password"}
                value={this.state.password}
                placeholder={"Enter your password"}
                label={"Password"}
                type={"password"}
                onChange={this.onChange}
                error={this.state.errors.password} />
              <div>
                <div>
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={"6LdWiQ0qAAAAAHptmi2-R9UaEpqz5s_uoD5GfpRp"}
                    onChange={this.handleCaptchaChange}
                  />
                </div>
                <button className="btn btn-success btn-login" disabled={!this.state.isCaptchaVerified} 
                type="submit">
                  {this.state.loading ? [spinner] : 'Login'}
                  </button>
              </div>

              <div className={"help-link-section"}>
                {/* <Link to="/confirm-code">Verify the code?</Link> */}
                <Link to="/forgot-password" className="text-center">Forgot Password?</Link>
              </div>
            </form>}
        </div>
      </div>
    );
  }
}

APPLogin.propTypes = {};
APPLogin.defaultProps = {};
const mapStateToProps = state => ({ thingsAssigned: state.userAuthentication.thingsAssigned });

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(UserAuthentication.creators, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps)(APPLogin);
