
import React from 'react';
import * as R from 'ramda';
// import Slider from 'react-slick';
import { Card, CardBody, CardTitle } from 'reactstrap';
// import Icon from 'react-icons-kit';
import './Index.css';
import pin_moving from '../../../assets/img/pin_moving.png';
import pin_ignition from '../../../assets/img/pin_ignition.png';
import pin_idle_shadow from '../../../assets/img/pin_idle_shadow.png';
import pin_parked from '../../../assets/img/pin_parked.png';
import noSignal from '../../../assets/img/no signal.png';
import ic_plugout from '../../../assets/img/ic_plugout.png';
import active_signal from '../../../assets/img/active_signal.png';
import pin_pluggedout from "../../../assets/img/pin_pluggedout.png";



const HelmetCardsWidget = (props) => {
  const {
    helmetIdleCount,
    helmetNotWearingDriving,
    helmetWearingDriving,
    helmetWearingStop,
    helmetParked,
    helmetUnplugCount,
    helmetNeverHeard,
    helmetNotResponding,
    totalhelmet,
    onLabelClick,
  } = props;

  return (<div className="container">
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: "1", width: "300px" }} className="card-display">
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row" style={{ textAlign: 'center' }}>
                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={pin_pluggedout}
                      
                      alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{
                        label: "Helmet Not Wearing But Driving",
                        value: "HELMET_NOT_WEARING_DRIVING"
                      }])}
                    >{helmetNotWearingDriving < 10 ? (`0` + helmetNotWearingDriving) : helmetNotWearingDriving}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Not Wearing Driving
                      </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={pin_moving} alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{
                        label: "Helmet Wearing Driving",
                        value: "HELMET_WEARING_DRIVING"
                      }])}
                    >{helmetWearingDriving < 10 ? (`0` + helmetWearingDriving) : helmetWearingDriving}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Wearing Driving
                      </span>
                  </div>

                </div>
                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={pin_ignition} style={{ transform: 'rotate(110deg)' }} alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{
                        label: "Helmet Wearing But Stop",
                        value: "HELMET_WEARING"
                      }])}
                    >{helmetWearingStop < 10 ? (`0` + helmetWearingStop) : helmetWearingStop}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Wearing
                        </span>
                  </div>

                </div>
              </div>
            </CardTitle>
          </CardBody>
        </Card>
      </div>


      <div style={{ flexGrow: "1", width: "300px" }} className="card-display">
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row" style={{ textAlign: 'center' }}>
                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={pin_idle_shadow} alt="" style={{
                      height: '28px',
                      transform: 'rotate(200deg)',
                      marginBottom: '4px'
                    }} className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{ label: "IDLE", value: "HELMET_IDLE" }])}
                    >{helmetIdleCount < 10 ? (`0` + helmetIdleCount) : helmetIdleCount}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Idle
                    </span>
                  </div>

                </div>

                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={pin_parked} alt="" style={{
                      height: '28px',
                      
                      marginBottom: '4px'
                    }} className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{ label: "Parked", value: "HELMET_PARKED" }])}
                    >{helmetParked < 10 ? (`0` + helmetParked) : helmetParked}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Parked
                                    </span>
                  </div>

                </div>

                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={active_signal} alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter"


                    >{totalhelmet < 10 ? (`0` + totalhelmet) : totalhelmet}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Total
                        </span>
                  </div>

                </div>

              </div>
            </CardTitle>
          </CardBody>
        </Card>
      </div>


      <div style={{ flexGrow: "1", width: "300px" }} className="card-display">
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row" style={{ textAlign: 'center' }}>
                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={ic_plugout} alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter "
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{
                        label: "UNPLUG",
                        value: "DEVICE_UNPLUG"
                      }])}

                    >{helmetUnplugCount < 10 ? (`0` + helmetUnplugCount) : helmetUnplugCount}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Unplug
                        </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={noSignal} alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter "
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{
                        label: "NOT INSTALLED",
                        value: "NEVER_HEARD"
                      }])}
                    >{helmetNeverHeard < 10 ? (`0` + helmetNeverHeard) : helmetNeverHeard}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Not Installed
                        </span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="margin-top-12">
                    <img src={noSignal} alt="" className="inline-block height32" />
                  </div>
                  <div>
                    <span className="counter "
                      style={{ cursor: 'pointer' }}
                      onClick={() => onLabelClick([{
                        label: "INACTIVE",
                        value: "NOT_RESPONDING"
                      }])}
                    >{helmetNotResponding < 10 ? (`0` + helmetNotResponding) : helmetNotResponding}</span>
                  </div>
                  <div>
                    <span className="card-heading font-15">
                      Inactive
                        </span>
                  </div>
                </div>
              </div>
            </CardTitle>
          </CardBody>
        </Card>
      </div>
    </div>

  </div>);
}

export default React.memo(HelmetCardsWidget);