import Duck from 'extensible-duck'
import axios from "axios";
import { logMaintenance } from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AUXOLOGMAINTENANCE', store: 'logmaintenace',
    types: [],
    initialState: {
    },
    reducer: (state, action, duck) => {},
    selectors: {
        root: state => state
    },
    creators: (duck) => ({


        getLogs: (id) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
           
            try {

              
                const response = await axios.get(logMaintenance.list(id));
                return response.data.data;
              
            }
            catch (e) {
               throw e;
            }
        },
        deleteLog: (id) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
           
            try {
                const response = await axios.delete(logMaintenance.delete(id));
                return response.data.data;
              
            }
            catch (e) {
               throw e;
            }
        },
        newLog: (logEntry) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
           
            try {
              
                const response = await axios.post(logMaintenance.newLog(),logEntry);
                return response.data.data;
              
            }
            catch (e) {
               throw e;
            }
        },
        newOtherLog: (logEntry) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
           
            try {
              
                const response = await axios.post(logMaintenance.newOtherLog(),logEntry);
                return response.data.data;
              
            }
            catch (e) {
               throw e;
            }
        },
    })
})