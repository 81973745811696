import axios from 'axios';

const clearLocalStorage = () => {
    localStorage.clear();
}

export default function setAuthorizationToken(token) {
    if (token) {
        //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem("token", token);
    
    } else {
        delete axios.defaults.headers.common['Authorization'];
        clearLocalStorage()
    }
}

export  function setIdentityId(identityId) {
    if (identityId) {
        localStorage.setItem("identityId", identityId);
    } else {
        clearLocalStorage()
    }
}
export function setRefreshToken(refreshToken){
    if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
    
    } else {
        clearLocalStorage()
    }
}
export function setUserId(userId){
    return new Promise((resolve) => {
        if (userId) {
            localStorage.setItem("user_id", userId);
        } else {
            clearLocalStorage()
        }
        return resolve(); 
    })
  
}

export function setCompanyInfo(info){
    if (info) {
        localStorage.setItem("companyInfo", JSON.stringify(info));
    } else {
        clearLocalStorage()
    }
}

export function setGroupInfo(info){
    if (info) {
        localStorage.setItem("groupInfo", JSON.stringify(info));
    } else {
        clearLocalStorage()
    }
}
export function setMigratedStatus(isMigrated){
    localStorage.setItem("isMigrated", isMigrated );
}
