import { clone, every, find, forEach, forEachRight, isArray, uniqBy } from 'lodash';

export const getGroup = (value, list, key = 'value', type = 'parent') => {

    let found = { group: find(list, [key, value]), leaf: true };

    if (!(found.group && found.group.value) && isArray(list))
      forEach(list, subList => {
        if (subList.options) {
          const optionFound = getGroup(value, subList.options, key, type);
          if (optionFound.group) {
            if (optionFound.leaf === true) found = {
              group: type === 'self' ? optionFound.group : subList,
              leaf: type === 'root'
            };
            else found = optionFound;
            return false;
          }
        }
      });

    return found
  },

  getGroupToRoot = (value, list, key = 'value', groupArr = []) => {
    const group = getGroup(value, list, key, 'parent').group;
    if (group[key] !== value) {
      getGroupToRoot(group[key], list, key, groupArr);
      groupArr.push(group);
    }
    return groupArr;
  },

  flattenOptionList = (optionList, returnSubKey) => {
    let resArr = [];
    forEach(optionList, option => {
      const data = option.data || option;
      resArr.push(returnSubKey ? data[returnSubKey] : data);
      if (option.options) resArr = resArr.concat(flattenOptionList(option.options, returnSubKey));
    });

    return resArr;
  },

  getGroupList = (optionList, resObj = {}) => {
    const parseOptionList = option => {
      if (option.options) {
        resObj[option.value] = flattenOptionList(option.options, 'value');
        getGroupList(option.options, resObj);
      }
    };

    if (isArray(optionList)) forEach(optionList, option => parseOptionList(option));
    else parseOptionList(optionList);

    return resObj;
  },

  selectGroupToRoot = (allOptionList, optionList ,selectedOption) => {
    const groupList = getGroupList(allOptionList),
      optionListPushed = optionList.length > 0 ? clone(optionList): [];

    optionListPushed.push(selectedOption);

    forEachRight(groupList, (groupOptionList, key) => {
      if (every(groupOptionList, i => find(optionListPushed, ['value', i]))) {
        const { options, ...groupData } = getGroup(key, allOptionList, 'value', 'self').group;
        optionListPushed.unshift(groupData);
      }
    });

    return uniqBy(optionListPushed, 'value');
  };
