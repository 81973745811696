import {isEmpty, isEqual} from 'lodash';
import {isEmptyValue, schema} from './validator';

export function validateInput(data) {
    let errors = {};
    if (isEmptyValue(data.username)) {
        errors.username = 'This field is required';
    }
    if (isEmptyValue(data.password)) {
        errors.password = 'This field is required';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validateForgotPassword(data) {
    let errors = {};
    if (isEmptyValue(data.username)) {
        errors.username = 'This field is required';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function   validateConfirmCodeInput(data) {
    let errors = {};
  
    if (isEmptyValue(data.token)) {
        errors.token = 'This field is required';
    }
    if (isEmptyValue(data.password)) {
        errors.password = 'This field is required';
    }
    if (isEmptyValue(data.confirmPassword)) {
        errors.confirmPassword = 'This field is required';
    }
    
    if (!schema.validate(data.password)) {
        errors.password = 'Invalid Password';
        errors.confirmPassword = 'Invalid Password';
    }
    
    if (!isEqual(data.password, data.confirmPassword)) {
        errors.confirmPassword = 'Password and confirm password does not match';
    }

    
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

