import React, {Component} from 'react';
import TextFieldGroup from "../../common/TextFieldGroup";
import {connect} from "react-redux";
import Groups from "../../../ducks/Groups";
import Things from "../../../ducks/Things";
// import {actionCreators as permissionActions} from "../../ducks/Permissions";
import {bindActionCreators} from "redux";
import CheckboxListComponent from "../../common/CheckboxListComponent";
import {validateInput} from "../../../validations/domainCreate";
import toastr from "toastr";
import * as _ from "lodash";
import Slider from 'rc-slider';
import {WhiteSpinner} from '../../common/Spinner/index';
import {HandleSlider} from "../../common/HandleSlider";


class GroupUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            pointOfContactDetails: "",
            roboCallPocPhoneNumber1:"",
            roboCallPocPhoneNumber2:"",
            errors: {},
            overSpeedingThreshold: null,
            vehicles: [],
            haveVehicles: false,
            selectAll: false,
            selectedVehicles: [],
            isEditMode: false,
            loader: false,
        }
    }

    async componentDidMount() {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

        await this
            .props
            .thingsActions
            .getGroupVehicles({groupId: companyInfo.groupId});

        await this
            .props
            .actions
            .getSingleToUpdate(this.props.match.params.id);



        let selectedVehicles = [];
        let vehicles = [];
        _.forEach(this.props.group.vehicles, item => {
            let vehicle = {
                _id: item._id,
                plateNumber: item.plateNumber || "N/A",
                checked: true,
                imei: item.imei || "N/A"
            };
            selectedVehicles.push(item._id);
            vehicles.push(vehicle)
        });
        _.forEach(this.props.group.allThings, item => {
            let vehicle = {
                _id: item._id,
                plateNumber: item.plateNumber || "N/A",
                checked: false,
                imei: item.imei || "N/A"
            };
            vehicles.push(vehicle)
        });
        //  _.forEach(this.props.vehicles, (item) => {

        //     let vehicle = {
        //         _id: item._id,
        //         plateNumber: item.plateNumber || "N/A",
        //         checked: false,
        //         imei: item.imei || "N/A"
        //     };
        //     vehicles.push(vehicle);
        // });

        this.setState({
            ...this.state,
            vehicles,
            haveVehicles: vehicles.length? true : false,
            name: this.props.group.name,
            pointOfContactDetails: this.props.group.pointOfContact,
            overSpeedingThreshold: this.props.group.overSpeedingThreshold,
            roboCallPocPhoneNumber1: this.props.group.roboCallPocPhoneNumber1,
            roboCallPocPhoneNumber2: this.props.group.roboCallPocPhoneNumber2,
            selectedVehicles: selectedVehicles

        });

    }
    isValid = () => {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }

        return isValid;
    };
    checkBoxListSelectAll = () => {

        let vehicles = this.state.vehicles;
        let selectedVehicles = this.state.selectedVehicles;

        let isSelectAll = this.state.selectAll;
        if (vehicles.length > 0) {
            for (let i = 0; i < vehicles.length; i++) {
                let item = vehicles[i];

                if (!isSelectAll) {
                    item.checked = true;
                    selectedVehicles.push(item._id);
                } else {
                    item.checked = false;
                    let index = selectedVehicles.findIndex(obj => {
                        return obj === item._id;
                    });
                    if (index > -1) {
                        selectedVehicles.splice(index, 1);
                    }
                }

            vehicles[i] = item;
            }
        }
        this.setState({
            ...this.state,
            vehicles,
            selectedVehicles,
            selectAll: !isSelectAll
        });
    };
    checkBoxListChanged = (value) => {
        let vehicles = this.state.vehicles;
        let selectedVehicles = this.state.selectedVehicles;
        let isSelectAll = this.state.selectAll;
        if (vehicles.length > 0) {
            for (let i = 0; i < vehicles.length; i++) {
                let item = vehicles[i];
                if (i === parseInt(value, 10)) {
                    item.checked = !item.checked;
                    if (item.checked) {
                        selectedVehicles.push(item._id);
                    } else {
                        let index = selectedVehicles.findIndex(obj => {
                            return obj === item._id;
                        });
                        if (index > -1) {
                            if(isSelectAll){
                                isSelectAll = false;
                            }
                            selectedVehicles.splice(index, 1);
                        }
                    }
                }
                vehicles[i] = item;
            }
        }
        this.setState({
            ...this.state,
            vehicles,
            selectedVehicles,
            selectAll: isSelectAll
        });
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    };
    onSubmit = async e => {
        e.preventDefault();
        if (this.isValid()) {
            try {
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                let body = {
                    groupId: this.props.group._id,
                    parentId: companyInfo.groupId,
                    name: _.trim(this.state.name),
                    pointOfContact: _.trim(this.state.pointOfContactDetails),
                    companyId: companyInfo.companyId,
                    vehicles: this.state.selectedVehicles,
                    roboCallPocPhoneNumber1: this.state.roboCallPocPhoneNumber1,
                    roboCallPocPhoneNumber2: this.state.roboCallPocPhoneNumber2,
                    overSpeedingThreshold: this.state.overSpeedingThreshold
                }
                this.setState({
                    ...this.state,
                    loader: true
                })
                await this
                    .props
                    .actions
                    .UpdateSubGroup(body);
                toastr.success("Group Updated Successfully!");
                this.setState({
                    ...this.state,
                    loader: false
                })
                this
                    .props
                    .history
                    .push("/groups");
            } catch (e) {
                this.setState({
                    ...this.state,
                    loader: false
                })
                toastr.error(e.response ? e.response.data.message : e);
            }

        }
    };
    searchVehicles = (e) => {

        const value = e.target.value;
        let vehicles = [];
        let selectedVehicles = this.state.selectedVehicles;
        if (value) {
             _.forEach(this.props.vehicles, (item) => {
                if (item.plateNumber.search(new RegExp(value, "i")) >= 0) {
                    let index = selectedVehicles.findIndex(obj => {
                        return obj === item._id;
                    });

                    vehicles.push({
                        _id: item._id,
                        plateNumber: item.plateNumber || "N/A",
                        checked: index > -1 ? true : false,
                        imei: item.imei || "N/A"
                    });
                }
            });

        } else {
            vehicles = _.map(this.props.vehicles, (item) => {
                let index = selectedVehicles.findIndex(obj => {
                    return obj === item._id;
                });
                return {
                    _id: item._id,
                    plateNumber: item.plateNumber || "N/A",
                    checked: index > -1 ? true : false,
                    imei: item.imei || "N/A"
                };
            });
        }
        this.setState({
            ...this.state,
            vehicles
        });
    }
    changeOverspeedingThreshold = (value)=> {

       this.setState({...this.state, overSpeedingThreshold: value})

    };


    render() {
        const loader = <WhiteSpinner key="1"/>;
        const {name, pointOfContactDetails,roboCallPocPhoneNumber1,roboCallPocPhoneNumber2} = this.state;
        const createBtnStyle= {
            "marginTop": '30px'
        };
        return (
            <div>
                <div className="heading-container">
                    <h3 className="font-weight-bold">Update Group</h3>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col">
                            <TextFieldGroup
                                onChange={this.onChange}
                                value={name}
                                type={"text"}
                                field={"name"}
                                label={"Group Name"}
                                placeholder={"Group name"}
                                error={this.state.errors.name}/>
                            <TextFieldGroup
                                onChange={this.onChange}
                                value={pointOfContactDetails}
                                type={"text"}
                                field={"pointOfContactDetails"}
                                label={"Description"}
                                placeholder={"Description"}/>
                            {/* <TextFieldGroup
                                onChange={this.onChange}
                                value={roboCallPocPhoneNumber1}
                                type={"number"}
                                field={"roboCallPocPhoneNumber1"}
                                label={"Robo Call Poc Phone Number 1"}
                                placeholder={"Phone Number of the Robo Call POC 1"}
                                /> */}
                                {/* {this.state.errors && (
                                <span className="text-danger">
                                    {this.state.errors.roboCallPocPhoneNumber1}
                                </span>
                                )} */}

                            {/* <TextFieldGroup
                                onChange={this.onChange}
                                value={roboCallPocPhoneNumber2}
                                type={"number"}
                                field={"roboCallPocPhoneNumber2"}
                                label={"Robo Call Poc Phone Number 2"}
                                placeholder={"Phone Number of the Robo Call POC 2"}
                                /> */}
                                {/* {this.state.errors && (
                                <span className="text-danger">
                                    {this.state.errors.roboCallPocPhoneNumber2}
                                </span>
                                )} */}
                                <div>
                                    <label>Select Over Speeding Threshold Value {this.state.overSpeedingThreshold}</label>
                            {this.state.overSpeedingThreshold !== null ? <Slider min={0} max={200} defaultValue={this.state.overSpeedingThreshold} handle={HandleSlider} onChange={this.changeOverspeedingThreshold}/> : ""}
                        </div>
                        <button className="btn auxo-primary-btn" style={createBtnStyle}>
                            {this.state.loader ? [loader] :  'Update'}</button>
                        </div>

                        <div className="col">
                        {this.state.haveVehicles ?  <div>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className={"nav-link active"}>Vehicles</a>
                                </li>
                            </ul>
                            <div className="tab-content access-tab mb-5">
                                <div className={"tab-pane fade show active"}>
                                    <CheckboxListComponent
                                        enableSearch = "true"
                                        selectAllCheck={this.state.selectAll}
                                        selectAllEnabled={this.state.vehicles.length ? true: false}
                                        search={this.searchVehicles}
                                        selectAll={this.checkBoxListSelectAll}
                                        onChange={this.checkBoxListChanged}
                                        data={this.state.vehicles && this
                                        .state
                                        .vehicles
                                        .map(item => ({mainTitle: item.plateNumber, subTitle: item.imei, checked: item.checked}))}/>
                                </div>
                            </div>
                            </div> : ""}

                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

GroupUpdate.propTypes = {};
GroupUpdate.defaultProps = {};
const mapStateToProps = state => {
    return {vehicles: state.things.groupVehicles, group: state.groups.group}
};
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Groups.creators, dispatch),
    thingsActions: bindActionCreators(Things.creators, dispatch),
    // permissionActions: bindActionCreators(permissionActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupUpdate);
