import axios from "axios";
import {
  ragAnalytics
} from "../api/endpoints";
import Duck from "extensible-duck";
import generateExcelReport from "../utils/generateReport";


export default new Duck({
  namespace: "BatAnalytics",
  store: "ragscore",
  types: ["IS_LOADING", "GET_RAG_SCORE", "GET_TOTAL_SCORE", "GET_THINGS", "GET_GROUPS" , "GET_VIOLATIONS"],
  initialState: {
    isLoading: false,
    analytics: null,
    accumulativeAnalytics: null,
    things: null,
    groupList: null,
    cardinalViolations:null,
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading,
        }
      case duck.types.GET_RAG_SCORE:
        return {
          ...state,
          analytics: action.analytics,
          accumulativeAnalytics: action.accumulativeAnalytics === undefined ? null : action.accumulativeAnalytics
        }
      case duck.types.GET_TOTAL_SCORE:
        return {
          ...state,
          accumulativeAnalytics: action.accumulativeAnalytics
        }
      case duck.types.GET_THINGS:
        return {
          ...state,
          things: action.things
        }
      case duck.types.GET_GROUPS:
        return {
          ...state,
          groupList: action.groupList
        }
        case duck.types.GET_VIOLATIONS:
        return {
          ...state,
          cardinalViolations: action.cardinalViolations
        }
      default:
        return state;
    }
  },
  selectors: {
    root: (state) => state,
  },
  creators: (duck) => ({
    getViolations: (id, data) => async (dispatch, getState) => {
      try {
        dispatch({ type: duck.types.GET_RAG_SCORE, analytics: null , accumulativeAnalytics: null});
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const response = await axios.post(ragAnalytics.ragscore, {
          id,
          data
        });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        if (response.data.data.mergedArray) {
          if (response.data.data.mergedArray.length === 1) {
            const formattedArray = response.data.data.mergedArray.map(obj => [
              { name: 'Harsh Accelerations per 100 km', value: parseFloat(obj?.parameterA) },
              { name: 'Harsh Brakes per 100 km', value: parseFloat(obj?.parameterB) },
              { name: 'Over Speeding Score per 100 km', value: parseFloat(obj?.parameterC) },
              { name: 'Total RAG Score', value: parseFloat(obj?.totalRagScore) }
            ]);
            dispatch({ type: duck.types.GET_RAG_SCORE, analytics: formattedArray });
          }
          else if (response.data.data.mergedArray.length > 1) {
            const formattedArray = response.data.data.mergedArray.map(obj => [
              obj.plateNumber,
              { name: 'Harsh Accelerations per 100 km', value: parseFloat(obj?.parameterA) },
              { name: 'Harsh Brakes per 100 km', value: parseFloat(obj?.parameterB) },
              { name: 'Over Speeding Score per 100 km', value: parseFloat(obj?.parameterC) },
              { name: 'Total RAG Score', value: parseFloat(obj?.totalRagScore) }
            ]);
            let sum = parseFloat(response?.data?.data?.accumulativeAnalytics?.totalParameterA) +
            parseFloat(response?.data?.data?.accumulativeAnalytics?.totalParameterB) + 
            parseFloat(response?.data?.data?.accumulativeAnalytics?.totalParameterC);
            const finalSum = sum.toFixed(2)
            let Data = [
              { name: 'Harsh Accelerations per 100 km', value: parseFloat(response?.data?.data?.accumulativeAnalytics?.totalParameterA) },
              { name: 'Harsh Brakes per 100 km', value: parseFloat(response?.data?.data?.accumulativeAnalytics?.totalParameterB) },
              { name: 'Over Speeding Score per 100 km', value: parseFloat(response?.data?.data?.accumulativeAnalytics?.totalParameterC) },
              { name: 'Total RAG Score' , value: parseFloat(finalSum)},
              { name: 'SD', value: response?.data?.data?.accumulativeAnalytics?.totalRagScorePopulation },
            ]
            dispatch({ type: duck.types.GET_RAG_SCORE, analytics: formattedArray, accumulativeAnalytics: Data });
          }
        }
        else {
          let inputObject = response.data.data[0];
          let parameterA = parseFloat(inputObject?.parameterA);
          let parameterB = parseFloat(inputObject?.parameterB);
          let parameterC = parseFloat(inputObject?.parameterC);
          let totalRagScore = parseFloat(inputObject?.totalRagScore);
          let Data = [[
            { name: 'Harsh Accelerations per 100 km', value: parameterA },
            { name: 'Harsh Brakes per 100 km', value: parameterB },
            { name: 'Over Speeding Score per 100 km', value: parameterC },
            { name: 'Total RAG Score', value: totalRagScore }
          ]
          ];
          dispatch({ type: duck.types.GET_RAG_SCORE, analytics: Data });
        }
      } catch (error) { }
    },

    getViolationsAndDownloadReport: (id, data) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.GET_RAG_SCORE, analytics: null, accumulativeAnalytics: null });
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
    
        const response = await axios.post(ragAnalytics.ragscore, { id, data });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
    
        let formattedArray = [];
        const startDate = data?.selection?.startDate;
        const endDate = data?.selection?.endDate;
    
        console.log("response.data.data.mergedArray -> ", data);
        if (response.data.data.mergedArray) {
          formattedArray = response.data.data.mergedArray.map(obj => ({
            'Start Date': new Date(startDate).toLocaleDateString(),  
            'End Date': new Date(endDate).toLocaleDateString(),
            'Vehicle': obj?.plateNumber,
            'Harsh Accelerations per 100 km': parseFloat(obj?.parameterA),
            'Harsh Brakes per 100 km': parseFloat(obj?.parameterB),
            'Over Speeding Score per 100 km': parseFloat(obj?.parameterC),
            'Total RAG Score': parseFloat(obj?.totalRagScore),
          }));
        } else {
          let inputObject = response.data.data[0];
          formattedArray.push({
            'Start Date': new Date(startDate).toLocaleDateString(),
            'End Date': new Date(endDate).toLocaleDateString(),
            'Vehicle': inputObject?.plateNumber,
            'Harsh Accelerations per 100 km': parseFloat(inputObject?.parameterA),
            'Harsh Brakes per 100 km': parseFloat(inputObject?.parameterB),
            'Over Speeding Score per 100 km': parseFloat(inputObject?.parameterC),
            'Total RAG Score': parseFloat(inputObject?.totalRagScore),   
          });
        }
    
        generateExcelReport(formattedArray, "rag_score_report");
    
      } catch (error) {
        console.error('Error generating report:', error);
      }
    },
    

    getThings: () => async (dispatch) => {
      try {
        dispatch({ type: duck.types.GET_RAG_SCORE, analytics: null , accumulativeAnalytics: null});
        const things = await axios.get(ragAnalytics.getThings)
        dispatch({ type: duck.types.GET_THINGS, things: things.data.data });
      } catch (error) {

      }
    },
    getSubGroups: () => async (dispatch) => {
      try {
        dispatch({ type: duck.types.GET_RAG_SCORE, analytics: null , accumulativeAnalytics: null});
        const response = await axios.get(ragAnalytics.getGroups);
        let subGroups = response.data.data;
        dispatch({ type: duck.types.GET_GROUPS, groupList: subGroups });

      }
      catch (e) {
      }
    },
    getCardinalViolations: (id , data) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const response = await axios.post(ragAnalytics.cardinalViolations, {
          id,
          data
        });
        dispatch({type: duck.types.GET_VIOLATIONS , cardinalViolations:response.data.data})
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
      } catch (error) {
        
      }
    }
  })

})