import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap4-modal";
import "./index.css";
import { Spinner } from "../../common/Spinner/index";
import TextFieldGroup from "../../common/TextFieldGroup";
import * as R from "ramda";
import moment from "moment";

class EngineKill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      remaining: null,
    };
    this.killEngine = this.killEngine.bind(this);
    this.killEngineConfirm = this.killEngineConfirm.bind(this);
    this.releaseEngine = this.releaseEngine.bind(this);
    this.releaseEngineConfirm = this.releaseEngineConfirm.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.fetchExpiry = this.fetchExpiry.bind(this);
    this.abortEngineKill = this.abortEngineKill.bind(this);
    this.abortEngineRelease = this.abortEngineRelease.bind(this);
  }

  async fetchExpiry() {
    const isLoading = true;
    this.setState({ isLoading });
    let code = await this.props.getExpiry();

    const { createdAt } = code;
    const expiry = createdAt
      ? moment(createdAt)
          .add(59, "minutes")
          .add("59", "seconds")
      : null;

    const remaining = expiry ? expiry.diff(moment(), "seconds") : null;
    this.setState({ remaining });

    const timer = function() {
      const rem = this.state.remaining;

      this.setState({ remaining: rem - 1 });
    };
    if (remaining) {
      setInterval(timer.bind(this), 1000);
    }
    this.setState({ isLoading: !isLoading });
  }
  async componentDidMount() {
    await this.fetchExpiry();
  }

  async killEngine() {
    const { thing } = this.props;
    this.setState({ isLoading: true });
    await this.props.onEngineKill(thing);
    await this.fetchExpiry();
    this.setState({ isLoading: false });
  }
  onCodeChange(event) {
    const code = event.target.value;
    this.setState({ confirmCode: code });
  }

  async killEngineConfirm() {
    try {
      const { confirmCode } = this.state;
      const { thing } = this.props;
      await this.props.onEngineKillConfirm(thing, confirmCode);
    } catch (error) {
      throw error;
    }
  }
  async abortEngineKill() {
    const { thing } = this.props;
    const { _id } = thing;
    await this.props.onAbortEngineKill(_id);
  }
  async abortEngineRelease() {
    const { thing } = this.props;
    const { _id } = thing;
    await this.props.onAbortEngineRelease(_id);
  }
  async releaseEngine() {
    const { thing } = this.props;
    this.setState({ isLoading: true });
    await this.props.onEngineRelease(thing);
    await this.fetchExpiry();
    this.setState({ isLoading: false });
  }
  async releaseEngineConfirm() {
    const { confirmCode } = this.state;
    const { thing } = this.props;
    await this.props.onEngineReleaseConfirm(thing, confirmCode);
  }

  render() {
    const remaining = this.state.remaining;
    const timer = remaining
      ? `${Math.floor(remaining / 60)}:${remaining % 60}`
      : null;
    const loader = <Spinner key="1" />;
    const { thing } = this.props;
    let { engineStatus } = thing;
    if (engineStatus === "awaiting_kill_code" && !timer) {
      engineStatus = "released";
    }
    const engineStatusInCaps = R.toUpper(engineStatus);

    const isEngineKillState = R.any(R.equals(engineStatus), [
      "released",
      "awaiting_kill_code",
      "awaiting_kill",
    ]);
    const isEngineAwaitingKillState = engineStatus === "awaiting_kill_code";
    const isEngineAwaitingReleaseState =
      engineStatus === "awaiting_release_code";
    console.debug({ isEngineAwaitingReleaseState, timer });
    return (
      <Modal
        visible={this.props.isOpen}
        dialogClassName="engine-kill-modal-dialog"
      >
        <div className="popup-close-btn">
          <button
            type="button"
            className="close close-x"
            aria-label="Close"
            onClick={this.props.closeAction}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {this.state.isLoading ? (
          [loader]
        ) : (
          <div>
            <div>
              <div className="modal-body ">
                <div className="row">
                  <div className="col-md-6">IMEI:{thing.imei}</div>
                  <div className="col-md-6">
                    Plat Number:{thing.plateNumber}
                  </div>
                </div>

                {isEngineKillState ? (
                  <div>
                    <div className="row">
                      <div className="col text-center ">
                        {(engineStatus === "released" ||
                          engineStatus === "awaiting_kill_code") && (
                          <button
                            className="btn auxo-primary-btn no-float mt-5"
                            onClick={this.killEngine}
                          >
                            {isEngineAwaitingKillState && timer
                              ? "Resend Code"
                              : "Kill Engine"}
                          </button>
                        )}
                        {/* {(engineStatus === 'awaiting_kill') && <button className="btn auxo-primary-btn no-float mt-5" onClick={() => this.abortEngineKill()} >Abort Engine Kill</button>} */}
                      </div>
                    </div>
                    {isEngineAwaitingKillState && timer && (
                      <div>
                        <div className="row">
                          <div className="col border-bottom mt-4 mb-4"></div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <TextFieldGroup
                              field={"kill-engine-code"}
                              placeholder={"Enter Code"}
                              value={this.state.confirmCode}
                              label={"Confirmation Code"}
                              type={"text"}
                              onChange={this.onCodeChange}
                            />
                          </div>

                          <div className="col">
                            <button
                              className={`btn auxo-primary-btn no-float no-margin`}
                              onClick={() => this.killEngineConfirm()}
                            >
                              Confirm Kill
                            </button>
                          </div>
                        </div>
                        {timer && (
                          <div className="row">
                            <div className="col">{timer}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col text-center ">
                        {(engineStatus === "killed" ||
                          engineStatus === "awaiting_release_code") && (
                          <button
                            className="btn auxo-primary-btn no-float mt-5"
                            onClick={this.releaseEngine}
                          >
                            {isEngineAwaitingReleaseState && timer
                              ? "Resend Code"
                              : "Release Engine"}
                          </button>
                        )}

                        {/* {(engineStatus === 'awaiting_release') && <button className="btn auxo-primary-btn no-float mt-5" onClick={() => this.abortEngineRelease()} >Abort Engine Release</button>} */}
                      </div>
                    </div>
                    {isEngineAwaitingReleaseState && timer && (
                      <div>
                        <div className="row">
                          <div className="col border-bottom mt-4 mb-4"></div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <TextFieldGroup
                              field={"kill-engine-code"}
                              placeholder={"Enter Code"}
                              value={this.state.confirmCode}
                              label={"Confirmation Code"}
                              type={"text"}
                              onChange={this.onCodeChange}
                            />
                          </div>

                          <div className="col">
                            <button
                              className={`btn auxo-primary-btn no-float no-margin`}
                              onClick={() => this.releaseEngineConfirm()}
                            >
                              Confirm Release
                            </button>
                          </div>
                        </div>
                        {timer && (
                          <div className="row">
                            <div className="col">{timer}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="modal-footer ">
                <h6>Status:</h6>
                <span>{engineStatusInCaps}</span>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

EngineKill.propTypes = {};
EngineKill.defaultProps = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = R.applySpec({});
export default connect(mapStateToProps, mapDispatchToProps)(EngineKill);
