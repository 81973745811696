import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';

import './index.scss';

import * as expandList from '../../assets/img/list/expand-list.png'
import * as collapseList from '../../assets/img/list/collapse-list.png'

const TableComponent = (props) => {

  // const paginationOptions = paginationFactory({
  //   sizePerPage: parseInt(process.env.REACT_APP_OFFSET_LIMIT || '100', 10),
  //   pageStartIndex: 1,
  //   totalSize: props.totalSize || props.data.length,
  //   paginationSize: 3,  // the pagination bar size, default is 5
  //   showTotal: true, // display pagination information
  //   withFirstAndLast: false, // hide the going to first and last page button
  //   alwaysShowAllBtns: true, // always show the next and previous page button
  //   hideSizePerPage: true, // hide the size per page dropdown
  //   hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
  //   onPageChange: props.onPageChange
  // });

  const rowEvents = {
    onClick: (e, row) => (props.onRowClick && props.onRowClick(row)) || (() => {})
  };

  const expandRow = {
    renderer: row => (props.onRowExpand && props.onRowExpand(row)) || (() => {}),
    showExpandColumn: true,
    expandByColumnOnly: true,
    onlyOneExpanding: true,
    nonExpandable: props.nonExpandable || [],
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (<img src={isAnyExpands ? collapseList : expandList} alt={isAnyExpands ? '-' : '+'} style={{ width: '10px' }}/>),
    expandColumnRenderer: props => {
      if (props.expandable) return (<img src={props.expanded ? collapseList : expandList} alt={props.expanded ? '-' : '+'} style={{ width: '10px' }}/>);
      return (<></>);
    }
  };

  const tableChange = (type, event) => {
    if (props.sortByColumn) props.sortByColumn(event.sortField || 'asc', event.sortOrder || '');
  };

  return (
    <div className={props.className}>
      <ToolkitProvider
        bootstrap4={true}
        columns={props.thList}
        keyField={'keyField'}
        data={props.data || []}
        columnToggle={props.columnToggle !== undefined ? props.columnToggle : false}
      >
        {
          (toolkitProps) => (
            <div>
              {props.columnToggle && <ColumnToggle.ToggleList {...toolkitProps.columnToggleProps}/>}
              <BootstrapTable
                classes={props.tableClassName}
                headerClasses={props.headerClassName}
                bordered={props.bordered !== undefined ? props.bordered : false}
                hover={props.hover !== undefined ? props.hover : true}
                noDataIndication={() => (<h3 className='center-text'>No data Found</h3>)}
                // pagination={props.showPagination ? paginationOptions: {}}
                remote={props.remoteFetch !== undefined ? props.remoteFetch : true}
                loading={true}
                responsive={true}
                rowClasses={props.rowClass}
                rowEvents={rowEvents}
                expandRow={expandRow}
                onTableChange={tableChange}
                {...toolkitProps.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );
};

export default TableComponent;
