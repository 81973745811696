import React, { useState } from 'react';
import { components } from 'react-select-nested-group';

import { flattenOptionList, getGroup, getGroupList, getGroupToRoot, selectGroupToRoot } from './utils';
import {
  every,
  filter,
  find,
  findIndex,
  forEach,
  forEachRight,
  includes,
  isArray,
  map,
  pickBy,
  pullAt,
  some
} from 'lodash';

const getOptionStyles = ({ isDisabled, isHovered }) => {
    return defaultStyles => {
      return {
        ...defaultStyles,
        backgroundColor: !isDisabled && isHovered ? '#deebff' : 'transparent',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '5%'
      }
    }
  },

  getGroupHeadingStyles = ({ isDisabled, isHovered }) => {
    return defaultStyles => {
      return {
        ...defaultStyles,
        backgroundColor: !isDisabled && isHovered ? '#deebff' : 'transparent',
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'normal',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '11px 12px 11px 5%',
        margin: 0
      };
    };
  },

  getGroupStyles = () => {
    return defaultStyles => {
      return {
        ...defaultStyles,
        listStyle: 'none',
        padding: '0'
      };
    };
  };


export const ValueContainer = (props) => {

  const children = props.children[0];

  let toBeRendered = [children, props.children[1]];

  if (props.isMulti) {
    let optionList = props.getValue();
    const groupList = getGroupList(props.options),
      valueSet = new Set();

    forEach(groupList, (groupOptionList, key) => {

      const parentGroupList = Object.keys(pickBy(groupList, group => includes(group, key)));

      // Skip Parent or Sub Parent already appended
      if (parentGroupList && parentGroupList.length && some(parentGroupList, group => valueSet.has(group))) return true;

      if (every(groupOptionList, i => find(optionList, ['value', i]))) {
        valueSet.add(key);
        optionList = filter(optionList, i => !includes(groupOptionList, i.value));
      }
    });

    toBeRendered = [
      !isArray(children) && children && children.key && children.key === 'placeholder'
        ? children
        : [filter(children, child => child && find(optionList, ['value', child.key]))],
      props.children[1]
    ];
  }

  return (<components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>);
};


export const Option = props => {
  const [isHovered, setIsHovered] = useState(false);

  const toggleOption = () => {

    let optionList = props.getValue();

    if (props.isMulti) {
      const groupList = [getGroup(props.value, props.options).group],
        removeIndexArr = [findIndex(optionList, ['value', props.data.value])];

      if (removeIndexArr[0] !== -1) {
        // Remove Group upto root if an option is unselected
        getGroupToRoot(groupList[0].value, props.options, 'value', groupList);
        forEach(groupList, group => {
          const groupIndex = findIndex(optionList, ['value', group.value]);
          if (groupIndex !== -1) removeIndexArr.push(groupIndex);
        });
        pullAt(optionList, removeIndexArr);
      } else optionList = selectGroupToRoot(props.options, optionList, props.data);
    }
    // For Single Mode always first index is pushed
    else optionList[0] = props.data;
    props.setValue(optionList)
  };

  const styles = getOptionStyles({ isDisabled: props.isDisabled, isHovered })(props.getStyles('option', props));

  return (
    <span
      {...props.innerProps}
      style={styles}
      onClick={toggleOption}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {
        props.isMulti && (
          <input
            type='checkbox'
            readOnly={true}
            checked={props.isSelected}
            onChange={() => null}
            style={{ margin: '0 2px 0 0' }}
          />
        )
      }
      {' '}
      {props.label}
    </span>
  );
};

export const GroupHeading = (props) => {

  const [isHovered, setIsHovered] = useState(false);

  const getChildrenList = () => {
    let selectedGroup = getGroup(props.group.key, props.menuOptions.render, 'key', 'self').group;
    return selectedGroup && selectedGroup.options ? selectedGroup.options : [];
  };

  const allOptionsSelected = () => {
    const selectValue = props.getValue();
    return every(flattenOptionList(getChildrenList()), i => props.isOptionSelected(i, selectValue));
  };

  const includedOptions = (groupData, groupListToRoot) => {

    const selectValue = props.getValue();

    const resOptionList = [
      ...filter(map(flattenOptionList(getChildrenList()), i => {
        const { options, ...data } = i.data || i;
        if (!props.isOptionSelected(i, selectValue)) return data
      })),
      ...selectValue,
    ];

    resOptionList.unshift(groupData);

    forEachRight(groupListToRoot, group => {
      const groupList = flattenOptionList(group.options);
      if (every(groupList, option => find(resOptionList, ['value', option.value])))
        resOptionList.unshift({ label: group.label, value: group.value });
    });

    return resOptionList;
  };

  const excludedOptions = (groupData, groupListToRoot) => {
    const children = flattenOptionList(getChildrenList()),
      selectValue = props.getValue(),
      groupToRootValueList = map(groupListToRoot, 'value');

    // Explicitly check root group since it was added manually
    return filter(selectValue, i => {
      return !(i.value === groupData.value || includes(groupToRootValueList, i.value) || props.isOptionSelected(i, children));
    });
  };

  const toggleGroup = () => {
    const { options, ...groupData } = props.group.data,
      groupListToRoot = map(getGroupToRoot(props.group.key, props.menuOptions.render, 'key'), 'data');

    if (props.isMulti) props.setValue(!allOptionsSelected() ? includedOptions(groupData, groupListToRoot) : excludedOptions(groupData, groupListToRoot));
    else props.setValue([groupData]);
  };

  const styles = getGroupHeadingStyles({ isDisabled: props.isDisabled, isHovered })(props.getStyles('groupHeading', props));

  return (
    <span
      className='group-heading'
      onClick={props.isDisabled ? null : toggleGroup}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={styles}
    >
      {
        props.isMulti && (
          <input
            type='checkbox'
            readOnly={true}
            // checked={allOptionsSelected()}
            style={{ margin: '0 2px 0 0' }}
            onChange={() => null}
          />
        )
      }
      {' '}
      {props.label}
    </span>
  );

};

export const Group = (props) => {

  const styles = getGroupStyles()(props.getStyles('group', props));

  return (
    <li
      {...props.innerProps}
      aria-label={props.label}
      ref={props.innerRef}
      style={styles}
    >
      <props.Heading {...props} />
      <div
        ref={props.innerRef}
        style={{ paddingLeft: '5%' }}
      >
        <ul style={{ margin: 0, padding: 0, borderLeft: '1px solid rgba(0, 0, 0, 0.125)' }}>
          {props.children}
        </ul>
      </div>
    </li>
  );
};
