import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

const TextAreaGroup = ({ field, value, label, placeholder, error, type, onChange, onBlur, pattern, id, key, labelIcon }) => {
    let labelTag;
    if(label){
       labelTag =  <label>{label}</label>
   }
    return (
        <div className={classnames('form-group', { 'has-error': error })}>
           {labelTag } {labelIcon ? <span> <img src={labelIcon.icon} alt="info" href="#" id="UncontrolledTooltipExample"/> <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
        {labelIcon.text}
      </UncontrolledTooltip> </span> : ""} 
            <textarea 
                rows="2"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={field}
                placeholder={placeholder}
                className="form-control"
                pattern={pattern}
                id= {id}
                key= {key}
            />
            {error && <span className="text-danger">{error}</span>}
        </div>  );
};

TextAreaGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    pattern: PropTypes.string,
    id: PropTypes.number,
    key: PropTypes.number,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checkUserExists:PropTypes.func
};

TextAreaGroup.defaultProps = {
    type: 'text'
};

export default TextAreaGroup;