import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Drivers from "../../../ducks/Drivers";
// import {actionCreators as permissionActions} from "../../ducks/Permissions";
import { bindActionCreators } from "redux";
import forwardIcon from "../../../assets/img/ic_forward.png";
import * as _ from "lodash";
import "./index.css";
import { Spinner } from "../../common/Spinner/index";
import { UncontrolledCollapse } from "reactstrap";
import moment from "moment";
import AssignSchedule from "../list/assignSchudule";
import { Card, CardBody, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, } from "reactstrap";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Sector,
  ResponsiveContainer,
} from "recharts";
import PieChartComp from "../../vehicles/mapView/pieChart";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy + outerRadius + 35}
        dy={8}
        textAnchor="middle"
        fill={fill}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + 5} y={ey - 5} textAnchor={textAnchor} fill="#333">{`${(
        percent * 100
      ).toFixed(0)}%`}</text>
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${(percent * 100).toFixed(1)}%`}
        </text> */}
    </g>
  );
};

const PieChartWithCells = (props) => {
  const colors = ["#FF0000", "#FFA500", "#03ABCE", "#B22222", "#d4aaff"];
  const [activeIndex, setActivendex] = useState(0);
  const allZero = (data) => {
    for (let cell of data) {
      if (cell.value > 0) {
        return false;
      }
    }
    return true;
  };
  const onPieEnter = (data, index) => {
    setActivendex(index);
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={200} height={300}>
        {allZero(props.data) && (
          <Pie
            data={[{ name: "Daily Score", value: 100 }]}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            cx={110}
            cy={90}
            innerRadius={40}
            outerRadius={60}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            fill={"#9AD35E"}
            onMouseEnter={onPieEnter}
          ></Pie>
        )}
        <Pie
          data={props.data}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          cx={110}
          cy={90}
          innerRadius={40}
          outerRadius={60}
          fill="#000000"
          paddingAngle={2}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          onMouseEnter={onPieEnter}
        >
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

class DriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRecords: true,
      scheduleVisible: false,
      driver: {},
      drivingScore: {},
      schedules: [],
      activeIndex: 0,
      modal: false,
      events: [],
      eventName: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  async fetchData() {
    let driverId = this.props.match.params.id;

    await this.props.actions.getSingle(driverId);
    this.setState({
      ...this.state,
      isLoadingRecords: false,
      driver: this.props.driver.driver,
      drivingScore: this.props.driver.drivingScore,
      schedules: this.props.driver.schedules
    });
  }

  async componentDidMount() {
    this.fetchData();
  }
  async showSchedule() {
    await this.setState({
      ...this.state,
      scheduleVisible: true,
    });
    this.child.loadData();
  }
  closePopup() {
    this.fetchData();
    this.setState({
      ...this.state,
      scheduleVisible: false,
    });
  }

  openSchedule(id) {
    let schedules = _.map(this.state.schedules, (item) => {
      if (item._id === id) {
        item.isSelected ? (item.isSelected = false) : (item.isSelected = true);
      }
      return item;
    });

    this.setState({
      ...this.state,
      schedules,
    });
  }

  formatData(data) {
    let response = [];
    for (let cell in data) {
      let key = "";
      if (cell === "harshBrakes") {
        key = "Harsh Brakes";
      }
      if (cell === "speedLimit") {
        key = "Speed Limit";
      }
      if (cell === "harshTurn") {
        key = "Sharp Turn";
      }
      if (cell === "unFastenSeatBelt") {
        key = "Unfasten Seatbelt";
      }
      if (cell === "harshAcceleration") {
        key = "Harsh Acceleration";
      }
      response = [...response, { name: cell, key, value: data[cell] }];
    }
    return response;
  }

  displayModal(state, eventName, displayName) {
    const eventNames = {
      harshBrakes: 'HARSH_BRAKES',
      speedLimit: 'SPEED_LIMIT',
      harshTurn: 'HARSH_TURN',
      unFastenSeatBelt: 'UNFASTEN_SEATBELT',
      harshAcceleration: 'HARSH_ACCELERATION'
    }
    const events = this.state.drivingScore[state].violations[eventNames[eventName]];
    
    this.setState({
      ...this.setState,
      events: events,
      modal: true,
      eventName: displayName
    })
  }

  toggle(){
    this.setState({
      ...this.state,
      modal: !this.state.modal
    })
  }
  getScoreColorClass(score){
    if(score > 90){
      return '';
    }

    if(score <= 90 && score >= 80 ) {
      return 'score-blue';
    } else {
      return 'score-red';
    }
  }



  render() {
    const loader = <Spinner key="1" />;
    const isLoadingRecords = this.state.isLoadingRecords;
    const driver = this.state.driver;
    const schedules = this.state.schedules;
    const drivingScore = this.state.drivingScore;
    const dailyViolations = this.formatData(
      this.state.drivingScore.dailyDrivingScore?.violationsCounts
    );
    const weeklyViolations = this.formatData(
      this.state.drivingScore.weeklyDrivingScore?.violationsCounts
    );
    const monthlyViolations = this.formatData(
      this.state.drivingScore.monthlyDrivingScore?.violationsCounts
    );
    const colors = ["#DE1A1A", "#95A3DA", "#ACBED8", "#F2D398", "#D78521"];
    return (
      <>
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{`${this.state.eventName} Violation Details`}</ModalHeader>
        <ModalBody>
          <table className='table-auxo'>
            <thead>
              <tr>
              <th>Plate Number</th>
              <th>Group</th>
              <th>Violation Time</th>
              <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {this.state.events.map((row, index) => {
                return (
                  <tr key={index.toString()}>
                    <td className="modal-cell">{row.plateNumber}</td>
                    <td className="modal-cell">{row.group}</td>
                    <td className="modal-cell">{moment(row.eventTime).format("DD-MMM-YYYY hh:mm a")}</td>
                    <td className="modal-cell"><a href={`https://google.com/maps/search/${row.latlng}`} target="_blank" rel="noopener noreferrer">{row.location}</a></td>
                  </tr>
                )
              })}
              {
                this.state.events.length === 0 && <tr>
                  <td colspan='4' style={{textAlign: 'center'}}>No Violation found.</td>
                </tr>
              }
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <button className="btn auxo-primary-btn create-geofence-btn" onClick={this.toggle}>Close</button>
        </ModalFooter>
      </Modal>
      <div>
        {!isLoadingRecords ? (
          <div>
            <AssignSchedule
              driverId={driver._id}
              driverName={driver.name}
              scheduleVisible={this.state.scheduleVisible}
              vehicles={driver.thing}
              onRef={(ref) => (this.child = ref)}
              closePopup={this.closePopup.bind(this)}
            />
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-8 margin-top-20">
                <span className="driver-name">{driver.name}</span>
                <span className="driver-emp-id">{driver.serialNumber}</span>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 margin-top-20">
                <button
                  className="btn auxo-primary-btn"
                  onClick={this.showSchedule.bind(this)}
                >
                  Schedule
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 ">
                <div className={"col-md-3 card-display "}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <div className="driver-card">
                        <div className="m-t-20-px">
                          {dailyViolations.map((violation, index) => {
                            return (
                              <div
                                className="flex space-between p-t-b-3-px divider"
                                key={index.toString()}
                                onClick={() => this.displayModal('dailyDrivingScore',violation.name, violation.key)}
                              >
                                <span className="score-tag">{violation.key}</span>
                                <span>{violation.value}</span>
                              </div>
                            );
                          })}
                        </div>
                        <span style={{textAlign: "center", marginTop: "10px", fontSize: "smaller"}}>Based on {this.state.drivingScore.dailyDrivingScore.trips} trips</span>
                        <div className="driver-score">
                          <span className={`score ${this.getScoreColorClass(drivingScore.dailyDrivingScore.drivingScore)}`}>
                            {drivingScore.dailyDrivingScore.drivingScore}
                          </span>
                          <span className="score-tag">Daily Score</span>
                        </div>
                        <div className="driver-chart">
                          <PieChartWithCells data={dailyViolations} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 ">
                <div className={"col-md-3 card-display "}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <div className="driver-card">
                        <div className="m-t-20-px">
                          {weeklyViolations.map((violation, index) => {
                            return (
                              <div
                                className="flex space-between p-t-b-3-px divider"
                                key={index.toString()}
                                onClick={() => this.displayModal('weeklyDrivingScore',violation.name, violation.key)}
                              >
                                <span className="score-tag">{violation.key}</span>
                                <span>{violation.value}</span>
                              </div>
                            );
                          })}
                        </div>
                        <span style={{textAlign: "center", marginTop: "10px", fontSize: "smaller"}}>Based on {this.state.drivingScore.weeklyDrivingScore.trips} trips</span>
                        <div className="driver-score">
                          <span className={`score ${this.getScoreColorClass(drivingScore.weeklyDrivingScore.drivingScore)}`}>
                            {drivingScore.weeklyDrivingScore.drivingScore}
                          </span>
                          <span className="score-tag">Last 7 Days</span>
                        </div>
                        <div className="driver-chart">
                          <PieChartWithCells data={weeklyViolations} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 ">
                <div className={"col-md-3 card-display "}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <div className="driver-card">
                        <div className="m-t-20-px">
                          {monthlyViolations.map((violation, index) => {
                            return (
                              <div
                                className="flex space-between p-t-b-3-px divider"
                                key={index.toString()}
                                onClick={() => this.displayModal('monthlyDrivingScore',violation.name, violation.key)}
                              >
                                <span className="score-tag">{violation.key}</span>
                                <span>{violation.value}</span>
                              </div>
                            );
                          })}
                        </div>
                        <span style={{textAlign: "center", marginTop: "10px", fontSize: "smaller"}}>Based on {this.state.drivingScore.monthlyDrivingScore.trips} trips</span>
                        <div className="driver-score">
                          <span className={`score ${this.getScoreColorClass(drivingScore.monthlyDrivingScore.drivingScore)}`}>
                            {drivingScore.monthlyDrivingScore.drivingScore}
                          </span>
                          <span className="score-tag">Last 30 days</span>
                        </div>
                        <div className="driver-chart">
                          <PieChartWithCells data={monthlyViolations} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>

          </div>
        ) : (
          [loader]
        )}
      </div>
      </>
    );
  }
}

DriverDetails.propTypes = {};
DriverDetails.defaultProps = {};
const mapStateToProps = (state) => ({ driver: state.drivers.driver });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Drivers.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(DriverDetails);
