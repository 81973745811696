import React, { useState, useEffect } from 'react';
import Select from 'react-select-nested-group';
import * as R from 'ramda';
import moment from 'moment';
import toastr from "toastr";
import transform from './observationHelper';
import { Spinner } from '../common/Spinner';
import DateTime from 'react-datetime';

import Things from "../../ducks/Things";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PaginatedTable from '../common/PaginatedTable';


const ObservationPage = (props) => {

  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [things, setThings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableValues, setTableValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedHours, setSelectedHours] = useState({ value: 12, label: 12 })



  const data = JSON.parse(localStorage.getItem("companyInfo"));
  const companyId = data.companyId;
  let columns = [
    { name: 'Storage id' , selector: "plate", sortable: true, wrap: true},
    { name: 'Device Time' , selector: "gpsTime", sortable: true, wrap: true, minWidth: "200px"},
    { name: 'latlng' , selector: "latlng", sortable: true, wrap: true, minWidth: "200px"},
    { name: 'Temperature' , selector: "sensorData"},
  ];
  let headers = [
    { label: 'Storage id' , key: "plate"},
    { label: 'Device Time' , key: "gpsTime", sortable: true, wrap: true},
    { label: 'latlng' , key: "latlng"},
    { label: 'Temperature' , key: "sensorData"},
  ];
 
  if(companyId === '603f24e4fe792949f28592c4'){
    columns.push({ name: 'Genset', selector: "generator" });
    headers.push({ label: 'Genset', key: 'generator'})
  }
  if(companyId === '60e834a9a035ca2af17a4521'){
    columns.push({ name: 'Humidity (%RH)', selector: "humidity" });
    columns.push({ name: 'Battery (%)', selector: "bleBattery" });
    headers.push({ label: 'Humidity (%RH)', key: 'humidity'})
    headers.push({ label: 'Battery (%', key: 'bleBattery'})
  }
  if(companyId === '603f7be06a23817d9e06a2f4'){
    columns = [
      { name: 'id' , selector: "plate", sortable: true, wrap: true},
      { name: 'Device Time' , selector: "gpsTime", sortable: true, wrap: true, minWidth: "200px"},
      { name: 'Fuel' , selector: "fuelValue"},
      { name: 'latlng' , selector: "latlng"},
      { name: 'Ignition' , selector: "ignition"},
      { name: 'Speed (KM/H)' , selector: "speed"},
      { name: 'Trip' , selector: "trip"},
      { name: 'Idling' , selector: "idling"},
    ];
    headers = [
      { label: 'id' , key: "plate"},
      { label: 'Device Time' , key: "gpsTime", minWidth: "200px"},
      { label: 'Fuel' , key: "fuelValue"},
      { label: 'latlng' , key: "latlng"},
      { label: 'Ignition' , key: "ignition"},
      { label: 'Speed (KM/H)' , key: "speed"},
      { label: 'Trip' , key: "trip"},
      { label: 'Idling' , key: "idling"},
    ];
  }


  const hoursArray = R.unfold(h => h <= 24 ? [h, h + 1] : false, 1)
  

  const actions = {
    async onSubmit(event) {
      event.preventDefault();
      if (!selectedVehicle) {
        return toastr.error('Id is required.')
      }
      if (!selectedDate) {
        return toastr.error('Start date is required.')
      }
      if (!selectedHours) {
        return toastr.error('Hours selection is required.')
      }
      const endDate = moment(selectedDate).add(selectedHours.value, 'hours');
   
      const interval = {
        startDate: moment(selectedDate),
        endDate: moment(endDate),
      }
      setIsLoading(true);
      const observations = await props.actions.getObservations(selectedVehicle.value, JSON.stringify({ interval }));
      setIsLoading(false);
      const filter = row => {
        return row[1] !== 'N/A' && row.gpsTime !== 'N/A'};
      const values = R.compose(R.filter(filter), (s) => {
        return s;
      }, R.map(transform(selectedVehicle.label)))(observations);
      setTableValues(values);
    },

    handleVehicleChange(option) {
      setSelectedVehicle(option);
    },
  }

  const loadThings = async () => {
    setIsLoading(true);
    const things = await props.actions.fetchThings();
    const mappedValues = R.map(thing => ({ value: thing.name, label: `${thing.plateNumber || 'N/A'}` }))(things);
    setThings(mappedValues);
    setIsLoading(false);
  }

  useEffect(() => {
    loadThings();
  }, [])
  return !isLoading ? (

    <div>


      <div className="row" style={{ margin: '0px 0px 25px 0' }}>

        <div className="col-lg-3 col-sm-3">
          <label>Select</label>
          <Select
            name="form-field-selectedThing"
            value={selectedVehicle}
            searchable={true}
            onChange={actions.handleVehicleChange}
            options={things} />

        </div>
        <div className="col-lg-3 col-sm-3" >
          <label>Date</label>
          <DateTime value={selectedDate} dateFormat="DD-MM-YYYY" timeFormat="HH:mm" onChange={setSelectedDate} />

        </div>
        <div className="col-lg-3 col-sm-3" >
          <label >Duration (Hours)</label>
          <Select
            name="form-field-name"
            isClearable={false}
            isSearchable={true}
            placeholder="Select"
            defaultValue={selectedHours}
            onChange={setSelectedHours}
            options={hoursArray.map(h => {
              return { value: h, label: h }
            })} />
        </div>
        <div className="col-lg-3" style={{ padding: '25px 0 0 25px' }}>
          <button className={`btn btn-primary search-btn  search-button`} onClick={actions.onSubmit}>Search</button>
        </div>
      </div>

      {/* <MUIDataTable
        title={"Observation List"}
        data={tableValues.map(value => { return value; })}
        columns={columns}
        options={options}
      /> */}
      <PaginatedTable columns={columns} headers={headers} data={tableValues} title="Observation List" fileName="Observation List" />
    </div>) : <div style={{
      'marginTop': '20%'
    }}><Spinner/></div>;
}

const mapStateToProps = state => ({ things: state.things.things });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ObservationPage);
