import React from 'react';
import queryString from 'query-string';
import UserAuthentication from "../../ducks/UserAuthentication";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const AdminLogin = (props) => {
  const query = queryString.parse(props.location.search);
  const companyInfo = { groupId: query.group, companyId: query.company };
  const groupInfo = { groupName: query.groupName, depthLevel: query.depthLevel, groupImage: query.groupImage };
  // localStorage.setItem('user_id', query.user);
  // localStorage.setItem('token', query.token);
  // localStorage.setItem('refreshToken', query.refreshToken);
  // localStorage.setItem('identityId', query.identityId);
  // localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
  // localStorage.setItem('groupInfo', JSON.stringify(groupInfo));

  const userInfo = {
    companyInfo,
    refreshToken:query.refreshToken,
    token:query.token,
    identityId:query?.identityId,
    userId:query.user,
    groupData:groupInfo,
    isMigrated: query.isMigrated
  };
  const setUser = async function(){
    props.actions.setAdminUser(userInfo).then(()=>{
      setTimeout(function () {
        props.history.push('/?ts='+Date.now())
      }, 1000);
    });
  }
setUser();
  return (<div>Loading....</div>)
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(UserAuthentication.creators, dispatch)
});
export default connect(mapStateToProps,mapDispatchToProps)(AdminLogin);