import React, { Component } from 'react';
import { connect } from "react-redux";
import Things from "../../../ducks/Things";
import Reports from '../../../ducks/Reports';
import moment from 'moment';
import Modal from 'react-bootstrap4-modal';
import { TripMap } from '../../common/maps/tripMap';
import Select from 'react-select-nested-group';
import { bindActionCreators } from "redux";
import Table from 'react-bootstrap/Table'
import toastr from "toastr";
import './index.css';
import { Spinner } from '../../common/Spinner/index';

const _ = require('lodash');

const colors = {
    activeThumb: {
      base: "#fff",
    },
    inactiveThumb: {
      base: "#fff",
    },
    active: {
      base: "#03ABCE",
      hover: "#03ABCE",
    },
    inactive: {
      base: "rgb(65,66,68)",
      hover: "rgb(95,96,98)",
    },
  };
class MapView extends Component {
    // define voilaions bool in state and function;
    constructor(props) {
        super(props);

        const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

        this.state = {
            responseData : {},
            // filteredData : {},
            // isReturnSide: false,
            // displayMap: false,
            // isMapRenderable: false,
            tripPath : [],
            currentChunkToRender : 0,
            loading: true,
            summaryMap: {},
            enableHarshAcceleration: true,
            enableHarshBrakes: true,
            enableHarshTurn: true,
            enableOverSpeeding: true,
            enableUnfastenSeatbelt: true,
            fatigues: true,
            restAfterfatigue: true,
            todayTrack: [],
            todayEvents: {},
            selectedDate: null,
            selectedVehicle: null,
            selectedHours: null,
            isLoadingRecords: true,
            loadingPlateNumber: true,
            vehicleNumberPlate: "",
            thingId: "",
            path: [],
            ignitionOn: [],
            ignitionOff: [],
            loadMap: false,
            geofence: [],
            geofencesArea: [],
            distance: null,
            harshAcceleration: [],
            voilations: {},
            enableHarshAcceleration: true,
            enableHarshBrakes: true,
            enableHarshTurn: true,
            enableOverSpeeding: true,
            isSearchCriteriaVisible: true,
            stops: [],
            position: {},
            playbackSpeed: { value: 1, label: '1x' },
            playbackStatus: null,
            companyId: companyInfo ? companyInfo.companyId : "",
        }
        this.gridElement = React.createRef();
        this.tbody = React.createRef();

    }

    componentDidMount() {

        this.props.onRef(this)

        this.setState({
            ...this.state,
            displayMap: false
        })
    }

    displayReturned() {

        if(this.state.responseData.returnTrip)
        {
            const filteredData = this.state.isReturnSide ? this.state.responseData.oneWayTrip : 
            this.state.responseData.returnTrip ? this.state.responseData.returnTrip : null;

            this.setState({
                ...this.state,
                isReturnSide: !this.state.isReturnSide,
                filteredData, 
                path: filteredData.path.map(point => point.latlng),
                geofences: filteredData.geofences,
                completePath: filteredData.path,
                // geofencesArea: filteredData.geofencesArea,
                // harshAcceleration: filteredData.harshAcceleration,
                // harshBrakes: filteredData.harshBrakes,
                // harshTurn: filteredData.harshTurn,
                // overSpeeding: filteredData.overSpeeding,
            });
        }
    }

    async prevChunk() {
      if(this.state.currentChunkToRender > 0) {
        this.setState({
          ...this.state,
          currentChunkToRender: --this.state.currentChunkToRender,
          //path : this.state.tripPath[this.state.currentChunkToRender-1]
        });

        const _path = this.state.tripPath[this.state.currentChunkToRender];

        const path = _path.map(p => p.latlng);

        await this.setState({
          ...this.state,
          path: path,
          currentIndex : 0
        });

        const currentIndex = 0;
        const position = path[currentIndex];
        const { angle } = _path[currentIndex];
        this.setState({ ...this.state, currentIndex: currentIndex, position, angle });
      }
    }
    async nextChunk() {
      if(this.state.currentChunkToRender < this.state.tripPath.length-1) {
        
        this.setState({
          ...this.state,
          currentChunkToRender: ++this.state.currentChunkToRender,
         // path : this.state.tripPath[this.state.currentChunkToRender+1]
        });
       

        const _path = this.state.tripPath[this.state.currentChunkToRender];
        const path = _path.map(p => p.latlng);

        await this.setState({
          ...this.state,
          path: path,
          currentIndex : 0
        });

        const currentIndex = 0;
        const position = path[currentIndex];
        const { angle } = _path[currentIndex];
        this.setState({ ...this.state, currentIndex: currentIndex, position, angle });

      }
    }
    
    displayMap() {
        this.setState({
            ...this.state,
            displayMap: !this.state.displayMap
        });
    }

    loadData = async (row) => {
        this.setState({
            loading: true,
            trip: {},
            enableHarshAcceleration: true,
            enableHarshBrakes: true,
            enableHarshTurn: true,
            enableOverSpeeding: true,
            enableUnfastenSeatbelt: true
        });
        let data = {
            'vehicleId':row.vehicleId,
            'oneWayStartTime': row.oneWayStartTime,
        };
        let end  = moment(new Date());
        if (row.returnEndTime) {
            data['returnEndTime'] = new Date(row.returnEndTime);
            end = moment(new Date(row.returnEndTime));
        }  else {
            end = moment(new Date(row.oneWayStartTime)).add(96, 'hours');
        }
        data['oneWayEndTime'] = row.oneWayEndTime || end;
        let tripStart = moment(new Date(data.oneWayStartTime));
        let oneSideEnd = data.oneWayEndTime ? moment(new Date(data.oneWayEndTime)) : null;
        
        const interval = { "startDate": tripStart.format(), "oneSideEnd": oneSideEnd.format(), "endDate": end.format() };
        const dataResponse = await this.props.actions.getGeoFenceTrackPoints(JSON.stringify({ interval }), row.vehicleId) || {};
        // this.setState({ 
        //     ...this.state,
        //     isMapRenderable: (dataResponse?.oneWayTrip?.path?.length < 15000 && 
        //         (dataResponse?.returnTrip ? dataResponse?.returnTrip?.path?.length < 15000 : true) 
        //         ? true 
        //         : false),
          
        // })

       // console.log("dataResponse: ", dataResponse);

        // console.log("-----dataResponse.oneWayTrip------",!(dataResponse.oneWayTrip && dataResponse.oneWayTrip.path && dataResponse.oneWayTrip.path.length && dataResponse.oneWayTrip.path.length > 1));
        // console.log("-----dataResponse.returnTrip------",!(dataResponse.returnTrip &&  dataResponse.returnTrip.path && dataResponse.returnTrip.path.length && dataResponse.returnTrip.path.length > 1));
        
        // let oneside = !(dataResponse.oneWayTrip && dataResponse.oneWayTrip.path && dataResponse.oneWayTrip.path.length && dataResponse.oneWayTrip.path.length > 1);
        // let returnSide = !(dataResponse.returnTrip &&  dataResponse.returnTrip.path && dataResponse.returnTrip.path.length && dataResponse.returnTrip.path.length > 1);
        // let result = oneside && returnSide 
        // if (oneside && returnSide) {
        //     toastr.error("No record found, Select another criteria");
        //     await this
        //     .props
        //     .actions
        //     .RemoveTripDetails();
        //     this.props.closePopup();
        //     this.setState({ ...this.state, playbackStatus: null, currentIndex: 0, isReturnSide:false});
        //     return;
        // }

        let geoData=[] ;
        if (dataResponse && dataResponse.geofencesArea){
            geoData = dataResponse.geofencesArea
        }
        let mergedGeofences;
        // if (dataResponse.oneWayTrip && dataResponse.geofencesArea){
        //   geoData = dataResponse.geofencesArea
        // }
        mergedGeofences = geoData;

        // let geoData1 = {};        

        // let mergedGeofences; 
        // if(dataResponse.returnTrip && dataResponse.returnTrip.geofencesArea)
        // {
        //     let geoData1 = dataResponse.returnTrip.geofencesArea;
        //     mergedGeofences = _.merge(geoData, geoData1);
        // }
        // else {
        //     mergedGeofences = geoData;
        // }

       const uniqueGeoFences = _.uniqBy(mergedGeofences, '_id')

        
        //const dataResponse = await this.props.actions.getTrackPoints(JSON.stringify({ interval }), row.vehicleId) || {};
        this.setState({ 
            ...this.state,
            //responseData: dataResponse,
            filteredData : dataResponse,
            tripPath : dataResponse.path,
            geofencesArea : mergedGeofences
        });

        let _path = this.state.tripPath[this.state.currentChunkToRender] || [];
        if (!_path.length) {
            toastr.error("No record found, Select another criteria");
        } else {
            const path = _path.map(point => point.latlng);

            const {
                geofences = [],
                geofencesArea = [],
                distance = 0,
                counts: voilations = {},
                geoFenceCounts = {},
                //fatigueList: _fatigueList = [],

                eventsListing: {
                    HARSH_ACCELERATION: _harshAcceleration = [],
                    HARSH_TURN: _harshTurn = [], 
                    SPEED_LIMIT: _overspeeding = [],
                    HARSH_BRAKES: _harshBrakes = [],
                    IGNITION_OFF: _ignitionOff = [],
                    IGNITION_ON: _ignitionOn = [],
                    FATIGUE: _fatigueList = [],
                    REST_AFTER_FATIGUE: _restAfterFatigue = [],
                } = {},
                eventsListing: voilationListTypes = [],
                duration = 0,
                idleTime = 0,
                idle = [],
                averageSpeed = 0,
                fuelConsumed = 0,
                stops = [],
            } = this.state.filteredData;

            const mapCoordinates = acc => {
                const [lat, lng] = acc.latlng.split(',');
                return { ...acc, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) } }
            }
            const harshAcceleration = _harshAcceleration.map(mapCoordinates);
            const harshTurn = _harshTurn.map(mapCoordinates);
            const ignitionOff = _ignitionOff.map(mapCoordinates);
            const ignitionOn = _ignitionOn.map(mapCoordinates);
            const overspeeding = _overspeeding.map(mapCoordinates);
            const harshBrakes = _harshBrakes.map(mapCoordinates);
            const fatigueList = _fatigueList.map(mapCoordinates);
            const restAfterFatigue = _restAfterFatigue.map(mapCoordinates);

            const cellSiteCoordinates = point => {
                if(point.eventType === 'ENTERING PATROLLING CELLSITE'){
                    const {lat, lng} = point.latlng;
                    return {...point, latlngCords: { lat: parseFloat(lat), lng: parseFloat(lng) }}
                }
                return;
            }
            const cellSitePoints = _path.filter(point => point.eventType === 'ENTERING PATROLLING CELLSITE').map(cellSiteCoordinates);    
            this.setState({
                ...this.state,
                path,
                completePath: _path[this.state.currentChunkToRender],
                loading: false,
                loadMap: true,
                geofences: this.state.filteredData.geofences,
                geofencesArea,
                distance,
                harshAcceleration,
                harshTurn,
                ignitionOff,
                ignitionOn,
                overspeeding,
                harshBrakes,
                voilations,
                voilationListTypes,
                geoFenceCounts,
                duration,
                idleTime,
                idle,
                averageSpeed,
                fuelConsumed,
                stops,
                fatigueList,
                restAfterFatigue,
                currentIndex: 0,
                position: path[0],
                angle: 180,
                enterCellSite: cellSitePoints
            });
        }
    }

    changeViolationData(data) {

        let voilationListTypes = Object.assign({}, this.state.voilationListTypes);
        if (data.type === "harshAcceleration") {

            voilationListTypes.HARSH_ACCELERATION = this.state.enableHarshAcceleration ? [] : this.state.voilationListTypes.HARSH_ACCELERATION

            this.setState({
                ...this.state,
                voilationListTypes,
                enableHarshAcceleration: !this.state.enableHarshAcceleration
            })

        }
        else if (data.type === "harshBrakes") {

            voilationListTypes.HARSH_BRAKES = this.state.enableHarshBrakes ? [] : this.state.voilationListTypes.HARSH_BRAKES

            this.setState({
                ...this.state,
                voilationListTypes,
                enableHarshBrakes: !this.state.enableHarshBrakes
            })
        }
        else if (data.type === "harshTurn") {

            voilationListTypes.HARSH_TURN = this.state.enableHarshTurn ? [] : this.state.voilationListTypes.HARSH_TURN

            this.setState({
                ...this.state,
                voilationListTypes,
                enableHarshTurn: !this.state.enableHarshTurn
            })

        }
        else if (data.type === "overSpeeding") {

            voilationListTypes.SPEED_LIMIT = this.state.enableOverSpeeding ? [] : this.state.voilationListTypes.SPEED_LIMIT

            this.setState({
                ...this.state,
                voilationListTypes,
                enableOverSpeeding: !this.state.enableOverSpeeding
            })
        }
    }

    changePlaybackSpeed = (playbackSpeed) => {
        this.setState({ ...this.state, playbackSpeed });
    }
    playbackRowSelected = (index) => {
        const currentIndex = index;
        const position = this.state.path[currentIndex];
        const { angle } = this.state.tripPath[this.state.currentChunkToRender][currentIndex];
        this.setState({ ...this.state, currentIndex: currentIndex, position, angle });
    }
    animate = () => {
        if (this.state.playbackStatus === 'playing') {
            const { currentIndex } = this.state;
            const nextIndex = currentIndex + 1;
            const {length} = this.state.tripPath[this.state.currentChunkToRender]
            if(length===nextIndex){
                return this.setState({...this.state,playbackStatus:'stop'});
            }
            const position = this.state.path[nextIndex];
            const { angle } = this.state.tripPath[this.state.currentChunkToRender][nextIndex];
            const SKIP = 4;
            const ROW_HEIGHT = this.tbody.current.clientHeight/this.state.path.length;
            if (nextIndex === SKIP) {
                this.gridElement.current.scrollTo({ top: (ROW_HEIGHT * (nextIndex + 1)), behavior: 'smooth' });
            } else if (nextIndex > 5 && nextIndex % 5 === 4) {
                this.gridElement.current.scrollTo({ top: (ROW_HEIGHT * (nextIndex + 1)), behavior: 'smooth' });
            }

            setTimeout(() => {
                this.setState({ ...this.state, currentIndex: nextIndex, position, angle });
                setTimeout(() => {
                    this.animate();
                }, Math.round(600 / this.state.playbackSpeed.value));
            }, 10);
        }
    }
    playPause = () => {
        const { playbackStatus } = this.state;
        if (!playbackStatus) {
            this.setState({ ...this.state, playbackStatus: 'playing' });
        }
        if (playbackStatus === 'playing') {
            this.setState({ ...this.state, playbackStatus: 'stop' });
        } else {
            this.setState({ ...this.state, playbackStatus: 'playing' });
        }
        setTimeout(() => { this.animate() }, 100);

    }
    resetPlayback = async() => {
        const position = this.state.path[0];
        const { angle } = this.state.tripPath[this.state.currentChunkToRender][0];
        this.gridElement.current.scrollTo({ top: 0, behavior: 'smooth' });
        await this.setState({ ...this.state, playbackStatus: null, currentIndex: 0 });
        await this.setState({ ...this.state, position, angle});


    }

    componentWillUnmount() {

     // this.setState({...this.state, currentChunkToRender : 1});
        this
            .props
            .actions
            .RemoveTripDetails();
    }
    async navigateToTrips() {
        await this
            .props
            .actions
            .RemoveTripDetails();
        this.props.closePopup();
        this.gridElement.current.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState({ ...this.state, playbackStatus: null, currentIndex: 0, isReturnSide:false, displayMap:false, isMapRenderable:false, currentChunkToRender : 0});
    }
    render() {
        const loader = <Spinner key="1" />;
        // console.log("IsReturing", this.state.isReturnSide);
        // console.log("Filtered Data", this.state.filteredData)
        // console.log("Current Chunk: ", this.state.currentChunkToRender);
        // console.log("Current Chunk Path: ", this.state.filteredData && this.state.filteredData[this.state.currentChunkToRender]);

        // // console.log("this.state.displayMap", this.state.displayMap);
        // console.log("isMapRenderable", this.state.isMapRenderable);

        return (

            <Modal visible={this.props.mapVisible} dialogClassName='distancetrip-modal-width' >
                <div className="popubtnparent">
                    <div>
                    </div>
                    <div className="popup-close-btn closeBtn">
                        <button type="button" className="close" aria-label="Close" 
                        onClick={this.navigateToTrips.bind(this)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                    {this.state.loading ? [loader] : <div>
                        {this.state.loadMap ? <div style={{ "width" : "98%", "margin" : "auto" }}>
                            <div className="row" id="map-id">
                                <div className="col-md-8">
                                  <TripMap
                                    height="40vh"
                                    playbackStatus={this.state.playbackStatus}
                                    position={this.state.position}
                                    angle={this.state.angle}
                                    path={this.state.path}
                                    distance={this.state.distance}
                                    geofencesArea={this.state.geofencesArea}
                                    harshAcceleration={this.state.filteredData.enableHarshAcceleration ? this.state.filteredData.harshAcceleration : []}
                                    overSpeeding={this.state.filteredData.enableOverSpeeding ? this.state.filteredData.overspeeding : []}
                                    harshTurn={this.state.enableHarshTurn ? this.state.harshTurn : []}
                                    harshBrakes={this.state.enableHarshBrakes ? this.state.harshBrakes : []}
                                    enterCellSite={this.state.enterCellSite || []}
                                    ignitionOn={this.state.filteredData.eventsListing.IGNITION_ON || []}
                                    ignitionOff={this.state.filteredData.eventsListing.IGNITION_OFF|| []}
                                    stops={this.state.filteredData.stops}
                                    idle={this.state.filteredData.idle}
                                />
                                    {/* {this.state.displayMap ? (
                                        this.state.isMapRenderable ?
                                        <TripMap
                                            height="40vh"
                                            playbackStatus={this.state.playbackStatus}
                                            position={this.state.position}
                                            angle={this.state.angle}
                                            path={this.state.path}
                                            distance={this.state.distance}
                                            geofencesArea={this.state.geofencesArea}
                                            harshAcceleration={this.state.filteredData.enableHarshAcceleration ? this.state.filteredData.harshAcceleration : []}
                                            overSpeeding={this.state.filteredData.enableOverSpeeding ? this.state.filteredData.overspeeding : []}
                                            harshTurn={this.state.enableHarshTurn ? this.state.harshTurn : []}
                                            harshBrakes={this.state.enableHarshBrakes ? this.state.harshBrakes : []}
                                            enterCellSite={this.state.enterCellSite || []}
                                            ignitionOn={this.state.filteredData.eventsListing.IGNITION_ON || []}
                                            ignitionOff={this.state.filteredData.eventsListing.IGNITION_OFF|| []}
                                            stops={this.state.filteredData.stops}
                                            idle={this.state.filteredData.idle}
                                        />
                                    :
                                    <p
                                        style={{
                                            color: 'red', // set the warning text color
                                            fontSize: '18px', // set the warning text font size
                                            textAlign: 'center', // center align the warning text
                                            paddingTop: '10em'
                                        }}
                                    >
                                        Unable to Render Map due to Browser Memory Limitation.
                                    </p>
                                    ) : null    
                                } */}
                                </div>
                                <div className="col-md-4">
                                    <div className="trip-detail-voilations" style={{ height: '40vh', overflowY: 'scroll', border: '1px solid #F1F1F1', margin: '2px' }}>
                                        <div className="voilations-section ">
                                            <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshAcceleration" })}>
                                                <input type="checkbox" checked={this.state.enableHarshAcceleration} onChange={() => this.changeViolationData.bind(this, { type: "harshAcceleration" })} onClick={() => this.changeViolationData.bind(this, { type: "harshAcceleration" })} value={1} />
                                                <label htmlFor="checkbox" className="red-bg-bd" />
                                            </div>
                                            <div className="col">
                                                <span className="pull-left voilation-name">Harsh Acceleration</span>
                                                <span className="pull-right voilation-num">{this.state.voilations.HARSH_ACCELERATION || 0} times</span>
                                            </div>
                                        </div>
                                        <div className="voilations-section">
                                            <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshBrakes" })}>
                                                <input type="checkbox" checked={this.state.enableHarshBrakes} onChange={() => this.changeViolationData.bind(this, { type: "harshBrakes" })} onClick={() => this.changeViolationData.bind(this, { type: "harshBrakes" })} value={1} />
                                                <label htmlFor="checkbox" className="orange-bg-bd" />
                                            </div>
                                            <div className="col">
                                                <span className="pull-left voilation-name">Sudden Brake</span>
                                                <span className="pull-right voilation-num">{this.state.voilations.HARSH_BRAKES || 0} times</span>
                                            </div>
                                        </div>
                                        <div className="voilations-section">
                                            <div className="round" onClick={this.changeViolationData.bind(this, { type: "harshTurn" })}>
                                                <input type="checkbox" checked={this.state.enableHarshTurn} onChange={() => this.changeViolationData.bind(this, { type: "harshTurn" })} onClick={() => this.changeViolationData.bind(this, { type: "harshTurn" })} value={1} />
                                                <label htmlFor="checkbox" className="blue-bg-bd" />
                                            </div>
                                            <div className="col">
                                                <span className="pull-left voilation-name">Sharp Turn</span>
                                                <span className="pull-right voilation-num">{this.state.voilations.HARSH_TURN || 0} times</span>
                                            </div>
                                        </div>
                                        <div className="voilations-section">
                                            <div className="round" onClick={this.changeViolationData.bind(this, { type: "overSpeeding" })}>
                                                <input type="checkbox" checked={this.state.enableOverSpeeding} onChange={() => this.changeViolationData.bind(this, { type: "overSpeeding" })} onClick={() => this.changeViolationData.bind(this, { type: "overSpeeding" })} value={1} />
                                                <label htmlFor="checkbox" className="purple-bg-bd" />
                                            </div>
                                            <div className="col">
                                                <span className="pull-left voilation-name">Overspeeding</span>
                                                <span className="pull-right voilation-num">{this.state.voilations.SPEED_LIMIT || 0} times</span>
                                            </div>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Unfasten Seatbelt</span>
                                            <span className="pull-right voilation-num">{this.state.voilations.UNFASTEN_SEATBELT || 0} times</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Ignition</span>
                                            <span className="pull-right voilation-num">{this.state.voilations.IGNITION_ON || 0} times</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">GeoFence Entry</span>
                                            <span className="pull-right voilation-num">{this.state.geoFenceCounts.VEHICLE_ENTERING || 0} times</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">GeoFence Exit</span>
                                            <span className="pull-right voilation-num">{this.state.geoFenceCounts.VEHICLE_LEAVING || 0} times</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Fatigue</span>
                                            <span className="pull-right voilation-num">{this.state.fatigueList.length} times</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Stops</span>
                                            <span className="pull-right voilation-num">{this.state.stops.length || "N/A"} </span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Distance</span>
                                            <span className="pull-right voilation-num">{this.state.distance || 0} KM</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Idle Time</span>
                                            <span className="pull-right voilation-num">{this.state.idleTime || 0} Min</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Idle Count</span>
                                            <span className="pull-right voilation-num">{this.state.idle.length || 0}</span>
                                        </div>
                                        <div className="voilations-info-section">
                                            <span className="pull-left voilation-name">Average Speed</span>
                                            <span className="pull-right voilation-num">{this.state.averageSpeed ? this.state.averageSpeed + ' KM/H' : "N/A"} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-3">
                                    <label >Speed</label>
                                    <Select
                                        name="form-field-name"

                                        isClearable={false}
                                        isSearchable={false}
                                        placeholder="Select Speed"
                                        defaultValue={this.state.playbackSpeed}
                                        onChange={this.changePlaybackSpeed}
                                        options={[
                                            { value: 1, label: '1x' },
                                            { value: 2, label: '2x' },
                                            { value: 4, label: '4x' },
                                            { value: 6, label: '6x' },
                                        ]} />

                                </div>
                                <div className="col-md-2">
                                    <button
                                        className="btn auxo-primary-btn"
                                        style={{ marginTop: '25px', width: '150px' }}
                                        onClick={this.playPause}
                                    >{this.state.playbackStatus === 'playing' ? 'Pause' : 'Play'}</button>

                                </div>
                                <div className="col-md-2">
                                    <button
                                        className="btn auxo-primary-btn"
                                        style={{ marginTop: '25px'}}
                                        onClick={this.resetPlayback}
                                    >Reset</button>

                                </div>
                            </div>
                            <div className='row text-center'>
                              <div className="col-md-1 toggle-btns">
                                <div className="button-container">
                                  <button className="btn auxo-primary-btn square-button" 
                                    onClick={this.prevChunk.bind(this)}
                                    disabled={this.state.currentChunkToRender === 0}>
                                      {'<<'}
                                  </button>
                                </div>
                              </div> 
                              <div className="col-md-1 toggle-btns">
                                <div className="button-container">
                                  <button className="btn auxo-primary-btn square-button" 
                                    onClick={this.nextChunk.bind(this)}
                                    disabled={this.state.currentChunkToRender === this.state.tripPath.length - 1}>
                                      {'>>'}
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-2 text-center d-flex indexes-center">
                                <p className='indexes-center' >
                                  {`${this.state.currentChunkToRender+1} of ${this.state.tripPath.length}` }
                                </p>
                              </div>
                              {/* <div className="col-md-2 text-center d-flex indexes-center">
                                <button onClick={this.handleSaveMap}>Save Map</button>
                              </div> */}
                            </div>
                            {/* <div className="row text-center">
                              <div className="col-md-6">
                                <div className="button-container">
                                  <div className="col-md-1"> 
                                    <button className="btn auxo-primary-btn square-button" onClick={this.prevChunk.bind(this)} disabled={this.state.currentChunkToRender === 0}>
                                      {'<<'}
                                    </button>
                                  </div>
                                  
                                  <p>{`${this.state.currentChunkToRender + 1} of ${this.state.tripPath.length}`}</p>
                                  <div className="col-md-1"> 
                                    <button className="btn auxo-primary-btn square-button" onClick={this.nextChunk.bind(this)} disabled={this.state.currentChunkToRender === this.state.tripPath.length - 1}>
                                      {'>>'}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className='row text-center '>
                              <div className="col-md-2">
                                <p className='text-center d-flex align-items-center' style={{margin: '10px'}}>
                                  {`${this.state.currentChunkToRender+1} of ${this.state.tripPath.length}` }
                                </p>
                              </div>
                            </div> */}

                            <div className="row" style={{ marginTop: '5px', height: '40vh', overflowY: 'scroll', }} ref={this.gridElement}>
                                <div className="col-md-12" >
                                    <Table bordered >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date Time</th>
                                                <th>Location</th>
                                                <th>Speed</th>
                                                <th>Angle</th>
                                                <th>Status</th>
                                                <th>Event</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={this.tbody}>
                                            {this.state.tripPath[this.state.currentChunkToRender] && this.state.tripPath[this.state.currentChunkToRender].map((event, index) => {
                                                return (<tr style={{ background: index === this.state.currentIndex ? '#00abce69' : 'none' }} onClick={(event) => this.playbackRowSelected(index, event)} >
                                                    <td style={{height:'42px'}}><span style={{height:'42px'}}>{index + 1}</span></td>
                                                    <td style={{height:'42px'}}>{moment(event.time, 'MM/DD/YYYY HH:mm:SS').format('DD-MM-YYYY HH:mm:SS')}</td>
                                                    <td style={{height:'42px'}}>{this.state.companyId !== '5f9fe73eb44c0b1a122a38fd' ? event.latlng ? `${event.latlng.lat},${event.latlng.lng}` : '': event.address}</td>
                                                    <td style={{height:'42px'}}>{event.speed}</td>
                                                    <td style={{height:'42px'}}>{event.angle}</td>
                                                    <td style={{height:'42px'}}>{event.status}</td>
                                                    <td style={{height:'42px'}}>{event.eventType}</td>
                                                </tr>);
                                            })}


                                        </tbody>
                                    </Table>
                                </div>

                            </div>
                        </div> : <div></div>}


                    </div>}
            </Modal>

        );
    }
}

MapView.propTypes = {};
MapView.defaultProps = {};
const mapStateToProps = state => ({ 
    summaryMap: state.Reports.summaryMap,
    permissions: state.permissions
 });

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Things.creators, dispatch),
    reportsActions: bindActionCreators(Reports.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MapView);
