import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import Reports from '../../../ducks/Reports';
import { ReportsListing } from './reportsList';
import ReportInterval from './interval';
import forwardIcon from '../../../assets/img/ic_forward.png';


class PatrollingReportsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reportType: '',
      alarmTypes: [],
      eventTypeList: [],
      step: 1,
      interval: {},
      cellSites: [],
      isRoster: this.props.match.params.reports
    };
    this.scheduleReportClickHandler = this.scheduleReportClickHandler.bind(this);
  }
  async selectReportType(reportType) {
    this.setState(prevState => ({ ...prevState, reportType, step: 2, }));
  }


  switchStep(step, cellSites, interval) {
    if (step === 1) {
      this.setState({
        ...this.state,
        reportType: '',
        alarmTypes: [],
        eventType: [],
        step: 1,
        columns: [],
        interval: {},
        cellSites: [],
        geofences: [],
      })
    }
    else this.setState(prevState => ({ ...prevState, step, interval, cellSites }));
  }

  async selectCellSitesAndRange(cellSites, things, interval) {

    if (this.state.reportType === 'rosterLog' && (!things || !things.length)) {
      return toastr.error('Please select vehicles');
    }
    // if (this.state.reportType === 'rescueCellsite' && (!cellSites || !cellSites.length)) {
    //   return toastr.error('Please select cellsites.')
    // }
    this.setState({ ...this.state, interval, cellSites });
    await this.props.actions.exportReport({
      cellSites,
      things,
      interval,
      reportType: this.state.reportType
    });

    toastr.success('Report Exported Successfully.')
  }



  scheduleReportClickHandler() {
    this
      .props
      .history
      .push('/report-schedule');
  }

  render() {
    let componentToRender = (<div></div>);

    if (this.state.step === 1) {
      componentToRender = (
        <ReportsListing selectType={this.selectReportType.bind(this)} reports={this.props.permissions.companyReports} isRoster={this.state.isRoster === 'reports'}/>
      );
    }
    else if (this.state.step === 2) {
      componentToRender = (
        <ReportInterval
          selectCellSitesAndRange={this.selectCellSitesAndRange.bind(this)}
          reportType={this.state.reportType}
          switchStep={this.switchStep.bind(this)}
          interval={this.state.interval}
          vehicles={this.state.vehicles}
        />
      );
    }


    return (
      <div>
        <div className='reports-header'><span className='headerchip export'>Export</span>
          <span className='selected-color headerchip'>Report type</span>
          <span className='headerchip'><img alt='' src={forwardIcon} /></span>
          <span className={`headerchip ${this.state.step === 2 ? 'selected-color' : ''}`}>Interval</span>

        </div>
        {componentToRender}
      </div>
    );
  }
}

PatrollingReportsList.propTypes = {};
PatrollingReportsList.defaultProps = {};

const mapStateToProps = state => ({ permissions: state.permissions });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(Reports.creators, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(PatrollingReportsList)
