import React, { Component } from 'react';
import { connect } from "react-redux";
import Things from "../../../ducks/Things";
import { bindActionCreators } from "redux";
import { Popover, PopoverBody, UncontrolledCollapse, UncontrolledTooltip } from 'reactstrap';
import * as _ from 'lodash';
import "./index.css"
import trail from "../../../assets/img/trail.png";
import startPoint from "../../../assets/img/startPoint.png";
import endPoint from '../../../assets/img/endPoint.png';
import forwardIcon from '../../../assets/img/ic_forward.png';
import moment from 'moment';
import { MapWithAMarkerClusterer } from '../../common/maps/Maps';
import Select from 'react-select-nested-group';
import { Spinner } from '../../common/Spinner/index';
import TripMapView from '../tripMapView/index';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../../assets/img/calender.png';
import dropdownIcon from '../../../assets/img/ic_dropdown_down.png';
import { subDays } from 'date-fns';
import MapModal from '../../common/maps/MapModal';

class VehicleTrips extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingRecords: true,
      loadingPlateNumber: true,
      loading: false,
      vehicleNumberPlate: "",
      tripId: null,
      trips: [],
      tripVisible: false,
      thingId: "",
      dateRangePicker: {
        selection: {
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
          key: 'selection',
        },
      },
      child: {},
      popoverOpen: false,
      sort: -1,
      sortingData: false,
      showModal: false,
      lat: 0,
      lng: 0
    }
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {

    await this
      .props
      .actions
      .getVehiclesPlateNumber();
    if (this.props.vehiclesPlateNumber.length) {
      await this.setState({
        ...this.state,
        thingId: this.props.vehiclesPlateNumber[0]._id,
        isLoadingRecords: true,
        loadingPlateNumber: false,
      });
      this.handlePlateNumberChange({ value: this.props.vehiclesPlateNumber[0]._id })
    } else {
      this.setState({
        ...this.state,
        loadingPlateNumber: false,
        isLoadingRecords: false,
        loading: false
      });
    }
  }

  async showTripDetails(data) {

    await this.setState({
      ...this.state,
      tripId: data.tripId,
      tripVisible: true
    });
    this.child.loadData()
  }

  closePopup() {
    this.setState({
      ...this.state,
      tripVisible: false
    });
  }

  componentWillUnmount() {
    this.props.actions.disconnectMqtt();
    this.props.actions.clearVehicleDetails();
    this.props.actions.clearTrips();
  }

  async loadTrips(loadMore, sortByDate) {

    let sort = this.state.sort;
    await this.setState({
      ...this.state,
      sortingData: true

    });
    if (sortByDate) {
      sort = sort === 1 ? -1 : 1;
    }
    await this
      .props
      .actions
      .getVehicleTrips({ thingId: this.state.thingId, loadMore, interval: this.state.dateRangePicker.selection, sort });

    await this.setState({
      ...this.state,
      sortingData: false,
      sort,
      trips: this.props.vehicleTrips,
      isLoadingRecords: false,
      loading: false
    });
  }

  openDayTrips(tripId) {
    let trips = _.map(this.state.trips, item => {
      if (item._id === tripId) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });

    this.setState({
      ...this.state,
      trips
    });
  }

  async handleRangeChange(which, payload) {
    await this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
    console.log("ehich" , which , payload)
  }
  async filterTrips() {
    this.loadTrips(false, false)
  }

  handlePlateNumberChange = async (selectedOption) => {
    let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    const thingId = selectedOption ? selectedOption.value : null;
    if (thingId) {
      await this.setState({
        ...this.state,
        isLoadingRecords: true,
        loading: true,
        vehicleNumberPlate: selectedOption
          ? selectedOption.value
          : "",
        thingId
      });
      this.props.actions.disconnectMqtt();
      this.props.actions.clearVehicleDetails();

      this
        .props
        .actions
        .GetVehicleDetails({ id: thingId });

      this
        .props
        .actions
        .getVehicleMqtt({ groupId: companyInfo.groupId, thingId });

      this.loadTrips(false, false)

    }

  };

  async getTrips() {
    this.loadTrips(true, false)
  }

  toggle = () => {
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen
    });
  };

  onCenterChanged(map) {
  }

  showModal(latLng){
    const lat = latLng.split(",")[0];
    const lng = latLng.split(",")[1];
    this.setState({
      ...this.state,
      lat: lat,
      lng: lng,
      showModal: true
    })
  }

  closeModal(){
    this.setState({
      ...this.state,
      showModal: false
    })
  }

  render() {
    let loader = <Spinner key="1" />;
    const showContent = this.props.vehicleDetail && this.props.vehicleDetail.plateNumber;
    let notFound = "";
    if (!this.state.isLoadingRecords && this.props.vehicleTrips.length === 0 && showContent && !this.state.sortingData) {
      notFound = (
        <div className="no-data-found">
          <h2>No trips found!</h2>
        </div>
      )
    }
    const vehicleDetail = this.props.vehicleDetail;
    const defaultValue = {
      value: this.props.vehiclesPlateNumber.length ? this.props.vehiclesPlateNumber[0]._id : '',
      label: this.props.vehiclesPlateNumber.length ? this.props.vehiclesPlateNumber[0].plateNumber : ""
    };
    const {companyId} = JSON.parse(localStorage.companyInfo);

    return (
      <div>
        <TripMapView
          tripId={this.state.tripId}
          tripVisible={this.state.tripVisible}
          onRef={ref => (this.child = ref)}
          closePopup={this.closePopup.bind(this)}
        />
       <MapModal showMap={this.state.showModal} close={this.closeModal} lat={this.state.lat} lng={this.state.lng} />

        {
          this.state.loadingPlateNumber ?
            [loader] :
            (
              <div className="col-lg-4 col-md-4 col-sm-4">
                {
                  this.props.vehiclesPlateNumber.length > 0
                    ? (
                      <div className='form-group'>
                        <Select
                          name="form-field-name"
                          isClearable={false}
                          isSearchable={true}
                          placeholder="Select Vehicle"
                          defaultValue={defaultValue}
                          onChange={this.handlePlateNumberChange}
                          options={this.props.vehiclesPlateNumber && this.props.vehiclesPlateNumber.map(
                            item => ({
                              value: item._id,
                              label: item.plateNumber
                            })
                          )}
                        />
                      </div>
                    )
                    : ''
                }
              </div>
            )
        }
        {this.state.loading ? [loader] :
          <div>{this.props.vehiclesPlateNumber.length === 0 && !this.state.loadingPlateNumber && !this.state.loadingPlateNumber && !this.state.loading ?
            <div className="no-data-found">
              <h2>No trips created yet!</h2>

            </div> : <div>
              {showContent ? <div className="vehicle-plate-Number-model">
                <p className="vehicle-plate-number">{this.props.vehicleDetail.plateNumber || "N/A"}</p>
                <p className="vehicle-model">{this.props.vehicleDetail.vehicleMakeAndModel || 'N/A'}</p>

              </div> : ""
              }
              <div>
                {vehicleDetail._id ? <MapWithAMarkerClusterer
                  markers={[{
                    _id: 1,
                    latitude: parseFloat(vehicleDetail.lastLocation.lat) || 33.6844,
                    longitude: parseFloat(vehicleDetail.lastLocation.lng) || 73.0479,
                    angle: parseInt(vehicleDetail.angle),
                    noInfo: true,
                    plateNumber: vehicleDetail.plateNumber,
                    vehicleStatus: vehicleDetail.vehicleStatus,
                  }
                  ]}
                  onCenterChanged={this.onCenterChanged.bind(this)}
                  onVehicleClick={() => {
                  }}
                  zoom={15}
                  center={{
                    lat: vehicleDetail.lastLocation.lat || 33.6844,
                    lng: vehicleDetail.lastLocation.lng || 73.0479,
                  }}
                  defaultCenter={{
                    lat: vehicleDetail.lastLocation.lat || 33.6844,
                    lng: vehicleDetail.lastLocation.lng || 73.0479,
                  }} /> : ""}

              </div>
              <div className="mask">
                <div className="">
                  {showContent ?
                    <div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <h1 className="trip-log">Trip Log</h1>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2">
                          <div className="selected-trip-Interval margin-top20">
                            <p>
                              <b>From:</b> {moment(this.state.dateRangePicker.selection.startDate).format('MMMM Do YYYY')}
                            </p>
                            <p><b>To:</b> {moment(this.state.dateRangePicker.selection.endDate).format('MMMM Do YYYY')}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-1">
                          <div className="margin-top20">
                            <a onClick={this.toggle} id="Popover1">
                              <img src={calenderIcon} alt="" />
                            </a>
                            <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1"
                              toggle={this.toggle.bind(this)}>
                              <PopoverBody>
                                <div>
                                  <DateRangePicker
                                    onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                                    showSelectionPreview={false}
                                    moveRangeOnFirstSelection={false}
                                    className={'PreviewArea'}
                                    months={2}
                                    ranges={[this.state.dateRangePicker.selection]}
                                    direction="horizontal"
                                    maxDate={new Date()}
                                  />
                                </div>
                              </PopoverBody>
                            </Popover>
                          </div>
                        </div>
                      
                        <div className="col-lg-2 col-md-2 col-sm-2">

                          <button className='btn auxo-primary-btn' onClick={this.filterTrips.bind(this)}>Filter
                         </button>
                      </div>
                      </div>
                      
                    </div>
                    : ""}
                  {notFound}
                </div>

                {!this.state.isLoadingRecords && ((this.props.vehicleTrips.length !== 0) || (this.props.vehicleTrips.length === 0 && this.state.sortingData))
                  ? <div className="trip-table-heading">
                    <p className="width-20">DATE <a onClick={this.loadTrips.bind(this, false, true)}><img
                      className={this.state.sort === 1 ? 'transform-arrow-click' : ''} src={dropdownIcon} alt="" /></a>
                    </p>
                    <p className="width-40">LAST LOCATION</p>
                    <p className="width-20">DISTANCE</p>
                    <p className="width-20">VIOLATIONS</p>
                  </div>
                  : ""}

                {this
                  .state
                  .trips
                  .map((item, index) => {
                    index = "abc" + index + "z";

                    return (

                      <div key={index}>
                        <div className="trip-table-data">
                          <a id={`${index}`} className="trip-date width-20"
                            onClick={this.openDayTrips.bind(this, item._id)}>
                            <img alt="" className={item.isSelected ? 'list-view-transform width18' : 'width18'}
                              src={forwardIcon} /> <p style={{ color: "#000", fontSize: '13px' }}>{item._id}</p>
                          </a>
                          <p className="width-40">{item.lastLocation}</p>
                          <p className="width-20">{item.distance || 0}
                            km</p>
                          <p className="width-20">{item.NumberOfVoilations || 0}</p>
                        </div>
                        <UncontrolledCollapse toggler={`${index}`} className="collapse-nav">
                          {item
                            .trips
                            .map((trip, tripIndex) => {
                              const voilation = _.clone(trip.voilations);

                              tripIndex = "abc" + tripIndex + "z";
                              return (
                                <div className="date-trip-logs" key={tripIndex}>
                                  <div className="tripwidth25">
                                    <div>
                                      <p className="trip-time">
                                        {moment(trip.startTime).format('LT') || 'N/A'}</p>
                                      <p className="tripLocation" style={{color: "#03abce", cursor: "pointer"}} onClick={() => this.showModal(trip.startingCoordinates)}>{trip.startingLocation !== 'N/A' ? trip.startingLocation : trip.startingCoordinates}</p>

                                    </div>
                                  </div>
                                  <div className="tripwidth50">
                                    <a key={index} onClick={this.showTripDetails.bind(this, { tripId: trip._id })}
                                      className="map-view">Map View</a>

                                    <div>
                                      <span className=""><img className="img-responsive" src={startPoint}
                                        alt="" /></span>
                                      <span><img className="img-responsive" src={trail} alt="" /></span>
                                      <span><img className="img-responsive" src={endPoint} alt="" /></span>
                                    </div>
                                    <div className="violations">
                                      <a className="map-view" id={tripIndex + index}>
                                        
                                        Violations</a>
                                  {companyId === '5fd0a1fb6dcde56e0a6c1f9e' &&
                                     <a className="map-view" onClick={() =>{this.props.actions.gensetTempReport(trip._id);}}>
                                             Genset/Temp</a>}
                                                                      
                                    </div>
                                    <UncontrolledTooltip placement="top" target={tripIndex + index}>
                                      Harsh acceleration: {voilation.HARSH_ACCELERATION}<br />
                                      Sudden brake: {voilation.HARSH_BRAKES}<br />
                                      Sharp turn: {voilation.HARSH_TURN}<br />
                                      Overspeeding: {voilation.SPEED_LIMIT}<br />
                                      Unfasten Seatbelt: {voilation.UNFASTEN_SEATBELT}
                                    </UncontrolledTooltip>

                                  </div>
                                  <div className="tripwidth25">
                                    <div>
                                      <p className="trip-time">{moment(trip.endTime).format('LT') || 'N/A'}</p>
                                      <p className="tripLocation" style={{color: "#03abce", cursor: "pointer"}} onClick={() => this.showModal(trip.endingCoordinates)}>{trip.endingLocation !== 'N/A' ? trip.endingLocation : trip.endingCoordinates}</p>

                                    </div>

                                  </div>
                                </div>
                              )
                            })}
                        </UncontrolledCollapse>

                      </div>

                    )
                  })}


              </div>
              {this.props.hasMoreTrips ?
                <div className="loadmore-notifications">
                  <a onClick={this
                    .getTrips
                    .bind(this)}
                  >Load More</a>
                </div>
                : ""}
            </div>}
          </div>
        }


      </div>

    );
  }
}

VehicleTrips.propTypes = {};
VehicleTrips.defaultProps = {};
const mapStateToProps = state => ({
  vehiclesPlateNumber: state.things.vehiclesPlateNumber,
  vehicleTrips: state.things.vehicleTrips,
  vehicleDetail: state.things.vehicleDetail,
  hasMoreTrips: state.things.hasMoreTrips
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleTrips);
