import {isEmpty, trim} from 'lodash';
const passwordValidator = require('password-validator');

 const isEmptyValue = (input) =>{
    if(isEmpty(trim(input))){
        return true;
    }
    return false;
}

function isAlphaNumeric (value)  {
    const pattern = /^[a-zA-Z0-9 -]*$/;
    return pattern.test( value );
}
function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
function isAlpha (value)  {
    const pattern = /^[a-zA-Z -]*$/;
    return pattern.test( value );
}
function isNumeric (value)  {
    const pattern = /^[0-9 -]*$/;
    return pattern.test( value );
}


let schema = new passwordValidator();
// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(16)                                   // Maximum length 16
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().symbols()                                // Must have symbols
.has().digits()                                 // Must have digits
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values
 
export {isEmptyValue, schema, isAlphaNumeric, isAlpha, isNumeric, validateEmail}