export const URL = 'https://apib2b.auxo.com.pk';
export const SOCKET_URL = 'https://socketb2b.auxo.com.pk';
export const PRODUCTION = true;

// //   staging
// export const URL = 'https://apib2bstaging.auxo.com.pk';
// export const SOCKET_URL = 'https://socketb2bStaging.auxo.com.pk';
// export const PRODUCTION = false;

// export const URL = 'http://localhost:8080';
// export const SOCKET_URL = 'http://localhost:8080';
// export const PRODUCTION = false;

export const DVR_ACCOUNT = 'admin';
export const DVR_PASSWORD = 'Alquddoos@321'
export const MVDR_URL = 'https://telenormic.com';
export const apiV1 = '/api/v1';
export const apiV2 = '/api/v2';
export const userCredEncryptionKey = 'aUxO_3@4';
export const socketAuth = 'aUxO_3@4';

export const googleAnalytics = "UA-153479146-1";
