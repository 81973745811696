import * as  R from 'ramda';
import moment from 'moment';
import React from 'react';

const IO_EVENT_MAPPING = {
  a239: 'Ignition',
  a240: 'Movement',
  a80: 'Data_Mode',
  a21: 'GSM_Signal',
  a200: 'Sleep_Mode',
  a69: 'GNSS_Status',
  a181: 'GNSS_PDOP',
  a182: 'GNSS_HDOP',
  a66: 'External_Voltage',
  a24: 'Speed',
  a205: 'GSM_Cell_ID',
  a206: 'GSM_Area_Code',
  a241: 'Active_GSM_Operator',
  a199: 'Trip_Odometer',
  a16: 'Total_Odometer',
  a1: 'Digital_Input_1',
  a9: 'Analog_input_1',
  a179: 'Digital_Output_1',
  a12: 'Fuel_Used_GPS',
  a13: 'Fuel_Rate_GPS',
  a17: 'Axis_X',
  a18: 'Axis_Y',
  a19: 'Axis_Z',
  a11: 'SIM_ICCID_number_part_1',
  a10: 'SD_Status',
  a15: 'Eco_Score',
  a30: 'Number_of_DTC',
  a31: 'Calculated_engine_load_value',
  a32: 'Engine_coolant_temperature',
  a33: 'Short_term_fuel_trim_1',
  a34: 'Fuel_pressure',
  a35: 'Intake_manifold_absolute_pressure',
  a36: 'Engine_RPM',
  a37: 'Vehicle_speed',
  a38: 'Timing_advance',
  a39: 'Intake_air_temperature',
  a40: 'MAF_air_flow_rate',
  a41: 'Throttle_position',
  a42: 'Run_time_since_engine_start',
  a43: 'Distance_traveled_MIL_on',
  a44: 'Relative_fuel_rail_pressure',
  a45: 'Direct_fuel_rail_pressure',
  a46: 'Commanded_EGR',
  a47: 'EGR_error',
  a48: 'Fuel_level',
  a49: 'Distance_ormattin_since_codes_cleared',
  a50: 'Barometric_pressure',
  a51: 'Control_module_voltage',
  a52: 'Absolute_load_value',
  a53: 'Ambient_air_temperature',
  a54: 'Time_run_with_MIL_on',
  a55: 'Time_since_trouble_codes_cleared',
  a56: 'Absolute_fuel_rail_pressure',
  a57: 'Hybrid_battery_pack_remaining_life',
  a58: 'Engine_oil_temperature',
  a59: 'Fuel_injection_timing',
  a60: 'Engine_fuel_rate',
  a175: 'Auto_Geofence',
  a250: 'Trip',
  a255: 'Over_Speeding',
  a251: 'Idling',
  a253: 'Green_driving_type',
  a246: 'Towing',
  a247: 'Crash_detection',
  a248: 'Immobilizer',
  a254: 'Green_driving_value',
  a249: 'Jamming',
  a14: 'SIM_ICCID_number_part_2',
  a67: 'Battery_Voltage',
  a68: 'Battery_Current',
  a252: 'Unplug',
  ts: 'gps_time',
  timestamp: 'haveTimestamp',
  pr: 'priority',
  latlng: 'latlng',
  alt: 'altitude',
  ang: 'angle',
  sat: 'visible_satellites',
  connection_status: 'connection_status',
  a264: 'BarCode Value',
  a331: 'ble1',
};

const getData = (plate) => (state) => {
  let _state = R.path(['_source', 'state'])(state)
  // if (_state.tcxn) {
  //   _state = { connection_status: _state.tcxn.connection_status };
  // }
  if(_state.tcxn){
    _state.connection_status = _state.tcxn.connection_status;
    delete _state.tcxn;
  }
  const pairedState = R.toPairs(_state);
  const replaceKeysMap = (obs) => {
    const [key, value] = obs;
    return [IO_EVENT_MAPPING[key] || key, value];
  }
  const mappedState = R.map(replaceKeysMap)(pairedState);
  const obs = R.fromPairs(mappedState);
  const withDefaultValue = R.defaultTo('N/A');
  // const timestamp = moment(state['_source']['timestamp']).format('DD/MM/YYYY hh:mm:ss A');
  const gpsTime = obs['gps_time'] ? moment(obs['gps_time']).format('DD/MM/YYYY hh:mm:ss A') : 'N/A';
  const withTruthyValue = (value) => {
    if (value === 'N/A') return value;
    if (value === 1) return 'Yes';
    return 'No';
  }
  const withDefaultTruthyValue = R.compose(withTruthyValue, withDefaultValue);
  const withGreenType = (type) => {
    if (type === 'N/A') return type;
    if (type === 1) return 'Acceleration';
    if (type === 2) return 'Brakes';
    if (type === 3) return 'Turn';
  }
  const withDefaultGreenValue = R.compose(withGreenType, withDefaultValue);

  // const latlng = <a
  //       href={`https://www.google.com/maps/dir/${obs['latlng']}`}
  //       target="_blank"
  //       rel="noopener noreferrer">
  //      {withDefaultValue(obs['latlng'])}
  //     </a>;
  const latlng = withDefaultValue(obs['latlng']);
  // { name: 'Device Time' },
  // { name: 'Platform Time' },
  // { name: 'latlng' },
  // { name: 'Ignition' },
  // { name: 'Movement' },
  // { name: 'External_Voltage (V)' },
  // { name: 'Speed (KM/H)' },
  // { name: 'Trip' },
  // { name: 'Idling' },
  // { name: 'Green_driving_type' },
  // { name: 'Green_driving_value' },
  // { name: 'Battery_Voltage (V)' },
  // { name: 'Unplug' },
  const values = {
    gpsTime,
    plate,
    latlng,
    address: withDefaultValue(obs['address']),
    ignition: withDefaultTruthyValue(obs['Ignition']),
    movement: withDefaultTruthyValue(obs['Movement']),
    gsmSignal: withDefaultValue(obs['GSM_Signal']),
    visibleSatellites: withDefaultValue(obs['visible_satellites']),
    externalVoltage: withDefaultValue(obs['External_Voltage'] / 1000),
    speed: withDefaultValue(obs['Speed']),
    trip: withDefaultTruthyValue(obs['Trip']),
    idling: withDefaultTruthyValue(obs['Idling']),
    greenDrivingType: withDefaultValue(obs['Green_driving_type']),
    greenDrivingValue: withDefaultGreenValue(obs['Green_driving_value']),
    batteryVoltage: withDefaultValue(obs['Battery_Voltage'] / 1000),
    unplug: withDefaultTruthyValue(obs['Unplug']),
    barcodeValue: withDefaultValue(obs['BarCode Value']),
    analogInput1: withDefaultValue(obs['Analog_input_1']),
    digitalInput1: withDefaultValue(obs['Digital_Input_1']),
    jamming: withDefaultValue(obs['Jamming']),
    towing: withDefaultValue(obs['Towing']),
    Data_Mode: withDefaultValue(obs['Data_Mode']),
    Sleep_Mode: withDefaultValue(obs['Sleep_Mode']),
    GNSS_Status: withDefaultValue(obs['GNSS_Status']),

    // withDefaultValue(obs['connection_status']),
    // withDefaultValue(obs['Data_Mode']),
    // withDefaultValue(obs['GSM_Signal']),
    // withDefaultValue(obs['Sleep_Mode']),
    // withDefaultValue(obs['GNSS_Status']),
    // withDefaultValue(obs['GNSS_PDOP']/10),
    // withDefaultValue(obs['GNSS_HDOP']/10),

    // withDefaultValue(obs['GSM_Cell_ID']),
    // withDefaultValue(obs['GSM_Area_Code']),
    // withDefaultValue(obs['Active_GSM_Operator']),
    // withDefaultValue(obs['Trip_Odometer']),
    // withDefaultValue(obs['Total_Odometer']/1000),
    // withDefaultValue(obs['Digital_Input_1']),
    // withDefaultValue(obs['Analog_input_1']),
    // withDefaultValue(obs['Digital_Output_1']),
    // withDefaultValue(obs['Fuel_Used_GPS']),
    // withDefaultValue(obs['Fuel_Rate_GPS']/100),
    // withDefaultValue(obs['Axis_X']),
    // withDefaultValue(obs['Axis_Y']),
    // withDefaultValue(obs['Axis_Z']),
    // withDefaultValue(obs['SIM_ICCID_number_part_1']),
    // withDefaultValue(obs['SD_Status']),
    // withDefaultValue(obs['Eco_Score']/10),
    // withDefaultValue(obs['Number_of_DTC']),
    // withDefaultValue(obs['Calculated_engine_load_value']),
    // withDefaultValue(obs['Engine_coolant_temperature']),
    // withDefaultValue(obs['Short_term_fuel_trim_1']),
    // withDefaultValue(obs['Fuel_pressure']),
    // withDefaultValue(obs['Intake_manifold_absolute_pressure']),
    // withDefaultValue(obs['Engine_RPM']),
    // withDefaultValue(obs['Vehicle_speed']),
    // withDefaultValue(obs['Timing_advance']),
    // withDefaultValue(obs['Intake_air_temperature']),
    // withDefaultValue(obs['MAF_air_flow_rate']),
    // withDefaultValue(obs['Throttle_position']),
    // withDefaultValue(obs['Run_time_since_engine_start']),
    // withDefaultValue(obs['Distance_traveled_MIL_on']),
    // withDefaultValue(obs['Relative_fuel_rail_pressure']),
    // withDefaultValue(obs['Direct_fuel_rail_pressure']),
    // withDefaultValue(obs['Commanded_EGR']),
    // withDefaultValue(obs['EGR_error']),
    // withDefaultValue(obs['Fuel_level']),
    // withDefaultValue(obs['Distance_ormattin_since_codes_cleared']),
    // withDefaultValue(obs['Barometric_pressure']),
    // withDefaultValue(obs['Control_module_voltage']),
    // withDefaultValue(obs['Absolute_load_value']),
    // withDefaultValue(obs['Ambient_air_temperature']),
    // withDefaultValue(obs['Time_run_with_MIL_on']),
    // withDefaultValue(obs['Time_since_trouble_codes_cleared']),
    // withDefaultValue(obs['Absolute_fuel_rail_pressure']),
    // withDefaultValue(obs['Hybrid_battery_pack_remaining_life']),
    // withDefaultValue(obs['Engine_oil_temperature']),
    // withDefaultValue(obs['Fuel_injection_timing']),
    // withDefaultValue(obs['Engine_fuel_rate']),
    // withDefaultValue(obs['Geofence_zone_01']),
    // withDefaultValue(obs['Geofence_zone_02']),
    // withDefaultValue(obs['Geofence_zone_03']),
    // withDefaultValue(obs['Geofence_zone_04']),
    // withDefaultValue(obs['Geofence_zone_05']),
    // withDefaultValue(obs['Geofence_zone_06']),
    // withDefaultValue(obs['Geofence_zone_07']),
    // withDefaultValue(obs['Geofence_zone_08']),
    // withDefaultValue(obs['Geofence_zone_09']),
    // withDefaultValue(obs['Geofence_zone_10']),
    // withDefaultValue(obs['Geofence_zone_11']),
    // withDefaultValue(obs['Geofence_zone_12']),
    // withDefaultValue(obs['Geofence_zone_13']),
    // withDefaultValue(obs['Geofence_zone_14']),
    // withDefaultValue(obs['Geofence_zone_15']),
    // withDefaultValue(obs['Geofence_zone_16']),
    // withDefaultValue(obs['Geofence_zone_17']),
    // withDefaultValue(obs['Geofence_zone_18']),
    // withDefaultValue(obs['Geofence_zone_19']),
    // withDefaultValue(obs['Geofence_zone_20']),
    // withDefaultValue(obs['Geofence_zone_21']),
    // withDefaultValue(obs['Geofence_zone_22']),
    // withDefaultValue(obs['Geofence_zone_23']),
    // withDefaultValue(obs['Geofence_zone_24']),
    // withDefaultValue(obs['Geofence_zone_25']),
    // withDefaultValue(obs['Geofence_zone_26']),
    // withDefaultValue(obs['Geofence_zone_27']),
    // withDefaultValue(obs['Geofence_zone_28']),
    // withDefaultValue(obs['Geofence_zone_29']),
    // withDefaultValue(obs['Geofence_zone_30']),
    // withDefaultValue(obs['Geofence_zone_31']),
    // withDefaultValue(obs['Geofence_zone_32']),
    // withDefaultValue(obs['Geofence_zone_33']),
    // withDefaultValue(obs['Geofence_zone_34']),
    // withDefaultValue(obs['Geofence_zone_35']),
    // withDefaultValue(obs['Geofence_zone_36']),
    // withDefaultValue(obs['Geofence_zone_37']),
    // withDefaultValue(obs['Geofence_zone_38']),
    // withDefaultValue(obs['Geofence_zone_39']),
    // withDefaultValue(obs['Geofence_zone_40']),
    // withDefaultValue(obs['Geofence_zone_41']),
    // withDefaultValue(obs['Geofence_zone_42']),
    // withDefaultValue(obs['Geofence_zone_43']),
    // withDefaultValue(obs['Geofence_zone_44']),
    // withDefaultValue(obs['Geofence_zone_45']),
    // withDefaultValue(obs['Geofence_zone_46']),
    // withDefaultValue(obs['Geofence_zone_47']),
    // withDefaultValue(obs['Geofence_zone_48']),
    // withDefaultValue(obs['Geofence_zone_49']),
    // withDefaultValue(obs['Geofence_zone_50']),
    // withDefaultValue(obs['Auto_Geofence']),
    // withDefaultValue(obs['Over_Speeding']),

    // withDefaultValue(obs['Towing']),
    // withDefaultValue(obs['Crash_detection']),
    // withDefaultValue(obs['Immobilizer']),
    // withDefaultValue(obs['Jamming']),
    // withDefaultValue(obs['SIM_ICCID_number_part_2']),
    // withDefaultValue(obs['Battery_Current']),
    // withDefaultValue(obs['haveTimestamp']),
    // withDefaultValue(obs['priority']),
    // withDefaultValue(obs['altitude']),
    // withDefaultValue(obs['angle']),
    // withDefaultValue(obs['visible_satellites']),
  };
  return values;
}

export default getData;

