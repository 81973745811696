import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Things from '../../../ducks/Things';
import Groups from '../../../ducks/Groups';
import PJP from '../../../ducks/PJP';
import { Spinner } from '../../common/Spinner/index';
import { Card, CardBody, CardTitle, CardText, Row, Col, CardHeader } from 'reactstrap';
import PieChartComp from './pieChart';
import SelectComponent from '../../select';
// import ReactToPdf from 'react-to-pdf';

const ref = React.createRef();

let statusData = [
  { name: 'Active', value: 2, fill: '#228B22' },
  { name: 'Inactive', value: 1, fill: '#DC143C' },
];
let violationData = [
  { name: 'Late Entry', value: 2, fill: '#A52A2A' },
  { name: 'Early Exit', value: 1, fill: '#DC143C' },
];

class UniliverDashboard extends Component {

  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    this.state = {
      selectedGroup: [],
      vselectedGroup: [],
      selectedStatus: null,
      isSettingsVisible: false,
      defaultSearchValue: null,
      searchValue: [],
      searchTerm: '',
      loading: true,
      pjpStats: { lateEntry: 0, earlyExit: 0, noEntry: 0 },
      yesterdayActiveInactiveStats: { active: [], inActive: 0 },
      pjpGeneralStats: { pjpCount: 0, hotspotCount: 0, assignedTrikes: 0, unAssignedTrikes: 0 },
      groupId: companyInfo ? companyInfo.groupId : '',
      companyId: companyInfo ? companyInfo.companyId : '',
    };

  }

  async componentDidMount() {

    if (this.state.groupId !== '') {
      if (!(this.props.groupList && this.props.groupList.length)) this.props.dispatch(Groups.creators.getSubGroups(this.state.groupId));
      let payload = [
        this
          .props
          .pjpActions
          .stats(),
        this
          .props
          .actions
          .activeInactiveStats(),
        this
          .props
          .pjpActions
          .generalStats(),
         this
          .props
          .actions
          .getSummaryWidgets()];
      Promise.all(payload).then((values) => {
        const pjpStats = values[0];
        const yesterdayActiveInactiveStats = values[1]; 
        const pjpGeneralStats = values[2]; 
      this.setState({ ...this.state, loading: false, pjpStats, pjpGeneralStats, yesterdayActiveInactiveStats })
  
      });

      
       }
  }

  selectGroupForStatus = async selectedGroup => {
    if (selectedGroup && selectedGroup[0] && selectedGroup[0].value) {
      await this
        .props
        .actions
        .getSummaryWidgets(null, null, selectedGroup[0].value);
    } else {
      await this
        .props
        .actions
        .getSummaryWidgets(null, null, null);
    }
    this.setState({ selectedGroup });
  }
  selectGroupForViolation = async selectedGroup => {
    if (selectedGroup && selectedGroup[0] && selectedGroup[0].value) {
      let pjpStats = await this
        .props
        .pjpActions
        .stats(selectedGroup[0].value);
      this.setState({ ...this.state, loading: false, pjpStats })
    } else {
      let pjpStats = await this
        .props
        .pjpActions
        .stats();
      this.setState({ ...this.state, loading: false, pjpStats })
    }
    this.setState({ vselectedGroup: selectedGroup });
  }

  onLabelClick(status, count, yesterday) {
    if (count === 0) {
      return;
    }
    this.props.history.push(`/listing?status=${status}&count=${count}&yesterday=${yesterday || false}&group=${this.state.selectedGroup[0] ? this.state.selectedGroup[0].value : null}`);
  }
  onViolationsLabelClick(status, count) {
    if (count === 0) {
      return;
    }
    this.props.history.push(`/violations?status=${status}&count=${count}&group=${this.state.vselectedGroup[0] ? this.state.vselectedGroup[0].value : null}`);
  }


  render() {
    let loader = <Spinner key='1' />;
    const { widgets = {} } = this.props;
    const { pjpStats, pjpGeneralStats, yesterdayActiveInactiveStats } = this.state;

    const { vehicleStateSummary = {} } = widgets;

    const {
      DEVICE_UNPLUG: unplugCount = 0,
      IGNITION_OFF: parkedCount = 0,
      DEVICE_PLUG_IN: pluginCount = 0,
      IGNITION_ON: ignitionCount = 0,
      VEHICLE_MOVING: movingCount = 0,
      NEVER_HEARD: neverHeard = 0,
      NOT_RESPONDING: notResponding = 0,
      IDLE: idleCount = 0,
      totalVehicles = 0,
    } = vehicleStateSummary;

    const active = parkedCount + pluginCount + ignitionCount + movingCount + idleCount
    const inActive = notResponding + neverHeard + unplugCount

    statusData[0].value = active;
    statusData[1].value = inActive;
    violationData[0].value = pjpStats.lateEntry || 1;
    violationData[1].value = pjpStats.earlyExit || 1;

    return (
      <div>
        {this.state.loading ? [loader] :
          <>
         
            <div>
              <div className='row'>

                <div className="col-md-8 card-display ">
                  <Card>
                    <CardBody>

                      <CardTitle>

                        <div className="row">
                          <div className="col-md-12">
                            <p className='f-15-px text-align-left m-b-9-px'>Overall Stats</p>
                          </div>

                        </div>
                      </CardTitle>

                      <div className="row m-t-20" style={{ textAlign: 'center' }}>
                        <div className="col-md-3">
                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                            >{pjpGeneralStats.pjpCount}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              PJP's
                        </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                            >{pjpGeneralStats.hotspotCount}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Hotspots
                        </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                            >{pjpGeneralStats.assignedTrikes}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Assigned Trikes
                        </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                            >{pjpGeneralStats.unAssignedTrikes}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Unassigned Trikes
                        </span>
                          </div>
                        </div>


                      </div>


                    </CardBody>
                  </Card>
                </div>



                <div className="col-md-4 card-display ">
                  <Card>
                    <CardBody>

                      <CardTitle>

                        <div className="row">
                          <div className="col-md-12">
                            <p className='f-15-px text-align-left m-b-9-px'>Yesterday's Active Inactive Stats</p>
                          </div>
                        </div>
                      </CardTitle>

                      <div className="row m-t-20" style={{ textAlign: 'center' }}>
                        <div className="col-md-6">
                          <div>
                            <span className="counter f-40-px"
                              onClick={() => this.onLabelClick('active', yesterdayActiveInactiveStats.active.length, true)}
                              style={{ cursor: 'pointer' }}
                            >{yesterdayActiveInactiveStats.active.length}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Active
                        </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <span className="counter f-40-px"
                              onClick={() => this.onLabelClick('inActive', yesterdayActiveInactiveStats.inActive.length, true)}
                              style={{ cursor: 'pointer' }}
                            >{yesterdayActiveInactiveStats.inActive.length}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Inactive
                        </span>
                          </div>
                        </div>



                      </div>


                    </CardBody>
                  </Card>
                </div>




              </div>


              <div className='row'>

                <div className="col-md-6 card-display ">
                  <Card className='height-520'>
                    <CardBody>

                      <CardTitle>

                        <div className="row" style={{ textAlign: 'center' }}>
                          <div className="col-md-12">
                            <p className='f-15-px text-align-left m-b-9-px'>Current Status</p>
                            <p className='f-11-px font-normal text-align-left grey-color'>An overview of current status</p>
                          </div>

                        </div>
                      </CardTitle>

                      <div className="row">
                        <div className="col-md-12">

                          <SelectComponent
                            field='form-field-name'
                            placeholder='Select Group'
                            preSelected={this.state.selectedGroup}
                            multi={false}
                            optionKeys={{ label: 'name', value: '_id' }}
                            options={this.props.groupList}
                            onChange={this.selectGroupForStatus}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12  height-220">
                          <PieChartComp data={statusData} fill="#03abce"></PieChartComp>

                        </div>
                      </div>
                      <div className="row m-t-43-px" style={{ textAlign: 'center' }}>

                        <div className="col-md-4">

                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onLabelClick('active', active)}
                            >{active}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Active
                       </span>
                          </div>
                        </div>
                        <div className="col-md-4">

                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onLabelClick('inActive', inActive)}
                            >{inActive}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Inactive
                       </span>
                          </div>
                        </div>
                        <div className="col-md-4">

                          <div>
                            <span className="counter f-40-px"
                            >{totalVehicles}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              Total
                       </span>
                          </div>
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </div>
                <div className="col-md-6 card-display ">
                  <Card className='height-520'>
                    <CardBody>
                      <CardTitle>

                        <div className="row" style={{ textAlign: 'center' }}>
                          <div className="col-md-12">
                            <p className='f-15-px text-align-left m-b-9-px'>Yesterday's Violations</p>
                            <p className='f-11-px font-normal text-align-left grey-color'>An overview yesterday's violations</p>
                          </div>
                        </div>
                      </CardTitle>
                      <div className="row">
                        <div className="col-md-12">
                          <SelectComponent
                            field='form-field-name'
                            placeholder='Select Group'
                            preSelected={this.state.vselectedGroup}
                            multi={false}
                            optionKeys={{ label: 'name', value: '_id' }}
                            options={this.props.groupList}
                            onChange={this.selectGroupForViolation}
                          />
                        </div>
                      </div>
                      {/* <div className="row">
                      <div className="col-md-12 height-220">
                        <PieChartComp data={violationData} fill="#03abce"></PieChartComp>
                      </div>
                    </div> */}

                      <div className="row m-t-135-px" style={{ textAlign: 'center' }}>

                        {/* <div className="col-md-4">

                        <div>
                          <span className="counter f-40-px"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.onViolationsLabelClick('Late Entry', pjpStats.lateEntry)}
                          >{pjpStats.lateEntry}</span>
                        </div>
                        <div>
                          <span className="card-heading font-15">
                            Late Entry
                       </span>
                        </div>
                      </div> */}
                        {/* <div className="col-md-4">

                        <div>
                          <span className="counter f-40-px"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.onViolationsLabelClick('Early Exit', pjpStats.earlyExit)}
                          >{pjpStats.earlyExit}</span>
                        </div>
                        <div>
                          <span className="card-heading font-15">
                            Early Exit
                       </span>
                        </div>
                      </div> */}
                        <div className="col-md-12">

                          <div>
                            <span className="counter f-40-px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onViolationsLabelClick('No Entry', pjpStats.noEntry)}
                            >{pjpStats.noEntry}</span>
                          </div>
                          <div>
                            <span className="card-heading font-15">
                              No Entry
                       </span>
                          </div>
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </div>








              </div>


            </div>
          </>}
      </div>

    );
  }
}

UniliverDashboard.propTypes = {};
UniliverDashboard.defaultProps = {};
const mapStateToProps = state => {

  return ({
    things: state.things.markers,
    center: state.things.center,
    observation: state.things.observation,
    widgets: state.things.widgets,
    widgetsConfig: state.things.widgetsConfig,
    groupList: state.groups.subGroups,
    permissions: state.permissions
  })
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch),
  pjpActions: bindActionCreators(PJP.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UniliverDashboard);


