import React, { Component } from 'react';
import { connect } from "react-redux";
import Groups from "../../../ducks/Groups";
import PJP from "../../../ducks/PJP";
import Hotspot from "../../../ducks/Hotspot";
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";
import MarkerMap from "../../common/maps/markerMap";
import { validateInput } from "../../../validations/hotspot";
import { WhiteSpinner } from '../../common/Spinner/index';
import Select from 'react-select-nested-group';
import trash_bin from '../../../assets/img/trash_bin.png';
import DateTime from 'react-datetime';

import * as _ from "lodash";

import toastr from "toastr";

class CreatePJP extends Component {

  constructor() {

    super();
    this.state = {
      name: "",
      description: "",
      center: [
        73.13915736973286, 33.59492064226822
      ],
      radius: 300,
      errors: {},
      vehicles: [],
      selectedVehicles: null,
      selectedhotspots: [],
      hotspots: [],
      route: [],
      loader: false,
     
    }
  }
  async componentDidMount() {
    await this
      .props
      .actions
      .getGroupsAndVehicles();
    await this
      .props
      .hotspotActions
      .getGroupHotspots();
    this.setState({
      ...this.state,
      vehicles: this.props.groupsAndVehicles.vehicles,
      hotspots: this.props.hotspots
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  addRoute = () =>{
    const route = this.state.route;
    if (route.length && !route[route.length-1].hotspot) {
      return;
    }
    route.push({ hotspot: null, arrivalTime: new Date(), departureTime: new Date() });
    this.setState({ ...this.state, route })
  }
  deleteRoute = (index) =>{
    let route = this.state.route;
    delete route[index];
    this.setState({ ...this.state, route });
  }
  searchVehicle = async (selectedOption) => {
    this.setState({ ...this.state, selectedVehicles: selectedOption });
  };
  selectHotspot(index, selectedOption){
    let {route, selectedhotspots} = this.state;
    route[index].hotspot = selectedOption.value;
    selectedhotspots = [...selectedhotspots, selectedOption];
    this.setState({ ...this.state, route, selectedhotspots, center: selectedhotspots[index].center });
  }
  setArrivalTime(index, selectedTime ){
    const route = this.state.route;
    route[index].arrivalTime = selectedTime;
    this.setState({ ...this.state, route })
  }
  setDepartureTime(index, selectedTime){
    const route = this.state.route;
    route[index].departureTime = selectedTime;
    this.setState({ ...this.state, route })
  }

  onMapChange = (circle) => {

    let radius = circle.getRadius();

    radius = Math.round(radius);

    this.setState({
      ...this.state,
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius
    });
    if (radius < 100) {
      this.setState({
        radius: 100
      });
    }

    return ({
      center: [
        circle
          .getCenter()
          .lng(),
        circle
          .getCenter()
          .lat()
      ],
      radius: radius
    })
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };
  onSubmit = async (e) => {

    e.preventDefault();
    const { loader } = this.state;

    if (loader) {
      return;
    }
    if (this.isValid()) {
      try {
        const state = this.state;
        
        if(!state.selectedVehicles){
          return  toastr.error('Please select trike');
        }
        if(!state.route.length){
          return  toastr.error('Please select route');
        }
        if(!state.route[state.route.length-1].hotspot){
          return  toastr.error('Please select hotspot');
        }
        const hotspots = _.filter(state.route, (item) =>{if(item) {return true;} return false})
        let pjpObj = {
          name: _.trim(state.name),
          description: _.trim(state.description),
          things: [state.selectedVehicles.value], 
          hotspots
        }
        this.setState({
          ...this.state,
          loader: true
        })
        await this
          .props
          .pjpActions
          .PJP(pjpObj);
        toastr.success("PJP Created Successfully!");
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push(`/pjp`);

      } catch (e) {
        toastr.error(e.response ? e.response.data.message : e);
        this.setState({
          ...this.state,
          loader: false
        });



      }
    } else {

    }

  };


  render() {
    const { name, description, vehicles, selectedVehicles, route, hotspots } = this.state;

    const loader = <WhiteSpinner key="1" />

    return (
      // <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8">
            <TextFieldGroup
              onChange={this.onChange.bind(this)}
              value={name}
              label={"PJP Name*"}
              type={"text"}
              field={"name"}
              error={this.state.errors.name}
              placeholder={"E.g Warehouse, school"} />
            <TextAreaGroup
              onChange={this.onChange.bind(this)}
              value={description}
              label={"Description"}
              field={"description"}
              error={this.state.errors.description}
              placeholder={"Extra details about PJP"} />


            <div className='form-group'>
              <label>Assign Trike*</label>

              <Select
                name='form-field-name'
                value={selectedVehicles}
                isClearable={false}
                isSearchable={true}
                placeholder='Select trikes Vehicles'
                onChange={this.searchVehicle}
                options={vehicles && vehicles.map(item => ({
                  value: item._id,
                  label: item.plateNumber
                }))}
              />
            </div>

            <div>
              <h4>Route</h4>

              <div className='trip-table-heading'>
                <p className='width-30'>Destination</p>
                <p className='width-30'>Arrival</p>
                <p className='width-30'>Departure</p>
                </div>
              {route.map((item, index) => {

                return (

                  <div className='width-100' key={index}>
                    <div className='trip-table-data'>
                      <p className='width-30 padding-10'>
                        <Select
                          name='form-field-name'
                          isSearchable={true}
                          placeholder='Select Hotspot'
                          onChange={this.selectHotspot.bind(this, index)}
                          options={hotspots && hotspots.map(item => ({
                            value: item._id,
                            label: item.name,
                            center: item.center,
                            radius: item.radius,
                          }))}
                        />
                      </p>

                      <p className='width-30 padding-10'>
                      <DateTime value={item.arrivalTime} dateFormat={false} timeFormat="HH:mm" onChange={this.setArrivalTime.bind(this, index)} />
                      </p>
                      <p className='width-30 padding-10'>
                      <DateTime value={item.departureTime} dateFormat={false} timeFormat="HH:mm" onChange={this.setDepartureTime.bind(this, index)} />
                      </p>
                      <span>
                    
                    <a onClick={this
                      .deleteRoute
                      .bind(this, _.clone(index))}><img src={trash_bin} className='width16' alt='' /></a> 
                </span>


                    </div>
                  </div>

                )
              })}
            </div>
            <button className="btn auxo-primary-btn add-hotspot" onClick={this.addRoute}>Add Hotspot</button>


            <div className="col padding0">


              <div className="margin-btm-50">
                <button className="btn auxo-primary-btn create-hotspot-btn" type="submit" onClick={this.onSubmit}>
                  {this.state.loader ? [loader] : 'Create'}</button>
              </div>

            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <MarkerMap
              center={{
                lat: this.state.center[1],
                lng: this.state.center[0]
              }}
              hotspots={this.state.selectedhotspots}
              radius={this.state.radius || 300}
              onMapChange={this
                .onMapChange} />
          </div>
          </div>
       
      // </form>

    );
  }
}

CreatePJP.propTypes = {};
CreatePJP.defaultProps = {};
const mapStateToProps = state => ({ groupsAndVehicles: state.groups.groupsAndVehicles, hotspots: state.hotspot.hotspots, });

const mapDispatchToProps = dispatch => ({
  pjpActions: bindActionCreators(PJP.creators, dispatch),
  hotspotActions: bindActionCreators(Hotspot.creators, dispatch),
  actions: bindActionCreators(Groups.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CreatePJP);
