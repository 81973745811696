import React, { Component } from 'react';
import { connect } from "react-redux";
import Drivers from "../../../ducks/Drivers";
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import * as _ from "lodash";
import searchIcon from '../../../assets/img/ic_search.png';
import Things from "../../../ducks/Things";
import './index.css';
import Select from 'react-select-nested-group';
// import 'react-select/dist/react-select.css';
import toastr from "toastr";
import moment from 'moment';
import editIcon from "../../../assets/img/ic_edit.png";
import saveIcon from "../../../assets/img/ic_save.png";
import cancelIcon from "../../../assets/img/ic_cancel.png";
import trash_bin from "../../../assets/img/trash_bin.png";
import Modal from 'react-bootstrap4-modal';
import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';
import dropdownIcon from '../../../assets/img/ic_dropdown_down.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ic_visibility from '../../../assets/img/score.png';
import { validateInput } from "../../../validations/createDriver";

class DriversListView extends Component {

  constructor() {
    super();
    this.state = {
      drivers: [],
      search: "",
      isLoadingRecords: true,
      showDeleteConfirmation: false,
      driverToBeDeleted: true,
      orderBy: "asc",
      scheduleVisible: false,
      savingSingleRecord: [],
      driverName: "",
      driverId: "",
      sortType: "",
      driverVehicles: []
    }
  }

  async componentDidMount() {
    this.fetchDrivers();
    await this
      .props
      .thingsActions
      .getVehiclesPlateNumber();
  }
  async fetchDrivers() {
    if (localStorage.getItem("companyInfo")) {
      let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

      await this
        .props
        .actions
        .getGroupDrivers(companyInfo.groupId);
      this.setState({
        ...this.state,
        drivers: _.clone(this.props.drivers),
        isLoadingRecords: false
      })
    }

  }

  onChange(event) {
    const value = event.target.value;
    if (value) {
      this.setState({
        ...this.state,
        search: value || ""
      });
    } else {
      this.setState({
        ...this.state,
        search: value || "",
        drivers: _.clone(this.props.drivers)
      });
    }
  }
  async editMode(obj) {
    if (this.props.permissions.updateDriver) {
      const index = obj.index;
      let drivers = _.clone(this.state.drivers);
      let item = { ...drivers[index] };
      item.editMode = obj.editMode;

      if (obj.cancelSave) {
        item = { ...this.props.drivers[index] }
      }
      if (obj.things) {
        item.thing = obj.things;
      }
      drivers[index] = item;
      this.setState({ ...this.state, drivers })
    } else {
      toastr.error("You have no access to perform this action. please contact system administrator!");
    }
  }
  searchDriver = (e) => {
    const value = e.target.value;
    if (value) {
      let drivers = _.filter(this.props.drivers, (item) => {
        let plateNumber = item.thing[0]['plateNumber'];
        if (plateNumber.search(new RegExp(value, "i")) >= 0 || item.name.search(new RegExp(value, "i")) >= 0) {
          return item;
        }
      });
      this.setState({
        ...this.state,
        drivers,
        search: value || ""
      });
    } else {
      this.setState({
        ...this.state,
        search: value || "",
        drivers: _.clone(this.props.drivers)
      });
    }
  }

  async exportDrivers() {
    try {
      await this
        .props
        .actions
        .exportDrivers();
    } catch (err) {
      toastr.error(err.response.data.message);
    }
  }
  navigateToAddDrivers() {
    this
      .props
      .history
      .push("/drivers/add");
  }
  isValid = (driverInfo) => {

    const { errors, isValid } = validateInput(driverInfo);
    if (!isValid) {
      if (errors.serialNumber) {
        toastr.error(errors.serialNumber);
      }
      else if (errors.name) {
        toastr.error(errors.name);
      }
      else if (errors.dateOfJoining) {
        toastr.error(errors.dateOfJoining);
      }

    }
    return isValid;
  };
  async updateDriverInfo(value) {
    try {
      let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
      let driver = this.state.drivers[value.index]
      const driverInfo = {
        id: driver._id,
        name: driver.name,
        iButton: driver.iButton,
        serialNumber: driver.serialNumber,
        dateOfJoining: driver.dateOfJoining,
        thing: driver.thing,
        group: companyInfo.groupId,
        email: driver.email
      }
      if (this.isValid(driverInfo)) {
        this.setState({
          ...this.state,
          savingSingleRecord: Object.assign([...this.state.savingSingleRecord], {
            [value.index]: true
          })
        });
        await this.props.actions.updateDriver(driverInfo);
        toastr.success("Driver updated successfully!");
        this.setState({
          ...this.state,
          savingSingleRecord: Object.assign([...this.state.savingSingleRecord], {
            [value.index]: false
          })
        })
        value.things = driverInfo.thing;
        this.editMode(value);
      }

    } catch (e) {
      this.setState({
        ...this.state,
        savingSingleRecord: Object.assign([...this.state.savingSingleRecord], {
          [value.index]: false
        })
      })
      toastr.error(e.response ? e.response.data.message : e);
    }

  }
  onChangeValue = (e) => {

    const index = e.target.id;
    let drivers = _.clone(this.state.drivers);
    let item = drivers[index]
    item[e.target.name] = e.target.value;
    drivers[index] = item;
    this.setState({ ...this.state, drivers })

  };
  handleDateChange(index, date) {

    let drivers = _.clone(this.state.drivers);
    let item = drivers[index]
    item.dateOfJoining = date;
    drivers[index] = item;
    this.setState({ ...this.state, drivers })
  }

  handlePlateNumberChange = async (index, selectedOption) => {
    let drivers = _.clone(this.state.drivers);
    let item = drivers[index];
    item.thing = _.map(selectedOption, item => { return { _id: item.value, plateNumber: item.label } });
    drivers[index] = item;
    this.setState({ ...this.state, drivers })

  }

  showDeleteDriverConfirmation(item) {
    this.setState({ ...this.state, driverToBeDeleted: item, showDeleteConfirmation: true })
  }
  cancelDelete() {
    this.setState({ ...this.state, showDeleteConfirmation: false })
  }
  deleteDriver() {
    this.props.actions.deleteDriver({ driverId: this.state.driverToBeDeleted._id });
    toastr.success("Driver deleted successfully!");
    let drivers = _.filter(this.state.drivers, item => {
      return item._id !== this.state.driverToBeDeleted._id;
    })
    this.setState({ ...this.state, showDeleteConfirmation: false, drivers })
  }

  sortColumn(type) {
    let drivers = [];
    if (this.state.orderBy === 'asc') {
      drivers = _.orderBy(this.state.drivers, [type], ['desc']);
      this.setState({ ...this.state, orderBy: "desc", drivers, sortType: type });
    } else {
      drivers = _.orderBy(this.state.drivers, [type], ['asc']);
      this.setState({ ...this.state, orderBy: "asc", drivers, sortType: type });
    }
  }
  closePopup() {
    this.setState({
      ...this.state,
      scheduleVisible: false
    });
  }

  driverDetails(id) {
    this
      .props
      .history
      .push(`/drivers/detail/${id}`);

  }
  render() {
    const isLoadingRecords = this.state.isLoadingRecords;
    const loader = <Spinner key="1" />;
    const smallLoader = <BlueSmallSpinner key="2" />;
    const hasData = this.props.drivers.length;

    return (
      <div>

        {!isLoadingRecords ?
          <div>

            {this.state.showDeleteConfirmation ?
              <Modal
                visible={true}
                onClickBackdrop={this.modalBackdropClicked}
                dialogClassName='delete-modal-dialog'>
                <div>
                  <button
                    type="button"
                    className="close close-x"
                    aria-label="Close"
                    onClick={this
                      .cancelDelete
                      .bind(this)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className='modal-heading'>
                  <h5 className="car-and-device-assig">Delete Driver?</h5>
                </div>
                <div className="modal-body">
                  <h6>Are You Sure You want to delete Driver {this.state.driverToBeDeleted.name}?</h6>


                </div>
                <div className="modal-footer model-btn">
                  <button
                    className="btn auxo-primary-btn"
                    onClick={this
                      .deleteDriver
                      .bind(this)}>
                    Confirm Delete
                    </button>
                  <button
                    className="btn auxo-primary-btn"
                    onClick={this
                      .cancelDelete
                      .bind(this)}>
                    Cancel
                    </button>
                </div>
              </Modal>
              : ""}

            {hasData
              ? <div className="row">

                <div className=" col-md-4 col-sm-4 margin-top-20">
                    <TextFieldGroup
                      field={"search"}
                      placeholder={"Search drivers"}
                      value={this.state.search}
                      type={"text"}
                      onChange={this
                        .searchDriver
                        .bind(this)} />
                </div>
                <div className="col-md-8 col-sm-8">

                  {this.props.permissions.createDriver ?
                    <button
                      className="btn auxo-primary-btn"
                      onClick={this
                        .navigateToAddDrivers
                        .bind(this)}>Add Driver</button> : ""}


                </div>
              </div>
              : ''}

            <div className="row">
              {hasData
                ? <table className="table-auxo">
                  <thead>
                    <tr>
                      <th>Serial Number</th>
                      <th>Driver Name <a onClick={this.sortColumn.bind(this, "name")}><img className={this.state.sortType === 'name' ? 'transform-arrow-click' : ''} src={dropdownIcon} alt="" /></a></th>
                      <th>Date of Joining <a onClick={this.sortColumn.bind(this, "dateOfJoining")}><img className={this.state.sortType === 'dateOfJoining' ? 'transform-arrow-click' : ''} src={dropdownIcon} alt="" /></a></th>
                      <th>Vehicle Plate Number</th>
                      {this.props.permissions.companyFeatures.indexOf('DRIVER_LOGIN') !== -1 && <th>Email</th>}
                      <th>Group</th>
                      <th>iButton Value</th>
                      <th>Actions</th>

                    </tr>
                  </thead>
                  <tbody>
                    {this.state.drivers && this
                      .state
                      .drivers
                      .map((item, index) => {
                        return (
                          <tr key={index} className="driver-listing">
                            <td className="edit-field">

                              {item.editMode ?
                                <TextFieldGroup
                                  onChange={this.onChangeValue}
                                  value={item.serialNumber}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"serialNumber"}
                                  placeholder={"Enter Serial Number"}
                                /> : <span>{item.serialNumber || "N/A"}</span>}

                            </td>
                            <td className="edit-field">

                              {item.editMode ?
                                <TextFieldGroup
                                  onChange={this.onChangeValue}
                                  value={item.name}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"name"}
                                  placeholder={"Enter name"}
                                /> : <span>{item.name || "N/A"}</span>}

                            </td>



                            <td className="edit-field">
                              {item.editMode ?
                                <DatePicker
                                  className="form-control marginbtm13"
                                  selected={moment(item.dateOfJoining)}
                                  onChange={this
                                    .handleDateChange
                                    .bind(this, index)} /> : <span> {moment(item.dateOfJoining).format('DD-MMM-YY') || "N/A"}</span>}


                            </td>

                            <td className="edit-field">
                              {item.editMode ?
                                <Select
                                  className="marginbtm13"
                                  name="form-field-name"
                                  defaultValue={item.thing ? item.thing
                                    .map(v => {
                                      return { value: v._id, label: v.plateNumber }
                                    }) : []}
                                  isClearable={true}
                                  isSearchable={true}
                                  isMulti
                                  onChange={this.handlePlateNumberChange.bind(this, index)}
                                  options={this.props.vehicles && this
                                    .props
                                    .vehicles
                                    .map(item => {
                                      return { value: item._id, label: item.plateNumber }
                                    })} /> :
                                <span> {item.thing
                                  ? item.thing
                                    .map((veh, thingIndex) => {
                                      return veh !== null ? veh.plateNumber : "N/A"
                                    }).join(',') : "N/A"}</span>}
                            </td>
                            {this.props.permissions.companyFeatures.indexOf('DRIVER_LOGIN') !== -1 && <td>
                                    {item.email}
                              </td>}
                            <td>
                              {item.group
                                ? item.group.name
                                : "N/A"}
                            </td>
                            <td className="edit-field">

                              {item.editMode ?
                                <TextFieldGroup
                                  onChange={this.onChangeValue}
                                  value={item.iButton}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"iButton"}
                                  placeholder={"Enter iButton value"}
                                /> : <span>{item.iButton || "N/A"}</span>}

                            </td>
                            <td className="last-column-width">
                              {this.state.savingSingleRecord[index] && this.state.savingSingleRecord[index] === true ? [smallLoader] : <div>
                                {item.editMode ? <div>  <a onClick={this.updateDriverInfo.bind(this, { index, editMode: false })}><img className="save-records" src={saveIcon} alt="" /></a>
                                  <a onClick={this.editMode.bind(this, { index, editMode: false, cancelSave: true })} ><img src={cancelIcon} alt="" /></a></div>
                                  : <div>
                                    <a onClick={this.driverDetails.bind(this, item._id)}><img className="width19" src={ic_visibility} alt="" /></a>
                                    <a onClick={this.editMode.bind(this, { index, editMode: true })}><img src={editIcon} alt="" /></a>
                                    <a onClick={this
                                      .showDeleteDriverConfirmation
                                      .bind(this, item)}><img src={trash_bin} className="width16" alt="" /></a>
                                  </div>}

                              </div>}
                            </td>

                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                : <div className="no-data-found">
                  <h2>No drivers added yet!</h2>
                  <button
                    className="btn"
                    onClick={this
                      .navigateToAddDrivers
                      .bind(this)}>Add Driver</button>
                </div>}

            </div>
          </div> : [loader]}
      </div>
    );
  }
}

DriversListView.propTypes = {};
DriversListView.defaultProps = {};
const mapStateToProps = state => (
  { 
    vehicles: state.things.vehiclesPlateNumber, 
    drivers: state.drivers.drivers, 
    isLoading: state.drivers.isLoading, 
    permissions: state.permissions 
  }
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Drivers.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DriversListView);
