import axios from 'axios';

export const setRemoveKeys = (type, key, value) => {
  if (type === 'add')
    try {
      if (value && typeof value === 'object') value = JSON.stringify(value);
      localStorage.setItem(key, value);
    }
  catch (e) {throw (e); }
  else {
    if (key === 'token') delete axios.defaults.headers.common.Authorization;
    clearLocalStorage();
  }

};

export const getKey = key => localStorage.getItem(key);
export const clearLocalStorage = () => localStorage.clear();
