import React, { Component } from 'react';
import TextFieldGroup from "../common/TextFieldGroup";
import classnames from "classnames";
import "./UserCreate.css";
import CheckboxListComponent from "../common/CheckboxListComponent";
import { connect } from "react-redux";
import Roles from "../../ducks/Roles";
import { bindActionCreators } from "redux";
import { validateUpdateInput } from "../../validations/userCreate";
import Groups from '../../ducks/Groups';
import toastr from "toastr";
import Users from "../../ducks/Users";
import * as _ from 'lodash';
import { WhiteSpinner, Spinner } from '../common/Spinner/index';
import SelectComponent from '../select';

class UpdateUser extends Component {
  constructor(props) {
    super(props);
    const groupData = JSON.parse(localStorage.getItem('groupInfo'))

    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      type: '',
      activeTab: "roles",
      selectedGroup: groupData.depthLevel !== 6 ? [] : [{ value: this.props.match.params.id }],
      errors: {},
      roleList: [],
      role: "",
      groupDepthLevel: groupData.depthLevel,
      companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
      loader: false,
      isLoading: true
    }
  }
  async componentDidMount() {
    await this
      .props
      .actions
      .getUser(this.props.match.params.id);
    await this
      .props
      .roleActions
      .getRoles();
    if (!(this.props.groupList && this.props.groupList.length))
      await this.props.dispatch(Groups.creators.getSubGroups(this.state.companyInfo.groupId));

    let user = this.props.user;

    let role = '';
    let roles = [];
    _.forEach(this.props.roles, (item) => {

      let flag = false;

      if (user.role === item._id) {
        flag = true;
        role = item._id;
      }
      roles.push({ _id: item._id, name: item.name, checked: flag });

    })
    this.setState({
      ...this.state,
      roleList: roles,
      role,
      firstName: user.firstName,
      lastName: user.lastName,
      selectedGroup: [{ value: user.group._id, label: user.group.name }],
      phone: user.phone,
      isLoading: false
    });
  }

  isValid = () => {

    const { errors, isValid } = validateUpdateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: ''
      }
    });
  };
  isTabActive = name => {
    return name === this.state.activeTab;
  };

  checkBoxListChanged = (value) => {
    let roleList = this.state.roleList;
    let role = "";
    if (roleList.length > 0) {
      for (let i = 0; i < roleList.length; i++) {
        let item = roleList[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          if (item.checked) {
            role = item._id;
          }
        } else {
          item.checked = false;
        }

        roleList[i] = {
          _id: item._id,
          name: item.name,
          checked: item.checked
        };

        this.setState({
          ...this.state,
          roleList,
          role: role.toString()
        });

      }
    }
  };

  onSubmit = async e => {

    e.preventDefault();
    if (this.isValid()) {
      try {
        const userInfo = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          groupId: this.state.selectedGroup[0].value,
          phone: this.state.phone,
          roleId: this.state.role
        }
        this.setState({
          ...this.state,
          loader: true
        })
        await this
          .props
          .actions
          .updateUser(this.props.match.params.id, userInfo);
        toastr.success("User updated successfully!");
        this.setState({
          ...this.state,
          loader: false
        })
        this
          .props
          .history
          .push("/users");
      } catch (e) {
        this.setState({
          ...this.state,
          loader: false
        })
        toastr.error(e.response ? e.response.data.message : e);
      }
    }
  };
  handleGroupChange = (selectedOption) => {
    this.setState({
      ...this.state,
      selectedGroup: selectedOption
    });
  }

  render() {
    const { firstName, lastName, phone } = this.state;
    const loader = <WhiteSpinner key="1" />
    const loaderRoles = <Spinner key='1' />;

    return (
      <>
        {this.state.isLoading ? [loaderRoles] :
          <div>
            <div className="heading-container">
              <h3 className="font-weight-bold">Update User</h3>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col">
                  <TextFieldGroup
                    onChange={this.onChange}
                    value={firstName}
                    type={"text"}
                    field={"firstName"}
                    label={"First Name"}
                    placeholder={"First name of the user"}
                    error={this.state.errors.firstName} />
                  <TextFieldGroup
                    onChange={this.onChange}
                    value={lastName}
                    type={"text"}
                    field={"lastName"}
                    label={"Last Name"}
                    placeholder={"Last name of the user"}
                    error={this.state.errors.lastName} />

                  <TextFieldGroup
                    onChange={this.onChange}
                    value={phone}
                    type={"text"}
                    field={"phone"}
                    label={"Phone"}
                    placeholder={"03451234567"}
                    error={this.state.errors.phone} />
                    {
                      this.state.groupDepthLevel !== 6 ?
                        <div className={classnames('form-group')}>
                            <SelectComponent
                              label='Select Group'
                              field='form-field-name'
                              placeholder='Select Group'
                              preSelected={this.state.selectedGroup}
                              optionKeys={{label: 'name', value: '_id'}}
                              options={this.props.groups}
                              error={this.state.errors.selectedGroup}
                              onChange={this.handleGroupChange}
                            />
                        </div> : ''
                    }
                </div>
                <div className="col">
                  <label>Access</label>
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className={classnames("nav-link", {
                          "active": this.isTabActive("roles")
                        })}
                        onClick={() => this.handleTabClick("roles")}>Roles</a>
                    </li>

                  </ul>
                  <div className="tab-content access-tab">
                    <div
                      className={classnames("tab-pane fade", {
                        "show active": this.isTabActive("roles")
                      })}>
                      <CheckboxListComponent
                        onChange={this.checkBoxListChanged}
                        selectAllEnabled={false}
                        data={this
                          .state
                          .roleList
                          .map(item => ({ id: item._id, mainTitle: item.name, checked: item.checked }))} /> {this.state.errors && <span className="text-danger">{this.state.errors.role}</span>}
                    </div>

                  </div>

                </div>

              </div>
              <button className="btn auxo-primary-btn">{this.state.loader ? [loader] : 'Update'}</button>
            </form>
          </div>
        }
      </>
    );
  }
}

UpdateUser.propTypes = {};
UpdateUser.defaultProps = {};
const mapStateToProps = state => ({ roles: state.roles.list, user: state.users.user, groups: state.groups.subGroups });
const mapDispatchToProps = dispatch => ({
  roleActions: bindActionCreators(Roles.creators, dispatch),
  actions: bindActionCreators(Users.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
