import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import map from 'lodash/map';
import classnames from 'classnames';
import { subDays } from 'date-fns';
import { Popover, PopoverBody } from 'reactstrap';
import Select from 'react-select-nested-group';
import { DateRangePicker } from 'react-date-range';
import DateTime from 'react-datetime';
import Groups from '../../ducks/Groups';
import GeoFence from '../../ducks/GeoFence';
import RouteFence from '../../ducks/RouteFence';
import toastr from 'toastr';
import TextFieldGroup from '../common/TextFieldGroup';
import CheckboxListComponent from '../common/CheckboxListComponent';
import CheckListComponent from '../check-list';
import './index.scss'
import calenderIcon from '../../assets/img/calender.png';
import MySelect from "./MySelect.js";

class ReportInterval extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    let dateData = {
      selection: {
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
        startTime: moment().startOf('day'),
        endTime: moment().endOf('day'),
        key: 'selection',
      },
    }

    if (props.reportType === "DLIReport") {
      dateData = {
        selection: {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 1),
          startTime: moment().set({"hours" : 0,"minutes" : 0}),
          endTime: moment().set({"hours" : 23,"minutes" : 59}),
          key: 'selection',
        },
      }
    }

    this.state = {
      performanceGeoFence: null,
      performanceRouteFence: null,
      geofenceEntry: null,
      geofenceExit: null,
      vehicles: [],
      groupList: [],
      selectedVehicles: [],
      distanceLimit: 25,
      activeTab: 'vehicles',
      errors: {},
      dateRangePicker: dateData,
      popoverOpen: false,
      overSpeedingLimit: 80,
      companyInfo: JSON.parse(localStorage.getItem('companyInfo') || '') || {}
    }
  }

  async componentDidMount() {

    if (!(this.props.groupList && this.props.groupList.length > 0)) await this.props.actions.getSubGroups(this.state.companyInfo.groupId);

    await this
      .props
      .actions
      .getGroupsAndVehicles();


    if (this.props.reportType === 'geofenceTrackReport' || this.props.reportType === 'performanceReport' ) {
      await this
        .props
        .geofenceActions
        .getGroupGeoFences();
    }

    if (this.props.reportType === 'performanceReport') {
      await this.props.routeFenceActions.getGroupRouteFences();
    }

    let dateRangePicker = {
      selection: {
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
        startTime: moment().set({"hours" : 0,"minutes" : 0}),
        endTime: moment().set({"hours" : 23,"minutes" : 59}),
        key: 'selection',
      },
    };

    if (this.props.reportType === "DLIReport") {
      dateRangePicker = {
        selection: {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 1),
          startTime: moment().set({"hours" : 0,"minutes" : 0}),
          endTime: moment().set({"hours" : 23,"minutes" : 59}),
          key: 'selection',
        },
      }
    }
    if (this.props.interval && this.props.interval.startDate) {
      dateRangePicker = { selection: this.props.interval }
    }

    this.setState({
      ...this.state,
      vehicles: this.props.groupsAndVehicles.vehicles,
      groupList: this.formatGroupList(this.props.groupList),
      dateRangePicker,
    });
  }

  toggle() {
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  searchVehicles = (e) => {
    const value = e.target.value;
    let vehicles = [];

    let selectedVehicles = this.state.selectedVehicles;
    if (value) {
      forEach(this.props.groupsAndVehicles.vehicles, (item) => {
        if (item.plateNumber.search(new RegExp(value, 'i')) >= 0) {
          let index = selectedVehicles.findIndex(obj => {
            return obj === item._id;
          });

          vehicles.push({
            _id: item._id,
            plateNumber: item.plateNumber || 'N/A',
            checked: index > -1,
            vehicleMakeAndModel: item.vehicleMakeAndModel || 'N/A'
          });
        }
      });

    } else {
      vehicles = map(this.props.groupsAndVehicles.vehicles, (item) => {
        let index = selectedVehicles.findIndex(obj => {
          return obj === item._id;
        });
        return {
          _id: item._id,
          plateNumber: item.plateNumber || 'N/A',
          checked: index > -1,
          vehicleMakeAndModel: item.vehicleMakeAndModel || 'N/A'
        };
      });
    }
    this.setState({
      ...this.state,
      vehicles
    });
  };

  checkBoxListChanged = (value) => {
    
    let vehicles = this.state.vehicles;
    let groupList = this.state.groupList;
    let selectedVehicles = this.state.selectedVehicles;

    if (vehicles.length > 0) {

      const selectGroup = (item, check) => {
        for (let j = 0; j < groupList.length; j++) {
          let groupItem = groupList[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groupList[j] = groupItem;
        }
      };

      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];
        if (i === parseInt(value, 10)) {
          item.checked = !item.checked;
          vehicles[i] = item;
          if (item.checked) {
            selectedVehicles.push(item._id);
            const groupVehicle = find(vehicles, veh => {
              return veh.group === item.group && !veh.checked
            });
            if (!groupVehicle) {
              selectGroup(item, true);
            }
          } else {
            selectGroup(item, false);

            let index = selectedVehicles.findIndex(obj => {
              return obj === item._id;
            });
            if (index > -1) {
              selectedVehicles.splice(index, 1);
            }
          }
        } else if(this.props.reportType === 'vehicleHistoryReport') {
          item.checked = false;
        }

      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groupList,
      selectedVehicles
    });
  };
  selectAllVehicles = (value) => {

    let vehicles = this.state.vehicles;
    let groupList = this.state.groupList;
    let selectedVehicles = this.state.selectedVehicles;

    if (vehicles.length > 0) {

      const selectGroup = (item, check) => {
        for (let j = 0; j < groupList.length; j++) {
          let groupItem = groupList[j];
          if (groupItem._id === item.group) {
            groupItem.checked = check;
          }
          groupList[j] = groupItem;
        }
      };

      for (let i = 0; i < vehicles.length; i++) {
        let item = vehicles[i];

        item.checked = value;
        vehicles[i] = item;
        if (item.checked) {
          selectedVehicles.push(item._id);
          const groupVehicle = find(vehicles, veh => {
            return veh.group === item.group && !veh.checked
          });
          if (!groupVehicle) {
            selectGroup(item, true);
          }
        } else {
          selectGroup(item, false);

          let index = selectedVehicles.findIndex(obj => {
            return obj === item._id;
          });
          if (index > -1) {
            selectedVehicles.splice(index, 1);
          }
        }


      }
    }
    this.setState({
      ...this.state,
      vehicles,
      groupList,
      selectedVehicles
    });
  };

  handleChange(which, payload) {

    this.setState({
      [which]: payload,
    });
  }

  handleRangeChange(which, payload) {

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }

  onGroupChange = (resData, groupCheckedArr) => {
    console.log("Group Changed: ")
    console.group(resData);
    let vehicles = this.state.vehicles,
      selectedVehicles = this.state.selectedVehicles;

    if (vehicles.length > 0) {
      for (let i = 0; i < vehicles.length; i++) {
        const groupChecked = find(groupCheckedArr, ['_id', vehicles[i].group]);
        if (groupChecked) {
          vehicles[i].checked = groupChecked.checked;
          if (vehicles[i].checked) selectedVehicles.push(vehicles[i]._id);
          else {
            let index = selectedVehicles.findIndex(obj => obj === vehicles[i]._id);
            if (index !== -1) selectedVehicles.splice(index, 1);
          }
        }
      }
    }
    this.setState({ ...this.state, vehicles, groupList: resData, selectedVehicles });
  };

  isTabActive = name => {
    return name === this.state.activeTab;
  };

  handleTabClick = name => {
    this.setState({
      ...this.state,
      activeTab: name
    })
  };

  handleSelect(range) {
    // An object with two keys,
    // 'startDate' and 'endDate' which are Momentjs objects.
  }

  selectVehiclesAndRange() {
    //   const selection = this.state.dateRangePicker.selection;
    //   let stn = selection.startTime;
    //   let et = selection.endTime;
    //   let nmin = stn.diff(et, 'minutes');
    //   console.log(nmin);

    //   let st = selection.startTime;
    //   debugger
    //   let sd = selection.startDate.set({
    //     hour:   st.get('hour'),
    //     minute: st.get('minute')
    // });

    //    console.log(clone(sd));

    //  let newDate = sd.add(nmin, 'minutes');
    //  console.log(newDate);
    // return;
    if (this.state.errors.distanceLimit) {
      return toastr.error('Invalid distance');
    }
    if (this.props.reportType === 'performanceReport' && !this.state.performanceGeoFence) {
      return toastr.error('Please select geofence');
    }
    if (this.props.reportType === 'pjpReport') {
      let {startDate, endDate} = this.state.dateRangePicker.selection;
      startDate = moment(startDate);
      endDate = moment(endDate);
      const diff = endDate.diff(startDate, 'days');
      if(diff > 1){
        return toastr.error('Please select interval of maximum two days');
      }
    }
    if(this.props.reportType === 'vehicleHistoryReport') {
      let {startDate, endDate, startTime, endTime} = this.state.dateRangePicker.selection;
      startTime = moment(startDate).set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second')
      });
      endTime = moment(endDate).set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second')
      });
      const diff = endTime.diff(startTime, 'seconds');
      
      if(diff > 259200){
        return toastr.error('Please select interval of maximum 72 hours');
      }
    }
    // if (this.props.reportType === 'performanceReport' && !this.state.performanceRouteFence) {
    //   return toastr.error('Please select routefence');
    // }
    let performanceGeoFenceValue = null;
    let performanceRouteFenceValue = null;
    if (this.state.performanceGeoFence) {
      performanceGeoFenceValue = map(this.state.performanceGeoFence, (item) => item.value);
    }
    if (this.state.performanceRouteFence) {
      performanceRouteFenceValue = map(this.state.performanceRouteFence, (item) => item.value);
    }

    this.props.selectVehiclesAndRange(this.state.selectedVehicles, this.state.dateRangePicker.selection, this.state.overSpeedingLimit, this.state.geofenceEntry, this.state.geofenceExit, this.state.distanceLimit, performanceGeoFenceValue, performanceRouteFenceValue)
  }
  setStartDate(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { startDate: payload, endDate: selection.endDate, startTime: selection.startTime, endTime: selection.endTime } } })
  }
  setEndDate(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { endDate: payload, startDate: selection.startDate, startTime: selection.startTime, endTime: selection.endTime } } })
  }
  setStartTime(payload) {

    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { startDate: selection.startDate, endDate: selection.endDate, startTime: payload, endTime: selection.endTime } } })
  }
  setEndTime(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({ ...this.state, dateRangePicker: { selection: { endDate: selection.endDate, startDate: selection.startDate, startTime: selection.startTime, endTime: payload } } })
  }

  back() {
    this.props.switchStep(2, this.state.selectedVehicles, this.state.dateRangePicker.selection);
  }

  selectGeofenceEntry = async (selectedOption) => {
    this.setState({ geofenceEntry: selectedOption })
  };
  selectGeofenceExit = async (selectedOption) => {
    this.setState({ geofenceExit: selectedOption })
  };
  selectPerformanceGeofence = async (selectedOption) => {
    this.setState({ performanceGeoFence: selectedOption })
  };
  selectPerformanceRoutefence = async (selectedOption) => {
    this.setState({ performanceRouteFence: selectedOption })
  };

  formatGroupList(groupList) {
    return map(groupList, item => {
      item.checked = false;
      if (item.child && item.child.length) item.child = this.formatGroupList(item.child);
      return item
    })
  };

valid = ( current ) => {

  if (this.props.reportType === "DLIReport") {

    let result = current.isBefore(subDays(new Date(), 1));
    return result;

  } else {
    return true;
  }

};

  render() {
    return (

      <div>
        <div>
          <ul className='reports-list'>
            <li className='interval-report'>
              <span>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.back.bind(this)}
                >
                  Previous
                </button>
              </span>
              {
                this.props.reportType !== 'OIL_CHANGE' &&
                  this.props.reportType !== 'TYRE_CHANGE' &&
                  this.props.reportType !== 'otherMaintainance' &&
                  this.props.reportType !== 'vehiclesRouteFenceReport' &&
                  this.props.reportType !== 'vehiclesGeoFenceReport'
                  ? (
                    <span className='text-center interval-icon'>
                      <h4>Vehicles and Interval</h4>
                      <p>Select vehicles and date interval for the report</p>
                    </span>
                  )
                  : (
                    <span className='text-center interval-icon'>
                      <h4>Vehicles</h4>
                      <p>Select vehicles for the report</p>

                    </span>
                  )
              }
              <span className='select-report'>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.selectVehiclesAndRange.bind(this)}
                >
                  Export
                </button>
              </span>
            </li>
          </ul>
        </div>
        {
          this.props.reportType === 'overSpeeding'
            ? (
              <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-3'>
                  <TextFieldGroup
                    onChange={this.onChange.bind(this)}
                    value={this.state.overSpeedingLimit}
                    type={'Number'}
                    field={'overSpeedingLimit'}
                    label={'Over Speeding Limit'}
                    placeholder={'Over Speeding Limit'}
                    error={this.state.errors.overSpeedingLimit}
                  />
                </div>
              </div>
            )
            : ''
        }
        {
          this.props.reportType === 'vehicleTravelReport'
            ? (
              <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-3'>
                  <TextFieldGroup
                    onChange={this.onChange.bind(this)}
                    value={this.state.distanceLimit}
                    type={'Number'}
                    field={'distanceLimit'}
                    label={'Min distance travelled (KM)'}
                    placeholder={'Min distance travelled'}
                    error={this.state.errors.distanceLimit}
                  />
                </div>
              </div>
            )
            : ''
        }
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-6'>
            <ul className='nav nav-tabs' role='tablist'>
              <li className='nav-item'>
                <a
                  className={'nav-link active'}
                  onClick={() => this.handleTabClick('vehicles')}
                >
                  Vehicles
                </a>
              </li>
              {this.props.reportType !== 'vehicleHistoryReport' && <li className='nav-item'>
                <a 
                className={'nav-link active'} 
                onClick={() => this.handleTabClick('groups')}
                >
                  Groups
                </a>
              </li>}
            </ul>
            <div className='tab-content access-tab mb-5'>
              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('vehicles') })}>
                {this.props.reportType === 'vehicleHistoryReport' ? <CheckboxListComponent
                  enableSearch='true'
                  selectAllEnabled={true}
                  selectAll={this.selectAllVehicles.bind(this)}
                  search={this.searchVehicles.bind(this)}
                  onChange={this.checkBoxListChanged}
                  data={
                    this.state.vehicles && this.state.vehicles.map(item => ({
                      mainTitle: item.plateNumber || 'N/A',
                      subTitle: item.vehicleMakeAndModel || 'N/A',
                      checked: item.checked
                    }))
                  }
                /> : <CheckboxListComponent
                enableSearch='true'
                selectAllEnabled={true}
                selectAll={this.selectAllVehicles.bind(this)}
                search={this.searchVehicles.bind(this)}
                onChange={this.checkBoxListChanged}
                data={
                  this.state.vehicles && this.state.vehicles.map(item => ({
                    mainTitle: item.plateNumber || 'N/A',
                    subTitle: item.vehicleMakeAndModel || 'N/A',
                    checked: item.checked
                  }))
                }
              />}
              </div>
              <div className={classnames('tab-pane fade', { 'show active': this.isTabActive('groups') })}>
                <CheckListComponent
                  onChange={this.onGroupChange}
                  isGroupSelect={true}
                  reportType={this.props.reportType}
                  data={this.state.groupList}
                  optionKeys={{ labelKey: 'name', valueKey: 'name', keyField: '_id', childKey: 'child' }}
                />
              </div>
              {this.state.errors && <span className='text-danger'>{this.state.errors.vehicles}</span>}
            </div>

          </div>
          {
            this.props.reportType !== 'OIL_CHANGE' &&
            this.props.reportType !== 'TYRE_CHANGE' &&
            this.props.reportType !== 'otherMaintainance' &&
            this.props.reportType !== 'vehicleTravelReport' &&
            this.props.reportType !== 'distanceTraveled' &&
            this.props.reportType !== 'vehiclesGeoFenceReport' &&
            this.props.reportType !== 'vehiclesRouteFenceReport' &&
            <div>
                <div>
                  <label >Start Date</label>
                  <DateTime  isValidDate={(e) => this.valid(e) } timeFormat={false} value={this.state.dateRangePicker.selection.startDate} dateFormat="DD-MM-YYYY" onChange={this.setStartDate.bind(this)} />
                  {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                  <label >End Date</label>
                  <DateTime isValidDate={(e) => this.valid(e) } timeFormat={false} value={this.state.dateRangePicker.selection.endDate} dateFormat="DD-MM-YYYY" onChange={this.setEndDate.bind(this)} />
                </div>
                <div>
                  <label >Start Time</label>
                  <DateTime dateFormat={false} value={this.state.dateRangePicker.selection.startTime} timeFormat="HH:mm" onChange={this.setStartTime.bind(this)} />
                  {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                  <label >End Time</label>
                  <DateTime dateFormat={false} value={this.state.dateRangePicker.selection.endTime} timeFormat="HH:mm" onChange={this.setEndTime.bind(this)} />
                </div>
              </div>
            // <div className='col-lg-3 col-md-3 col-sm-4'>
            //   <label>Select Interval</label>
            //   <div>
            //     <a onClick={this.toggle} id='Popover1'>
            //       <img src={calenderIcon} alt='' />
            //     </a>
            //     <Popover placement='bottom' isOpen={this.state.popoverOpen} target='Popover1' toggle={this.toggle}>
            //       <PopoverBody>
            //         <div>
            //           <DateRangePicker
            //             onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
            //             showSelectionPreview={true}
            //             moveRangeOnFirstSelection={false}
            //             className={'PreviewArea'}
            //             months={2}
            //             ranges={[this.state.dateRangePicker.selection]}
            //             direction='horizontal'
            //             maxDate={new Date()}
            //           />
            //         </div>
            //       </PopoverBody>
            //     </Popover>
            //   </div>

            //   <div className='selected-Interval'>
            //     <p><b>From:</b> {moment(this.state.dateRangePicker.selection.startDate).format('MMMM Do YYYY')}</p>
            //     <p><b>To:</b> {moment(this.state.dateRangePicker.selection.endDate).format('MMMM Do YYYY')}</p>
            //   </div>
            // </div>
          }
          {
           ( this.props.reportType === 'vehicleTravelReport' ||  this.props.reportType === 'distanceTraveled') &&

            <div className='col-lg-3 col-md-3 col-sm-4'>
              <label>Select Interval</label>
              <div>
                <div>
                  <label >Start Date</label>
                  <DateTime timeFormat={false} value={this.state.dateRangePicker.selection.startDate} dateFormat="DD-MM-YYYY" onChange={this.setStartDate.bind(this)} />
                  {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                  <label >End Date</label>
                  <DateTime timeFormat={false} value={this.state.dateRangePicker.selection.endDate} dateFormat="DD-MM-YYYY" onChange={this.setEndDate.bind(this)} />
                </div>
                <div>
                  <label >Start Time</label>
                  <DateTime dateFormat={false} value={this.state.dateRangePicker.selection.startTime} timeFormat="HH:mm" onChange={this.setStartTime.bind(this)} />
                  {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                  <label >End Time</label>
                  <DateTime dateFormat={false} value={this.state.dateRangePicker.selection.endTime} timeFormat="HH:mm" onChange={this.setEndTime.bind(this)} />
                </div>
              </div>
            </div>
          }
          {
            this.props.reportType === 'geofenceTrackReport' && <div className='col-lg-3 col-md-3 col-sm-4'>
              <div style={{ padding: '80px 0px 20px 0px' }}>
                <Select
                  name='form-field-name'
                  isClearable={true}
                  isSearchable={true}
                  placeholder='Select Source Geofence'
                  onChange={this.selectGeofenceEntry}
                  value={this.state.geofenceEntry}
                  options={this.props.geoFences.map(geofence => ({ value: geofence._id, label: geofence.name }))}
                />
              </div>
              <div>
                <Select
                  name='form-field-name'
                  isClearable={true}
                  isSearchable={true}
                  placeholder='Select Destination Geofence'
                  onChange={this.selectGeofenceExit}
                  value={this.state.geofenceExit}
                  options={this.props.geoFences.map(geofence => ({ value: geofence._id, label: geofence.name }))}
                />
              </div>
            </div>
          }
          {
            this.props.reportType === 'performanceReport' && <div className='col-lg-3 col-md-3 col-sm-4'>
              <div style={{ padding: '80px 0px 20px 0px' }}>
                <MySelect
                  name='form-field-name'
                  isClearable={true}
                  isSearchable={true}
                  isMulti
                  allowSelectAll={this.props.geoFences.length > 0}
                  placeholder='Select  Geofence'
                  onChange={this.selectPerformanceGeofence}
                  value={this.state.performanceGeoFence}
                  options={this.props.geoFences.map(geofence => ({ value: geofence._id, label: geofence.name }))}
                />
              </div>
              <div>
                <MySelect
                  name='form-field-name'
                  isClearable={true}
                  isSearchable={true}
                  isMulti
                  allowSelectAll={this.props.routeFences.length > 0}
                  placeholder='Select RouteFence'
                  onChange={this.selectPerformanceRoutefence}
                  value={this.state.performanceRouteFence}
                  options={this.props.routeFences.map(routefence => ({ value: routefence._id, label: routefence.name }))}
                />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

ReportInterval.propTypes = {};
ReportInterval.defaultProps = {};

const mapStateToProps = state => ({
  groupsAndVehicles: state.groups.groupsAndVehicles,
  geoFences: state.geoFence.geoFences,
  routeFences: state.routeFence.routeFences,
  groupList: state.groups.subGroups
});
const mapDispatchToProps = dispatch => ({
  routeFenceActions: bindActionCreators(RouteFence.creators, dispatch),
  actions: bindActionCreators(Groups.creators, dispatch),
  geofenceActions: bindActionCreators(GeoFence.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportInterval);
