import Duck from 'extensible-duck'
import axios from "axios";
import {routeFenceEndPoints} from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoRouteFence',
    store: 'routeFence',
    types: [
        'IS_LOADING', 'DETAILS_FETCHED', 'ROUTEFENCE_FETCHED'
    ],
    initialState: {
        routeFence: {},
        routeFences: [],
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.ROUTEFENCE_FETCHED:
                return {
                    ...state,
                    routeFences: action.routeFences
                };

            case duck.types.DETAILS_FETCHED:
                return {
                    ...state,
                    routeFence: action.routeFence
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().routeFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                
                const response = await axios.get(routeFenceEndPoints.individualRouteFence(state.id));
                const routeFence = response.data.data;
                dispatch({type: duck.types.DETAILS_FETCHED, routeFence});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getGroupRouteFences: () => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().routeFence.isLoading) {
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(routeFenceEndPoints.groupRouteFence(companyInfo.groupId));
                const routeFences = response.data.data;
                dispatch({type: duck.types.ROUTEFENCE_FETCHED, routeFences});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return routeFences
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },

     
        RouteFence: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().routeFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                if(state.id){
                    await axios.put(routeFenceEndPoints.individualRouteFence(state.id, companyInfo.groupId), state.routeFenceObj);
                } else{
                    await axios.post(routeFenceEndPoints.routeFence(), state);
                }
               
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
              console.log(e)
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
        deleteRouteFence: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().routeFence.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                await axios.delete(routeFenceEndPoints.individualRouteFence(state.id));

                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
       
    })
})