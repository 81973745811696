import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Things from "../../../ducks/Things";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import { Spinner } from "../../common/Spinner/index";
import moment from "moment";
import DateTime from "react-datetime";
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Sector,
  ResponsiveContainer,
} from "recharts";
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy + outerRadius + 35}
        dy={8}
        textAnchor="middle"
        fill={fill}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + 5} y={ey - 5} textAnchor={textAnchor} fill="#333">{`${(
        percent * 100
      ).toFixed(0)}%`}</text>
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(1)}%`}
              </text> */}
    </g>
  );
};

const PieChartWithCells = (props) => {
  const colors = ["#FF0000", "#FFA500", "#03ABCE", "#B22222", "#d4aaff"];
  const [activeIndex, setActivendex] = useState(0);
  const allZero = (data) => {
    for (let cell of data) {
      if (cell.value > 0) {
        return false;
      }
    }
    return true;
  };
  const onPieEnter = (data, index) => {
    setActivendex(index);
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={200} height={300}>
        {allZero(props.data) && (
          <Pie
            data={[{ name: "Daily Score", value: 100 }]}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            cx={110}
            cy={90}
            innerRadius={40}
            outerRadius={60}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            fill={"#9AD35E"}
            onMouseEnter={onPieEnter}
          ></Pie>
        )}
        <Pie
          data={props.data}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          cx={110}
          cy={90}
          innerRadius={40}
          outerRadius={60}
          fill="#000000"
          paddingAngle={2}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          onMouseEnter={onPieEnter}
        >
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
class VehicleScore extends Component {
  constructor(props) {
    super(props);
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    this.state = {
      isLoadingRecords: false,
      scheduleVisible: false,
      drivingScore: null,
      activeIndex: 0,
      modal: false,
      events: [],
      eventName: "",
      companyId: companyInfo ? companyInfo.companyId : "",
      plateNumber: "",
      startDate: moment().subtract("1", "days"),
      endDate: moment(),
    };
  }
  async componentDidMount() {
    await this.fetchData();
    this.setState({
      ...this.state,
      isLoadingRecords: true,
      drivingScore: this.props.scorecard,
    });
  }
  async fetchData() {
    this.setState({ plateNumber: this.props.match.params.plateNumber });
    let thingId = this.props.match.params.id;
    await this.props.actions.getVehicleScore(thingId,  this.state.startDate,
      this.state.endDate);
  }
  formatData(data) {
    let response = [];
    for (let cell in data) {
      let key = "";
      if (cell === "harshBrakes") {
        key = "Harsh Brakes";
      }
      if (cell === "speedLimit") {
        key = "Speed Limit";
      }
      if (cell === "harshTurn") {
        key = "Sharp Turn";
      }
      if (cell === "unFastenSeatBelt") {
        key = "Unfasten Seatbelt";
      }
      if (cell === "harshAcceleration") {
        key = "Harsh Acceleration";
      }
      response = [...response, { name: cell, key, value: data[cell] }];
    }
    return response;
  }
  displayModal(state, eventName, displayName) {
    const eventNames = {
      harshBrakes: "HARSH_BRAKES",
      speedLimit: "SPEED_LIMIT",
      harshTurn: "HARSH_TURN",
      unFastenSeatBelt: "UNFASTEN_SEATBELT",
      harshAcceleration: "HARSH_ACCELERATION",
    };
    const events =
      this.state.drivingScore[state].violations.allViolations[
      eventNames[eventName]
      ];

    this.setState({
      ...this.setState,
      events: events,
      modal: true,
      eventName: displayName,
    });
  }
  getScoreColorClass(score) {
    if (score > 90) {
      return "";
    }

    if (score <= 90 && score >= 80) {
      return "score-blue";
    } else {
      return "score-red";
    }
  }
  toggle = () => {
    this.setState({
      ...this.state,
      modal: !this.state.modal,
    });
  };
  onChangeStartDate = (e) => {
    var check = moment(e, "YYYY/MM/DD");
    this.setState({
      startDate: new Date(e),
    });
  };
  onChangeEndDate = (e) => {
    var check = moment(e, "YYYY/MM/DD");
    this.setState({
      endDate: new Date(e),
    });
  };
  seacrh = async () => {
    let thingId = this.props.match.params.id;
    this.setState({
      isLoadingRecords: false,
    });
    await this.props.actions.getVehicleScore(
      thingId,
      this.state.startDate,
      this.state.endDate
    );
    this.setState({
      ...this.state,
      isLoadingRecords: true,
      drivingScore: this.props.scorecard,
    });
  };
  render() {
    const loader = <Spinner key="1" />;
    const { drivingScore, companyId } = this.state;
    const isLoadingRecords = this.state.isLoadingRecords;
    const driverScore = drivingScore
      ? drivingScore.driverScore
      : { drivingScore: 0, violations: [] };
    const vehicleScore = drivingScore
      ? drivingScore.vehicleScore
      : { drivingScore: 0, violations: [] };
    const managerScore = drivingScore
      ? drivingScore.managerScore
      : { drivingScore: 0, violations: [] };
    const VehicleViolations = this.formatData(
      vehicleScore ?.violations ?.allViolations
    );
    const driverViolations = this.formatData(
      driverScore ?.violations ?.allViolations
    );
    const managerViolations = this.formatData(
      managerScore ?.violations ?.allViolations
    );
    const VehicleViolationsCount = this.formatData(
      vehicleScore ?.violations ?.violationsCounts
    );
    const driverViolationsCount = this.formatData(
      driverScore ?.violations ?.violationsCounts
    );
    const managerViolationsCount = this.formatData(
      managerScore ?.violations ?.violationsCounts
    );



    const classes = companyId === '606b15ed8d1b9f7336b0bee5' ? 'col-lg-4 col-md-4 col-sm-4' : 'col-lg-6 col-md-6 col-sm-6'


    // const colors = ["#DE1A1A", "#95A3DA", "#ACBED8", "#F2D398", "#D78521"];
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader
            toggle={this.toggle}
          >{`${this.state.eventName} Violation Details`}</ModalHeader>
          <ModalBody>
            <table className="table-auxo">
              <thead>
                <tr>
                  <th>Violation Time</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {this.state.events.map((row, index) => {
                  return (
                    <tr key={index.toString()}>
                      <td className="modal-cell">
                        {moment(row.eventTime).format("DD-MMM-YYYY hh:mm a")}
                      </td>
                      <td className="modal-cell">
                        <a
                          href={`https://google.com/maps/search/${row.latlng}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.location}
                        </a>
                      </td>
                    </tr>
                  );
                })}
                {this.state.events.length === 0 && (
                  <tr>
                    <td colspan="4" style={{ textAlign: "center" }}>
                      No Violation found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn auxo-primary-btn create-geofence-btn"
              onClick={this.toggle}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
        <div>
          {isLoadingRecords ? (
            <div>

              <p className="vehicle-platenumber"> Driving Behaviour For {this.state.plateNumber}</p>
              <div className="upper-div">

                <div className="group-sec">
                  <div classname="form-group">
                    <label> Start Date</label>
                    <DateTime
                      value={this.state.startDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.onChangeStartDate}
                    />
                  </div>
                  <div className="form-group">
                    <label> End Date</label>
                    <DateTime
                      value={this.state.endDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      onChange={this.onChangeEndDate}
                    />
                  </div>{" "}
                </div>
                <button
                  className="btn auxo-small-btn"
                  type="submit"
                  onClick={this.seacrh}
                >
                  Filter
                    </button>
              </div>

              <div className="row">
              {this.state.companyId !== '606b15ed8d1b9f7336b0bee5' && 
               <div className="col-lg-2 col-md-2 col-sm-2"></div>}
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className={"col-md-3 card-display "}>
                    <Card className="height-auto text-center">
                      <CardBody>
                        <div className="driver-card">
                          <div className="m-t-20-px">
                            {VehicleViolationsCount.map((violation, index) => {
                              return (
                                <div
                                  className="flex space-between p-t-b-3-px divider"
                                  key={index.toString()}
                                  onClick={() =>
                                    this.displayModal(
                                      "vehicleScore",
                                      violation.name,
                                      violation.key
                                    )
                                  }
                                >
                                  <span className="score-tag">
                                    {violation.key}
                                  </span>
                                  <span>{violation.value}</span>
                                </div>
                              );
                            })}
                          </div>
                          <span
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              fontSize: "smaller",
                            }}
                          ></span>
                          <div className="driver-score">
                            <span
                              className={`score ${this.getScoreColorClass(
                                vehicleScore.drivingScore
                              )}`}
                            >
                              {vehicleScore.drivingScore}
                            </span>
                            <span className="score-tag">Vehicle</span>
                          </div>
                          <div className="driver-chart">
                            <PieChartWithCells data={VehicleViolationsCount} />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className={"col-md-3 card-display "}>
                    <Card className="height-auto text-center">
                      <CardBody>
                        <div className="driver-card">
                          <div className="m-t-20-px">
                            {driverViolationsCount.map((violation, index) => {
                              return (
                                <div
                                  className="flex space-between p-t-b-3-px divider"
                                  key={index.toString()}
                                  onClick={() =>
                                    this.displayModal(
                                      "driverScore",
                                      violation.name,
                                      violation.key
                                    )
                                  }
                                >
                                  <span className="score-tag">
                                    {violation.key}
                                  </span>
                                  <span>{violation.value}</span>
                                </div>
                              );
                            })}
                          </div>
                          <span
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              fontSize: "smaller",
                            }}
                          ></span>
                          <div className="driver-score">
                            <span
                              className={`score ${this.getScoreColorClass(
                                driverScore.drivingScore
                              )}`}
                            >
                              {driverScore.drivingScore}
                            </span>
                            <span className="score-tag">Driver</span>
                          </div>
                          <div className="driver-chart">
                            <PieChartWithCells data={driverViolationsCount} />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                {this.state.companyId !== '606b15ed8d1b9f7336b0bee5' && 
               <div className="col-lg-2 col-md-2 col-sm-2"></div>}
                {this.state.companyId === '606b15ed8d1b9f7336b0bee5' && 
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className={"col-md-3 card-display "}>
                    <Card className="height-auto text-center">
                      <CardBody>
                        <div className="driver-card">
                          <div className="m-t-20-px">
                            {managerViolationsCount.map((violation, index) => {
                              return (
                                <div
                                  className="flex space-between p-t-b-3-px divider"
                                  key={index.toString()}
                                  onClick={() =>
                                    this.displayModal(
                                      "managerScore",
                                      violation.name,
                                      violation.key
                                    )
                                  }
                                >
                                  <span className="score-tag">
                                    {violation.key}
                                  </span>
                                  <span>{violation.value}</span>
                                </div>
                              );
                            })}
                          </div>
                          <span
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              fontSize: "smaller",
                            }}
                          ></span>
                          <div className="driver-score">
                            <span
                              className={`score ${this.getScoreColorClass(
                                managerScore.drivingScore
                              )}`}
                            >
                              {managerScore.drivingScore}
                            </span>
                            <span className="score-tag">Manager</span>
                          </div>
                          <div className="driver-chart">
                            <PieChartWithCells data={managerViolationsCount} />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                }
              </div>
            </div>
          ) : (
              [loader]
            )}
        </div>
      </>
    );
  }
}
VehicleScore.propTypes = {};
VehicleScore.defaultProps = {};
const mapStateToProps = (state) => ({ scorecard: state.things.scorecard });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleScore);
