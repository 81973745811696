import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Groups from "../../ducks/Groups";
import Visualization from "../../ducks/Visualization";
import Reports from "../../ducks/Reports";
import { Card, CardHeader } from "reactstrap";
import DateTime from "react-datetime";
import { WhiteSpinner } from "../common/Spinner/index";
import moment from "moment";
import { validateInput } from "../../validations/fuelBar";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import placeholder from "../../assets/img/chart-placeholder.svg";
import Select from "react-select-nested-group";
const FuelBarChart = (props) => {
  const [state, setState] = useState({
    vehicles: [],
    selectedVehicle: "",
    startDate: moment().subtract("1", "days"),
    endDate: moment(),
    errors: {},
    loader: false,
  });
  useEffect(() => {
    props.actions.getGroupsAndVehicles();
  }, []);
  useEffect(() => {
    setState({
      ...state,
      vehicles: props.groupsAndVehicles.vehicles,
    });
  }, [props.groupsAndVehicles]);
  const vehicleSelected = (e) => {
    setState({
      ...state,
      selectedVehicle: e,
    });
  };
  const onChangeStartDate = (e) => {
    setState({ ...state, startDate: new Date(e) });
  };
  const onChangeEndDate = (e) => {
    setState({ ...state, endDate: new Date(e) });
  };
  const isValid = () => {
    const { errors, isValid } = validateInput(state);
    if (!isValid) {
      setState({ ...state, errors });
    }
    return isValid;
  };
  const submit = async (e) => {
    e.preventDefault();
    if (state.loader) {
      return;
    }
    if (isValid()) {
      setState({ ...state, loader: true });
      const requestObject = {
        vehicle: state.selectedVehicle.value,
        startDate: state.startDate,
        endDate: state.endDate,
      };
      await props.chartActions.loadFuelChartData(requestObject);
      setState({ ...state, loader: false });
    }
  };
  const download = async () => {
    if (state.loader) {
      return;
    }
    if (isValid()) {
      const requestObject = {
        vehicle: state.selectedVehicle,
        startDate: state.startDate,
        endDate: state.endDate,
        reportType: "fuelVisualReport",
      };
      await props.reportActions.exportReport(requestObject);
    }
  };
  const formatXAxis = (value) => {
    return moment(value.split("-")[0]).format("MMM DD, YYYY");
  };
  const loader = <WhiteSpinner key="1" />;
  return (
    <div className="row">
      <div className="col-md-4">
        <Card className="height-100-p " style={{ minWidth: "100%" }}>
          <CardHeader className="f-bold">Filter Criteria</CardHeader>
          <form onSubmit={submit}>
            <div className="col p-10-px">
              <div className="form-group">
                <label>Select Vehicles</label>
                <Select
                  name="form-field-name"
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select Vehicle"
                  onChange={vehicleSelected}
                  value={state.selectedVehicle}
                  options={
                    state.vehicles &&
                    state.vehicles.map((item) => ({
                      value: item._id,
                      label: item.plateNumber || "N/A",
                      item: item,
                    }))
                  }
                />
             
              </div>
              {state.errors && (
                <span className="text-danger">{state.errors.vehicle}</span>
              )}
              <div className="form-group">
                <label>Start Date</label>
                <DateTime
                  value={state.startDate}
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  onChange={onChangeStartDate}
                />
              </div>
              {state.errors && (
                <span className="text-danger">{state.errors.startDate}</span>
              )}
              <div className="form-group">
                <label>End Date</label>
                <DateTime
                  value={state.endDate}
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  onChange={onChangeEndDate}
                />
              </div>
              {state.errors && (
                <span className="text-danger">{state.errors.endDate}</span>
              )}
              <div className="margin-btm-50">
                <button
                  className="btn auxo-primary-btn create-geofence-btn"
                  type="submit"
                >
                  {state.loader ? [loader] : "Filter"}
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
      <div className="col-md-8">
        <Card style={{ minWidth: "100%", minHeight:"600px" }}>
          <CardHeader className="f-bold">
            Visualization
            {props.fuelChart.data?.length > 0 && (
              <span>
                <button
                  className="btn auxo-primary-btn m-btn-v"
                  type="button"
                  onClick={() => download()}
                >
                  <i className="fa fa-download"></i>
                </button>
              </span>
            )}
          </CardHeader>
          {props.fuelChart.data?.length > 0 && (
            <div className="row" style={{ margin: "11px 0px 1px 0px" }}>
              <div className="col-md-3">
                <div className="stat-card">
                  <span className="counter">
                    {props.fuelChart.count?.totalFuelConsumed || 0}
                  </span>
                  <p className="f-11-px text-center">Total Fuel Consumed (Liters)</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="stat-card">
                  <span className="counter">
                    {props.fuelChart.count?.totalFuelFilled || 0}
                  </span>
                  <p className="f-11-px text-center">Total Fuel Filled (Liters)</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="stat-card">
                  <span className="counter">
                    {props.fuelChart.count?.totalDistanceTravelled || 0} 
                  </span>
                  <p className="f-11-px text-center">
                    Total Distance Travelled (KM)
                  </p>
                </div>
              </div> 

              <div className="col-md-2">
                <div className="stat-card">
                  <span className="counter">
                    {(props.fuelChart.count?.totalDistanceTravelled / props.fuelChart.count?.totalFuelConsumed).toFixed(1) || 0} 
                  </span>
                  <p className="f-11-px text-center">
                    Fuel Average (km/l)
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="stat-card">
                  <span className="counter">
                    {props.fuelChart.count?.currentFuelLevel || 0} 
                  </span>
                  <p className="f-11-px text-center">
                    Current Fuel Level
                  </p>
                </div>
              </div>
            </div>
          )}
          {props.fuelChart.data?.length > 0 && (
            <ResponsiveContainer width="98%" height="90%" className="m-t-40-px">
              <BarChart data={props.fuelChart.data} width={700} height={300}>
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="date_time" tickFormatter={formatXAxis} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="fuelConsumed" fill="#FA8072" />
                {/* <Bar dataKey="distance" fill="#FFA500" /> */}
                {/* <Bar dataKey="fuelFilled" fill="#FFA500" /> */}
              </BarChart>
            </ResponsiveContainer>
          )}
          {props.fuelChart.data.length === 0 && (
            <div className="img-container">
              <img src={placeholder} width="600" height="560" />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groupsAndVehicles: state.groups.groupsAndVehicles,
  fuelChart: state.visualization.fuelChartData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Groups.creators, dispatch),
  chartActions: bindActionCreators(Visualization.creators, dispatch),
  reportActions: bindActionCreators(Reports.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FuelBarChart);
