import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateTime from "react-datetime";
import toastr from "toastr";
import {  map } from "lodash";
import moment from "moment";
import { Card, CardHeader,CardTitle,CardBody } from "reactstrap";
import Thermometer from 'react-thermometer-component'
import Select from "react-select-nested-group";
import ReactSpeedometer from "react-d3-speedometer";
import {
  LineChart,
  PieChart, Pie,
  ResponsiveContainer,
  BarChart, Bar,
  XAxis,Sector,
  Legend,
  CartesianGrid,
  YAxis,
  Tooltip,
  Line
} from "recharts";
import Visualization from "../../ducks/Visualization";
import Things from "../../ducks/Things";
import Company from "../../ducks/Company";
import Groups from "../../ducks/Groups";
import { Spinner } from '../common/Spinner/index';
import { filterVehicleListing } from "../../utils/helper.util";
import SelectComponent from "../select";
import "./index.css";

class GdThingBarChart extends Component {
  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    this.state = {
      activeIndex: 0,
      selectedGroup: [],
      defaultSearchValue: null,
      searchValue: [],
      searchTerm: "",
      zoom: 5.5,
      vehicleNumberPlate: "",
      loading: false,
      isLabelEnable: false,
      selectedVehicle: null,
      filterClicked:false,
      searchVehicleValues: [],
      groupId: companyInfo ? companyInfo.groupId : "",
      companyId: companyInfo ? companyInfo.companyId : "",
      geofence: false,
      routefence: false,
      mapLoading: false,
      startDate: moment().subtract("1", "days"),
      endDate: moment(),
    };
  }

  async componentDidMount() {
    if (this.state.groupId !== "") {
      const actions = [this.props.actions.fetchThings(true)];
      await Promise.all(actions).then(() => {
        this.setState({
            ...this.state,
            searchVehicleValues: map(this.props.things, (item) => ({
              value: item._id,
              label: item.plateNumber || item.imei.substr(-8),
              item: item,
            })),
          })
      });
    }
  }

  selectGroup = (selectedGroup) => {
    const filteredThings = filterVehicleListing(
      this.props.things,
      selectedGroup
    );
    let searchValue = map(filteredThings, (item) => ({
      value: item._id,
      label: item.plateNumber || item.imei.substr(-8),
      item: item,
    }));
    this.setState({ selectedGroup , searchValue });
  } 

  filterVehicle = async (value) => {
    await this.setState({ ...this.state, searchValue: value });
  };

  onChangeStartDate = (e) => {
    this.setState({ ...this.state, startDate: new Date(e) });
  };
  onChangeEndDate = (e) => {
    this.setState({ ...this.state, endDate: new Date(e) });
  };

  SubmitServerQuery = async (e) => {
    
      if (!this.state.searchValue){
        toastr.error("Please Select Thing");
        return;
      };
      this.setState({
        ...this.state,
        loading: true
      });

      let startDate = this.state.startDate.toISOString();
      let endDate = this.state.endDate.toISOString();

      let interval = {
              'startDate' : moment(startDate),
              'endDate':moment(endDate)
      };
      await this.props.chartActions.loadGdChartData(this.state.searchValue.value, JSON.stringify({ interval }));
    this.setState({
      ...this.state,
      loading: false,
      filterClicked:true
    });
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} %`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  render() {
    let loader = <Spinner key="1" />;

    const data = this.props?.gdThingsChartData?.data;
    const lastElement = data?.length && data?.slice(data?.length-1,data.length);
    if ( lastElement && lastElement.length ){
      let engineCurrentLoad  = lastElement[0]['engineCurrentLoad'] ? 100 - lastElement[0]['engineCurrentLoad'] : 100 ;
      let pedalPosition = lastElement[0]['pedalPosition'] ? 100 - lastElement[0]['pedalPosition'] : 100 ;

      lastElement.push({
        'pedalPosition':pedalPosition,
        'engineCurrentLoad':engineCurrentLoad
      });
    };

    return (
      <div>
        <div>
          <div className="filterDiv">
            <div className="row">
              <div className="col-md-12">
                <Card style={{height:"140px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                    <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Search Filters</CardHeader>
                      <div className="filtersParent">
                        <div className="col-md-3">
                          <div>
                            <div className="form-group">
                              <Select
                                name="form-field-name"
                                isClearable={true}
                                isSearchable={true}
                                placeholder="Search"
                                onChange={this.filterVehicle}
                                value={this.state.searchValue}
                                options={this.state.searchVehicleValues}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <div className="dateSelector">
                              <label>Start Date</label>
                              <DateTime 
                                className="datePicker"
                                value={this.state.startDate}
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                onChange={this.onChangeStartDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <div className="dateSelector">
                              <label>End Date</label>
                              <DateTime
                                className="datePicker"
                                value={this.state.endDate}
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                onChange={this.onChangeEndDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div>
                            <div className="form-group">
                              <button className="btn btn-primary" onClick={this.SubmitServerQuery}>Search</button>
                            </div>
                          </div>
                        </div>
                      </div>
                </Card>
              </div>
            </div>
          </div>

          {this.state.loading ? (
            [loader]
              ) : (
                data?.length ? (
                <div className="statsDiv">
                  <div className="cardParent row">
                    <div className="multiCards col-md-12">
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "30%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Engine Coolent Temperature	</CardHeader>
                          <div style={{display: "flex",flexDirection: "row",justifyContent: "center"}} 
                            width="100%" height="100%">
                            <Thermometer
                              theme="light"
                              value={data[data.length-1]?.coolentTemp}
                              max="150"
                              steps="3"
                              format="°C"
                              size="normal"
                              height="250"
                            />
                          </div>            
                      </Card>
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "30%",alignItems: "center" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Pedal Position Current	</CardHeader>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart width={300} height={400}>
                            <Pie
                              activeIndex={this.state.activeIndex}
                              activeShape={this.renderActiveShape}
                              data={lastElement}
                              cx="50%"
                              cy="50%"
                              innerRadius={50}
                              outerRadius={70}
                              fill="#8884d8"
                              dataKey="pedalPosition"
                              onMouseEnter={this.onPieEnter}
                            />
                          </PieChart>
                        </ResponsiveContainer>            
                      </Card>
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "30%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Engine RPM</CardHeader>
                          <div style={{ width:"100%",height:"100%",display:"flex",flexDirection: "row"}}>
                            <ResponsiveContainer width="100%" height="100%">
                              <div className="row" style={{marginTop: "50px",justifyContent: 'center'}}>
                                  <ReactSpeedometer
                                    maxValue={8000}
                                    minValue={0}
                                    value={data[data.length-1]?.engineRpm}
                                    needleColor="#9A3334"
                                    segmentColors={["#0339ce","#035bce","#0365ce","#0380ce","#03abce","#ff7300","#ff6600","#ff4d00","#ff2f00","#FF0000"]}
                                    startColor="#03abce"
                                    segments={11}
                                    endColor="#FF0000"
                                    textColor="grey"
                                    valueTextFontSize="18px"
                                  />
                              </div>    
                            </ResponsiveContainer>
                          </div> 
                      </Card>
                    </div>
                  </div>
                  <div className="cardParent row">
                    <div className="col-md-12">
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Engine Current Load	</CardHeader>
                          <div style={{ width:"100%",height:"100%",display:"flex",flexDirection: "row"}}>
                          <ResponsiveContainer width="30%" height="100%">
                            <PieChart width={400} height={400}>
                              <Pie
                                activeIndex={this.state.activeIndex}
                                activeShape={this.renderActiveShape}
                                data={lastElement}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="engineCurrentLoad"
                                onMouseEnter={this.onPieEnter}
                              />
                            </PieChart>
                          </ResponsiveContainer>  
                          <ResponsiveContainer width="70%" height="100%">
                            <LineChart width={1020} height={250} data={this.props?.gdThingsChartData?.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis interval="preserveStartEnd" dataKey="dateTime" label={{
                                value: "Date and Hour",
                                position: "insideBottomLeft",
                                offset: -6,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Fuel Level Liters",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="engineCurrentLoad" stroke="#03abce" />
                            </LineChart>
                          </ResponsiveContainer>  
                          </div>      
                      </Card>
                    </div>
                  </div>
                  <div className="cardParent row">
                    <div className="col-md-12">
                      <Card style={{height:"420px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Battery Voltage	</CardHeader>
                          <div style={{ width:"100%",height:"100%",display:"flex",flexDirection: "row"}}>
                          <ResponsiveContainer width="30%" height="100%">
                            <div className="row" style={{marginTop: "50px",justifyContent: 'center'}}>
                              <ReactSpeedometer
                                maxValue={100}
                                minValue={0}
                                value={data[data.length-1]?.batteryVoltage}
                                needleColor="#9A3334"
                                segmentColors={["#0339ce","#035bce","#0365ce","#0380ce","#03abce","#ff7300","#ff6600","#ff4d00","#ff2f00","#FF0000"]}
                                startColor="#03abce"
                                segments={11}
                                endColor="#FF0000"
                                textColor="grey"
                                valueTextFontSize="18px"
                              />
                              </div>
                          </ResponsiveContainer>  
                          <ResponsiveContainer width="70%" height="100%">
                            <LineChart width={1020} height={250} data={data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis interval="preserveStartEnd" dataKey="dateTime" label={{
                                value: "Date and Hour",
                                position: "insideBottomLeft",
                                offset: -6,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Battery Voltage V",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="batteryVoltage" stroke="#03abce" />
                            </LineChart>
                          </ResponsiveContainer>  
                          </div>      
                      </Card>
                    </div>
                  </div>
                  <div className="cardParent row">
                    <div className="col-md-12">
                      <Card style={{height:"420px",marginBottom:"20px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Fuel Level	</CardHeader>
                        <div style={{ width:"100%",height:"100%",display:"flex",flexDirection: "row"}}>
                          <ResponsiveContainer width="30%" height="100%">
                              <div className="row" style={{marginTop: "50px",justifyContent: 'center'}}>
                                <ReactSpeedometer
                                  maxValue={100}
                                  minValue={0}
                                  value={data[data.length-1]?.fuelLevelPercent}
                                  needleColor="#9A3334"
                                  segmentColors={["#0339ce","#035bce","#0365ce","#0380ce","#03abce","#ff7300","#ff6600","#ff4d00","#ff2f00","#FF0000"]}
                                  startColor="#03abce"
                                  segments={11}
                                  endColor="#FF0000"
                                  textColor="grey"
                                  valueTextFontSize="18px"
                                />
                              </div>
                          </ResponsiveContainer>  
                          <ResponsiveContainer width="70%" height="100%">
                            <LineChart width={1020} height={250} data={this.props?.gdThingsChartData?.data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis interval="preserveStartEnd" dataKey="dateTime" label={{
                                value: "Date and Hour",
                                position: "insideBottomLeft",
                                offset: -6,
                                fill: "#666666",
                                fontFamily: "Roboto",
                              }} />
                              <YAxis label={{
                                value: "Fuel Level Liters",
                                angle: -90,
                                position: "left",
                                offset: -4,
                                fill: "#666666",
                                fontFamily: "Roboto"
                              }} />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="fuelLevelLiters" stroke="#03abce" />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              ) : this.state.filterClicked ? ('There is no Data') : ''
          )}
        </div>
      </div>
    );
  }
}

GdThingBarChart.propTypes = {};
GdThingBarChart.defaultProps = {};
const mapStateToProps = (state) => {
  return {
    things: state.things.markers,
    groupList: state.groups.subGroups,
    permissions: state.permissions,
    company: state.company,
    selectedVehicleCords: state.things.selectedVehicleCords,
    tripsChartData: state.visualization.tripsChartData.data,
    gdThingsChartData: state.visualization.gdThingsChartData.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch),
  chartActions: bindActionCreators(Visualization.creators, dispatch),
  groupActions: bindActionCreators(Groups.creators, dispatch),
  companyActions: bindActionCreators(Company.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(GdThingBarChart);
