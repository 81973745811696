import Duck from 'extensible-duck'
import axios from "axios";
import { groupEndPoints } from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoGroups', store: 'groups',
    types: ['GROUP_IS_LOADING', 'DETAILS_FETCHED', 'GROUP_FETCHED','DRIVER_HOURS_SETTING_FETCHED', 'SUB_GROUPS_FETCHED', 'GROUPS_FETCHED', 'GROUPS_VEHICLES_FETCHED', 'GROUPS_DRIVERS_FETCHED', 'DRIVING_SCORE_SETTING_FETCHED'],
    initialState: {
        group: {},
        subGroups: [],
        groups: [],
        driverHours:{},
        groupsAndVehicles: [],
        groupsAndDrivers: [],
        scoreSetting: {
            harshAcceleration: false,
            harshBrake: false,
            overSpeeding: false,
            sharpTurn: false
        },
        
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.GROUP_IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.GROUPS_VEHICLES_FETCHED:
                return {
                    ...state,
                    groupsAndVehicles: action.groupsAndVehicles
                };
            case duck.types.GROUPS_DRIVERS_FETCHED:
                return {
                    ...state,
                    groupsAndDrivers: action.groupsAndDrivers
                };


            case duck.types.GROUP_FETCHED:
                return {
                    ...state,
                    group: action.group
                };
            case duck.types.SUB_GROUPS_FETCHED:
                return {
                    ...state,
                    subGroups: action.subGroups
                };
            case duck.types.GROUPS_FETCHED:
                return {
                    ...state,
                    groups: action.groups
                };
            case duck.types.DRIVING_SCORE_SETTING_FETCHED:
                return {
                    ...state,
                    scoreSetting: action.scoreSetting
                };
            case duck.types.DRIVER_HOURS_SETTING_FETCHED:
                return {
                    ...state,
                    driverHours: action.driverHours
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getGroupsAndVehicles: () => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

                const response = await axios.get(groupEndPoints.groupsAndVehicles(companyInfo.groupId));
                let groupsAndVehicles = response.data.data;
                dispatch({ type: duck.types.GROUPS_VEHICLES_FETCHED, groupsAndVehicles });
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        getGroupsAndDrivers: () => async (dispatch, getState) => {
          
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

                const response = await axios.get(groupEndPoints.groupsAndDrivers(companyInfo.groupId));
                let groupsAndDrivers = response.data.data;
                dispatch({ type: duck.types.GROUPS_DRIVERS_FETCHED, groupsAndDrivers });
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },

        getSingle: (id) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });

                const response = await axios.get(groupEndPoints.individualGroup(id));
                let group = response.data.data;
                dispatch({ type: duck.types.GROUP_FETCHED, group });
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        deleteGroup: (id) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });
                await axios.delete(groupEndPoints.individualGroup(id));
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },

        getSingleToUpdate: (id) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });

                const response = await axios.get(groupEndPoints.updateGroup(id));
                let group = response.data.data;
                dispatch({ type: duck.types.GROUP_FETCHED, group });
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        getSubGroups: (id) => async (dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });

                const response = await axios.get(groupEndPoints.subGroups(id));
                let subGroups = response.data.data;
                dispatch({ type: duck.types.SUB_GROUPS_FETCHED, subGroups });

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },

        UpdateGroup: (state) => async (dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });
                const formData = new FormData();
                formData.append('name', state.name)
                formData.append('image', state.image)
                formData.append('overSpeedingThreshold', state.overSpeedingThreshold)
                formData.append('lowIntensityOverSpeeding', state.lowIntensityOverSpeeding)
                formData.append('highIntensityOverSpeeding', state.highIntensityOverSpeeding)
                formData.append('nrConfig', state.nrConfig)
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                await axios.put(groupEndPoints.individualGroup(state.groupId), formData, config);

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        updateFatigueThreshold: (state) => async (dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });
            
                await axios.put(groupEndPoints.updateFatigueThreshold(state.groupId), {...state});

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        UpdateSubGroup: (state) => async (dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });

                await axios.put(groupEndPoints.updateGroup(state.groupId), state);

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },

        createGroup: (state) => async (dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().groups.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            try {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: true });

                await axios.post(groupEndPoints.groups(), state);

                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
            }
            catch (e) {
                dispatch({ type: duck.types.GROUP_IS_LOADING, isLoading: false });
                throw e;
            }
        },
        getCompanyGroups: (state) => async (dispatch, getState) => {
            try {

                const response = await axios.get(groupEndPoints.companyGroups(state.companyId));
                dispatch({ type: duck.types.GROUPS_FETCHED, groups: response.data.data });

            }
            catch (e) {
                throw e;
            }
        },
        getDrivingScoreSetting: (companyId) => async (dispatch, getState) => {
            try {

                const response = await axios.get(groupEndPoints.drivingScoreSetting(companyId));
                dispatch({ type: duck.types.DRIVING_SCORE_SETTING_FETCHED, scoreSetting: response.data.data });

            }
            catch (e) {
                throw e;
            }
        },
        updateDrivingScoreSetting: (state) => async (dispatch, getState) => {
            try {
                await axios.put(groupEndPoints.drivingScoreSetting(state.companyId), {drivingScoreSetting: state.drivingScoreSetting});
            }
            catch (e) {
                throw e;
            }
        },
        getDriverHoursConfigSetting: (companyId) => async (dispatch, getState) => {
            try {

                const response = await axios.get(groupEndPoints.drivingHoursConfig(companyId));
                dispatch({ type: duck.types.DRIVER_HOURS_SETTING_FETCHED, driverHours: response.data.data });

            }
            catch (e) {
                throw e;
            }
        },
        updateDriverHoursConfigSetting: (state) => async (dispatch, getState) => {
            try {
                await axios.put(groupEndPoints.drivingHoursConfig(state.companyId), {driverHours: state.driverHours});
            }
            catch (e) {
                throw e;
            }
        },
    })
})