import { isEmpty } from "lodash";
import { isEmptyValue, isAlphaNumeric } from "./validator";

export function validateSiteInput(data) {
  let errors = {};

  

  if (isEmptyValue(data.name)) {
    errors.name = "This field is required";
  } else if (data.name.length > 100) {
    errors.name = "Max 100 characters are allowed in name";
  }
  if (!isAlphaNumeric(data.description)) {
    errors.description = "Description must be alphanumeric";
  } else if (data.description.length > 200) {
    errors.description = "Max 200 characters are allowed in description.";
  }
  

  return { errors, isValid: isEmpty(errors) };
}



export function validateInput(data) {
  let errors = {};

  const setChecked = () => {
    if (data.mapDrawTypes) {
      return data.mapDrawTypes.filter((item) => item?.checked === true);
    }
  };

  if (isEmptyValue(data.name)) {
    errors.name = "This field is required";
  } else if (data.name.length > 100) {
    errors.name = "Max 100 characters are allowed in name";
  }
  if (!isAlphaNumeric(data.description)) {
    errors.description = "Description must be alphanumeric";
  } else if (data.description.length > 200) {
    errors.description = "Max 200 characters are allowed in description.";
  }
  if (data.selectedVehicles.length === 0) {
    errors.vehicles = "Please select vehicles";
  }
  if (data.geoFenceType == "CIRCLE" && !data.radius) {
    errors.radius = "Radius is required";
  } else if (data.radius < 300) {
    errors.radius = "Radius should be greater than 300";
  }
 
  if (data.mapDrawTypes) {
    if (
      data.path.length < 4 &&
      data.mapDrawTypes &&
      setChecked()[0]?.mainTitle !== "Circle"
    ) {
      errors.path = "Please select atleast four coordinates";
    } else {
      delete errors.path;
    }
  } else {
    if (data.path.length < 4 && data.geoFenceType !== "CIRCLE") {
      errors.path = "Please select atleast four coordinates";
    } else {
      delete errors.path;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}
