import React, { useState, useEffect } from 'react';
import Select from 'react-select-nested-group';
import * as R from 'ramda';
import moment from 'moment';
import toastr from "toastr";
import transform from './observationHelper';
import {Spinner} from '../common/Spinner/index';
import DateTime from 'react-datetime';


import Things from "../../ducks/Things";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Geocode from "react-geocode";
import PaginatedTable from '../common/PaginatedTable';
Geocode.setApiKey("AIzaSyCZI1nlQrNvcDVSrAcnnSxJlou3uejXyQY");

const ECUObservationPage = (props) => {

  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [things, setThings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableValues, setTableValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedHours, setSelectedHours] = useState({ value: 12, label: 12 })

  
  const columns = [
    { name: 'Device Time', selector: "gpsTime", sortable: true, wrap: true},
    { name: 'Plate', selector: "plate", wrap: true},
    { name: 'Vehicle Speed', selector: "speed"},
    { name: 'Accelerator Pedal Position', selector: "pedalPosition"},
    { name: 'RPM', selector: "rpm"},
    { name: 'Engine Oil Level', selector: "engineOilLevel"},
    { name: 'Fuel Level', selector: "fuelLevel"},
    { name: 'Door Status', selector: "doorStatus"},
    { name: 'Engine Temprature', selector: "engineTemperature"},
    { name: 'Fuel Consumed', selector: "fuelComsumed"},
    { name: 'Total Mileage(KM)', selector: "totalMileage"},
    { name: 'iButton', selector: "iButton"},
  ];
  const headers = [
    { label: 'Device Time', key: "gpsTime"},
    { label: 'Plate', key: "plate"},
    { label: 'Vehicle Speed', key: "speed"},
    { label: 'Accelerator Pedal Position', key: "pedalPosition"},
    { label: 'RPM', key: "rpm"},
    { label: 'Engine Oil Level', key: "engineOilLevel"},
    { label: 'Fuel Level', key: "fuelLevel"},
    { label: 'Door Status', key: "doorStatus"},
    { label: 'Engine Temprature', key: "engineTemperature"},
    { label: 'Fuel Consumed', key: "fuelComsumed"},
    { label: 'Total Mileage(KM)', key: "totalMileage"},
    { label: 'iButton', key: "iButton"},
  ];

  const hoursArray = R.unfold(h => h <= 24 ? [h, h + 1] : false, 1)

  const actions = {
    async onSubmit(event) {
      event.preventDefault();
      if (!selectedVehicle) {
        return toastr.error('Vehicle is required.')
      }
      if (!selectedDate) {
        return toastr.error('Start date is required.')
      }
      if (!selectedHours) {
        return toastr.error('Hours selection is required.')
      }
      const endDate = moment(selectedDate).add(selectedHours.value, 'hours');
   
      const interval = {
        startDate: moment(selectedDate),
        endDate: moment(endDate),
      }
      setIsLoading(true);
      const observations = await props.actions.getObservations(selectedVehicle.value, JSON.stringify({ interval }));
      setIsLoading(false);
      const filter = row => row.gpsTime !== 'N/A';
      const values = R.compose(R.filter(filter), (s) => {
        return s;
      }, R.map(transform(selectedVehicle.label)))(observations);
      setTableValues(values);
    },

    handleVehicleChange(option) {
      setSelectedVehicle(option);
    },
  }

  const loadThings = async () => {
    setIsLoading(true);
    const things = await props.actions.fetchThings();
    const mappedValues = R.map(thing => ({ value: thing.name, label: `${thing.plateNumber || 'N/A'}` }))(things);
    setThings(mappedValues);
    setIsLoading(false);
  }

  useEffect(() => {
    loadThings();
  }, [])
  return !isLoading ? (

    <div>


      <div className="row" style={{ margin: '0px 0px 25px 0' }}>

        <div className="col-lg-3 col-sm-3">
          <label>Select Vehicle</label>
          <Select
            name="form-field-selectedThing"
            value={selectedVehicle}
            searchable={true}
            onChange={actions.handleVehicleChange}
            options={things} />

        </div>
        <div className="col-lg-3 col-sm-3" >
          <label>Date</label>
          <DateTime value={selectedDate} dateFormat="DD-MM-YYYY" timeFormat="HH:mm" onChange={setSelectedDate} />

        </div>
        <div className="col-lg-3 col-sm-3" >
          <label >Duration (Hours)</label>
          <Select
            name="form-field-name"
            isClearable={false}
            isSearchable={true}
            placeholder="Select Vehicle"
            defaultValue={selectedHours}
            onChange={setSelectedHours}
            options={hoursArray.map(h => {
              return { value: h, label: h }
            })} />
        </div>
        <div className="col-lg-3" style={{ padding: '25px 0 0 25px' }}>
          <button className={`btn btn-primary search-btn  search-button`} onClick={actions.onSubmit}>Search</button>
        </div>
      </div>

      <PaginatedTable 
        title="Observation List"
        columns={columns}
        headers={headers}
        fileName="ECU-Observation-List"
        data={tableValues}/>
    </div>) : <div style={{
      'marginTop': '20%'
    }}><Spinner key="1"/></div>;
}

const mapStateToProps = state => ({ things: state.things.things });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ECUObservationPage);
