import React, {Component} from 'react';
import TextFieldGroup from "../../common/TextFieldGroup";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {validateInput} from "../../../validations/userCreate";
import toastr from "toastr";
import Groups from "../../../ducks/Groups";
import UserAuthentication from "../../../ducks/UserAuthentication";
import './index.css';
import camera from "../../../assets/img/ic_camera.png"

class UpdateGroupSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: '',
            name: "",
            image: camera,
            file: "",
            overSpeedingThreshold: null,
            errors: {}
        }
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            await this
                .props
                .actions
                .getSingle(this.props.match.params.id);

            this.setState({
                ...this.state,
                name: this.props.group.name,
                groupId: this.props.match.params.id,
                image: this.props.group.groupImage,
                overSpeedingThreshold: this.props.group.overSpeedingThreshold,
            })

        }
    }

    isValid = () => {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }

        return isValid;
    };
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    };
    async handleImageUpload(event) {

        try {

            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                if(file.type.size > 5000000){
                    toastr.error("Please select an image less than the size of 5MB.");
                    return;
                }

                if (file.type.match('image.*')) {
                    let reader = new FileReader();
                reader.onload = (e) => {

                    this.setState({
                        ...this.state,
                        image: e.target.result,
                        file
                    });
                };
                reader.readAsDataURL(file);
                } else{
                    toastr.error("Invalid file format, please select an image!");
                }


            }
        } catch (e) {
            toastr.error(e);
        }

    }
    changeOverspeedingThreshold = (value)=> {

        this.setState({...this.state, overSpeedingThreshold: value})

     }

    onSubmit = async e => {
        e.preventDefault();

        try {
            await this
                .props
                .actions
                .UpdateGroup({name: this.state.name, image: this.state.file, groupId: this.state.groupId, overSpeedingThreshold: this.state.overSpeedingThreshold});
            toastr.success("Group Updated successfully!");

            this.props.actionsAuth.updateLoggedInGroupInfo({groupImage: this.state.image, groupName: this.state.name})
            this
                .props
                .history
                .push("/account");
        } catch (e) {

            toastr.error(e.response ? e.response.data.message : e);
        }

    };

    render() {
        const {name} = this.state;
        return (
            <div>
                <div className="heading-container">
                    <h3 className="font-weight-bold">Update Group</h3>
                </div>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <a className='image-input'>

                                <label htmlFor="image-input">
                                    <img src={this.state.image || camera} alt="Company" className='image-input'/>
                                </label>
                                <input
                                    accept='image/*'
                                    type='file'
                                    id="image-input"
                                    onChange={this
                                    .handleImageUpload
                                    .bind(this)}/>
                            </a>


                            <TextFieldGroup
                                onChange={this.onChange}
                                value={name}
                                type={"text"}
                                field={"name"}
                                label={"Group Name"}
                                placeholder={"Name of the Group"}
                                error={this.state.errors.name}/>



                        </div>

                    </div>

                    <button className='btn auxo-primary-btn' type='submit'>Update</button>
                </form>
            </div>
        );
    }
}

UpdateGroupSettings.propTypes = {};
UpdateGroupSettings.defaultProps = {};
const mapStateToProps = state => ({group: state.groups.group});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Groups.creators, dispatch),
    actionsAuth: bindActionCreators(UserAuthentication.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroupSettings);
