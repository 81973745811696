import Duck from 'extensible-duck'
import axios from "axios";
import {complaintsEndPoints} from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
    namespace: 'AuxoComplaints',
    store: 'complaints',
    types: [
        'COMPLAINT_IS_LOADING', 'DETAILS_FETCHED', 'COMPLAINTS_FETCHED','RESET_LIST','COMPLAINTS_COMMENTS_FETCHED'
    ],
    initialState: {
        complaint: {},
        complaints: [],
        complaintComments: [],
        isLoading: false,
        offset: 0,
        complaintsCount: 0
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.COMPLAINT_IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.COMPLAINTS_FETCHED:
                return {
                    complaints: action.complaintsData.complaints,
                    complaintsCount: action.complaintsData.complaintsCount
                };

            case duck.types.COMPLAINTS_COMMENTS_FETCHED:
                return {
                    ...state,
                    complaintComments: action.complaintComments
                };

            case duck.types.RESET_LIST:
                return {
                    ...state,
                    complaints: [],
                    offset: 0,
                    hasMoreItems: true
                };

            case duck.types.DETAILS_FETCHED:
                return {
                    ...state,
                    complaint: action.complaint
                };

            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (id) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {

                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: true});

                const response = await axios.get(complaintsEndPoints.individualComplaint(id));
                let complaint = response.data.data;
                dispatch({type: duck.types.DETAILS_FETCHED, complaint});
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
                throw e;
            }
        },

        updateComplaint: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: true});

                await axios.put(complaintsEndPoints.individualComplaint(state.id), state);

                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        addComplaint: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                await axios.post(complaintsEndPoints.individualComplaint(companyInfo.groupId), state);

                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getComplaints: ( filters,sizePerPage,page) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

            filters['sizePerPage'] = sizePerPage;
            filters['page'] = page;

            let url = complaintsEndPoints.getComplaints(companyInfo.groupId);
            const response = await axios.post(url,filters);
            // console.log("---------response-------",response.data);
            const complaintsData = response.data.data;
            dispatch({ type: duck.types.COMPLAINT_IS_LOADING, isLoading: false });
            dispatch({ type: duck.types.COMPLAINTS_FETCHED, complaintsData });

        },
        getCommentsForComplaint: ( id) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

            let url = complaintsEndPoints.getCommentsForComplaint(companyInfo.groupId,id);
            const response = await axios.get(url);
            const complaintComments = response.data.data;
            dispatch({ type: duck.types.COMPLAINT_IS_LOADING, isLoading: false });
            dispatch({ type: duck.types.COMPLAINTS_COMMENTS_FETCHED, complaintComments });

        },
        updateClosedToReopn: ( id) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

            let url = complaintsEndPoints.updateClosedToReopn(companyInfo.groupId,id);
            const response = await axios.post(url,{});
            dispatch({ type: duck.types.COMPLAINT_IS_LOADING, isLoading: false });
        },
        deleteComplaint: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().complaints.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: true});

                await axios.delete(complaintsEndPoints.individualComplaint(state.complaintId));

                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.COMPLAINT_IS_LOADING, isLoading: false});
                throw e;
            }
        },
        exportComplaints: (state) => async(dispatch, getState) => {}
    })
})