import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import * as moment from "moment";
import toastr from "toastr";
import Reports from '../../../ducks/Reports';
import Groups from "../../../ducks/Groups";
import Select from 'react-select-nested-group';
import DateTime from "react-datetime";
import forEach from 'lodash/forEach';
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import './index.css';
import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';
import 'react-datepicker/dist/react-datepicker.css';
import  MileageChart from './barChart';

let mockData = [
  [
    {
        "plateNumber": "FDC-1619",
        "totalDistance": "85.95",
        "startKm": "25145.38",
        "endKm": "25231.32",
        "date": "2024-05-25T08:48:24.121Z",
        "comment": ""
    },
    {
        "plateNumber": "ANK-331",
        "totalDistance": "36.98",
        "startKm": "52082.50",
        "endKm": "52119.48",
        "date": "2024-05-25T08:48:24.121Z",
        "comment": ""
    }
],
[
    {
        "plateNumber": "FDC-1619",
        "totalDistance": "85.13",
        "startKm": "25231.32",
        "endKm": "25316.46",
        "date": "2024-05-26T00:00:00.000Z",
        "comment": ""
    },
    {
        "plateNumber": "ANK-331",
        "totalDistance": "0.00",
        "startKm": "52119.48",
        "endKm": "52119.48",
        "date": "2024-05-26T00:00:00.000Z",
        "comment": ""
    }
]
]

class MileageReport extends Component {

  constructor() {
    super();

    this.state = {
      reportFor: '',
      selectdRow:{},
      mapVisible:false,
      isLoadingRecords: true,
      selectAllVehicles:false,
      notResponding:false,
      orderBy: "asc",
      scheduleVisible: false,
      savingSingleRecord: [],
      selectedVehicles: [],
      vehicles: [],
      groups:[],
      selectedGroups: [],
      selectedStartDate: moment().startOf('day'),
      selectedEndDate: moment().endOf('day'),
      tablevisible:false,
      graphVisible: false,
      detailsTableVisible: false,
      sortType: "",
      page: 1,
      data: [],
      child: {},
      sizePerPage: 10,
      isCompleted: false
    };
  };


 async componentDidMount () {
    this.setState({ ...this.state,isLoadingRecords: false});

    await this
        .props
        .groupActions
        .getGroupsAndVehicles();


    this.setState({
      ...this.state,
      vehicles: this.props.groupsAndVehicles.vehicles,
      groups: this.props.groupsAndVehicles.groups
    });
  }

  async onUpdate(row) {
    await this.setState({
      ...this.state,
      selectdRow: row,
      mapVisible: true
    });
    this.child.loadData(row)
  }

  async searchServerComplaint() {

    if (!this.state.selectedVehicles.length && !this.state.selectedGroups.length) {
      toastr.error("Please Select Vehicles or Group");
      return;
    }
    this.setState({ ...this.state,isLoadingRecords: true});

    let mileageTableState = this.props.mileageTableState

    if (Object.keys(mileageTableState).length == 0) {
      let selectedVehicles = [];
      if (this.state.selectAllVehicles) {
        selectedVehicles = this.state.vehicles.map(item => {
          return item._id;
        });
      } else if (this.state.selectedVehicles){
        selectedVehicles = this.state.selectedVehicles.map(item => {
          return item.value;
        });
      }
    
      let selectedGroups = [];
      if (this.state.selectedGroups) {
        selectedGroups = this.state.selectedGroups.map(item => {
          return item.value;
        });
      }
  
      let data = {
        vehicles : selectedVehicles,
        groups: selectedGroups,
        interval : {
          "startDate": new Date(this.state.selectedStartDate).toISOString(),
          "endDate":  new Date(this.state.selectedEndDate).toISOString(),
          "startTime":  new Date(this.state.selectedStartDate).toISOString(),
          "endTime":  new Date(this.state.selectedEndDate).toISOString()
        },
        "reportType": "mileageReport"
      };

      mileageTableState = data;
    };

    await this.props.reportsActions.exportReport(mileageTableState);
    this.setState({...this.state,isLoadingRecords: false});
    toastr.success("Report created and can be viewed in View Reports");
  }

  async searchDataForReport() {
    try {
      if (!this.state.selectedVehicles.length && !this.state.selectedGroups.length) {
        toastr.error("Please Select Vehicles or Group");
        return;
      }
      let selectedVehicles = [];
      if (this.state.selectAllVehicles) {
        selectedVehicles = this.state.vehicles.map(item => {
          return item._id;
        });
      } else if (this.state.selectedVehicles){
        selectedVehicles = this.state.selectedVehicles.map(item => {
          return item.value;
        });
      }
    
      let selectedGroups = [];
      if (this.state.selectedGroups) {
        selectedGroups = this.state.selectedGroups.map(item => {
          return item.value;
        });
      }
  
      const numberOfDaysSelected = moment(this.state.selectedEndDate).diff(moment(this.state.selectedStartDate));
      this.setState({...this.state, columns: {...this.state.columns, }})
      let data = {
        vehicles : selectedVehicles,
        group: selectedGroups,
        interval : {
          "startDate": new Date(this.state.selectedStartDate).toISOString(),
          "endDate":  new Date(this.state.selectedEndDate).toISOString(),
          "startTime":  new Date(this.state.selectedStartDate).toISOString(),
          "endTime":  new Date(this.state.selectedEndDate).toISOString()
        },
        "reportType": "mileageReport",
        sizePerPage : 10,
        page :0
      };
      this.setState({ ...this.state,isLoadingRecords: true,notResponding: false});
      await this.props.reportsActions.mileageReportView(data);
      const reportForString = this.getReportForString();
      this.setState({...this.state,reportFor: reportForString, isLoadingRecords: false,page:1,tablevisible : true, graphVisible: true, detailsTableVisible: true});
      
    } catch (error) {
      console.log("Error in searchDataForMileageReport: ", JSON.stringify(error));
    }
    
  }

  formatSearchData = (data) => {
    try {
      const dataToDisplay = [];
      const groupedData = {};
      const sortOrder = [];
      data.forEach(item => {
        !sortOrder.includes(item.plateNumber) && sortOrder.push(item.plateNumber);
        dataToDisplay.push({ ...item, groupVehicle: item.plateNumber, date: moment(item.date).format("DD/MM/YYYY") });
        if (item.plateNumber in groupedData) {
          groupedData[`${item.plateNumber}`]++;
        } else
          groupedData[`${item.plateNumber}`] = 1;
      });
      const sortedData = dataToDisplay.sort((a, b) => {
        return sortOrder.indexOf(a.groupVehicle) - sortOrder.indexOf(b.groupVehicle);
      });
      return { dataToDisplay: sortedData, groupedData};
    } catch (error) {
      throw(error);
    }
  }

  createGraphData = data => {
    try {
      let graphData = [];
      data.forEach(item => {
        if(graphData.filter(graphObj => graphObj.date === item.date).length){
          graphData = graphData.map(graphObj => graphObj.date === item.date ? {...graphObj, totalDistance: (parseFloat(graphObj.totalDistance) + parseFloat(item.totalDistance)).toFixed(2)}: graphObj)
        } else 
        graphData.push({date: item.date,
          totalDistance: parseFloat(item.totalDistance).toFixed(2)});
        
      });
      return graphData;
    } catch(error) {
      throw(error)
    }
  }

  handleTableChange = async (type, { page, sizePerPage }) => {
    let data = this.props.mileageTableState;
    data['sizePerPage'] = sizePerPage;
    data['page'] = page -1;
    this.setState({ ...this.state,isLoadingRecords: true,notResponding: false});
    await this.props.reportsActions.reportsView(data);
    this.setState({...this.state, page , isLoadingRecords: false});
  }

  async onclickIsCompleted(value){
    if (!value){ 
    }
    this.setState({ ...this.state, isCompleted: !value });

  }

closePopup() {
  this.setState({
    ...this.state,
    mapVisible: false
  });
}

searchVehicles = (value) => {
  if( value === null || value.length === 0){
    this.setState({
      ...this.state,
      selectAllVehicles: false,
      selectedVehicles: [],
    });
    return;
  }
  let selectAll = false;
  let selectedValue = null;
  
  forEach(value, item => {
    if (item.value === 'ALL') {
      this.setState({
        ...this.state,
        selectAllVehicles: true,
        selectedVehicles: [{ value: 'ALL', label: 'Select all' }],
      });
    } else {
      this.setState({
        ...this.state,
        selectAllVehicles: false,
        selectedVehicles: [...this.state.selectedVehicles, item],
      });
    }
  });

}

selectGroups = (group) => {
  if(group !== null) {
    this.setState({
      ...this.state,
      selectedGroups: [group],
    });
  } else {
    this.setState({
      ...this.state,
      selectedGroups: [],
    });
  }
}

filterNotResponding = () => {
  this.setState({
    ...this.state,
    notResponding: !this.state.notResponding
  });
}
filterCompleted = () => {
  this.setState({
    ...this.state,
    isCompleted: !this.state.isCompleted
  });
}

setSelectedStartDate = (value) => {
  this.setState({
    ...this.state,
    selectedStartDate: value,
  });
}

setSelectedEndDate = (value) => {
  this.setState({
    ...this.state,
    selectedEndDate: value,
  });
}

  getReportForString = () => {
    let reportForString = '';
    if(this.state.selectedVehicles && this.state.selectedVehicles.length >0) {
      if(this.state.selectedVehicles[0].value === "ALL")
        this.state.vehicles.forEach(item => reportForString = reportForString === '' ? item.plateNumber : reportForString + ", " + item.plateNumber );
      else 
        this.state.selectedVehicles.forEach(item => reportForString = reportForString === '' ? item.label : reportForString + ", " + item.label );
    } else if (this.state.selectedGroups && this.state.selectedGroups.length >0)
      this.state.selectedGroups.forEach(item => {
        reportForString = reportForString === '' ? item.label : reportForString + ", " + item.label;
    });
    return reportForString;
  }

  render() {
    const {groupedData,dataToDisplay} = this.props.mileageReportData && this.props.mileageReportData.length >0 && this.formatSearchData(this.props.mileageReportData);
    const columns = [
      {
        dataField: 'groupVehicle',
        text: 'Plate Number',
        formatter: (cell, row, rowIndex, extraData) => {
          // Only render cell for the first row of the group
          const rowSpan = groupedData[row.groupVehicle];
          if (
            rowIndex === 0 ||
            (rowIndex > 0 && dataToDisplay[rowIndex - 1] && dataToDisplay[rowIndex - 1].groupVehicle !== row.groupVehicle)
          ) {
            return (
              <div style={{ verticalAlign: 'middle' }} rowSpan={rowSpan}>
                {cell}
              </div>
            );
          }
          return <div style={{ visibility: 'hidden' }}>{cell}</div>;
        },
        style: (cell, row, rowIndex) => {
          if (
            rowIndex === 0 ||
            (rowIndex > 0 && dataToDisplay[rowIndex - 1] && dataToDisplay[rowIndex - 1].groupVehicle !== row.groupVehicle)
          ) {
            return {borderTop: "1px solid #dee2e6", borderBottom: "0 solid #dee2e6"}
          }
          return {borderTop: "0px solid #dee2e6", borderBottom: "0 solid #dee2e6"};
        }
      },
      {
        dataField: 'date',
        text: 'Date',
      },
      {
        dataField: 'startKm',
        text: 'Start Km'
      },
      {
        dataField: 'endKm',
        text: 'End Km'
      },
      {
        dataField: 'totalDistance',
        text: 'Distance'
      },
      {
        dataField: 'comment',
        text: 'Comments'
      }
    ]
    const topTableColumns = [
    {
      dataField: 'reportFor',
      text: 'Report For',
    },
    {
      dataField: 'dateRange',
      text: 'Report Time Period',
    },
    {
      dataField: 'date',
      text: 'Reported At',
    },
    {
      dataField: 'totalDistance',
      text: 'Total Distance Km'
    }];
    const dataAtTop = [{
      reportFor: this.state.reportFor,
      dateRange: `${moment(this.state.selectedStartDate).format("DD-MM-YYYY hh:mm A")} - ${moment(this.state.selectedEndDate).format("DD-MM-YYYY hh:mm A")}`,
      date: moment().format("DD-MM-YYYY hh:mm A"),
      totalDistance: this.props.totalDistance + " Kms"
    }];
    const graphData = dataToDisplay && dataToDisplay.length > 0 && this.createGraphData(dataToDisplay) || [];
    const isLoadingRecords = this.state.isLoadingRecords;
    const loader = <Spinner key="1" />;

    return (
      <>
        <div className='mileage-report-container'>
        <div style={{ textAlign: 'center' }}>
          <label>
            <b>Select Filters</b>
            <br></br>
          </label>
        </div>
          <div className='searchDiv'>
            <div className='filtersWrapper'>
              <div className='tableRow'>
              <div className="col-sm-2" >
                <label>Group</label>
                <Select
                    isDisabled={this.state.selectedVehicles && this.state.selectedVehicles.length >0}
                    name='form-field-name'
                    isClearable={true}
                    placeholder='Select Group'
                    onChange={this.selectGroups}
                    value={this.state.selectedGroups}
                    options={this.state.groups.map(item => {
                      return {
                        value: item._id,
                        label: item.name
                      }
                    })}
                  /> 
              </div> 
              <div className="col-sm-2">
                <label>Vehicles</label>
                <Select
                    isDisabled={this.state.selectedGroups && this.state.selectedGroups.length >0}
                    name='form-field-name'
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    placeholder='Select Vehicles'
                    onChange={this.searchVehicles}
                    value={this.state.selectedVehicles}
                    options={[{
                      value: 'ALL',
                      label: 'Select all'
                    }, ...this.state.vehicles.map(item => {
                      return {
                        value: item._id,
                        label: item.plateNumber
                      }
                    })]}
                    /> 
              </div>
              
              <div className="col-md-2">
                <label>Start Date and Time</label>
                  <DateTime
                    value={this.state.selectedStartDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={this.setSelectedStartDate}
                  />
              </div>
              <div className="col-md-2">
                <label>End Date and Time</label>
                  <DateTime
                    value={this.state.selectedEndDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={this.setSelectedEndDate}
                  />
              </div>
              <div className="col-sm-2">
                <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.searchDataForReport.bind(this)}>Search</button>
              </div>
              <div className="col-sm-2">
                <button className="btn auxo-primary-btn addComplaintBtn" onClick={this.searchServerComplaint.bind(this)}>Export</button>
              </div>
              </div>
            </div>
          
          </div>
        <div style={this.state.detailsTableVisible && {marginTop: "-180px"} || {}}>
            {isLoadingRecords && loader }
            {!isLoadingRecords && this.props.totalDistance && this.state.detailsTableVisible && <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={dataAtTop}
                  columns={topTableColumns}
                />
              </div>}
            {
              !isLoadingRecords && this.state.graphVisible && graphData &&
                graphData.length > 0 &&
                <div className='mileageGraphContainer'>
                  <MileageChart data={graphData} />
                </div>
                
            }
            {
              !isLoadingRecords && this.state.tablevisible && dataToDisplay &&
              dataToDisplay.length > 0 &&
              <div>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={dataToDisplay}
                  columns={columns}
                  onTableChange={this.handleTableChange}
                  extraData={{ groupedData, data: dataToDisplay }}
                />
              </div>
            }
            {!dataToDisplay && !graphData && !dataToDisplay.length && !graphData.length ? <div>There is no Data To Display</div>: ""}
        </div>
      </div>
      </>
    );
  }
}

MileageReport.propTypes = {};
MileageReport.defaultProps = {};
const mapStateToProps = state => (
  { 
    isLoading: state.complaints.isLoading,
    groupsAndVehicles: state.groups.groupsAndVehicles,
    mileageReportData : state.Reports.mileageData,
    totalDistance: state.Reports.totalDistance,
    eventsCount : state.Reports.eventsCount,
    sizePerPage : state.Reports.sizePerPage,
    page : state.Reports.page,
    mileageTableState : state.Reports.mileageTableState,
  }
);

const mapDispatchToProps = dispatch => ({
  reportsActions: bindActionCreators(Reports.creators, dispatch),
  groupActions: bindActionCreators(Groups.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MileageReport);
