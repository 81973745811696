import Duck from 'extensible-duck'
import axios from "axios";
import moment from "moment";
import { reportScheduleEndpoints } from "../api/endpoints";
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

export default new Duck({
  namespace: 'ReportSchedule', store: 'report_schedule',
  types: ['IS_LOADING', 'VEHICLE_LIST_FETCHED', 'JOB_SAVING', 'JOB_LIST_FETCHED'],
  initialState: {
    vehicleList: [],
    isLoading: false,
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };
      case duck.types.VEHICLE_LIST_FETCHED:
        return {
          ...state,
          vehicleList: action.vehicleList
        };
      case duck.types.JOB_LIST_FETCHED:
        return {
          ...state,
          reportScheduleList: action.reportScheduleList
        }
      default:
        return state;
    }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({
    getVehicelList: () => async (dispatch, getState) => {
      try {
        if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
        }
        // if (getState().reportSchedule.isLoading) {
        //   // Don't issue a duplicate request (we already have or are loading the requested data)
        //   return;
        // }
     

        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const { companyId } = JSON.parse(localStorage.getItem("companyInfo"));
        const response = await axios.get(reportScheduleEndpoints.vehicleList(companyId));
        const vehicleList = response.data.data;
        dispatch({ type: duck.types.VEHICLE_LIST_FETCHED, vehicleList });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });

      }
      catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        console.log('error', e);
      }
    },
    saveScheduleReport: (scheduleReport) => async (dispatch, getState) => {
      try {
        if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
        }
        if (getState().reportSchedule.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
        }
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const { companyId } = JSON.parse(localStorage.getItem("companyInfo"));
        
        const { _id } = scheduleReport;
        if (_id) {
           await axios.put(reportScheduleEndpoints.reportWithId(_id), { ...scheduleReport, company: companyId });
        } else {
          await axios.post(reportScheduleEndpoints.basePath(), { ...scheduleReport, company: companyId });
        }
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
      }
      catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        console.log('error', e);
      }

    },
    deleteReportSchedule: (id) => async (dispatch, getState) => {
      try {
        if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
        }
        if (getState().reportSchedule.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
        }
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        
        await axios.delete(reportScheduleEndpoints.reportWithId(id));
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
      }
      catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        console.log('error', e);
      }

    },
    getReportScheduleList: () => async (dispatch, getState) => {
      try {
        if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
        }
        // if (getState().reportSchedule.isLoading) {
        //   // Don't issue a duplicate request (we already have or are loading the requested data)
        //   return;
        // }
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const { companyId } = JSON.parse(localStorage.getItem("companyInfo"));
        const response = await axios.get(reportScheduleEndpoints.basePath(companyId));
        const reportScheduleList = response.data.data;
        dispatch({ type: duck.types.JOB_LIST_FETCHED, reportScheduleList });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });

      }
      catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        console.log('error', e);
      }
    }
  })
})