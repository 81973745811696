import React, { Component } from 'react';
import { connect } from "react-redux";
import Schedule from "../../ducks/Schedule";
import Modal from 'react-bootstrap4-modal';
import { bindActionCreators } from "redux";
import { validateInput } from "../../validations/schedule";
import './index.css';
import toastr from "toastr";
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select-nested-group';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';
const now = moment().hour(0);


let days = [
    {
        value: 1,
        label: "Monday"
    },
    {
        value: 2,
        label: "Tuesday"
    },
    {
        value: 3,
        label: "Wednesday"
    },
    {
        value: 4,
        label: "Thursday"
    },
    {
        value: 5,
        label: "Friday"
    },
    {
        value: 6,
        label: "Saturday"
    },
    {
        value: 7,
        label: "Sunday"
    },
]


class AddSlot extends Component {

    constructor() {
        super();
        this.state = {
            startDay: "",
            endDay: "",
            startTime: now.format("HH"),
            endTime: now.format("HH"),
            scheduleId: "",
            errors: {}
        }
    }

    async componentDidMount() {
        let scheduleId = this.props.match.params.scheduleId;
        if (scheduleId) {
            this.setState({ ...this.state, scheduleId })
        }
    }

    handleStartDayChange(value) {
        if (value) {
            this.setState({
                ...this.state,
                startDay: value.value
            })
        }

    }
    handleEndDayChange(value) {
        if (value) {
            this.setState({
                ...this.state,
                endDay: value.value
            })
        }
    }
    onStartTimeChange(value) {
        if (value) {
            this.setState({ ...this.state, startTime: value.format("HH:mm") })
        }

    }
    onEndTimeChange(value) {
        if (value) {
            this.setState({ ...this.state, endTime: value.format("HH:mm") })
        }
    }

    navigateToList() {
        this.props.history.goBack();
    }

    isValid = () => {

        const { errors, isValid } = validateInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    };


    async onSubmit() {

        if (this.isValid()) {
            try {
                const state = this.state;
                if (!state.scheduleId) {
                    return toastr.error("No schedule seleted");
                }

                const slotData = {
                    startDay: state.startDay,
                    endDay: state.endDay,
                    startTime: state.startTime,
                    endTime: state.endTime,
                    scheduleId: state.scheduleId
                }

                await this.props.actions.addSlot(slotData);
                toastr.success("Slot created successfully!");
                this.navigateToList()

            } catch (e) {
                toastr.error(e.response ? e.response.data.message : e);
            }
        }
    };

    render() {
        return (

            <Modal
                visible={true}
                onClickBackdrop={this.modalBackdropClicked}
                dialogClassName='driver-modal-dialog'>
                <div>
                    <button
                        type="button"
                        className="close close-x"
                        aria-label="Close"
                        onClick={this
                            .navigateToList
                            .bind(this)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='modal-heading row'>
                    <div className="col-lg-4 col-md-4 col-sm-4"> <p className="car-and-device-assig">Add a new Slot</p></div>

                    <div className="col-lg-8 col-md-8 col-sm-8">


                    </div>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-6">

                            <div className='form-group'>
                                <label>Start Day</label>
                                <Select
                                    name="form-field-name"
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={this.handleStartDayChange.bind(this)}
                                    options={days
                                        .map(item => {
                                            return { value: item.value, label: item.label }
                                        })} />
                                {this.state.errors.startDay && <span className="text-danger">{this.state.errors.startDay}</span>}
                            </div>

                            <div className='form-group'>
                                <label>End Day</label>
                                <Select
                                    name="form-field-name"
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={this.handleEndDayChange.bind(this)}
                                    options={days
                                        .map(item => {
                                            return { value: item.value, label: item.label }
                                        })} />
                                {this.state.errors.endDay && <span className="text-danger">{this.state.errors.endDay}</span>}

                            </div>



                        </div>
                        <div className="col-lg-6">
                            <div className='form-group'>
                                <label>Start Time</label>
                                <TimePicker
                                    showSecond={false}
                                    showMinute={true}
                                    allowEmpty={false}
                                    defaultValue={now}
                                    className="time-selector"
                                    onChange={this.onStartTimeChange.bind(this)}
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                />
                                {this.state.errors.startTime && <span className="text-danger">{this.state.errors.startTime}</span>}

                            </div>
                            <div className='form-group'>
                                <label>End Time</label>
                                <TimePicker
                                    showSecond={false}
                                    showMinute={true}
                                    allowEmpty={false}
                                    defaultValue={now}
                                    className="time-selector"
                                    onChange={this.onEndTimeChange.bind(this)}
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                />
                                {this.state.errors.endTime && <span className="text-danger">{this.state.errors.endTime}</span>}

                            </div>
                        </div>

                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn close-btn"
                        onClick={this
                            .onSubmit
                            .bind(this)}
                    >Add Slot</button>
                </div>
            </Modal>

        )
    }

}

AddSlot.propTypes = {};
AddSlot.defaultProps = {};
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Schedule.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AddSlot);
