import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateTime from "react-datetime";
import toastr from "toastr";
import {  map } from "lodash";
import moment from "moment";
import { Card, CardHeader,CardTitle,CardBody } from "reactstrap";
import Select from "react-select-nested-group";
import {
  LineChart,
  ResponsiveContainer,
  BarChart, Bar,
  XAxis,
  Legend,
  CartesianGrid,
  YAxis,
  Tooltip,
  Line
} from "recharts";
import Visualization from "../../ducks/Visualization";
import Things from "../../ducks/Things";
import Company from "../../ducks/Company";
import Groups from "../../ducks/Groups";
import { Spinner } from '../common/Spinner/index';
import { filterVehicleListing } from "../../utils/helper.util";
import SelectComponent from "../select";
import "./index.css";

class TripsBarChart extends Component {
  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    this.state = {
      selectedGroup: [],
      defaultSearchValue: null,
      searchValue: [],
      searchTerm: "",
      zoom: 5.5,
      vehicleNumberPlate: "",
      loading: false,
      isLabelEnable: false,
      selectedVehicle: null,
      filterClicked:false,
      searchVehicleValues: [],
      groupId: companyInfo ? companyInfo.groupId : "",
      companyId: companyInfo ? companyInfo.companyId : "",
      geofence: false,
      routefence: false,
      mapLoading: false,
      startDate: moment().subtract("1", "days"),
      endDate: moment(),
    };
  }

  async componentDidMount() {
    if (this.state.groupId !== "") {
      const actions = [this.props.actions.fetchThings(true)];
      if (!(this.props.groupList && this.props.groupList.length)) {
        actions.push(this.props.groupActions.getSubGroups(this.state.groupId));
      }
      await Promise.all(actions).then(() =>
        this.setState({
          ...this.state,
          searchVehicleValues: map(this.props.things, (item) => ({
            value: item._id,
            label: item.plateNumber || item.imei.substr(-8),
            item: item,
          })),
        })
      );
    }
  }

  selectGroup = (selectedGroup) => {
    const filteredThings = filterVehicleListing(
      this.props.things,
      selectedGroup
    );
    let searchValue = map(filteredThings, (item) => ({
      value: item._id,
      label: item.plateNumber || item.imei.substr(-8),
      item: item,
    }));
    this.setState({ selectedGroup , searchValue });
  } 

  filterVehicle = async (value) => {
    await this.setState({ ...this.state, searchValue: value });
  };

  onChangeStartDate = (e) => {
    this.setState({ ...this.state, startDate: new Date(e) });
  };
  onChangeEndDate = (e) => {
    this.setState({ ...this.state, endDate: new Date(e) });
  };

  SubmitServerQuery = async (e) => {
 
    //call api
    if (!this.state.searchValue.length){
       toastr.error("Please Select Thing");
       return;
    };

    this.setState({
      ...this.state,
      loading: true,
    });
    const requestObject = {
      vehicles: this.state.searchValue,
      startDate: this.state.startDate.toISOString(),
      endDate: this.state.endDate.toISOString(),
    };

    await this.props.chartActions.loadTripsChartData(requestObject);
    this.setState({
      ...this.state,
      loading: false,
      filterClicked:true
    });
  };

  render() {
    let loader = <Spinner key="1" />;
    const permissions = this.props.permissions;

    let violations = this.props?.tripsChartData?.violations;

    return (
      <div>
        <div>
          <div className="filterDiv">
            <div className="row">
              <div className="col-md-12">
                <Card style={{height:"140px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                    <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Search Filters</CardHeader>
                      <div className="filtersParent">
                        <div className="col-md-2">
                          <div>
                            {permissions.companyFeatures.indexOf("GROUPS") !== -1 && (
                              <SelectComponent
                                field="form-field-name"
                                placeholder="Select Group"
                                preSelected={this.state.selectedGroup}
                                multi={true}
                                optionKeys={{ label: "name", value: "_id" }}
                                options={this.props.groupList}
                                onChange={this.selectGroup}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <div className="form-group">
                              <Select
                                name="form-field-name"
                                isClearable={true}
                                isMulti
                                isSearchable={true}
                                placeholder="Search"
                                onChange={this.filterVehicle}
                                value={this.state.searchValue}
                                options={this.state.searchVehicleValues}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <div className="dateSelector">
                              <label>Start Date</label>
                              <DateTime
                                className="datePicker"
                                value={this.state.startDate}
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                onChange={this.onChangeStartDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <div className="dateSelector">
                              <label>End Date</label>
                              <DateTime
                                className="datePicker"
                                value={this.state.endDate}
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                onChange={this.onChangeEndDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div>
                            <div className="form-group">
                              <button className="btn btn-primary" onClick={this.SubmitServerQuery}>Search</button>
                            </div>
                          </div>
                        </div>
                      </div>
                </Card>
              </div>
            </div>
          </div>

          {this.state.loading ? (
            [loader]
              ) : (
              this.props?.tripsChartData?.makeObject?.length ? (
                <div className="statsDiv">
                  <div className="cardParent row">
                    <div className="col-md-12">
                            <Card style={{height:"270px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                            <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Total Count</CardHeader>
                            <div style={{display: "flex",width: "100%",justifyContent: 'center'}}>
                              <div className="maintainance-card totalCountsRow ">
                                <div className={"card-display maintainance-card-width"}>
                                  <Card className="height-auto text-center cardInner">
                                    <CardBody>
                                      <CardTitle style={{height: "50%"}}>
                                        <div>
                                          <span className="card-heading">Trips</span>
                                        </div>
                                      </CardTitle>
                                      <div style={{height: "50%"}} className="card-data">
                                        {this.props?.tripsChartData?.totalCount?.Trips}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                              <div className="maintainance-card totalCountsRow ">
                                <div className={"card-display maintainance-card-width"}>
                                  <Card className="height-auto text-center cardInner">
                                    <CardBody>
                                      <CardTitle style={{height: "50%"}}>
                                        <div>
                                          <span className="card-heading">
                                          Distance
                                          </span>
                                        </div>
                                      </CardTitle>
                                      <div style={{height: "50%"}} className="card-data">
                                      {this.props?.tripsChartData?.totalCount?.Distance} 
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                              <div className="maintainance-card totalCountsRow ">
                                <div className={"card-display maintainance-card-width"}>
                                  <Card className="height-auto text-center cardInner">
                                    <CardBody>
                                      <CardTitle style={{height: "50%"}}>
                                        <div>
                                          <span className="card-heading">Violations</span>
                                        </div>
                                      </CardTitle>
                                      <div style={{height: "50%"}} className="card-data">
                                      {this.props?.tripsChartData?.totalCount?.Violations}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                              <div className="maintainance-card totalCountsRow ">
                                <div className={"card-display maintainance-card-width"}>
                                  <Card className="height-auto text-center cardInner">
                                    <CardBody>
                                      <CardTitle style={{height: "50%"}}>
                                        <div>
                                          <span className="card-heading">Fuel Consumed</span>
                                        </div>
                                      </CardTitle>
                                      <div style={{height: "50%"}} className="card-data">
                                      {this.props?.tripsChartData?.totalCount?.FuelConsumed}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                            </div>
                            </Card>
                      </div>
                  </div>
                  <div className="cardParent row">
                    <div className="col-md-12">
                            <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                              <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Trips Against Vehicles</CardHeader>
                              <ResponsiveContainer width="100%" height="100%">
                                <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="thingName" label={{
                                    value: "Things",
                                    position: "insideBottomLeft",
                                    offset: -8,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <YAxis label={{
                                    value: "Trips",
                                    angle: -90,
                                    position: "left",
                                    offset: -4,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="totalTrips" fill="#82ca9d" />
                                </BarChart >
                              </ResponsiveContainer>              
                            </Card>
                    </div>
                  </div>
                  <div className="cardParent row">
                    <div className="multiCards col-md-12">
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "40%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Violations Against Vehicles</CardHeader>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="thingName" label={{
                              value: "Things",
                              position: "insideBottomLeft",
                              offset: -8,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <YAxis label={{
                              value: "Counts",
                              angle: -90,
                              position: "left",
                              offset: -4,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="totalViolations" fill="#82ca9d" />
                          </BarChart >
                        </ResponsiveContainer>              
                      </Card>
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "60%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Fule Consumed Against Vehicles</CardHeader>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="thingName" label={{
                              value: "Things",
                              position: "insideBottomLeft",
                              offset: -8,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <YAxis label={{
                              value: "Fuel Consumed",
                              angle: -90,
                              position: "left",
                              offset: -4,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="fuelConsumed" fill="#82ca9d" />
                          </BarChart >
                        </ResponsiveContainer>              
                      </Card>
                    </div>
                  </div>
                  {/* <div className="cardParent row">
                    <div className="col-md-12">
                            <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                              <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Fule Consumed Against Vehicles</CardHeader>
                              <ResponsiveContainer width="100%" height="100%">
                                <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="thingName" label={{
                                    value: "Things",
                                    position: "insideBottomLeft",
                                    offset: -8,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <YAxis label={{
                                    value: "Fuel Consumed",
                                    angle: -90,
                                    position: "left",
                                    offset: -4,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="fuelConsumed" fill="#82ca9d" />
                                </BarChart >
                              </ResponsiveContainer>              
                            </Card>
                    </div>
                  </div> */}
                  <div className="cardParent row">
                    <div className="col-md-12">
                            <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                              <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Distance Against Vehicles</CardHeader>
                              <ResponsiveContainer width="100%" height="100%">
                                <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="thingName" label={{
                                    value: "Things",
                                    position: "insideBottomLeft",
                                    offset: -8,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <YAxis label={{
                                    value: "Distance",
                                    angle: -90,
                                    position: "left",
                                    offset: -4,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="distance" fill="#82ca9d" />
                                </BarChart >
                              </ResponsiveContainer>              
                            </Card>
                    </div>
                  </div>
                  <div className="cardParent row" style={{paddingBottom:"10px"}}>
                    <div className="multiCards col-md-12">
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "30%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Idle Duration Against Vehicles</CardHeader>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="thingName" label={{
                              value: "Things",
                              position: "insideBottomLeft",
                              offset: -8,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <YAxis label={{
                              value: "Idle Duration",
                              angle: -90,
                              position: "left",
                              offset: -4,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="idle" fill="#82ca9d" />
                          </BarChart >
                        </ResponsiveContainer>              
                      </Card>
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "30%" }}>
                              <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Average Speed Against Vehicles</CardHeader>
                              <ResponsiveContainer width="100%" height="100%">
                                <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="thingName" label={{
                                    value: "Things",
                                    position: "insideBottomLeft",
                                    offset: -8,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <YAxis label={{
                                    value: "Avg Speed",
                                    angle: -90,
                                    position: "left",
                                    offset: -4,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="avg_speed" fill="#82ca9d" />
                                </BarChart >
                              </ResponsiveContainer>              
                      </Card>
                      <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "30%" }}>
                        <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Average Trip Duration/Distance Against Vehicles</CardHeader>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="thingName" label={{
                              value: "Things",
                              position: "insideBottomLeft",
                              offset: -8,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <YAxis label={{
                              value: "Avg Duration/Distance",
                              angle: -90,
                              position: "left",
                              offset: -4,
                              fill: "#666666",
                              fontFamily: "Roboto"
                            }} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="avg_distance" fill="#82ca9d" />
                            <Bar dataKey="avg_duration" fill="#FF0000" />
                          </BarChart >
                        </ResponsiveContainer>              
                      </Card>
                    </div>
                  </div>
                  {/* <div className="cardParent row">
                    <div className="col-md-12">
                            <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                              <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Average Speed Against Vehicles</CardHeader>
                              <ResponsiveContainer width="100%" height="100%">
                                <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="thingName" label={{
                                    value: "Things",
                                    position: "insideBottomLeft",
                                    offset: -8,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <YAxis label={{
                                    value: "Avg Speed",
                                    angle: -90,
                                    position: "left",
                                    offset: -4,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="avg_speed" fill="#82ca9d" />
                                </BarChart >
                              </ResponsiveContainer>              
                            </Card>
                    </div>
                  </div>
                  <div className="cardParent row">
                    <div className="col-md-12">
                            <Card style={{height:"390px",paddingBottom:"10px",flexDirection: "column", minWidth: "100%" }}>
                              <CardHeader style={{ width: "100%",marginBottom: "10px"}} className="f-bold">Average Trip Duration/Distance Against Vehicles</CardHeader>
                              <ResponsiveContainer width="100%" height="100%">
                                <BarChart  width={1020} height={250} data={this.props?.tripsChartData?.makeObject || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="thingName" label={{
                                    value: "Things",
                                    position: "insideBottomLeft",
                                    offset: -8,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <YAxis label={{
                                    value: "Avg Duration/Distance",
                                    angle: -90,
                                    position: "left",
                                    offset: -4,
                                    fill: "#666666",
                                    fontFamily: "Roboto"
                                  }} />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="avg_distance" fill="#82ca9d" />
                                  <Bar dataKey="avg_duration" fill="#FF0000" />
                                </BarChart >
                              </ResponsiveContainer>              
                            </Card>
                    </div>
                  </div> */}
                </div>
              ) :  this.state.filterClicked ? ('There is no Data') : ''
          )}
        </div>
      </div>
    );
  }
}

TripsBarChart.propTypes = {};
TripsBarChart.defaultProps = {};
const mapStateToProps = (state) => {
  return {
    things: state.things.markers,
    groupList: state.groups.subGroups,
    permissions: state.permissions,
    company: state.company,
    selectedVehicleCords: state.things.selectedVehicleCords,
    tripsChartData: state.visualization.tripsChartData.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch),
  chartActions: bindActionCreators(Visualization.creators, dispatch),
  groupActions: bindActionCreators(Groups.creators, dispatch),
  companyActions: bindActionCreators(Company.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(TripsBarChart);
