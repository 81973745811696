const axios = require('axios');

const retryApiCall = async (apiCall, retries = 3, timeout = 120000) => {
  console.log("retryApiCall")
  const source = axios.CancelToken.source();

  const timeoutId = setTimeout(() => {
    console.log("timeout -> ", timeout)
    source.cancel(`Request timed out after ${timeout / 1000} seconds.`);
  }, timeout);

  try {
    const response = await apiCall(source.token);
    clearTimeout(timeoutId);
    return response;
  } catch (error) {
    clearTimeout(timeoutId);
    if (retries > 0 && (error.response?.status === 504 || error.code === 'ECONNABORTED' || error.message.includes('timeout') || error.message.includes('Request timed out'))) {
      console.warn(`API call failed. Retrying... Remaining retries: ${retries - 1}`);
      return retryApiCall(apiCall, retries - 1, timeout);
    } else {
      throw error;
    }
  }
}


export default retryApiCall;