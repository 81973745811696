import isEmpty from 'lodash/isEmpty';
import { isAlphaNumeric, isEmptyValue } from './validator';

export function validateInput(data) {
  const errors = {};

  if (isEmptyValue(data.name)) errors.name = 'This field is required';
  else if (!isAlphaNumeric(data.name)) errors.name = 'Group name must be alphanumeric';
  else if (data.name.length > 30) errors.name = 'Max 30 characters are allowed in name';

  if (!isAlphaNumeric(data.pointOfContactDetails)) errors.name = 'Point of contact details must be alphanumeric';
  else if (data.pointOfContactDetails.length > 200) errors.name = 'Max 200 characters are allowed in point of contact details.';

  return { errors, isValid: isEmpty(errors) };
}
