import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import * as _ from 'lodash';
import moment from 'moment';
import Icon from 'react-icons-kit';
import Alert from 'react-bootstrap/Alert'
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form'
import Select from 'react-select-nested-group';
import { subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { Popover, PopoverBody } from 'reactstrap';

import Users from '../../ducks/Users';
import Things from '../../ducks/Things';

import { Spinner } from '../common/Spinner/index';


import './index.css';
import ic_violations from '../../assets/img/ic_violations.png'
import ic_harsh_accelerations from '../../assets/img/ic_harsh_accelerations.png'
import ic_harsh_brakes from '../../assets/img/ic_harsh_brakes.png'
import ic_harshturn from '../../assets/img/ic_harshturn.png'
import ic_speed_limit from '../../assets/img/ic_speed_limit.png'
import ic_vehicle_entering from '../../assets/img/geofence_entry.png'
import ic_vehicle_leaving from '../../assets/img/geofence_exit.png'
import ic_plugout from '../../assets/img/ic_plugout.png'
import ic_plugin from '../../assets/img/ic_plugin.png'
import ic_ignition_on from '../../assets/img/ic_ignition_on.png';
import ic_ignition_off from '../../assets/img/ic_ignition_off.png';
import ic_vehicle_idle from '../../assets/img/ic_vehicle_idle.png';
import fatigue from '../../assets/img/fatigue.png';
import seatbelt from '../../assets/img/seatbelt.png'
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import calenderIcon from '../../assets/img/calender.png';
import MapModal from '../common/maps/MapModal';

const queryString = require('query-string');

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>

  </div>
);

let restrictedHours = false;
let timeCheckCondition = false;
const groupedOptions = [
  {
    label: 'Geofence',
    options: [{ value: 'geoFenceEntry', label: 'Geofence entry' },
    { value: 'geoFenceExit', label: 'Geofence exit' }],
  },
  {
    label: 'Routefence',
    options: [{ value: 'routeFenceEntry', label: 'Routefence entry' },
    { value: 'routeFenceExit', label: 'Routefence exit' }],
  },
  {
    label: 'Violations',
    options: [{ value: 'harshAcceleration', label: 'Harsh acceleration' },
    { value: 'harshBrake', label: 'Sudden brake' },
    { value: 'sharpTurn', label: 'Sharp turn' },
    { value: 'overSpeeding', label: 'Overspeeding' },
    { value: 'unfasten_seatbelt', label: 'Unfasten seatbelt' },
    { value: 'fatigue', label: 'Fatigue ' },
    { value: 'restFatigue', label: 'Rest Fatigue' },
    { value: 'highRpm', label: 'High RPM' },
    ],
  },
  {
    label: 'Vehicle Status',
    options:
      [{ value: 'vehicleMoving', label: 'Moving' },
      { value: 'vehicleParked', label: 'Vehicle parked' },
      { value: 'vehicleIdle', label: 'Vehicle Idle' },
      { value: 'vibration', label: 'Vibration' },
      { value: 'impact', label: 'Impact' },
      { value: 'ignitionOn', label: 'Ignition' },
      ],
  },
  {
    label: 'Device Status',
    options:
      [{ value: 'vehicleOffline', label: 'Device unplugged' },
      { value: 'vehicleOnline', label: 'Device pluggedin' }],
  },
  {
    label: 'Door',
    options:
      [
      { value: 'doorStatus', label: 'Door Status' },
      ],
  },
  {
    label: 'Maintenance',
    options:
      [{ value: 'tyreChange', label: 'Tyre Change' },
      { value: 'oilChange', label: 'Oil Change' },
    ],
  },
  {
    label: 'Vehicle Info',
    options:
      [{ value: 'lowFuel', label: 'Low Fuel' },
      { value: 'engineHeatup', label: 'Engine Heatup' },
      ],
  },
  {
    label: 'Other',
    options:
      [{ value: 'unknownDriver', label: 'Unknown Driver' },
      { value: 'panic_button', label: 'Panic Button' },
      { value: 'jamming', label: 'Jamming' },
      { value: 'driverDetected', label: 'Driver Detected' },
      ],
  },
];

class NotificationsList extends Component {

  constructor() {
    super();
    this.state = {
      user: {},
      popoverOpen: false,
      loading: true,
      vehicleNumberPlate: [],
      notificationSelected: [],
      notifications: [],
      dateRangePicker: {
        selection: {
          startDate: subDays(new Date(), 2),
          endDate: new Date(),
          key: 'selection',
        },
      },
      collapse: [],
      showModal: false,
      lat: 0,
      lng: 0
    }
    this.closeModal = this.closeModal.bind(this);

  }

  async toggleCollapse(index) {
    const { collapse } = this.state;
    collapse[index] = !collapse[index];
    this.setState({ ...this.state, collapse });

  }

  async getNotification(loadMore) {
    if (!loadMore) {
      await this.setState({
        ...this.state,
        loading: true
      })
    }
    let obj = { interval: this.state.dateRangePicker.selection };

    let vehicels = [];
    let notificationSelected = [];

    if (this.state.vehicleNumberPlate) {
      vehicels = _.map(this.state.vehicleNumberPlate, (item) => item.value);
      obj.vehicles = vehicels
    }
    if (this.state.notificationSelected) {
      notificationSelected = _.map(this.state.notificationSelected, (item) => item.value);
      obj.type = notificationSelected
    }

    await this.props.actions.getUserNotifications(obj, loadMore);

    this.setState({
      ...this.state,
      loading: false,
      notifications: this.props.notifications.map(notification => ({
        ...notification,
        comments: notification.comments ? notification.comments : []
      }))
    })

  }

  async newComment(item, index) {
    const { newComment: comment, _id: id } = item;
    if (!comment) return;
    await this.props.actions.newComment(id, comment);
    const notifications = this.state.notifications.map(notification => {
      if (notification._id === id) {
        return {
          ...notification,
          newComment: '',
          comments: [{
            userName: `${this.state.user.firstName} ${this.state.user.lastName}`,
            _id: moment().format(),
            comment,
            createdAt: moment().format()
          }, ...notification.comments]
        }
      }
      return notification;
    });
    this.setState({ ...this.state, notifications });
  }

  async componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    // this.setState
    if (qs.thing) {
      const startDate = moment(qs.date).toDate();
      const endDate = moment(qs.date).add(8, 'hours').toDate();
      const selection = { key: 'selection', startDate, endDate };
      const dateRangePicker = { selection };
      await this.setState({
        ...this.state,
        dateRangePicker,
        vehicleNumberPlate: [{ value: qs.thing, label: qs.plate }]
      });
    }

    setTimeout(() => this.getNotification(false), 2000);
    await this.props.thingsActions.getVehiclesPlateNumber();

    const userId = localStorage.getItem('user_id');
    const user = await this.props.actions.getUser(userId);

    this.setState({ ...this.state, user });
  }

  searchVehicle = async (selectedOption) => {
    await this.setState({ ...this.state, vehicleNumberPlate: selectedOption });
    this.getNotification(false);
  };

  selectNotification = async (selectedOption) => {
    await this.setState({ ...this.state, notificationSelected: selectedOption || [] });
    this.getNotification(false);
  };

  toggle = () => {
    this.setState({ ...this.state, popoverOpen: !this.state.popoverOpen });
  };

  async handleRangeChange(which, payload) {

    await this.setState({
      [which]: { ...this.state[which], ...payload, },
    });

    setTimeout(() => this.getNotification(false), 3000);
  }

  async handleCommentChange(event, index) {

    const { notifications } = this.state;
    notifications[index].newComment = event.target.value;
    this.setState({ ...this.state, notifications });

  }

  displayModal(item) {
    this.setState({
      ...this.state,
      lat: item.latlng.split(",")[0],
      lng: item.latlng.split(",")[1],
      showModal: true
    })
  }

  closeModal(){
    this.setState({
      showModal: false
    })
  }

  render() {
    const loader = <Spinner key='1' />;

    const companyFeatures = this.props.permissions.companyFeatures;
    if (companyFeatures.indexOf("DRIVERHOURS") !== -1){
      if (!restrictedHours){
        restrictedHours = true;
        groupedOptions.push({
          label: 'Restricted Hours',
          options:
          [
            {value : 'vehicleMovingRestrictedHours', label : 'Vehicle Moving Restricted Hours'},
            {value : 'ignitionOnRestrictedHours', label : 'Ignition On Restricted Hours'}
          ]
        });
      }
    }
    if (companyFeatures.indexOf("TIMECHECK") !== -1){
      if (!timeCheckCondition){
        timeCheckCondition = true;
        groupedOptions.push({
          label: 'Time Check',
          options:
          [
            {value : 'timeCheck', label : 'Vehcile Time Exceed'}
          ]
        });
      }
    }

    if (this.state.notifications.filter(notification => {
      if (this.state.vehicleNumberPlate && this.state.vehicleNumberPlate.length) {
        return !!R.find(s => s.label === notification.plateNumber)(this.state.vehicleNumberPlate);
      }
      return true;
    })
      .filter(notification => {
        if (this.state.notificationSelected.length) return !!R.find(s => s.value === notification.type)(this.state.notificationSelected);
        return true;
      }).length > 0) {

      return (
        <>
        {this.state.showModal && this.state.lat && this.state.lng && <MapModal showMap={this.state.showModal} close={this.closeModal} lat={this.state.lat} lng={this.state.lng}/>}
        <div>
          <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-4'>
              <div className='form-group'>
                <Select
                  name='form-field-name'
                  value={this.state.vehicleNumberPlate}
                  isClearable={true}
                  isSearchable={true}
                  placeholder='Search Vehicles'
                  onChange={this.searchVehicle}
                  isMulti
                  options={this.props.vehiclesPlateNumber && this.props.vehiclesPlateNumber.map(item => ({
                    value: item._id,
                    label: item.plateNumber
                  }))}
                />
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-4'>
              <div className='form-group'>

                <Select
                  name='form-field-name'

                  isClearable={true}
                  isSearchable={true}
                  isMulti
                  placeholder='Select Notification Type'
                  onChange={this.selectNotification}
                  options={groupedOptions}
                  formatGroupLabel={formatGroupLabel} />
              </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2'>
              <div className='selected-trip-Interval '>

                <p className='margin-zero'>
                  <b>From:</b> {moment(this.state.dateRangePicker.selection.startDate).format('MMMM DD YYYY')}
                </p>
                <p className='margin-zero'>
                  <b>To:</b> {moment(this.state.dateRangePicker.selection.endDate).format('MMMM DD YYYY')}
                </p>

              </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2'>

              <div className='m'>

                <a onClick={this.toggle} id='Popover1'>
                  <img src={calenderIcon} alt='' />
                </a>
                <Popover placement='bottom' isOpen={this.state.popoverOpen} target='Popover1'
                  toggle={this.toggle.bind(this)}>
                  <PopoverBody>
                    <div>
                      <DateRangePicker
                        onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                        startDate={this.state.dateRangePicker.selection.startDate}
                        endDate={this.state.dateRangePicker.selection.endDate}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        className={'PreviewArea'}
                        months={2}
                        ranges={[this.state.dateRangePicker.selection]}
                        direction='horizontal'
                        maxDate={new Date()}
                      />

                    </div>
                  </PopoverBody>
                </Popover>

              </div>

            </div>
          </div>


          <div className='reports-header'><span className='export'>Notifications</span></div>

          <div className='notification-window'>
            {
              this.state.loading
                ? [loader]
                : <div>
                  {
                    <div>
                      {
                        this.state.notifications.filter(notification => {
                          if (this.state.vehicleNumberPlate && this.state.vehicleNumberPlate.length) return !!R.find(s => s.label === notification.plateNumber)(this.state.vehicleNumberPlate);
                          return true;
                        })
                          .filter(notification => {
                            if (this.state.notificationSelected && this.state.notificationSelected.length) return !!R.find(s => s.value === notification.type)(this.state.notificationSelected);
                            return true;
                          })
                          .map((item, index) => {
                            let icon = ic_violations;
                            switch (item.type) {
                              case 'harshAcceleration':
                                icon = ic_harsh_accelerations;
                                break;
                              case 'harshBrake':
                                icon = ic_harsh_brakes;
                                break;
                              case 'sharpTurn':
                                icon = ic_harshturn;
                                break;
                              case 'overSpeeding':
                                icon = ic_speed_limit;
                                break;
                              case 'geoFenceEntry':
                                icon = ic_vehicle_entering;
                                break;
                              case 'geoFenceExit':
                                icon = ic_vehicle_leaving;
                                break;
                              case 'vehicleMoving':
                                icon = ic_ignition_on;
                                break;
                              case 'ignitionOn':
                                icon = ic_ignition_on;
                                break;
                              case 'vehicleParked':
                                icon = ic_ignition_off;
                                break;
                              case 'vehicleOffline':
                                icon = ic_plugout;
                                break;
                              case 'vehicleIdle':
                                icon = ic_vehicle_idle;
                                break;
                              case 'vehicleOnline':
                                icon = ic_plugin;
                                break;
                              case 'vibration':
                                icon = ic_plugin;
                                break;
                              case 'unfasten_seatbelt':
                                icon = seatbelt;
                                break;
                              case 'fatigue':
                                icon = fatigue;
                                break;
                              case 'restFatigue':
                                icon = fatigue;
                                break;
                            }
                            return (
                              <div style={{ borderBottom: '1px solid #ddd' }}>
                                <div key={index} className='notifications-listing'
                                  style={{ borderBottom: 'none' }}>
                                  <span><img src={icon} className='width36' alt='' /></span>
                                  <span>
                                    <div>{item.message}</div>
                                    <span style={{ display: 'block', color: "#03abce", cursor: "pointer" }} onClick={() => this.displayModal(item)}>
                                      {item.location || 'N/A'}
                                    </span>
                                    <span>{item.plateNumber || ''}</span>
                                    <span>
                                      {moment(item.createdAt).format('DD-MMM-YY h:mm a') || 'N/A'}
                                    </span>

                                  </span>

                                </div>
                                <div onClick={() => this.toggleCollapse(index)} style={{
                                  marginBottom: '10px',
                                  marginLeft: '38px',
                                  cursor: 'pointer',
                                  fontWeight: 600,
                                }}>
                                  <span style={{ marginLeft: '0px', marginRight: '10px' }}>{this.state.collapse[index] ?
                                    <Icon size={24} icon={ic_keyboard_arrow_up} /> :
                                    <Icon size={24} icon={ic_keyboard_arrow_down} />}</span>
                                  Comments ({item.comments ? item.comments.length : 0})
                                </div>
                                <Collapse in={!!this.state.collapse[index]}>
                                  <div style={{ margin: 'auto 23px auto 28px', paddingTop: '10px' }}>
                                    <Form.Control style={{ marginBottom: '10px' }} as='textarea' rows='3'
                                      value={item.newComment} onChange={(event) => {
                                        this.handleCommentChange(event, index)
                                      }} />
                                    <button
                                      className='btn auxo-primary-btn'
                                      style={{ float: 'none', margin: '18px 0' }}
                                      onClick={() => {
                                        this.newComment(item, index)
                                      }}
                                    >Post
                                    </button>
                                    {item.comments && item
                                      .comments
                                      .sort((comment1, comment2) => {
                                        return moment(comment2.createdAt).diff(comment1.createdAt)
                                      })
                                      .map((comment, index) => {
                                        return (<Alert variant='secondary' key={comment._id}>
                                          <Alert.Heading><span
                                            style={{ fontSize: '15px' }}>{comment.userName}</span>
                                            <span
                                              style={{ fontSize: '13px' }}>{moment(comment.createdAt).format('hh:mm a DD-MM-YYYY')}</span></Alert.Heading>
                                          <p>
                                            {comment.comment}
                                          </p>

                                        </Alert>)
                                      })}


                                  </div>
                                </Collapse>

                              </div>
                            )
                          })}

                    </div>}

                </div>}

          </div>
          {this.props.hasMoreNotifications && !this.state.loading
            ? <footer className='loadmore-notifications'>
              <a
                onClick={this
                  .getNotification
                  .bind(this, true)}>Load More</a>
            </footer>
            : ''}
        </div>
                  </>
      )
    } else {
      return (
        <div>
          <div className='row'>

            <div className='col-lg-4 col-md-4 col-sm-4'>
              <div className='form-group'>
                <Select
                  name='form-field-name'
                  value={this.state.vehicleNumberPlate}
                  isClearable={true}
                  isSearchable={true}
                  placeholder='Search Vehicles'
                  onChange={this.searchVehicle}
                  isMulti
                  options={this.props.vehiclesPlateNumber && this.props.vehiclesPlateNumber.map(item => ({
                    value: item._id,
                    label: item.plateNumber
                  }))}
                />
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-4'>
              <div className='form-group'>

                <Select
                  name='form-field-name'

                  isClearable={true}
                  isSearchable={true}
                  isMulti
                  placeholder='Select Notification Type'
                  onChange={this.selectNotification}
                  options={groupedOptions}
                  formatGroupLabel={formatGroupLabel} />
              </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2'>
              <div className='selected-trip-Interval '>

                <p className='margin-zero'>
                  <b>From:</b> {moment(this.state.dateRangePicker.selection.startDate).format('MMMM DD YYYY')}
                </p>
                <p className='margin-zero'>
                  <b>To:</b> {moment(this.state.dateRangePicker.selection.endDate).format('MMMM DD YYYY')}
                </p>

              </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2'>

              <div className='m'>

                <a onClick={this.toggle} id='Popover1'>
                  <img src={calenderIcon} alt='' />
                </a>
                <Popover placement='bottom' isOpen={this.state.popoverOpen} target='Popover1'
                  toggle={this.toggle.bind(this)}>
                  <PopoverBody>
                    <div>
                      <DateRangePicker
                        onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                        startDate={this.state.dateRangePicker.selection.startDate}
                        endDate={this.state.dateRangePicker.selection.endDate}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        className={'PreviewArea'}
                        months={2}
                        ranges={[this.state.dateRangePicker.selection]}
                        direction='horizontal'
                        maxDate={new Date()}
                      />

                    </div>
                  </PopoverBody>
                </Popover>

              </div>

            </div>
          </div>


          <div className='reports-header'>
            <span className='export'>
              Notifications
                    </span>

          </div>
          <div className='notification-window'>
            {this.state.loading
              ? [loader]
              : <div>
                {<p className='no-notifications'>No Notifications Yet!</p>}

              </div>}

          </div>
          {this.props.hasMoreNotifications && !this.state.loading
            ? <footer className='loadmore-notifications'>
              <a
                onClick={this
                  .getNotification
                  .bind(this, true)}>Load More</a>
            </footer>
            : ''}
        </div>
      )
    }
  }
}

NotificationsList.propTypes = {};
NotificationsList.defaultProps = {};
const mapStateToProps = state => ({
  vehiclesPlateNumber: state.things.vehiclesPlateNumber,
  notifications: state.users.notifications.notifications,
  notificationsCount: state.users.notifications.notificationsCount,
  hasMoreNotifications: state.users.hasMoreNotifications,
  permissions: state.permissions
});

const mapDispatchToProps = dispatch => ({

  actions: bindActionCreators(Users.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch)
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationsList));
