import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

export default function (ComposedComponent, requiresPermission, requiresFeature) {

  class Authenticate extends Component {

    componentWillMount() {
      this.setState({loaded: false});

      if (this.props.isAuthenticated === false) this.props.history.push('/login');
      if (requiresPermission && requiresPermission.authorize) {
        if (!this.props.list.length) {
          let interval = setInterval(() => {
            if (this.props.list.length) {
              this.checkForPermission();
              clearInterval(interval);
            }
          }, 300)
        }
        else this.checkForPermission();
      } if(requiresFeature){
        if (!this.props.companyFeatures.length) {
          let interval = setInterval(() => {
            if (this.props.companyFeatures.length) {
              this.checkForFeature();
              clearInterval(interval);
            }
          }, 2000)
        }
        else this.checkForFeature();
      }
      else this.setState({...this.state, loaded: true})
    }

    componentWillUpdate(nextProps) {
      if (this.props.isAuthenticated === false) this.props.history.push('/login');
    }

    checkForFeature() {
      const value = requiresFeature.permissionString;
      let props = this.props;

      if (props.companyFeatures.indexOf(value) === -1) {
        this.props.history.push('/');
      }
      this.setState({...this.state, loaded: true})
    }

    checkForPermission() {
      const value = requiresPermission.permissionString;
      let props = this.props;
   
      if (('CREATE:USER' !== value || !props.createUser) &&
        ('LIST:USER' !== value || !props.listUser) &&
        ('LIST:ROLE' !== value || !props.listRole) &&
        ('CREATE:ROLE' !== value || !props.createRole) &&
        ('UPDATE:ROLE' !== value || !props.updateRole) &&
        ('UPDATE:VEHICLE' !== value || !props.updateVehicle) &&
        ('VIEW:VEHICLE' !== value || !props.viewVehicle) &&
        ('VIEW:GEOFENCE' !== value || !props.viewGeoFence) &&
        ('UPDATE:GEOFENCE' !== value || !props.updateGeoFence) &&
        ('DELETE:GEOFENCE' !== value || !props.deleteGeoFence) &&
        ('CREATE:GEOFENCE' !== value || !props.createGeoFence) &&
        ('CREATE:GROUP' !== value || !props.createGroup) &&
        ('VIEW:GROUP' !== value || !props.viewGroup) &&
        ('UPDATE:GROUP' !== value || !props.updateGroup) &&
        ('CREATE:DRIVER' !== value || !props.createDriver) &&
        ('COMPLAINT' !== value || !props.createDriver) &&
        ('UPDATE:DRIVER' !== value || !props.updateDriver) &&
        ('VIEW:DRIVER' !== value || !props.viewDriver) &&
        ('DELETE:DRIVER' !== value || !props.deleteDriver) &&
        ('FUEL_GROUP' !== value || !props.fuelGroup) &&
        ('TRIPS' !== value || !props.trips) &&
        ('PLAYBACK' !== value || !props.playBack) &&
        ('DASHBOARD' !== value || !props.dashboard) &&
        ('OBSERVATIONS' !== value || !props.observations) &&
        ('MAINTENANCE' !== value || !props.maintenance) &&
        ('VIEW:ROUTEFENCE' !== value || !props.viewRouteFence) &&
        ('UPDATE:ROUTEFENCE' !== value || !props.updateRouteFence) &&
        ('DELETE:ROUTEFENCE' !== value || !props.deleteRouteFence) &&
        ('CREATE:ROUTEFENCE' !== value || !props.createRouteFence) &&
        ('REPORTS' !== value || !props.reports) &&
        ('ROSTER' !== value || !props.roster) &&
        ('ACCOUNT_SETTING' !== value || !props.AccountSetting) &&
        ('UPDATE:USER' !== value || !props.updateUser) &&
        ('DELETE:USER' !== value || !props.deleteUser) &&
        ('CREATE:SCHEDULE' !== value || !props.createSchedule) &&
        ('LIST:SCHEDULE' !== value || !props.listSchedule) &&
        ('UPDATE:SCHEDULE' !== value || !props.updateSchedule) &&
        ('DELETE:SCHEDULE' !== value || !props.deleteSchedule) &&
        ('DELETE:SCHEDULE' !== value || !props.deleteSchedule) &&
        ('VIEW:NOTIFICATION' !== value || !props.viewNotification) &&
        ('ENGINE:KILL' !== value || !props.engineKill) &&
        ('PATROLLING' !== value || !props.patrolling)

      ) {
        const isSuperAdminUser = localStorage.getItem('isSuperAdminUser');
       
        if(isSuperAdminUser){
          this.props.history.push('/');
        }
        else if(props.companyFeatures.indexOf('HOTSPOT') !== -1){
          this.props.history.push('/dashboard');
        }
        else if (props.companyFeatures.indexOf('ROUTER') !== -1 ){
          this.props.history.push('/router');
        } 
        else if(props.dashboard && props.companyFeatures.indexOf('DASHBOARD:VIEWS') !== -1){
          if(props.companyFeatures.indexOf('HOTSPOT') !== -1){
            this.props.history.push('/dashboard');
          } else if(props.companyFeatures.indexOf('ROUTER') !== -1){
            this.props.history.push('/router');
          }
           else{
            this.props.history.push('/');
          }
        } 
        else if(props.patrolling && props.companyFeatures.indexOf('PATROLLING') !== -1){
          this.props.history.push('/patrolling/dashboard');
        } 
        else if(props.roster && props.companyFeatures.indexOf('ROSTER') !== -1){
          this.props.history.push('/roster/list');
        } 
        else if(props.viewVehicle){
          this.props.history.push('/vehicles/list');
        }
         else if(props.trips && props.companyFeatures.indexOf('TRIP:HISTORY') !== -1){
          this.props.history.push('/vehicles/trips');
        } 
        else if(props.playBack && props.companyFeatures.indexOf('PLAYBACK') !== -1){
          this.props.history.push('/vehicles/track-playback');
        }
        else if(props.observations && props.companyFeatures.indexOf('OBSERVATIONS') !== -1){
          this.props.history.push('/vehicles/observation');
        }
        else if(props.viewDriver  && props.companyFeatures.indexOf('DRIVER:MANAGEMENT') !== -1){
          this.props.history.push('/drivers');
        }
        else if(props.viewDriver && props.listSchedule  && props.companyFeatures.indexOf('DRIVER:MANAGEMENT') !== -1){
          this.props.history.push('/schedule');
        }
        else if(props.maintenance  && props.companyFeatures.indexOf('MAINTENANCE') !== -1){
          this.props.history.push('/vehicles/details/nil');
        }
        else if(props.fuelGroup  && props.companyFeatures.indexOf('FUEL:GROUP') !== -1){
          this.props.history.push('/fuel-group');
        }
        else if(props.viewGroup  && props.companyFeatures.indexOf('GROUPS') !== -1){
          this.props.history.push('/groups');
        }
        else if(props.viewGeoFence  && props.companyFeatures.indexOf('GEO-FENCE') !== -1){
          this.props.history.push('/geofence/list');
        }
        else if(props.viewRouteFence && props.companyFeatures.indexOf('ROUTE-FENCE') !== -1){
          this.props.history.push('/routefence/list');
        }
        else if(props.reports && props.companyFeatures.indexOf('REPORTS') !== -1){
          this.props.history.push('/reports');
        }
        else if(props.AccountSetting){
          this.props.history.push('/account');
        }
        else if(props.listUser){
          this.props.history.push('/users');
        }
        else if(props.listRole){
          this.props.history.push('/roles');
        }
      }
      if(this.props.location.pathname === '/' && props.companyFeatures.indexOf('HOTSPOT') !== -1){
        this.props.history.push('/dashboard');
      } 
      if(this.props.location.pathname === '/' && props.companyFeatures.indexOf('ROUTER') !== -1){
        this.props.history.push('/router');
      } 
     
      this.setState({...this.state, loaded: true})
    }

    render() {
      if (ComposedComponent && !requiresPermission) return (<ComposedComponent {...this.props} />);
      if (ComposedComponent && requiresPermission) return (this.state.loaded ? <ComposedComponent {...this.props} /> : "");
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  // Context error fix
  // Authenticate.contextTypes = {
  //   router: PropTypes.object.isRequired
  // };

    function mapStateToProps(state) {
    return {
      isAuthenticated: state.userAuthentication.isAuthenticated,
      list: state.permissions.permissions,
      listRole: state.permissions.listRole,
      listUser: state.permissions.listUser,
      createUser: state.permissions.createUser,
      createRole: state.permissions.createRole,
      updateRole: state.permissions.updateRole,
      updateVehicle: state.permissions.updateVehicle,
      viewVehicle: state.permissions.viewVehicle,
      viewGeoFence: state.permissions.viewGeoFence,
      updateGeoFence: state.permissions.updateGeoFence,
      deleteGeoFence: state.permissions.deleteGeoFence,
      createGeoFence: state.permissions.createGeoFence,
      createGroup: state.permissions.createGroup,
      updateGroup: state.permissions.updateGroup,
      viewGroup: state.permissions.viewGroup,
      createDriver: state.permissions.createDriver,
      viewDriver: state.permissions.viewDriver,
      updateDriver: state.permissions.updateDriver,
      deleteDriver: state.permissions.deleteDriver,
      fuelGroup:state.permissions.fuelGroup,
      
      trips:state.permissions.trips,
      playBack:state.permissions.playBack,
      dashboard:state.permissions.dashboard,
      observations:state.permissions.observations,
      maintenance:state.permissions.maintenance,
      viewRouteFence: state.permissions.viewRouteFence,
      updateRouteFence: state.permissions.updateRouteFence,
      deleteRouteFence: state.permissions.deleteRouteFence,
      createRouteFence: state.permissions.createRouteFence,
      reports: state.permissions.reports,
      roster:state.permissions.roster,
      AccountSetting:state.permissions.AccountSetting,
      updateUser:state.permissions.updateUser,
      deleteUser:state.permissions.deleteUser,
      createSchedule:state.permissions.createSchedule,
      listSchedule:state.permissions.listSchedule,
      updateSchedule:state.permissions.updateSchedule,
      deleteSchedule:state.permissions.deleteSchedule,
      viewNotification:state.permissions.viewNotification,
      engineKill:state.permissions.engineKill,
      patrolling:state.permissions.patrolling,
      companyFeatures: state.permissions.companyFeatures,
    };
  }

  return connect(mapStateToProps, null)(Authenticate);
}
