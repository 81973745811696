import isEmpty from 'lodash/isEmpty';
import { isEmptyValue, isAlphaNumeric, validateEmail } from './validator';

export function validateInput(data) {
  let errors = {};
  if (isEmptyValue(data.firstName)) {
    errors.firstName = 'This field is required';
  }
  else if (!isAlphaNumeric(data.firstName)) {
    errors.firstName = 'Firstname should be alphanumeric';
  } else if (data.firstName.length > 20) {
    errors.firstName = 'Max 20 characters are allowed in firstname';
  }
  if (isEmptyValue(data.lastName)) {
    errors.lastName = 'This field is required';
  } else if (!isAlphaNumeric(data.lastName)) {
    errors.lastName = 'Lastname should be alphanumeric';
  } else if (data.lastName.length > 20) {
    errors.lastName = 'Max 20 characters are allowed in lastname';
  }

  if (isEmptyValue(data.userName)) {
    errors.userName = 'This field is required';
  } else if (!isAlphaNumeric(data.userName)) {
    errors.userName = 'Username should be alphanumeric';
  } else if (data.lastName.length > 30) {
    errors.userName = 'Max 30 characters are allowed in username';
  }
  if (data.userName.indexOf(' ') !== -1) {
    errors.userName = 'Spaces not allowed in user name';
  }
  if (isEmptyValue(data.email)) {
    errors.email = 'This field is required';
  } else if (!validateEmail(data.email)) {
    errors.email = 'Invalid email!';
  }
  if (isEmptyValue(data.phone)) {
    errors.phone = 'This field is required';
  }
  if (data.phone.length > 11) {
    errors.phone = 'Max 11 characters are allowed in phone';
  }
  if (isEmptyValue(data.type)) {
    errors.type = 'User type is required';
  }

  if (isEmptyValue(data.role)) {
    errors.role = 'User role is required';
  }

  if (data.selectedGroup.length === 0) {
    errors.selectedGroup = 'Please select a group';
  }
  return {
    errors,
    isValid: isEmpty(errors)
  };
}


export function validateUpdateInput(data) {
  let errors = {};
  if (isEmptyValue(data.firstName)) {
    errors.firstName = 'This field is required';
  }
  else if (!isAlphaNumeric(data.firstName)) {
    errors.firstName = 'Firstname should be alphanumeric';
  } else if (data.firstName.length > 20) {
    errors.firstName = 'Max 20 characters are allowed in firstname';
  }

  if (isEmptyValue(data.lastName)) {
    errors.lastName = 'This field is required';
  } else if (!isAlphaNumeric(data.lastName)) {
    errors.lastName = 'Lastname should be alphanumeric';
  } else if (data.lastName.length > 20) {
    errors.lastName = 'Max 20 characters are allowed in lastname';
  }
  if (data.selectedGroup.length === 0) {
    errors.selectedGroup = 'Please select a group';
  }
  if (isEmptyValue(data.phone)) {
    errors.phone = 'This field is required';
  }
  // if (data.phone.length > 11) {
  //   errors.phone = 'Max 11 characters are allowed in phone';
  // }

  if (isEmptyValue(data.role)) {
    errors.role = 'User role is required';
  }
  return {
    errors,
    isValid: isEmpty(errors)
  };
}
