import Duck from 'extensible-duck'
import axios from "axios";
import {PatrollingEndPoints, userEndpoints} from "../api/endpoints";
import moment from "moment";
import * as _ from "lodash";
import toastr from "toastr";
import HashMap from 'hashmap';
import UserAuthentication from "./UserAuthentication";
const authenticationActions = UserAuthentication.creators;

let map = new HashMap();

export default new Duck({
    namespace: 'AuxoPatrolling',
    store: 'patrolling',
    types: [
        'IS_LOADING', 'CELL_SITES_AND_VEHICLES_FETCHED'
    ],
    initialState: {
        cellSites: [],
        vehicles:[],
        isLoading: false
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
            case duck.types.IS_LOADING:
                return {
                    ...state,
                    isLoading: action.isLoading
                };

            case duck.types.CELL_SITES_AND_VEHICLES_FETCHED:
                return {
                    ...state,
                    cellSites: action.data.cellSites,
                    vehicles: action.data.vehicles
                };
            default:
                return state;
        }
    },
    selectors: {
        root: state => state
    },
    creators: (duck) => ({

        getSingle: (state) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().patrolling.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {

                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                const response = await axios.get(PatrollingEndPoints.individualCellSite(state.id));
                let cellSite = response.data.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return cellSite;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        getVehicleDriver: (thingId) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().patrolling.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                const response = await axios.get(PatrollingEndPoints.vehicleDriver(thingId));
                const driver = response.data.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return driver;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        listCellSites: () => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().patrolling.isLoading) {
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(PatrollingEndPoints.cellSites(companyInfo.companyId));
                const cellSites = response.data.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return cellSites;

            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        bulkCellSites: (sheet) => async(dispatch, getState) => {
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
                return;
            }
            if (getState().patrolling.isLoading) {
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                const formData = new FormData(); 
                formData.append('sheet', sheet);
      
                const config = {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                };

                await axios.post(PatrollingEndPoints.bulkCellSites(), formData, config);
      
                dispatch({type: duck.types.IS_LOADING, isLoading: false});

            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },

        getUser: (userId) => async (dispatch, getState) => {
          try {
              const response = await axios.get(userEndpoints.user + `/${userId}`);
              const user = response.data.data;
              // dispatch({ type: duck.types.SINGLE_USER_FETCHED, user });
              return user;
              
          }
          catch (e) {
              toastr.error(e);
              throw e;
          }
      },

     
        createUpdateCellSite: (state) => async(dispatch, getState) => {
      
            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().patrolling.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                if(state.id){
                    await axios.put(PatrollingEndPoints.individualCellSite(state.id), state.cellSite);
                } else{
                    await axios.post(PatrollingEndPoints.createCellSite(), state.cellSite);
                }
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
      
        deleteCellSite: (state) => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().patrolling.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                await axios.delete(PatrollingEndPoints.individualCellSite(state.id));
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        listCellSitesAndVehicles: () => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().patrolling.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(PatrollingEndPoints.listCellSitesAndVehicles(companyInfo.companyId));
                const data = response.data.data;
                dispatch({type: duck.types.CELL_SITES_AND_VEHICLES_FETCHED, data});
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return data;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        listCellSitesAndVehiclesName: () => async(dispatch, getState) => {

            if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
                dispatch(authenticationActions.logoutUser());
            }
            if (getState().patrolling.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested
                // data)
                return;
            }
            try {
                dispatch({type: duck.types.IS_LOADING, isLoading: true});
                let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
                const response = await axios.get(PatrollingEndPoints.listCellSitesAndVehiclesList(companyInfo.companyId));
                const data = response.data.data;
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                return data;
            } catch (e) {
                dispatch({type: duck.types.IS_LOADING, isLoading: false});
                throw e;
            }
        },
        listPatrollingRegions: () => async(dispatch, getState) => {
          if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
              dispatch(authenticationActions.logoutUser());
              return;
          }
          if (getState().patrolling.isLoading) {
              return;
          }
          try {
              dispatch({type: duck.types.IS_LOADING, isLoading: true});
              let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
              const response = await axios.get(PatrollingEndPoints.patrollingRegions(companyInfo.companyId));
              const cellSites = response.data.data;
              dispatch({type: duck.types.IS_LOADING, isLoading: false});
              return cellSites;

          } catch (e) {
              dispatch({type: duck.types.IS_LOADING, isLoading: false});
              throw e;
          }
      },
      createUpdateRegion: (state) => async(dispatch, getState) => {
      
        if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
            dispatch(authenticationActions.logoutUser());
        }
        if (getState().patrolling.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested
            // data)
            return;
        }
        try {
            dispatch({type: duck.types.IS_LOADING, isLoading: true});
            if(state.id){
                await axios.put(PatrollingEndPoints.patrollingRegion(state.id), state.region);
            } else{
                await axios.post(PatrollingEndPoints.addPatrollingRegion(), state.region);
            }
            dispatch({type: duck.types.IS_LOADING, isLoading: false});
        } catch (e) {
            dispatch({type: duck.types.IS_LOADING, isLoading: false});
            throw e;
        }
    },
  
    deleteRegion: (state) => async(dispatch, getState) => {

        if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
            dispatch(authenticationActions.logoutUser());
        }
        if (getState().patrolling.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested
            // data)
            return;
        }
        try {
            dispatch({type: duck.types.IS_LOADING, isLoading: true});
            await axios.delete(PatrollingEndPoints.patrollingRegion(state.id));
            dispatch({type: duck.types.IS_LOADING, isLoading: false});
        } catch (e) {
            dispatch({type: duck.types.IS_LOADING, isLoading: false});
            throw e;
        }
    },
    getSingleRegion: (state) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested
          // data)
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          const response = await axios.get(PatrollingEndPoints.patrollingRegion(state.id));
          let region = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return region;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  getCellSiteAndRegionVehicles: (state) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          // Don't issue a duplicate request (we already have or are loading the requested
          // data)
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          const response = await axios.get(PatrollingEndPoints.getCellSiteAndRegionVehicles(state.id));
          let region = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return region;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  rescueCellSite: (state) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          const response = await axios.post(PatrollingEndPoints.rescueCellSite(), state);
          let data = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return data;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  cancelrescueCellSite: (rescueId) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          await axios.get(PatrollingEndPoints.cancelrescueCellSite(rescueId) );
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  completeRescueCellSite: (rescueId) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          await axios.get(PatrollingEndPoints.completeRescueCellSite(rescueId) );
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  getInProgressrescueCellSite: () => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.get(PatrollingEndPoints.getInProgressrescueCellSite(companyInfo.companyId));
          let data = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return data;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  createRoster: (state) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          await axios.post(PatrollingEndPoints.roster(), state);
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  getRosterList: () => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.get(PatrollingEndPoints.rostersList(companyInfo.companyId));
          let data = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return data;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  getRoster: (rosterId) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          const response = await axios.get(PatrollingEndPoints.rosterWithId(rosterId));
          let data = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return data;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  updateRoster: (state) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          const response = await axios.put(PatrollingEndPoints.rosterWithId(state.rosterId), state);
          let data = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return data;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
  deleteRoster: (rosterId) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
          dispatch(authenticationActions.logoutUser());
          return;
      }
      if (getState().patrolling.isLoading) {
          return;
      }
      try {
          dispatch({type: duck.types.IS_LOADING, isLoading: true});
          const response = await axios.delete(PatrollingEndPoints.rosterWithId(rosterId));
          let data = response.data.data;
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          return data;
      } catch (e) {
          dispatch({type: duck.types.IS_LOADING, isLoading: false});
          throw e;
      }
  },
       
    })
})