import Duck from "extensible-duck";
import UserAuthentication from "./UserAuthentication";
import { PlayBackEndPoints, pjpEndPoints } from "../../src/api/endpoints";
import axios from "axios";
import moment from "moment";
import { DVR_ACCOUNT, DVR_PASSWORD } from "../constants";
const authenticationActions = UserAuthentication.creators;
export default new Duck({
  namespace: "AuxoPlayBack",
  store: "pjp",
  types: ["IS_LOADING", "DETAILS_FETCHED", "LOGIN", "FETECHED_ALL"],
  initialState: {
    pjp: {},
    pjps: [],
    pjpCount: 0,
    hasMoreItems: false,
    isLoading: false,
    jSession: "",
    data: {
      cmsserver: 0,
      files: [],
      result: 2,
    },
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading,
        };

      case duck.types.LOGIN:
        return {
          ...state,
          jSession: action.sessionId,
        };

      case duck.types.DETAILS_FETCHED:
        return {
          ...state,
          pjp: action.pjp,
        };
      case duck.types.FETECHED_ALL:
        return {
          ...state,
          data: action.result,
        };
      default:
        return state;
    }
  },
  selectors: {
    root: (state) => state,
  },
  creators: (duck) => ({
    getSingle: (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
        dispatch(authenticationActions.logoutUser());
        return;
      }
      if (getState().pjp.isLoading) {
        // Don't issue a duplicate request (we already have or are loading the requested
        // data)
        return;
      }
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
        const response = await axios.get(
          pjpEndPoints.individualPJP(state.id, companyInfo.groupId)
        );
        let pjp = response.data.data;
        dispatch({ type: duck.types.DETAILS_FETCHED, pjp });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }
    },
    loginToMvdr: (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
        dispatch(authenticationActions.logoutUser());
        return;
      }
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        await fetch(PlayBackEndPoints.login(DVR_ACCOUNT, DVR_PASSWORD))
          .then((response) => response.json())
          .then((result) => {
            let sessionId = result.jsession;
            dispatch({ type: duck.types.LOGIN, sessionId });
            fetch(PlayBackEndPoints.fetchAll(sessionId))
            .then((response) =>response.json())
              .then((result) => {
                dispatch({ type: duck.types.FETECHED_ALL, result });
              })
              .catch((error) => {
                console.log(error);
                dispatch({ type: duck.types.IS_LOADING, isLoading: false });
                throw error;
              });

            dispatch({ type: duck.types.IS_LOADING, isLoading: false });
          })
          .catch((error) => {
            console.log(error);
            dispatch({ type: duck.types.IS_LOADING, isLoading: false });
            throw error;
          });
      } catch (e) {
        console.log(e);
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }
    },
    fetchSingleRecord: (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) {
        dispatch(authenticationActions.logoutUser());
        return;
      }
      try {
        // dispatch({type: duck.types.IS_LOADING, isLoading: true});
        const response = await axios.get(
          PlayBackEndPoints.fetchSingleRecord(state)
        );
        let data = response;
        dispatch({ type: duck.types.FETECHED_SINGLE_RECORD, data });
        // dispatch({type: duck.types.IS_LOADING, isLoading: false});
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }
    },
  }),
});
