import { compose, withProps, withStateHandlers, lifecycle } from "recompose";
import React, { useState } from "react";
import "./geofence.css";
import toastr from "toastr";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Polygon,
  Marker,
  MapControl,
  OverlayView
} from "react-google-maps";
import { OVERLAY_VIEW } from "react-google-maps/lib/constants";
const {
  SearchBox,
} = require("react-google-maps/lib/components/places/SearchBox");
const _ = require("lodash");
let self = {};

const GeoFenceMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="geofence-map-container" />,
    mapElement: <div className="geofence-map-element" />,
  }),
  lifecycle({
    componentDidMount() {
      const refs = {};

      setTimeout(() => {
        this.setState({
          bounds: null,
          center: {
            lat: self.center.lat,
            lng: self.center.lng,
          },
          onMapMounted: (ref) => {
            refs.map = ref;
          },
          onBoundsChanged: () => {
            this.setState({
              bounds: refs.map.getBounds(),
              center: refs.map.getCenter(),
            });
          },
          onSearchBoxMounted: (ref) => {
            refs.searchBox = ref;
          },
          onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();

            places.forEach((place) => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            const nextMarkers = places.map((place) => ({
              position: place.geometry.location,
            }));
            const nextCenter = _.get(
              nextMarkers,
              "0.position",
              this.state.center
            );

            this.setState({
              center: nextCenter,
            });
            if(self.circle){
              self.circle.center = nextCenter;
              self.onMapChange(self.circle);
            }
            if(self.polygon){
              self.onMapChange(nextCenter, true);
            }
            // refs.map.fitBounds(bounds);
          },
        });
      }, 2000);
    },
  }),
  withScriptjs,
  withGoogleMap,
  withStateHandlers(({ circleReference }) => ({ circle: circleReference }), {
    onCircleMounted: (state) => (circle) => {
      return { circle };
    },
  })
)((props) => {
  self = props;
  const [paths, setPaths] = useState(props.paths || []);
  function radiusToZoom() {
    const radius = props.radius / 1000;
    return Math.round(13 - Math.log(radius) / Math.LN2);
  }
  const zoom = parseInt(radiusToZoom(), 10);
  

  const addPoint = (event) => {
    if (!props.polygon) return;
    if (paths.length <= 19) {
      setPaths([
        ...paths,
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
      ]);
      props.path([
        ...paths,
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
      ]);
    }
    else {
      toastr.error("You can't add more than 20 points")
    }
    
  };

  const handleMarkerDrag = (index, event) => {
    
    const { latLng } = event;
    const newLatLng = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    const newPath = [...paths];
    newPath[index] = newLatLng;
    
    setPaths(newPath);
    
    props.path(newPath);
  };

  const customMarker = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: '#fff',
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 0.5,
    scale: 3.5,
  };

  const resetShape = () =>{
    setPaths([]);
    props.path([]);
  }
  return (
    <>
      <GoogleMap
      zoom={zoom}
      defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
      center={props.center}
      onClick={addPoint}
    >
      {/* <MapControl></MapControl> */}
      {props.geoFences.length>0 &&
        props.geoFences.map((circle, index) => {
          if (circle.geoFenceType === "CIRCLE"){
            return (
              <Circle
                center={{
                  lat: circle.center[1],
                  lng: circle.center[0],
                }}
                radius={circle.radius}
                key={index.toString()}
                options={{
                  strokeColor: "#03ABCE",
                  strokeOpacity: 0.8,
                  fillColor: "#03ABCE",
                  fillOpacity: 0.35,
                }}
              />
            );
          }
       })
      }
      {props.geoFences.length>0 &&
        props.geoFences.map((polygon, index) => {
          if (polygon.geoFenceType === "POLYGON"){
            return (
            <Polygon
              options={{
                strokeColor: "#03ABCE",
                fillColor: "#03ABCE",
                fillOpacity: 0.42
              }}
              center={{
                  lat: polygon.center[1],
                  lng: polygon.center[0],
                }}
              path={polygon.polygonPath }
            />
            );
          }
        })}
      {props.hasOwnProperty("editable") ? (
        ""
      ) : (
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
          options={{
            componentRestrictions: { country: "br" },
          }}
        >
          <input
            type="text"
            placeholder="Search places"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: "295px",
              height: "41px",
              marginTop: "9px",
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </SearchBox>
      )}
      
      {!props.polygon && (
        <Circle
          ref={props.onCircleMounted}
          center={props.center}
          draggable={props.hasOwnProperty("draggable") ? props.draggable : true}
          editable={props.hasOwnProperty("editable") ? props.editable : true}
          radius={props.radius}
          options={{
            strokeColor: "#03ABCE",
            fillColor: "#03ABCE",
            fillOpacity: 0.42,
          }}
          onRadiusChanged={() => props.onMapChange(props.circle)}
          onCenterChanged={() => props.onMapChange(props.circle)}
        />
      )}
      
      {props.polygon &&  (
        <Polygon
          // draggable={
          //   props.hasOwnProperty("draggable") ? props.draggable : true
          // }
          // editable={props.hasOwnProperty("editable") ? props.editable : true}
          options={{
            strokeColor: "#03ABCE",
            fillColor: "#03ABCE",
            fillOpacity: 0.42
          }}
          center={props.center}
          path={props.paths || paths}
          //onDragEnd={handlePolygonDragEnd}
        />
      )}
      {paths?.map((coord, index) => (
        <Marker
          key={index}
          position={coord}
          draggable
          icon={customMarker}
          onDragEnd={(event) => handleMarkerDrag(index, event)}
        />
      ))}

      {/* <div>
        <SearchBox
          ref={props.onSearchBoxMounted}
          
          controlPosition={window.google.maps.ControlPosition.BOTTOM_CENTER}
         
          options={{
            componentRestrictions: { country: "br" },
          }}
         
        >
        <button
          className="btn auxo-primary-btn btn-small"
          type="button"
          onClick={resetShape}
        >
          Reset
        </button>
    
        </SearchBox>
      </div> */}
    </GoogleMap>
    
    <div className="col-lg-12 col-md-12 col-sm-12">
      <button
        className="btn auxo-primary-btn btn-sm"
        type="button"
        onClick={resetShape}
        >
        Reset
      </button>
    </div>
    </>
  );
});

export default GeoFenceMap;
