import React, { Component } from 'react';
import TextFieldGroup from "../common/TextFieldGroup";
import { connect } from "react-redux";
import Roles from "../../ducks/Roles";
import Permissions from "../../ducks/Permissions";
import { bindActionCreators } from "redux";
import CheckboxListComponent from "../common/CheckboxListComponent";
import { validateInput } from "../../validations/roleCreate";
import { Spinner } from '../common/Spinner/index';
import toastr from "toastr";
import * as _ from "lodash";
import './role.css';

class UpdateRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            activeTab: "roles",
            access: [],
            errors: {},
            permissionList: [],
            type: '',
            isLoading: true
        }
    }

    async componentDidMount() {

        await this
            .props
            .permissionActions
            .getPermissionsAndCompanies();
        if (this.props.match.params.id) {
            await this
                .props
                .actions
                .getSingle(this.props.match.params.id);

            const type = this.props.single.type;

            let permissionModules = this
                .props
                .permissionList
                .filter(item => {
                    return item.userType === type
                });
            let modules = permissionModules.map(item => {
                return item.permissions
            });
            modules = _.flatten(modules);

            let permissionList = modules.map((permission) => {
                return { id: permission._id, name: permission.name, checked: false, module: permission.module, permissionString: permission.permissionString }
            });
            let access = [];
            for (let i = 0; i < permissionList.length; i++) {
                let index = this
                    .props
                    .single
                    .permissions
                    .findIndex(item => {
                        return permissionList[i].permissionString === item.permissionString;
                    });
                permissionList[i].checked = index > -1;
                if (permissionList[i].checked) {
                    access.push(permissionList[i].id)
                }
            }

            this.setState({
                ...this.state,
                access,
                type,
                name: this.props.single.name,
                modules: permissionModules,
                permissionList: permissionList,
                isLoading: false
            });

        }
    }

    isValid = () => {
        const { errors, isValid } = validateInput(this.state);

        if (!isValid) {
            this.setState({ errors });
        }

        return isValid;
    };
    checkBoxListChanged = (value) => {
        let permissionList = this.state.permissionList;
        let access = this.state.access;
        if (permissionList.length > 0) {
            for (let i = 0; i < permissionList.length; i++) {
                let item = permissionList[i];
                if (i === parseInt(value, 10)) {
                    item.checked = !item.checked;
                    if (item.checked) {
                        access.push(item.id);
                    } else {
                        let index = access.findIndex(obj => {
                            return obj === item.id;
                        });
                        if (index > -1) {
                            access.splice(index, 1);
                        }
                    }
                }
                permissionList[i] = item;
            }
        }
        this.setState({ roleList: permissionList, access });
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    };
    onSubmit = async e => {
        e.preventDefault();
        if (this.isValid()) {
            try {

                await this
                    .props
                    .actions
                    .updateRole(this.props.match.params.id, {
                        name: this.state.name,
                        permissions: this.state.access
                    });
                toastr.success("Role Update!");

                this
                    .props
                    .history
                    .push("/roles");
            } catch (e) {
                toastr.error(e.response ? e.response.data.message : e);
            }
        }
    };

    render() {
        const { name } = this.state;
        const loader = <Spinner key='1' />;

        let permissions = (
            <div className="col">
                <label>Access</label>
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className={"nav-link active"}>Permissions</a>
                    </li>
                </ul>
                <div className="tab-content access-tab mb-5">
                    <div className={"tab-pane fade show active"}>
                        {this.state.modules && this
                            .state
                            .modules
                            .map((item, index) => {
                                return (
                                    <table key={index} className="create-role">
                                        <thead>
                                            <tr>
                                                <th>{item.name}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr key={item._id}>

                                                <td>
                                                    <CheckboxListComponent
                                                        onChange={this.checkBoxListChanged}
                                                        data={this.state.permissionList && this
                                                            .state
                                                            .permissionList
                                                            .map(permission => {
                                                                if (permission.module === item.name) {
                                                                    return { mainTitle: permission.name, checked: permission.checked }
                                                                }
                                                                return null;
                                                            })} /> {this.state.errors && <span className="text-danger">{this.state.errors.access}</span>}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                            })}

                    </div>
                </div>
                <button className="btn auxo-primary-btn">Update Role</button>
            </div>
        )

        return (
          <>
          {this.state.isLoading ? [loader] :
            <div>
                <div className="heading-container">
                    <h3 className="font-weight-bold">Update Role</h3>
                </div>

                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <TextFieldGroup
                                onChange={this.onChange}
                                value={name}
                                type={"text"}
                                field={"name"}
                                label={"Name"}
                                placeholder={"Role name"}
                                error={this.state.errors.name} />

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            {permissions}
                        </div>

                    </div>
                </form>
            </div>
          }
          </>
        );
    }
}

UpdateRole.propTypes = {};
UpdateRole.defaultProps = {};
const mapStateToProps = state => ({ permissionList: state.permissions.list, single: state.roles.single, companies: state.permissions.companies });
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Roles.creators, dispatch),
    permissionActions: bindActionCreators(Permissions.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateRole);
