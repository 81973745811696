import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter } from 'connected-react-router'
import UserAuthentication from '../ducks/UserAuthentication';
import Users from '../ducks/Users';
import Roles from '../ducks/Roles';
import Permissions from '../ducks/Permissions';
import Things from '../ducks/Things';
import Groups from '../ducks/Groups';
import Drivers from '../ducks/Drivers';
import Complaint from '../ducks/Complaint';
import GeoFence from '../ducks/GeoFence';
import Reports from '../ducks/Reports';
import Schedule from '../ducks/Schedule';
import FuelGroup from '../ducks/FuelGroup';
import ReportSchedule from '../ducks/ReportSchedule';
import RouteFence from '../ducks/RouteFence';
import Patrolling from '../ducks/Patrolling';
import Hotspot from '../ducks/Hotspot';
import PJP from '../ducks/PJP';
import Visualization from '../ducks/Visualization';
import { middleware } from '../middlewares/index';
import Company from '../ducks/Company';
import PlayBack from '../ducks/PlayBack';
import Ragscore from '../ducks/ragscore';
import TelcoSite from '../ducks/TelcoSite';

export default function configureStore(history, initialState) {
  const reducers = {
    userAuthentication: UserAuthentication.reducer,
    users: Users.reducer,
    roles: Roles.reducer,
    things: Things.reducer,
    groups: Groups.reducer,
    permissions: Permissions.reducer,
    drivers: Drivers.reducer,
    complaints: Complaint.reducer,
    geoFence: GeoFence.reducer,
    Reports: Reports.reducer,
    schedule: Schedule.reducer,
    fuelGroup: FuelGroup.reducer,
    reportSchedule: ReportSchedule.reducer,
    routeFence: RouteFence.reducer,
    patrolling: Patrolling.reducer,
    hotspot: Hotspot.reducer,
    pjp: PJP.reducer,
    visualization: Visualization.reducer,
    company: Company.reducer,
    playBack: PlayBack.reducer,
    ragscore : Ragscore.reducer,
    telcosites: TelcoSite.reducer

  };
  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
