import React, { useState, useEffect } from "react";
import { Spinner } from "../../common/Spinner";
import PaginatedTable from "../../common/PaginatedTable";
import Modal from "react-bootstrap4-modal";
import Things from "../../../ducks/Things";
import trash_bin from "../../../assets/img/trash_bin.png";

import Select from "react-select-nested-group";
import moment from "moment";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaGroup from "../../common/TextAreaGroup";

import toastr from "toastr";

import Stepper from "react-stepper-horizontal";
import LogMaintenance from "../../../ducks/LogMaintenance";
import OtherMaintainanceModal from "./otherMaintainanceModel";
import * as R from "ramda";

import DatePicker from "react-datepicker";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="m-b-20-px"
    >
      <Container>{children}</Container>
    </div>
  );
}

const VehicleDetails = (props) => {
  // const [isLoading, setLoading] = useState(true);
  const [isVehicleListLoading, setIsVehicleListLoading] = useState(true);
  const [isVehicleLoading, setIsVehicleLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});

  const [vehicleId] = useState(props.match.params.id);
  const [vehicleList, setVehicleList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  async function LoadThings() {
    setIsVehicleListLoading(true);
    const vehiclesPlateNumber = await props.thingsActions.getVehiclesPlateNumber();
    setVehicleList(vehiclesPlateNumber);

    if (vehicleId === "nil") {
      return props.history.push(vehiclesPlateNumber[0]._id);
    }
    const selectedVehicle = R.find((p) => p._id === vehicleId)(
      vehiclesPlateNumber
    );
    setSelectedVehicle({
      value: selectedVehicle._id,
      label: selectedVehicle.plateNumber,
    });

    setIsVehicleListLoading(false);
  }
  useEffect(() => {
    LoadThings();
  }, []);

  const [isOilModalVisible, setOilModalVisibility] = useState(false);
  const [isTyreModalVisible, setTyreModalVisibility] = useState(false);
  const [isOtherModalVisible, setOtherModalVisibility] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState({});

  const [vehicleInfo, setVehicleInfo] = useState({});

  const [oilMaintenance, setOilMaintenance] = useState({});
  const [tyreMaintenance, setTyreMaintenance] = useState({});
  const [otherMaintenance, setOtherMaintenance] = useState({});
  // const vehicleId = '5d01fcd2d88738601f88db11';
  const loadVehicleInfo = async () => {
    setIsVehicleLoading(true);
    const Log = await props.actions.getLogs(vehicleId);
    const {
      imei,
      odoMeterReading,
      plateNumber,
      makeAndModel = "Toyota Passo",
      oilMaintenance,
      tyreMaintenance,
      other,
    } = Log;
    const vehicleInfo = { imei, odoMeterReading, plateNumber, makeAndModel };
    setVehicleInfo(vehicleInfo);
    setOilMaintenance(oilMaintenance);
    setTyreMaintenance(tyreMaintenance);
    setOtherMaintenance(other);
    setIsVehicleLoading(false);
  };

  useEffect(() => {
    if (vehicleId !== "nil") {
      loadVehicleInfo();
    }
  }, [vehicleId]);

  const columns = [
    { name: "Serial Number" , selector: "serialNumber"},
    { name: "Date" , selector: "date"},
    { name: "Mileage" , selector: "mileage"},
    { name: "Remarks" , selector: "remarks"},
    { name: "Action" , selector: "action"},
  ];
  const headers = [
    { label: "Serial Number" , key: "serialNumber"},
    { label: "Date" , key: "date"},
    { label: "Mileage" , key: "mileage"},
    { label: "Remarks" , key: "remarks"},
    { label: "Action" , key: "action"}
  ]
  const otherColumns = [
    {name: "Serial Number" , selector: "serialNumber"},
    {name:"Type", selector: "type"},
    {name:"Date", selector: "date"},
    {name:"Cost", selector: "cost"},
    {name:"Description", selector: "description"},
    {name:"Warrenty", selector: "warrenty"},
    {name:"Remarks", selector: "remarks"},
    {name:"Action", selector: "action"},
  ];

  const otherHeaders = [
    {label: "Serial Number" , key: "serialNumber"},
    {label:"Type", key: "type"},
    {label:"Date", key: "date"},
    {label:"Cost", key: "cost"},
    {label:"Description", key: "description"},
    {label:"Warrenty", key: "warrenty"},
    {label:"Remarks", key: "remarks"},
    {label:"Action", key: "action"},
  ];
  const oilMaintenanceData =
    oilMaintenance.oilChangeLog && oilMaintenance.oilChangeLog.length
      ? oilMaintenance.oilChangeLog.map((data, index) => {
          return {
            serialNumber:index + 1,
            date: moment(data.lastChangeDate).format("DD-MM-YYYY"),
            mileage:data.lastChangeMilage,
            remarks: data.remarks || "N/A",
            action: <a onClick={showDeleteRecord.bind(this, data)}>
              <img src={trash_bin} className="width16" alt="" />
            </a>,
          };
        })
      : [];
  const tyreMaintenanceData =
    tyreMaintenance.tyreChangeLog && tyreMaintenance.tyreChangeLog.length
      ? tyreMaintenance.tyreChangeLog.map((data, index) => {
          return {
            serialNumber:index + 1,
            date:moment(data.lastChangeDate).format("DD-MM-YYYY"),
            mileage:data.lastChangeMilage,
            remarks:data.remarks || "N/A",
            action: <a onClick={showDeleteRecord.bind(this, data)}>
              <img src={trash_bin} className="width16" alt="" />
            </a>,
          };
        })
      : [];

  const otherMaintenanceData =
    otherMaintenance && otherMaintenance.length
      ? otherMaintenance.map((data,index) => {
          return {
            serialNumber:index + 1,
            type:data.otherMaintenanceType,
            date:moment(data.maintenanceDate).format("DD-MM-YYYY"),
            cost:data.cost,
            description:data.description,
            warrenty: data.warrenty || "N/A",
            remarks: data.remarks,
            action: <a onClick={showDeleteRecord.bind(this, data)}>
              <img src={trash_bin} className="width16" alt="" />
            </a>,
          };
        })
      : [];

  const options = {
    filterType: "checkbox",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filter: false,
    sort: false,
  };

  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  async function showDeleteRecord(data) {
    setRecordToDelete(data);
    setDeleteModalVisibility(!isDeleteModalVisible);
  }
  async function deleteRecord() {
    try {
      await props.actions.deleteLog(recordToDelete._id);
      loadVehicleInfo();
      setDeleteModalVisibility(!isDeleteModalVisible);
      toastr.success("Record deleted successfully");
    } catch (err) {
      toastr.error("Something went wrong");
    }
  }

  async function onNewOilChange(record) {
    const logEntry = { ...record, vehicle: vehicleId, type: "OIL_CHANGE" };
    setIsModalLoading(true);
    await props.actions.newLog(logEntry);
    setOilModalVisibility(false);
    setIsModalLoading(false);
    loadVehicleInfo();
  }
  async function onNewTyreChange(record) {
    const logEntry = { ...record, vehicle: vehicleId, type: "TYRE_CHANGE" };
    setIsModalLoading(true);
    await props.actions.newLog(logEntry);
    setTyreModalVisibility(false);
    setIsModalLoading(false);
    loadVehicleInfo();
  }
  async function onNewOtherMaintainance(record) {
    const logEntry = { ...record, vehicle: vehicleId, type: "OTHER" };
    setIsModalLoading(true);
    await props.actions.newOtherLog(logEntry);
    setOtherModalVisibility(false);
    setIsModalLoading(false);
    loadVehicleInfo();
  }
  const oilDueDuration = R.path(["oilChangeDue", "Duration"])(oilMaintenance);
  const oilDurationMonths = moment(oilDueDuration, "DD-MM-YYYY").diff(
    moment(),
    "month"
  );

  const oilTimeCovered = R.path(["SinceLastOilChange", "timeCoverd"])(
    oilMaintenance
  );
  const oilTimeCoveredMoment = moment().subtract(oilTimeCovered, "day");
  const oilTimeCoveredMonths = moment().diff(oilTimeCoveredMoment, "month");

  const oilPendingTime = R.path(["oilNotification", "PendingTime"])(
    oilMaintenance
  );
  const oilPendingTimeMoment = moment().add(oilPendingTime, "day");
  const oilPendingTimeMonths = oilPendingTimeMoment.diff(moment(), "month");

  const tyreDueDuration = R.path(["tyreChangeDue", "Duration"])(
    tyreMaintenance
  );
  const tyreDurationMonths = moment(tyreDueDuration, "DD-MM-YYYY").diff(
    moment(),
    "month"
  );

  const tyreTimeCovered = R.path(["SinceLastTyreChange", "timeCoverd"])(
    tyreMaintenance
  );
  const tyreTimeCoveredMoment = moment().subtract(tyreTimeCovered, "day");
  const tyreTimeCoveredMonths = moment().diff(tyreTimeCoveredMoment, "month");

  const tyrePendingTime = R.path(["tyreNotification", "PendingTime"])(
    tyreMaintenance
  );
  const tyrePendingTimeMoment = moment().add(tyrePendingTime, "day");
  const tyrePendingTimeMonths = tyrePendingTimeMoment.diff(moment(), "month");

  const isLoading = isVehicleListLoading || isVehicleLoading || isModalLoading;

  if (isLoading) {
    return (
      <Container>
        <div>
          <Spinner />
        </div>
      </Container>
    );
  }
  return (
    <div>
      {isDeleteModalVisible ? (
        <Modal visible={true} dialogClassName="delete-modal-dialog">
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={showDeleteRecord.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading">
            <h5 className="car-and-device-assig">Delete Record?</h5>
          </div>
          <div className="modal-body">
            <h6>Are you sure you want to delete Record?</h6>
          </div>
          <div className="modal-footer model-btn">
            <button
              className="btn auxo-primary-btn"
              onClick={deleteRecord.bind(this)}
            >
              Confirm Delete
            </button>

            <button
              className="btn auxo-primary-btn"
              onClick={showDeleteRecord.bind(this)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      ) : (
        ""
      )}

      <div style={{ maxWidth: "400px" }}>
        <Select
          name="form-field-name"
          isClearable={false}
          isSearchable={true}
          placeholder="Select Vehicle"
          defaultValue={selectedVehicle}
          onChange={(option) => {
            props.history.push(option.value);
          }}
          options={vehicleList.map((item) => {
            return { value: item._id, label: item.plateNumber };
          })}
        />
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-8 margin-top-20">
          <span className="driver-name">
            {vehicleInfo.plateNumber || "N/A"}
          </span>
          <span className="driver-emp-id">{vehicleInfo.makeAndModel}</span>
          <br />
          <span className="vehicle-info-imei">{vehicleInfo.imei}</span>
          <br />
          <span
            className="vehicle-info-imei"
            style={{ marginBottom: "15px", display: "block" }}
          >
            Meter Reading: {vehicleInfo.odoMeterReading} KM
          </span>
        </div>
      </div>

      <div className="maintainance-box">
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                OIL
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                TYRE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                OTHER
              </NavLink>
            </NavItem>
          </Nav>

          <TabPanel value={activeTab} index={"1"}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 ">
                <button
                  className="btn auxo-primary-btn add-new-maintainance-btn"
                  onClick={() => {
                    setOilModalVisibility(true);
                  }}
                >
                  Change Oil
                </button>
              </div>
            </div>
            <div className="row ">
              <div className="maintainance-card">
                <div className={"card-display maintainance-card-width"}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <CardTitle>
                        <div>
                          <span className="card-heading">Oil Change Due</span>
                        </div>
                      </CardTitle>
                      <div className="card-data">
                        Date: {oilDueDuration || "N/A"}
                      </div>
                      <div className="card-data">
                        Mileage: {oilMaintenance.oilChangeDue.distance} KM
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="maintainance-card">
                <div className={"card-display maintainance-card-width"}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <CardTitle>
                        <div>
                          <span className="card-heading">
                            Since Last Oil Change
                          </span>
                        </div>
                      </CardTitle>
                      <div className="card-data">
                        Distance Covered:{" "}
                        {oilMaintenance.SinceLastOilChange.distanceCoverd} KM
                      </div>
                      <div className="card-data">
                        Time Elapsed: {oilTimeCovered} Days
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>

              <div className="maintainance-card">
                <div className={"card-display maintainance-card-width"}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <CardTitle>
                        <div>
                          <span className="card-heading">Notification</span>
                        </div>
                      </CardTitle>
                      <div className="card-data">
                        Date: {oilPendingTime || "N/A"}
                      </div>
                      <div className="card-data">
                        Mileage: {oilMaintenance.oilNotification.Distance} KM
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>

            {/*
          <Grid container justify="space-between">
            <Grid item>
              <Typography component="h6" variant="h6"></Typography>
              <Typography component="p" variant="p"></Typography>
              <Typography component="p" variant="p"></Typography>
              <Typography component="h6" variant="h6"></Typography>
              <Typography component="p" variant="p"></Typography>
              <Typography component="p" variant="p"></Typography>
              <Typography component="h6" variant="h6"></Typography>
              <Typography component="p" variant="p"> </Typography>
              <Typography component="p" variant="p"></Typography>

            </Grid>
            <Grid item>
              <button
                className="btn auxo-primary-btn"
                onClick={() => { setOilModalVisibility(true) }}>New Oil Change</button>
            </Grid>
          </Grid> */}
            <br />
            <PaginatedTable
              title={"Oil History"}
              data={oilMaintenanceData}
              columns={columns}
              fileName="Oil History"
              headers={headers}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={"2"}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <button
                  className="btn auxo-primary-btn add-new-maintainance-btn"
                  onClick={() => {
                    setTyreModalVisibility(true);
                  }}
                >
                  Change Tyre
                </button>
              </div>
            </div>
            <div className="row">
              <div className="maintainance-card">
                <div className={"card-display maintainance-card-width"}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <CardTitle>
                        <div>
                          <span className="card-heading">Tyre Change Due</span>
                        </div>
                      </CardTitle>
                      <div className="card-data">
                        Date: {tyreDueDuration || "N/A"}
                      </div>
                      <div className="card-data">
                        Mileage:{" "}
                        {tyreMaintenance.tyreChangeDue.distance
                          ? tyreMaintenance.tyreChangeDue.distance
                          : "N/A"}{" "}
                        KM
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="maintainance-card">
                <div className={"card-display maintainance-card-width"}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <CardTitle>
                        <div>
                          <span className="card-heading">
                            Since Last Tyre Change
                          </span>
                        </div>
                      </CardTitle>
                      <div className="card-data">
                        Time Elapsed: {tyreTimeCovered} days
                      </div>
                      <div className="card-data">
                        Distance Covered:{" "}
                        {tyreMaintenance.SinceLastTyreChange.distanceCoverd} KM
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>

              <div className="maintainance-card">
                <div className={"card-display maintainance-card-width"}>
                  <Card className="height-auto text-center">
                    <CardBody>
                      <CardTitle>
                        <div>
                          <span className="card-heading">Notification</span>
                        </div>
                      </CardTitle>
                      <div className="card-data">
                        Date: {tyrePendingTime || "N/A"}
                      </div>
                      <div className="card-data">
                        Mileage:{" "}
                        {tyreMaintenance.tyreNotification.Distance
                          ? tyreMaintenance.tyreNotification.Distance
                          : "N/A"}{" "}
                        KM
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>

            <br />
            <PaginatedTable
              title={"Tyre History"}
              data={tyreMaintenanceData}
              columns={columns}
              fileName="Tyre History"
              headers={headers}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={"3"}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <button
                  className="btn auxo-primary-btn add-new-maintainance-btn"
                  onClick={() => {
                    setOtherModalVisibility(true);
                  }}
                >
                  Add
                </button>
              </div>
            </div>

            <br />
            <PaginatedTable
              title={"Other History"}
              data={otherMaintenanceData}
              columns={otherColumns}
              headers={otherHeaders}
              fileName={"Other History"}
            />
          </TabPanel>
        </div>
      </div>
      {isOilModalVisible && (
        <ChangeModal
          isVisible={isOilModalVisible}
          onCancel={() => setOilModalVisibility(false)}
          title={"Oil"}
          odometer={vehicleInfo.odoMeterReading}
          onConfirm={onNewOilChange}
        />
      )}
      {isTyreModalVisible && (
        <ChangeModal
          isVisible={isTyreModalVisible}
          onCancel={() => setTyreModalVisibility(false)}
          title={"Tyre"}
          odometer={vehicleInfo.odoMeterReading}
          onConfirm={onNewTyreChange}
        />
      )}
      {isOtherModalVisible && (
        <OtherMaintainanceModal
          isVisible={isOtherModalVisible}
          onCancel={() => setOtherModalVisibility(false)}
          title={"Other"}
          onConfirm={onNewOtherMaintainance}
        />
      )}
    </div>
  );
};

const ChangeModal = (props) => {
  const [odometer, setOdometer] = useState(props.odometer);
  const [odometerLastChange, setOdometerLastChange] = useState(props.odometer);
  const [odometerNextChange, setOdometerNextChange] = useState();
  const [dateLastChange, setDateLastChange] = useState(moment());
  const [expiryDays, setExpiryDays] = useState();
  const [reminderOdometer, setReminderOdometer] = useState();
  const [remarks, setRemarks] = useState();
  const [reminderDays, setReminderDays] = useState();
  const steps = [
    { title: "Current Mileage" },
    { title: `${props.title} Change` },
    { title: "Change Due" },
    { title: "Reminder" },
    { title: "Remarks" },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const [errorOdometer, setErrorOdometer] = useState();
  const [errorDateLastChange, setErrorDateLastChange] = useState();
  const [errorOdometerLastChange, setErrorOdometerLastChange] = useState();
  const [errorExpiryDays, setErrorExpiryDays] = useState();
  const [errorOdometerNextChange, setErrorOdometerNextChange] = useState();
  const [errorReminderDays, setErrorReminderDays] = useState();
  const [errorReminderOdometer, setErrorReminderOdometer] = useState();
  function shouldGoNext(step) {
    if (step === 0) {
      if (!odometer) {
        setErrorOdometer("Meter reading is required");
        return;
      } else {
        setErrorOdometer("");
      }
    } else if (step === 1) {
      if (!dateLastChange) {
        setErrorDateLastChange("Date is required");
        return;
      } else if (!odometerLastChange) {
        setErrorOdometerLastChange("Mileage is required");
        return;
      } else if (odometer < parseInt(odometerLastChange)) {
        setErrorOdometerLastChange("Should be less than current odometer");
        return;
      } else {
        setErrorDateLastChange("");
        setErrorOdometerLastChange("");
      }
    } else if (step === 2) {
      if (!expiryDays) {
        setErrorExpiryDays("Duration is required");
        return;
      } else if (!odometerNextChange) {
        setErrorOdometerNextChange("Distance is required");
        return;
      } else {
        setErrorExpiryDays("");
        setErrorOdometerNextChange("");
      }
    } else if (step === 3) {
      if (!reminderDays) {
        setErrorReminderDays("Days left before due date are required");
        return;
      } else if (!reminderOdometer) {
        setErrorReminderOdometer("Distance left before due date is required");
        return;
      } else {
        setErrorReminderDays("");
        setErrorReminderOdometer("");
      }
    }

    return true;
  }
  function handleNext() {
    if (shouldGoNext(activeStep)) {
      if (activeStep === 0) {
        setOdometerLastChange(odometer);
      }
      setActiveStep((activeStep) => activeStep + 1);
    }
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleOdometer(event) {
    const value = event.target.value;
    setOdometer(value);
  }
  function handleOdometerLastChange(event) {
    const value = event.target.value;
    setOdometerLastChange(value);
  }

  function handleDateChange(date) {
    setDateLastChange(date);
  }
  function handleOdometerNextChange(event) {
    const value = event.target.value;
    setOdometerNextChange(value);
  }
  function handleExpiryDays(event) {
    const value = event.target.value;
    setExpiryDays(value);
  }
  function handleReminderOdometer(event) {
    const value = event.target.value;
    setReminderOdometer(value);
  }
  function handleChangeRemarks(event) {
    const value = event.target.value;
    setRemarks(value);
  }

  function handleChangeReminderDays(event) {
    const value = event.target.value;
    setReminderDays(value);
  }

  return (
    <Modal
      visible={props.isVisible}
      onClickBackdrop={() => {}}
      dialogClassName="driver-modal-dialog"
    >
      <div>
        <button
          type="button"
          className="close close-x"
          aria-label="Close"
          onClick={props.onCancel}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-heading row">
        <div className="col-lg-4 col-md-4 col-sm-4">
          {" "}
          <p className="driver-heading">Change {props.title}</p>
        </div>
      </div>
      <div className="modal-body">
        <div className="stepper-container">
          <Stepper activeStep={activeStep} steps={steps} circleFontSize={13} titleFontSize={13}/>
        </div>
        {activeStep === 0 && (
          <div>
            <TextFieldGroup
              required
              field={"odometer"}
              placeholder={"e.g 5000 km"}
              value={odometer}
              type={"number"}
              label={"Meter Reading"}
              error={errorOdometer}
              onChange={handleOdometer}
            />
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <div className="form-group">
              <label>Date</label>
              <DatePicker
                className="form-control"
                selected={dateLastChange}
                onChange={handleDateChange}
              />
              {errorDateLastChange && (
                <span className="error-maintainance">
                  {errorDateLastChange}
                </span>
              )}
            </div>
            <TextFieldGroup
              field={"odometerLast"}
              placeholder={"e.g 5000"}
              value={odometerLastChange}
              type={"number"}
              label={"Mileage"}
              error={errorOdometerLastChange}
              onChange={handleOdometerLastChange}
            />
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <TextFieldGroup
              field={"changeDays"}
              placeholder="e.g 90"
              value={expiryDays}
              type={"number"}
              label={"Duration (Days)"}
              error={errorExpiryDays}
              onChange={handleExpiryDays}
            />
            <TextFieldGroup
              field={"odometerNext"}
              placeholder={"e.g 5000"}
              value={odometerNextChange}
              type={"number"}
              label={"Distance(km)"}
              error={errorOdometerNextChange}
              onChange={handleOdometerNextChange}
            />
          </div>
        )}
        {activeStep === 3 && (
          <div>
            <TextFieldGroup
              field={"ReminderDays"}
              placeholder={"e.g 90"}
              value={reminderDays}
              type={"number"}
              label={"Days Left before due date"}
              error={errorReminderDays}
              onChange={handleChangeReminderDays}
            />
            <TextFieldGroup
              field={"ReminderOdometer"}
              placeholder={"e.g 500"}
              value={reminderOdometer}
              type={"number"}
              label={"Distance left before due date"}
              error={errorReminderOdometer}
              onChange={handleReminderOdometer}
            />
          </div>
        )}
        {activeStep === 4 && (
          <div>
            <TextAreaGroup
              field={"Remarks"}
              placeholder={"Remarks..."}
              value={remarks}
              type={"text"}
              label={"Remarks"}
              onChange={handleChangeRemarks}
            />
          </div>
        )}
        {activeStep === 5 && (
          <Container>
            <div className="row">
              <div className="col-sm-12 no-padding">
                <h3 style={{ fontSize: "2rem", paddingBottom: "2px" }}>
                  Summary
                </h3>
              </div>
              <div className="col-sm-12 no-padding">
                <div className="gridItem gridHeader">Mileage</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Current</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Modified</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{props.odometer}</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{odometer}</div>
              </div>
              <div className="col-sm-12 no-padding">
                <div className="gridItem gridHeader">{props.title} Change</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Date</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Mileage</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">
                  {dateLastChange.format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{odometerLastChange}</div>
              </div>
              <div className="col-sm-12 no-padding">
                <div className="gridItem gridHeader">Change Due</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Distance (KM)</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Duration (Days)</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{odometerNextChange || "N/A"}</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{expiryDays || "N/A"}</div>
              </div>
              <div className="col-sm-12 no-padding">
                <div className="gridItem gridHeader">Reminder</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">Days left before due date (Days)</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">
                  Distance left before due date (KM)
                </div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{reminderDays || "N/A"}</div>
              </div>
              <div className="col-sm-6 no-padding">
                <div className="gridItem">{reminderOdometer || "N/A"}</div>
              </div>
              <div className="col-sm-12 no-padding">
                <div className="gridItem gridHeader">Remarks</div>
              </div>
              <div className="col-sm-12 no-padding">
                <div className="gridItem">{remarks || "N/A"}</div>
              </div>
            </div>
          </Container>
        )}
        <div className="actionsContainer">
          <div>
            <button
              disabled={activeStep === 0}
              onClick={handleBack}
              className="btn btn-success"
              style={{ outline: "none" }}
            >
              Back
            </button>
            <button
              onClick={handleNext}
              className="btn btn-contained"
              disabled={activeStep === 5}
              style={{ outline: "none" }}
            >
              {activeStep >= steps.length - 1 ? "Finish" : "Next"}
            </button>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          disabled={activeStep !== 5}
          className="btn btn-success m-t-20-px"
          onClick={() => {
            props.onConfirm({
              lastChangeDate: dateLastChange,
              lastChangeMilage: odometerLastChange,
              expiryMilage: odometerNextChange,
              expiryTime: expiryDays,
              remindMilage: reminderOdometer,
              remindTime: reminderDays,
              odoMeterReading: odometer,
              remarks,
            });
          }}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  thingsActions: bindActionCreators(Things.creators, dispatch),
  actions: bindActionCreators(LogMaintenance.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);
