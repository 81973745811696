import React, {Component} from 'react';
import "./CheckboxListComponent.css";

class CheckboxListComponent extends Component {
  
    constructor(){
        super();
        this.state = {
            search : "",
            selectAll: false
        }
    }
    onChange = e=>{
      this.props.onChange(e);
    };
    selectAll = ()=>{   
      const value = !this.state.selectAll;
      this.setState({selectAll: value}) 
      this.props.selectAll(value);
    };
    
    search = (event)=>{    
      this.props.search(event);
      this.setState({...this.state, search : event.target.value || ""});
    };
  
    render() {
        return (
            <ul className="list-group padding0">
            {this.props.enableSearch &&
                <li className="list-group-item border-top-0">
                    <input type="text" className="form-control" placeholder="Search" onChange={this.search} name="search" value={this.state.search}/>
                </li>}
                {this.props.selectAllEnabled && <li className="list-group-item checkbox-list-item" onClick={()=>this.selectAll()}>
                    <div className="row">
                        <div className="round">
                            <input type="checkbox" checked={this.state.selectAll} onChange={()=>this.selectAll()} onClick={()=>this.selectAll()} value={1}/>
                            <label htmlFor="checkbox"/>
                        </div>
                        <div className="col">
                            <p className="ml-2"><b>Select All</b></p>
                        </div>
                    </div>
                </li>}

                {this.props.data && this.props.data.map((item,index)=>{
                    if(item){
                    return <li className="list-group-item checkbox-list-item " key={index} onClick={()=>this.onChange(index)}>
                        <div className="row">
                            <div className="round">
                                <input type="checkbox" checked={item.checked} onChange={()=>this.onChange(index)} onClick={()=>this.onChange(index)} value={index}/>
                                <label htmlFor="checkbox"/>
                            </div>
                            <div className="col">
                                <p className="ml-2 text-secondary"><b className="text-dark">{item.mainTitle}</b> {item.subTitle}</p>
                            </div>
                        </div>
                    </li>
                    }else{
                        return ''
                    } 
                })}
            
            </ul>
        );
    }
}

export class CheckboxInlineComponent extends Component {
  
    onChange = e=>{
        this.props.onChange(e);
      };
  
    render() {
        return (
            <ul className="checkbox-inline padding0">
          

                {this.props.data && this.props.data.map((item,index)=>{
                    if(item){
                    return <li className="checkbox-list-in-item" key={index} onClick={()=>this.onChange(index)}>
                        <div className="checkbox-inline">
                            <div className="round">
                                <input type="checkbox" checked={item.checked} onChange={()=>this.onChange(index)} onClick={()=>this.onChange(index)} value={index}/>
                                <label htmlFor="checkbox"/>
                            </div>
                            <div className="col">
                                <p className="ml-2 text-secondary"><b className="text-dark">{item.mainTitle}</b> {item.subTitle}</p>
                            </div>
                        </div>
                    </li>
                    }else{
                        return ''
                    } 
                })}
            
            </ul>
        );
    }
}

CheckboxListComponent.propTypes = {};
CheckboxListComponent.defaultProps = {};

CheckboxInlineComponent.propTypes = {};
CheckboxInlineComponent.defaultProps = {};

export default CheckboxListComponent;

