import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import * as R from "ramda";
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import map from 'lodash/map';
import classnames from 'classnames';
import { subDays } from 'date-fns';
import { Popover, PopoverBody } from 'reactstrap';
import Select from 'react-select-nested-group';
import { DateRangePicker } from 'react-date-range';
import DateTime from 'react-datetime';
import Groups from '../../ducks/Groups';
import Things from "../../ducks/Things";
import GeoFence from '../../ducks/GeoFence';
import RouteFence from '../../ducks/RouteFence';
import Reports from '../../ducks/Reports';
import toastr from 'toastr';
import TextFieldGroup from '../common/TextFieldGroup';
import CheckboxListComponent from '../common/CheckboxListComponent';
import CheckListComponent from '../check-list';
import './index.scss'
import calenderIcon from '../../assets/img/calender.png';
import MySelect from "./MySelect.js";

class Observation extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      performanceGeoFence: null,
      performanceRouteFence: null,
      geofenceEntry: null,
      geofenceExit: null,
      vehicles: [],
      groupList: [],
      selectedStartDate:moment(),
      selectedEndDate:moment(),
      selectedVehicles: [],
      selectedVehicle:'',
      distanceLimit: 25,
      activeTab: 'vehicles',
      errors: {},
      dateRangePicker: {
        selection: {
          startDate: subDays(new Date(), 1),
          endDate: new Date(),
          startTime: moment(),
          endTime: moment(),
          key: 'selection',
        },
      },
      popoverOpen: false,
      overSpeedingLimit: 80,
      companyInfo: JSON.parse(localStorage.getItem('companyInfo') || '') || {}
    }
  };


  async componentDidMount() {

    if (!(this.props.groupList && this.props.groupList.length > 0)) await this.props.actions.getSubGroups(this.state.companyInfo.groupId);

    await this
      .props
      .actions
      .getGroupsAndVehicles();


    if (this.props.reportType === 'geofenceTrackReport' || this.props.reportType === 'performanceReport' ) {
      await this
        .props
        .geofenceActions
        .getGroupGeoFences();
    }

    if (this.props.reportType === 'performanceReport') {
      await this.props.routeFenceActions.getGroupRouteFences();
    }

    let dateRangePicker = {
      selection: {
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
        startTime: moment().startOf('day'),
        endTime: moment().endOf('day'),
        key: 'selection',
      },
    };
    if (this.props.interval && this.props.interval.startDate) {
      dateRangePicker = { selection: this.props.interval }
    };

    const things = await this.props.thingsActions.fetchThings();
    const mappedValues = R.map((thing) => ({
      value: thing.name,
      label: `${thing.plateNumber || "N/A"}`,
    }))(things);
    // setThings(mappedValues);

    this.setState({
      ...this.state,
      vehicles: mappedValues,
      groupList: this.formatGroupList(this.props.groupList),
      dateRangePicker,
    });
  }

  toggle() {
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  handleChange(which, payload) {

    this.setState({
      [which]: payload,
    });
  }

  handleRangeChange(which, payload) {

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }

  isTabActive = name => {
    return name === this.state.activeTab;
  };

  handleTabClick = name => {
    this.setState({
      ...this.state,
      activeTab: name
    })
  };

  handleSelect(range) {
    // An object with two keys,
    // 'startDate' and 'endDate' which are Momentjs objects.
  }

  selectVehiclesAndRange() {

    const date = moment(this.state.selectedStartDate);
    const endDate = moment(this.state.selectedEndDate);
    let diff = endDate.diff(date, 'days');

    if (diff > 7){
      return toastr.error("Please Select 7 Day Max Range ");
    }

    const interval = {
        startDate: moment(this.state.selectedStartDate),
        endDate: moment(this.state.selectedEndDate),
      };
    this.props.reportsActions.exportObservationsReport(this.state.selectedVehicle.value, JSON.stringify({ interval }));
  }

  handleVehicleChange(option) {
    this.setState({
        ...this.state,
        selectedVehicle : option
    })
  };

  back() {
    this.props.switchStep(2, this.state.selectedVehicles, this.state.dateRangePicker.selection);
  }

  selectGeofenceEntry = async (selectedOption) => {
    this.setState({ geofenceEntry: selectedOption })
  };
  selectGeofenceExit = async (selectedOption) => {
    this.setState({ geofenceExit: selectedOption })
  };
  selectPerformanceGeofence = async (selectedOption) => {
    this.setState({ performanceGeoFence: selectedOption })
  };
  selectPerformanceRoutefence = async (selectedOption) => {
    this.setState({ performanceRouteFence: selectedOption })
  };
  dateTimeChange = async (e,flag) => {
      this.setState({...this.state,
        [flag]: e['_d'] })
  }

  formatGroupList(groupList) {
    return map(groupList, item => {
      item.checked = false;
      if (item.child && item.child.length) item.child = this.formatGroupList(item.child);
      return item
    })
  };

  render() {
    return (

      <div>
        <div>
          <ul className='reports-list'>
            <li className='interval-report'>
                <span>
                    <button
                    className='btn auxo-primary-btn margin-zero'
                    onClick={this.back.bind(this)}
                    >
                    Previous
                    </button>
                </span>
                <span className='text-center interval-icon'>
                    <h4>Vehicles and Interval</h4>
                    <p>Select vehicles and date interval for the report</p>
                </span>
              <span className='select-report'>
                <button
                  className='btn auxo-primary-btn margin-zero'
                  onClick={this.selectVehiclesAndRange.bind(this)}
                >
                  Export
                </button>
              </span>
            </li>
          </ul>
        </div>
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-6'>
                <label>Select Vehicle</label>
                    <Select
                        name="form-field-selectedThing"
                        value={this.state.selectedVehicle}
                        searchable={true}
                        onChange={this.handleVehicleChange.bind(this)}
                        options={this.state.vehicles}
                    />
          </div>
          <div className='col-lg-2 col-md-2 col-sm-3'>
                <div>
                  <label >Start Date</label>
                    <DateTime
                        value={this.state.selectedStartDate}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="HH:mm"
                        onChange={(e) => this.dateTimeChange(e,'selectedStartDate')}
                    />
                </div>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-3'>
            <div>
              <label >End Date</label>
                <DateTime
                    value={this.state.selectedEndDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={(e) => this.dateTimeChange(e,'selectedEndDate')}
                />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Observation.propTypes = {};
Observation.defaultProps = {};

const mapStateToProps = state => ({
  groupsAndVehicles: state.groups.groupsAndVehicles,
  geoFences: state.geoFence.geoFences,
  routeFences: state.routeFence.routeFences,
  groupList: state.groups.subGroups
});
const mapDispatchToProps = dispatch => ({
  routeFenceActions: bindActionCreators(RouteFence.creators, dispatch),
  thingsActions: bindActionCreators(Things.creators, dispatch),
  actions: bindActionCreators(Groups.creators, dispatch),
  geofenceActions: bindActionCreators(GeoFence.creators, dispatch),
  reportsActions: bindActionCreators(Reports.creators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Observation);
