import React, { Component } from 'react';

import Modal from 'react-bootstrap4-modal';
// import { Spinner, BlueSmallSpinner } from '../../common/Spinner/index';

export class DeletePopUp extends Component {
 

  render() {


    return (
      <Modal
        visible={true}
        dialogClassName='delete-modal-dialog'>
        <div>
          <button
            type="button"
            className="close close-x"
            aria-label="Close"
            onClick={this.props
              .cancelDelete
              .bind(this)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className='modal-heading'>
          <h5 className="car-and-device-assig">Delete Record?</h5>
        </div>
        <div className="modal-body">
          <h6>Are You Sure You want to delete this record?</h6>


        </div>
        <div className="modal-footer model-btn">
          <button
            className="btn auxo-primary-btn"
            onClick={this.props
              .confirmDelete
              .bind(this)}>
            Confirm Delete
</button>
          <button
            className="btn auxo-primary-btn"
            onClick={this.props
              .cancelDelete
              .bind(this)}>
            Cancel
</button>
        </div>
      </Modal>)
  }

}