import { withStateHandlers, compose } from 'recompose';
import React, { Fragment } from 'react';
import { Marker } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import violation from '../../../assets/img/violation.png';
import blue_status from '../../../assets/img/blue_status.png';
import green_status from '../../../assets/img/green_status.png';
import status_purple from '../../../assets/img/status_purple.png';
import status_orange from '../../../assets/img/status_orange.png';
import status_gray from '../../../assets/img/status_gray.png';
import startPoint from '../../../assets/img/startPoint.png';
import CellTower from '../../../assets/img/tower.png';
import pin_idle from '../../../assets/img/pin_idle.png';
import pin_pluggedin from '../../../assets/img/pin_pluggedin.png';
import pin_moving from '../../../assets/img/pin_moving.png';
import pin_parked from '../../../assets/img/pin_parked.png';
import pin_ignition from '../../../assets/img/pin_ignition.png';
import pin_pluggedout from '../../../assets/img/pin_pluggedout.png';

import "./maps.css"

function timeConvert(n) {
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return  "Stop: " + rhours + " hour(s) and " + rminutes + " minute(s).";
  }

const stateHandlers =
  withStateHandlers(
    ({ isOpen = false }) => ({ isOpen }),
    {
      onMarkerClick: (state) => () => {
        return ({ isOpen: !state.isOpen });

      },
      onMouseOver: () => () => {
        return ({ isOpen: true });

      },
      onMouseOut: () => () => {
        return ({ isOpen: false });

      },

    }
  )
const Violation = (props) => {
  let icon = violation;
  if(props.company === "664f4f93e440b860d2c1c93c") {  
    if(props.type === "geoFence"){
    icon = pin_ignition;
    }
    if(props.type === "unfastenSeatbelt"){
      icon = pin_pluggedin;
    }
    if(props.type === "IDLE"){
      icon = pin_idle;
    }
    else if(props.type === "harshTurn"){
        icon = pin_moving;
    }
    else if(props.type === "harshBrakes"){
      icon = pin_parked;
    }
    else if(props.type === "ignitionOn"){
      icon = pin_pluggedin;
    } 
    else if(props.type === "overSpeeding"){
      icon = status_purple;
    }
    else if(props.type === "stop"){
      icon = pin_pluggedout;
    }
    else if(props.type === "FATIGUE"){
      icon = pin_parked;
    }
    else if(props.type === "enterCellSite"){
      icon = CellTower
    }
  } else {
    if(props.type === "geoFence"){
      icon = startPoint;
      }
      if(props.type === "unfastenSeatbelt"){
        icon = green_status;
      }
      if(props.type === "IDLE"){
        icon = status_gray;
      }
      else if(props.type === "harshTurn"){
          icon = blue_status;
      }
      else if(props.type === "harshBrakes"){
        icon = status_orange;
      }
      else if(props.type === "ignitionOn"){
        icon = green_status;
      } 
      else if(props.type === "overSpeeding"){
        icon = status_purple;
      }
      else if(props.type === "stop"){
        icon = violation;
      }
      else if(props.type === "FATIGUE"){
        icon = status_orange;
      }
      else if(props.type === "enterCellSite"){
        icon = CellTower
      }
  }

  const duration = props.duration? timeConvert(props.duration) : ''
 
  return (
    
    
    <Fragment>
      <Marker position={props.latlngCords}
       noRedraw={true}
       icon={icon}
        onClick={props.onMarkerClick} 
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}
      >
        <InfoBox visible={props.isOpen}
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
        >
          <div style={{ backgroundColor: `#fff`, padding: `12px`, minHeight: '60px' }}>
            <div style={{ fontSize: `12px`, fontFamily: 'Roboto' }}>
              <section style={{ fontSize: '14px', fontWeight: '500', marginBottom: '5px', backgroundColor: `#fff` }} className="violation-title">
                {props.title || duration}
              </section>
              {props.eventTime || props.time || ''}
            </div>
          </div>
        </InfoBox>
      </Marker>
    </Fragment>
  )
};

const ViolationComponent =
  compose(stateHandlers)(Violation);
export default React.memo(ViolationComponent)