import ReactGA from 'react-ga';
import { googleAnalytics, PRODUCTION } from '../constants';
if (PRODUCTION) {
  ReactGA.initialize(googleAnalytics, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
}

export default () => next => (action) => {

  if (PRODUCTION) {
    if (action && action.type === '@@router/LOCATION_CHANGE') {
      if (action.payload && action.payload.location) {
        const pathName = action.payload.location.pathname;
        ReactGA.pageview(pathName);
      }
    }
  }
  return next(action);
};
