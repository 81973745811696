import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Schedule from "../../ducks/Schedule";
import { Spinner } from '../common/Spinner/index';
import './index.css';
import ic_visibility from '../../assets/img/ic_visibility.png';
import ic_edit from '../../assets/img/ic_edit.png';
import EditSchedule from './editSchedule';
import Modal from "react-bootstrap4-modal";



class ScheduleListView extends Component {

    constructor() {
        super();
        this.state = {
            isLoadingRecords: true,
            popupVisible: false,
            scheduleToEdit: {},
            child: {},
            deleteScheduleId: null
        }

        this.deleteReportSchedule = this.deleteReportSchedule.bind(this)
        this.deleteSchedule = this.deleteSchedule.bind(this);
    }

    async componentDidMount() {
        await this
            .props
            .actions
            .getSchedules();
        this.setState({ ...this.state, isLoadingRecords: false })
    }

    ScheduleSchedule() {
        this
            .props
            .history
            .push("/schedule/add");
    }
    viewScheduleSlots(id) {
        this
            .props
            .history
            .push("/schedule/details/" + id);
    }
    async editSchedule(scheduleToEdit) {

        await this.setState({
            ...this.state,
            scheduleToEdit,
            popupVisible: true
        });
        this.child.loadData()
    }

    async deleteReportSchedule(item) {
      console.log("dleete id before yes ", item._id)
      await this.setState({
          ...this.state,
          deleteScheduleId: item._id,
          dialogOpen: true
      });
  }


    closePopup() {
        this.setState({
            ...this.state,
            popupVisible: false
        });
    }

    deleteSchedule = async () => {
      const { deleteScheduleId } = this.state;
      console.log("dleete id after yes ", deleteScheduleId)
      await this.props.actions.DeleteSchedule(deleteScheduleId);
      this.setState({
        _id: null,
        dialogOpen: false,
      });
    }



    cancelDeleteOperation = () => {
      this.setState({
        deleteScheduleId: null,
        dialogOpen: false,
      });
    };

    render() {
        const loader = <Spinner key="1" />;
        const isLoadingRecords = this.state.isLoadingRecords;

        return (
            <div>{!isLoadingRecords ?
                <div>
                    <EditSchedule scheduleToEdit={this.state.scheduleToEdit} popupVisible={this.state.popupVisible} onRef={ref => (this.child = ref)} closePopup={this
                        .closePopup
                        .bind(this)} />

                    <div className="row">

                        <div className="col-md-4 col-sm-4 margin-top-20 margin-bottom-9">

                            <h2>Schedule</h2>
                        </div>
                        <div className="col-md-8 col-sm-8">
                        {this.props.permissions.createSchedule &&
                            <button
                                className="btn auxo-primary-btn"
                                onClick={this
                                    .ScheduleSchedule
                                    .bind(this)}>Add Schedule</button>

                                }
                        </div>
                    </div>


                    <div className="row">

                        <table className="table-auxo">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>DESCRIPTION</th>
                                    <th >ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.schedules && this
                                    .props
                                    .schedules
                                    .map((item, index) => {

                                        return (
                                          <tr
                                            key={index}
                                            className="driver-listing"
                                          >
                                            <td className="edit-field">
                                              {item.name}
                                            </td>
                                            <td className="edit-field">
                                              {item.description}
                                            </td>
                                            <td className="edit-field last-column-width">
                                              <div>
                                                <Tooltip
                                                  prefixCls="rc-slider-tooltip"
                                                  overlay={"View Schedule"}
                                                  placement="top"
                                                >
                                                  <a
                                                    onClick={this.viewScheduleSlots.bind(
                                                      this,
                                                      item._id
                                                    )}
                                                  >
                                                    <img
                                                      className="width16"
                                                      src={ic_visibility}
                                                      alt=""
                                                    />
                                                  </a>
                                                </Tooltip>

                                                {this.props.permissions.updateSchedule && 
                                                  <Tooltip
                                                    prefixCls="rc-slider-tooltip"
                                                    overlay={"Edit Schedule"}
                                                    placement="top"
                                                  >
                                                    <a
                                                      onClick={this.editSchedule.bind(
                                                        this,
                                                        item
                                                      )}
                                                    >
                                                      <img
                                                        className="width16"
                                                        src={ic_edit}
                                                        alt=""
                                                      />
                                                    </a>
                                                  </Tooltip>
                                                }

{this.props.permissions.updateSchedule && 
                                                  <Tooltip
                                                    prefixCls="rc-slider-tooltip"
                                                    overlay={"Delete Schedule"}
                                                    placement="top"
                                                  >
                                                    <a
                                                      onClick={this.deleteReportSchedule.bind(
                                                        this,
                                                        item
                                                      )}
                                                    >
                                                      {/* <img
                                                        className="width16"
                                                        src={ic_edit}
                                                        alt=""
                                                      /> */}
                                                     <i className="fa fa-trash" aria-hidden="true" style={{color: "#03ABCE", padding: "0px"}}></i>
                                                    </a>
                                                  </Tooltip>
                                                }
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                    })}


                            </tbody>
                        </table>
                    </div>
                </div> : [loader]}

                <Modal
          visible={this.state.dialogOpen}
          onClickBackdrop={this.cancelDeleteOperation}
          dialogClassName="delete-modal-dialog"
        >
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={this.cancelDeleteOperation.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading">
            <h5 className="car-and-device-assig">
              {"Delete Report Schedule?"}
            </h5>
          </div>
          <div className="modal-body">
            <h6>Do you want to delete this schedule ?</h6>
          </div>
          <div className="modal-footer model-btn">
            <button
              onClick={this.cancelDeleteOperation}
              className="btn auxo-primary-btn"
            >
              No
            </button>
            <button
              onClick={this.deleteSchedule}
              className="btn auxo-primary-btn"
            >
              Yes
            </button>
          </div>
        </Modal>
            </div>
        );
    }
}

ScheduleListView.propTypes = {};
ScheduleListView.defaultProps = {};
const mapStateToProps = state => ({ schedules: state.schedule.schedules,  permissions: state.permissions });

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Schedule.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleListView);
